import client from "src/lib/apollo/client-provider";
import type {
        ApolloQueryResult, ObservableQuery, WatchQueryOptions, MutationOptions, SubscriptionOptions
      } from "@apollo/client";
import { readable } from "svelte/store";
import type { Readable } from "svelte/store";
import gql from "graphql-tag"
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _text: string;
  bigint: number;
  date: string;
  json: object;
  smallint: number;
  timestamp: any;
  timestamptz: string;
  uuid: string;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CheckInviteStatusOutput = {
  __typename?: 'CheckInviteStatusOutput';
  id: Scalars['String'];
  preregistered: Scalars['Boolean'];
  role: RolesEnum;
  status: InviteState;
};

export type CreateUserOutput = {
  __typename?: 'CreateUserOutput';
  cpf?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<Scalars['Float']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type InviteNewUserOutput = {
  __typename?: 'InviteNewUserOutput';
  token: Scalars['String'];
  user_invite_id: Scalars['String'];
};

export enum InviteState {
  Accepted = 'accepted',
  Pending = 'pending',
  Refused = 'refused'
}

export type InviteUserToTeamOutput = {
  __typename?: 'InviteUserToTeamOutput';
  invite_id: Scalars['String'];
  team_id: Scalars['String'];
  user_id: Scalars['String'];
};

export type RespondTeamInvitationOutput = {
  __typename?: 'RespondTeamInvitationOutput';
  status: Scalars['String'];
  team_id: Scalars['String'];
  user_id: Scalars['String'];
};

export enum RolesEnum {
  Admin = 'admin',
  Anonymous = 'anonymous',
  Editor = 'editor',
  Manager = 'manager',
  Mentor = 'mentor',
  User = 'user'
}

export type SendManagerEmailOutput = {
  __typename?: 'SendManagerEmailOutput';
  status_code: Scalars['String'];
};

export type SendUserEmailOutput = {
  __typename?: 'SendUserEmailOutput';
  status_code: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type UpdateUserRoleOutput = {
  __typename?: 'UpdateUserRoleOutput';
  role?: Maybe<RolesEnum>;
  user_id?: Maybe<Scalars['String']>;
};

export type UploadOutput = {
  __typename?: 'UploadOutput';
  file_id: Scalars['String'];
  file_path: Scalars['String'];
};

export type UploadProfilePictureOutput = {
  __typename?: 'UploadProfilePictureOutput';
  id: Scalars['String'];
  path: Scalars['String'];
};

/** columns and relationships of "available_mentoring_dates" */
export type Available_Mentoring_Dates = {
  __typename?: 'available_mentoring_dates';
  date: Scalars['timestamptz'];
  id: Scalars['uuid'];
  request_id: Scalars['uuid'];
  /** An object relationship */
  requested_mentoring: Requested_Mentorings;
};

/** aggregated selection of "available_mentoring_dates" */
export type Available_Mentoring_Dates_Aggregate = {
  __typename?: 'available_mentoring_dates_aggregate';
  aggregate?: Maybe<Available_Mentoring_Dates_Aggregate_Fields>;
  nodes: Array<Available_Mentoring_Dates>;
};

export type Available_Mentoring_Dates_Aggregate_Bool_Exp = {
  count?: InputMaybe<Available_Mentoring_Dates_Aggregate_Bool_Exp_Count>;
};

export type Available_Mentoring_Dates_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Available_Mentoring_Dates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Available_Mentoring_Dates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "available_mentoring_dates" */
export type Available_Mentoring_Dates_Aggregate_Fields = {
  __typename?: 'available_mentoring_dates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Available_Mentoring_Dates_Max_Fields>;
  min?: Maybe<Available_Mentoring_Dates_Min_Fields>;
};


/** aggregate fields of "available_mentoring_dates" */
export type Available_Mentoring_Dates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Available_Mentoring_Dates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "available_mentoring_dates" */
export type Available_Mentoring_Dates_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Available_Mentoring_Dates_Max_Order_By>;
  min?: InputMaybe<Available_Mentoring_Dates_Min_Order_By>;
};

/** input type for inserting array relation for remote table "available_mentoring_dates" */
export type Available_Mentoring_Dates_Arr_Rel_Insert_Input = {
  data: Array<Available_Mentoring_Dates_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Available_Mentoring_Dates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "available_mentoring_dates". All fields are combined with a logical 'AND'. */
export type Available_Mentoring_Dates_Bool_Exp = {
  _and?: InputMaybe<Array<Available_Mentoring_Dates_Bool_Exp>>;
  _not?: InputMaybe<Available_Mentoring_Dates_Bool_Exp>;
  _or?: InputMaybe<Array<Available_Mentoring_Dates_Bool_Exp>>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  request_id?: InputMaybe<Uuid_Comparison_Exp>;
  requested_mentoring?: InputMaybe<Requested_Mentorings_Bool_Exp>;
};

/** unique or primary key constraints on table "available_mentoring_dates" */
export enum Available_Mentoring_Dates_Constraint {
  /** unique or primary key constraint on columns "request_id", "date" */
  AvailableMentoringDatesDateRequestIdKey = 'available_mentoring_dates_date_request_id_key',
  /** unique or primary key constraint on columns "id" */
  AvailableMentoringDatesPkey = 'available_mentoring_dates_pkey'
}

/** input type for inserting data into table "available_mentoring_dates" */
export type Available_Mentoring_Dates_Insert_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  request_id?: InputMaybe<Scalars['uuid']>;
  requested_mentoring?: InputMaybe<Requested_Mentorings_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Available_Mentoring_Dates_Max_Fields = {
  __typename?: 'available_mentoring_dates_max_fields';
  date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  request_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "available_mentoring_dates" */
export type Available_Mentoring_Dates_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Available_Mentoring_Dates_Min_Fields = {
  __typename?: 'available_mentoring_dates_min_fields';
  date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  request_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "available_mentoring_dates" */
export type Available_Mentoring_Dates_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "available_mentoring_dates" */
export type Available_Mentoring_Dates_Mutation_Response = {
  __typename?: 'available_mentoring_dates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Available_Mentoring_Dates>;
};

/** on_conflict condition type for table "available_mentoring_dates" */
export type Available_Mentoring_Dates_On_Conflict = {
  constraint: Available_Mentoring_Dates_Constraint;
  update_columns?: Array<Available_Mentoring_Dates_Update_Column>;
  where?: InputMaybe<Available_Mentoring_Dates_Bool_Exp>;
};

/** Ordering options when selecting data from "available_mentoring_dates". */
export type Available_Mentoring_Dates_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  requested_mentoring?: InputMaybe<Requested_Mentorings_Order_By>;
};

/** primary key columns input for table: available_mentoring_dates */
export type Available_Mentoring_Dates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "available_mentoring_dates" */
export enum Available_Mentoring_Dates_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  RequestId = 'request_id'
}

/** input type for updating data in table "available_mentoring_dates" */
export type Available_Mentoring_Dates_Set_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  request_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "available_mentoring_dates" */
export type Available_Mentoring_Dates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Available_Mentoring_Dates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Available_Mentoring_Dates_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  request_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "available_mentoring_dates" */
export enum Available_Mentoring_Dates_Update_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  RequestId = 'request_id'
}

export type Available_Mentoring_Dates_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Available_Mentoring_Dates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Available_Mentoring_Dates_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "contents" */
export type Contents = {
  __typename?: 'contents';
  description: Scalars['String'];
  /** An array relationship */
  groups: Array<Groups_Contents>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Contents_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  repositories: Array<Repositories>;
  /** An aggregate relationship */
  repositories_aggregate: Repositories_Aggregate;
  /** An array relationship */
  sections: Array<Contents_Sections>;
  /** An aggregate relationship */
  sections_aggregate: Contents_Sections_Aggregate;
  /** An array relationship */
  video_repositories: Array<Video_Repositories>;
  /** An aggregate relationship */
  video_repositories_aggregate: Video_Repositories_Aggregate;
};


/** columns and relationships of "contents" */
export type ContentsGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsRepositoriesArgs = {
  distinct_on?: InputMaybe<Array<Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Repositories_Order_By>>;
  where?: InputMaybe<Repositories_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsRepositories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Repositories_Order_By>>;
  where?: InputMaybe<Repositories_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsSectionsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsSections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsVideo_RepositoriesArgs = {
  distinct_on?: InputMaybe<Array<Video_Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Repositories_Order_By>>;
  where?: InputMaybe<Video_Repositories_Bool_Exp>;
};


/** columns and relationships of "contents" */
export type ContentsVideo_Repositories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Repositories_Order_By>>;
  where?: InputMaybe<Video_Repositories_Bool_Exp>;
};

/** aggregated selection of "contents" */
export type Contents_Aggregate = {
  __typename?: 'contents_aggregate';
  aggregate?: Maybe<Contents_Aggregate_Fields>;
  nodes: Array<Contents>;
};

/** aggregate fields of "contents" */
export type Contents_Aggregate_Fields = {
  __typename?: 'contents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Contents_Max_Fields>;
  min?: Maybe<Contents_Min_Fields>;
};


/** aggregate fields of "contents" */
export type Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contents". All fields are combined with a logical 'AND'. */
export type Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Contents_Bool_Exp>>;
  _not?: InputMaybe<Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Contents_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  groups?: InputMaybe<Groups_Contents_Bool_Exp>;
  groups_aggregate?: InputMaybe<Groups_Contents_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  repositories?: InputMaybe<Repositories_Bool_Exp>;
  repositories_aggregate?: InputMaybe<Repositories_Aggregate_Bool_Exp>;
  sections?: InputMaybe<Contents_Sections_Bool_Exp>;
  sections_aggregate?: InputMaybe<Contents_Sections_Aggregate_Bool_Exp>;
  video_repositories?: InputMaybe<Video_Repositories_Bool_Exp>;
  video_repositories_aggregate?: InputMaybe<Video_Repositories_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "contents" */
export enum Contents_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContentsPkey = 'contents_pkey'
}

/** input type for inserting data into table "contents" */
export type Contents_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Groups_Contents_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  repositories?: InputMaybe<Repositories_Arr_Rel_Insert_Input>;
  sections?: InputMaybe<Contents_Sections_Arr_Rel_Insert_Input>;
  video_repositories?: InputMaybe<Video_Repositories_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Contents_Max_Fields = {
  __typename?: 'contents_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Contents_Min_Fields = {
  __typename?: 'contents_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "contents" */
export type Contents_Mutation_Response = {
  __typename?: 'contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contents>;
};

/** input type for inserting object relation for remote table "contents" */
export type Contents_Obj_Rel_Insert_Input = {
  data: Contents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contents_On_Conflict>;
};

/** on_conflict condition type for table "contents" */
export type Contents_On_Conflict = {
  constraint: Contents_Constraint;
  update_columns?: Array<Contents_Update_Column>;
  where?: InputMaybe<Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "contents". */
export type Contents_Order_By = {
  description?: InputMaybe<Order_By>;
  groups_aggregate?: InputMaybe<Groups_Contents_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  repositories_aggregate?: InputMaybe<Repositories_Aggregate_Order_By>;
  sections_aggregate?: InputMaybe<Contents_Sections_Aggregate_Order_By>;
  video_repositories_aggregate?: InputMaybe<Video_Repositories_Aggregate_Order_By>;
};

/** primary key columns input for table: contents */
export type Contents_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "contents_sections" */
export type Contents_Sections = {
  __typename?: 'contents_sections';
  /** An object relationship */
  content: Contents;
  content_id: Scalars['uuid'];
  /** An object relationship */
  section: Sections;
  section_id: Scalars['uuid'];
};

/** aggregated selection of "contents_sections" */
export type Contents_Sections_Aggregate = {
  __typename?: 'contents_sections_aggregate';
  aggregate?: Maybe<Contents_Sections_Aggregate_Fields>;
  nodes: Array<Contents_Sections>;
};

export type Contents_Sections_Aggregate_Bool_Exp = {
  count?: InputMaybe<Contents_Sections_Aggregate_Bool_Exp_Count>;
};

export type Contents_Sections_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Contents_Sections_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "contents_sections" */
export type Contents_Sections_Aggregate_Fields = {
  __typename?: 'contents_sections_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Contents_Sections_Max_Fields>;
  min?: Maybe<Contents_Sections_Min_Fields>;
};


/** aggregate fields of "contents_sections" */
export type Contents_Sections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contents_sections" */
export type Contents_Sections_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Contents_Sections_Max_Order_By>;
  min?: InputMaybe<Contents_Sections_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contents_sections" */
export type Contents_Sections_Arr_Rel_Insert_Input = {
  data: Array<Contents_Sections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Contents_Sections_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contents_sections". All fields are combined with a logical 'AND'. */
export type Contents_Sections_Bool_Exp = {
  _and?: InputMaybe<Array<Contents_Sections_Bool_Exp>>;
  _not?: InputMaybe<Contents_Sections_Bool_Exp>;
  _or?: InputMaybe<Array<Contents_Sections_Bool_Exp>>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Uuid_Comparison_Exp>;
  section?: InputMaybe<Sections_Bool_Exp>;
  section_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "contents_sections" */
export enum Contents_Sections_Constraint {
  /** unique or primary key constraint on columns "section_id", "content_id" */
  ContentsSectionsPkey = 'contents_sections_pkey'
}

/** input type for inserting data into table "contents_sections" */
export type Contents_Sections_Insert_Input = {
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['uuid']>;
  section?: InputMaybe<Sections_Obj_Rel_Insert_Input>;
  section_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Contents_Sections_Max_Fields = {
  __typename?: 'contents_sections_max_fields';
  content_id?: Maybe<Scalars['uuid']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "contents_sections" */
export type Contents_Sections_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Contents_Sections_Min_Fields = {
  __typename?: 'contents_sections_min_fields';
  content_id?: Maybe<Scalars['uuid']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "contents_sections" */
export type Contents_Sections_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "contents_sections" */
export type Contents_Sections_Mutation_Response = {
  __typename?: 'contents_sections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contents_Sections>;
};

/** on_conflict condition type for table "contents_sections" */
export type Contents_Sections_On_Conflict = {
  constraint: Contents_Sections_Constraint;
  update_columns?: Array<Contents_Sections_Update_Column>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};

/** Ordering options when selecting data from "contents_sections". */
export type Contents_Sections_Order_By = {
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  section?: InputMaybe<Sections_Order_By>;
  section_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contents_sections */
export type Contents_Sections_Pk_Columns_Input = {
  content_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};

/** select columns of table "contents_sections" */
export enum Contents_Sections_Select_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  SectionId = 'section_id'
}

/** input type for updating data in table "contents_sections" */
export type Contents_Sections_Set_Input = {
  content_id?: InputMaybe<Scalars['uuid']>;
  section_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "contents_sections" */
export type Contents_Sections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contents_Sections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contents_Sections_Stream_Cursor_Value_Input = {
  content_id?: InputMaybe<Scalars['uuid']>;
  section_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "contents_sections" */
export enum Contents_Sections_Update_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  SectionId = 'section_id'
}

export type Contents_Sections_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contents_Sections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contents_Sections_Bool_Exp;
};

/** select columns of table "contents" */
export enum Contents_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "contents" */
export type Contents_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "contents" */
export type Contents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contents_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "contents" */
export enum Contents_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Contents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contents_Bool_Exp;
};

/** fields of action: "createUser" */
export type CreateUser = {
  __typename?: 'createUser';
  /** the time at which this action was created */
  created_at: Scalars['timestamptz'];
  /** errors related to the invocation */
  errors?: Maybe<Scalars['json']>;
  /** the unique id of an action */
  id: Scalars['uuid'];
  /** the output fields of this action */
  output?: Maybe<CreateUserOutput>;
};

/** fields of action: "createUserPreregistered" */
export type CreateUserPreregistered = {
  __typename?: 'createUserPreregistered';
  /** the time at which this action was created */
  created_at: Scalars['timestamptz'];
  /** errors related to the invocation */
  errors?: Maybe<Scalars['json']>;
  /** the unique id of an action */
  id: Scalars['uuid'];
  /** the output fields of this action */
  output?: Maybe<CreateUserOutput>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "diagnostics" */
export type Diagnostics = {
  __typename?: 'diagnostics';
  description: Scalars['String'];
  /** An array relationship */
  groups: Array<Diagnostics_Groups>;
  /** An aggregate relationship */
  groups_aggregate: Diagnostics_Groups_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  tabs: Array<Diagnostics_Tabs>;
  /** An aggregate relationship */
  tabs_aggregate: Diagnostics_Tabs_Aggregate;
};


/** columns and relationships of "diagnostics" */
export type DiagnosticsGroupsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Groups_Order_By>>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};


/** columns and relationships of "diagnostics" */
export type DiagnosticsGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Groups_Order_By>>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};


/** columns and relationships of "diagnostics" */
export type DiagnosticsTabsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};


/** columns and relationships of "diagnostics" */
export type DiagnosticsTabs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};

/** aggregated selection of "diagnostics" */
export type Diagnostics_Aggregate = {
  __typename?: 'diagnostics_aggregate';
  aggregate?: Maybe<Diagnostics_Aggregate_Fields>;
  nodes: Array<Diagnostics>;
};

/** aggregate fields of "diagnostics" */
export type Diagnostics_Aggregate_Fields = {
  __typename?: 'diagnostics_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Max_Fields>;
  min?: Maybe<Diagnostics_Min_Fields>;
};


/** aggregate fields of "diagnostics" */
export type Diagnostics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "diagnostics". All fields are combined with a logical 'AND'. */
export type Diagnostics_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  groups?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
  groups_aggregate?: InputMaybe<Diagnostics_Groups_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tabs?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
  tabs_aggregate?: InputMaybe<Diagnostics_Tabs_Aggregate_Bool_Exp>;
};

/** columns and relationships of "diagnostics_comments" */
export type Diagnostics_Comments = {
  __typename?: 'diagnostics_comments';
  comment?: Maybe<Scalars['String']>;
  /** An object relationship */
  diagnostic_tab: Diagnostics_Tabs;
  diagnostic_tab_id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};

/** aggregated selection of "diagnostics_comments" */
export type Diagnostics_Comments_Aggregate = {
  __typename?: 'diagnostics_comments_aggregate';
  aggregate?: Maybe<Diagnostics_Comments_Aggregate_Fields>;
  nodes: Array<Diagnostics_Comments>;
};

export type Diagnostics_Comments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Diagnostics_Comments_Aggregate_Bool_Exp_Count>;
};

export type Diagnostics_Comments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Diagnostics_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "diagnostics_comments" */
export type Diagnostics_Comments_Aggregate_Fields = {
  __typename?: 'diagnostics_comments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Comments_Max_Fields>;
  min?: Maybe<Diagnostics_Comments_Min_Fields>;
};


/** aggregate fields of "diagnostics_comments" */
export type Diagnostics_Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "diagnostics_comments" */
export type Diagnostics_Comments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Diagnostics_Comments_Max_Order_By>;
  min?: InputMaybe<Diagnostics_Comments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "diagnostics_comments" */
export type Diagnostics_Comments_Arr_Rel_Insert_Input = {
  data: Array<Diagnostics_Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Comments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "diagnostics_comments". All fields are combined with a logical 'AND'. */
export type Diagnostics_Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Comments_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Comments_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  diagnostic_tab?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
  diagnostic_tab_id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_comments" */
export enum Diagnostics_Comments_Constraint {
  /** unique or primary key constraint on columns "team_id", "diagnostic_tab_id" */
  DiagnosticsCommentsPkey = 'diagnostics_comments_pkey'
}

/** input type for inserting data into table "diagnostics_comments" */
export type Diagnostics_Comments_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  diagnostic_tab?: InputMaybe<Diagnostics_Tabs_Obj_Rel_Insert_Input>;
  diagnostic_tab_id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Diagnostics_Comments_Max_Fields = {
  __typename?: 'diagnostics_comments_max_fields';
  comment?: Maybe<Scalars['String']>;
  diagnostic_tab_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "diagnostics_comments" */
export type Diagnostics_Comments_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  diagnostic_tab_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Diagnostics_Comments_Min_Fields = {
  __typename?: 'diagnostics_comments_min_fields';
  comment?: Maybe<Scalars['String']>;
  diagnostic_tab_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "diagnostics_comments" */
export type Diagnostics_Comments_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  diagnostic_tab_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "diagnostics_comments" */
export type Diagnostics_Comments_Mutation_Response = {
  __typename?: 'diagnostics_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Comments>;
};

/** on_conflict condition type for table "diagnostics_comments" */
export type Diagnostics_Comments_On_Conflict = {
  constraint: Diagnostics_Comments_Constraint;
  update_columns?: Array<Diagnostics_Comments_Update_Column>;
  where?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_comments". */
export type Diagnostics_Comments_Order_By = {
  comment?: InputMaybe<Order_By>;
  diagnostic_tab?: InputMaybe<Diagnostics_Tabs_Order_By>;
  diagnostic_tab_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_comments */
export type Diagnostics_Comments_Pk_Columns_Input = {
  diagnostic_tab_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};

/** select columns of table "diagnostics_comments" */
export enum Diagnostics_Comments_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  DiagnosticTabId = 'diagnostic_tab_id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "diagnostics_comments" */
export type Diagnostics_Comments_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  diagnostic_tab_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "diagnostics_comments" */
export type Diagnostics_Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Diagnostics_Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Diagnostics_Comments_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  diagnostic_tab_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "diagnostics_comments" */
export enum Diagnostics_Comments_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  DiagnosticTabId = 'diagnostic_tab_id',
  /** column name */
  TeamId = 'team_id'
}

export type Diagnostics_Comments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Diagnostics_Comments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Diagnostics_Comments_Bool_Exp;
};

/** unique or primary key constraints on table "diagnostics" */
export enum Diagnostics_Constraint {
  /** unique or primary key constraint on columns "id" */
  DiagnosticsPkey = 'diagnostics_pkey'
}

/** columns and relationships of "diagnostics_groups" */
export type Diagnostics_Groups = {
  __typename?: 'diagnostics_groups';
  active: Scalars['Boolean'];
  /** An object relationship */
  diagnostic: Diagnostics;
  diagnostic_id: Scalars['uuid'];
  /** An object relationship */
  group: Groups;
  group_id: Scalars['uuid'];
};

/** aggregated selection of "diagnostics_groups" */
export type Diagnostics_Groups_Aggregate = {
  __typename?: 'diagnostics_groups_aggregate';
  aggregate?: Maybe<Diagnostics_Groups_Aggregate_Fields>;
  nodes: Array<Diagnostics_Groups>;
};

export type Diagnostics_Groups_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Diagnostics_Groups_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Diagnostics_Groups_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Diagnostics_Groups_Aggregate_Bool_Exp_Count>;
};

export type Diagnostics_Groups_Aggregate_Bool_Exp_Bool_And = {
  arguments: Diagnostics_Groups_Select_Column_Diagnostics_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Diagnostics_Groups_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Diagnostics_Groups_Select_Column_Diagnostics_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Diagnostics_Groups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "diagnostics_groups" */
export type Diagnostics_Groups_Aggregate_Fields = {
  __typename?: 'diagnostics_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Groups_Max_Fields>;
  min?: Maybe<Diagnostics_Groups_Min_Fields>;
};


/** aggregate fields of "diagnostics_groups" */
export type Diagnostics_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "diagnostics_groups" */
export type Diagnostics_Groups_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Diagnostics_Groups_Max_Order_By>;
  min?: InputMaybe<Diagnostics_Groups_Min_Order_By>;
};

/** input type for inserting array relation for remote table "diagnostics_groups" */
export type Diagnostics_Groups_Arr_Rel_Insert_Input = {
  data: Array<Diagnostics_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Groups_On_Conflict>;
};

/** Boolean expression to filter rows from the table "diagnostics_groups". All fields are combined with a logical 'AND'. */
export type Diagnostics_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Groups_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Groups_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  diagnostic?: InputMaybe<Diagnostics_Bool_Exp>;
  diagnostic_id?: InputMaybe<Uuid_Comparison_Exp>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_groups" */
export enum Diagnostics_Groups_Constraint {
  /** unique or primary key constraint on columns "active", "group_id" */
  DiagnosticsGroupsGroupIdActiveIdx = 'diagnostics_groups_group_id_active_idx',
  /** unique or primary key constraint on columns "diagnostic_id", "group_id" */
  DiagnosticsGroupsPkey = 'diagnostics_groups_pkey'
}

/** input type for inserting data into table "diagnostics_groups" */
export type Diagnostics_Groups_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  diagnostic?: InputMaybe<Diagnostics_Obj_Rel_Insert_Input>;
  diagnostic_id?: InputMaybe<Scalars['uuid']>;
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Diagnostics_Groups_Max_Fields = {
  __typename?: 'diagnostics_groups_max_fields';
  diagnostic_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "diagnostics_groups" */
export type Diagnostics_Groups_Max_Order_By = {
  diagnostic_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Diagnostics_Groups_Min_Fields = {
  __typename?: 'diagnostics_groups_min_fields';
  diagnostic_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "diagnostics_groups" */
export type Diagnostics_Groups_Min_Order_By = {
  diagnostic_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "diagnostics_groups" */
export type Diagnostics_Groups_Mutation_Response = {
  __typename?: 'diagnostics_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Groups>;
};

/** on_conflict condition type for table "diagnostics_groups" */
export type Diagnostics_Groups_On_Conflict = {
  constraint: Diagnostics_Groups_Constraint;
  update_columns?: Array<Diagnostics_Groups_Update_Column>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_groups". */
export type Diagnostics_Groups_Order_By = {
  active?: InputMaybe<Order_By>;
  diagnostic?: InputMaybe<Diagnostics_Order_By>;
  diagnostic_id?: InputMaybe<Order_By>;
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_groups */
export type Diagnostics_Groups_Pk_Columns_Input = {
  diagnostic_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};

/** select columns of table "diagnostics_groups" */
export enum Diagnostics_Groups_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  DiagnosticId = 'diagnostic_id',
  /** column name */
  GroupId = 'group_id'
}

/** select "diagnostics_groups_aggregate_bool_exp_bool_and_arguments_columns" columns of table "diagnostics_groups" */
export enum Diagnostics_Groups_Select_Column_Diagnostics_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "diagnostics_groups_aggregate_bool_exp_bool_or_arguments_columns" columns of table "diagnostics_groups" */
export enum Diagnostics_Groups_Select_Column_Diagnostics_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "diagnostics_groups" */
export type Diagnostics_Groups_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  diagnostic_id?: InputMaybe<Scalars['uuid']>;
  group_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "diagnostics_groups" */
export type Diagnostics_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Diagnostics_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Diagnostics_Groups_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  diagnostic_id?: InputMaybe<Scalars['uuid']>;
  group_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "diagnostics_groups" */
export enum Diagnostics_Groups_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  DiagnosticId = 'diagnostic_id',
  /** column name */
  GroupId = 'group_id'
}

export type Diagnostics_Groups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Diagnostics_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Diagnostics_Groups_Bool_Exp;
};

/** input type for inserting data into table "diagnostics" */
export type Diagnostics_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Diagnostics_Groups_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  tabs?: InputMaybe<Diagnostics_Tabs_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Diagnostics_Max_Fields = {
  __typename?: 'diagnostics_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Diagnostics_Min_Fields = {
  __typename?: 'diagnostics_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "diagnostics" */
export type Diagnostics_Mutation_Response = {
  __typename?: 'diagnostics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics>;
};

/** input type for inserting object relation for remote table "diagnostics" */
export type Diagnostics_Obj_Rel_Insert_Input = {
  data: Diagnostics_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_On_Conflict>;
};

/** on_conflict condition type for table "diagnostics" */
export type Diagnostics_On_Conflict = {
  constraint: Diagnostics_Constraint;
  update_columns?: Array<Diagnostics_Update_Column>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics". */
export type Diagnostics_Order_By = {
  description?: InputMaybe<Order_By>;
  groups_aggregate?: InputMaybe<Diagnostics_Groups_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  tabs_aggregate?: InputMaybe<Diagnostics_Tabs_Aggregate_Order_By>;
};

/** primary key columns input for table: diagnostics */
export type Diagnostics_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "diagnostics_questions" */
export type Diagnostics_Questions = {
  __typename?: 'diagnostics_questions';
  /** An array relationship */
  answers: Array<Diagnostics_Questions_Answers>;
  /** An aggregate relationship */
  answers_aggregate: Diagnostics_Questions_Answers_Aggregate;
  id: Scalars['uuid'];
  order: Scalars['smallint'];
  question: Scalars['String'];
  /** An object relationship */
  subsection: Diagnostics_Subsections;
  subsection_id: Scalars['uuid'];
};


/** columns and relationships of "diagnostics_questions" */
export type Diagnostics_QuestionsAnswersArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Answers_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};


/** columns and relationships of "diagnostics_questions" */
export type Diagnostics_QuestionsAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Answers_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};

/** aggregated selection of "diagnostics_questions" */
export type Diagnostics_Questions_Aggregate = {
  __typename?: 'diagnostics_questions_aggregate';
  aggregate?: Maybe<Diagnostics_Questions_Aggregate_Fields>;
  nodes: Array<Diagnostics_Questions>;
};

export type Diagnostics_Questions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Diagnostics_Questions_Aggregate_Bool_Exp_Count>;
};

export type Diagnostics_Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Diagnostics_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "diagnostics_questions" */
export type Diagnostics_Questions_Aggregate_Fields = {
  __typename?: 'diagnostics_questions_aggregate_fields';
  avg?: Maybe<Diagnostics_Questions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Questions_Max_Fields>;
  min?: Maybe<Diagnostics_Questions_Min_Fields>;
  stddev?: Maybe<Diagnostics_Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Diagnostics_Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Diagnostics_Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Diagnostics_Questions_Sum_Fields>;
  var_pop?: Maybe<Diagnostics_Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Diagnostics_Questions_Var_Samp_Fields>;
  variance?: Maybe<Diagnostics_Questions_Variance_Fields>;
};


/** aggregate fields of "diagnostics_questions" */
export type Diagnostics_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "diagnostics_questions" */
export type Diagnostics_Questions_Aggregate_Order_By = {
  avg?: InputMaybe<Diagnostics_Questions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Diagnostics_Questions_Max_Order_By>;
  min?: InputMaybe<Diagnostics_Questions_Min_Order_By>;
  stddev?: InputMaybe<Diagnostics_Questions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Diagnostics_Questions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Diagnostics_Questions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Diagnostics_Questions_Sum_Order_By>;
  var_pop?: InputMaybe<Diagnostics_Questions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Diagnostics_Questions_Var_Samp_Order_By>;
  variance?: InputMaybe<Diagnostics_Questions_Variance_Order_By>;
};

/** columns and relationships of "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers = {
  __typename?: 'diagnostics_questions_answers';
  answer: Scalars['smallint'];
  id: Scalars['uuid'];
  /** An object relationship */
  question: Diagnostics_Questions;
  question_id: Scalars['uuid'];
  /** An object relationship */
  tab: Diagnostics_Tabs;
  tab_id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};

/** aggregated selection of "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Aggregate = {
  __typename?: 'diagnostics_questions_answers_aggregate';
  aggregate?: Maybe<Diagnostics_Questions_Answers_Aggregate_Fields>;
  nodes: Array<Diagnostics_Questions_Answers>;
};

export type Diagnostics_Questions_Answers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Diagnostics_Questions_Answers_Aggregate_Bool_Exp_Count>;
};

export type Diagnostics_Questions_Answers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Aggregate_Fields = {
  __typename?: 'diagnostics_questions_answers_aggregate_fields';
  avg?: Maybe<Diagnostics_Questions_Answers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Questions_Answers_Max_Fields>;
  min?: Maybe<Diagnostics_Questions_Answers_Min_Fields>;
  stddev?: Maybe<Diagnostics_Questions_Answers_Stddev_Fields>;
  stddev_pop?: Maybe<Diagnostics_Questions_Answers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Diagnostics_Questions_Answers_Stddev_Samp_Fields>;
  sum?: Maybe<Diagnostics_Questions_Answers_Sum_Fields>;
  var_pop?: Maybe<Diagnostics_Questions_Answers_Var_Pop_Fields>;
  var_samp?: Maybe<Diagnostics_Questions_Answers_Var_Samp_Fields>;
  variance?: Maybe<Diagnostics_Questions_Answers_Variance_Fields>;
};


/** aggregate fields of "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Aggregate_Order_By = {
  avg?: InputMaybe<Diagnostics_Questions_Answers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Diagnostics_Questions_Answers_Max_Order_By>;
  min?: InputMaybe<Diagnostics_Questions_Answers_Min_Order_By>;
  stddev?: InputMaybe<Diagnostics_Questions_Answers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Diagnostics_Questions_Answers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Diagnostics_Questions_Answers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Diagnostics_Questions_Answers_Sum_Order_By>;
  var_pop?: InputMaybe<Diagnostics_Questions_Answers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Diagnostics_Questions_Answers_Var_Samp_Order_By>;
  variance?: InputMaybe<Diagnostics_Questions_Answers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Arr_Rel_Insert_Input = {
  data: Array<Diagnostics_Questions_Answers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Questions_Answers_On_Conflict>;
};

/** aggregate avg on columns */
export type Diagnostics_Questions_Answers_Avg_Fields = {
  __typename?: 'diagnostics_questions_answers_avg_fields';
  answer?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Avg_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "diagnostics_questions_answers". All fields are combined with a logical 'AND'. */
export type Diagnostics_Questions_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Questions_Answers_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Questions_Answers_Bool_Exp>>;
  answer?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  question?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
  question_id?: InputMaybe<Uuid_Comparison_Exp>;
  tab?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
  tab_id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_questions_answers" */
export enum Diagnostics_Questions_Answers_Constraint {
  /** unique or primary key constraint on columns "tab_id", "question_id", "team_id" */
  DiagnosticsQuestionsAnswersGroupIdQuestionIdTabIdKey = 'diagnostics_questions_answers_group_id_question_id_tab_id_key',
  /** unique or primary key constraint on columns "id" */
  DiagnosticsQuestionsAnswersPkey = 'diagnostics_questions_answers_pkey'
}

/** input type for incrementing numeric columns in table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Inc_Input = {
  answer?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Insert_Input = {
  answer?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Diagnostics_Questions_Obj_Rel_Insert_Input>;
  question_id?: InputMaybe<Scalars['uuid']>;
  tab?: InputMaybe<Diagnostics_Tabs_Obj_Rel_Insert_Input>;
  tab_id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Diagnostics_Questions_Answers_Max_Fields = {
  __typename?: 'diagnostics_questions_answers_max_fields';
  answer?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['uuid']>;
  question_id?: Maybe<Scalars['uuid']>;
  tab_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Max_Order_By = {
  answer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  tab_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Diagnostics_Questions_Answers_Min_Fields = {
  __typename?: 'diagnostics_questions_answers_min_fields';
  answer?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['uuid']>;
  question_id?: Maybe<Scalars['uuid']>;
  tab_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Min_Order_By = {
  answer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  tab_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Mutation_Response = {
  __typename?: 'diagnostics_questions_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Questions_Answers>;
};

/** on_conflict condition type for table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_On_Conflict = {
  constraint: Diagnostics_Questions_Answers_Constraint;
  update_columns?: Array<Diagnostics_Questions_Answers_Update_Column>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_questions_answers". */
export type Diagnostics_Questions_Answers_Order_By = {
  answer?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Diagnostics_Questions_Order_By>;
  question_id?: InputMaybe<Order_By>;
  tab?: InputMaybe<Diagnostics_Tabs_Order_By>;
  tab_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_questions_answers */
export type Diagnostics_Questions_Answers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "diagnostics_questions_answers" */
export enum Diagnostics_Questions_Answers_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  TabId = 'tab_id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Set_Input = {
  answer?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['uuid']>;
  question_id?: InputMaybe<Scalars['uuid']>;
  tab_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Diagnostics_Questions_Answers_Stddev_Fields = {
  __typename?: 'diagnostics_questions_answers_stddev_fields';
  answer?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Stddev_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Diagnostics_Questions_Answers_Stddev_Pop_Fields = {
  __typename?: 'diagnostics_questions_answers_stddev_pop_fields';
  answer?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Stddev_Pop_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Diagnostics_Questions_Answers_Stddev_Samp_Fields = {
  __typename?: 'diagnostics_questions_answers_stddev_samp_fields';
  answer?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Stddev_Samp_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Diagnostics_Questions_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Diagnostics_Questions_Answers_Stream_Cursor_Value_Input = {
  answer?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['uuid']>;
  question_id?: InputMaybe<Scalars['uuid']>;
  tab_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Diagnostics_Questions_Answers_Sum_Fields = {
  __typename?: 'diagnostics_questions_answers_sum_fields';
  answer?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Sum_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** update columns of table "diagnostics_questions_answers" */
export enum Diagnostics_Questions_Answers_Update_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  TabId = 'tab_id',
  /** column name */
  TeamId = 'team_id'
}

export type Diagnostics_Questions_Answers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Diagnostics_Questions_Answers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Diagnostics_Questions_Answers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Diagnostics_Questions_Answers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Diagnostics_Questions_Answers_Var_Pop_Fields = {
  __typename?: 'diagnostics_questions_answers_var_pop_fields';
  answer?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Var_Pop_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Diagnostics_Questions_Answers_Var_Samp_Fields = {
  __typename?: 'diagnostics_questions_answers_var_samp_fields';
  answer?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Var_Samp_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Diagnostics_Questions_Answers_Variance_Fields = {
  __typename?: 'diagnostics_questions_answers_variance_fields';
  answer?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "diagnostics_questions_answers" */
export type Diagnostics_Questions_Answers_Variance_Order_By = {
  answer?: InputMaybe<Order_By>;
};

/** input type for inserting array relation for remote table "diagnostics_questions" */
export type Diagnostics_Questions_Arr_Rel_Insert_Input = {
  data: Array<Diagnostics_Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Diagnostics_Questions_Avg_Fields = {
  __typename?: 'diagnostics_questions_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "diagnostics_questions". All fields are combined with a logical 'AND'. */
export type Diagnostics_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Questions_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Questions_Bool_Exp>>;
  answers?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
  answers_aggregate?: InputMaybe<Diagnostics_Questions_Answers_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Smallint_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  subsection?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
  subsection_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_questions" */
export enum Diagnostics_Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  DiagnosticsQuestionsPkey = 'diagnostics_questions_pkey',
  /** unique or primary key constraint on columns "subsection_id", "order" */
  DiagnosticsQuestionsSubsectionIdOrderKey = 'diagnostics_questions_subsection_id_order_key'
}

/** input type for incrementing numeric columns in table "diagnostics_questions" */
export type Diagnostics_Questions_Inc_Input = {
  order?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "diagnostics_questions" */
export type Diagnostics_Questions_Insert_Input = {
  answers?: InputMaybe<Diagnostics_Questions_Answers_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  question?: InputMaybe<Scalars['String']>;
  subsection?: InputMaybe<Diagnostics_Subsections_Obj_Rel_Insert_Input>;
  subsection_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Diagnostics_Questions_Max_Fields = {
  __typename?: 'diagnostics_questions_max_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  question?: Maybe<Scalars['String']>;
  subsection_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  subsection_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Diagnostics_Questions_Min_Fields = {
  __typename?: 'diagnostics_questions_min_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  question?: Maybe<Scalars['String']>;
  subsection_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  subsection_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "diagnostics_questions" */
export type Diagnostics_Questions_Mutation_Response = {
  __typename?: 'diagnostics_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Questions>;
};

/** input type for inserting object relation for remote table "diagnostics_questions" */
export type Diagnostics_Questions_Obj_Rel_Insert_Input = {
  data: Diagnostics_Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Questions_On_Conflict>;
};

/** on_conflict condition type for table "diagnostics_questions" */
export type Diagnostics_Questions_On_Conflict = {
  constraint: Diagnostics_Questions_Constraint;
  update_columns?: Array<Diagnostics_Questions_Update_Column>;
  where?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_questions". */
export type Diagnostics_Questions_Order_By = {
  answers_aggregate?: InputMaybe<Diagnostics_Questions_Answers_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  subsection?: InputMaybe<Diagnostics_Subsections_Order_By>;
  subsection_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_questions */
export type Diagnostics_Questions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "diagnostics_questions" */
export enum Diagnostics_Questions_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Question = 'question',
  /** column name */
  SubsectionId = 'subsection_id'
}

/** input type for updating data in table "diagnostics_questions" */
export type Diagnostics_Questions_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  question?: InputMaybe<Scalars['String']>;
  subsection_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Diagnostics_Questions_Stddev_Fields = {
  __typename?: 'diagnostics_questions_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Diagnostics_Questions_Stddev_Pop_Fields = {
  __typename?: 'diagnostics_questions_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Diagnostics_Questions_Stddev_Samp_Fields = {
  __typename?: 'diagnostics_questions_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "diagnostics_questions" */
export type Diagnostics_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Diagnostics_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Diagnostics_Questions_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  question?: InputMaybe<Scalars['String']>;
  subsection_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Diagnostics_Questions_Sum_Fields = {
  __typename?: 'diagnostics_questions_sum_fields';
  order?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "diagnostics_questions" */
export enum Diagnostics_Questions_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Question = 'question',
  /** column name */
  SubsectionId = 'subsection_id'
}

export type Diagnostics_Questions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Diagnostics_Questions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Diagnostics_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Diagnostics_Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Diagnostics_Questions_Var_Pop_Fields = {
  __typename?: 'diagnostics_questions_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Diagnostics_Questions_Var_Samp_Fields = {
  __typename?: 'diagnostics_questions_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Diagnostics_Questions_Variance_Fields = {
  __typename?: 'diagnostics_questions_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "diagnostics_questions" */
export type Diagnostics_Questions_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "diagnostics_sections" */
export type Diagnostics_Sections = {
  __typename?: 'diagnostics_sections';
  id: Scalars['uuid'];
  /** An array relationship */
  subsections: Array<Diagnostics_Subsections>;
  /** An aggregate relationship */
  subsections_aggregate: Diagnostics_Subsections_Aggregate;
  /** An array relationship */
  tabs: Array<Diagnostics_Tabs_Sections>;
  /** An aggregate relationship */
  tabs_aggregate: Diagnostics_Tabs_Sections_Aggregate;
  title: Scalars['String'];
};


/** columns and relationships of "diagnostics_sections" */
export type Diagnostics_SectionsSubsectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Subsections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Subsections_Order_By>>;
  where?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
};


/** columns and relationships of "diagnostics_sections" */
export type Diagnostics_SectionsSubsections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Subsections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Subsections_Order_By>>;
  where?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
};


/** columns and relationships of "diagnostics_sections" */
export type Diagnostics_SectionsTabsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};


/** columns and relationships of "diagnostics_sections" */
export type Diagnostics_SectionsTabs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};

/** aggregated selection of "diagnostics_sections" */
export type Diagnostics_Sections_Aggregate = {
  __typename?: 'diagnostics_sections_aggregate';
  aggregate?: Maybe<Diagnostics_Sections_Aggregate_Fields>;
  nodes: Array<Diagnostics_Sections>;
};

/** aggregate fields of "diagnostics_sections" */
export type Diagnostics_Sections_Aggregate_Fields = {
  __typename?: 'diagnostics_sections_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Sections_Max_Fields>;
  min?: Maybe<Diagnostics_Sections_Min_Fields>;
};


/** aggregate fields of "diagnostics_sections" */
export type Diagnostics_Sections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "diagnostics_sections". All fields are combined with a logical 'AND'. */
export type Diagnostics_Sections_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Sections_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Sections_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  subsections?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
  subsections_aggregate?: InputMaybe<Diagnostics_Subsections_Aggregate_Bool_Exp>;
  tabs?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
  tabs_aggregate?: InputMaybe<Diagnostics_Tabs_Sections_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_sections" */
export enum Diagnostics_Sections_Constraint {
  /** unique or primary key constraint on columns "id" */
  DiagnosticsSectionsPkey = 'diagnostics_sections_pkey'
}

/** input type for inserting data into table "diagnostics_sections" */
export type Diagnostics_Sections_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  subsections?: InputMaybe<Diagnostics_Subsections_Arr_Rel_Insert_Input>;
  tabs?: InputMaybe<Diagnostics_Tabs_Sections_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Diagnostics_Sections_Max_Fields = {
  __typename?: 'diagnostics_sections_max_fields';
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Diagnostics_Sections_Min_Fields = {
  __typename?: 'diagnostics_sections_min_fields';
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "diagnostics_sections" */
export type Diagnostics_Sections_Mutation_Response = {
  __typename?: 'diagnostics_sections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Sections>;
};

/** input type for inserting object relation for remote table "diagnostics_sections" */
export type Diagnostics_Sections_Obj_Rel_Insert_Input = {
  data: Diagnostics_Sections_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Sections_On_Conflict>;
};

/** on_conflict condition type for table "diagnostics_sections" */
export type Diagnostics_Sections_On_Conflict = {
  constraint: Diagnostics_Sections_Constraint;
  update_columns?: Array<Diagnostics_Sections_Update_Column>;
  where?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_sections". */
export type Diagnostics_Sections_Order_By = {
  id?: InputMaybe<Order_By>;
  subsections_aggregate?: InputMaybe<Diagnostics_Subsections_Aggregate_Order_By>;
  tabs_aggregate?: InputMaybe<Diagnostics_Tabs_Sections_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_sections */
export type Diagnostics_Sections_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "diagnostics_sections" */
export enum Diagnostics_Sections_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "diagnostics_sections" */
export type Diagnostics_Sections_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "diagnostics_sections" */
export type Diagnostics_Sections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Diagnostics_Sections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Diagnostics_Sections_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** update columns of table "diagnostics_sections" */
export enum Diagnostics_Sections_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

export type Diagnostics_Sections_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Diagnostics_Sections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Diagnostics_Sections_Bool_Exp;
};

/** select columns of table "diagnostics" */
export enum Diagnostics_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "diagnostics" */
export type Diagnostics_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "diagnostics" */
export type Diagnostics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Diagnostics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Diagnostics_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "diagnostics_subsections" */
export type Diagnostics_Subsections = {
  __typename?: 'diagnostics_subsections';
  id: Scalars['uuid'];
  order: Scalars['smallint'];
  /** An array relationship */
  questions: Array<Diagnostics_Questions>;
  /** An aggregate relationship */
  questions_aggregate: Diagnostics_Questions_Aggregate;
  /** An object relationship */
  section: Diagnostics_Sections;
  section_id: Scalars['uuid'];
  title: Scalars['String'];
};


/** columns and relationships of "diagnostics_subsections" */
export type Diagnostics_SubsectionsQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
};


/** columns and relationships of "diagnostics_subsections" */
export type Diagnostics_SubsectionsQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
};

/** aggregated selection of "diagnostics_subsections" */
export type Diagnostics_Subsections_Aggregate = {
  __typename?: 'diagnostics_subsections_aggregate';
  aggregate?: Maybe<Diagnostics_Subsections_Aggregate_Fields>;
  nodes: Array<Diagnostics_Subsections>;
};

export type Diagnostics_Subsections_Aggregate_Bool_Exp = {
  count?: InputMaybe<Diagnostics_Subsections_Aggregate_Bool_Exp_Count>;
};

export type Diagnostics_Subsections_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Diagnostics_Subsections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "diagnostics_subsections" */
export type Diagnostics_Subsections_Aggregate_Fields = {
  __typename?: 'diagnostics_subsections_aggregate_fields';
  avg?: Maybe<Diagnostics_Subsections_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Subsections_Max_Fields>;
  min?: Maybe<Diagnostics_Subsections_Min_Fields>;
  stddev?: Maybe<Diagnostics_Subsections_Stddev_Fields>;
  stddev_pop?: Maybe<Diagnostics_Subsections_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Diagnostics_Subsections_Stddev_Samp_Fields>;
  sum?: Maybe<Diagnostics_Subsections_Sum_Fields>;
  var_pop?: Maybe<Diagnostics_Subsections_Var_Pop_Fields>;
  var_samp?: Maybe<Diagnostics_Subsections_Var_Samp_Fields>;
  variance?: Maybe<Diagnostics_Subsections_Variance_Fields>;
};


/** aggregate fields of "diagnostics_subsections" */
export type Diagnostics_Subsections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Subsections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Aggregate_Order_By = {
  avg?: InputMaybe<Diagnostics_Subsections_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Diagnostics_Subsections_Max_Order_By>;
  min?: InputMaybe<Diagnostics_Subsections_Min_Order_By>;
  stddev?: InputMaybe<Diagnostics_Subsections_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Diagnostics_Subsections_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Diagnostics_Subsections_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Diagnostics_Subsections_Sum_Order_By>;
  var_pop?: InputMaybe<Diagnostics_Subsections_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Diagnostics_Subsections_Var_Samp_Order_By>;
  variance?: InputMaybe<Diagnostics_Subsections_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "diagnostics_subsections" */
export type Diagnostics_Subsections_Arr_Rel_Insert_Input = {
  data: Array<Diagnostics_Subsections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Subsections_On_Conflict>;
};

/** aggregate avg on columns */
export type Diagnostics_Subsections_Avg_Fields = {
  __typename?: 'diagnostics_subsections_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "diagnostics_subsections". All fields are combined with a logical 'AND'. */
export type Diagnostics_Subsections_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Subsections_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Subsections_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Smallint_Comparison_Exp>;
  questions?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
  questions_aggregate?: InputMaybe<Diagnostics_Questions_Aggregate_Bool_Exp>;
  section?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
  section_id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_subsections" */
export enum Diagnostics_Subsections_Constraint {
  /** unique or primary key constraint on columns "id" */
  DiagnosticsSubsectionsPkey = 'diagnostics_subsections_pkey',
  /** unique or primary key constraint on columns "section_id", "order" */
  DiagnosticsSubsectionsSectionIdOrderKey = 'diagnostics_subsections_section_id_order_key'
}

/** input type for incrementing numeric columns in table "diagnostics_subsections" */
export type Diagnostics_Subsections_Inc_Input = {
  order?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "diagnostics_subsections" */
export type Diagnostics_Subsections_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  questions?: InputMaybe<Diagnostics_Questions_Arr_Rel_Insert_Input>;
  section?: InputMaybe<Diagnostics_Sections_Obj_Rel_Insert_Input>;
  section_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Diagnostics_Subsections_Max_Fields = {
  __typename?: 'diagnostics_subsections_max_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  section_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Diagnostics_Subsections_Min_Fields = {
  __typename?: 'diagnostics_subsections_min_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  section_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "diagnostics_subsections" */
export type Diagnostics_Subsections_Mutation_Response = {
  __typename?: 'diagnostics_subsections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Subsections>;
};

/** input type for inserting object relation for remote table "diagnostics_subsections" */
export type Diagnostics_Subsections_Obj_Rel_Insert_Input = {
  data: Diagnostics_Subsections_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Subsections_On_Conflict>;
};

/** on_conflict condition type for table "diagnostics_subsections" */
export type Diagnostics_Subsections_On_Conflict = {
  constraint: Diagnostics_Subsections_Constraint;
  update_columns?: Array<Diagnostics_Subsections_Update_Column>;
  where?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_subsections". */
export type Diagnostics_Subsections_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  questions_aggregate?: InputMaybe<Diagnostics_Questions_Aggregate_Order_By>;
  section?: InputMaybe<Diagnostics_Sections_Order_By>;
  section_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_subsections */
export type Diagnostics_Subsections_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "diagnostics_subsections" */
export enum Diagnostics_Subsections_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  SectionId = 'section_id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "diagnostics_subsections" */
export type Diagnostics_Subsections_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  section_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Diagnostics_Subsections_Stddev_Fields = {
  __typename?: 'diagnostics_subsections_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Diagnostics_Subsections_Stddev_Pop_Fields = {
  __typename?: 'diagnostics_subsections_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Diagnostics_Subsections_Stddev_Samp_Fields = {
  __typename?: 'diagnostics_subsections_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "diagnostics_subsections" */
export type Diagnostics_Subsections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Diagnostics_Subsections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Diagnostics_Subsections_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  section_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Diagnostics_Subsections_Sum_Fields = {
  __typename?: 'diagnostics_subsections_sum_fields';
  order?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "diagnostics_subsections" */
export enum Diagnostics_Subsections_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  SectionId = 'section_id',
  /** column name */
  Title = 'title'
}

export type Diagnostics_Subsections_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Diagnostics_Subsections_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Diagnostics_Subsections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Diagnostics_Subsections_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Diagnostics_Subsections_Var_Pop_Fields = {
  __typename?: 'diagnostics_subsections_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Diagnostics_Subsections_Var_Samp_Fields = {
  __typename?: 'diagnostics_subsections_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Diagnostics_Subsections_Variance_Fields = {
  __typename?: 'diagnostics_subsections_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "diagnostics_subsections" */
export type Diagnostics_Subsections_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "diagnostics_tabs" */
export type Diagnostics_Tabs = {
  __typename?: 'diagnostics_tabs';
  /** An array relationship */
  comments: Array<Diagnostics_Comments>;
  /** An aggregate relationship */
  comments_aggregate: Diagnostics_Comments_Aggregate;
  /** An object relationship */
  diagnostic: Diagnostics;
  diagnostic_id: Scalars['uuid'];
  id: Scalars['uuid'];
  order: Scalars['smallint'];
  /** An array relationship */
  sections: Array<Diagnostics_Tabs_Sections>;
  /** An aggregate relationship */
  sections_aggregate: Diagnostics_Tabs_Sections_Aggregate;
  title: Scalars['String'];
};


/** columns and relationships of "diagnostics_tabs" */
export type Diagnostics_TabsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Comments_Order_By>>;
  where?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
};


/** columns and relationships of "diagnostics_tabs" */
export type Diagnostics_TabsComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Comments_Order_By>>;
  where?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
};


/** columns and relationships of "diagnostics_tabs" */
export type Diagnostics_TabsSectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};


/** columns and relationships of "diagnostics_tabs" */
export type Diagnostics_TabsSections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};

/** aggregated selection of "diagnostics_tabs" */
export type Diagnostics_Tabs_Aggregate = {
  __typename?: 'diagnostics_tabs_aggregate';
  aggregate?: Maybe<Diagnostics_Tabs_Aggregate_Fields>;
  nodes: Array<Diagnostics_Tabs>;
};

export type Diagnostics_Tabs_Aggregate_Bool_Exp = {
  count?: InputMaybe<Diagnostics_Tabs_Aggregate_Bool_Exp_Count>;
};

export type Diagnostics_Tabs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Diagnostics_Tabs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "diagnostics_tabs" */
export type Diagnostics_Tabs_Aggregate_Fields = {
  __typename?: 'diagnostics_tabs_aggregate_fields';
  avg?: Maybe<Diagnostics_Tabs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Tabs_Max_Fields>;
  min?: Maybe<Diagnostics_Tabs_Min_Fields>;
  stddev?: Maybe<Diagnostics_Tabs_Stddev_Fields>;
  stddev_pop?: Maybe<Diagnostics_Tabs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Diagnostics_Tabs_Stddev_Samp_Fields>;
  sum?: Maybe<Diagnostics_Tabs_Sum_Fields>;
  var_pop?: Maybe<Diagnostics_Tabs_Var_Pop_Fields>;
  var_samp?: Maybe<Diagnostics_Tabs_Var_Samp_Fields>;
  variance?: Maybe<Diagnostics_Tabs_Variance_Fields>;
};


/** aggregate fields of "diagnostics_tabs" */
export type Diagnostics_Tabs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Tabs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Aggregate_Order_By = {
  avg?: InputMaybe<Diagnostics_Tabs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Diagnostics_Tabs_Max_Order_By>;
  min?: InputMaybe<Diagnostics_Tabs_Min_Order_By>;
  stddev?: InputMaybe<Diagnostics_Tabs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Diagnostics_Tabs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Diagnostics_Tabs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Diagnostics_Tabs_Sum_Order_By>;
  var_pop?: InputMaybe<Diagnostics_Tabs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Diagnostics_Tabs_Var_Samp_Order_By>;
  variance?: InputMaybe<Diagnostics_Tabs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "diagnostics_tabs" */
export type Diagnostics_Tabs_Arr_Rel_Insert_Input = {
  data: Array<Diagnostics_Tabs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Tabs_On_Conflict>;
};

/** aggregate avg on columns */
export type Diagnostics_Tabs_Avg_Fields = {
  __typename?: 'diagnostics_tabs_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "diagnostics_tabs". All fields are combined with a logical 'AND'. */
export type Diagnostics_Tabs_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Tabs_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Tabs_Bool_Exp>>;
  comments?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
  comments_aggregate?: InputMaybe<Diagnostics_Comments_Aggregate_Bool_Exp>;
  diagnostic?: InputMaybe<Diagnostics_Bool_Exp>;
  diagnostic_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Smallint_Comparison_Exp>;
  sections?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
  sections_aggregate?: InputMaybe<Diagnostics_Tabs_Sections_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_tabs" */
export enum Diagnostics_Tabs_Constraint {
  /** unique or primary key constraint on columns "diagnostic_id", "order" */
  DiagnosticsTabsOrderDiagnosticIdKey = 'diagnostics_tabs_order_diagnostic_id_key',
  /** unique or primary key constraint on columns "id" */
  DiagnosticsTabsPkey = 'diagnostics_tabs_pkey'
}

/** input type for incrementing numeric columns in table "diagnostics_tabs" */
export type Diagnostics_Tabs_Inc_Input = {
  order?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "diagnostics_tabs" */
export type Diagnostics_Tabs_Insert_Input = {
  comments?: InputMaybe<Diagnostics_Comments_Arr_Rel_Insert_Input>;
  diagnostic?: InputMaybe<Diagnostics_Obj_Rel_Insert_Input>;
  diagnostic_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  sections?: InputMaybe<Diagnostics_Tabs_Sections_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Diagnostics_Tabs_Max_Fields = {
  __typename?: 'diagnostics_tabs_max_fields';
  diagnostic_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Max_Order_By = {
  diagnostic_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Diagnostics_Tabs_Min_Fields = {
  __typename?: 'diagnostics_tabs_min_fields';
  diagnostic_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Min_Order_By = {
  diagnostic_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "diagnostics_tabs" */
export type Diagnostics_Tabs_Mutation_Response = {
  __typename?: 'diagnostics_tabs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Tabs>;
};

/** input type for inserting object relation for remote table "diagnostics_tabs" */
export type Diagnostics_Tabs_Obj_Rel_Insert_Input = {
  data: Diagnostics_Tabs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Tabs_On_Conflict>;
};

/** on_conflict condition type for table "diagnostics_tabs" */
export type Diagnostics_Tabs_On_Conflict = {
  constraint: Diagnostics_Tabs_Constraint;
  update_columns?: Array<Diagnostics_Tabs_Update_Column>;
  where?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_tabs". */
export type Diagnostics_Tabs_Order_By = {
  comments_aggregate?: InputMaybe<Diagnostics_Comments_Aggregate_Order_By>;
  diagnostic?: InputMaybe<Diagnostics_Order_By>;
  diagnostic_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  sections_aggregate?: InputMaybe<Diagnostics_Tabs_Sections_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_tabs */
export type Diagnostics_Tabs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections = {
  __typename?: 'diagnostics_tabs_sections';
  id: Scalars['uuid'];
  order: Scalars['smallint'];
  /** An object relationship */
  section: Diagnostics_Sections;
  section_id: Scalars['uuid'];
  /** An object relationship */
  tab: Diagnostics_Tabs;
  tab_id: Scalars['uuid'];
};

/** aggregated selection of "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Aggregate = {
  __typename?: 'diagnostics_tabs_sections_aggregate';
  aggregate?: Maybe<Diagnostics_Tabs_Sections_Aggregate_Fields>;
  nodes: Array<Diagnostics_Tabs_Sections>;
};

export type Diagnostics_Tabs_Sections_Aggregate_Bool_Exp = {
  count?: InputMaybe<Diagnostics_Tabs_Sections_Aggregate_Bool_Exp_Count>;
};

export type Diagnostics_Tabs_Sections_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Aggregate_Fields = {
  __typename?: 'diagnostics_tabs_sections_aggregate_fields';
  avg?: Maybe<Diagnostics_Tabs_Sections_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Diagnostics_Tabs_Sections_Max_Fields>;
  min?: Maybe<Diagnostics_Tabs_Sections_Min_Fields>;
  stddev?: Maybe<Diagnostics_Tabs_Sections_Stddev_Fields>;
  stddev_pop?: Maybe<Diagnostics_Tabs_Sections_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Diagnostics_Tabs_Sections_Stddev_Samp_Fields>;
  sum?: Maybe<Diagnostics_Tabs_Sections_Sum_Fields>;
  var_pop?: Maybe<Diagnostics_Tabs_Sections_Var_Pop_Fields>;
  var_samp?: Maybe<Diagnostics_Tabs_Sections_Var_Samp_Fields>;
  variance?: Maybe<Diagnostics_Tabs_Sections_Variance_Fields>;
};


/** aggregate fields of "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Aggregate_Order_By = {
  avg?: InputMaybe<Diagnostics_Tabs_Sections_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Diagnostics_Tabs_Sections_Max_Order_By>;
  min?: InputMaybe<Diagnostics_Tabs_Sections_Min_Order_By>;
  stddev?: InputMaybe<Diagnostics_Tabs_Sections_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Diagnostics_Tabs_Sections_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Diagnostics_Tabs_Sections_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Diagnostics_Tabs_Sections_Sum_Order_By>;
  var_pop?: InputMaybe<Diagnostics_Tabs_Sections_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Diagnostics_Tabs_Sections_Var_Samp_Order_By>;
  variance?: InputMaybe<Diagnostics_Tabs_Sections_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Arr_Rel_Insert_Input = {
  data: Array<Diagnostics_Tabs_Sections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Diagnostics_Tabs_Sections_On_Conflict>;
};

/** aggregate avg on columns */
export type Diagnostics_Tabs_Sections_Avg_Fields = {
  __typename?: 'diagnostics_tabs_sections_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "diagnostics_tabs_sections". All fields are combined with a logical 'AND'. */
export type Diagnostics_Tabs_Sections_Bool_Exp = {
  _and?: InputMaybe<Array<Diagnostics_Tabs_Sections_Bool_Exp>>;
  _not?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
  _or?: InputMaybe<Array<Diagnostics_Tabs_Sections_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Smallint_Comparison_Exp>;
  section?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
  section_id?: InputMaybe<Uuid_Comparison_Exp>;
  tab?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
  tab_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "diagnostics_tabs_sections" */
export enum Diagnostics_Tabs_Sections_Constraint {
  /** unique or primary key constraint on columns "id" */
  DiagnosticsTabsSectionsPkey = 'diagnostics_tabs_sections_pkey',
  /** unique or primary key constraint on columns "tab_id", "section_id" */
  DiagnosticsTabsSectionsTabIdSectionIdKey = 'diagnostics_tabs_sections_tab_id_section_id_key',
  /** unique or primary key constraint on columns "tab_id", "section_id", "order" */
  DiagnosticsTabsSectionsTabIdSectionIdOrderKey = 'diagnostics_tabs_sections_tab_id_section_id_order_key'
}

/** input type for incrementing numeric columns in table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Inc_Input = {
  order?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  section?: InputMaybe<Diagnostics_Sections_Obj_Rel_Insert_Input>;
  section_id?: InputMaybe<Scalars['uuid']>;
  tab?: InputMaybe<Diagnostics_Tabs_Obj_Rel_Insert_Input>;
  tab_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Diagnostics_Tabs_Sections_Max_Fields = {
  __typename?: 'diagnostics_tabs_sections_max_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  section_id?: Maybe<Scalars['uuid']>;
  tab_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
  tab_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Diagnostics_Tabs_Sections_Min_Fields = {
  __typename?: 'diagnostics_tabs_sections_min_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  section_id?: Maybe<Scalars['uuid']>;
  tab_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
  tab_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Mutation_Response = {
  __typename?: 'diagnostics_tabs_sections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Diagnostics_Tabs_Sections>;
};

/** on_conflict condition type for table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_On_Conflict = {
  constraint: Diagnostics_Tabs_Sections_Constraint;
  update_columns?: Array<Diagnostics_Tabs_Sections_Update_Column>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};

/** Ordering options when selecting data from "diagnostics_tabs_sections". */
export type Diagnostics_Tabs_Sections_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  section?: InputMaybe<Diagnostics_Sections_Order_By>;
  section_id?: InputMaybe<Order_By>;
  tab?: InputMaybe<Diagnostics_Tabs_Order_By>;
  tab_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: diagnostics_tabs_sections */
export type Diagnostics_Tabs_Sections_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "diagnostics_tabs_sections" */
export enum Diagnostics_Tabs_Sections_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  SectionId = 'section_id',
  /** column name */
  TabId = 'tab_id'
}

/** input type for updating data in table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  section_id?: InputMaybe<Scalars['uuid']>;
  tab_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Diagnostics_Tabs_Sections_Stddev_Fields = {
  __typename?: 'diagnostics_tabs_sections_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Diagnostics_Tabs_Sections_Stddev_Pop_Fields = {
  __typename?: 'diagnostics_tabs_sections_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Diagnostics_Tabs_Sections_Stddev_Samp_Fields = {
  __typename?: 'diagnostics_tabs_sections_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Diagnostics_Tabs_Sections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Diagnostics_Tabs_Sections_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  section_id?: InputMaybe<Scalars['uuid']>;
  tab_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Diagnostics_Tabs_Sections_Sum_Fields = {
  __typename?: 'diagnostics_tabs_sections_sum_fields';
  order?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "diagnostics_tabs_sections" */
export enum Diagnostics_Tabs_Sections_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  SectionId = 'section_id',
  /** column name */
  TabId = 'tab_id'
}

export type Diagnostics_Tabs_Sections_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Diagnostics_Tabs_Sections_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Diagnostics_Tabs_Sections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Diagnostics_Tabs_Sections_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Diagnostics_Tabs_Sections_Var_Pop_Fields = {
  __typename?: 'diagnostics_tabs_sections_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Diagnostics_Tabs_Sections_Var_Samp_Fields = {
  __typename?: 'diagnostics_tabs_sections_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Diagnostics_Tabs_Sections_Variance_Fields = {
  __typename?: 'diagnostics_tabs_sections_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "diagnostics_tabs_sections" */
export type Diagnostics_Tabs_Sections_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** select columns of table "diagnostics_tabs" */
export enum Diagnostics_Tabs_Select_Column {
  /** column name */
  DiagnosticId = 'diagnostic_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "diagnostics_tabs" */
export type Diagnostics_Tabs_Set_Input = {
  diagnostic_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Diagnostics_Tabs_Stddev_Fields = {
  __typename?: 'diagnostics_tabs_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Diagnostics_Tabs_Stddev_Pop_Fields = {
  __typename?: 'diagnostics_tabs_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Diagnostics_Tabs_Stddev_Samp_Fields = {
  __typename?: 'diagnostics_tabs_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "diagnostics_tabs" */
export type Diagnostics_Tabs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Diagnostics_Tabs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Diagnostics_Tabs_Stream_Cursor_Value_Input = {
  diagnostic_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Diagnostics_Tabs_Sum_Fields = {
  __typename?: 'diagnostics_tabs_sum_fields';
  order?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "diagnostics_tabs" */
export enum Diagnostics_Tabs_Update_Column {
  /** column name */
  DiagnosticId = 'diagnostic_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Title = 'title'
}

export type Diagnostics_Tabs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Diagnostics_Tabs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Diagnostics_Tabs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Diagnostics_Tabs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Diagnostics_Tabs_Var_Pop_Fields = {
  __typename?: 'diagnostics_tabs_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Diagnostics_Tabs_Var_Samp_Fields = {
  __typename?: 'diagnostics_tabs_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Diagnostics_Tabs_Variance_Fields = {
  __typename?: 'diagnostics_tabs_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "diagnostics_tabs" */
export type Diagnostics_Tabs_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "diagnostics" */
export enum Diagnostics_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

export type Diagnostics_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Diagnostics_Set_Input>;
  /** filter the rows which have to be updated */
  where: Diagnostics_Bool_Exp;
};

/** columns and relationships of "files" */
export type Files = {
  __typename?: 'files';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  path: Scalars['String'];
};

/** aggregated selection of "files" */
export type Files_Aggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<Files_Aggregate_Fields>;
  nodes: Array<Files>;
};

/** aggregate fields of "files" */
export type Files_Aggregate_Fields = {
  __typename?: 'files_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Files_Max_Fields>;
  min?: Maybe<Files_Min_Fields>;
};


/** aggregate fields of "files" */
export type Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: InputMaybe<Array<Files_Bool_Exp>>;
  _not?: InputMaybe<Files_Bool_Exp>;
  _or?: InputMaybe<Array<Files_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "files" */
export enum Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilesPkey = 'files_pkey'
}

/** input type for inserting data into table "files" */
export type Files_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  path?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Files_Max_Fields = {
  __typename?: 'files_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  path?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Files_Min_Fields = {
  __typename?: 'files_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  path?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "files" */
export type Files_Mutation_Response = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "files" */
export type Files_Obj_Rel_Insert_Input = {
  data: Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** on_conflict condition type for table "files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns?: Array<Files_Update_Column>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** Ordering options when selecting data from "files". */
export type Files_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
};

/** primary key columns input for table: files */
export type Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "files" */
export enum Files_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Path = 'path'
}

/** input type for updating data in table "files" */
export type Files_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  path?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "files" */
export type Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  path?: InputMaybe<Scalars['String']>;
};

/** update columns of table "files" */
export enum Files_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Path = 'path'
}

export type Files_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Files_Bool_Exp;
};

/** columns and relationships of "groups" */
export type Groups = {
  __typename?: 'groups';
  /** An array relationship */
  contents: Array<Groups_Contents>;
  /** An aggregate relationship */
  contents_aggregate: Groups_Contents_Aggregate;
  /** An array relationship */
  diagnostics: Array<Diagnostics_Groups>;
  /** An aggregate relationship */
  diagnostics_aggregate: Diagnostics_Groups_Aggregate;
  email: Scalars['String'];
  end_date: Scalars['date'];
  /** An array relationship */
  flags: Array<Groups_Group_Flags>;
  /** An aggregate relationship */
  flags_aggregate: Groups_Group_Flags_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['uuid'];
  methodology: Metodologies_Enum;
  /** An array relationship */
  names: Array<Groups_Names>;
  /** An aggregate relationship */
  names_aggregate: Groups_Names_Aggregate;
  start_date: Scalars['date'];
  /** An array relationship */
  teams: Array<Groups_Teams>;
  /** An aggregate relationship */
  teams_aggregate: Groups_Teams_Aggregate;
  url?: Maybe<Scalars['String']>;
};


/** columns and relationships of "groups" */
export type GroupsContentsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsContents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsDiagnosticsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Groups_Order_By>>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsDiagnostics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Groups_Order_By>>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsFlagsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Group_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Group_Flags_Order_By>>;
  where?: InputMaybe<Groups_Group_Flags_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsFlags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Group_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Group_Flags_Order_By>>;
  where?: InputMaybe<Groups_Group_Flags_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsNamesArgs = {
  distinct_on?: InputMaybe<Array<Groups_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Names_Order_By>>;
  where?: InputMaybe<Groups_Names_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsNames_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Names_Order_By>>;
  where?: InputMaybe<Groups_Names_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsTeamsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};

/** aggregated selection of "groups" */
export type Groups_Aggregate = {
  __typename?: 'groups_aggregate';
  aggregate?: Maybe<Groups_Aggregate_Fields>;
  nodes: Array<Groups>;
};

export type Groups_Aggregate_Bool_Exp = {
  count?: InputMaybe<Groups_Aggregate_Bool_Exp_Count>;
};

export type Groups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "groups" */
export type Groups_Aggregate_Fields = {
  __typename?: 'groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Groups_Max_Fields>;
  min?: Maybe<Groups_Min_Fields>;
};


/** aggregate fields of "groups" */
export type Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "groups" */
export type Groups_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Groups_Max_Order_By>;
  min?: InputMaybe<Groups_Min_Order_By>;
};

/** input type for inserting array relation for remote table "groups" */
export type Groups_Arr_Rel_Insert_Input = {
  data: Array<Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** Boolean expression to filter rows from the table "groups". All fields are combined with a logical 'AND'. */
export type Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Groups_Bool_Exp>>;
  _not?: InputMaybe<Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Groups_Bool_Exp>>;
  contents?: InputMaybe<Groups_Contents_Bool_Exp>;
  contents_aggregate?: InputMaybe<Groups_Contents_Aggregate_Bool_Exp>;
  diagnostics?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
  diagnostics_aggregate?: InputMaybe<Diagnostics_Groups_Aggregate_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  flags?: InputMaybe<Groups_Group_Flags_Bool_Exp>;
  flags_aggregate?: InputMaybe<Groups_Group_Flags_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Locations_Bool_Exp>;
  location_id?: InputMaybe<Uuid_Comparison_Exp>;
  methodology?: InputMaybe<Metodologies_Enum_Comparison_Exp>;
  names?: InputMaybe<Groups_Names_Bool_Exp>;
  names_aggregate?: InputMaybe<Groups_Names_Aggregate_Bool_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  teams?: InputMaybe<Groups_Teams_Bool_Exp>;
  teams_aggregate?: InputMaybe<Groups_Teams_Aggregate_Bool_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups" */
export enum Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupsPkey = 'groups_pkey'
}

/** columns and relationships of "groups_contents" */
export type Groups_Contents = {
  __typename?: 'groups_contents';
  active: Scalars['Boolean'];
  /** An object relationship */
  content: Contents;
  content_id: Scalars['uuid'];
  /** An object relationship */
  group: Groups;
  group_id: Scalars['uuid'];
};

/** aggregated selection of "groups_contents" */
export type Groups_Contents_Aggregate = {
  __typename?: 'groups_contents_aggregate';
  aggregate?: Maybe<Groups_Contents_Aggregate_Fields>;
  nodes: Array<Groups_Contents>;
};

export type Groups_Contents_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Groups_Contents_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Groups_Contents_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Groups_Contents_Aggregate_Bool_Exp_Count>;
};

export type Groups_Contents_Aggregate_Bool_Exp_Bool_And = {
  arguments: Groups_Contents_Select_Column_Groups_Contents_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Groups_Contents_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Groups_Contents_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Groups_Contents_Select_Column_Groups_Contents_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Groups_Contents_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Groups_Contents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Groups_Contents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "groups_contents" */
export type Groups_Contents_Aggregate_Fields = {
  __typename?: 'groups_contents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Groups_Contents_Max_Fields>;
  min?: Maybe<Groups_Contents_Min_Fields>;
};


/** aggregate fields of "groups_contents" */
export type Groups_Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "groups_contents" */
export type Groups_Contents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Groups_Contents_Max_Order_By>;
  min?: InputMaybe<Groups_Contents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "groups_contents" */
export type Groups_Contents_Arr_Rel_Insert_Input = {
  data: Array<Groups_Contents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_Contents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "groups_contents". All fields are combined with a logical 'AND'. */
export type Groups_Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Groups_Contents_Bool_Exp>>;
  _not?: InputMaybe<Groups_Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Groups_Contents_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Uuid_Comparison_Exp>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups_contents" */
export enum Groups_Contents_Constraint {
  /** unique or primary key constraint on columns "active", "group_id" */
  GroupsContentsGroupIdActiveIdx = 'groups_contents_group_id_active_idx',
  /** unique or primary key constraint on columns "content_id", "group_id" */
  GroupsContentsGroupIdContentIdKey = 'groups_contents_group_id_content_id_key',
  /** unique or primary key constraint on columns "content_id", "group_id" */
  GroupsContentsPkey = 'groups_contents_pkey'
}

/** input type for inserting data into table "groups_contents" */
export type Groups_Contents_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['uuid']>;
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Groups_Contents_Max_Fields = {
  __typename?: 'groups_contents_max_fields';
  content_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "groups_contents" */
export type Groups_Contents_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Groups_Contents_Min_Fields = {
  __typename?: 'groups_contents_min_fields';
  content_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "groups_contents" */
export type Groups_Contents_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "groups_contents" */
export type Groups_Contents_Mutation_Response = {
  __typename?: 'groups_contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups_Contents>;
};

/** on_conflict condition type for table "groups_contents" */
export type Groups_Contents_On_Conflict = {
  constraint: Groups_Contents_Constraint;
  update_columns?: Array<Groups_Contents_Update_Column>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "groups_contents". */
export type Groups_Contents_Order_By = {
  active?: InputMaybe<Order_By>;
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: groups_contents */
export type Groups_Contents_Pk_Columns_Input = {
  content_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};

/** select columns of table "groups_contents" */
export enum Groups_Contents_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ContentId = 'content_id',
  /** column name */
  GroupId = 'group_id'
}

/** select "groups_contents_aggregate_bool_exp_bool_and_arguments_columns" columns of table "groups_contents" */
export enum Groups_Contents_Select_Column_Groups_Contents_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "groups_contents_aggregate_bool_exp_bool_or_arguments_columns" columns of table "groups_contents" */
export enum Groups_Contents_Select_Column_Groups_Contents_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "groups_contents" */
export type Groups_Contents_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  content_id?: InputMaybe<Scalars['uuid']>;
  group_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "groups_contents" */
export type Groups_Contents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Groups_Contents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Groups_Contents_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  content_id?: InputMaybe<Scalars['uuid']>;
  group_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "groups_contents" */
export enum Groups_Contents_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ContentId = 'content_id',
  /** column name */
  GroupId = 'group_id'
}

export type Groups_Contents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Groups_Contents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Groups_Contents_Bool_Exp;
};

/** columns and relationships of "groups_flags" */
export type Groups_Flags = {
  __typename?: 'groups_flags';
  name: Scalars['String'];
};

/** aggregated selection of "groups_flags" */
export type Groups_Flags_Aggregate = {
  __typename?: 'groups_flags_aggregate';
  aggregate?: Maybe<Groups_Flags_Aggregate_Fields>;
  nodes: Array<Groups_Flags>;
};

/** aggregate fields of "groups_flags" */
export type Groups_Flags_Aggregate_Fields = {
  __typename?: 'groups_flags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Groups_Flags_Max_Fields>;
  min?: Maybe<Groups_Flags_Min_Fields>;
};


/** aggregate fields of "groups_flags" */
export type Groups_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Groups_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "groups_flags". All fields are combined with a logical 'AND'. */
export type Groups_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Groups_Flags_Bool_Exp>>;
  _not?: InputMaybe<Groups_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Groups_Flags_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups_flags" */
export enum Groups_Flags_Constraint {
  /** unique or primary key constraint on columns "name" */
  GroupsFlagsPkey = 'groups_flags_pkey'
}

export enum Groups_Flags_Enum {
  DiagnosticsEnabled = 'diagnostics_enabled',
  GamificationNoCost = 'gamification_no_cost',
  GamificationV1 = 'gamification_v1',
  GamificationV1Mixed = 'gamification_v1_mixed',
  GamificationV2 = 'gamification_v2',
  GamificationV2Mixed = 'gamification_v2_mixed'
}

/** Boolean expression to compare columns of type "groups_flags_enum". All fields are combined with logical 'AND'. */
export type Groups_Flags_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Groups_Flags_Enum>;
  _in?: InputMaybe<Array<Groups_Flags_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Groups_Flags_Enum>;
  _nin?: InputMaybe<Array<Groups_Flags_Enum>>;
};

/** input type for inserting data into table "groups_flags" */
export type Groups_Flags_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Groups_Flags_Max_Fields = {
  __typename?: 'groups_flags_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Groups_Flags_Min_Fields = {
  __typename?: 'groups_flags_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "groups_flags" */
export type Groups_Flags_Mutation_Response = {
  __typename?: 'groups_flags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups_Flags>;
};

/** on_conflict condition type for table "groups_flags" */
export type Groups_Flags_On_Conflict = {
  constraint: Groups_Flags_Constraint;
  update_columns?: Array<Groups_Flags_Update_Column>;
  where?: InputMaybe<Groups_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "groups_flags". */
export type Groups_Flags_Order_By = {
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: groups_flags */
export type Groups_Flags_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "groups_flags" */
export enum Groups_Flags_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "groups_flags" */
export type Groups_Flags_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "groups_flags" */
export type Groups_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Groups_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Groups_Flags_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "groups_flags" */
export enum Groups_Flags_Update_Column {
  /** column name */
  Name = 'name'
}

export type Groups_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Groups_Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Groups_Flags_Bool_Exp;
};

/** columns and relationships of "groups_group_flags" */
export type Groups_Group_Flags = {
  __typename?: 'groups_group_flags';
  flag: Groups_Flags_Enum;
  /** An object relationship */
  group: Groups;
  group_id: Scalars['uuid'];
};

/** aggregated selection of "groups_group_flags" */
export type Groups_Group_Flags_Aggregate = {
  __typename?: 'groups_group_flags_aggregate';
  aggregate?: Maybe<Groups_Group_Flags_Aggregate_Fields>;
  nodes: Array<Groups_Group_Flags>;
};

export type Groups_Group_Flags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Groups_Group_Flags_Aggregate_Bool_Exp_Count>;
};

export type Groups_Group_Flags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Groups_Group_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Groups_Group_Flags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "groups_group_flags" */
export type Groups_Group_Flags_Aggregate_Fields = {
  __typename?: 'groups_group_flags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Groups_Group_Flags_Max_Fields>;
  min?: Maybe<Groups_Group_Flags_Min_Fields>;
};


/** aggregate fields of "groups_group_flags" */
export type Groups_Group_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Groups_Group_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "groups_group_flags" */
export type Groups_Group_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Groups_Group_Flags_Max_Order_By>;
  min?: InputMaybe<Groups_Group_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "groups_group_flags" */
export type Groups_Group_Flags_Arr_Rel_Insert_Input = {
  data: Array<Groups_Group_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_Group_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "groups_group_flags". All fields are combined with a logical 'AND'. */
export type Groups_Group_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Groups_Group_Flags_Bool_Exp>>;
  _not?: InputMaybe<Groups_Group_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Groups_Group_Flags_Bool_Exp>>;
  flag?: InputMaybe<Groups_Flags_Enum_Comparison_Exp>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups_group_flags" */
export enum Groups_Group_Flags_Constraint {
  /** unique or primary key constraint on columns "flag", "group_id" */
  GroupsGroupFlagsPkey = 'groups_group_flags_pkey'
}

/** input type for inserting data into table "groups_group_flags" */
export type Groups_Group_Flags_Insert_Input = {
  flag?: InputMaybe<Groups_Flags_Enum>;
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Groups_Group_Flags_Max_Fields = {
  __typename?: 'groups_group_flags_max_fields';
  group_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "groups_group_flags" */
export type Groups_Group_Flags_Max_Order_By = {
  group_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Groups_Group_Flags_Min_Fields = {
  __typename?: 'groups_group_flags_min_fields';
  group_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "groups_group_flags" */
export type Groups_Group_Flags_Min_Order_By = {
  group_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "groups_group_flags" */
export type Groups_Group_Flags_Mutation_Response = {
  __typename?: 'groups_group_flags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups_Group_Flags>;
};

/** on_conflict condition type for table "groups_group_flags" */
export type Groups_Group_Flags_On_Conflict = {
  constraint: Groups_Group_Flags_Constraint;
  update_columns?: Array<Groups_Group_Flags_Update_Column>;
  where?: InputMaybe<Groups_Group_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "groups_group_flags". */
export type Groups_Group_Flags_Order_By = {
  flag?: InputMaybe<Order_By>;
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: groups_group_flags */
export type Groups_Group_Flags_Pk_Columns_Input = {
  flag: Groups_Flags_Enum;
  group_id: Scalars['uuid'];
};

/** select columns of table "groups_group_flags" */
export enum Groups_Group_Flags_Select_Column {
  /** column name */
  Flag = 'flag',
  /** column name */
  GroupId = 'group_id'
}

/** input type for updating data in table "groups_group_flags" */
export type Groups_Group_Flags_Set_Input = {
  flag?: InputMaybe<Groups_Flags_Enum>;
  group_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "groups_group_flags" */
export type Groups_Group_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Groups_Group_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Groups_Group_Flags_Stream_Cursor_Value_Input = {
  flag?: InputMaybe<Groups_Flags_Enum>;
  group_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "groups_group_flags" */
export enum Groups_Group_Flags_Update_Column {
  /** column name */
  Flag = 'flag',
  /** column name */
  GroupId = 'group_id'
}

export type Groups_Group_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Groups_Group_Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Groups_Group_Flags_Bool_Exp;
};

/** input type for inserting data into table "groups" */
export type Groups_Insert_Input = {
  contents?: InputMaybe<Groups_Contents_Arr_Rel_Insert_Input>;
  diagnostics?: InputMaybe<Diagnostics_Groups_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  flags?: InputMaybe<Groups_Group_Flags_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['uuid']>;
  methodology?: InputMaybe<Metodologies_Enum>;
  names?: InputMaybe<Groups_Names_Arr_Rel_Insert_Input>;
  start_date?: InputMaybe<Scalars['date']>;
  teams?: InputMaybe<Groups_Teams_Arr_Rel_Insert_Input>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Groups_Max_Fields = {
  __typename?: 'groups_max_fields';
  email?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "groups" */
export type Groups_Max_Order_By = {
  email?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Groups_Min_Fields = {
  __typename?: 'groups_min_fields';
  email?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "groups" */
export type Groups_Min_Order_By = {
  email?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "groups" */
export type Groups_Mutation_Response = {
  __typename?: 'groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups>;
};

/** columns and relationships of "groups_names" */
export type Groups_Names = {
  __typename?: 'groups_names';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  group: Groups;
  group_id: Scalars['uuid'];
  id: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "groups_names" */
export type Groups_Names_Aggregate = {
  __typename?: 'groups_names_aggregate';
  aggregate?: Maybe<Groups_Names_Aggregate_Fields>;
  nodes: Array<Groups_Names>;
};

export type Groups_Names_Aggregate_Bool_Exp = {
  count?: InputMaybe<Groups_Names_Aggregate_Bool_Exp_Count>;
};

export type Groups_Names_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Groups_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Groups_Names_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "groups_names" */
export type Groups_Names_Aggregate_Fields = {
  __typename?: 'groups_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Groups_Names_Max_Fields>;
  min?: Maybe<Groups_Names_Min_Fields>;
};


/** aggregate fields of "groups_names" */
export type Groups_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Groups_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "groups_names" */
export type Groups_Names_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Groups_Names_Max_Order_By>;
  min?: InputMaybe<Groups_Names_Min_Order_By>;
};

/** input type for inserting array relation for remote table "groups_names" */
export type Groups_Names_Arr_Rel_Insert_Input = {
  data: Array<Groups_Names_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_Names_On_Conflict>;
};

/** Boolean expression to filter rows from the table "groups_names". All fields are combined with a logical 'AND'. */
export type Groups_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Groups_Names_Bool_Exp>>;
  _not?: InputMaybe<Groups_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Groups_Names_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups_names" */
export enum Groups_Names_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupsNamesPkey = 'groups_names_pkey'
}

/** input type for inserting data into table "groups_names" */
export type Groups_Names_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Groups_Names_Max_Fields = {
  __typename?: 'groups_names_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "groups_names" */
export type Groups_Names_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Groups_Names_Min_Fields = {
  __typename?: 'groups_names_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "groups_names" */
export type Groups_Names_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "groups_names" */
export type Groups_Names_Mutation_Response = {
  __typename?: 'groups_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups_Names>;
};

/** on_conflict condition type for table "groups_names" */
export type Groups_Names_On_Conflict = {
  constraint: Groups_Names_Constraint;
  update_columns?: Array<Groups_Names_Update_Column>;
  where?: InputMaybe<Groups_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "groups_names". */
export type Groups_Names_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: groups_names */
export type Groups_Names_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "groups_names" */
export enum Groups_Names_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "groups_names" */
export type Groups_Names_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "groups_names" */
export type Groups_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Groups_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Groups_Names_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "groups_names" */
export enum Groups_Names_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Groups_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Groups_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Groups_Names_Bool_Exp;
};

/** input type for inserting object relation for remote table "groups" */
export type Groups_Obj_Rel_Insert_Input = {
  data: Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** on_conflict condition type for table "groups" */
export type Groups_On_Conflict = {
  constraint: Groups_Constraint;
  update_columns?: Array<Groups_Update_Column>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "groups". */
export type Groups_Order_By = {
  contents_aggregate?: InputMaybe<Groups_Contents_Aggregate_Order_By>;
  diagnostics_aggregate?: InputMaybe<Diagnostics_Groups_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  flags_aggregate?: InputMaybe<Groups_Group_Flags_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Locations_Order_By>;
  location_id?: InputMaybe<Order_By>;
  methodology?: InputMaybe<Order_By>;
  names_aggregate?: InputMaybe<Groups_Names_Aggregate_Order_By>;
  start_date?: InputMaybe<Order_By>;
  teams_aggregate?: InputMaybe<Groups_Teams_Aggregate_Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: groups */
export type Groups_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "groups" */
export enum Groups_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Methodology = 'methodology',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "groups" */
export type Groups_Set_Input = {
  email?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  location_id?: InputMaybe<Scalars['uuid']>;
  methodology?: InputMaybe<Metodologies_Enum>;
  start_date?: InputMaybe<Scalars['date']>;
  url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "groups" */
export type Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Groups_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  location_id?: InputMaybe<Scalars['uuid']>;
  methodology?: InputMaybe<Metodologies_Enum>;
  start_date?: InputMaybe<Scalars['date']>;
  url?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "groups_teams" */
export type Groups_Teams = {
  __typename?: 'groups_teams';
  /** An object relationship */
  group: Groups;
  group_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};

/** aggregated selection of "groups_teams" */
export type Groups_Teams_Aggregate = {
  __typename?: 'groups_teams_aggregate';
  aggregate?: Maybe<Groups_Teams_Aggregate_Fields>;
  nodes: Array<Groups_Teams>;
};

export type Groups_Teams_Aggregate_Bool_Exp = {
  count?: InputMaybe<Groups_Teams_Aggregate_Bool_Exp_Count>;
};

export type Groups_Teams_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Groups_Teams_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "groups_teams" */
export type Groups_Teams_Aggregate_Fields = {
  __typename?: 'groups_teams_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Groups_Teams_Max_Fields>;
  min?: Maybe<Groups_Teams_Min_Fields>;
};


/** aggregate fields of "groups_teams" */
export type Groups_Teams_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "groups_teams" */
export type Groups_Teams_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Groups_Teams_Max_Order_By>;
  min?: InputMaybe<Groups_Teams_Min_Order_By>;
};

/** input type for inserting array relation for remote table "groups_teams" */
export type Groups_Teams_Arr_Rel_Insert_Input = {
  data: Array<Groups_Teams_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_Teams_On_Conflict>;
};

/** Boolean expression to filter rows from the table "groups_teams". All fields are combined with a logical 'AND'. */
export type Groups_Teams_Bool_Exp = {
  _and?: InputMaybe<Array<Groups_Teams_Bool_Exp>>;
  _not?: InputMaybe<Groups_Teams_Bool_Exp>;
  _or?: InputMaybe<Array<Groups_Teams_Bool_Exp>>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups_teams" */
export enum Groups_Teams_Constraint {
  /** unique or primary key constraint on columns "team_id", "group_id" */
  GroupsTeamsGroupIdTeamIdKey = 'groups_teams_group_id_team_id_key',
  /** unique or primary key constraint on columns "id" */
  GroupsTeamsPkey = 'groups_teams_pkey'
}

/** input type for inserting data into table "groups_teams" */
export type Groups_Teams_Insert_Input = {
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Groups_Teams_Max_Fields = {
  __typename?: 'groups_teams_max_fields';
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "groups_teams" */
export type Groups_Teams_Max_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Groups_Teams_Min_Fields = {
  __typename?: 'groups_teams_min_fields';
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "groups_teams" */
export type Groups_Teams_Min_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "groups_teams" */
export type Groups_Teams_Mutation_Response = {
  __typename?: 'groups_teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups_Teams>;
};

/** on_conflict condition type for table "groups_teams" */
export type Groups_Teams_On_Conflict = {
  constraint: Groups_Teams_Constraint;
  update_columns?: Array<Groups_Teams_Update_Column>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};

/** Ordering options when selecting data from "groups_teams". */
export type Groups_Teams_Order_By = {
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: groups_teams */
export type Groups_Teams_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "groups_teams" */
export enum Groups_Teams_Select_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "groups_teams" */
export type Groups_Teams_Set_Input = {
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "groups_teams" */
export type Groups_Teams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Groups_Teams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Groups_Teams_Stream_Cursor_Value_Input = {
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "groups_teams" */
export enum Groups_Teams_Update_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id'
}

export type Groups_Teams_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Groups_Teams_Set_Input>;
  /** filter the rows which have to be updated */
  where: Groups_Teams_Bool_Exp;
};

/** update columns of table "groups" */
export enum Groups_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Methodology = 'methodology',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Url = 'url'
}

export type Groups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Groups_Bool_Exp;
};

/** columns and relationships of "icons_types" */
export type Icons_Types = {
  __typename?: 'icons_types';
  type: Scalars['String'];
};

/** aggregated selection of "icons_types" */
export type Icons_Types_Aggregate = {
  __typename?: 'icons_types_aggregate';
  aggregate?: Maybe<Icons_Types_Aggregate_Fields>;
  nodes: Array<Icons_Types>;
};

/** aggregate fields of "icons_types" */
export type Icons_Types_Aggregate_Fields = {
  __typename?: 'icons_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Icons_Types_Max_Fields>;
  min?: Maybe<Icons_Types_Min_Fields>;
};


/** aggregate fields of "icons_types" */
export type Icons_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Icons_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "icons_types". All fields are combined with a logical 'AND'. */
export type Icons_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Icons_Types_Bool_Exp>>;
  _not?: InputMaybe<Icons_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Icons_Types_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "icons_types" */
export enum Icons_Types_Constraint {
  /** unique or primary key constraint on columns "type" */
  IconsTypesPkey = 'icons_types_pkey'
}

export enum Icons_Types_Enum {
  Big = 'big',
  Medium = 'medium',
  Small = 'small'
}

/** Boolean expression to compare columns of type "icons_types_enum". All fields are combined with logical 'AND'. */
export type Icons_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Icons_Types_Enum>;
  _in?: InputMaybe<Array<Icons_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Icons_Types_Enum>;
  _nin?: InputMaybe<Array<Icons_Types_Enum>>;
};

/** input type for inserting data into table "icons_types" */
export type Icons_Types_Insert_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Icons_Types_Max_Fields = {
  __typename?: 'icons_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Icons_Types_Min_Fields = {
  __typename?: 'icons_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "icons_types" */
export type Icons_Types_Mutation_Response = {
  __typename?: 'icons_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Icons_Types>;
};

/** on_conflict condition type for table "icons_types" */
export type Icons_Types_On_Conflict = {
  constraint: Icons_Types_Constraint;
  update_columns?: Array<Icons_Types_Update_Column>;
  where?: InputMaybe<Icons_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "icons_types". */
export type Icons_Types_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: icons_types */
export type Icons_Types_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "icons_types" */
export enum Icons_Types_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "icons_types" */
export type Icons_Types_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "icons_types" */
export type Icons_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Icons_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Icons_Types_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "icons_types" */
export enum Icons_Types_Update_Column {
  /** column name */
  Type = 'type'
}

export type Icons_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Icons_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Icons_Types_Bool_Exp;
};

/** columns and relationships of "invite_states" */
export type Invite_States = {
  __typename?: 'invite_states';
  state: Scalars['String'];
};

/** aggregated selection of "invite_states" */
export type Invite_States_Aggregate = {
  __typename?: 'invite_states_aggregate';
  aggregate?: Maybe<Invite_States_Aggregate_Fields>;
  nodes: Array<Invite_States>;
};

/** aggregate fields of "invite_states" */
export type Invite_States_Aggregate_Fields = {
  __typename?: 'invite_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Invite_States_Max_Fields>;
  min?: Maybe<Invite_States_Min_Fields>;
};


/** aggregate fields of "invite_states" */
export type Invite_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invite_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "invite_states". All fields are combined with a logical 'AND'. */
export type Invite_States_Bool_Exp = {
  _and?: InputMaybe<Array<Invite_States_Bool_Exp>>;
  _not?: InputMaybe<Invite_States_Bool_Exp>;
  _or?: InputMaybe<Array<Invite_States_Bool_Exp>>;
  state?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "invite_states" */
export enum Invite_States_Constraint {
  /** unique or primary key constraint on columns "state" */
  InviteStatesPkey = 'invite_states_pkey'
}

export enum Invite_States_Enum {
  Accepted = 'accepted',
  Pending = 'pending',
  Refused = 'refused'
}

/** Boolean expression to compare columns of type "invite_states_enum". All fields are combined with logical 'AND'. */
export type Invite_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Invite_States_Enum>;
  _in?: InputMaybe<Array<Invite_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Invite_States_Enum>;
  _nin?: InputMaybe<Array<Invite_States_Enum>>;
};

/** input type for inserting data into table "invite_states" */
export type Invite_States_Insert_Input = {
  state?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Invite_States_Max_Fields = {
  __typename?: 'invite_states_max_fields';
  state?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Invite_States_Min_Fields = {
  __typename?: 'invite_states_min_fields';
  state?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "invite_states" */
export type Invite_States_Mutation_Response = {
  __typename?: 'invite_states_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invite_States>;
};

/** on_conflict condition type for table "invite_states" */
export type Invite_States_On_Conflict = {
  constraint: Invite_States_Constraint;
  update_columns?: Array<Invite_States_Update_Column>;
  where?: InputMaybe<Invite_States_Bool_Exp>;
};

/** Ordering options when selecting data from "invite_states". */
export type Invite_States_Order_By = {
  state?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invite_states */
export type Invite_States_Pk_Columns_Input = {
  state: Scalars['String'];
};

/** select columns of table "invite_states" */
export enum Invite_States_Select_Column {
  /** column name */
  State = 'state'
}

/** input type for updating data in table "invite_states" */
export type Invite_States_Set_Input = {
  state?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "invite_states" */
export type Invite_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invite_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invite_States_Stream_Cursor_Value_Input = {
  state?: InputMaybe<Scalars['String']>;
};

/** update columns of table "invite_states" */
export enum Invite_States_Update_Column {
  /** column name */
  State = 'state'
}

export type Invite_States_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invite_States_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invite_States_Bool_Exp;
};

/** columns and relationships of "kanban_columns" */
export type Kanban_Columns = {
  __typename?: 'kanban_columns';
  column: Scalars['String'];
};

/** aggregated selection of "kanban_columns" */
export type Kanban_Columns_Aggregate = {
  __typename?: 'kanban_columns_aggregate';
  aggregate?: Maybe<Kanban_Columns_Aggregate_Fields>;
  nodes: Array<Kanban_Columns>;
};

/** aggregate fields of "kanban_columns" */
export type Kanban_Columns_Aggregate_Fields = {
  __typename?: 'kanban_columns_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kanban_Columns_Max_Fields>;
  min?: Maybe<Kanban_Columns_Min_Fields>;
};


/** aggregate fields of "kanban_columns" */
export type Kanban_Columns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kanban_Columns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "kanban_columns". All fields are combined with a logical 'AND'. */
export type Kanban_Columns_Bool_Exp = {
  _and?: InputMaybe<Array<Kanban_Columns_Bool_Exp>>;
  _not?: InputMaybe<Kanban_Columns_Bool_Exp>;
  _or?: InputMaybe<Array<Kanban_Columns_Bool_Exp>>;
  column?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "kanban_columns" */
export enum Kanban_Columns_Constraint {
  /** unique or primary key constraint on columns "column" */
  KanbanColumnsPkey = 'kanban_columns_pkey'
}

export enum Kanban_Columns_Enum {
  Backlog = 'backlog',
  Done = 'done',
  Impediments = 'impediments',
  Ongoing = 'ongoing',
  Todo = 'todo'
}

/** Boolean expression to compare columns of type "kanban_columns_enum". All fields are combined with logical 'AND'. */
export type Kanban_Columns_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Kanban_Columns_Enum>;
  _in?: InputMaybe<Array<Kanban_Columns_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Kanban_Columns_Enum>;
  _nin?: InputMaybe<Array<Kanban_Columns_Enum>>;
};

/** input type for inserting data into table "kanban_columns" */
export type Kanban_Columns_Insert_Input = {
  column?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Kanban_Columns_Max_Fields = {
  __typename?: 'kanban_columns_max_fields';
  column?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Kanban_Columns_Min_Fields = {
  __typename?: 'kanban_columns_min_fields';
  column?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "kanban_columns" */
export type Kanban_Columns_Mutation_Response = {
  __typename?: 'kanban_columns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kanban_Columns>;
};

/** on_conflict condition type for table "kanban_columns" */
export type Kanban_Columns_On_Conflict = {
  constraint: Kanban_Columns_Constraint;
  update_columns?: Array<Kanban_Columns_Update_Column>;
  where?: InputMaybe<Kanban_Columns_Bool_Exp>;
};

/** Ordering options when selecting data from "kanban_columns". */
export type Kanban_Columns_Order_By = {
  column?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kanban_columns */
export type Kanban_Columns_Pk_Columns_Input = {
  column: Scalars['String'];
};

/** select columns of table "kanban_columns" */
export enum Kanban_Columns_Select_Column {
  /** column name */
  Column = 'column'
}

/** input type for updating data in table "kanban_columns" */
export type Kanban_Columns_Set_Input = {
  column?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "kanban_columns" */
export type Kanban_Columns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kanban_Columns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kanban_Columns_Stream_Cursor_Value_Input = {
  column?: InputMaybe<Scalars['String']>;
};

/** update columns of table "kanban_columns" */
export enum Kanban_Columns_Update_Column {
  /** column name */
  Column = 'column'
}

export type Kanban_Columns_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Kanban_Columns_Set_Input>;
  /** filter the rows which have to be updated */
  where: Kanban_Columns_Bool_Exp;
};

/** columns and relationships of "kanbans" */
export type Kanbans = {
  __typename?: 'kanbans';
  /** An array relationship */
  cards: Array<Kanbans_Cards>;
  /** An aggregate relationship */
  cards_aggregate: Kanbans_Cards_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};


/** columns and relationships of "kanbans" */
export type KanbansCardsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Bool_Exp>;
};


/** columns and relationships of "kanbans" */
export type KanbansCards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Bool_Exp>;
};

/** aggregated selection of "kanbans" */
export type Kanbans_Aggregate = {
  __typename?: 'kanbans_aggregate';
  aggregate?: Maybe<Kanbans_Aggregate_Fields>;
  nodes: Array<Kanbans>;
};

/** aggregate fields of "kanbans" */
export type Kanbans_Aggregate_Fields = {
  __typename?: 'kanbans_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kanbans_Max_Fields>;
  min?: Maybe<Kanbans_Min_Fields>;
};


/** aggregate fields of "kanbans" */
export type Kanbans_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kanbans_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "kanbans". All fields are combined with a logical 'AND'. */
export type Kanbans_Bool_Exp = {
  _and?: InputMaybe<Array<Kanbans_Bool_Exp>>;
  _not?: InputMaybe<Kanbans_Bool_Exp>;
  _or?: InputMaybe<Array<Kanbans_Bool_Exp>>;
  cards?: InputMaybe<Kanbans_Cards_Bool_Exp>;
  cards_aggregate?: InputMaybe<Kanbans_Cards_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** columns and relationships of "kanbans_cards" */
export type Kanbans_Cards = {
  __typename?: 'kanbans_cards';
  /** An array relationship */
  assignations: Array<Kanbans_Cards_Assignations>;
  /** An aggregate relationship */
  assignations_aggregate: Kanbans_Cards_Assignations_Aggregate;
  /** An array relationship */
  attachments: Array<Kanbans_Cards_Attachments>;
  /** An aggregate relationship */
  attachments_aggregate: Kanbans_Cards_Attachments_Aggregate;
  column: Kanban_Columns_Enum;
  /** An array relationship */
  comments: Array<Kanbans_Cards_Comments>;
  /** An aggregate relationship */
  comments_aggregate: Kanbans_Cards_Comments_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by: Users;
  creator_id: Scalars['String'];
  due_date?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  kanban: Kanbans;
  kanban_id: Scalars['uuid'];
  /** An object relationship */
  link_output?: Maybe<Outputs>;
  link_output_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  link_section?: Maybe<Sections>;
  link_section_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  link_tool?: Maybe<Tools>;
  link_tool_id?: Maybe<Scalars['uuid']>;
  title: Scalars['String'];
};


/** columns and relationships of "kanbans_cards" */
export type Kanbans_CardsAssignationsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Assignations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Assignations_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
};


/** columns and relationships of "kanbans_cards" */
export type Kanbans_CardsAssignations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Assignations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Assignations_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
};


/** columns and relationships of "kanbans_cards" */
export type Kanbans_CardsAttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Attachments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
};


/** columns and relationships of "kanbans_cards" */
export type Kanbans_CardsAttachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Attachments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
};


/** columns and relationships of "kanbans_cards" */
export type Kanbans_CardsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Comments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
};


/** columns and relationships of "kanbans_cards" */
export type Kanbans_CardsComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Comments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
};

/** aggregated selection of "kanbans_cards" */
export type Kanbans_Cards_Aggregate = {
  __typename?: 'kanbans_cards_aggregate';
  aggregate?: Maybe<Kanbans_Cards_Aggregate_Fields>;
  nodes: Array<Kanbans_Cards>;
};

export type Kanbans_Cards_Aggregate_Bool_Exp = {
  count?: InputMaybe<Kanbans_Cards_Aggregate_Bool_Exp_Count>;
};

export type Kanbans_Cards_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Kanbans_Cards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Kanbans_Cards_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "kanbans_cards" */
export type Kanbans_Cards_Aggregate_Fields = {
  __typename?: 'kanbans_cards_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kanbans_Cards_Max_Fields>;
  min?: Maybe<Kanbans_Cards_Min_Fields>;
};


/** aggregate fields of "kanbans_cards" */
export type Kanbans_Cards_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kanbans_Cards_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "kanbans_cards" */
export type Kanbans_Cards_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kanbans_Cards_Max_Order_By>;
  min?: InputMaybe<Kanbans_Cards_Min_Order_By>;
};

/** input type for inserting array relation for remote table "kanbans_cards" */
export type Kanbans_Cards_Arr_Rel_Insert_Input = {
  data: Array<Kanbans_Cards_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Kanbans_Cards_On_Conflict>;
};

/** columns and relationships of "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations = {
  __typename?: 'kanbans_cards_assignations';
  /** An object relationship */
  card: Kanbans_Cards;
  card_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Aggregate = {
  __typename?: 'kanbans_cards_assignations_aggregate';
  aggregate?: Maybe<Kanbans_Cards_Assignations_Aggregate_Fields>;
  nodes: Array<Kanbans_Cards_Assignations>;
};

export type Kanbans_Cards_Assignations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Kanbans_Cards_Assignations_Aggregate_Bool_Exp_Count>;
};

export type Kanbans_Cards_Assignations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Kanbans_Cards_Assignations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Aggregate_Fields = {
  __typename?: 'kanbans_cards_assignations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kanbans_Cards_Assignations_Max_Fields>;
  min?: Maybe<Kanbans_Cards_Assignations_Min_Fields>;
};


/** aggregate fields of "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kanbans_Cards_Assignations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kanbans_Cards_Assignations_Max_Order_By>;
  min?: InputMaybe<Kanbans_Cards_Assignations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Arr_Rel_Insert_Input = {
  data: Array<Kanbans_Cards_Assignations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Kanbans_Cards_Assignations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "kanbans_cards_assignations". All fields are combined with a logical 'AND'. */
export type Kanbans_Cards_Assignations_Bool_Exp = {
  _and?: InputMaybe<Array<Kanbans_Cards_Assignations_Bool_Exp>>;
  _not?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
  _or?: InputMaybe<Array<Kanbans_Cards_Assignations_Bool_Exp>>;
  card?: InputMaybe<Kanbans_Cards_Bool_Exp>;
  card_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "kanbans_cards_assignations" */
export enum Kanbans_Cards_Assignations_Constraint {
  /** unique or primary key constraint on columns "user_id", "card_id" */
  KanbansCardsAssignationsPkey = 'kanbans_cards_assignations_pkey'
}

/** input type for inserting data into table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Insert_Input = {
  card?: InputMaybe<Kanbans_Cards_Obj_Rel_Insert_Input>;
  card_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Kanbans_Cards_Assignations_Max_Fields = {
  __typename?: 'kanbans_cards_assignations_max_fields';
  card_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Max_Order_By = {
  card_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Kanbans_Cards_Assignations_Min_Fields = {
  __typename?: 'kanbans_cards_assignations_min_fields';
  card_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Min_Order_By = {
  card_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Mutation_Response = {
  __typename?: 'kanbans_cards_assignations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kanbans_Cards_Assignations>;
};

/** on_conflict condition type for table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_On_Conflict = {
  constraint: Kanbans_Cards_Assignations_Constraint;
  update_columns?: Array<Kanbans_Cards_Assignations_Update_Column>;
  where?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
};

/** Ordering options when selecting data from "kanbans_cards_assignations". */
export type Kanbans_Cards_Assignations_Order_By = {
  card?: InputMaybe<Kanbans_Cards_Order_By>;
  card_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kanbans_cards_assignations */
export type Kanbans_Cards_Assignations_Pk_Columns_Input = {
  card_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** select columns of table "kanbans_cards_assignations" */
export enum Kanbans_Cards_Assignations_Select_Column {
  /** column name */
  CardId = 'card_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Set_Input = {
  card_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "kanbans_cards_assignations" */
export type Kanbans_Cards_Assignations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kanbans_Cards_Assignations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kanbans_Cards_Assignations_Stream_Cursor_Value_Input = {
  card_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "kanbans_cards_assignations" */
export enum Kanbans_Cards_Assignations_Update_Column {
  /** column name */
  CardId = 'card_id',
  /** column name */
  UserId = 'user_id'
}

export type Kanbans_Cards_Assignations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Kanbans_Cards_Assignations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Kanbans_Cards_Assignations_Bool_Exp;
};

/** columns and relationships of "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments = {
  __typename?: 'kanbans_cards_attachments';
  /** An object relationship */
  card: Kanbans_Cards;
  card_id: Scalars['uuid'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
};

/** aggregated selection of "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Aggregate = {
  __typename?: 'kanbans_cards_attachments_aggregate';
  aggregate?: Maybe<Kanbans_Cards_Attachments_Aggregate_Fields>;
  nodes: Array<Kanbans_Cards_Attachments>;
};

export type Kanbans_Cards_Attachments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Kanbans_Cards_Attachments_Aggregate_Bool_Exp_Count>;
};

export type Kanbans_Cards_Attachments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Kanbans_Cards_Attachments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Aggregate_Fields = {
  __typename?: 'kanbans_cards_attachments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kanbans_Cards_Attachments_Max_Fields>;
  min?: Maybe<Kanbans_Cards_Attachments_Min_Fields>;
};


/** aggregate fields of "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kanbans_Cards_Attachments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kanbans_Cards_Attachments_Max_Order_By>;
  min?: InputMaybe<Kanbans_Cards_Attachments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Arr_Rel_Insert_Input = {
  data: Array<Kanbans_Cards_Attachments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Kanbans_Cards_Attachments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "kanbans_cards_attachments". All fields are combined with a logical 'AND'. */
export type Kanbans_Cards_Attachments_Bool_Exp = {
  _and?: InputMaybe<Array<Kanbans_Cards_Attachments_Bool_Exp>>;
  _not?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
  _or?: InputMaybe<Array<Kanbans_Cards_Attachments_Bool_Exp>>;
  card?: InputMaybe<Kanbans_Cards_Bool_Exp>;
  card_id?: InputMaybe<Uuid_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "kanbans_cards_attachments" */
export enum Kanbans_Cards_Attachments_Constraint {
  /** unique or primary key constraint on columns "card_id", "file_id" */
  KanbansCardsAttachmentsPkey = 'kanbans_cards_attachments_pkey'
}

/** input type for inserting data into table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Insert_Input = {
  card?: InputMaybe<Kanbans_Cards_Obj_Rel_Insert_Input>;
  card_id?: InputMaybe<Scalars['uuid']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Kanbans_Cards_Attachments_Max_Fields = {
  __typename?: 'kanbans_cards_attachments_max_fields';
  card_id?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Max_Order_By = {
  card_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Kanbans_Cards_Attachments_Min_Fields = {
  __typename?: 'kanbans_cards_attachments_min_fields';
  card_id?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Min_Order_By = {
  card_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Mutation_Response = {
  __typename?: 'kanbans_cards_attachments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kanbans_Cards_Attachments>;
};

/** on_conflict condition type for table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_On_Conflict = {
  constraint: Kanbans_Cards_Attachments_Constraint;
  update_columns?: Array<Kanbans_Cards_Attachments_Update_Column>;
  where?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
};

/** Ordering options when selecting data from "kanbans_cards_attachments". */
export type Kanbans_Cards_Attachments_Order_By = {
  card?: InputMaybe<Kanbans_Cards_Order_By>;
  card_id?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kanbans_cards_attachments */
export type Kanbans_Cards_Attachments_Pk_Columns_Input = {
  card_id: Scalars['uuid'];
  file_id: Scalars['uuid'];
};

/** select columns of table "kanbans_cards_attachments" */
export enum Kanbans_Cards_Attachments_Select_Column {
  /** column name */
  CardId = 'card_id',
  /** column name */
  FileId = 'file_id'
}

/** input type for updating data in table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Set_Input = {
  card_id?: InputMaybe<Scalars['uuid']>;
  file_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "kanbans_cards_attachments" */
export type Kanbans_Cards_Attachments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kanbans_Cards_Attachments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kanbans_Cards_Attachments_Stream_Cursor_Value_Input = {
  card_id?: InputMaybe<Scalars['uuid']>;
  file_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "kanbans_cards_attachments" */
export enum Kanbans_Cards_Attachments_Update_Column {
  /** column name */
  CardId = 'card_id',
  /** column name */
  FileId = 'file_id'
}

export type Kanbans_Cards_Attachments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Kanbans_Cards_Attachments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Kanbans_Cards_Attachments_Bool_Exp;
};

/** Boolean expression to filter rows from the table "kanbans_cards". All fields are combined with a logical 'AND'. */
export type Kanbans_Cards_Bool_Exp = {
  _and?: InputMaybe<Array<Kanbans_Cards_Bool_Exp>>;
  _not?: InputMaybe<Kanbans_Cards_Bool_Exp>;
  _or?: InputMaybe<Array<Kanbans_Cards_Bool_Exp>>;
  assignations?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
  assignations_aggregate?: InputMaybe<Kanbans_Cards_Assignations_Aggregate_Bool_Exp>;
  attachments?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
  attachments_aggregate?: InputMaybe<Kanbans_Cards_Attachments_Aggregate_Bool_Exp>;
  column?: InputMaybe<Kanban_Columns_Enum_Comparison_Exp>;
  comments?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
  comments_aggregate?: InputMaybe<Kanbans_Cards_Comments_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Users_Bool_Exp>;
  creator_id?: InputMaybe<String_Comparison_Exp>;
  due_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kanban?: InputMaybe<Kanbans_Bool_Exp>;
  kanban_id?: InputMaybe<Uuid_Comparison_Exp>;
  link_output?: InputMaybe<Outputs_Bool_Exp>;
  link_output_id?: InputMaybe<Uuid_Comparison_Exp>;
  link_section?: InputMaybe<Sections_Bool_Exp>;
  link_section_id?: InputMaybe<Uuid_Comparison_Exp>;
  link_tool?: InputMaybe<Tools_Bool_Exp>;
  link_tool_id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "kanbans_cards_comments" */
export type Kanbans_Cards_Comments = {
  __typename?: 'kanbans_cards_comments';
  /** An object relationship */
  card: Kanbans_Cards;
  card_id: Scalars['uuid'];
  content?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by: Users;
  creator_id: Scalars['String'];
  id: Scalars['uuid'];
};

/** aggregated selection of "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Aggregate = {
  __typename?: 'kanbans_cards_comments_aggregate';
  aggregate?: Maybe<Kanbans_Cards_Comments_Aggregate_Fields>;
  nodes: Array<Kanbans_Cards_Comments>;
};

export type Kanbans_Cards_Comments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Kanbans_Cards_Comments_Aggregate_Bool_Exp_Count>;
};

export type Kanbans_Cards_Comments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Kanbans_Cards_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Aggregate_Fields = {
  __typename?: 'kanbans_cards_comments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kanbans_Cards_Comments_Max_Fields>;
  min?: Maybe<Kanbans_Cards_Comments_Min_Fields>;
};


/** aggregate fields of "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kanbans_Cards_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kanbans_Cards_Comments_Max_Order_By>;
  min?: InputMaybe<Kanbans_Cards_Comments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Arr_Rel_Insert_Input = {
  data: Array<Kanbans_Cards_Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Kanbans_Cards_Comments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "kanbans_cards_comments". All fields are combined with a logical 'AND'. */
export type Kanbans_Cards_Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Kanbans_Cards_Comments_Bool_Exp>>;
  _not?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Kanbans_Cards_Comments_Bool_Exp>>;
  card?: InputMaybe<Kanbans_Cards_Bool_Exp>;
  card_id?: InputMaybe<Uuid_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Users_Bool_Exp>;
  creator_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "kanbans_cards_comments" */
export enum Kanbans_Cards_Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  KanbansCardsCommentsPkey = 'kanbans_cards_comments_pkey'
}

/** input type for inserting data into table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Insert_Input = {
  card?: InputMaybe<Kanbans_Cards_Obj_Rel_Insert_Input>;
  card_id?: InputMaybe<Scalars['uuid']>;
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  creator_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Kanbans_Cards_Comments_Max_Fields = {
  __typename?: 'kanbans_cards_comments_max_fields';
  card_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Max_Order_By = {
  card_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Kanbans_Cards_Comments_Min_Fields = {
  __typename?: 'kanbans_cards_comments_min_fields';
  card_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Min_Order_By = {
  card_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Mutation_Response = {
  __typename?: 'kanbans_cards_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kanbans_Cards_Comments>;
};

/** on_conflict condition type for table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_On_Conflict = {
  constraint: Kanbans_Cards_Comments_Constraint;
  update_columns?: Array<Kanbans_Cards_Comments_Update_Column>;
  where?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "kanbans_cards_comments". */
export type Kanbans_Cards_Comments_Order_By = {
  card?: InputMaybe<Kanbans_Cards_Order_By>;
  card_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Users_Order_By>;
  creator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kanbans_cards_comments */
export type Kanbans_Cards_Comments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "kanbans_cards_comments" */
export enum Kanbans_Cards_Comments_Select_Column {
  /** column name */
  CardId = 'card_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Set_Input = {
  card_id?: InputMaybe<Scalars['uuid']>;
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "kanbans_cards_comments" */
export type Kanbans_Cards_Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kanbans_Cards_Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kanbans_Cards_Comments_Stream_Cursor_Value_Input = {
  card_id?: InputMaybe<Scalars['uuid']>;
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "kanbans_cards_comments" */
export enum Kanbans_Cards_Comments_Update_Column {
  /** column name */
  CardId = 'card_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id'
}

export type Kanbans_Cards_Comments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Kanbans_Cards_Comments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Kanbans_Cards_Comments_Bool_Exp;
};

/** unique or primary key constraints on table "kanbans_cards" */
export enum Kanbans_Cards_Constraint {
  /** unique or primary key constraint on columns "id" */
  KanbansCardsPkey = 'kanbans_cards_pkey'
}

/** input type for inserting data into table "kanbans_cards" */
export type Kanbans_Cards_Insert_Input = {
  assignations?: InputMaybe<Kanbans_Cards_Assignations_Arr_Rel_Insert_Input>;
  attachments?: InputMaybe<Kanbans_Cards_Attachments_Arr_Rel_Insert_Input>;
  column?: InputMaybe<Kanban_Columns_Enum>;
  comments?: InputMaybe<Kanbans_Cards_Comments_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  creator_id?: InputMaybe<Scalars['String']>;
  due_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  kanban?: InputMaybe<Kanbans_Obj_Rel_Insert_Input>;
  kanban_id?: InputMaybe<Scalars['uuid']>;
  link_output?: InputMaybe<Outputs_Obj_Rel_Insert_Input>;
  link_output_id?: InputMaybe<Scalars['uuid']>;
  link_section?: InputMaybe<Sections_Obj_Rel_Insert_Input>;
  link_section_id?: InputMaybe<Scalars['uuid']>;
  link_tool?: InputMaybe<Tools_Obj_Rel_Insert_Input>;
  link_tool_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Kanbans_Cards_Max_Fields = {
  __typename?: 'kanbans_cards_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  kanban_id?: Maybe<Scalars['uuid']>;
  link_output_id?: Maybe<Scalars['uuid']>;
  link_section_id?: Maybe<Scalars['uuid']>;
  link_tool_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "kanbans_cards" */
export type Kanbans_Cards_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kanban_id?: InputMaybe<Order_By>;
  link_output_id?: InputMaybe<Order_By>;
  link_section_id?: InputMaybe<Order_By>;
  link_tool_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Kanbans_Cards_Min_Fields = {
  __typename?: 'kanbans_cards_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_id?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  kanban_id?: Maybe<Scalars['uuid']>;
  link_output_id?: Maybe<Scalars['uuid']>;
  link_section_id?: Maybe<Scalars['uuid']>;
  link_tool_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "kanbans_cards" */
export type Kanbans_Cards_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  creator_id?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kanban_id?: InputMaybe<Order_By>;
  link_output_id?: InputMaybe<Order_By>;
  link_section_id?: InputMaybe<Order_By>;
  link_tool_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "kanbans_cards" */
export type Kanbans_Cards_Mutation_Response = {
  __typename?: 'kanbans_cards_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kanbans_Cards>;
};

/** input type for inserting object relation for remote table "kanbans_cards" */
export type Kanbans_Cards_Obj_Rel_Insert_Input = {
  data: Kanbans_Cards_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Kanbans_Cards_On_Conflict>;
};

/** on_conflict condition type for table "kanbans_cards" */
export type Kanbans_Cards_On_Conflict = {
  constraint: Kanbans_Cards_Constraint;
  update_columns?: Array<Kanbans_Cards_Update_Column>;
  where?: InputMaybe<Kanbans_Cards_Bool_Exp>;
};

/** Ordering options when selecting data from "kanbans_cards". */
export type Kanbans_Cards_Order_By = {
  assignations_aggregate?: InputMaybe<Kanbans_Cards_Assignations_Aggregate_Order_By>;
  attachments_aggregate?: InputMaybe<Kanbans_Cards_Attachments_Aggregate_Order_By>;
  column?: InputMaybe<Order_By>;
  comments_aggregate?: InputMaybe<Kanbans_Cards_Comments_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Users_Order_By>;
  creator_id?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kanban?: InputMaybe<Kanbans_Order_By>;
  kanban_id?: InputMaybe<Order_By>;
  link_output?: InputMaybe<Outputs_Order_By>;
  link_output_id?: InputMaybe<Order_By>;
  link_section?: InputMaybe<Sections_Order_By>;
  link_section_id?: InputMaybe<Order_By>;
  link_tool?: InputMaybe<Tools_Order_By>;
  link_tool_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kanbans_cards */
export type Kanbans_Cards_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "kanbans_cards" */
export enum Kanbans_Cards_Select_Column {
  /** column name */
  Column = 'column',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  KanbanId = 'kanban_id',
  /** column name */
  LinkOutputId = 'link_output_id',
  /** column name */
  LinkSectionId = 'link_section_id',
  /** column name */
  LinkToolId = 'link_tool_id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "kanbans_cards" */
export type Kanbans_Cards_Set_Input = {
  column?: InputMaybe<Kanban_Columns_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['String']>;
  due_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  kanban_id?: InputMaybe<Scalars['uuid']>;
  link_output_id?: InputMaybe<Scalars['uuid']>;
  link_section_id?: InputMaybe<Scalars['uuid']>;
  link_tool_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "kanbans_cards" */
export type Kanbans_Cards_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kanbans_Cards_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kanbans_Cards_Stream_Cursor_Value_Input = {
  column?: InputMaybe<Kanban_Columns_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creator_id?: InputMaybe<Scalars['String']>;
  due_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  kanban_id?: InputMaybe<Scalars['uuid']>;
  link_output_id?: InputMaybe<Scalars['uuid']>;
  link_section_id?: InputMaybe<Scalars['uuid']>;
  link_tool_id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** update columns of table "kanbans_cards" */
export enum Kanbans_Cards_Update_Column {
  /** column name */
  Column = 'column',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  KanbanId = 'kanban_id',
  /** column name */
  LinkOutputId = 'link_output_id',
  /** column name */
  LinkSectionId = 'link_section_id',
  /** column name */
  LinkToolId = 'link_tool_id',
  /** column name */
  Title = 'title'
}

export type Kanbans_Cards_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Kanbans_Cards_Set_Input>;
  /** filter the rows which have to be updated */
  where: Kanbans_Cards_Bool_Exp;
};

/** unique or primary key constraints on table "kanbans" */
export enum Kanbans_Constraint {
  /** unique or primary key constraint on columns "id" */
  KanbansPkey = 'kanbans_pkey'
}

/** input type for inserting data into table "kanbans" */
export type Kanbans_Insert_Input = {
  cards?: InputMaybe<Kanbans_Cards_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Kanbans_Max_Fields = {
  __typename?: 'kanbans_max_fields';
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Kanbans_Min_Fields = {
  __typename?: 'kanbans_min_fields';
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "kanbans" */
export type Kanbans_Mutation_Response = {
  __typename?: 'kanbans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kanbans>;
};

/** input type for inserting object relation for remote table "kanbans" */
export type Kanbans_Obj_Rel_Insert_Input = {
  data: Kanbans_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Kanbans_On_Conflict>;
};

/** on_conflict condition type for table "kanbans" */
export type Kanbans_On_Conflict = {
  constraint: Kanbans_Constraint;
  update_columns?: Array<Kanbans_Update_Column>;
  where?: InputMaybe<Kanbans_Bool_Exp>;
};

/** Ordering options when selecting data from "kanbans". */
export type Kanbans_Order_By = {
  cards_aggregate?: InputMaybe<Kanbans_Cards_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kanbans */
export type Kanbans_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "kanbans" */
export enum Kanbans_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "kanbans" */
export type Kanbans_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "kanbans" */
export type Kanbans_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kanbans_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kanbans_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "kanbans" */
export enum Kanbans_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id'
}

export type Kanbans_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Kanbans_Set_Input>;
  /** filter the rows which have to be updated */
  where: Kanbans_Bool_Exp;
};

/** columns and relationships of "locations" */
export type Locations = {
  __typename?: 'locations';
  address: Scalars['String'];
  calendar_id?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  names: Array<Locations_Names>;
  /** An aggregate relationship */
  names_aggregate: Locations_Names_Aggregate;
  state: Scalars['String'];
};


/** columns and relationships of "locations" */
export type LocationsGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsNamesArgs = {
  distinct_on?: InputMaybe<Array<Locations_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Names_Order_By>>;
  where?: InputMaybe<Locations_Names_Bool_Exp>;
};


/** columns and relationships of "locations" */
export type LocationsNames_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Names_Order_By>>;
  where?: InputMaybe<Locations_Names_Bool_Exp>;
};

/** aggregated selection of "locations" */
export type Locations_Aggregate = {
  __typename?: 'locations_aggregate';
  aggregate?: Maybe<Locations_Aggregate_Fields>;
  nodes: Array<Locations>;
};

/** aggregate fields of "locations" */
export type Locations_Aggregate_Fields = {
  __typename?: 'locations_aggregate_fields';
  avg?: Maybe<Locations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Locations_Max_Fields>;
  min?: Maybe<Locations_Min_Fields>;
  stddev?: Maybe<Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Locations_Sum_Fields>;
  var_pop?: Maybe<Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Locations_Var_Samp_Fields>;
  variance?: Maybe<Locations_Variance_Fields>;
};


/** aggregate fields of "locations" */
export type Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Locations_Avg_Fields = {
  __typename?: 'locations_avg_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Locations_Bool_Exp>>;
  _not?: InputMaybe<Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Locations_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  calendar_id?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  geo_lat?: InputMaybe<Float_Comparison_Exp>;
  geo_lon?: InputMaybe<Float_Comparison_Exp>;
  groups?: InputMaybe<Groups_Bool_Exp>;
  groups_aggregate?: InputMaybe<Groups_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  names?: InputMaybe<Locations_Names_Bool_Exp>;
  names_aggregate?: InputMaybe<Locations_Names_Aggregate_Bool_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "locations" */
export enum Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationsPkey = 'locations_pkey'
}

/** input type for incrementing numeric columns in table "locations" */
export type Locations_Inc_Input = {
  geo_lat?: InputMaybe<Scalars['Float']>;
  geo_lon?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "locations" */
export type Locations_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  calendar_id?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  geo_lat?: InputMaybe<Scalars['Float']>;
  geo_lon?: InputMaybe<Scalars['Float']>;
  groups?: InputMaybe<Groups_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  names?: InputMaybe<Locations_Names_Arr_Rel_Insert_Input>;
  state?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Locations_Max_Fields = {
  __typename?: 'locations_max_fields';
  address?: Maybe<Scalars['String']>;
  calendar_id?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Locations_Min_Fields = {
  __typename?: 'locations_min_fields';
  address?: Maybe<Scalars['String']>;
  calendar_id?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "locations" */
export type Locations_Mutation_Response = {
  __typename?: 'locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Locations>;
};

/** columns and relationships of "locations_names" */
export type Locations_Names = {
  __typename?: 'locations_names';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location: Locations;
  location_id: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "locations_names" */
export type Locations_Names_Aggregate = {
  __typename?: 'locations_names_aggregate';
  aggregate?: Maybe<Locations_Names_Aggregate_Fields>;
  nodes: Array<Locations_Names>;
};

export type Locations_Names_Aggregate_Bool_Exp = {
  count?: InputMaybe<Locations_Names_Aggregate_Bool_Exp_Count>;
};

export type Locations_Names_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Locations_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Locations_Names_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "locations_names" */
export type Locations_Names_Aggregate_Fields = {
  __typename?: 'locations_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Locations_Names_Max_Fields>;
  min?: Maybe<Locations_Names_Min_Fields>;
};


/** aggregate fields of "locations_names" */
export type Locations_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Locations_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "locations_names" */
export type Locations_Names_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Locations_Names_Max_Order_By>;
  min?: InputMaybe<Locations_Names_Min_Order_By>;
};

/** input type for inserting array relation for remote table "locations_names" */
export type Locations_Names_Arr_Rel_Insert_Input = {
  data: Array<Locations_Names_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Locations_Names_On_Conflict>;
};

/** Boolean expression to filter rows from the table "locations_names". All fields are combined with a logical 'AND'. */
export type Locations_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Locations_Names_Bool_Exp>>;
  _not?: InputMaybe<Locations_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Locations_Names_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Locations_Bool_Exp>;
  location_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "locations_names" */
export enum Locations_Names_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationsNamesPkey = 'locations_names_pkey'
}

/** input type for inserting data into table "locations_names" */
export type Locations_Names_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Locations_Names_Max_Fields = {
  __typename?: 'locations_names_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "locations_names" */
export type Locations_Names_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Locations_Names_Min_Fields = {
  __typename?: 'locations_names_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "locations_names" */
export type Locations_Names_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "locations_names" */
export type Locations_Names_Mutation_Response = {
  __typename?: 'locations_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Locations_Names>;
};

/** on_conflict condition type for table "locations_names" */
export type Locations_Names_On_Conflict = {
  constraint: Locations_Names_Constraint;
  update_columns?: Array<Locations_Names_Update_Column>;
  where?: InputMaybe<Locations_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "locations_names". */
export type Locations_Names_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Locations_Order_By>;
  location_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: locations_names */
export type Locations_Names_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "locations_names" */
export enum Locations_Names_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "locations_names" */
export type Locations_Names_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "locations_names" */
export type Locations_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Locations_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locations_Names_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "locations_names" */
export enum Locations_Names_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name'
}

export type Locations_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Locations_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Locations_Names_Bool_Exp;
};

/** input type for inserting object relation for remote table "locations" */
export type Locations_Obj_Rel_Insert_Input = {
  data: Locations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** on_conflict condition type for table "locations" */
export type Locations_On_Conflict = {
  constraint: Locations_Constraint;
  update_columns?: Array<Locations_Update_Column>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "locations". */
export type Locations_Order_By = {
  address?: InputMaybe<Order_By>;
  calendar_id?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  geo_lat?: InputMaybe<Order_By>;
  geo_lon?: InputMaybe<Order_By>;
  groups_aggregate?: InputMaybe<Groups_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  names_aggregate?: InputMaybe<Locations_Names_Aggregate_Order_By>;
  state?: InputMaybe<Order_By>;
};

/** primary key columns input for table: locations */
export type Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "locations" */
export enum Locations_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Description = 'description',
  /** column name */
  GeoLat = 'geo_lat',
  /** column name */
  GeoLon = 'geo_lon',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state'
}

/** input type for updating data in table "locations" */
export type Locations_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  calendar_id?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  geo_lat?: InputMaybe<Scalars['Float']>;
  geo_lon?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  state?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Locations_Stddev_Fields = {
  __typename?: 'locations_stddev_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Locations_Stddev_Pop_Fields = {
  __typename?: 'locations_stddev_pop_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Locations_Stddev_Samp_Fields = {
  __typename?: 'locations_stddev_samp_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "locations" */
export type Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locations_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  calendar_id?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  geo_lat?: InputMaybe<Scalars['Float']>;
  geo_lon?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['uuid']>;
  state?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Locations_Sum_Fields = {
  __typename?: 'locations_sum_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** update columns of table "locations" */
export enum Locations_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Description = 'description',
  /** column name */
  GeoLat = 'geo_lat',
  /** column name */
  GeoLon = 'geo_lon',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state'
}

export type Locations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Locations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Locations_Var_Pop_Fields = {
  __typename?: 'locations_var_pop_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Locations_Var_Samp_Fields = {
  __typename?: 'locations_var_samp_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Locations_Variance_Fields = {
  __typename?: 'locations_variance_fields';
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lon?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "mentor_additional_data" */
export type Mentor_Additional_Data = {
  __typename?: 'mentor_additional_data';
  city?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  linkedin?: Maybe<Scalars['String']>;
  /** An array relationship */
  mentor_additional_data_mentors: Array<Mentors>;
  /** An aggregate relationship */
  mentor_additional_data_mentors_aggregate: Mentors_Aggregate;
  organization?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};


/** columns and relationships of "mentor_additional_data" */
export type Mentor_Additional_DataMentor_Additional_Data_MentorsArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Order_By>>;
  where?: InputMaybe<Mentors_Bool_Exp>;
};


/** columns and relationships of "mentor_additional_data" */
export type Mentor_Additional_DataMentor_Additional_Data_Mentors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Order_By>>;
  where?: InputMaybe<Mentors_Bool_Exp>;
};

/** aggregated selection of "mentor_additional_data" */
export type Mentor_Additional_Data_Aggregate = {
  __typename?: 'mentor_additional_data_aggregate';
  aggregate?: Maybe<Mentor_Additional_Data_Aggregate_Fields>;
  nodes: Array<Mentor_Additional_Data>;
};

/** aggregate fields of "mentor_additional_data" */
export type Mentor_Additional_Data_Aggregate_Fields = {
  __typename?: 'mentor_additional_data_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mentor_Additional_Data_Max_Fields>;
  min?: Maybe<Mentor_Additional_Data_Min_Fields>;
};


/** aggregate fields of "mentor_additional_data" */
export type Mentor_Additional_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mentor_Additional_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "mentor_additional_data". All fields are combined with a logical 'AND'. */
export type Mentor_Additional_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Mentor_Additional_Data_Bool_Exp>>;
  _not?: InputMaybe<Mentor_Additional_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Mentor_Additional_Data_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  linkedin?: InputMaybe<String_Comparison_Exp>;
  mentor_additional_data_mentors?: InputMaybe<Mentors_Bool_Exp>;
  mentor_additional_data_mentors_aggregate?: InputMaybe<Mentors_Aggregate_Bool_Exp>;
  organization?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  resume?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "mentor_additional_data" */
export enum Mentor_Additional_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  MentorAdditionalDataPkey = 'mentor_additional_data_pkey'
}

/** input type for inserting data into table "mentor_additional_data" */
export type Mentor_Additional_Data_Insert_Input = {
  city?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  linkedin?: InputMaybe<Scalars['String']>;
  mentor_additional_data_mentors?: InputMaybe<Mentors_Arr_Rel_Insert_Input>;
  organization?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  resume?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Mentor_Additional_Data_Max_Fields = {
  __typename?: 'mentor_additional_data_max_fields';
  city?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  linkedin?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Mentor_Additional_Data_Min_Fields = {
  __typename?: 'mentor_additional_data_min_fields';
  city?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  linkedin?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  resume?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "mentor_additional_data" */
export type Mentor_Additional_Data_Mutation_Response = {
  __typename?: 'mentor_additional_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mentor_Additional_Data>;
};

/** input type for inserting object relation for remote table "mentor_additional_data" */
export type Mentor_Additional_Data_Obj_Rel_Insert_Input = {
  data: Mentor_Additional_Data_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentor_Additional_Data_On_Conflict>;
};

/** on_conflict condition type for table "mentor_additional_data" */
export type Mentor_Additional_Data_On_Conflict = {
  constraint: Mentor_Additional_Data_Constraint;
  update_columns?: Array<Mentor_Additional_Data_Update_Column>;
  where?: InputMaybe<Mentor_Additional_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "mentor_additional_data". */
export type Mentor_Additional_Data_Order_By = {
  city?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  linkedin?: InputMaybe<Order_By>;
  mentor_additional_data_mentors_aggregate?: InputMaybe<Mentors_Aggregate_Order_By>;
  organization?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  resume?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mentor_additional_data */
export type Mentor_Additional_Data_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "mentor_additional_data" */
export enum Mentor_Additional_Data_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Id = 'id',
  /** column name */
  Linkedin = 'linkedin',
  /** column name */
  Organization = 'organization',
  /** column name */
  Phone = 'phone',
  /** column name */
  Resume = 'resume',
  /** column name */
  State = 'state'
}

/** input type for updating data in table "mentor_additional_data" */
export type Mentor_Additional_Data_Set_Input = {
  city?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  linkedin?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  resume?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "mentor_additional_data" */
export type Mentor_Additional_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mentor_Additional_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mentor_Additional_Data_Stream_Cursor_Value_Input = {
  city?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  linkedin?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  resume?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** update columns of table "mentor_additional_data" */
export enum Mentor_Additional_Data_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Id = 'id',
  /** column name */
  Linkedin = 'linkedin',
  /** column name */
  Organization = 'organization',
  /** column name */
  Phone = 'phone',
  /** column name */
  Resume = 'resume',
  /** column name */
  State = 'state'
}

export type Mentor_Additional_Data_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mentor_Additional_Data_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mentor_Additional_Data_Bool_Exp;
};

/** columns and relationships of "mentor_tags" */
export type Mentor_Tags = {
  __typename?: 'mentor_tags';
  name: Scalars['String'];
};

/** aggregated selection of "mentor_tags" */
export type Mentor_Tags_Aggregate = {
  __typename?: 'mentor_tags_aggregate';
  aggregate?: Maybe<Mentor_Tags_Aggregate_Fields>;
  nodes: Array<Mentor_Tags>;
};

/** aggregate fields of "mentor_tags" */
export type Mentor_Tags_Aggregate_Fields = {
  __typename?: 'mentor_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mentor_Tags_Max_Fields>;
  min?: Maybe<Mentor_Tags_Min_Fields>;
};


/** aggregate fields of "mentor_tags" */
export type Mentor_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mentor_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "mentor_tags". All fields are combined with a logical 'AND'. */
export type Mentor_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Mentor_Tags_Bool_Exp>>;
  _not?: InputMaybe<Mentor_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Mentor_Tags_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "mentor_tags" */
export enum Mentor_Tags_Constraint {
  /** unique or primary key constraint on columns "name" */
  MentorTagsNameKey = 'mentor_tags_name_key',
  /** unique or primary key constraint on columns "name" */
  MentorTagsPkey = 'mentor_tags_pkey'
}

/** input type for inserting data into table "mentor_tags" */
export type Mentor_Tags_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Mentor_Tags_Max_Fields = {
  __typename?: 'mentor_tags_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Mentor_Tags_Min_Fields = {
  __typename?: 'mentor_tags_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "mentor_tags" */
export type Mentor_Tags_Mutation_Response = {
  __typename?: 'mentor_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mentor_Tags>;
};

/** input type for inserting object relation for remote table "mentor_tags" */
export type Mentor_Tags_Obj_Rel_Insert_Input = {
  data: Mentor_Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentor_Tags_On_Conflict>;
};

/** on_conflict condition type for table "mentor_tags" */
export type Mentor_Tags_On_Conflict = {
  constraint: Mentor_Tags_Constraint;
  update_columns?: Array<Mentor_Tags_Update_Column>;
  where?: InputMaybe<Mentor_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "mentor_tags". */
export type Mentor_Tags_Order_By = {
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mentor_tags */
export type Mentor_Tags_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "mentor_tags" */
export enum Mentor_Tags_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "mentor_tags" */
export type Mentor_Tags_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "mentor_tags" */
export type Mentor_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mentor_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mentor_Tags_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "mentor_tags" */
export enum Mentor_Tags_Update_Column {
  /** column name */
  Name = 'name'
}

export type Mentor_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mentor_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mentor_Tags_Bool_Exp;
};

/** columns and relationships of "mentoring_records" */
export type Mentoring_Records = {
  __typename?: 'mentoring_records';
  date: Scalars['timestamptz'];
  duration: Scalars['smallint'];
  id: Scalars['uuid'];
  /** An array relationship */
  members: Array<Mentoring_Records_Members>;
  /** An aggregate relationship */
  members_aggregate: Mentoring_Records_Members_Aggregate;
  /** An array relationship */
  mentoring_records_attachments: Array<Mentoring_Records_Attachments>;
  /** An aggregate relationship */
  mentoring_records_attachments_aggregate: Mentoring_Records_Attachments_Aggregate;
  record: Scalars['String'];
  /** An object relationship */
  schedules?: Maybe<Mentoring_Scheduling>;
  scheduling_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};


/** columns and relationships of "mentoring_records" */
export type Mentoring_RecordsMembersArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
};


/** columns and relationships of "mentoring_records" */
export type Mentoring_RecordsMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
};


/** columns and relationships of "mentoring_records" */
export type Mentoring_RecordsMentoring_Records_AttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Attachments_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Attachments_Bool_Exp>;
};


/** columns and relationships of "mentoring_records" */
export type Mentoring_RecordsMentoring_Records_Attachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Attachments_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Attachments_Bool_Exp>;
};

/** aggregated selection of "mentoring_records" */
export type Mentoring_Records_Aggregate = {
  __typename?: 'mentoring_records_aggregate';
  aggregate?: Maybe<Mentoring_Records_Aggregate_Fields>;
  nodes: Array<Mentoring_Records>;
};

/** aggregate fields of "mentoring_records" */
export type Mentoring_Records_Aggregate_Fields = {
  __typename?: 'mentoring_records_aggregate_fields';
  avg?: Maybe<Mentoring_Records_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mentoring_Records_Max_Fields>;
  min?: Maybe<Mentoring_Records_Min_Fields>;
  stddev?: Maybe<Mentoring_Records_Stddev_Fields>;
  stddev_pop?: Maybe<Mentoring_Records_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mentoring_Records_Stddev_Samp_Fields>;
  sum?: Maybe<Mentoring_Records_Sum_Fields>;
  var_pop?: Maybe<Mentoring_Records_Var_Pop_Fields>;
  var_samp?: Maybe<Mentoring_Records_Var_Samp_Fields>;
  variance?: Maybe<Mentoring_Records_Variance_Fields>;
};


/** aggregate fields of "mentoring_records" */
export type Mentoring_Records_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mentoring_Records_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "mentoring_records_attachments" */
export type Mentoring_Records_Attachments = {
  __typename?: 'mentoring_records_attachments';
  attachment: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  label: Scalars['String'];
  /** An object relationship */
  mentoring_record: Mentoring_Records;
  mentoring_record_id: Scalars['uuid'];
};

/** aggregated selection of "mentoring_records_attachments" */
export type Mentoring_Records_Attachments_Aggregate = {
  __typename?: 'mentoring_records_attachments_aggregate';
  aggregate?: Maybe<Mentoring_Records_Attachments_Aggregate_Fields>;
  nodes: Array<Mentoring_Records_Attachments>;
};

export type Mentoring_Records_Attachments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mentoring_Records_Attachments_Aggregate_Bool_Exp_Count>;
};

export type Mentoring_Records_Attachments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mentoring_Records_Attachments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mentoring_Records_Attachments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mentoring_records_attachments" */
export type Mentoring_Records_Attachments_Aggregate_Fields = {
  __typename?: 'mentoring_records_attachments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mentoring_Records_Attachments_Max_Fields>;
  min?: Maybe<Mentoring_Records_Attachments_Min_Fields>;
};


/** aggregate fields of "mentoring_records_attachments" */
export type Mentoring_Records_Attachments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mentoring_Records_Attachments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mentoring_records_attachments" */
export type Mentoring_Records_Attachments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mentoring_Records_Attachments_Max_Order_By>;
  min?: InputMaybe<Mentoring_Records_Attachments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "mentoring_records_attachments" */
export type Mentoring_Records_Attachments_Arr_Rel_Insert_Input = {
  data: Array<Mentoring_Records_Attachments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentoring_Records_Attachments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "mentoring_records_attachments". All fields are combined with a logical 'AND'. */
export type Mentoring_Records_Attachments_Bool_Exp = {
  _and?: InputMaybe<Array<Mentoring_Records_Attachments_Bool_Exp>>;
  _not?: InputMaybe<Mentoring_Records_Attachments_Bool_Exp>;
  _or?: InputMaybe<Array<Mentoring_Records_Attachments_Bool_Exp>>;
  attachment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  mentoring_record?: InputMaybe<Mentoring_Records_Bool_Exp>;
  mentoring_record_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mentoring_records_attachments" */
export enum Mentoring_Records_Attachments_Constraint {
  /** unique or primary key constraint on columns "id" */
  MentoringRecordsAttachmentsPkey = 'mentoring_records_attachments_pkey'
}

/** input type for inserting data into table "mentoring_records_attachments" */
export type Mentoring_Records_Attachments_Insert_Input = {
  attachment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  label?: InputMaybe<Scalars['String']>;
  mentoring_record?: InputMaybe<Mentoring_Records_Obj_Rel_Insert_Input>;
  mentoring_record_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Mentoring_Records_Attachments_Max_Fields = {
  __typename?: 'mentoring_records_attachments_max_fields';
  attachment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  mentoring_record_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "mentoring_records_attachments" */
export type Mentoring_Records_Attachments_Max_Order_By = {
  attachment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  mentoring_record_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mentoring_Records_Attachments_Min_Fields = {
  __typename?: 'mentoring_records_attachments_min_fields';
  attachment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  mentoring_record_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "mentoring_records_attachments" */
export type Mentoring_Records_Attachments_Min_Order_By = {
  attachment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  mentoring_record_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mentoring_records_attachments" */
export type Mentoring_Records_Attachments_Mutation_Response = {
  __typename?: 'mentoring_records_attachments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mentoring_Records_Attachments>;
};

/** on_conflict condition type for table "mentoring_records_attachments" */
export type Mentoring_Records_Attachments_On_Conflict = {
  constraint: Mentoring_Records_Attachments_Constraint;
  update_columns?: Array<Mentoring_Records_Attachments_Update_Column>;
  where?: InputMaybe<Mentoring_Records_Attachments_Bool_Exp>;
};

/** Ordering options when selecting data from "mentoring_records_attachments". */
export type Mentoring_Records_Attachments_Order_By = {
  attachment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  mentoring_record?: InputMaybe<Mentoring_Records_Order_By>;
  mentoring_record_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mentoring_records_attachments */
export type Mentoring_Records_Attachments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "mentoring_records_attachments" */
export enum Mentoring_Records_Attachments_Select_Column {
  /** column name */
  Attachment = 'attachment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  MentoringRecordId = 'mentoring_record_id'
}

/** input type for updating data in table "mentoring_records_attachments" */
export type Mentoring_Records_Attachments_Set_Input = {
  attachment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  label?: InputMaybe<Scalars['String']>;
  mentoring_record_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "mentoring_records_attachments" */
export type Mentoring_Records_Attachments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mentoring_Records_Attachments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mentoring_Records_Attachments_Stream_Cursor_Value_Input = {
  attachment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  label?: InputMaybe<Scalars['String']>;
  mentoring_record_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "mentoring_records_attachments" */
export enum Mentoring_Records_Attachments_Update_Column {
  /** column name */
  Attachment = 'attachment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  MentoringRecordId = 'mentoring_record_id'
}

export type Mentoring_Records_Attachments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mentoring_Records_Attachments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mentoring_Records_Attachments_Bool_Exp;
};

/** aggregate avg on columns */
export type Mentoring_Records_Avg_Fields = {
  __typename?: 'mentoring_records_avg_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "mentoring_records". All fields are combined with a logical 'AND'. */
export type Mentoring_Records_Bool_Exp = {
  _and?: InputMaybe<Array<Mentoring_Records_Bool_Exp>>;
  _not?: InputMaybe<Mentoring_Records_Bool_Exp>;
  _or?: InputMaybe<Array<Mentoring_Records_Bool_Exp>>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  duration?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  members?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
  members_aggregate?: InputMaybe<Mentoring_Records_Members_Aggregate_Bool_Exp>;
  mentoring_records_attachments?: InputMaybe<Mentoring_Records_Attachments_Bool_Exp>;
  mentoring_records_attachments_aggregate?: InputMaybe<Mentoring_Records_Attachments_Aggregate_Bool_Exp>;
  record?: InputMaybe<String_Comparison_Exp>;
  schedules?: InputMaybe<Mentoring_Scheduling_Bool_Exp>;
  scheduling_id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mentoring_records" */
export enum Mentoring_Records_Constraint {
  /** unique or primary key constraint on columns "id" */
  MentoringRecordsPkey = 'mentoring_records_pkey'
}

/** input type for incrementing numeric columns in table "mentoring_records" */
export type Mentoring_Records_Inc_Input = {
  duration?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "mentoring_records" */
export type Mentoring_Records_Insert_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['uuid']>;
  members?: InputMaybe<Mentoring_Records_Members_Arr_Rel_Insert_Input>;
  mentoring_records_attachments?: InputMaybe<Mentoring_Records_Attachments_Arr_Rel_Insert_Input>;
  record?: InputMaybe<Scalars['String']>;
  schedules?: InputMaybe<Mentoring_Scheduling_Obj_Rel_Insert_Input>;
  scheduling_id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Mentoring_Records_Max_Fields = {
  __typename?: 'mentoring_records_max_fields';
  date?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['uuid']>;
  record?: Maybe<Scalars['String']>;
  scheduling_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "mentoring_records_members" */
export type Mentoring_Records_Members = {
  __typename?: 'mentoring_records_members';
  /** An object relationship */
  member: Users;
  member_id: Scalars['String'];
  /** An object relationship */
  mentoring_record: Mentoring_Records;
  mentoring_record_id: Scalars['uuid'];
};

/** aggregated selection of "mentoring_records_members" */
export type Mentoring_Records_Members_Aggregate = {
  __typename?: 'mentoring_records_members_aggregate';
  aggregate?: Maybe<Mentoring_Records_Members_Aggregate_Fields>;
  nodes: Array<Mentoring_Records_Members>;
};

export type Mentoring_Records_Members_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mentoring_Records_Members_Aggregate_Bool_Exp_Count>;
};

export type Mentoring_Records_Members_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mentoring_Records_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mentoring_records_members" */
export type Mentoring_Records_Members_Aggregate_Fields = {
  __typename?: 'mentoring_records_members_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mentoring_Records_Members_Max_Fields>;
  min?: Maybe<Mentoring_Records_Members_Min_Fields>;
};


/** aggregate fields of "mentoring_records_members" */
export type Mentoring_Records_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mentoring_Records_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mentoring_records_members" */
export type Mentoring_Records_Members_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mentoring_Records_Members_Max_Order_By>;
  min?: InputMaybe<Mentoring_Records_Members_Min_Order_By>;
};

/** input type for inserting array relation for remote table "mentoring_records_members" */
export type Mentoring_Records_Members_Arr_Rel_Insert_Input = {
  data: Array<Mentoring_Records_Members_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentoring_Records_Members_On_Conflict>;
};

/** Boolean expression to filter rows from the table "mentoring_records_members". All fields are combined with a logical 'AND'. */
export type Mentoring_Records_Members_Bool_Exp = {
  _and?: InputMaybe<Array<Mentoring_Records_Members_Bool_Exp>>;
  _not?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
  _or?: InputMaybe<Array<Mentoring_Records_Members_Bool_Exp>>;
  member?: InputMaybe<Users_Bool_Exp>;
  member_id?: InputMaybe<String_Comparison_Exp>;
  mentoring_record?: InputMaybe<Mentoring_Records_Bool_Exp>;
  mentoring_record_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mentoring_records_members" */
export enum Mentoring_Records_Members_Constraint {
  /** unique or primary key constraint on columns "member_id", "mentoring_record_id" */
  MentoringRecordsMembersPkey = 'mentoring_records_members_pkey'
}

/** input type for inserting data into table "mentoring_records_members" */
export type Mentoring_Records_Members_Insert_Input = {
  member?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  member_id?: InputMaybe<Scalars['String']>;
  mentoring_record?: InputMaybe<Mentoring_Records_Obj_Rel_Insert_Input>;
  mentoring_record_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Mentoring_Records_Members_Max_Fields = {
  __typename?: 'mentoring_records_members_max_fields';
  member_id?: Maybe<Scalars['String']>;
  mentoring_record_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "mentoring_records_members" */
export type Mentoring_Records_Members_Max_Order_By = {
  member_id?: InputMaybe<Order_By>;
  mentoring_record_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mentoring_Records_Members_Min_Fields = {
  __typename?: 'mentoring_records_members_min_fields';
  member_id?: Maybe<Scalars['String']>;
  mentoring_record_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "mentoring_records_members" */
export type Mentoring_Records_Members_Min_Order_By = {
  member_id?: InputMaybe<Order_By>;
  mentoring_record_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mentoring_records_members" */
export type Mentoring_Records_Members_Mutation_Response = {
  __typename?: 'mentoring_records_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mentoring_Records_Members>;
};

/** on_conflict condition type for table "mentoring_records_members" */
export type Mentoring_Records_Members_On_Conflict = {
  constraint: Mentoring_Records_Members_Constraint;
  update_columns?: Array<Mentoring_Records_Members_Update_Column>;
  where?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
};

/** Ordering options when selecting data from "mentoring_records_members". */
export type Mentoring_Records_Members_Order_By = {
  member?: InputMaybe<Users_Order_By>;
  member_id?: InputMaybe<Order_By>;
  mentoring_record?: InputMaybe<Mentoring_Records_Order_By>;
  mentoring_record_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mentoring_records_members */
export type Mentoring_Records_Members_Pk_Columns_Input = {
  member_id: Scalars['String'];
  mentoring_record_id: Scalars['uuid'];
};

/** select columns of table "mentoring_records_members" */
export enum Mentoring_Records_Members_Select_Column {
  /** column name */
  MemberId = 'member_id',
  /** column name */
  MentoringRecordId = 'mentoring_record_id'
}

/** input type for updating data in table "mentoring_records_members" */
export type Mentoring_Records_Members_Set_Input = {
  member_id?: InputMaybe<Scalars['String']>;
  mentoring_record_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "mentoring_records_members" */
export type Mentoring_Records_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mentoring_Records_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mentoring_Records_Members_Stream_Cursor_Value_Input = {
  member_id?: InputMaybe<Scalars['String']>;
  mentoring_record_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "mentoring_records_members" */
export enum Mentoring_Records_Members_Update_Column {
  /** column name */
  MemberId = 'member_id',
  /** column name */
  MentoringRecordId = 'mentoring_record_id'
}

export type Mentoring_Records_Members_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mentoring_Records_Members_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mentoring_Records_Members_Bool_Exp;
};

/** aggregate min on columns */
export type Mentoring_Records_Min_Fields = {
  __typename?: 'mentoring_records_min_fields';
  date?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['uuid']>;
  record?: Maybe<Scalars['String']>;
  scheduling_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "mentoring_records" */
export type Mentoring_Records_Mutation_Response = {
  __typename?: 'mentoring_records_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mentoring_Records>;
};

/** input type for inserting object relation for remote table "mentoring_records" */
export type Mentoring_Records_Obj_Rel_Insert_Input = {
  data: Mentoring_Records_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentoring_Records_On_Conflict>;
};

/** on_conflict condition type for table "mentoring_records" */
export type Mentoring_Records_On_Conflict = {
  constraint: Mentoring_Records_Constraint;
  update_columns?: Array<Mentoring_Records_Update_Column>;
  where?: InputMaybe<Mentoring_Records_Bool_Exp>;
};

/** Ordering options when selecting data from "mentoring_records". */
export type Mentoring_Records_Order_By = {
  date?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  members_aggregate?: InputMaybe<Mentoring_Records_Members_Aggregate_Order_By>;
  mentoring_records_attachments_aggregate?: InputMaybe<Mentoring_Records_Attachments_Aggregate_Order_By>;
  record?: InputMaybe<Order_By>;
  schedules?: InputMaybe<Mentoring_Scheduling_Order_By>;
  scheduling_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mentoring_records */
export type Mentoring_Records_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "mentoring_records" */
export enum Mentoring_Records_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Record = 'record',
  /** column name */
  SchedulingId = 'scheduling_id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "mentoring_records" */
export type Mentoring_Records_Set_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['uuid']>;
  record?: InputMaybe<Scalars['String']>;
  scheduling_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Mentoring_Records_Stddev_Fields = {
  __typename?: 'mentoring_records_stddev_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Mentoring_Records_Stddev_Pop_Fields = {
  __typename?: 'mentoring_records_stddev_pop_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Mentoring_Records_Stddev_Samp_Fields = {
  __typename?: 'mentoring_records_stddev_samp_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "mentoring_records" */
export type Mentoring_Records_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mentoring_Records_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mentoring_Records_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['uuid']>;
  record?: InputMaybe<Scalars['String']>;
  scheduling_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Mentoring_Records_Sum_Fields = {
  __typename?: 'mentoring_records_sum_fields';
  duration?: Maybe<Scalars['smallint']>;
};

/** update columns of table "mentoring_records" */
export enum Mentoring_Records_Update_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Record = 'record',
  /** column name */
  SchedulingId = 'scheduling_id',
  /** column name */
  TeamId = 'team_id'
}

export type Mentoring_Records_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mentoring_Records_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mentoring_Records_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mentoring_Records_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mentoring_Records_Var_Pop_Fields = {
  __typename?: 'mentoring_records_var_pop_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Mentoring_Records_Var_Samp_Fields = {
  __typename?: 'mentoring_records_var_samp_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Mentoring_Records_Variance_Fields = {
  __typename?: 'mentoring_records_variance_fields';
  duration?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "mentoring_reschedules" */
export type Mentoring_Reschedules = {
  __typename?: 'mentoring_reschedules';
  description: Scalars['String'];
  id: Scalars['uuid'];
  new_date: Scalars['timestamp'];
  old_date: Scalars['timestamp'];
  /** An object relationship */
  schedule: Mentoring_Scheduling;
  schedule_id: Scalars['uuid'];
};

/** aggregated selection of "mentoring_reschedules" */
export type Mentoring_Reschedules_Aggregate = {
  __typename?: 'mentoring_reschedules_aggregate';
  aggregate?: Maybe<Mentoring_Reschedules_Aggregate_Fields>;
  nodes: Array<Mentoring_Reschedules>;
};

/** aggregate fields of "mentoring_reschedules" */
export type Mentoring_Reschedules_Aggregate_Fields = {
  __typename?: 'mentoring_reschedules_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mentoring_Reschedules_Max_Fields>;
  min?: Maybe<Mentoring_Reschedules_Min_Fields>;
};


/** aggregate fields of "mentoring_reschedules" */
export type Mentoring_Reschedules_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mentoring_Reschedules_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "mentoring_reschedules". All fields are combined with a logical 'AND'. */
export type Mentoring_Reschedules_Bool_Exp = {
  _and?: InputMaybe<Array<Mentoring_Reschedules_Bool_Exp>>;
  _not?: InputMaybe<Mentoring_Reschedules_Bool_Exp>;
  _or?: InputMaybe<Array<Mentoring_Reschedules_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  new_date?: InputMaybe<Timestamp_Comparison_Exp>;
  old_date?: InputMaybe<Timestamp_Comparison_Exp>;
  schedule?: InputMaybe<Mentoring_Scheduling_Bool_Exp>;
  schedule_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mentoring_reschedules" */
export enum Mentoring_Reschedules_Constraint {
  /** unique or primary key constraint on columns "id" */
  MentoringReschedulesPkey = 'mentoring_reschedules_pkey'
}

/** input type for inserting data into table "mentoring_reschedules" */
export type Mentoring_Reschedules_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  new_date?: InputMaybe<Scalars['timestamp']>;
  old_date?: InputMaybe<Scalars['timestamp']>;
  schedule?: InputMaybe<Mentoring_Scheduling_Obj_Rel_Insert_Input>;
  schedule_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Mentoring_Reschedules_Max_Fields = {
  __typename?: 'mentoring_reschedules_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  new_date?: Maybe<Scalars['timestamp']>;
  old_date?: Maybe<Scalars['timestamp']>;
  schedule_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Mentoring_Reschedules_Min_Fields = {
  __typename?: 'mentoring_reschedules_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  new_date?: Maybe<Scalars['timestamp']>;
  old_date?: Maybe<Scalars['timestamp']>;
  schedule_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "mentoring_reschedules" */
export type Mentoring_Reschedules_Mutation_Response = {
  __typename?: 'mentoring_reschedules_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mentoring_Reschedules>;
};

/** input type for inserting object relation for remote table "mentoring_reschedules" */
export type Mentoring_Reschedules_Obj_Rel_Insert_Input = {
  data: Mentoring_Reschedules_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentoring_Reschedules_On_Conflict>;
};

/** on_conflict condition type for table "mentoring_reschedules" */
export type Mentoring_Reschedules_On_Conflict = {
  constraint: Mentoring_Reschedules_Constraint;
  update_columns?: Array<Mentoring_Reschedules_Update_Column>;
  where?: InputMaybe<Mentoring_Reschedules_Bool_Exp>;
};

/** Ordering options when selecting data from "mentoring_reschedules". */
export type Mentoring_Reschedules_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_date?: InputMaybe<Order_By>;
  old_date?: InputMaybe<Order_By>;
  schedule?: InputMaybe<Mentoring_Scheduling_Order_By>;
  schedule_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mentoring_reschedules */
export type Mentoring_Reschedules_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "mentoring_reschedules" */
export enum Mentoring_Reschedules_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NewDate = 'new_date',
  /** column name */
  OldDate = 'old_date',
  /** column name */
  ScheduleId = 'schedule_id'
}

/** input type for updating data in table "mentoring_reschedules" */
export type Mentoring_Reschedules_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  new_date?: InputMaybe<Scalars['timestamp']>;
  old_date?: InputMaybe<Scalars['timestamp']>;
  schedule_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "mentoring_reschedules" */
export type Mentoring_Reschedules_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mentoring_Reschedules_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mentoring_Reschedules_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  new_date?: InputMaybe<Scalars['timestamp']>;
  old_date?: InputMaybe<Scalars['timestamp']>;
  schedule_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "mentoring_reschedules" */
export enum Mentoring_Reschedules_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NewDate = 'new_date',
  /** column name */
  OldDate = 'old_date',
  /** column name */
  ScheduleId = 'schedule_id'
}

export type Mentoring_Reschedules_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mentoring_Reschedules_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mentoring_Reschedules_Bool_Exp;
};

/** columns and relationships of "mentoring_scheduling" */
export type Mentoring_Scheduling = {
  __typename?: 'mentoring_scheduling';
  completed: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  date: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  meeting?: Maybe<Mentoring_Scheduling_Meeting>;
  meeting_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  mentor?: Maybe<Mentors>;
  mentor_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  mentoring_scheduling_members: Array<Mentoring_Scheduling_Members>;
  /** An aggregate relationship */
  mentoring_scheduling_members_aggregate: Mentoring_Scheduling_Members_Aggregate;
  /** An object relationship */
  record?: Maybe<Mentoring_Records>;
  record_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  request?: Maybe<Requested_Mentorings>;
  request_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  reschedule?: Maybe<Mentoring_Reschedules>;
  reschedule_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};


/** columns and relationships of "mentoring_scheduling" */
export type Mentoring_SchedulingMentoring_Scheduling_MembersArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Scheduling_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Scheduling_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Scheduling_Members_Bool_Exp>;
};


/** columns and relationships of "mentoring_scheduling" */
export type Mentoring_SchedulingMentoring_Scheduling_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Scheduling_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Scheduling_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Scheduling_Members_Bool_Exp>;
};

/** aggregated selection of "mentoring_scheduling" */
export type Mentoring_Scheduling_Aggregate = {
  __typename?: 'mentoring_scheduling_aggregate';
  aggregate?: Maybe<Mentoring_Scheduling_Aggregate_Fields>;
  nodes: Array<Mentoring_Scheduling>;
};

/** aggregate fields of "mentoring_scheduling" */
export type Mentoring_Scheduling_Aggregate_Fields = {
  __typename?: 'mentoring_scheduling_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mentoring_Scheduling_Max_Fields>;
  min?: Maybe<Mentoring_Scheduling_Min_Fields>;
};


/** aggregate fields of "mentoring_scheduling" */
export type Mentoring_Scheduling_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mentoring_Scheduling_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "mentoring_scheduling". All fields are combined with a logical 'AND'. */
export type Mentoring_Scheduling_Bool_Exp = {
  _and?: InputMaybe<Array<Mentoring_Scheduling_Bool_Exp>>;
  _not?: InputMaybe<Mentoring_Scheduling_Bool_Exp>;
  _or?: InputMaybe<Array<Mentoring_Scheduling_Bool_Exp>>;
  completed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meeting?: InputMaybe<Mentoring_Scheduling_Meeting_Bool_Exp>;
  meeting_id?: InputMaybe<Uuid_Comparison_Exp>;
  mentor?: InputMaybe<Mentors_Bool_Exp>;
  mentor_id?: InputMaybe<Uuid_Comparison_Exp>;
  mentoring_scheduling_members?: InputMaybe<Mentoring_Scheduling_Members_Bool_Exp>;
  mentoring_scheduling_members_aggregate?: InputMaybe<Mentoring_Scheduling_Members_Aggregate_Bool_Exp>;
  record?: InputMaybe<Mentoring_Records_Bool_Exp>;
  record_id?: InputMaybe<Uuid_Comparison_Exp>;
  request?: InputMaybe<Requested_Mentorings_Bool_Exp>;
  request_id?: InputMaybe<Uuid_Comparison_Exp>;
  reschedule?: InputMaybe<Mentoring_Reschedules_Bool_Exp>;
  reschedule_id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mentoring_scheduling" */
export enum Mentoring_Scheduling_Constraint {
  /** unique or primary key constraint on columns "id" */
  MentoringSchedulingPkey = 'mentoring_scheduling_pkey'
}

/** input type for inserting data into table "mentoring_scheduling" */
export type Mentoring_Scheduling_Insert_Input = {
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meeting?: InputMaybe<Mentoring_Scheduling_Meeting_Obj_Rel_Insert_Input>;
  meeting_id?: InputMaybe<Scalars['uuid']>;
  mentor?: InputMaybe<Mentors_Obj_Rel_Insert_Input>;
  mentor_id?: InputMaybe<Scalars['uuid']>;
  mentoring_scheduling_members?: InputMaybe<Mentoring_Scheduling_Members_Arr_Rel_Insert_Input>;
  record?: InputMaybe<Mentoring_Records_Obj_Rel_Insert_Input>;
  record_id?: InputMaybe<Scalars['uuid']>;
  request?: InputMaybe<Requested_Mentorings_Obj_Rel_Insert_Input>;
  request_id?: InputMaybe<Scalars['uuid']>;
  reschedule?: InputMaybe<Mentoring_Reschedules_Obj_Rel_Insert_Input>;
  reschedule_id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Mentoring_Scheduling_Max_Fields = {
  __typename?: 'mentoring_scheduling_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_id?: Maybe<Scalars['uuid']>;
  mentor_id?: Maybe<Scalars['uuid']>;
  record_id?: Maybe<Scalars['uuid']>;
  request_id?: Maybe<Scalars['uuid']>;
  reschedule_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "mentoring_scheduling_meeting" */
export type Mentoring_Scheduling_Meeting = {
  __typename?: 'mentoring_scheduling_meeting';
  contact: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  scheduling?: Maybe<Mentoring_Scheduling>;
  title: Scalars['String'];
};

/** aggregated selection of "mentoring_scheduling_meeting" */
export type Mentoring_Scheduling_Meeting_Aggregate = {
  __typename?: 'mentoring_scheduling_meeting_aggregate';
  aggregate?: Maybe<Mentoring_Scheduling_Meeting_Aggregate_Fields>;
  nodes: Array<Mentoring_Scheduling_Meeting>;
};

/** aggregate fields of "mentoring_scheduling_meeting" */
export type Mentoring_Scheduling_Meeting_Aggregate_Fields = {
  __typename?: 'mentoring_scheduling_meeting_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mentoring_Scheduling_Meeting_Max_Fields>;
  min?: Maybe<Mentoring_Scheduling_Meeting_Min_Fields>;
};


/** aggregate fields of "mentoring_scheduling_meeting" */
export type Mentoring_Scheduling_Meeting_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mentoring_Scheduling_Meeting_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "mentoring_scheduling_meeting". All fields are combined with a logical 'AND'. */
export type Mentoring_Scheduling_Meeting_Bool_Exp = {
  _and?: InputMaybe<Array<Mentoring_Scheduling_Meeting_Bool_Exp>>;
  _not?: InputMaybe<Mentoring_Scheduling_Meeting_Bool_Exp>;
  _or?: InputMaybe<Array<Mentoring_Scheduling_Meeting_Bool_Exp>>;
  contact?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  scheduling?: InputMaybe<Mentoring_Scheduling_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "mentoring_scheduling_meeting" */
export enum Mentoring_Scheduling_Meeting_Constraint {
  /** unique or primary key constraint on columns "id" */
  MentoringSchedulingMeetingPkey = 'mentoring_scheduling_meeting_pkey'
}

/** input type for inserting data into table "mentoring_scheduling_meeting" */
export type Mentoring_Scheduling_Meeting_Insert_Input = {
  contact?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  scheduling?: InputMaybe<Mentoring_Scheduling_Obj_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Mentoring_Scheduling_Meeting_Max_Fields = {
  __typename?: 'mentoring_scheduling_meeting_max_fields';
  contact?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Mentoring_Scheduling_Meeting_Min_Fields = {
  __typename?: 'mentoring_scheduling_meeting_min_fields';
  contact?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "mentoring_scheduling_meeting" */
export type Mentoring_Scheduling_Meeting_Mutation_Response = {
  __typename?: 'mentoring_scheduling_meeting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mentoring_Scheduling_Meeting>;
};

/** input type for inserting object relation for remote table "mentoring_scheduling_meeting" */
export type Mentoring_Scheduling_Meeting_Obj_Rel_Insert_Input = {
  data: Mentoring_Scheduling_Meeting_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentoring_Scheduling_Meeting_On_Conflict>;
};

/** on_conflict condition type for table "mentoring_scheduling_meeting" */
export type Mentoring_Scheduling_Meeting_On_Conflict = {
  constraint: Mentoring_Scheduling_Meeting_Constraint;
  update_columns?: Array<Mentoring_Scheduling_Meeting_Update_Column>;
  where?: InputMaybe<Mentoring_Scheduling_Meeting_Bool_Exp>;
};

/** Ordering options when selecting data from "mentoring_scheduling_meeting". */
export type Mentoring_Scheduling_Meeting_Order_By = {
  contact?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  scheduling?: InputMaybe<Mentoring_Scheduling_Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mentoring_scheduling_meeting */
export type Mentoring_Scheduling_Meeting_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "mentoring_scheduling_meeting" */
export enum Mentoring_Scheduling_Meeting_Select_Column {
  /** column name */
  Contact = 'contact',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "mentoring_scheduling_meeting" */
export type Mentoring_Scheduling_Meeting_Set_Input = {
  contact?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "mentoring_scheduling_meeting" */
export type Mentoring_Scheduling_Meeting_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mentoring_Scheduling_Meeting_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mentoring_Scheduling_Meeting_Stream_Cursor_Value_Input = {
  contact?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** update columns of table "mentoring_scheduling_meeting" */
export enum Mentoring_Scheduling_Meeting_Update_Column {
  /** column name */
  Contact = 'contact',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

export type Mentoring_Scheduling_Meeting_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mentoring_Scheduling_Meeting_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mentoring_Scheduling_Meeting_Bool_Exp;
};

/** columns and relationships of "mentoring_scheduling_members" */
export type Mentoring_Scheduling_Members = {
  __typename?: 'mentoring_scheduling_members';
  id: Scalars['uuid'];
  /** An object relationship */
  member: Users;
  member_id: Scalars['String'];
  /** An object relationship */
  mentoring_scheduling: Mentoring_Scheduling;
  mentoring_scheduling_id: Scalars['uuid'];
};

/** aggregated selection of "mentoring_scheduling_members" */
export type Mentoring_Scheduling_Members_Aggregate = {
  __typename?: 'mentoring_scheduling_members_aggregate';
  aggregate?: Maybe<Mentoring_Scheduling_Members_Aggregate_Fields>;
  nodes: Array<Mentoring_Scheduling_Members>;
};

export type Mentoring_Scheduling_Members_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mentoring_Scheduling_Members_Aggregate_Bool_Exp_Count>;
};

export type Mentoring_Scheduling_Members_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mentoring_Scheduling_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mentoring_Scheduling_Members_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mentoring_scheduling_members" */
export type Mentoring_Scheduling_Members_Aggregate_Fields = {
  __typename?: 'mentoring_scheduling_members_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mentoring_Scheduling_Members_Max_Fields>;
  min?: Maybe<Mentoring_Scheduling_Members_Min_Fields>;
};


/** aggregate fields of "mentoring_scheduling_members" */
export type Mentoring_Scheduling_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mentoring_Scheduling_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mentoring_scheduling_members" */
export type Mentoring_Scheduling_Members_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mentoring_Scheduling_Members_Max_Order_By>;
  min?: InputMaybe<Mentoring_Scheduling_Members_Min_Order_By>;
};

/** input type for inserting array relation for remote table "mentoring_scheduling_members" */
export type Mentoring_Scheduling_Members_Arr_Rel_Insert_Input = {
  data: Array<Mentoring_Scheduling_Members_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentoring_Scheduling_Members_On_Conflict>;
};

/** Boolean expression to filter rows from the table "mentoring_scheduling_members". All fields are combined with a logical 'AND'. */
export type Mentoring_Scheduling_Members_Bool_Exp = {
  _and?: InputMaybe<Array<Mentoring_Scheduling_Members_Bool_Exp>>;
  _not?: InputMaybe<Mentoring_Scheduling_Members_Bool_Exp>;
  _or?: InputMaybe<Array<Mentoring_Scheduling_Members_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  member?: InputMaybe<Users_Bool_Exp>;
  member_id?: InputMaybe<String_Comparison_Exp>;
  mentoring_scheduling?: InputMaybe<Mentoring_Scheduling_Bool_Exp>;
  mentoring_scheduling_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "mentoring_scheduling_members" */
export enum Mentoring_Scheduling_Members_Constraint {
  /** unique or primary key constraint on columns "member_id", "id", "mentoring_scheduling_id" */
  MentoringSchedulingMembersPkey = 'mentoring_scheduling_members_pkey'
}

/** input type for inserting data into table "mentoring_scheduling_members" */
export type Mentoring_Scheduling_Members_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  member?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  member_id?: InputMaybe<Scalars['String']>;
  mentoring_scheduling?: InputMaybe<Mentoring_Scheduling_Obj_Rel_Insert_Input>;
  mentoring_scheduling_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Mentoring_Scheduling_Members_Max_Fields = {
  __typename?: 'mentoring_scheduling_members_max_fields';
  id?: Maybe<Scalars['uuid']>;
  member_id?: Maybe<Scalars['String']>;
  mentoring_scheduling_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "mentoring_scheduling_members" */
export type Mentoring_Scheduling_Members_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  mentoring_scheduling_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mentoring_Scheduling_Members_Min_Fields = {
  __typename?: 'mentoring_scheduling_members_min_fields';
  id?: Maybe<Scalars['uuid']>;
  member_id?: Maybe<Scalars['String']>;
  mentoring_scheduling_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "mentoring_scheduling_members" */
export type Mentoring_Scheduling_Members_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  mentoring_scheduling_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mentoring_scheduling_members" */
export type Mentoring_Scheduling_Members_Mutation_Response = {
  __typename?: 'mentoring_scheduling_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mentoring_Scheduling_Members>;
};

/** on_conflict condition type for table "mentoring_scheduling_members" */
export type Mentoring_Scheduling_Members_On_Conflict = {
  constraint: Mentoring_Scheduling_Members_Constraint;
  update_columns?: Array<Mentoring_Scheduling_Members_Update_Column>;
  where?: InputMaybe<Mentoring_Scheduling_Members_Bool_Exp>;
};

/** Ordering options when selecting data from "mentoring_scheduling_members". */
export type Mentoring_Scheduling_Members_Order_By = {
  id?: InputMaybe<Order_By>;
  member?: InputMaybe<Users_Order_By>;
  member_id?: InputMaybe<Order_By>;
  mentoring_scheduling?: InputMaybe<Mentoring_Scheduling_Order_By>;
  mentoring_scheduling_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mentoring_scheduling_members */
export type Mentoring_Scheduling_Members_Pk_Columns_Input = {
  id: Scalars['uuid'];
  member_id: Scalars['String'];
  mentoring_scheduling_id: Scalars['uuid'];
};

/** select columns of table "mentoring_scheduling_members" */
export enum Mentoring_Scheduling_Members_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  MentoringSchedulingId = 'mentoring_scheduling_id'
}

/** input type for updating data in table "mentoring_scheduling_members" */
export type Mentoring_Scheduling_Members_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  member_id?: InputMaybe<Scalars['String']>;
  mentoring_scheduling_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "mentoring_scheduling_members" */
export type Mentoring_Scheduling_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mentoring_Scheduling_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mentoring_Scheduling_Members_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  member_id?: InputMaybe<Scalars['String']>;
  mentoring_scheduling_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "mentoring_scheduling_members" */
export enum Mentoring_Scheduling_Members_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  MentoringSchedulingId = 'mentoring_scheduling_id'
}

export type Mentoring_Scheduling_Members_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mentoring_Scheduling_Members_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mentoring_Scheduling_Members_Bool_Exp;
};

/** aggregate min on columns */
export type Mentoring_Scheduling_Min_Fields = {
  __typename?: 'mentoring_scheduling_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_id?: Maybe<Scalars['uuid']>;
  mentor_id?: Maybe<Scalars['uuid']>;
  record_id?: Maybe<Scalars['uuid']>;
  request_id?: Maybe<Scalars['uuid']>;
  reschedule_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "mentoring_scheduling" */
export type Mentoring_Scheduling_Mutation_Response = {
  __typename?: 'mentoring_scheduling_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mentoring_Scheduling>;
};

/** input type for inserting object relation for remote table "mentoring_scheduling" */
export type Mentoring_Scheduling_Obj_Rel_Insert_Input = {
  data: Mentoring_Scheduling_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentoring_Scheduling_On_Conflict>;
};

/** on_conflict condition type for table "mentoring_scheduling" */
export type Mentoring_Scheduling_On_Conflict = {
  constraint: Mentoring_Scheduling_Constraint;
  update_columns?: Array<Mentoring_Scheduling_Update_Column>;
  where?: InputMaybe<Mentoring_Scheduling_Bool_Exp>;
};

/** Ordering options when selecting data from "mentoring_scheduling". */
export type Mentoring_Scheduling_Order_By = {
  completed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting?: InputMaybe<Mentoring_Scheduling_Meeting_Order_By>;
  meeting_id?: InputMaybe<Order_By>;
  mentor?: InputMaybe<Mentors_Order_By>;
  mentor_id?: InputMaybe<Order_By>;
  mentoring_scheduling_members_aggregate?: InputMaybe<Mentoring_Scheduling_Members_Aggregate_Order_By>;
  record?: InputMaybe<Mentoring_Records_Order_By>;
  record_id?: InputMaybe<Order_By>;
  request?: InputMaybe<Requested_Mentorings_Order_By>;
  request_id?: InputMaybe<Order_By>;
  reschedule?: InputMaybe<Mentoring_Reschedules_Order_By>;
  reschedule_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mentoring_scheduling */
export type Mentoring_Scheduling_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "mentoring_scheduling" */
export enum Mentoring_Scheduling_Select_Column {
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  MentorId = 'mentor_id',
  /** column name */
  RecordId = 'record_id',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  RescheduleId = 'reschedule_id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "mentoring_scheduling" */
export type Mentoring_Scheduling_Set_Input = {
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meeting_id?: InputMaybe<Scalars['uuid']>;
  mentor_id?: InputMaybe<Scalars['uuid']>;
  record_id?: InputMaybe<Scalars['uuid']>;
  request_id?: InputMaybe<Scalars['uuid']>;
  reschedule_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "mentoring_scheduling" */
export type Mentoring_Scheduling_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mentoring_Scheduling_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mentoring_Scheduling_Stream_Cursor_Value_Input = {
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meeting_id?: InputMaybe<Scalars['uuid']>;
  mentor_id?: InputMaybe<Scalars['uuid']>;
  record_id?: InputMaybe<Scalars['uuid']>;
  request_id?: InputMaybe<Scalars['uuid']>;
  reschedule_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "mentoring_scheduling" */
export enum Mentoring_Scheduling_Update_Column {
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingId = 'meeting_id',
  /** column name */
  MentorId = 'mentor_id',
  /** column name */
  RecordId = 'record_id',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  RescheduleId = 'reschedule_id',
  /** column name */
  TeamId = 'team_id'
}

export type Mentoring_Scheduling_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mentoring_Scheduling_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mentoring_Scheduling_Bool_Exp;
};

/** columns and relationships of "mentors" */
export type Mentors = {
  __typename?: 'mentors';
  /** An object relationship */
  additional_data?: Maybe<Mentor_Additional_Data>;
  additional_data_id?: Maybe<Scalars['uuid']>;
  cpf?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  tags: Array<Mentors_Mentor_Tags>;
  /** An aggregate relationship */
  tags_aggregate: Mentors_Mentor_Tags_Aggregate;
  /** An object relationship */
  user?: Maybe<Users>;
};


/** columns and relationships of "mentors" */
export type MentorsTagsArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Mentor_Tags_Order_By>>;
  where?: InputMaybe<Mentors_Mentor_Tags_Bool_Exp>;
};


/** columns and relationships of "mentors" */
export type MentorsTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Mentor_Tags_Order_By>>;
  where?: InputMaybe<Mentors_Mentor_Tags_Bool_Exp>;
};

/** aggregated selection of "mentors" */
export type Mentors_Aggregate = {
  __typename?: 'mentors_aggregate';
  aggregate?: Maybe<Mentors_Aggregate_Fields>;
  nodes: Array<Mentors>;
};

export type Mentors_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mentors_Aggregate_Bool_Exp_Count>;
};

export type Mentors_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mentors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mentors_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mentors" */
export type Mentors_Aggregate_Fields = {
  __typename?: 'mentors_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mentors_Max_Fields>;
  min?: Maybe<Mentors_Min_Fields>;
};


/** aggregate fields of "mentors" */
export type Mentors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mentors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mentors" */
export type Mentors_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mentors_Max_Order_By>;
  min?: InputMaybe<Mentors_Min_Order_By>;
};

/** input type for inserting array relation for remote table "mentors" */
export type Mentors_Arr_Rel_Insert_Input = {
  data: Array<Mentors_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentors_On_Conflict>;
};

/** columns and relationships of "mentors_blablabla" */
export type Mentors_Blablabla = {
  __typename?: 'mentors_blablabla';
  id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
  /** An object relationship */
  tool: Tools;
  tool_id: Scalars['uuid'];
  value: Scalars['String'];
};

/** aggregated selection of "mentors_blablabla" */
export type Mentors_Blablabla_Aggregate = {
  __typename?: 'mentors_blablabla_aggregate';
  aggregate?: Maybe<Mentors_Blablabla_Aggregate_Fields>;
  nodes: Array<Mentors_Blablabla>;
};

export type Mentors_Blablabla_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mentors_Blablabla_Aggregate_Bool_Exp_Count>;
};

export type Mentors_Blablabla_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mentors_blablabla" */
export type Mentors_Blablabla_Aggregate_Fields = {
  __typename?: 'mentors_blablabla_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mentors_Blablabla_Max_Fields>;
  min?: Maybe<Mentors_Blablabla_Min_Fields>;
};


/** aggregate fields of "mentors_blablabla" */
export type Mentors_Blablabla_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mentors_blablabla" */
export type Mentors_Blablabla_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mentors_Blablabla_Max_Order_By>;
  min?: InputMaybe<Mentors_Blablabla_Min_Order_By>;
};

/** input type for inserting array relation for remote table "mentors_blablabla" */
export type Mentors_Blablabla_Arr_Rel_Insert_Input = {
  data: Array<Mentors_Blablabla_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentors_Blablabla_On_Conflict>;
};

/** Boolean expression to filter rows from the table "mentors_blablabla". All fields are combined with a logical 'AND'. */
export type Mentors_Blablabla_Bool_Exp = {
  _and?: InputMaybe<Array<Mentors_Blablabla_Bool_Exp>>;
  _not?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
  _or?: InputMaybe<Array<Mentors_Blablabla_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  tool?: InputMaybe<Tools_Bool_Exp>;
  tool_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "mentors_blablabla" */
export enum Mentors_Blablabla_Constraint {
  /** unique or primary key constraint on columns "id" */
  MentorsBlablablaPkey = 'mentors_blablabla_pkey',
  /** unique or primary key constraint on columns "team_id", "tool_id" */
  MentorsBlablablaTeamIdToolIdKey = 'mentors_blablabla_team_id_tool_id_key'
}

/** input type for inserting data into table "mentors_blablabla" */
export type Mentors_Blablabla_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  tool?: InputMaybe<Tools_Obj_Rel_Insert_Input>;
  tool_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Mentors_Blablabla_Max_Fields = {
  __typename?: 'mentors_blablabla_max_fields';
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "mentors_blablabla" */
export type Mentors_Blablabla_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mentors_Blablabla_Min_Fields = {
  __typename?: 'mentors_blablabla_min_fields';
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "mentors_blablabla" */
export type Mentors_Blablabla_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mentors_blablabla" */
export type Mentors_Blablabla_Mutation_Response = {
  __typename?: 'mentors_blablabla_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mentors_Blablabla>;
};

/** on_conflict condition type for table "mentors_blablabla" */
export type Mentors_Blablabla_On_Conflict = {
  constraint: Mentors_Blablabla_Constraint;
  update_columns?: Array<Mentors_Blablabla_Update_Column>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};

/** Ordering options when selecting data from "mentors_blablabla". */
export type Mentors_Blablabla_Order_By = {
  id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  tool?: InputMaybe<Tools_Order_By>;
  tool_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mentors_blablabla */
export type Mentors_Blablabla_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "mentors_blablabla" */
export enum Mentors_Blablabla_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "mentors_blablabla" */
export type Mentors_Blablabla_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  tool_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "mentors_blablabla" */
export type Mentors_Blablabla_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mentors_Blablabla_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mentors_Blablabla_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  tool_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "mentors_blablabla" */
export enum Mentors_Blablabla_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  Value = 'value'
}

export type Mentors_Blablabla_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mentors_Blablabla_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mentors_Blablabla_Bool_Exp;
};

/** Boolean expression to filter rows from the table "mentors". All fields are combined with a logical 'AND'. */
export type Mentors_Bool_Exp = {
  _and?: InputMaybe<Array<Mentors_Bool_Exp>>;
  _not?: InputMaybe<Mentors_Bool_Exp>;
  _or?: InputMaybe<Array<Mentors_Bool_Exp>>;
  additional_data?: InputMaybe<Mentor_Additional_Data_Bool_Exp>;
  additional_data_id?: InputMaybe<Uuid_Comparison_Exp>;
  cpf?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tags?: InputMaybe<Mentors_Mentor_Tags_Bool_Exp>;
  tags_aggregate?: InputMaybe<Mentors_Mentor_Tags_Aggregate_Bool_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "mentors" */
export enum Mentors_Constraint {
  /** unique or primary key constraint on columns "cpf" */
  MentorsCpfKey = 'mentors_cpf_key',
  /** unique or primary key constraint on columns "email" */
  MentorsEmailKey = 'mentors_email_key',
  /** unique or primary key constraint on columns "id" */
  MentorsPkey = 'mentors_pkey'
}

/** input type for inserting data into table "mentors" */
export type Mentors_Insert_Input = {
  additional_data?: InputMaybe<Mentor_Additional_Data_Obj_Rel_Insert_Input>;
  additional_data_id?: InputMaybe<Scalars['uuid']>;
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Mentors_Mentor_Tags_Arr_Rel_Insert_Input>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Mentors_Max_Fields = {
  __typename?: 'mentors_max_fields';
  additional_data_id?: Maybe<Scalars['uuid']>;
  cpf?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "mentors" */
export type Mentors_Max_Order_By = {
  additional_data_id?: InputMaybe<Order_By>;
  cpf?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** columns and relationships of "mentors_mentor_tags" */
export type Mentors_Mentor_Tags = {
  __typename?: 'mentors_mentor_tags';
  /** An object relationship */
  mentor: Mentors;
  mentor_id: Scalars['uuid'];
  tag_name: Scalars['String'];
};

/** aggregated selection of "mentors_mentor_tags" */
export type Mentors_Mentor_Tags_Aggregate = {
  __typename?: 'mentors_mentor_tags_aggregate';
  aggregate?: Maybe<Mentors_Mentor_Tags_Aggregate_Fields>;
  nodes: Array<Mentors_Mentor_Tags>;
};

export type Mentors_Mentor_Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Mentors_Mentor_Tags_Aggregate_Bool_Exp_Count>;
};

export type Mentors_Mentor_Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mentors_Mentor_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mentors_Mentor_Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "mentors_mentor_tags" */
export type Mentors_Mentor_Tags_Aggregate_Fields = {
  __typename?: 'mentors_mentor_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Mentors_Mentor_Tags_Max_Fields>;
  min?: Maybe<Mentors_Mentor_Tags_Min_Fields>;
};


/** aggregate fields of "mentors_mentor_tags" */
export type Mentors_Mentor_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mentors_Mentor_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mentors_mentor_tags" */
export type Mentors_Mentor_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mentors_Mentor_Tags_Max_Order_By>;
  min?: InputMaybe<Mentors_Mentor_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "mentors_mentor_tags" */
export type Mentors_Mentor_Tags_Arr_Rel_Insert_Input = {
  data: Array<Mentors_Mentor_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentors_Mentor_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "mentors_mentor_tags". All fields are combined with a logical 'AND'. */
export type Mentors_Mentor_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Mentors_Mentor_Tags_Bool_Exp>>;
  _not?: InputMaybe<Mentors_Mentor_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Mentors_Mentor_Tags_Bool_Exp>>;
  mentor?: InputMaybe<Mentors_Bool_Exp>;
  mentor_id?: InputMaybe<Uuid_Comparison_Exp>;
  tag_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "mentors_mentor_tags" */
export enum Mentors_Mentor_Tags_Constraint {
  /** unique or primary key constraint on columns "mentor_id", "tag_name" */
  MentorsMentorTagsMentorIdTagNameKey = 'mentors_mentor_tags_mentor_id_tag_name_key',
  /** unique or primary key constraint on columns "mentor_id", "tag_name" */
  MentorsMentorTagsPkey = 'mentors_mentor_tags_pkey'
}

/** input type for inserting data into table "mentors_mentor_tags" */
export type Mentors_Mentor_Tags_Insert_Input = {
  mentor?: InputMaybe<Mentors_Obj_Rel_Insert_Input>;
  mentor_id?: InputMaybe<Scalars['uuid']>;
  tag_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Mentors_Mentor_Tags_Max_Fields = {
  __typename?: 'mentors_mentor_tags_max_fields';
  mentor_id?: Maybe<Scalars['uuid']>;
  tag_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "mentors_mentor_tags" */
export type Mentors_Mentor_Tags_Max_Order_By = {
  mentor_id?: InputMaybe<Order_By>;
  tag_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mentors_Mentor_Tags_Min_Fields = {
  __typename?: 'mentors_mentor_tags_min_fields';
  mentor_id?: Maybe<Scalars['uuid']>;
  tag_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "mentors_mentor_tags" */
export type Mentors_Mentor_Tags_Min_Order_By = {
  mentor_id?: InputMaybe<Order_By>;
  tag_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mentors_mentor_tags" */
export type Mentors_Mentor_Tags_Mutation_Response = {
  __typename?: 'mentors_mentor_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mentors_Mentor_Tags>;
};

/** on_conflict condition type for table "mentors_mentor_tags" */
export type Mentors_Mentor_Tags_On_Conflict = {
  constraint: Mentors_Mentor_Tags_Constraint;
  update_columns?: Array<Mentors_Mentor_Tags_Update_Column>;
  where?: InputMaybe<Mentors_Mentor_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "mentors_mentor_tags". */
export type Mentors_Mentor_Tags_Order_By = {
  mentor?: InputMaybe<Mentors_Order_By>;
  mentor_id?: InputMaybe<Order_By>;
  tag_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mentors_mentor_tags */
export type Mentors_Mentor_Tags_Pk_Columns_Input = {
  mentor_id: Scalars['uuid'];
  tag_name: Scalars['String'];
};

/** select columns of table "mentors_mentor_tags" */
export enum Mentors_Mentor_Tags_Select_Column {
  /** column name */
  MentorId = 'mentor_id',
  /** column name */
  TagName = 'tag_name'
}

/** input type for updating data in table "mentors_mentor_tags" */
export type Mentors_Mentor_Tags_Set_Input = {
  mentor_id?: InputMaybe<Scalars['uuid']>;
  tag_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "mentors_mentor_tags" */
export type Mentors_Mentor_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mentors_Mentor_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mentors_Mentor_Tags_Stream_Cursor_Value_Input = {
  mentor_id?: InputMaybe<Scalars['uuid']>;
  tag_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "mentors_mentor_tags" */
export enum Mentors_Mentor_Tags_Update_Column {
  /** column name */
  MentorId = 'mentor_id',
  /** column name */
  TagName = 'tag_name'
}

export type Mentors_Mentor_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mentors_Mentor_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mentors_Mentor_Tags_Bool_Exp;
};

/** aggregate min on columns */
export type Mentors_Min_Fields = {
  __typename?: 'mentors_min_fields';
  additional_data_id?: Maybe<Scalars['uuid']>;
  cpf?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "mentors" */
export type Mentors_Min_Order_By = {
  additional_data_id?: InputMaybe<Order_By>;
  cpf?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "mentors" */
export type Mentors_Mutation_Response = {
  __typename?: 'mentors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mentors>;
};

/** input type for inserting object relation for remote table "mentors" */
export type Mentors_Obj_Rel_Insert_Input = {
  data: Mentors_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mentors_On_Conflict>;
};

/** on_conflict condition type for table "mentors" */
export type Mentors_On_Conflict = {
  constraint: Mentors_Constraint;
  update_columns?: Array<Mentors_Update_Column>;
  where?: InputMaybe<Mentors_Bool_Exp>;
};

/** Ordering options when selecting data from "mentors". */
export type Mentors_Order_By = {
  additional_data?: InputMaybe<Mentor_Additional_Data_Order_By>;
  additional_data_id?: InputMaybe<Order_By>;
  cpf?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tags_aggregate?: InputMaybe<Mentors_Mentor_Tags_Aggregate_Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: mentors */
export type Mentors_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "mentors" */
export enum Mentors_Select_Column {
  /** column name */
  AdditionalDataId = 'additional_data_id',
  /** column name */
  Cpf = 'cpf',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "mentors" */
export type Mentors_Set_Input = {
  additional_data_id?: InputMaybe<Scalars['uuid']>;
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "mentors" */
export type Mentors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mentors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mentors_Stream_Cursor_Value_Input = {
  additional_data_id?: InputMaybe<Scalars['uuid']>;
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "mentors" */
export enum Mentors_Update_Column {
  /** column name */
  AdditionalDataId = 'additional_data_id',
  /** column name */
  Cpf = 'cpf',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Mentors_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mentors_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mentors_Bool_Exp;
};

/** columns and relationships of "metodologies" */
export type Metodologies = {
  __typename?: 'metodologies';
  type: Scalars['String'];
};

/** aggregated selection of "metodologies" */
export type Metodologies_Aggregate = {
  __typename?: 'metodologies_aggregate';
  aggregate?: Maybe<Metodologies_Aggregate_Fields>;
  nodes: Array<Metodologies>;
};

/** aggregate fields of "metodologies" */
export type Metodologies_Aggregate_Fields = {
  __typename?: 'metodologies_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Metodologies_Max_Fields>;
  min?: Maybe<Metodologies_Min_Fields>;
};


/** aggregate fields of "metodologies" */
export type Metodologies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Metodologies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "metodologies". All fields are combined with a logical 'AND'. */
export type Metodologies_Bool_Exp = {
  _and?: InputMaybe<Array<Metodologies_Bool_Exp>>;
  _not?: InputMaybe<Metodologies_Bool_Exp>;
  _or?: InputMaybe<Array<Metodologies_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "metodologies" */
export enum Metodologies_Constraint {
  /** unique or primary key constraint on columns "type" */
  MetodologiesPkey = 'metodologies_pkey'
}

export enum Metodologies_Enum {
  Boot = 'BOOT',
  Corp = 'CORP',
  Default = 'DEFAULT',
  Reinventa = 'REINVENTA'
}

/** Boolean expression to compare columns of type "metodologies_enum". All fields are combined with logical 'AND'. */
export type Metodologies_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Metodologies_Enum>;
  _in?: InputMaybe<Array<Metodologies_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Metodologies_Enum>;
  _nin?: InputMaybe<Array<Metodologies_Enum>>;
};

/** input type for inserting data into table "metodologies" */
export type Metodologies_Insert_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Metodologies_Max_Fields = {
  __typename?: 'metodologies_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Metodologies_Min_Fields = {
  __typename?: 'metodologies_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "metodologies" */
export type Metodologies_Mutation_Response = {
  __typename?: 'metodologies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Metodologies>;
};

/** on_conflict condition type for table "metodologies" */
export type Metodologies_On_Conflict = {
  constraint: Metodologies_Constraint;
  update_columns?: Array<Metodologies_Update_Column>;
  where?: InputMaybe<Metodologies_Bool_Exp>;
};

/** Ordering options when selecting data from "metodologies". */
export type Metodologies_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: metodologies */
export type Metodologies_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "metodologies" */
export enum Metodologies_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "metodologies" */
export type Metodologies_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "metodologies" */
export type Metodologies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Metodologies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Metodologies_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "metodologies" */
export enum Metodologies_Update_Column {
  /** column name */
  Type = 'type'
}

export type Metodologies_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Metodologies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Metodologies_Bool_Exp;
};

/** columns and relationships of "multimedia_contents" */
export type Multimedia_Contents = {
  __typename?: 'multimedia_contents';
  created_at: Scalars['timestamptz'];
  ebooks_link?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  resources?: Maybe<Scalars['String']>;
  /** An array relationship */
  video_lessons: Array<Multimedia_Contents_Links>;
  /** An aggregate relationship */
  video_lessons_aggregate: Multimedia_Contents_Links_Aggregate;
};


/** columns and relationships of "multimedia_contents" */
export type Multimedia_ContentsVideo_LessonsArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Links_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};


/** columns and relationships of "multimedia_contents" */
export type Multimedia_ContentsVideo_Lessons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Links_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};

/** aggregated selection of "multimedia_contents" */
export type Multimedia_Contents_Aggregate = {
  __typename?: 'multimedia_contents_aggregate';
  aggregate?: Maybe<Multimedia_Contents_Aggregate_Fields>;
  nodes: Array<Multimedia_Contents>;
};

/** aggregate fields of "multimedia_contents" */
export type Multimedia_Contents_Aggregate_Fields = {
  __typename?: 'multimedia_contents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Multimedia_Contents_Max_Fields>;
  min?: Maybe<Multimedia_Contents_Min_Fields>;
};


/** aggregate fields of "multimedia_contents" */
export type Multimedia_Contents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Multimedia_Contents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "multimedia_contents". All fields are combined with a logical 'AND'. */
export type Multimedia_Contents_Bool_Exp = {
  _and?: InputMaybe<Array<Multimedia_Contents_Bool_Exp>>;
  _not?: InputMaybe<Multimedia_Contents_Bool_Exp>;
  _or?: InputMaybe<Array<Multimedia_Contents_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ebooks_link?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  resources?: InputMaybe<String_Comparison_Exp>;
  video_lessons?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
  video_lessons_aggregate?: InputMaybe<Multimedia_Contents_Links_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "multimedia_contents" */
export enum Multimedia_Contents_Constraint {
  /** unique or primary key constraint on columns "id" */
  MultimediaContentsPkey = 'multimedia_contents_pkey'
}

/** input type for inserting data into table "multimedia_contents" */
export type Multimedia_Contents_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ebooks_link?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  resources?: InputMaybe<Scalars['String']>;
  video_lessons?: InputMaybe<Multimedia_Contents_Links_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "multimedia_contents_links" */
export type Multimedia_Contents_Links = {
  __typename?: 'multimedia_contents_links';
  id: Scalars['uuid'];
  link: Scalars['String'];
  /** An object relationship */
  multimedia_content: Multimedia_Contents;
  multimedia_content_id: Scalars['uuid'];
};

/** aggregated selection of "multimedia_contents_links" */
export type Multimedia_Contents_Links_Aggregate = {
  __typename?: 'multimedia_contents_links_aggregate';
  aggregate?: Maybe<Multimedia_Contents_Links_Aggregate_Fields>;
  nodes: Array<Multimedia_Contents_Links>;
};

export type Multimedia_Contents_Links_Aggregate_Bool_Exp = {
  count?: InputMaybe<Multimedia_Contents_Links_Aggregate_Bool_Exp_Count>;
};

export type Multimedia_Contents_Links_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Multimedia_Contents_Links_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "multimedia_contents_links" */
export type Multimedia_Contents_Links_Aggregate_Fields = {
  __typename?: 'multimedia_contents_links_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Multimedia_Contents_Links_Max_Fields>;
  min?: Maybe<Multimedia_Contents_Links_Min_Fields>;
};


/** aggregate fields of "multimedia_contents_links" */
export type Multimedia_Contents_Links_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Multimedia_Contents_Links_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "multimedia_contents_links" */
export type Multimedia_Contents_Links_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Multimedia_Contents_Links_Max_Order_By>;
  min?: InputMaybe<Multimedia_Contents_Links_Min_Order_By>;
};

/** input type for inserting array relation for remote table "multimedia_contents_links" */
export type Multimedia_Contents_Links_Arr_Rel_Insert_Input = {
  data: Array<Multimedia_Contents_Links_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Multimedia_Contents_Links_On_Conflict>;
};

/** Boolean expression to filter rows from the table "multimedia_contents_links". All fields are combined with a logical 'AND'. */
export type Multimedia_Contents_Links_Bool_Exp = {
  _and?: InputMaybe<Array<Multimedia_Contents_Links_Bool_Exp>>;
  _not?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
  _or?: InputMaybe<Array<Multimedia_Contents_Links_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Bool_Exp>;
  multimedia_content_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "multimedia_contents_links" */
export enum Multimedia_Contents_Links_Constraint {
  /** unique or primary key constraint on columns "id" */
  MultimediaContentsLinksPkey = 'multimedia_contents_links_pkey'
}

/** input type for inserting data into table "multimedia_contents_links" */
export type Multimedia_Contents_Links_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Obj_Rel_Insert_Input>;
  multimedia_content_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Multimedia_Contents_Links_Max_Fields = {
  __typename?: 'multimedia_contents_links_max_fields';
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  multimedia_content_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "multimedia_contents_links" */
export type Multimedia_Contents_Links_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  multimedia_content_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Multimedia_Contents_Links_Min_Fields = {
  __typename?: 'multimedia_contents_links_min_fields';
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  multimedia_content_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "multimedia_contents_links" */
export type Multimedia_Contents_Links_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  multimedia_content_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "multimedia_contents_links" */
export type Multimedia_Contents_Links_Mutation_Response = {
  __typename?: 'multimedia_contents_links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Multimedia_Contents_Links>;
};

/** on_conflict condition type for table "multimedia_contents_links" */
export type Multimedia_Contents_Links_On_Conflict = {
  constraint: Multimedia_Contents_Links_Constraint;
  update_columns?: Array<Multimedia_Contents_Links_Update_Column>;
  where?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};

/** Ordering options when selecting data from "multimedia_contents_links". */
export type Multimedia_Contents_Links_Order_By = {
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Order_By>;
  multimedia_content_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: multimedia_contents_links */
export type Multimedia_Contents_Links_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "multimedia_contents_links" */
export enum Multimedia_Contents_Links_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  MultimediaContentId = 'multimedia_content_id'
}

/** input type for updating data in table "multimedia_contents_links" */
export type Multimedia_Contents_Links_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  multimedia_content_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "multimedia_contents_links" */
export type Multimedia_Contents_Links_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Multimedia_Contents_Links_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Multimedia_Contents_Links_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  multimedia_content_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "multimedia_contents_links" */
export enum Multimedia_Contents_Links_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  MultimediaContentId = 'multimedia_content_id'
}

export type Multimedia_Contents_Links_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Multimedia_Contents_Links_Set_Input>;
  /** filter the rows which have to be updated */
  where: Multimedia_Contents_Links_Bool_Exp;
};

/** aggregate max on columns */
export type Multimedia_Contents_Max_Fields = {
  __typename?: 'multimedia_contents_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ebooks_link?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  resources?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Multimedia_Contents_Min_Fields = {
  __typename?: 'multimedia_contents_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ebooks_link?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  resources?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "multimedia_contents" */
export type Multimedia_Contents_Mutation_Response = {
  __typename?: 'multimedia_contents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Multimedia_Contents>;
};

/** input type for inserting object relation for remote table "multimedia_contents" */
export type Multimedia_Contents_Obj_Rel_Insert_Input = {
  data: Multimedia_Contents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Multimedia_Contents_On_Conflict>;
};

/** on_conflict condition type for table "multimedia_contents" */
export type Multimedia_Contents_On_Conflict = {
  constraint: Multimedia_Contents_Constraint;
  update_columns?: Array<Multimedia_Contents_Update_Column>;
  where?: InputMaybe<Multimedia_Contents_Bool_Exp>;
};

/** Ordering options when selecting data from "multimedia_contents". */
export type Multimedia_Contents_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ebooks_link?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  resources?: InputMaybe<Order_By>;
  video_lessons_aggregate?: InputMaybe<Multimedia_Contents_Links_Aggregate_Order_By>;
};

/** primary key columns input for table: multimedia_contents */
export type Multimedia_Contents_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "multimedia_contents" */
export enum Multimedia_Contents_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EbooksLink = 'ebooks_link',
  /** column name */
  Id = 'id',
  /** column name */
  Resources = 'resources'
}

/** input type for updating data in table "multimedia_contents" */
export type Multimedia_Contents_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ebooks_link?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  resources?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "multimedia_contents" */
export type Multimedia_Contents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Multimedia_Contents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Multimedia_Contents_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ebooks_link?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  resources?: InputMaybe<Scalars['String']>;
};

/** update columns of table "multimedia_contents" */
export enum Multimedia_Contents_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EbooksLink = 'ebooks_link',
  /** column name */
  Id = 'id',
  /** column name */
  Resources = 'resources'
}

export type Multimedia_Contents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Multimedia_Contents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Multimedia_Contents_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  createUser: Scalars['uuid'];
  /** createUserPreregistered */
  createUserPreregistered: Scalars['uuid'];
  /** delete data from the table: "available_mentoring_dates" */
  delete_available_mentoring_dates?: Maybe<Available_Mentoring_Dates_Mutation_Response>;
  /** delete single row from the table: "available_mentoring_dates" */
  delete_available_mentoring_dates_by_pk?: Maybe<Available_Mentoring_Dates>;
  /** delete data from the table: "contents" */
  delete_contents?: Maybe<Contents_Mutation_Response>;
  /** delete single row from the table: "contents" */
  delete_contents_by_pk?: Maybe<Contents>;
  /** delete data from the table: "contents_sections" */
  delete_contents_sections?: Maybe<Contents_Sections_Mutation_Response>;
  /** delete single row from the table: "contents_sections" */
  delete_contents_sections_by_pk?: Maybe<Contents_Sections>;
  /** delete data from the table: "diagnostics" */
  delete_diagnostics?: Maybe<Diagnostics_Mutation_Response>;
  /** delete single row from the table: "diagnostics" */
  delete_diagnostics_by_pk?: Maybe<Diagnostics>;
  /** delete data from the table: "diagnostics_comments" */
  delete_diagnostics_comments?: Maybe<Diagnostics_Comments_Mutation_Response>;
  /** delete single row from the table: "diagnostics_comments" */
  delete_diagnostics_comments_by_pk?: Maybe<Diagnostics_Comments>;
  /** delete data from the table: "diagnostics_groups" */
  delete_diagnostics_groups?: Maybe<Diagnostics_Groups_Mutation_Response>;
  /** delete single row from the table: "diagnostics_groups" */
  delete_diagnostics_groups_by_pk?: Maybe<Diagnostics_Groups>;
  /** delete data from the table: "diagnostics_questions" */
  delete_diagnostics_questions?: Maybe<Diagnostics_Questions_Mutation_Response>;
  /** delete data from the table: "diagnostics_questions_answers" */
  delete_diagnostics_questions_answers?: Maybe<Diagnostics_Questions_Answers_Mutation_Response>;
  /** delete single row from the table: "diagnostics_questions_answers" */
  delete_diagnostics_questions_answers_by_pk?: Maybe<Diagnostics_Questions_Answers>;
  /** delete single row from the table: "diagnostics_questions" */
  delete_diagnostics_questions_by_pk?: Maybe<Diagnostics_Questions>;
  /** delete data from the table: "diagnostics_sections" */
  delete_diagnostics_sections?: Maybe<Diagnostics_Sections_Mutation_Response>;
  /** delete single row from the table: "diagnostics_sections" */
  delete_diagnostics_sections_by_pk?: Maybe<Diagnostics_Sections>;
  /** delete data from the table: "diagnostics_subsections" */
  delete_diagnostics_subsections?: Maybe<Diagnostics_Subsections_Mutation_Response>;
  /** delete single row from the table: "diagnostics_subsections" */
  delete_diagnostics_subsections_by_pk?: Maybe<Diagnostics_Subsections>;
  /** delete data from the table: "diagnostics_tabs" */
  delete_diagnostics_tabs?: Maybe<Diagnostics_Tabs_Mutation_Response>;
  /** delete single row from the table: "diagnostics_tabs" */
  delete_diagnostics_tabs_by_pk?: Maybe<Diagnostics_Tabs>;
  /** delete data from the table: "diagnostics_tabs_sections" */
  delete_diagnostics_tabs_sections?: Maybe<Diagnostics_Tabs_Sections_Mutation_Response>;
  /** delete single row from the table: "diagnostics_tabs_sections" */
  delete_diagnostics_tabs_sections_by_pk?: Maybe<Diagnostics_Tabs_Sections>;
  /** delete data from the table: "files" */
  delete_files?: Maybe<Files_Mutation_Response>;
  /** delete single row from the table: "files" */
  delete_files_by_pk?: Maybe<Files>;
  /** delete data from the table: "groups" */
  delete_groups?: Maybe<Groups_Mutation_Response>;
  /** delete single row from the table: "groups" */
  delete_groups_by_pk?: Maybe<Groups>;
  /** delete data from the table: "groups_contents" */
  delete_groups_contents?: Maybe<Groups_Contents_Mutation_Response>;
  /** delete single row from the table: "groups_contents" */
  delete_groups_contents_by_pk?: Maybe<Groups_Contents>;
  /** delete data from the table: "groups_flags" */
  delete_groups_flags?: Maybe<Groups_Flags_Mutation_Response>;
  /** delete single row from the table: "groups_flags" */
  delete_groups_flags_by_pk?: Maybe<Groups_Flags>;
  /** delete data from the table: "groups_group_flags" */
  delete_groups_group_flags?: Maybe<Groups_Group_Flags_Mutation_Response>;
  /** delete single row from the table: "groups_group_flags" */
  delete_groups_group_flags_by_pk?: Maybe<Groups_Group_Flags>;
  /** delete data from the table: "groups_names" */
  delete_groups_names?: Maybe<Groups_Names_Mutation_Response>;
  /** delete single row from the table: "groups_names" */
  delete_groups_names_by_pk?: Maybe<Groups_Names>;
  /** delete data from the table: "groups_teams" */
  delete_groups_teams?: Maybe<Groups_Teams_Mutation_Response>;
  /** delete single row from the table: "groups_teams" */
  delete_groups_teams_by_pk?: Maybe<Groups_Teams>;
  /** delete data from the table: "icons_types" */
  delete_icons_types?: Maybe<Icons_Types_Mutation_Response>;
  /** delete single row from the table: "icons_types" */
  delete_icons_types_by_pk?: Maybe<Icons_Types>;
  /** delete data from the table: "invite_states" */
  delete_invite_states?: Maybe<Invite_States_Mutation_Response>;
  /** delete single row from the table: "invite_states" */
  delete_invite_states_by_pk?: Maybe<Invite_States>;
  /** delete data from the table: "kanban_columns" */
  delete_kanban_columns?: Maybe<Kanban_Columns_Mutation_Response>;
  /** delete single row from the table: "kanban_columns" */
  delete_kanban_columns_by_pk?: Maybe<Kanban_Columns>;
  /** delete data from the table: "kanbans" */
  delete_kanbans?: Maybe<Kanbans_Mutation_Response>;
  /** delete single row from the table: "kanbans" */
  delete_kanbans_by_pk?: Maybe<Kanbans>;
  /** delete data from the table: "kanbans_cards" */
  delete_kanbans_cards?: Maybe<Kanbans_Cards_Mutation_Response>;
  /** delete data from the table: "kanbans_cards_assignations" */
  delete_kanbans_cards_assignations?: Maybe<Kanbans_Cards_Assignations_Mutation_Response>;
  /** delete single row from the table: "kanbans_cards_assignations" */
  delete_kanbans_cards_assignations_by_pk?: Maybe<Kanbans_Cards_Assignations>;
  /** delete data from the table: "kanbans_cards_attachments" */
  delete_kanbans_cards_attachments?: Maybe<Kanbans_Cards_Attachments_Mutation_Response>;
  /** delete single row from the table: "kanbans_cards_attachments" */
  delete_kanbans_cards_attachments_by_pk?: Maybe<Kanbans_Cards_Attachments>;
  /** delete single row from the table: "kanbans_cards" */
  delete_kanbans_cards_by_pk?: Maybe<Kanbans_Cards>;
  /** delete data from the table: "kanbans_cards_comments" */
  delete_kanbans_cards_comments?: Maybe<Kanbans_Cards_Comments_Mutation_Response>;
  /** delete single row from the table: "kanbans_cards_comments" */
  delete_kanbans_cards_comments_by_pk?: Maybe<Kanbans_Cards_Comments>;
  /** delete data from the table: "locations" */
  delete_locations?: Maybe<Locations_Mutation_Response>;
  /** delete single row from the table: "locations" */
  delete_locations_by_pk?: Maybe<Locations>;
  /** delete data from the table: "locations_names" */
  delete_locations_names?: Maybe<Locations_Names_Mutation_Response>;
  /** delete single row from the table: "locations_names" */
  delete_locations_names_by_pk?: Maybe<Locations_Names>;
  /** delete data from the table: "mentor_additional_data" */
  delete_mentor_additional_data?: Maybe<Mentor_Additional_Data_Mutation_Response>;
  /** delete single row from the table: "mentor_additional_data" */
  delete_mentor_additional_data_by_pk?: Maybe<Mentor_Additional_Data>;
  /** delete data from the table: "mentor_tags" */
  delete_mentor_tags?: Maybe<Mentor_Tags_Mutation_Response>;
  /** delete single row from the table: "mentor_tags" */
  delete_mentor_tags_by_pk?: Maybe<Mentor_Tags>;
  /** delete data from the table: "mentoring_records" */
  delete_mentoring_records?: Maybe<Mentoring_Records_Mutation_Response>;
  /** delete data from the table: "mentoring_records_attachments" */
  delete_mentoring_records_attachments?: Maybe<Mentoring_Records_Attachments_Mutation_Response>;
  /** delete single row from the table: "mentoring_records_attachments" */
  delete_mentoring_records_attachments_by_pk?: Maybe<Mentoring_Records_Attachments>;
  /** delete single row from the table: "mentoring_records" */
  delete_mentoring_records_by_pk?: Maybe<Mentoring_Records>;
  /** delete data from the table: "mentoring_records_members" */
  delete_mentoring_records_members?: Maybe<Mentoring_Records_Members_Mutation_Response>;
  /** delete single row from the table: "mentoring_records_members" */
  delete_mentoring_records_members_by_pk?: Maybe<Mentoring_Records_Members>;
  /** delete data from the table: "mentoring_reschedules" */
  delete_mentoring_reschedules?: Maybe<Mentoring_Reschedules_Mutation_Response>;
  /** delete single row from the table: "mentoring_reschedules" */
  delete_mentoring_reschedules_by_pk?: Maybe<Mentoring_Reschedules>;
  /** delete data from the table: "mentoring_scheduling" */
  delete_mentoring_scheduling?: Maybe<Mentoring_Scheduling_Mutation_Response>;
  /** delete single row from the table: "mentoring_scheduling" */
  delete_mentoring_scheduling_by_pk?: Maybe<Mentoring_Scheduling>;
  /** delete data from the table: "mentoring_scheduling_meeting" */
  delete_mentoring_scheduling_meeting?: Maybe<Mentoring_Scheduling_Meeting_Mutation_Response>;
  /** delete single row from the table: "mentoring_scheduling_meeting" */
  delete_mentoring_scheduling_meeting_by_pk?: Maybe<Mentoring_Scheduling_Meeting>;
  /** delete data from the table: "mentoring_scheduling_members" */
  delete_mentoring_scheduling_members?: Maybe<Mentoring_Scheduling_Members_Mutation_Response>;
  /** delete single row from the table: "mentoring_scheduling_members" */
  delete_mentoring_scheduling_members_by_pk?: Maybe<Mentoring_Scheduling_Members>;
  /** delete data from the table: "mentors" */
  delete_mentors?: Maybe<Mentors_Mutation_Response>;
  /** delete data from the table: "mentors_blablabla" */
  delete_mentors_blablabla?: Maybe<Mentors_Blablabla_Mutation_Response>;
  /** delete single row from the table: "mentors_blablabla" */
  delete_mentors_blablabla_by_pk?: Maybe<Mentors_Blablabla>;
  /** delete single row from the table: "mentors" */
  delete_mentors_by_pk?: Maybe<Mentors>;
  /** delete data from the table: "mentors_mentor_tags" */
  delete_mentors_mentor_tags?: Maybe<Mentors_Mentor_Tags_Mutation_Response>;
  /** delete single row from the table: "mentors_mentor_tags" */
  delete_mentors_mentor_tags_by_pk?: Maybe<Mentors_Mentor_Tags>;
  /** delete data from the table: "metodologies" */
  delete_metodologies?: Maybe<Metodologies_Mutation_Response>;
  /** delete single row from the table: "metodologies" */
  delete_metodologies_by_pk?: Maybe<Metodologies>;
  /** delete data from the table: "multimedia_contents" */
  delete_multimedia_contents?: Maybe<Multimedia_Contents_Mutation_Response>;
  /** delete single row from the table: "multimedia_contents" */
  delete_multimedia_contents_by_pk?: Maybe<Multimedia_Contents>;
  /** delete data from the table: "multimedia_contents_links" */
  delete_multimedia_contents_links?: Maybe<Multimedia_Contents_Links_Mutation_Response>;
  /** delete single row from the table: "multimedia_contents_links" */
  delete_multimedia_contents_links_by_pk?: Maybe<Multimedia_Contents_Links>;
  /** delete data from the table: "outputs" */
  delete_outputs?: Maybe<Outputs_Mutation_Response>;
  /** delete single row from the table: "outputs" */
  delete_outputs_by_pk?: Maybe<Outputs>;
  /** delete data from the table: "outputs_icons" */
  delete_outputs_icons?: Maybe<Outputs_Icons_Mutation_Response>;
  /** delete single row from the table: "outputs_icons" */
  delete_outputs_icons_by_pk?: Maybe<Outputs_Icons>;
  /** delete data from the table: "outputs_tools" */
  delete_outputs_tools?: Maybe<Outputs_Tools_Mutation_Response>;
  /** delete single row from the table: "outputs_tools" */
  delete_outputs_tools_by_pk?: Maybe<Outputs_Tools>;
  /** delete data from the table: "questionnaires" */
  delete_questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** delete single row from the table: "questionnaires" */
  delete_questionnaires_by_pk?: Maybe<Questionnaires>;
  /** delete data from the table: "questionnaires_teams_states" */
  delete_questionnaires_teams_states?: Maybe<Questionnaires_Teams_States_Mutation_Response>;
  /** delete single row from the table: "questionnaires_teams_states" */
  delete_questionnaires_teams_states_by_pk?: Maybe<Questionnaires_Teams_States>;
  /** delete data from the table: "questions" */
  delete_questions?: Maybe<Questions_Mutation_Response>;
  /** delete data from the table: "questions_answers" */
  delete_questions_answers?: Maybe<Questions_Answers_Mutation_Response>;
  /** delete single row from the table: "questions_answers" */
  delete_questions_answers_by_pk?: Maybe<Questions_Answers>;
  /** delete single row from the table: "questions" */
  delete_questions_by_pk?: Maybe<Questions>;
  /** delete data from the table: "questions_options" */
  delete_questions_options?: Maybe<Questions_Options_Mutation_Response>;
  /** delete single row from the table: "questions_options" */
  delete_questions_options_by_pk?: Maybe<Questions_Options>;
  /** delete data from the table: "questions_types" */
  delete_questions_types?: Maybe<Questions_Types_Mutation_Response>;
  /** delete single row from the table: "questions_types" */
  delete_questions_types_by_pk?: Maybe<Questions_Types>;
  /** delete data from the table: "repositories" */
  delete_repositories?: Maybe<Repositories_Mutation_Response>;
  /** delete single row from the table: "repositories" */
  delete_repositories_by_pk?: Maybe<Repositories>;
  /** delete data from the table: "requested_mentorings" */
  delete_requested_mentorings?: Maybe<Requested_Mentorings_Mutation_Response>;
  /** delete single row from the table: "requested_mentorings" */
  delete_requested_mentorings_by_pk?: Maybe<Requested_Mentorings>;
  /** delete data from the table: "requested_mentorings_status" */
  delete_requested_mentorings_status?: Maybe<Requested_Mentorings_Status_Mutation_Response>;
  /** delete single row from the table: "requested_mentorings_status" */
  delete_requested_mentorings_status_by_pk?: Maybe<Requested_Mentorings_Status>;
  /** delete data from the table: "requests_mentor_tags" */
  delete_requests_mentor_tags?: Maybe<Requests_Mentor_Tags_Mutation_Response>;
  /** delete single row from the table: "requests_mentor_tags" */
  delete_requests_mentor_tags_by_pk?: Maybe<Requests_Mentor_Tags>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<Roles_Mutation_Response>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "sections" */
  delete_sections?: Maybe<Sections_Mutation_Response>;
  /** delete single row from the table: "sections" */
  delete_sections_by_pk?: Maybe<Sections>;
  /** delete data from the table: "sections_icons" */
  delete_sections_icons?: Maybe<Sections_Icons_Mutation_Response>;
  /** delete single row from the table: "sections_icons" */
  delete_sections_icons_by_pk?: Maybe<Sections_Icons>;
  /** delete data from the table: "social_medias" */
  delete_social_medias?: Maybe<Social_Medias_Mutation_Response>;
  /** delete single row from the table: "social_medias" */
  delete_social_medias_by_pk?: Maybe<Social_Medias>;
  /** delete data from the table: "tag_conversion" */
  delete_tag_conversion?: Maybe<Tag_Conversion_Mutation_Response>;
  /** delete single row from the table: "tag_conversion" */
  delete_tag_conversion_by_pk?: Maybe<Tag_Conversion>;
  /** delete data from the table: "tags" */
  delete_tags?: Maybe<Tags_Mutation_Response>;
  /** delete single row from the table: "tags" */
  delete_tags_by_pk?: Maybe<Tags>;
  /** delete data from the table: "team_invites" */
  delete_team_invites?: Maybe<Team_Invites_Mutation_Response>;
  /** delete single row from the table: "team_invites" */
  delete_team_invites_by_pk?: Maybe<Team_Invites>;
  /** delete data from the table: "teams" */
  delete_teams?: Maybe<Teams_Mutation_Response>;
  /** delete single row from the table: "teams" */
  delete_teams_by_pk?: Maybe<Teams>;
  /** delete data from the table: "teams_names" */
  delete_teams_names?: Maybe<Teams_Names_Mutation_Response>;
  /** delete single row from the table: "teams_names" */
  delete_teams_names_by_pk?: Maybe<Teams_Names>;
  /** delete data from the table: "teams_tags" */
  delete_teams_tags?: Maybe<Teams_Tags_Mutation_Response>;
  /** delete single row from the table: "teams_tags" */
  delete_teams_tags_by_pk?: Maybe<Teams_Tags>;
  /** delete data from the table: "teams_users" */
  delete_teams_users?: Maybe<Teams_Users_Mutation_Response>;
  /** delete single row from the table: "teams_users" */
  delete_teams_users_by_pk?: Maybe<Teams_Users>;
  /** delete data from the table: "tools" */
  delete_tools?: Maybe<Tools_Mutation_Response>;
  /** delete single row from the table: "tools" */
  delete_tools_by_pk?: Maybe<Tools>;
  /** delete data from the table: "tools_icons" */
  delete_tools_icons?: Maybe<Tools_Icons_Mutation_Response>;
  /** delete single row from the table: "tools_icons" */
  delete_tools_icons_by_pk?: Maybe<Tools_Icons>;
  /** delete data from the table: "user_info" */
  delete_user_info?: Maybe<User_Info_Mutation_Response>;
  /** delete single row from the table: "user_info" */
  delete_user_info_by_pk?: Maybe<User_Info>;
  /** delete data from the table: "user_invites" */
  delete_user_invites?: Maybe<User_Invites_Mutation_Response>;
  /** delete single row from the table: "user_invites" */
  delete_user_invites_by_pk?: Maybe<User_Invites>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete data from the table: "users_actions" */
  delete_users_actions?: Maybe<Users_Actions_Mutation_Response>;
  /** delete single row from the table: "users_actions" */
  delete_users_actions_by_pk?: Maybe<Users_Actions>;
  /** delete data from the table: "users_actions_types" */
  delete_users_actions_types?: Maybe<Users_Actions_Types_Mutation_Response>;
  /** delete single row from the table: "users_actions_types" */
  delete_users_actions_types_by_pk?: Maybe<Users_Actions_Types>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "users_documents" */
  delete_users_documents?: Maybe<Users_Documents_Mutation_Response>;
  /** delete single row from the table: "users_documents" */
  delete_users_documents_by_pk?: Maybe<Users_Documents>;
  /** delete data from the table: "users_documents_types" */
  delete_users_documents_types?: Maybe<Users_Documents_Types_Mutation_Response>;
  /** delete single row from the table: "users_documents_types" */
  delete_users_documents_types_by_pk?: Maybe<Users_Documents_Types>;
  /** delete data from the table: "users_names" */
  delete_users_names?: Maybe<Users_Names_Mutation_Response>;
  /** delete single row from the table: "users_names" */
  delete_users_names_by_pk?: Maybe<Users_Names>;
  /** delete data from the table: "video_repositories" */
  delete_video_repositories?: Maybe<Video_Repositories_Mutation_Response>;
  /** delete single row from the table: "video_repositories" */
  delete_video_repositories_by_pk?: Maybe<Video_Repositories>;
  /** delete data from the table: "videos" */
  delete_videos?: Maybe<Videos_Mutation_Response>;
  /** delete single row from the table: "videos" */
  delete_videos_by_pk?: Maybe<Videos>;
  /** delete data from the table: "videos_youtube_tags" */
  delete_videos_youtube_tags?: Maybe<Videos_Youtube_Tags_Mutation_Response>;
  /** delete single row from the table: "videos_youtube_tags" */
  delete_videos_youtube_tags_by_pk?: Maybe<Videos_Youtube_Tags>;
  /** delete data from the table: "youtube_tags" */
  delete_youtube_tags?: Maybe<Youtube_Tags_Mutation_Response>;
  /** delete single row from the table: "youtube_tags" */
  delete_youtube_tags_by_pk?: Maybe<Youtube_Tags>;
  /** insert data into the table: "available_mentoring_dates" */
  insert_available_mentoring_dates?: Maybe<Available_Mentoring_Dates_Mutation_Response>;
  /** insert a single row into the table: "available_mentoring_dates" */
  insert_available_mentoring_dates_one?: Maybe<Available_Mentoring_Dates>;
  /** insert data into the table: "contents" */
  insert_contents?: Maybe<Contents_Mutation_Response>;
  /** insert a single row into the table: "contents" */
  insert_contents_one?: Maybe<Contents>;
  /** insert data into the table: "contents_sections" */
  insert_contents_sections?: Maybe<Contents_Sections_Mutation_Response>;
  /** insert a single row into the table: "contents_sections" */
  insert_contents_sections_one?: Maybe<Contents_Sections>;
  /** insert data into the table: "diagnostics" */
  insert_diagnostics?: Maybe<Diagnostics_Mutation_Response>;
  /** insert data into the table: "diagnostics_comments" */
  insert_diagnostics_comments?: Maybe<Diagnostics_Comments_Mutation_Response>;
  /** insert a single row into the table: "diagnostics_comments" */
  insert_diagnostics_comments_one?: Maybe<Diagnostics_Comments>;
  /** insert data into the table: "diagnostics_groups" */
  insert_diagnostics_groups?: Maybe<Diagnostics_Groups_Mutation_Response>;
  /** insert a single row into the table: "diagnostics_groups" */
  insert_diagnostics_groups_one?: Maybe<Diagnostics_Groups>;
  /** insert a single row into the table: "diagnostics" */
  insert_diagnostics_one?: Maybe<Diagnostics>;
  /** insert data into the table: "diagnostics_questions" */
  insert_diagnostics_questions?: Maybe<Diagnostics_Questions_Mutation_Response>;
  /** insert data into the table: "diagnostics_questions_answers" */
  insert_diagnostics_questions_answers?: Maybe<Diagnostics_Questions_Answers_Mutation_Response>;
  /** insert a single row into the table: "diagnostics_questions_answers" */
  insert_diagnostics_questions_answers_one?: Maybe<Diagnostics_Questions_Answers>;
  /** insert a single row into the table: "diagnostics_questions" */
  insert_diagnostics_questions_one?: Maybe<Diagnostics_Questions>;
  /** insert data into the table: "diagnostics_sections" */
  insert_diagnostics_sections?: Maybe<Diagnostics_Sections_Mutation_Response>;
  /** insert a single row into the table: "diagnostics_sections" */
  insert_diagnostics_sections_one?: Maybe<Diagnostics_Sections>;
  /** insert data into the table: "diagnostics_subsections" */
  insert_diagnostics_subsections?: Maybe<Diagnostics_Subsections_Mutation_Response>;
  /** insert a single row into the table: "diagnostics_subsections" */
  insert_diagnostics_subsections_one?: Maybe<Diagnostics_Subsections>;
  /** insert data into the table: "diagnostics_tabs" */
  insert_diagnostics_tabs?: Maybe<Diagnostics_Tabs_Mutation_Response>;
  /** insert a single row into the table: "diagnostics_tabs" */
  insert_diagnostics_tabs_one?: Maybe<Diagnostics_Tabs>;
  /** insert data into the table: "diagnostics_tabs_sections" */
  insert_diagnostics_tabs_sections?: Maybe<Diagnostics_Tabs_Sections_Mutation_Response>;
  /** insert a single row into the table: "diagnostics_tabs_sections" */
  insert_diagnostics_tabs_sections_one?: Maybe<Diagnostics_Tabs_Sections>;
  /** insert data into the table: "files" */
  insert_files?: Maybe<Files_Mutation_Response>;
  /** insert a single row into the table: "files" */
  insert_files_one?: Maybe<Files>;
  /** insert data into the table: "groups" */
  insert_groups?: Maybe<Groups_Mutation_Response>;
  /** insert data into the table: "groups_contents" */
  insert_groups_contents?: Maybe<Groups_Contents_Mutation_Response>;
  /** insert a single row into the table: "groups_contents" */
  insert_groups_contents_one?: Maybe<Groups_Contents>;
  /** insert data into the table: "groups_flags" */
  insert_groups_flags?: Maybe<Groups_Flags_Mutation_Response>;
  /** insert a single row into the table: "groups_flags" */
  insert_groups_flags_one?: Maybe<Groups_Flags>;
  /** insert data into the table: "groups_group_flags" */
  insert_groups_group_flags?: Maybe<Groups_Group_Flags_Mutation_Response>;
  /** insert a single row into the table: "groups_group_flags" */
  insert_groups_group_flags_one?: Maybe<Groups_Group_Flags>;
  /** insert data into the table: "groups_names" */
  insert_groups_names?: Maybe<Groups_Names_Mutation_Response>;
  /** insert a single row into the table: "groups_names" */
  insert_groups_names_one?: Maybe<Groups_Names>;
  /** insert a single row into the table: "groups" */
  insert_groups_one?: Maybe<Groups>;
  /** insert data into the table: "groups_teams" */
  insert_groups_teams?: Maybe<Groups_Teams_Mutation_Response>;
  /** insert a single row into the table: "groups_teams" */
  insert_groups_teams_one?: Maybe<Groups_Teams>;
  /** insert data into the table: "icons_types" */
  insert_icons_types?: Maybe<Icons_Types_Mutation_Response>;
  /** insert a single row into the table: "icons_types" */
  insert_icons_types_one?: Maybe<Icons_Types>;
  /** insert data into the table: "invite_states" */
  insert_invite_states?: Maybe<Invite_States_Mutation_Response>;
  /** insert a single row into the table: "invite_states" */
  insert_invite_states_one?: Maybe<Invite_States>;
  /** insert data into the table: "kanban_columns" */
  insert_kanban_columns?: Maybe<Kanban_Columns_Mutation_Response>;
  /** insert a single row into the table: "kanban_columns" */
  insert_kanban_columns_one?: Maybe<Kanban_Columns>;
  /** insert data into the table: "kanbans" */
  insert_kanbans?: Maybe<Kanbans_Mutation_Response>;
  /** insert data into the table: "kanbans_cards" */
  insert_kanbans_cards?: Maybe<Kanbans_Cards_Mutation_Response>;
  /** insert data into the table: "kanbans_cards_assignations" */
  insert_kanbans_cards_assignations?: Maybe<Kanbans_Cards_Assignations_Mutation_Response>;
  /** insert a single row into the table: "kanbans_cards_assignations" */
  insert_kanbans_cards_assignations_one?: Maybe<Kanbans_Cards_Assignations>;
  /** insert data into the table: "kanbans_cards_attachments" */
  insert_kanbans_cards_attachments?: Maybe<Kanbans_Cards_Attachments_Mutation_Response>;
  /** insert a single row into the table: "kanbans_cards_attachments" */
  insert_kanbans_cards_attachments_one?: Maybe<Kanbans_Cards_Attachments>;
  /** insert data into the table: "kanbans_cards_comments" */
  insert_kanbans_cards_comments?: Maybe<Kanbans_Cards_Comments_Mutation_Response>;
  /** insert a single row into the table: "kanbans_cards_comments" */
  insert_kanbans_cards_comments_one?: Maybe<Kanbans_Cards_Comments>;
  /** insert a single row into the table: "kanbans_cards" */
  insert_kanbans_cards_one?: Maybe<Kanbans_Cards>;
  /** insert a single row into the table: "kanbans" */
  insert_kanbans_one?: Maybe<Kanbans>;
  /** insert data into the table: "locations" */
  insert_locations?: Maybe<Locations_Mutation_Response>;
  /** insert data into the table: "locations_names" */
  insert_locations_names?: Maybe<Locations_Names_Mutation_Response>;
  /** insert a single row into the table: "locations_names" */
  insert_locations_names_one?: Maybe<Locations_Names>;
  /** insert a single row into the table: "locations" */
  insert_locations_one?: Maybe<Locations>;
  /** insert data into the table: "mentor_additional_data" */
  insert_mentor_additional_data?: Maybe<Mentor_Additional_Data_Mutation_Response>;
  /** insert a single row into the table: "mentor_additional_data" */
  insert_mentor_additional_data_one?: Maybe<Mentor_Additional_Data>;
  /** insert data into the table: "mentor_tags" */
  insert_mentor_tags?: Maybe<Mentor_Tags_Mutation_Response>;
  /** insert a single row into the table: "mentor_tags" */
  insert_mentor_tags_one?: Maybe<Mentor_Tags>;
  /** insert data into the table: "mentoring_records" */
  insert_mentoring_records?: Maybe<Mentoring_Records_Mutation_Response>;
  /** insert data into the table: "mentoring_records_attachments" */
  insert_mentoring_records_attachments?: Maybe<Mentoring_Records_Attachments_Mutation_Response>;
  /** insert a single row into the table: "mentoring_records_attachments" */
  insert_mentoring_records_attachments_one?: Maybe<Mentoring_Records_Attachments>;
  /** insert data into the table: "mentoring_records_members" */
  insert_mentoring_records_members?: Maybe<Mentoring_Records_Members_Mutation_Response>;
  /** insert a single row into the table: "mentoring_records_members" */
  insert_mentoring_records_members_one?: Maybe<Mentoring_Records_Members>;
  /** insert a single row into the table: "mentoring_records" */
  insert_mentoring_records_one?: Maybe<Mentoring_Records>;
  /** insert data into the table: "mentoring_reschedules" */
  insert_mentoring_reschedules?: Maybe<Mentoring_Reschedules_Mutation_Response>;
  /** insert a single row into the table: "mentoring_reschedules" */
  insert_mentoring_reschedules_one?: Maybe<Mentoring_Reschedules>;
  /** insert data into the table: "mentoring_scheduling" */
  insert_mentoring_scheduling?: Maybe<Mentoring_Scheduling_Mutation_Response>;
  /** insert data into the table: "mentoring_scheduling_meeting" */
  insert_mentoring_scheduling_meeting?: Maybe<Mentoring_Scheduling_Meeting_Mutation_Response>;
  /** insert a single row into the table: "mentoring_scheduling_meeting" */
  insert_mentoring_scheduling_meeting_one?: Maybe<Mentoring_Scheduling_Meeting>;
  /** insert data into the table: "mentoring_scheduling_members" */
  insert_mentoring_scheduling_members?: Maybe<Mentoring_Scheduling_Members_Mutation_Response>;
  /** insert a single row into the table: "mentoring_scheduling_members" */
  insert_mentoring_scheduling_members_one?: Maybe<Mentoring_Scheduling_Members>;
  /** insert a single row into the table: "mentoring_scheduling" */
  insert_mentoring_scheduling_one?: Maybe<Mentoring_Scheduling>;
  /** insert data into the table: "mentors" */
  insert_mentors?: Maybe<Mentors_Mutation_Response>;
  /** insert data into the table: "mentors_blablabla" */
  insert_mentors_blablabla?: Maybe<Mentors_Blablabla_Mutation_Response>;
  /** insert a single row into the table: "mentors_blablabla" */
  insert_mentors_blablabla_one?: Maybe<Mentors_Blablabla>;
  /** insert data into the table: "mentors_mentor_tags" */
  insert_mentors_mentor_tags?: Maybe<Mentors_Mentor_Tags_Mutation_Response>;
  /** insert a single row into the table: "mentors_mentor_tags" */
  insert_mentors_mentor_tags_one?: Maybe<Mentors_Mentor_Tags>;
  /** insert a single row into the table: "mentors" */
  insert_mentors_one?: Maybe<Mentors>;
  /** insert data into the table: "metodologies" */
  insert_metodologies?: Maybe<Metodologies_Mutation_Response>;
  /** insert a single row into the table: "metodologies" */
  insert_metodologies_one?: Maybe<Metodologies>;
  /** insert data into the table: "multimedia_contents" */
  insert_multimedia_contents?: Maybe<Multimedia_Contents_Mutation_Response>;
  /** insert data into the table: "multimedia_contents_links" */
  insert_multimedia_contents_links?: Maybe<Multimedia_Contents_Links_Mutation_Response>;
  /** insert a single row into the table: "multimedia_contents_links" */
  insert_multimedia_contents_links_one?: Maybe<Multimedia_Contents_Links>;
  /** insert a single row into the table: "multimedia_contents" */
  insert_multimedia_contents_one?: Maybe<Multimedia_Contents>;
  /** insert data into the table: "outputs" */
  insert_outputs?: Maybe<Outputs_Mutation_Response>;
  /** insert data into the table: "outputs_icons" */
  insert_outputs_icons?: Maybe<Outputs_Icons_Mutation_Response>;
  /** insert a single row into the table: "outputs_icons" */
  insert_outputs_icons_one?: Maybe<Outputs_Icons>;
  /** insert a single row into the table: "outputs" */
  insert_outputs_one?: Maybe<Outputs>;
  /** insert data into the table: "outputs_tools" */
  insert_outputs_tools?: Maybe<Outputs_Tools_Mutation_Response>;
  /** insert a single row into the table: "outputs_tools" */
  insert_outputs_tools_one?: Maybe<Outputs_Tools>;
  /** insert data into the table: "questionnaires" */
  insert_questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** insert a single row into the table: "questionnaires" */
  insert_questionnaires_one?: Maybe<Questionnaires>;
  /** insert data into the table: "questionnaires_teams_states" */
  insert_questionnaires_teams_states?: Maybe<Questionnaires_Teams_States_Mutation_Response>;
  /** insert a single row into the table: "questionnaires_teams_states" */
  insert_questionnaires_teams_states_one?: Maybe<Questionnaires_Teams_States>;
  /** insert data into the table: "questions" */
  insert_questions?: Maybe<Questions_Mutation_Response>;
  /** insert data into the table: "questions_answers" */
  insert_questions_answers?: Maybe<Questions_Answers_Mutation_Response>;
  /** insert a single row into the table: "questions_answers" */
  insert_questions_answers_one?: Maybe<Questions_Answers>;
  /** insert a single row into the table: "questions" */
  insert_questions_one?: Maybe<Questions>;
  /** insert data into the table: "questions_options" */
  insert_questions_options?: Maybe<Questions_Options_Mutation_Response>;
  /** insert a single row into the table: "questions_options" */
  insert_questions_options_one?: Maybe<Questions_Options>;
  /** insert data into the table: "questions_types" */
  insert_questions_types?: Maybe<Questions_Types_Mutation_Response>;
  /** insert a single row into the table: "questions_types" */
  insert_questions_types_one?: Maybe<Questions_Types>;
  /** insert data into the table: "repositories" */
  insert_repositories?: Maybe<Repositories_Mutation_Response>;
  /** insert a single row into the table: "repositories" */
  insert_repositories_one?: Maybe<Repositories>;
  /** insert data into the table: "requested_mentorings" */
  insert_requested_mentorings?: Maybe<Requested_Mentorings_Mutation_Response>;
  /** insert a single row into the table: "requested_mentorings" */
  insert_requested_mentorings_one?: Maybe<Requested_Mentorings>;
  /** insert data into the table: "requested_mentorings_status" */
  insert_requested_mentorings_status?: Maybe<Requested_Mentorings_Status_Mutation_Response>;
  /** insert a single row into the table: "requested_mentorings_status" */
  insert_requested_mentorings_status_one?: Maybe<Requested_Mentorings_Status>;
  /** insert data into the table: "requests_mentor_tags" */
  insert_requests_mentor_tags?: Maybe<Requests_Mentor_Tags_Mutation_Response>;
  /** insert a single row into the table: "requests_mentor_tags" */
  insert_requests_mentor_tags_one?: Maybe<Requests_Mentor_Tags>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<Roles_Mutation_Response>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<Roles>;
  /** insert data into the table: "sections" */
  insert_sections?: Maybe<Sections_Mutation_Response>;
  /** insert data into the table: "sections_icons" */
  insert_sections_icons?: Maybe<Sections_Icons_Mutation_Response>;
  /** insert a single row into the table: "sections_icons" */
  insert_sections_icons_one?: Maybe<Sections_Icons>;
  /** insert a single row into the table: "sections" */
  insert_sections_one?: Maybe<Sections>;
  /** insert data into the table: "social_medias" */
  insert_social_medias?: Maybe<Social_Medias_Mutation_Response>;
  /** insert a single row into the table: "social_medias" */
  insert_social_medias_one?: Maybe<Social_Medias>;
  /** insert data into the table: "tag_conversion" */
  insert_tag_conversion?: Maybe<Tag_Conversion_Mutation_Response>;
  /** insert a single row into the table: "tag_conversion" */
  insert_tag_conversion_one?: Maybe<Tag_Conversion>;
  /** insert data into the table: "tags" */
  insert_tags?: Maybe<Tags_Mutation_Response>;
  /** insert a single row into the table: "tags" */
  insert_tags_one?: Maybe<Tags>;
  /** insert data into the table: "team_invites" */
  insert_team_invites?: Maybe<Team_Invites_Mutation_Response>;
  /** insert a single row into the table: "team_invites" */
  insert_team_invites_one?: Maybe<Team_Invites>;
  /** insert data into the table: "teams" */
  insert_teams?: Maybe<Teams_Mutation_Response>;
  /** insert data into the table: "teams_names" */
  insert_teams_names?: Maybe<Teams_Names_Mutation_Response>;
  /** insert a single row into the table: "teams_names" */
  insert_teams_names_one?: Maybe<Teams_Names>;
  /** insert a single row into the table: "teams" */
  insert_teams_one?: Maybe<Teams>;
  /** insert data into the table: "teams_tags" */
  insert_teams_tags?: Maybe<Teams_Tags_Mutation_Response>;
  /** insert a single row into the table: "teams_tags" */
  insert_teams_tags_one?: Maybe<Teams_Tags>;
  /** insert data into the table: "teams_users" */
  insert_teams_users?: Maybe<Teams_Users_Mutation_Response>;
  /** insert a single row into the table: "teams_users" */
  insert_teams_users_one?: Maybe<Teams_Users>;
  /** insert data into the table: "tools" */
  insert_tools?: Maybe<Tools_Mutation_Response>;
  /** insert data into the table: "tools_icons" */
  insert_tools_icons?: Maybe<Tools_Icons_Mutation_Response>;
  /** insert a single row into the table: "tools_icons" */
  insert_tools_icons_one?: Maybe<Tools_Icons>;
  /** insert a single row into the table: "tools" */
  insert_tools_one?: Maybe<Tools>;
  /** insert data into the table: "user_info" */
  insert_user_info?: Maybe<User_Info_Mutation_Response>;
  /** insert a single row into the table: "user_info" */
  insert_user_info_one?: Maybe<User_Info>;
  /** insert data into the table: "user_invites" */
  insert_user_invites?: Maybe<User_Invites_Mutation_Response>;
  /** insert a single row into the table: "user_invites" */
  insert_user_invites_one?: Maybe<User_Invites>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert data into the table: "users_actions" */
  insert_users_actions?: Maybe<Users_Actions_Mutation_Response>;
  /** insert a single row into the table: "users_actions" */
  insert_users_actions_one?: Maybe<Users_Actions>;
  /** insert data into the table: "users_actions_types" */
  insert_users_actions_types?: Maybe<Users_Actions_Types_Mutation_Response>;
  /** insert a single row into the table: "users_actions_types" */
  insert_users_actions_types_one?: Maybe<Users_Actions_Types>;
  /** insert data into the table: "users_documents" */
  insert_users_documents?: Maybe<Users_Documents_Mutation_Response>;
  /** insert a single row into the table: "users_documents" */
  insert_users_documents_one?: Maybe<Users_Documents>;
  /** insert data into the table: "users_documents_types" */
  insert_users_documents_types?: Maybe<Users_Documents_Types_Mutation_Response>;
  /** insert a single row into the table: "users_documents_types" */
  insert_users_documents_types_one?: Maybe<Users_Documents_Types>;
  /** insert data into the table: "users_names" */
  insert_users_names?: Maybe<Users_Names_Mutation_Response>;
  /** insert a single row into the table: "users_names" */
  insert_users_names_one?: Maybe<Users_Names>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "video_repositories" */
  insert_video_repositories?: Maybe<Video_Repositories_Mutation_Response>;
  /** insert a single row into the table: "video_repositories" */
  insert_video_repositories_one?: Maybe<Video_Repositories>;
  /** insert data into the table: "videos" */
  insert_videos?: Maybe<Videos_Mutation_Response>;
  /** insert a single row into the table: "videos" */
  insert_videos_one?: Maybe<Videos>;
  /** insert data into the table: "videos_youtube_tags" */
  insert_videos_youtube_tags?: Maybe<Videos_Youtube_Tags_Mutation_Response>;
  /** insert a single row into the table: "videos_youtube_tags" */
  insert_videos_youtube_tags_one?: Maybe<Videos_Youtube_Tags>;
  /** insert data into the table: "youtube_tags" */
  insert_youtube_tags?: Maybe<Youtube_Tags_Mutation_Response>;
  /** insert a single row into the table: "youtube_tags" */
  insert_youtube_tags_one?: Maybe<Youtube_Tags>;
  inviteNewUser?: Maybe<InviteNewUserOutput>;
  inviteUserToTeam?: Maybe<InviteUserToTeamOutput>;
  populateVideos: Scalars['Boolean'];
  respondTeamInvitation?: Maybe<RespondTeamInvitationOutput>;
  /** Send Email */
  sendEmail?: Maybe<SendUserEmailOutput>;
  sendManagerEmail?: Maybe<SendManagerEmailOutput>;
  sendUserEmail?: Maybe<SendUserEmailOutput>;
  updateUserRole?: Maybe<UpdateUserRoleOutput>;
  /** update data of the table: "available_mentoring_dates" */
  update_available_mentoring_dates?: Maybe<Available_Mentoring_Dates_Mutation_Response>;
  /** update single row of the table: "available_mentoring_dates" */
  update_available_mentoring_dates_by_pk?: Maybe<Available_Mentoring_Dates>;
  /** update multiples rows of table: "available_mentoring_dates" */
  update_available_mentoring_dates_many?: Maybe<Array<Maybe<Available_Mentoring_Dates_Mutation_Response>>>;
  /** update data of the table: "contents" */
  update_contents?: Maybe<Contents_Mutation_Response>;
  /** update single row of the table: "contents" */
  update_contents_by_pk?: Maybe<Contents>;
  /** update multiples rows of table: "contents" */
  update_contents_many?: Maybe<Array<Maybe<Contents_Mutation_Response>>>;
  /** update data of the table: "contents_sections" */
  update_contents_sections?: Maybe<Contents_Sections_Mutation_Response>;
  /** update single row of the table: "contents_sections" */
  update_contents_sections_by_pk?: Maybe<Contents_Sections>;
  /** update multiples rows of table: "contents_sections" */
  update_contents_sections_many?: Maybe<Array<Maybe<Contents_Sections_Mutation_Response>>>;
  /** update data of the table: "diagnostics" */
  update_diagnostics?: Maybe<Diagnostics_Mutation_Response>;
  /** update single row of the table: "diagnostics" */
  update_diagnostics_by_pk?: Maybe<Diagnostics>;
  /** update data of the table: "diagnostics_comments" */
  update_diagnostics_comments?: Maybe<Diagnostics_Comments_Mutation_Response>;
  /** update single row of the table: "diagnostics_comments" */
  update_diagnostics_comments_by_pk?: Maybe<Diagnostics_Comments>;
  /** update multiples rows of table: "diagnostics_comments" */
  update_diagnostics_comments_many?: Maybe<Array<Maybe<Diagnostics_Comments_Mutation_Response>>>;
  /** update data of the table: "diagnostics_groups" */
  update_diagnostics_groups?: Maybe<Diagnostics_Groups_Mutation_Response>;
  /** update single row of the table: "diagnostics_groups" */
  update_diagnostics_groups_by_pk?: Maybe<Diagnostics_Groups>;
  /** update multiples rows of table: "diagnostics_groups" */
  update_diagnostics_groups_many?: Maybe<Array<Maybe<Diagnostics_Groups_Mutation_Response>>>;
  /** update multiples rows of table: "diagnostics" */
  update_diagnostics_many?: Maybe<Array<Maybe<Diagnostics_Mutation_Response>>>;
  /** update data of the table: "diagnostics_questions" */
  update_diagnostics_questions?: Maybe<Diagnostics_Questions_Mutation_Response>;
  /** update data of the table: "diagnostics_questions_answers" */
  update_diagnostics_questions_answers?: Maybe<Diagnostics_Questions_Answers_Mutation_Response>;
  /** update single row of the table: "diagnostics_questions_answers" */
  update_diagnostics_questions_answers_by_pk?: Maybe<Diagnostics_Questions_Answers>;
  /** update multiples rows of table: "diagnostics_questions_answers" */
  update_diagnostics_questions_answers_many?: Maybe<Array<Maybe<Diagnostics_Questions_Answers_Mutation_Response>>>;
  /** update single row of the table: "diagnostics_questions" */
  update_diagnostics_questions_by_pk?: Maybe<Diagnostics_Questions>;
  /** update multiples rows of table: "diagnostics_questions" */
  update_diagnostics_questions_many?: Maybe<Array<Maybe<Diagnostics_Questions_Mutation_Response>>>;
  /** update data of the table: "diagnostics_sections" */
  update_diagnostics_sections?: Maybe<Diagnostics_Sections_Mutation_Response>;
  /** update single row of the table: "diagnostics_sections" */
  update_diagnostics_sections_by_pk?: Maybe<Diagnostics_Sections>;
  /** update multiples rows of table: "diagnostics_sections" */
  update_diagnostics_sections_many?: Maybe<Array<Maybe<Diagnostics_Sections_Mutation_Response>>>;
  /** update data of the table: "diagnostics_subsections" */
  update_diagnostics_subsections?: Maybe<Diagnostics_Subsections_Mutation_Response>;
  /** update single row of the table: "diagnostics_subsections" */
  update_diagnostics_subsections_by_pk?: Maybe<Diagnostics_Subsections>;
  /** update multiples rows of table: "diagnostics_subsections" */
  update_diagnostics_subsections_many?: Maybe<Array<Maybe<Diagnostics_Subsections_Mutation_Response>>>;
  /** update data of the table: "diagnostics_tabs" */
  update_diagnostics_tabs?: Maybe<Diagnostics_Tabs_Mutation_Response>;
  /** update single row of the table: "diagnostics_tabs" */
  update_diagnostics_tabs_by_pk?: Maybe<Diagnostics_Tabs>;
  /** update multiples rows of table: "diagnostics_tabs" */
  update_diagnostics_tabs_many?: Maybe<Array<Maybe<Diagnostics_Tabs_Mutation_Response>>>;
  /** update data of the table: "diagnostics_tabs_sections" */
  update_diagnostics_tabs_sections?: Maybe<Diagnostics_Tabs_Sections_Mutation_Response>;
  /** update single row of the table: "diagnostics_tabs_sections" */
  update_diagnostics_tabs_sections_by_pk?: Maybe<Diagnostics_Tabs_Sections>;
  /** update multiples rows of table: "diagnostics_tabs_sections" */
  update_diagnostics_tabs_sections_many?: Maybe<Array<Maybe<Diagnostics_Tabs_Sections_Mutation_Response>>>;
  /** update data of the table: "files" */
  update_files?: Maybe<Files_Mutation_Response>;
  /** update single row of the table: "files" */
  update_files_by_pk?: Maybe<Files>;
  /** update multiples rows of table: "files" */
  update_files_many?: Maybe<Array<Maybe<Files_Mutation_Response>>>;
  /** update data of the table: "groups" */
  update_groups?: Maybe<Groups_Mutation_Response>;
  /** update single row of the table: "groups" */
  update_groups_by_pk?: Maybe<Groups>;
  /** update data of the table: "groups_contents" */
  update_groups_contents?: Maybe<Groups_Contents_Mutation_Response>;
  /** update single row of the table: "groups_contents" */
  update_groups_contents_by_pk?: Maybe<Groups_Contents>;
  /** update multiples rows of table: "groups_contents" */
  update_groups_contents_many?: Maybe<Array<Maybe<Groups_Contents_Mutation_Response>>>;
  /** update data of the table: "groups_flags" */
  update_groups_flags?: Maybe<Groups_Flags_Mutation_Response>;
  /** update single row of the table: "groups_flags" */
  update_groups_flags_by_pk?: Maybe<Groups_Flags>;
  /** update multiples rows of table: "groups_flags" */
  update_groups_flags_many?: Maybe<Array<Maybe<Groups_Flags_Mutation_Response>>>;
  /** update data of the table: "groups_group_flags" */
  update_groups_group_flags?: Maybe<Groups_Group_Flags_Mutation_Response>;
  /** update single row of the table: "groups_group_flags" */
  update_groups_group_flags_by_pk?: Maybe<Groups_Group_Flags>;
  /** update multiples rows of table: "groups_group_flags" */
  update_groups_group_flags_many?: Maybe<Array<Maybe<Groups_Group_Flags_Mutation_Response>>>;
  /** update multiples rows of table: "groups" */
  update_groups_many?: Maybe<Array<Maybe<Groups_Mutation_Response>>>;
  /** update data of the table: "groups_names" */
  update_groups_names?: Maybe<Groups_Names_Mutation_Response>;
  /** update single row of the table: "groups_names" */
  update_groups_names_by_pk?: Maybe<Groups_Names>;
  /** update multiples rows of table: "groups_names" */
  update_groups_names_many?: Maybe<Array<Maybe<Groups_Names_Mutation_Response>>>;
  /** update data of the table: "groups_teams" */
  update_groups_teams?: Maybe<Groups_Teams_Mutation_Response>;
  /** update single row of the table: "groups_teams" */
  update_groups_teams_by_pk?: Maybe<Groups_Teams>;
  /** update multiples rows of table: "groups_teams" */
  update_groups_teams_many?: Maybe<Array<Maybe<Groups_Teams_Mutation_Response>>>;
  /** update data of the table: "icons_types" */
  update_icons_types?: Maybe<Icons_Types_Mutation_Response>;
  /** update single row of the table: "icons_types" */
  update_icons_types_by_pk?: Maybe<Icons_Types>;
  /** update multiples rows of table: "icons_types" */
  update_icons_types_many?: Maybe<Array<Maybe<Icons_Types_Mutation_Response>>>;
  /** update data of the table: "invite_states" */
  update_invite_states?: Maybe<Invite_States_Mutation_Response>;
  /** update single row of the table: "invite_states" */
  update_invite_states_by_pk?: Maybe<Invite_States>;
  /** update multiples rows of table: "invite_states" */
  update_invite_states_many?: Maybe<Array<Maybe<Invite_States_Mutation_Response>>>;
  /** update data of the table: "kanban_columns" */
  update_kanban_columns?: Maybe<Kanban_Columns_Mutation_Response>;
  /** update single row of the table: "kanban_columns" */
  update_kanban_columns_by_pk?: Maybe<Kanban_Columns>;
  /** update multiples rows of table: "kanban_columns" */
  update_kanban_columns_many?: Maybe<Array<Maybe<Kanban_Columns_Mutation_Response>>>;
  /** update data of the table: "kanbans" */
  update_kanbans?: Maybe<Kanbans_Mutation_Response>;
  /** update single row of the table: "kanbans" */
  update_kanbans_by_pk?: Maybe<Kanbans>;
  /** update data of the table: "kanbans_cards" */
  update_kanbans_cards?: Maybe<Kanbans_Cards_Mutation_Response>;
  /** update data of the table: "kanbans_cards_assignations" */
  update_kanbans_cards_assignations?: Maybe<Kanbans_Cards_Assignations_Mutation_Response>;
  /** update single row of the table: "kanbans_cards_assignations" */
  update_kanbans_cards_assignations_by_pk?: Maybe<Kanbans_Cards_Assignations>;
  /** update multiples rows of table: "kanbans_cards_assignations" */
  update_kanbans_cards_assignations_many?: Maybe<Array<Maybe<Kanbans_Cards_Assignations_Mutation_Response>>>;
  /** update data of the table: "kanbans_cards_attachments" */
  update_kanbans_cards_attachments?: Maybe<Kanbans_Cards_Attachments_Mutation_Response>;
  /** update single row of the table: "kanbans_cards_attachments" */
  update_kanbans_cards_attachments_by_pk?: Maybe<Kanbans_Cards_Attachments>;
  /** update multiples rows of table: "kanbans_cards_attachments" */
  update_kanbans_cards_attachments_many?: Maybe<Array<Maybe<Kanbans_Cards_Attachments_Mutation_Response>>>;
  /** update single row of the table: "kanbans_cards" */
  update_kanbans_cards_by_pk?: Maybe<Kanbans_Cards>;
  /** update data of the table: "kanbans_cards_comments" */
  update_kanbans_cards_comments?: Maybe<Kanbans_Cards_Comments_Mutation_Response>;
  /** update single row of the table: "kanbans_cards_comments" */
  update_kanbans_cards_comments_by_pk?: Maybe<Kanbans_Cards_Comments>;
  /** update multiples rows of table: "kanbans_cards_comments" */
  update_kanbans_cards_comments_many?: Maybe<Array<Maybe<Kanbans_Cards_Comments_Mutation_Response>>>;
  /** update multiples rows of table: "kanbans_cards" */
  update_kanbans_cards_many?: Maybe<Array<Maybe<Kanbans_Cards_Mutation_Response>>>;
  /** update multiples rows of table: "kanbans" */
  update_kanbans_many?: Maybe<Array<Maybe<Kanbans_Mutation_Response>>>;
  /** update data of the table: "locations" */
  update_locations?: Maybe<Locations_Mutation_Response>;
  /** update single row of the table: "locations" */
  update_locations_by_pk?: Maybe<Locations>;
  /** update multiples rows of table: "locations" */
  update_locations_many?: Maybe<Array<Maybe<Locations_Mutation_Response>>>;
  /** update data of the table: "locations_names" */
  update_locations_names?: Maybe<Locations_Names_Mutation_Response>;
  /** update single row of the table: "locations_names" */
  update_locations_names_by_pk?: Maybe<Locations_Names>;
  /** update multiples rows of table: "locations_names" */
  update_locations_names_many?: Maybe<Array<Maybe<Locations_Names_Mutation_Response>>>;
  /** update data of the table: "mentor_additional_data" */
  update_mentor_additional_data?: Maybe<Mentor_Additional_Data_Mutation_Response>;
  /** update single row of the table: "mentor_additional_data" */
  update_mentor_additional_data_by_pk?: Maybe<Mentor_Additional_Data>;
  /** update multiples rows of table: "mentor_additional_data" */
  update_mentor_additional_data_many?: Maybe<Array<Maybe<Mentor_Additional_Data_Mutation_Response>>>;
  /** update data of the table: "mentor_tags" */
  update_mentor_tags?: Maybe<Mentor_Tags_Mutation_Response>;
  /** update single row of the table: "mentor_tags" */
  update_mentor_tags_by_pk?: Maybe<Mentor_Tags>;
  /** update multiples rows of table: "mentor_tags" */
  update_mentor_tags_many?: Maybe<Array<Maybe<Mentor_Tags_Mutation_Response>>>;
  /** update data of the table: "mentoring_records" */
  update_mentoring_records?: Maybe<Mentoring_Records_Mutation_Response>;
  /** update data of the table: "mentoring_records_attachments" */
  update_mentoring_records_attachments?: Maybe<Mentoring_Records_Attachments_Mutation_Response>;
  /** update single row of the table: "mentoring_records_attachments" */
  update_mentoring_records_attachments_by_pk?: Maybe<Mentoring_Records_Attachments>;
  /** update multiples rows of table: "mentoring_records_attachments" */
  update_mentoring_records_attachments_many?: Maybe<Array<Maybe<Mentoring_Records_Attachments_Mutation_Response>>>;
  /** update single row of the table: "mentoring_records" */
  update_mentoring_records_by_pk?: Maybe<Mentoring_Records>;
  /** update multiples rows of table: "mentoring_records" */
  update_mentoring_records_many?: Maybe<Array<Maybe<Mentoring_Records_Mutation_Response>>>;
  /** update data of the table: "mentoring_records_members" */
  update_mentoring_records_members?: Maybe<Mentoring_Records_Members_Mutation_Response>;
  /** update single row of the table: "mentoring_records_members" */
  update_mentoring_records_members_by_pk?: Maybe<Mentoring_Records_Members>;
  /** update multiples rows of table: "mentoring_records_members" */
  update_mentoring_records_members_many?: Maybe<Array<Maybe<Mentoring_Records_Members_Mutation_Response>>>;
  /** update data of the table: "mentoring_reschedules" */
  update_mentoring_reschedules?: Maybe<Mentoring_Reschedules_Mutation_Response>;
  /** update single row of the table: "mentoring_reschedules" */
  update_mentoring_reschedules_by_pk?: Maybe<Mentoring_Reschedules>;
  /** update multiples rows of table: "mentoring_reschedules" */
  update_mentoring_reschedules_many?: Maybe<Array<Maybe<Mentoring_Reschedules_Mutation_Response>>>;
  /** update data of the table: "mentoring_scheduling" */
  update_mentoring_scheduling?: Maybe<Mentoring_Scheduling_Mutation_Response>;
  /** update single row of the table: "mentoring_scheduling" */
  update_mentoring_scheduling_by_pk?: Maybe<Mentoring_Scheduling>;
  /** update multiples rows of table: "mentoring_scheduling" */
  update_mentoring_scheduling_many?: Maybe<Array<Maybe<Mentoring_Scheduling_Mutation_Response>>>;
  /** update data of the table: "mentoring_scheduling_meeting" */
  update_mentoring_scheduling_meeting?: Maybe<Mentoring_Scheduling_Meeting_Mutation_Response>;
  /** update single row of the table: "mentoring_scheduling_meeting" */
  update_mentoring_scheduling_meeting_by_pk?: Maybe<Mentoring_Scheduling_Meeting>;
  /** update multiples rows of table: "mentoring_scheduling_meeting" */
  update_mentoring_scheduling_meeting_many?: Maybe<Array<Maybe<Mentoring_Scheduling_Meeting_Mutation_Response>>>;
  /** update data of the table: "mentoring_scheduling_members" */
  update_mentoring_scheduling_members?: Maybe<Mentoring_Scheduling_Members_Mutation_Response>;
  /** update single row of the table: "mentoring_scheduling_members" */
  update_mentoring_scheduling_members_by_pk?: Maybe<Mentoring_Scheduling_Members>;
  /** update multiples rows of table: "mentoring_scheduling_members" */
  update_mentoring_scheduling_members_many?: Maybe<Array<Maybe<Mentoring_Scheduling_Members_Mutation_Response>>>;
  /** update data of the table: "mentors" */
  update_mentors?: Maybe<Mentors_Mutation_Response>;
  /** update data of the table: "mentors_blablabla" */
  update_mentors_blablabla?: Maybe<Mentors_Blablabla_Mutation_Response>;
  /** update single row of the table: "mentors_blablabla" */
  update_mentors_blablabla_by_pk?: Maybe<Mentors_Blablabla>;
  /** update multiples rows of table: "mentors_blablabla" */
  update_mentors_blablabla_many?: Maybe<Array<Maybe<Mentors_Blablabla_Mutation_Response>>>;
  /** update single row of the table: "mentors" */
  update_mentors_by_pk?: Maybe<Mentors>;
  /** update multiples rows of table: "mentors" */
  update_mentors_many?: Maybe<Array<Maybe<Mentors_Mutation_Response>>>;
  /** update data of the table: "mentors_mentor_tags" */
  update_mentors_mentor_tags?: Maybe<Mentors_Mentor_Tags_Mutation_Response>;
  /** update single row of the table: "mentors_mentor_tags" */
  update_mentors_mentor_tags_by_pk?: Maybe<Mentors_Mentor_Tags>;
  /** update multiples rows of table: "mentors_mentor_tags" */
  update_mentors_mentor_tags_many?: Maybe<Array<Maybe<Mentors_Mentor_Tags_Mutation_Response>>>;
  /** update data of the table: "metodologies" */
  update_metodologies?: Maybe<Metodologies_Mutation_Response>;
  /** update single row of the table: "metodologies" */
  update_metodologies_by_pk?: Maybe<Metodologies>;
  /** update multiples rows of table: "metodologies" */
  update_metodologies_many?: Maybe<Array<Maybe<Metodologies_Mutation_Response>>>;
  /** update data of the table: "multimedia_contents" */
  update_multimedia_contents?: Maybe<Multimedia_Contents_Mutation_Response>;
  /** update single row of the table: "multimedia_contents" */
  update_multimedia_contents_by_pk?: Maybe<Multimedia_Contents>;
  /** update data of the table: "multimedia_contents_links" */
  update_multimedia_contents_links?: Maybe<Multimedia_Contents_Links_Mutation_Response>;
  /** update single row of the table: "multimedia_contents_links" */
  update_multimedia_contents_links_by_pk?: Maybe<Multimedia_Contents_Links>;
  /** update multiples rows of table: "multimedia_contents_links" */
  update_multimedia_contents_links_many?: Maybe<Array<Maybe<Multimedia_Contents_Links_Mutation_Response>>>;
  /** update multiples rows of table: "multimedia_contents" */
  update_multimedia_contents_many?: Maybe<Array<Maybe<Multimedia_Contents_Mutation_Response>>>;
  /** update data of the table: "outputs" */
  update_outputs?: Maybe<Outputs_Mutation_Response>;
  /** update single row of the table: "outputs" */
  update_outputs_by_pk?: Maybe<Outputs>;
  /** update data of the table: "outputs_icons" */
  update_outputs_icons?: Maybe<Outputs_Icons_Mutation_Response>;
  /** update single row of the table: "outputs_icons" */
  update_outputs_icons_by_pk?: Maybe<Outputs_Icons>;
  /** update multiples rows of table: "outputs_icons" */
  update_outputs_icons_many?: Maybe<Array<Maybe<Outputs_Icons_Mutation_Response>>>;
  /** update multiples rows of table: "outputs" */
  update_outputs_many?: Maybe<Array<Maybe<Outputs_Mutation_Response>>>;
  /** update data of the table: "outputs_tools" */
  update_outputs_tools?: Maybe<Outputs_Tools_Mutation_Response>;
  /** update single row of the table: "outputs_tools" */
  update_outputs_tools_by_pk?: Maybe<Outputs_Tools>;
  /** update multiples rows of table: "outputs_tools" */
  update_outputs_tools_many?: Maybe<Array<Maybe<Outputs_Tools_Mutation_Response>>>;
  /** update data of the table: "questionnaires" */
  update_questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** update single row of the table: "questionnaires" */
  update_questionnaires_by_pk?: Maybe<Questionnaires>;
  /** update multiples rows of table: "questionnaires" */
  update_questionnaires_many?: Maybe<Array<Maybe<Questionnaires_Mutation_Response>>>;
  /** update data of the table: "questionnaires_teams_states" */
  update_questionnaires_teams_states?: Maybe<Questionnaires_Teams_States_Mutation_Response>;
  /** update single row of the table: "questionnaires_teams_states" */
  update_questionnaires_teams_states_by_pk?: Maybe<Questionnaires_Teams_States>;
  /** update multiples rows of table: "questionnaires_teams_states" */
  update_questionnaires_teams_states_many?: Maybe<Array<Maybe<Questionnaires_Teams_States_Mutation_Response>>>;
  /** update data of the table: "questions" */
  update_questions?: Maybe<Questions_Mutation_Response>;
  /** update data of the table: "questions_answers" */
  update_questions_answers?: Maybe<Questions_Answers_Mutation_Response>;
  /** update single row of the table: "questions_answers" */
  update_questions_answers_by_pk?: Maybe<Questions_Answers>;
  /** update multiples rows of table: "questions_answers" */
  update_questions_answers_many?: Maybe<Array<Maybe<Questions_Answers_Mutation_Response>>>;
  /** update single row of the table: "questions" */
  update_questions_by_pk?: Maybe<Questions>;
  /** update multiples rows of table: "questions" */
  update_questions_many?: Maybe<Array<Maybe<Questions_Mutation_Response>>>;
  /** update data of the table: "questions_options" */
  update_questions_options?: Maybe<Questions_Options_Mutation_Response>;
  /** update single row of the table: "questions_options" */
  update_questions_options_by_pk?: Maybe<Questions_Options>;
  /** update multiples rows of table: "questions_options" */
  update_questions_options_many?: Maybe<Array<Maybe<Questions_Options_Mutation_Response>>>;
  /** update data of the table: "questions_types" */
  update_questions_types?: Maybe<Questions_Types_Mutation_Response>;
  /** update single row of the table: "questions_types" */
  update_questions_types_by_pk?: Maybe<Questions_Types>;
  /** update multiples rows of table: "questions_types" */
  update_questions_types_many?: Maybe<Array<Maybe<Questions_Types_Mutation_Response>>>;
  /** update data of the table: "repositories" */
  update_repositories?: Maybe<Repositories_Mutation_Response>;
  /** update single row of the table: "repositories" */
  update_repositories_by_pk?: Maybe<Repositories>;
  /** update multiples rows of table: "repositories" */
  update_repositories_many?: Maybe<Array<Maybe<Repositories_Mutation_Response>>>;
  /** update data of the table: "requested_mentorings" */
  update_requested_mentorings?: Maybe<Requested_Mentorings_Mutation_Response>;
  /** update single row of the table: "requested_mentorings" */
  update_requested_mentorings_by_pk?: Maybe<Requested_Mentorings>;
  /** update multiples rows of table: "requested_mentorings" */
  update_requested_mentorings_many?: Maybe<Array<Maybe<Requested_Mentorings_Mutation_Response>>>;
  /** update data of the table: "requested_mentorings_status" */
  update_requested_mentorings_status?: Maybe<Requested_Mentorings_Status_Mutation_Response>;
  /** update single row of the table: "requested_mentorings_status" */
  update_requested_mentorings_status_by_pk?: Maybe<Requested_Mentorings_Status>;
  /** update multiples rows of table: "requested_mentorings_status" */
  update_requested_mentorings_status_many?: Maybe<Array<Maybe<Requested_Mentorings_Status_Mutation_Response>>>;
  /** update data of the table: "requests_mentor_tags" */
  update_requests_mentor_tags?: Maybe<Requests_Mentor_Tags_Mutation_Response>;
  /** update single row of the table: "requests_mentor_tags" */
  update_requests_mentor_tags_by_pk?: Maybe<Requests_Mentor_Tags>;
  /** update multiples rows of table: "requests_mentor_tags" */
  update_requests_mentor_tags_many?: Maybe<Array<Maybe<Requests_Mentor_Tags_Mutation_Response>>>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update multiples rows of table: "roles" */
  update_roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
  /** update data of the table: "sections" */
  update_sections?: Maybe<Sections_Mutation_Response>;
  /** update single row of the table: "sections" */
  update_sections_by_pk?: Maybe<Sections>;
  /** update data of the table: "sections_icons" */
  update_sections_icons?: Maybe<Sections_Icons_Mutation_Response>;
  /** update single row of the table: "sections_icons" */
  update_sections_icons_by_pk?: Maybe<Sections_Icons>;
  /** update multiples rows of table: "sections_icons" */
  update_sections_icons_many?: Maybe<Array<Maybe<Sections_Icons_Mutation_Response>>>;
  /** update multiples rows of table: "sections" */
  update_sections_many?: Maybe<Array<Maybe<Sections_Mutation_Response>>>;
  /** update data of the table: "social_medias" */
  update_social_medias?: Maybe<Social_Medias_Mutation_Response>;
  /** update single row of the table: "social_medias" */
  update_social_medias_by_pk?: Maybe<Social_Medias>;
  /** update multiples rows of table: "social_medias" */
  update_social_medias_many?: Maybe<Array<Maybe<Social_Medias_Mutation_Response>>>;
  /** update data of the table: "tag_conversion" */
  update_tag_conversion?: Maybe<Tag_Conversion_Mutation_Response>;
  /** update single row of the table: "tag_conversion" */
  update_tag_conversion_by_pk?: Maybe<Tag_Conversion>;
  /** update multiples rows of table: "tag_conversion" */
  update_tag_conversion_many?: Maybe<Array<Maybe<Tag_Conversion_Mutation_Response>>>;
  /** update data of the table: "tags" */
  update_tags?: Maybe<Tags_Mutation_Response>;
  /** update single row of the table: "tags" */
  update_tags_by_pk?: Maybe<Tags>;
  /** update multiples rows of table: "tags" */
  update_tags_many?: Maybe<Array<Maybe<Tags_Mutation_Response>>>;
  /** update data of the table: "team_invites" */
  update_team_invites?: Maybe<Team_Invites_Mutation_Response>;
  /** update single row of the table: "team_invites" */
  update_team_invites_by_pk?: Maybe<Team_Invites>;
  /** update multiples rows of table: "team_invites" */
  update_team_invites_many?: Maybe<Array<Maybe<Team_Invites_Mutation_Response>>>;
  /** update data of the table: "teams" */
  update_teams?: Maybe<Teams_Mutation_Response>;
  /** update single row of the table: "teams" */
  update_teams_by_pk?: Maybe<Teams>;
  /** update multiples rows of table: "teams" */
  update_teams_many?: Maybe<Array<Maybe<Teams_Mutation_Response>>>;
  /** update data of the table: "teams_names" */
  update_teams_names?: Maybe<Teams_Names_Mutation_Response>;
  /** update single row of the table: "teams_names" */
  update_teams_names_by_pk?: Maybe<Teams_Names>;
  /** update multiples rows of table: "teams_names" */
  update_teams_names_many?: Maybe<Array<Maybe<Teams_Names_Mutation_Response>>>;
  /** update data of the table: "teams_tags" */
  update_teams_tags?: Maybe<Teams_Tags_Mutation_Response>;
  /** update single row of the table: "teams_tags" */
  update_teams_tags_by_pk?: Maybe<Teams_Tags>;
  /** update multiples rows of table: "teams_tags" */
  update_teams_tags_many?: Maybe<Array<Maybe<Teams_Tags_Mutation_Response>>>;
  /** update data of the table: "teams_users" */
  update_teams_users?: Maybe<Teams_Users_Mutation_Response>;
  /** update single row of the table: "teams_users" */
  update_teams_users_by_pk?: Maybe<Teams_Users>;
  /** update multiples rows of table: "teams_users" */
  update_teams_users_many?: Maybe<Array<Maybe<Teams_Users_Mutation_Response>>>;
  /** update data of the table: "tools" */
  update_tools?: Maybe<Tools_Mutation_Response>;
  /** update single row of the table: "tools" */
  update_tools_by_pk?: Maybe<Tools>;
  /** update data of the table: "tools_icons" */
  update_tools_icons?: Maybe<Tools_Icons_Mutation_Response>;
  /** update single row of the table: "tools_icons" */
  update_tools_icons_by_pk?: Maybe<Tools_Icons>;
  /** update multiples rows of table: "tools_icons" */
  update_tools_icons_many?: Maybe<Array<Maybe<Tools_Icons_Mutation_Response>>>;
  /** update multiples rows of table: "tools" */
  update_tools_many?: Maybe<Array<Maybe<Tools_Mutation_Response>>>;
  /** update data of the table: "user_info" */
  update_user_info?: Maybe<User_Info_Mutation_Response>;
  /** update single row of the table: "user_info" */
  update_user_info_by_pk?: Maybe<User_Info>;
  /** update multiples rows of table: "user_info" */
  update_user_info_many?: Maybe<Array<Maybe<User_Info_Mutation_Response>>>;
  /** update data of the table: "user_invites" */
  update_user_invites?: Maybe<User_Invites_Mutation_Response>;
  /** update single row of the table: "user_invites" */
  update_user_invites_by_pk?: Maybe<User_Invites>;
  /** update multiples rows of table: "user_invites" */
  update_user_invites_many?: Maybe<Array<Maybe<User_Invites_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update data of the table: "users_actions" */
  update_users_actions?: Maybe<Users_Actions_Mutation_Response>;
  /** update single row of the table: "users_actions" */
  update_users_actions_by_pk?: Maybe<Users_Actions>;
  /** update multiples rows of table: "users_actions" */
  update_users_actions_many?: Maybe<Array<Maybe<Users_Actions_Mutation_Response>>>;
  /** update data of the table: "users_actions_types" */
  update_users_actions_types?: Maybe<Users_Actions_Types_Mutation_Response>;
  /** update single row of the table: "users_actions_types" */
  update_users_actions_types_by_pk?: Maybe<Users_Actions_Types>;
  /** update multiples rows of table: "users_actions_types" */
  update_users_actions_types_many?: Maybe<Array<Maybe<Users_Actions_Types_Mutation_Response>>>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "users_documents" */
  update_users_documents?: Maybe<Users_Documents_Mutation_Response>;
  /** update single row of the table: "users_documents" */
  update_users_documents_by_pk?: Maybe<Users_Documents>;
  /** update multiples rows of table: "users_documents" */
  update_users_documents_many?: Maybe<Array<Maybe<Users_Documents_Mutation_Response>>>;
  /** update data of the table: "users_documents_types" */
  update_users_documents_types?: Maybe<Users_Documents_Types_Mutation_Response>;
  /** update single row of the table: "users_documents_types" */
  update_users_documents_types_by_pk?: Maybe<Users_Documents_Types>;
  /** update multiples rows of table: "users_documents_types" */
  update_users_documents_types_many?: Maybe<Array<Maybe<Users_Documents_Types_Mutation_Response>>>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "users_names" */
  update_users_names?: Maybe<Users_Names_Mutation_Response>;
  /** update single row of the table: "users_names" */
  update_users_names_by_pk?: Maybe<Users_Names>;
  /** update multiples rows of table: "users_names" */
  update_users_names_many?: Maybe<Array<Maybe<Users_Names_Mutation_Response>>>;
  /** update data of the table: "video_repositories" */
  update_video_repositories?: Maybe<Video_Repositories_Mutation_Response>;
  /** update single row of the table: "video_repositories" */
  update_video_repositories_by_pk?: Maybe<Video_Repositories>;
  /** update multiples rows of table: "video_repositories" */
  update_video_repositories_many?: Maybe<Array<Maybe<Video_Repositories_Mutation_Response>>>;
  /** update data of the table: "videos" */
  update_videos?: Maybe<Videos_Mutation_Response>;
  /** update single row of the table: "videos" */
  update_videos_by_pk?: Maybe<Videos>;
  /** update multiples rows of table: "videos" */
  update_videos_many?: Maybe<Array<Maybe<Videos_Mutation_Response>>>;
  /** update data of the table: "videos_youtube_tags" */
  update_videos_youtube_tags?: Maybe<Videos_Youtube_Tags_Mutation_Response>;
  /** update single row of the table: "videos_youtube_tags" */
  update_videos_youtube_tags_by_pk?: Maybe<Videos_Youtube_Tags>;
  /** update multiples rows of table: "videos_youtube_tags" */
  update_videos_youtube_tags_many?: Maybe<Array<Maybe<Videos_Youtube_Tags_Mutation_Response>>>;
  /** update data of the table: "youtube_tags" */
  update_youtube_tags?: Maybe<Youtube_Tags_Mutation_Response>;
  /** update single row of the table: "youtube_tags" */
  update_youtube_tags_by_pk?: Maybe<Youtube_Tags>;
  /** update multiples rows of table: "youtube_tags" */
  update_youtube_tags_many?: Maybe<Array<Maybe<Youtube_Tags_Mutation_Response>>>;
  uploadFile?: Maybe<UploadOutput>;
  uploadProfilePicture?: Maybe<UploadProfilePictureOutput>;
};


/** mutation root */
export type Mutation_RootCreateUserArgs = {
  address: Scalars['String'];
  address_doc_b64str?: InputMaybe<Scalars['String']>;
  cep: Scalars['String'];
  city: Scalars['String'];
  cpf: Scalars['String'];
  dob: Scalars['date'];
  email: Scalars['String'];
  id_doc_b64str: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
  secondLine?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  team_id?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateUserPreregisteredArgs = {
  email: Scalars['String'];
  id_doc_b64str: Scalars['String'];
  password: Scalars['String'];
  team_id?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Available_Mentoring_DatesArgs = {
  where: Available_Mentoring_Dates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Available_Mentoring_Dates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContentsArgs = {
  where: Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Contents_SectionsArgs = {
  where: Contents_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contents_Sections_By_PkArgs = {
  content_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_DiagnosticsArgs = {
  where: Diagnostics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_CommentsArgs = {
  where: Diagnostics_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Comments_By_PkArgs = {
  diagnostic_tab_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_GroupsArgs = {
  where: Diagnostics_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Groups_By_PkArgs = {
  diagnostic_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_QuestionsArgs = {
  where: Diagnostics_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Questions_AnswersArgs = {
  where: Diagnostics_Questions_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Questions_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_SectionsArgs = {
  where: Diagnostics_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Sections_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_SubsectionsArgs = {
  where: Diagnostics_Subsections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Subsections_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_TabsArgs = {
  where: Diagnostics_Tabs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Tabs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Tabs_SectionsArgs = {
  where: Diagnostics_Tabs_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Diagnostics_Tabs_Sections_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FilesArgs = {
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GroupsArgs = {
  where: Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Groups_ContentsArgs = {
  where: Groups_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Groups_Contents_By_PkArgs = {
  content_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Groups_FlagsArgs = {
  where: Groups_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Groups_Flags_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Groups_Group_FlagsArgs = {
  where: Groups_Group_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Groups_Group_Flags_By_PkArgs = {
  flag: Groups_Flags_Enum;
  group_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Groups_NamesArgs = {
  where: Groups_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Groups_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Groups_TeamsArgs = {
  where: Groups_Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Groups_Teams_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Icons_TypesArgs = {
  where: Icons_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Icons_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Invite_StatesArgs = {
  where: Invite_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invite_States_By_PkArgs = {
  state: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Kanban_ColumnsArgs = {
  where: Kanban_Columns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kanban_Columns_By_PkArgs = {
  column: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_KanbansArgs = {
  where: Kanbans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_CardsArgs = {
  where: Kanbans_Cards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_Cards_AssignationsArgs = {
  where: Kanbans_Cards_Assignations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_Cards_Assignations_By_PkArgs = {
  card_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_Cards_AttachmentsArgs = {
  where: Kanbans_Cards_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_Cards_Attachments_By_PkArgs = {
  card_id: Scalars['uuid'];
  file_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_Cards_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_Cards_CommentsArgs = {
  where: Kanbans_Cards_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kanbans_Cards_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LocationsArgs = {
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Locations_NamesArgs = {
  where: Locations_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Locations_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mentor_Additional_DataArgs = {
  where: Mentor_Additional_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mentor_Additional_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mentor_TagsArgs = {
  where: Mentor_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mentor_Tags_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_RecordsArgs = {
  where: Mentoring_Records_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_Records_AttachmentsArgs = {
  where: Mentoring_Records_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_Records_Attachments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_Records_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_Records_MembersArgs = {
  where: Mentoring_Records_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_Records_Members_By_PkArgs = {
  member_id: Scalars['String'];
  mentoring_record_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_ReschedulesArgs = {
  where: Mentoring_Reschedules_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_Reschedules_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_SchedulingArgs = {
  where: Mentoring_Scheduling_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_Scheduling_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_Scheduling_MeetingArgs = {
  where: Mentoring_Scheduling_Meeting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_Scheduling_Meeting_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_Scheduling_MembersArgs = {
  where: Mentoring_Scheduling_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mentoring_Scheduling_Members_By_PkArgs = {
  id: Scalars['uuid'];
  member_id: Scalars['String'];
  mentoring_scheduling_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MentorsArgs = {
  where: Mentors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mentors_BlablablaArgs = {
  where: Mentors_Blablabla_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mentors_Blablabla_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mentors_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Mentors_Mentor_TagsArgs = {
  where: Mentors_Mentor_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mentors_Mentor_Tags_By_PkArgs = {
  mentor_id: Scalars['uuid'];
  tag_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_MetodologiesArgs = {
  where: Metodologies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Metodologies_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Multimedia_ContentsArgs = {
  where: Multimedia_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Multimedia_Contents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Multimedia_Contents_LinksArgs = {
  where: Multimedia_Contents_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Multimedia_Contents_Links_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_OutputsArgs = {
  where: Outputs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Outputs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Outputs_IconsArgs = {
  where: Outputs_Icons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Outputs_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Outputs_ToolsArgs = {
  where: Outputs_Tools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Outputs_Tools_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionnairesArgs = {
  where: Questionnaires_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questionnaires_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Questionnaires_Teams_StatesArgs = {
  where: Questionnaires_Teams_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questionnaires_Teams_States_By_PkArgs = {
  questionnaire_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_QuestionsArgs = {
  where: Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questions_AnswersArgs = {
  where: Questions_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questions_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Questions_OptionsArgs = {
  where: Questions_Options_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questions_Options_By_PkArgs = {
  label: Scalars['String'];
  question_id: Scalars['uuid'];
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Questions_TypesArgs = {
  where: Questions_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questions_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_RepositoriesArgs = {
  where: Repositories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Repositories_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Requested_MentoringsArgs = {
  where: Requested_Mentorings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Requested_Mentorings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Requested_Mentorings_StatusArgs = {
  where: Requested_Mentorings_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Requested_Mentorings_Status_By_PkArgs = {
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Requests_Mentor_TagsArgs = {
  where: Requests_Mentor_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Requests_Mentor_Tags_By_PkArgs = {
  request_id: Scalars['uuid'];
  tag_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  role: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_SectionsArgs = {
  where: Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sections_By_PkArgs = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Sections_IconsArgs = {
  where: Sections_Icons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sections_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Social_MediasArgs = {
  where: Social_Medias_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Social_Medias_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Tag_ConversionArgs = {
  where: Tag_Conversion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tag_Conversion_By_PkArgs = {
  old_tag: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TagsArgs = {
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tags_By_PkArgs = {
  id: Scalars['uuid'];
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Team_InvitesArgs = {
  where: Team_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Team_Invites_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TeamsArgs = {
  where: Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teams_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Teams_NamesArgs = {
  where: Teams_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teams_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Teams_TagsArgs = {
  where: Teams_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teams_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Teams_UsersArgs = {
  where: Teams_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teams_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ToolsArgs = {
  where: Tools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tools_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Tools_IconsArgs = {
  where: Tools_Icons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tools_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_InfoArgs = {
  where: User_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Info_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_InvitesArgs = {
  where: User_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Invites_By_PkArgs = {
  id: Scalars['uuid'];
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_ActionsArgs = {
  where: Users_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Actions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Users_Actions_TypesArgs = {
  where: Users_Actions_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Actions_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  email: Scalars['String'];
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Users_DocumentsArgs = {
  where: Users_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Documents_By_PkArgs = {
  file_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Users_Documents_TypesArgs = {
  where: Users_Documents_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Documents_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Users_NamesArgs = {
  where: Users_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Video_RepositoriesArgs = {
  where: Video_Repositories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Video_Repositories_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_VideosArgs = {
  where: Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Videos_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Videos_Youtube_TagsArgs = {
  where: Videos_Youtube_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Videos_Youtube_Tags_By_PkArgs = {
  tag: Scalars['String'];
  video_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Youtube_TagsArgs = {
  where: Youtube_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Youtube_Tags_By_PkArgs = {
  tag: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Available_Mentoring_DatesArgs = {
  objects: Array<Available_Mentoring_Dates_Insert_Input>;
  on_conflict?: InputMaybe<Available_Mentoring_Dates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Available_Mentoring_Dates_OneArgs = {
  object: Available_Mentoring_Dates_Insert_Input;
  on_conflict?: InputMaybe<Available_Mentoring_Dates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContentsArgs = {
  objects: Array<Contents_Insert_Input>;
  on_conflict?: InputMaybe<Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contents_OneArgs = {
  object: Contents_Insert_Input;
  on_conflict?: InputMaybe<Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contents_SectionsArgs = {
  objects: Array<Contents_Sections_Insert_Input>;
  on_conflict?: InputMaybe<Contents_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contents_Sections_OneArgs = {
  object: Contents_Sections_Insert_Input;
  on_conflict?: InputMaybe<Contents_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DiagnosticsArgs = {
  objects: Array<Diagnostics_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_CommentsArgs = {
  objects: Array<Diagnostics_Comments_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Comments_OneArgs = {
  object: Diagnostics_Comments_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_GroupsArgs = {
  objects: Array<Diagnostics_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Groups_OneArgs = {
  object: Diagnostics_Groups_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_OneArgs = {
  object: Diagnostics_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_QuestionsArgs = {
  objects: Array<Diagnostics_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Questions_AnswersArgs = {
  objects: Array<Diagnostics_Questions_Answers_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Questions_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Questions_Answers_OneArgs = {
  object: Diagnostics_Questions_Answers_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Questions_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Questions_OneArgs = {
  object: Diagnostics_Questions_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_SectionsArgs = {
  objects: Array<Diagnostics_Sections_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Sections_OneArgs = {
  object: Diagnostics_Sections_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_SubsectionsArgs = {
  objects: Array<Diagnostics_Subsections_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Subsections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Subsections_OneArgs = {
  object: Diagnostics_Subsections_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Subsections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_TabsArgs = {
  objects: Array<Diagnostics_Tabs_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Tabs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Tabs_OneArgs = {
  object: Diagnostics_Tabs_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Tabs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Tabs_SectionsArgs = {
  objects: Array<Diagnostics_Tabs_Sections_Insert_Input>;
  on_conflict?: InputMaybe<Diagnostics_Tabs_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Diagnostics_Tabs_Sections_OneArgs = {
  object: Diagnostics_Tabs_Sections_Insert_Input;
  on_conflict?: InputMaybe<Diagnostics_Tabs_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_OneArgs = {
  object: Files_Insert_Input;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GroupsArgs = {
  objects: Array<Groups_Insert_Input>;
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_ContentsArgs = {
  objects: Array<Groups_Contents_Insert_Input>;
  on_conflict?: InputMaybe<Groups_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_Contents_OneArgs = {
  object: Groups_Contents_Insert_Input;
  on_conflict?: InputMaybe<Groups_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_FlagsArgs = {
  objects: Array<Groups_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Groups_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_Flags_OneArgs = {
  object: Groups_Flags_Insert_Input;
  on_conflict?: InputMaybe<Groups_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_Group_FlagsArgs = {
  objects: Array<Groups_Group_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Groups_Group_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_Group_Flags_OneArgs = {
  object: Groups_Group_Flags_Insert_Input;
  on_conflict?: InputMaybe<Groups_Group_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_NamesArgs = {
  objects: Array<Groups_Names_Insert_Input>;
  on_conflict?: InputMaybe<Groups_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_Names_OneArgs = {
  object: Groups_Names_Insert_Input;
  on_conflict?: InputMaybe<Groups_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_OneArgs = {
  object: Groups_Insert_Input;
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_TeamsArgs = {
  objects: Array<Groups_Teams_Insert_Input>;
  on_conflict?: InputMaybe<Groups_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_Teams_OneArgs = {
  object: Groups_Teams_Insert_Input;
  on_conflict?: InputMaybe<Groups_Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Icons_TypesArgs = {
  objects: Array<Icons_Types_Insert_Input>;
  on_conflict?: InputMaybe<Icons_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Icons_Types_OneArgs = {
  object: Icons_Types_Insert_Input;
  on_conflict?: InputMaybe<Icons_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invite_StatesArgs = {
  objects: Array<Invite_States_Insert_Input>;
  on_conflict?: InputMaybe<Invite_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invite_States_OneArgs = {
  object: Invite_States_Insert_Input;
  on_conflict?: InputMaybe<Invite_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanban_ColumnsArgs = {
  objects: Array<Kanban_Columns_Insert_Input>;
  on_conflict?: InputMaybe<Kanban_Columns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanban_Columns_OneArgs = {
  object: Kanban_Columns_Insert_Input;
  on_conflict?: InputMaybe<Kanban_Columns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_KanbansArgs = {
  objects: Array<Kanbans_Insert_Input>;
  on_conflict?: InputMaybe<Kanbans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_CardsArgs = {
  objects: Array<Kanbans_Cards_Insert_Input>;
  on_conflict?: InputMaybe<Kanbans_Cards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_Cards_AssignationsArgs = {
  objects: Array<Kanbans_Cards_Assignations_Insert_Input>;
  on_conflict?: InputMaybe<Kanbans_Cards_Assignations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_Cards_Assignations_OneArgs = {
  object: Kanbans_Cards_Assignations_Insert_Input;
  on_conflict?: InputMaybe<Kanbans_Cards_Assignations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_Cards_AttachmentsArgs = {
  objects: Array<Kanbans_Cards_Attachments_Insert_Input>;
  on_conflict?: InputMaybe<Kanbans_Cards_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_Cards_Attachments_OneArgs = {
  object: Kanbans_Cards_Attachments_Insert_Input;
  on_conflict?: InputMaybe<Kanbans_Cards_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_Cards_CommentsArgs = {
  objects: Array<Kanbans_Cards_Comments_Insert_Input>;
  on_conflict?: InputMaybe<Kanbans_Cards_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_Cards_Comments_OneArgs = {
  object: Kanbans_Cards_Comments_Insert_Input;
  on_conflict?: InputMaybe<Kanbans_Cards_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_Cards_OneArgs = {
  object: Kanbans_Cards_Insert_Input;
  on_conflict?: InputMaybe<Kanbans_Cards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kanbans_OneArgs = {
  object: Kanbans_Insert_Input;
  on_conflict?: InputMaybe<Kanbans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationsArgs = {
  objects: Array<Locations_Insert_Input>;
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locations_NamesArgs = {
  objects: Array<Locations_Names_Insert_Input>;
  on_conflict?: InputMaybe<Locations_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locations_Names_OneArgs = {
  object: Locations_Names_Insert_Input;
  on_conflict?: InputMaybe<Locations_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locations_OneArgs = {
  object: Locations_Insert_Input;
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentor_Additional_DataArgs = {
  objects: Array<Mentor_Additional_Data_Insert_Input>;
  on_conflict?: InputMaybe<Mentor_Additional_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentor_Additional_Data_OneArgs = {
  object: Mentor_Additional_Data_Insert_Input;
  on_conflict?: InputMaybe<Mentor_Additional_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentor_TagsArgs = {
  objects: Array<Mentor_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Mentor_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentor_Tags_OneArgs = {
  object: Mentor_Tags_Insert_Input;
  on_conflict?: InputMaybe<Mentor_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_RecordsArgs = {
  objects: Array<Mentoring_Records_Insert_Input>;
  on_conflict?: InputMaybe<Mentoring_Records_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_Records_AttachmentsArgs = {
  objects: Array<Mentoring_Records_Attachments_Insert_Input>;
  on_conflict?: InputMaybe<Mentoring_Records_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_Records_Attachments_OneArgs = {
  object: Mentoring_Records_Attachments_Insert_Input;
  on_conflict?: InputMaybe<Mentoring_Records_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_Records_MembersArgs = {
  objects: Array<Mentoring_Records_Members_Insert_Input>;
  on_conflict?: InputMaybe<Mentoring_Records_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_Records_Members_OneArgs = {
  object: Mentoring_Records_Members_Insert_Input;
  on_conflict?: InputMaybe<Mentoring_Records_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_Records_OneArgs = {
  object: Mentoring_Records_Insert_Input;
  on_conflict?: InputMaybe<Mentoring_Records_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_ReschedulesArgs = {
  objects: Array<Mentoring_Reschedules_Insert_Input>;
  on_conflict?: InputMaybe<Mentoring_Reschedules_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_Reschedules_OneArgs = {
  object: Mentoring_Reschedules_Insert_Input;
  on_conflict?: InputMaybe<Mentoring_Reschedules_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_SchedulingArgs = {
  objects: Array<Mentoring_Scheduling_Insert_Input>;
  on_conflict?: InputMaybe<Mentoring_Scheduling_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_Scheduling_MeetingArgs = {
  objects: Array<Mentoring_Scheduling_Meeting_Insert_Input>;
  on_conflict?: InputMaybe<Mentoring_Scheduling_Meeting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_Scheduling_Meeting_OneArgs = {
  object: Mentoring_Scheduling_Meeting_Insert_Input;
  on_conflict?: InputMaybe<Mentoring_Scheduling_Meeting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_Scheduling_MembersArgs = {
  objects: Array<Mentoring_Scheduling_Members_Insert_Input>;
  on_conflict?: InputMaybe<Mentoring_Scheduling_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_Scheduling_Members_OneArgs = {
  object: Mentoring_Scheduling_Members_Insert_Input;
  on_conflict?: InputMaybe<Mentoring_Scheduling_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentoring_Scheduling_OneArgs = {
  object: Mentoring_Scheduling_Insert_Input;
  on_conflict?: InputMaybe<Mentoring_Scheduling_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MentorsArgs = {
  objects: Array<Mentors_Insert_Input>;
  on_conflict?: InputMaybe<Mentors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentors_BlablablaArgs = {
  objects: Array<Mentors_Blablabla_Insert_Input>;
  on_conflict?: InputMaybe<Mentors_Blablabla_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentors_Blablabla_OneArgs = {
  object: Mentors_Blablabla_Insert_Input;
  on_conflict?: InputMaybe<Mentors_Blablabla_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentors_Mentor_TagsArgs = {
  objects: Array<Mentors_Mentor_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Mentors_Mentor_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentors_Mentor_Tags_OneArgs = {
  object: Mentors_Mentor_Tags_Insert_Input;
  on_conflict?: InputMaybe<Mentors_Mentor_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mentors_OneArgs = {
  object: Mentors_Insert_Input;
  on_conflict?: InputMaybe<Mentors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MetodologiesArgs = {
  objects: Array<Metodologies_Insert_Input>;
  on_conflict?: InputMaybe<Metodologies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Metodologies_OneArgs = {
  object: Metodologies_Insert_Input;
  on_conflict?: InputMaybe<Metodologies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Multimedia_ContentsArgs = {
  objects: Array<Multimedia_Contents_Insert_Input>;
  on_conflict?: InputMaybe<Multimedia_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Multimedia_Contents_LinksArgs = {
  objects: Array<Multimedia_Contents_Links_Insert_Input>;
  on_conflict?: InputMaybe<Multimedia_Contents_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Multimedia_Contents_Links_OneArgs = {
  object: Multimedia_Contents_Links_Insert_Input;
  on_conflict?: InputMaybe<Multimedia_Contents_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Multimedia_Contents_OneArgs = {
  object: Multimedia_Contents_Insert_Input;
  on_conflict?: InputMaybe<Multimedia_Contents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OutputsArgs = {
  objects: Array<Outputs_Insert_Input>;
  on_conflict?: InputMaybe<Outputs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outputs_IconsArgs = {
  objects: Array<Outputs_Icons_Insert_Input>;
  on_conflict?: InputMaybe<Outputs_Icons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outputs_Icons_OneArgs = {
  object: Outputs_Icons_Insert_Input;
  on_conflict?: InputMaybe<Outputs_Icons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outputs_OneArgs = {
  object: Outputs_Insert_Input;
  on_conflict?: InputMaybe<Outputs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outputs_ToolsArgs = {
  objects: Array<Outputs_Tools_Insert_Input>;
  on_conflict?: InputMaybe<Outputs_Tools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outputs_Tools_OneArgs = {
  object: Outputs_Tools_Insert_Input;
  on_conflict?: InputMaybe<Outputs_Tools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionnairesArgs = {
  objects: Array<Questionnaires_Insert_Input>;
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaires_OneArgs = {
  object: Questionnaires_Insert_Input;
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaires_Teams_StatesArgs = {
  objects: Array<Questionnaires_Teams_States_Insert_Input>;
  on_conflict?: InputMaybe<Questionnaires_Teams_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaires_Teams_States_OneArgs = {
  object: Questionnaires_Teams_States_Insert_Input;
  on_conflict?: InputMaybe<Questionnaires_Teams_States_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_QuestionsArgs = {
  objects: Array<Questions_Insert_Input>;
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_AnswersArgs = {
  objects: Array<Questions_Answers_Insert_Input>;
  on_conflict?: InputMaybe<Questions_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_Answers_OneArgs = {
  object: Questions_Answers_Insert_Input;
  on_conflict?: InputMaybe<Questions_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_OneArgs = {
  object: Questions_Insert_Input;
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_OptionsArgs = {
  objects: Array<Questions_Options_Insert_Input>;
  on_conflict?: InputMaybe<Questions_Options_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_Options_OneArgs = {
  object: Questions_Options_Insert_Input;
  on_conflict?: InputMaybe<Questions_Options_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_TypesArgs = {
  objects: Array<Questions_Types_Insert_Input>;
  on_conflict?: InputMaybe<Questions_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questions_Types_OneArgs = {
  object: Questions_Types_Insert_Input;
  on_conflict?: InputMaybe<Questions_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RepositoriesArgs = {
  objects: Array<Repositories_Insert_Input>;
  on_conflict?: InputMaybe<Repositories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Repositories_OneArgs = {
  object: Repositories_Insert_Input;
  on_conflict?: InputMaybe<Repositories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Requested_MentoringsArgs = {
  objects: Array<Requested_Mentorings_Insert_Input>;
  on_conflict?: InputMaybe<Requested_Mentorings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Requested_Mentorings_OneArgs = {
  object: Requested_Mentorings_Insert_Input;
  on_conflict?: InputMaybe<Requested_Mentorings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Requested_Mentorings_StatusArgs = {
  objects: Array<Requested_Mentorings_Status_Insert_Input>;
  on_conflict?: InputMaybe<Requested_Mentorings_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Requested_Mentorings_Status_OneArgs = {
  object: Requested_Mentorings_Status_Insert_Input;
  on_conflict?: InputMaybe<Requested_Mentorings_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Requests_Mentor_TagsArgs = {
  objects: Array<Requests_Mentor_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Requests_Mentor_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Requests_Mentor_Tags_OneArgs = {
  object: Requests_Mentor_Tags_Insert_Input;
  on_conflict?: InputMaybe<Requests_Mentor_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SectionsArgs = {
  objects: Array<Sections_Insert_Input>;
  on_conflict?: InputMaybe<Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sections_IconsArgs = {
  objects: Array<Sections_Icons_Insert_Input>;
  on_conflict?: InputMaybe<Sections_Icons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sections_Icons_OneArgs = {
  object: Sections_Icons_Insert_Input;
  on_conflict?: InputMaybe<Sections_Icons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sections_OneArgs = {
  object: Sections_Insert_Input;
  on_conflict?: InputMaybe<Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Social_MediasArgs = {
  objects: Array<Social_Medias_Insert_Input>;
  on_conflict?: InputMaybe<Social_Medias_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Social_Medias_OneArgs = {
  object: Social_Medias_Insert_Input;
  on_conflict?: InputMaybe<Social_Medias_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_ConversionArgs = {
  objects: Array<Tag_Conversion_Insert_Input>;
  on_conflict?: InputMaybe<Tag_Conversion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tag_Conversion_OneArgs = {
  object: Tag_Conversion_Insert_Input;
  on_conflict?: InputMaybe<Tag_Conversion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TagsArgs = {
  objects: Array<Tags_Insert_Input>;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tags_OneArgs = {
  object: Tags_Insert_Input;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_InvitesArgs = {
  objects: Array<Team_Invites_Insert_Input>;
  on_conflict?: InputMaybe<Team_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Team_Invites_OneArgs = {
  object: Team_Invites_Insert_Input;
  on_conflict?: InputMaybe<Team_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeamsArgs = {
  objects: Array<Teams_Insert_Input>;
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_NamesArgs = {
  objects: Array<Teams_Names_Insert_Input>;
  on_conflict?: InputMaybe<Teams_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_Names_OneArgs = {
  object: Teams_Names_Insert_Input;
  on_conflict?: InputMaybe<Teams_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_OneArgs = {
  object: Teams_Insert_Input;
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_TagsArgs = {
  objects: Array<Teams_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Teams_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_Tags_OneArgs = {
  object: Teams_Tags_Insert_Input;
  on_conflict?: InputMaybe<Teams_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_UsersArgs = {
  objects: Array<Teams_Users_Insert_Input>;
  on_conflict?: InputMaybe<Teams_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teams_Users_OneArgs = {
  object: Teams_Users_Insert_Input;
  on_conflict?: InputMaybe<Teams_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ToolsArgs = {
  objects: Array<Tools_Insert_Input>;
  on_conflict?: InputMaybe<Tools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tools_IconsArgs = {
  objects: Array<Tools_Icons_Insert_Input>;
  on_conflict?: InputMaybe<Tools_Icons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tools_Icons_OneArgs = {
  object: Tools_Icons_Insert_Input;
  on_conflict?: InputMaybe<Tools_Icons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tools_OneArgs = {
  object: Tools_Insert_Input;
  on_conflict?: InputMaybe<Tools_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_InfoArgs = {
  objects: Array<User_Info_Insert_Input>;
  on_conflict?: InputMaybe<User_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Info_OneArgs = {
  object: User_Info_Insert_Input;
  on_conflict?: InputMaybe<User_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_InvitesArgs = {
  objects: Array<User_Invites_Insert_Input>;
  on_conflict?: InputMaybe<User_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Invites_OneArgs = {
  object: User_Invites_Insert_Input;
  on_conflict?: InputMaybe<User_Invites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_ActionsArgs = {
  objects: Array<Users_Actions_Insert_Input>;
  on_conflict?: InputMaybe<Users_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Actions_OneArgs = {
  object: Users_Actions_Insert_Input;
  on_conflict?: InputMaybe<Users_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Actions_TypesArgs = {
  objects: Array<Users_Actions_Types_Insert_Input>;
  on_conflict?: InputMaybe<Users_Actions_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Actions_Types_OneArgs = {
  object: Users_Actions_Types_Insert_Input;
  on_conflict?: InputMaybe<Users_Actions_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_DocumentsArgs = {
  objects: Array<Users_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Users_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Documents_OneArgs = {
  object: Users_Documents_Insert_Input;
  on_conflict?: InputMaybe<Users_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Documents_TypesArgs = {
  objects: Array<Users_Documents_Types_Insert_Input>;
  on_conflict?: InputMaybe<Users_Documents_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Documents_Types_OneArgs = {
  object: Users_Documents_Types_Insert_Input;
  on_conflict?: InputMaybe<Users_Documents_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_NamesArgs = {
  objects: Array<Users_Names_Insert_Input>;
  on_conflict?: InputMaybe<Users_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Names_OneArgs = {
  object: Users_Names_Insert_Input;
  on_conflict?: InputMaybe<Users_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Video_RepositoriesArgs = {
  objects: Array<Video_Repositories_Insert_Input>;
  on_conflict?: InputMaybe<Video_Repositories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Video_Repositories_OneArgs = {
  object: Video_Repositories_Insert_Input;
  on_conflict?: InputMaybe<Video_Repositories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VideosArgs = {
  objects: Array<Videos_Insert_Input>;
  on_conflict?: InputMaybe<Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Videos_OneArgs = {
  object: Videos_Insert_Input;
  on_conflict?: InputMaybe<Videos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Videos_Youtube_TagsArgs = {
  objects: Array<Videos_Youtube_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Videos_Youtube_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Videos_Youtube_Tags_OneArgs = {
  object: Videos_Youtube_Tags_Insert_Input;
  on_conflict?: InputMaybe<Videos_Youtube_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Youtube_TagsArgs = {
  objects: Array<Youtube_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Youtube_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Youtube_Tags_OneArgs = {
  object: Youtube_Tags_Insert_Input;
  on_conflict?: InputMaybe<Youtube_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInviteNewUserArgs = {
  email: Scalars['String'];
  preregistered?: InputMaybe<Scalars['Boolean']>;
  role: RolesEnum;
  team_id?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootInviteUserToTeamArgs = {
  team_id: Scalars['String'];
  user_email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRespondTeamInvitationArgs = {
  accept: Scalars['Boolean'];
  invite_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSendEmailArgs = {
  message: Scalars['String'];
  subject: Scalars['String'];
  to: Array<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootSendManagerEmailArgs = {
  description: Scalars['String'];
  team_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootSendUserEmailArgs = {
  date: Scalars['String'];
  status: Scalars['String'];
  team_id: Scalars['uuid'];
  time: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdateUserRoleArgs = {
  new_role: RolesEnum;
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_Available_Mentoring_DatesArgs = {
  _set?: InputMaybe<Available_Mentoring_Dates_Set_Input>;
  where: Available_Mentoring_Dates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Available_Mentoring_Dates_By_PkArgs = {
  _set?: InputMaybe<Available_Mentoring_Dates_Set_Input>;
  pk_columns: Available_Mentoring_Dates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Available_Mentoring_Dates_ManyArgs = {
  updates: Array<Available_Mentoring_Dates_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ContentsArgs = {
  _set?: InputMaybe<Contents_Set_Input>;
  where: Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contents_By_PkArgs = {
  _set?: InputMaybe<Contents_Set_Input>;
  pk_columns: Contents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contents_ManyArgs = {
  updates: Array<Contents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contents_SectionsArgs = {
  _set?: InputMaybe<Contents_Sections_Set_Input>;
  where: Contents_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contents_Sections_By_PkArgs = {
  _set?: InputMaybe<Contents_Sections_Set_Input>;
  pk_columns: Contents_Sections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contents_Sections_ManyArgs = {
  updates: Array<Contents_Sections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DiagnosticsArgs = {
  _set?: InputMaybe<Diagnostics_Set_Input>;
  where: Diagnostics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_By_PkArgs = {
  _set?: InputMaybe<Diagnostics_Set_Input>;
  pk_columns: Diagnostics_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_CommentsArgs = {
  _set?: InputMaybe<Diagnostics_Comments_Set_Input>;
  where: Diagnostics_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Comments_By_PkArgs = {
  _set?: InputMaybe<Diagnostics_Comments_Set_Input>;
  pk_columns: Diagnostics_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Comments_ManyArgs = {
  updates: Array<Diagnostics_Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_GroupsArgs = {
  _set?: InputMaybe<Diagnostics_Groups_Set_Input>;
  where: Diagnostics_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Groups_By_PkArgs = {
  _set?: InputMaybe<Diagnostics_Groups_Set_Input>;
  pk_columns: Diagnostics_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Groups_ManyArgs = {
  updates: Array<Diagnostics_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_ManyArgs = {
  updates: Array<Diagnostics_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_QuestionsArgs = {
  _inc?: InputMaybe<Diagnostics_Questions_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Questions_Set_Input>;
  where: Diagnostics_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Questions_AnswersArgs = {
  _inc?: InputMaybe<Diagnostics_Questions_Answers_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Questions_Answers_Set_Input>;
  where: Diagnostics_Questions_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Questions_Answers_By_PkArgs = {
  _inc?: InputMaybe<Diagnostics_Questions_Answers_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Questions_Answers_Set_Input>;
  pk_columns: Diagnostics_Questions_Answers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Questions_Answers_ManyArgs = {
  updates: Array<Diagnostics_Questions_Answers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Questions_By_PkArgs = {
  _inc?: InputMaybe<Diagnostics_Questions_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Questions_Set_Input>;
  pk_columns: Diagnostics_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Questions_ManyArgs = {
  updates: Array<Diagnostics_Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_SectionsArgs = {
  _set?: InputMaybe<Diagnostics_Sections_Set_Input>;
  where: Diagnostics_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Sections_By_PkArgs = {
  _set?: InputMaybe<Diagnostics_Sections_Set_Input>;
  pk_columns: Diagnostics_Sections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Sections_ManyArgs = {
  updates: Array<Diagnostics_Sections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_SubsectionsArgs = {
  _inc?: InputMaybe<Diagnostics_Subsections_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Subsections_Set_Input>;
  where: Diagnostics_Subsections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Subsections_By_PkArgs = {
  _inc?: InputMaybe<Diagnostics_Subsections_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Subsections_Set_Input>;
  pk_columns: Diagnostics_Subsections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Subsections_ManyArgs = {
  updates: Array<Diagnostics_Subsections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_TabsArgs = {
  _inc?: InputMaybe<Diagnostics_Tabs_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Tabs_Set_Input>;
  where: Diagnostics_Tabs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Tabs_By_PkArgs = {
  _inc?: InputMaybe<Diagnostics_Tabs_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Tabs_Set_Input>;
  pk_columns: Diagnostics_Tabs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Tabs_ManyArgs = {
  updates: Array<Diagnostics_Tabs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Tabs_SectionsArgs = {
  _inc?: InputMaybe<Diagnostics_Tabs_Sections_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Tabs_Sections_Set_Input>;
  where: Diagnostics_Tabs_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Tabs_Sections_By_PkArgs = {
  _inc?: InputMaybe<Diagnostics_Tabs_Sections_Inc_Input>;
  _set?: InputMaybe<Diagnostics_Tabs_Sections_Set_Input>;
  pk_columns: Diagnostics_Tabs_Sections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Diagnostics_Tabs_Sections_ManyArgs = {
  updates: Array<Diagnostics_Tabs_Sections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FilesArgs = {
  _set?: InputMaybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Files_By_PkArgs = {
  _set?: InputMaybe<Files_Set_Input>;
  pk_columns: Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Files_ManyArgs = {
  updates: Array<Files_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GroupsArgs = {
  _set?: InputMaybe<Groups_Set_Input>;
  where: Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_By_PkArgs = {
  _set?: InputMaybe<Groups_Set_Input>;
  pk_columns: Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_ContentsArgs = {
  _set?: InputMaybe<Groups_Contents_Set_Input>;
  where: Groups_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_Contents_By_PkArgs = {
  _set?: InputMaybe<Groups_Contents_Set_Input>;
  pk_columns: Groups_Contents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_Contents_ManyArgs = {
  updates: Array<Groups_Contents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_FlagsArgs = {
  _set?: InputMaybe<Groups_Flags_Set_Input>;
  where: Groups_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_Flags_By_PkArgs = {
  _set?: InputMaybe<Groups_Flags_Set_Input>;
  pk_columns: Groups_Flags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_Flags_ManyArgs = {
  updates: Array<Groups_Flags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_Group_FlagsArgs = {
  _set?: InputMaybe<Groups_Group_Flags_Set_Input>;
  where: Groups_Group_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_Group_Flags_By_PkArgs = {
  _set?: InputMaybe<Groups_Group_Flags_Set_Input>;
  pk_columns: Groups_Group_Flags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_Group_Flags_ManyArgs = {
  updates: Array<Groups_Group_Flags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_ManyArgs = {
  updates: Array<Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_NamesArgs = {
  _set?: InputMaybe<Groups_Names_Set_Input>;
  where: Groups_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_Names_By_PkArgs = {
  _set?: InputMaybe<Groups_Names_Set_Input>;
  pk_columns: Groups_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_Names_ManyArgs = {
  updates: Array<Groups_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_TeamsArgs = {
  _set?: InputMaybe<Groups_Teams_Set_Input>;
  where: Groups_Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_Teams_By_PkArgs = {
  _set?: InputMaybe<Groups_Teams_Set_Input>;
  pk_columns: Groups_Teams_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_Teams_ManyArgs = {
  updates: Array<Groups_Teams_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Icons_TypesArgs = {
  _set?: InputMaybe<Icons_Types_Set_Input>;
  where: Icons_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Icons_Types_By_PkArgs = {
  _set?: InputMaybe<Icons_Types_Set_Input>;
  pk_columns: Icons_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Icons_Types_ManyArgs = {
  updates: Array<Icons_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Invite_StatesArgs = {
  _set?: InputMaybe<Invite_States_Set_Input>;
  where: Invite_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invite_States_By_PkArgs = {
  _set?: InputMaybe<Invite_States_Set_Input>;
  pk_columns: Invite_States_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invite_States_ManyArgs = {
  updates: Array<Invite_States_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Kanban_ColumnsArgs = {
  _set?: InputMaybe<Kanban_Columns_Set_Input>;
  where: Kanban_Columns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kanban_Columns_By_PkArgs = {
  _set?: InputMaybe<Kanban_Columns_Set_Input>;
  pk_columns: Kanban_Columns_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kanban_Columns_ManyArgs = {
  updates: Array<Kanban_Columns_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_KanbansArgs = {
  _set?: InputMaybe<Kanbans_Set_Input>;
  where: Kanbans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_By_PkArgs = {
  _set?: InputMaybe<Kanbans_Set_Input>;
  pk_columns: Kanbans_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_CardsArgs = {
  _set?: InputMaybe<Kanbans_Cards_Set_Input>;
  where: Kanbans_Cards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_AssignationsArgs = {
  _set?: InputMaybe<Kanbans_Cards_Assignations_Set_Input>;
  where: Kanbans_Cards_Assignations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_Assignations_By_PkArgs = {
  _set?: InputMaybe<Kanbans_Cards_Assignations_Set_Input>;
  pk_columns: Kanbans_Cards_Assignations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_Assignations_ManyArgs = {
  updates: Array<Kanbans_Cards_Assignations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_AttachmentsArgs = {
  _set?: InputMaybe<Kanbans_Cards_Attachments_Set_Input>;
  where: Kanbans_Cards_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_Attachments_By_PkArgs = {
  _set?: InputMaybe<Kanbans_Cards_Attachments_Set_Input>;
  pk_columns: Kanbans_Cards_Attachments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_Attachments_ManyArgs = {
  updates: Array<Kanbans_Cards_Attachments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_By_PkArgs = {
  _set?: InputMaybe<Kanbans_Cards_Set_Input>;
  pk_columns: Kanbans_Cards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_CommentsArgs = {
  _set?: InputMaybe<Kanbans_Cards_Comments_Set_Input>;
  where: Kanbans_Cards_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_Comments_By_PkArgs = {
  _set?: InputMaybe<Kanbans_Cards_Comments_Set_Input>;
  pk_columns: Kanbans_Cards_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_Comments_ManyArgs = {
  updates: Array<Kanbans_Cards_Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_Cards_ManyArgs = {
  updates: Array<Kanbans_Cards_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Kanbans_ManyArgs = {
  updates: Array<Kanbans_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LocationsArgs = {
  _inc?: InputMaybe<Locations_Inc_Input>;
  _set?: InputMaybe<Locations_Set_Input>;
  where: Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_By_PkArgs = {
  _inc?: InputMaybe<Locations_Inc_Input>;
  _set?: InputMaybe<Locations_Set_Input>;
  pk_columns: Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_ManyArgs = {
  updates: Array<Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_NamesArgs = {
  _set?: InputMaybe<Locations_Names_Set_Input>;
  where: Locations_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_Names_By_PkArgs = {
  _set?: InputMaybe<Locations_Names_Set_Input>;
  pk_columns: Locations_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Locations_Names_ManyArgs = {
  updates: Array<Locations_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mentor_Additional_DataArgs = {
  _set?: InputMaybe<Mentor_Additional_Data_Set_Input>;
  where: Mentor_Additional_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mentor_Additional_Data_By_PkArgs = {
  _set?: InputMaybe<Mentor_Additional_Data_Set_Input>;
  pk_columns: Mentor_Additional_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mentor_Additional_Data_ManyArgs = {
  updates: Array<Mentor_Additional_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mentor_TagsArgs = {
  _set?: InputMaybe<Mentor_Tags_Set_Input>;
  where: Mentor_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mentor_Tags_By_PkArgs = {
  _set?: InputMaybe<Mentor_Tags_Set_Input>;
  pk_columns: Mentor_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mentor_Tags_ManyArgs = {
  updates: Array<Mentor_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_RecordsArgs = {
  _inc?: InputMaybe<Mentoring_Records_Inc_Input>;
  _set?: InputMaybe<Mentoring_Records_Set_Input>;
  where: Mentoring_Records_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Records_AttachmentsArgs = {
  _set?: InputMaybe<Mentoring_Records_Attachments_Set_Input>;
  where: Mentoring_Records_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Records_Attachments_By_PkArgs = {
  _set?: InputMaybe<Mentoring_Records_Attachments_Set_Input>;
  pk_columns: Mentoring_Records_Attachments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Records_Attachments_ManyArgs = {
  updates: Array<Mentoring_Records_Attachments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Records_By_PkArgs = {
  _inc?: InputMaybe<Mentoring_Records_Inc_Input>;
  _set?: InputMaybe<Mentoring_Records_Set_Input>;
  pk_columns: Mentoring_Records_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Records_ManyArgs = {
  updates: Array<Mentoring_Records_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Records_MembersArgs = {
  _set?: InputMaybe<Mentoring_Records_Members_Set_Input>;
  where: Mentoring_Records_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Records_Members_By_PkArgs = {
  _set?: InputMaybe<Mentoring_Records_Members_Set_Input>;
  pk_columns: Mentoring_Records_Members_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Records_Members_ManyArgs = {
  updates: Array<Mentoring_Records_Members_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_ReschedulesArgs = {
  _set?: InputMaybe<Mentoring_Reschedules_Set_Input>;
  where: Mentoring_Reschedules_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Reschedules_By_PkArgs = {
  _set?: InputMaybe<Mentoring_Reschedules_Set_Input>;
  pk_columns: Mentoring_Reschedules_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Reschedules_ManyArgs = {
  updates: Array<Mentoring_Reschedules_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_SchedulingArgs = {
  _set?: InputMaybe<Mentoring_Scheduling_Set_Input>;
  where: Mentoring_Scheduling_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Scheduling_By_PkArgs = {
  _set?: InputMaybe<Mentoring_Scheduling_Set_Input>;
  pk_columns: Mentoring_Scheduling_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Scheduling_ManyArgs = {
  updates: Array<Mentoring_Scheduling_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Scheduling_MeetingArgs = {
  _set?: InputMaybe<Mentoring_Scheduling_Meeting_Set_Input>;
  where: Mentoring_Scheduling_Meeting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Scheduling_Meeting_By_PkArgs = {
  _set?: InputMaybe<Mentoring_Scheduling_Meeting_Set_Input>;
  pk_columns: Mentoring_Scheduling_Meeting_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Scheduling_Meeting_ManyArgs = {
  updates: Array<Mentoring_Scheduling_Meeting_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Scheduling_MembersArgs = {
  _set?: InputMaybe<Mentoring_Scheduling_Members_Set_Input>;
  where: Mentoring_Scheduling_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Scheduling_Members_By_PkArgs = {
  _set?: InputMaybe<Mentoring_Scheduling_Members_Set_Input>;
  pk_columns: Mentoring_Scheduling_Members_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mentoring_Scheduling_Members_ManyArgs = {
  updates: Array<Mentoring_Scheduling_Members_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MentorsArgs = {
  _set?: InputMaybe<Mentors_Set_Input>;
  where: Mentors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mentors_BlablablaArgs = {
  _set?: InputMaybe<Mentors_Blablabla_Set_Input>;
  where: Mentors_Blablabla_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mentors_Blablabla_By_PkArgs = {
  _set?: InputMaybe<Mentors_Blablabla_Set_Input>;
  pk_columns: Mentors_Blablabla_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mentors_Blablabla_ManyArgs = {
  updates: Array<Mentors_Blablabla_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mentors_By_PkArgs = {
  _set?: InputMaybe<Mentors_Set_Input>;
  pk_columns: Mentors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mentors_ManyArgs = {
  updates: Array<Mentors_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Mentors_Mentor_TagsArgs = {
  _set?: InputMaybe<Mentors_Mentor_Tags_Set_Input>;
  where: Mentors_Mentor_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mentors_Mentor_Tags_By_PkArgs = {
  _set?: InputMaybe<Mentors_Mentor_Tags_Set_Input>;
  pk_columns: Mentors_Mentor_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mentors_Mentor_Tags_ManyArgs = {
  updates: Array<Mentors_Mentor_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MetodologiesArgs = {
  _set?: InputMaybe<Metodologies_Set_Input>;
  where: Metodologies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Metodologies_By_PkArgs = {
  _set?: InputMaybe<Metodologies_Set_Input>;
  pk_columns: Metodologies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Metodologies_ManyArgs = {
  updates: Array<Metodologies_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Multimedia_ContentsArgs = {
  _set?: InputMaybe<Multimedia_Contents_Set_Input>;
  where: Multimedia_Contents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Multimedia_Contents_By_PkArgs = {
  _set?: InputMaybe<Multimedia_Contents_Set_Input>;
  pk_columns: Multimedia_Contents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Multimedia_Contents_LinksArgs = {
  _set?: InputMaybe<Multimedia_Contents_Links_Set_Input>;
  where: Multimedia_Contents_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Multimedia_Contents_Links_By_PkArgs = {
  _set?: InputMaybe<Multimedia_Contents_Links_Set_Input>;
  pk_columns: Multimedia_Contents_Links_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Multimedia_Contents_Links_ManyArgs = {
  updates: Array<Multimedia_Contents_Links_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Multimedia_Contents_ManyArgs = {
  updates: Array<Multimedia_Contents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OutputsArgs = {
  _inc?: InputMaybe<Outputs_Inc_Input>;
  _set?: InputMaybe<Outputs_Set_Input>;
  where: Outputs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Outputs_By_PkArgs = {
  _inc?: InputMaybe<Outputs_Inc_Input>;
  _set?: InputMaybe<Outputs_Set_Input>;
  pk_columns: Outputs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Outputs_IconsArgs = {
  _set?: InputMaybe<Outputs_Icons_Set_Input>;
  where: Outputs_Icons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Outputs_Icons_By_PkArgs = {
  _set?: InputMaybe<Outputs_Icons_Set_Input>;
  pk_columns: Outputs_Icons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Outputs_Icons_ManyArgs = {
  updates: Array<Outputs_Icons_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Outputs_ManyArgs = {
  updates: Array<Outputs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Outputs_ToolsArgs = {
  _inc?: InputMaybe<Outputs_Tools_Inc_Input>;
  _set?: InputMaybe<Outputs_Tools_Set_Input>;
  where: Outputs_Tools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Outputs_Tools_By_PkArgs = {
  _inc?: InputMaybe<Outputs_Tools_Inc_Input>;
  _set?: InputMaybe<Outputs_Tools_Set_Input>;
  pk_columns: Outputs_Tools_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Outputs_Tools_ManyArgs = {
  updates: Array<Outputs_Tools_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionnairesArgs = {
  _set?: InputMaybe<Questionnaires_Set_Input>;
  where: Questionnaires_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaires_By_PkArgs = {
  _set?: InputMaybe<Questionnaires_Set_Input>;
  pk_columns: Questionnaires_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaires_ManyArgs = {
  updates: Array<Questionnaires_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaires_Teams_StatesArgs = {
  _set?: InputMaybe<Questionnaires_Teams_States_Set_Input>;
  where: Questionnaires_Teams_States_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaires_Teams_States_By_PkArgs = {
  _set?: InputMaybe<Questionnaires_Teams_States_Set_Input>;
  pk_columns: Questionnaires_Teams_States_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaires_Teams_States_ManyArgs = {
  updates: Array<Questionnaires_Teams_States_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_QuestionsArgs = {
  _inc?: InputMaybe<Questions_Inc_Input>;
  _set?: InputMaybe<Questions_Set_Input>;
  where: Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_AnswersArgs = {
  _set?: InputMaybe<Questions_Answers_Set_Input>;
  where: Questions_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_Answers_By_PkArgs = {
  _set?: InputMaybe<Questions_Answers_Set_Input>;
  pk_columns: Questions_Answers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_Answers_ManyArgs = {
  updates: Array<Questions_Answers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_By_PkArgs = {
  _inc?: InputMaybe<Questions_Inc_Input>;
  _set?: InputMaybe<Questions_Set_Input>;
  pk_columns: Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_ManyArgs = {
  updates: Array<Questions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_OptionsArgs = {
  _set?: InputMaybe<Questions_Options_Set_Input>;
  where: Questions_Options_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_Options_By_PkArgs = {
  _set?: InputMaybe<Questions_Options_Set_Input>;
  pk_columns: Questions_Options_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_Options_ManyArgs = {
  updates: Array<Questions_Options_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_TypesArgs = {
  _set?: InputMaybe<Questions_Types_Set_Input>;
  where: Questions_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_Types_By_PkArgs = {
  _set?: InputMaybe<Questions_Types_Set_Input>;
  pk_columns: Questions_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questions_Types_ManyArgs = {
  updates: Array<Questions_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RepositoriesArgs = {
  _set?: InputMaybe<Repositories_Set_Input>;
  where: Repositories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Repositories_By_PkArgs = {
  _set?: InputMaybe<Repositories_Set_Input>;
  pk_columns: Repositories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Repositories_ManyArgs = {
  updates: Array<Repositories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Requested_MentoringsArgs = {
  _inc?: InputMaybe<Requested_Mentorings_Inc_Input>;
  _set?: InputMaybe<Requested_Mentorings_Set_Input>;
  where: Requested_Mentorings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Requested_Mentorings_By_PkArgs = {
  _inc?: InputMaybe<Requested_Mentorings_Inc_Input>;
  _set?: InputMaybe<Requested_Mentorings_Set_Input>;
  pk_columns: Requested_Mentorings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Requested_Mentorings_ManyArgs = {
  updates: Array<Requested_Mentorings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Requested_Mentorings_StatusArgs = {
  _set?: InputMaybe<Requested_Mentorings_Status_Set_Input>;
  where: Requested_Mentorings_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Requested_Mentorings_Status_By_PkArgs = {
  _set?: InputMaybe<Requested_Mentorings_Status_Set_Input>;
  pk_columns: Requested_Mentorings_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Requested_Mentorings_Status_ManyArgs = {
  updates: Array<Requested_Mentorings_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Requests_Mentor_TagsArgs = {
  _set?: InputMaybe<Requests_Mentor_Tags_Set_Input>;
  where: Requests_Mentor_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Requests_Mentor_Tags_By_PkArgs = {
  _set?: InputMaybe<Requests_Mentor_Tags_Set_Input>;
  pk_columns: Requests_Mentor_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Requests_Mentor_Tags_ManyArgs = {
  updates: Array<Requests_Mentor_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_ManyArgs = {
  updates: Array<Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SectionsArgs = {
  _inc?: InputMaybe<Sections_Inc_Input>;
  _set?: InputMaybe<Sections_Set_Input>;
  where: Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sections_By_PkArgs = {
  _inc?: InputMaybe<Sections_Inc_Input>;
  _set?: InputMaybe<Sections_Set_Input>;
  pk_columns: Sections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sections_IconsArgs = {
  _set?: InputMaybe<Sections_Icons_Set_Input>;
  where: Sections_Icons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sections_Icons_By_PkArgs = {
  _set?: InputMaybe<Sections_Icons_Set_Input>;
  pk_columns: Sections_Icons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sections_Icons_ManyArgs = {
  updates: Array<Sections_Icons_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sections_ManyArgs = {
  updates: Array<Sections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Social_MediasArgs = {
  _set?: InputMaybe<Social_Medias_Set_Input>;
  where: Social_Medias_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Social_Medias_By_PkArgs = {
  _set?: InputMaybe<Social_Medias_Set_Input>;
  pk_columns: Social_Medias_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Social_Medias_ManyArgs = {
  updates: Array<Social_Medias_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_ConversionArgs = {
  _set?: InputMaybe<Tag_Conversion_Set_Input>;
  where: Tag_Conversion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_Conversion_By_PkArgs = {
  _set?: InputMaybe<Tag_Conversion_Set_Input>;
  pk_columns: Tag_Conversion_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tag_Conversion_ManyArgs = {
  updates: Array<Tag_Conversion_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TagsArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_By_PkArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  pk_columns: Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_ManyArgs = {
  updates: Array<Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Team_InvitesArgs = {
  _set?: InputMaybe<Team_Invites_Set_Input>;
  where: Team_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Invites_By_PkArgs = {
  _set?: InputMaybe<Team_Invites_Set_Input>;
  pk_columns: Team_Invites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Team_Invites_ManyArgs = {
  updates: Array<Team_Invites_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TeamsArgs = {
  _set?: InputMaybe<Teams_Set_Input>;
  where: Teams_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_By_PkArgs = {
  _set?: InputMaybe<Teams_Set_Input>;
  pk_columns: Teams_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_ManyArgs = {
  updates: Array<Teams_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_NamesArgs = {
  _set?: InputMaybe<Teams_Names_Set_Input>;
  where: Teams_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_Names_By_PkArgs = {
  _set?: InputMaybe<Teams_Names_Set_Input>;
  pk_columns: Teams_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_Names_ManyArgs = {
  updates: Array<Teams_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_TagsArgs = {
  _set?: InputMaybe<Teams_Tags_Set_Input>;
  where: Teams_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_Tags_By_PkArgs = {
  _set?: InputMaybe<Teams_Tags_Set_Input>;
  pk_columns: Teams_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_Tags_ManyArgs = {
  updates: Array<Teams_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_UsersArgs = {
  _set?: InputMaybe<Teams_Users_Set_Input>;
  where: Teams_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_Users_By_PkArgs = {
  _set?: InputMaybe<Teams_Users_Set_Input>;
  pk_columns: Teams_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teams_Users_ManyArgs = {
  updates: Array<Teams_Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ToolsArgs = {
  _inc?: InputMaybe<Tools_Inc_Input>;
  _set?: InputMaybe<Tools_Set_Input>;
  where: Tools_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tools_By_PkArgs = {
  _inc?: InputMaybe<Tools_Inc_Input>;
  _set?: InputMaybe<Tools_Set_Input>;
  pk_columns: Tools_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tools_IconsArgs = {
  _set?: InputMaybe<Tools_Icons_Set_Input>;
  where: Tools_Icons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tools_Icons_By_PkArgs = {
  _set?: InputMaybe<Tools_Icons_Set_Input>;
  pk_columns: Tools_Icons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tools_Icons_ManyArgs = {
  updates: Array<Tools_Icons_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Tools_ManyArgs = {
  updates: Array<Tools_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_InfoArgs = {
  _set?: InputMaybe<User_Info_Set_Input>;
  where: User_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Info_By_PkArgs = {
  _set?: InputMaybe<User_Info_Set_Input>;
  pk_columns: User_Info_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Info_ManyArgs = {
  updates: Array<User_Info_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_InvitesArgs = {
  _set?: InputMaybe<User_Invites_Set_Input>;
  where: User_Invites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Invites_By_PkArgs = {
  _set?: InputMaybe<User_Invites_Set_Input>;
  pk_columns: User_Invites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Invites_ManyArgs = {
  updates: Array<User_Invites_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ActionsArgs = {
  _set?: InputMaybe<Users_Actions_Set_Input>;
  where: Users_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Actions_By_PkArgs = {
  _set?: InputMaybe<Users_Actions_Set_Input>;
  pk_columns: Users_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Actions_ManyArgs = {
  updates: Array<Users_Actions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Actions_TypesArgs = {
  _set?: InputMaybe<Users_Actions_Types_Set_Input>;
  where: Users_Actions_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Actions_Types_By_PkArgs = {
  _set?: InputMaybe<Users_Actions_Types_Set_Input>;
  pk_columns: Users_Actions_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Actions_Types_ManyArgs = {
  updates: Array<Users_Actions_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_DocumentsArgs = {
  _set?: InputMaybe<Users_Documents_Set_Input>;
  where: Users_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Documents_By_PkArgs = {
  _set?: InputMaybe<Users_Documents_Set_Input>;
  pk_columns: Users_Documents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Documents_ManyArgs = {
  updates: Array<Users_Documents_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Documents_TypesArgs = {
  _set?: InputMaybe<Users_Documents_Types_Set_Input>;
  where: Users_Documents_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Documents_Types_By_PkArgs = {
  _set?: InputMaybe<Users_Documents_Types_Set_Input>;
  pk_columns: Users_Documents_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Documents_Types_ManyArgs = {
  updates: Array<Users_Documents_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_NamesArgs = {
  _set?: InputMaybe<Users_Names_Set_Input>;
  where: Users_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Names_By_PkArgs = {
  _set?: InputMaybe<Users_Names_Set_Input>;
  pk_columns: Users_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Names_ManyArgs = {
  updates: Array<Users_Names_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Video_RepositoriesArgs = {
  _set?: InputMaybe<Video_Repositories_Set_Input>;
  where: Video_Repositories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Video_Repositories_By_PkArgs = {
  _set?: InputMaybe<Video_Repositories_Set_Input>;
  pk_columns: Video_Repositories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Video_Repositories_ManyArgs = {
  updates: Array<Video_Repositories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VideosArgs = {
  _set?: InputMaybe<Videos_Set_Input>;
  where: Videos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Videos_By_PkArgs = {
  _set?: InputMaybe<Videos_Set_Input>;
  pk_columns: Videos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Videos_ManyArgs = {
  updates: Array<Videos_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Videos_Youtube_TagsArgs = {
  _set?: InputMaybe<Videos_Youtube_Tags_Set_Input>;
  where: Videos_Youtube_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Videos_Youtube_Tags_By_PkArgs = {
  _set?: InputMaybe<Videos_Youtube_Tags_Set_Input>;
  pk_columns: Videos_Youtube_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Videos_Youtube_Tags_ManyArgs = {
  updates: Array<Videos_Youtube_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Youtube_TagsArgs = {
  _set?: InputMaybe<Youtube_Tags_Set_Input>;
  where: Youtube_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Youtube_Tags_By_PkArgs = {
  _set?: InputMaybe<Youtube_Tags_Set_Input>;
  pk_columns: Youtube_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Youtube_Tags_ManyArgs = {
  updates: Array<Youtube_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUploadFileArgs = {
  base64str: Scalars['String'];
  container: Scalars['String'];
  filename: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUploadProfilePictureArgs = {
  b64_img: Scalars['String'];
  user_id: Scalars['String'];
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "outputs" */
export type Outputs = {
  __typename?: 'outputs';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  icons: Array<Outputs_Icons>;
  /** An aggregate relationship */
  icons_aggregate: Outputs_Icons_Aggregate;
  id: Scalars['uuid'];
  intro: Scalars['String'];
  /** An object relationship */
  multimedia_content: Multimedia_Contents;
  multimedia_content_id: Scalars['uuid'];
  name: Scalars['String'];
  order?: Maybe<Scalars['smallint']>;
  parent_section_id: Scalars['uuid'];
  /** An object relationship */
  section: Sections;
  slug: Scalars['String'];
  /** An array relationship */
  tools: Array<Outputs_Tools>;
  /** An aggregate relationship */
  tools_aggregate: Outputs_Tools_Aggregate;
};


/** columns and relationships of "outputs" */
export type OutputsIconsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Icons_Order_By>>;
  where?: InputMaybe<Outputs_Icons_Bool_Exp>;
};


/** columns and relationships of "outputs" */
export type OutputsIcons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Icons_Order_By>>;
  where?: InputMaybe<Outputs_Icons_Bool_Exp>;
};


/** columns and relationships of "outputs" */
export type OutputsToolsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};


/** columns and relationships of "outputs" */
export type OutputsTools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};

/** aggregated selection of "outputs" */
export type Outputs_Aggregate = {
  __typename?: 'outputs_aggregate';
  aggregate?: Maybe<Outputs_Aggregate_Fields>;
  nodes: Array<Outputs>;
};

export type Outputs_Aggregate_Bool_Exp = {
  count?: InputMaybe<Outputs_Aggregate_Bool_Exp_Count>;
};

export type Outputs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Outputs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Outputs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "outputs" */
export type Outputs_Aggregate_Fields = {
  __typename?: 'outputs_aggregate_fields';
  avg?: Maybe<Outputs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Outputs_Max_Fields>;
  min?: Maybe<Outputs_Min_Fields>;
  stddev?: Maybe<Outputs_Stddev_Fields>;
  stddev_pop?: Maybe<Outputs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outputs_Stddev_Samp_Fields>;
  sum?: Maybe<Outputs_Sum_Fields>;
  var_pop?: Maybe<Outputs_Var_Pop_Fields>;
  var_samp?: Maybe<Outputs_Var_Samp_Fields>;
  variance?: Maybe<Outputs_Variance_Fields>;
};


/** aggregate fields of "outputs" */
export type Outputs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Outputs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "outputs" */
export type Outputs_Aggregate_Order_By = {
  avg?: InputMaybe<Outputs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Outputs_Max_Order_By>;
  min?: InputMaybe<Outputs_Min_Order_By>;
  stddev?: InputMaybe<Outputs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Outputs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Outputs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Outputs_Sum_Order_By>;
  var_pop?: InputMaybe<Outputs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Outputs_Var_Samp_Order_By>;
  variance?: InputMaybe<Outputs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outputs" */
export type Outputs_Arr_Rel_Insert_Input = {
  data: Array<Outputs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Outputs_On_Conflict>;
};

/** aggregate avg on columns */
export type Outputs_Avg_Fields = {
  __typename?: 'outputs_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "outputs" */
export type Outputs_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outputs". All fields are combined with a logical 'AND'. */
export type Outputs_Bool_Exp = {
  _and?: InputMaybe<Array<Outputs_Bool_Exp>>;
  _not?: InputMaybe<Outputs_Bool_Exp>;
  _or?: InputMaybe<Array<Outputs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  icons?: InputMaybe<Outputs_Icons_Bool_Exp>;
  icons_aggregate?: InputMaybe<Outputs_Icons_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  intro?: InputMaybe<String_Comparison_Exp>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Bool_Exp>;
  multimedia_content_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Smallint_Comparison_Exp>;
  parent_section_id?: InputMaybe<Uuid_Comparison_Exp>;
  section?: InputMaybe<Sections_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  tools?: InputMaybe<Outputs_Tools_Bool_Exp>;
  tools_aggregate?: InputMaybe<Outputs_Tools_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "outputs" */
export enum Outputs_Constraint {
  /** unique or primary key constraint on columns "id" */
  OutputsIdKey = 'outputs_id_key',
  /** unique or primary key constraint on columns "parent_section_id", "order" */
  OutputsOrderParentSectionIdKey = 'outputs_order_parent_section_id_key',
  /** unique or primary key constraint on columns "id" */
  OutputsPkey = 'outputs_pkey',
  /** unique or primary key constraint on columns "parent_section_id", "slug" */
  OutputsSlugParentSectionIdKey = 'outputs_slug_parent_section_id_key'
}

/** columns and relationships of "outputs_icons" */
export type Outputs_Icons = {
  __typename?: 'outputs_icons';
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  output: Outputs;
  output_id: Scalars['uuid'];
  type: Icons_Types_Enum;
};

/** aggregated selection of "outputs_icons" */
export type Outputs_Icons_Aggregate = {
  __typename?: 'outputs_icons_aggregate';
  aggregate?: Maybe<Outputs_Icons_Aggregate_Fields>;
  nodes: Array<Outputs_Icons>;
};

export type Outputs_Icons_Aggregate_Bool_Exp = {
  count?: InputMaybe<Outputs_Icons_Aggregate_Bool_Exp_Count>;
};

export type Outputs_Icons_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Outputs_Icons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Outputs_Icons_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "outputs_icons" */
export type Outputs_Icons_Aggregate_Fields = {
  __typename?: 'outputs_icons_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Outputs_Icons_Max_Fields>;
  min?: Maybe<Outputs_Icons_Min_Fields>;
};


/** aggregate fields of "outputs_icons" */
export type Outputs_Icons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Outputs_Icons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "outputs_icons" */
export type Outputs_Icons_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Outputs_Icons_Max_Order_By>;
  min?: InputMaybe<Outputs_Icons_Min_Order_By>;
};

/** input type for inserting array relation for remote table "outputs_icons" */
export type Outputs_Icons_Arr_Rel_Insert_Input = {
  data: Array<Outputs_Icons_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Outputs_Icons_On_Conflict>;
};

/** Boolean expression to filter rows from the table "outputs_icons". All fields are combined with a logical 'AND'. */
export type Outputs_Icons_Bool_Exp = {
  _and?: InputMaybe<Array<Outputs_Icons_Bool_Exp>>;
  _not?: InputMaybe<Outputs_Icons_Bool_Exp>;
  _or?: InputMaybe<Array<Outputs_Icons_Bool_Exp>>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  output?: InputMaybe<Outputs_Bool_Exp>;
  output_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Icons_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "outputs_icons" */
export enum Outputs_Icons_Constraint {
  /** unique or primary key constraint on columns "file_id", "output_id" */
  OutputsIconsOutputIdFileIdKey = 'outputs_icons_output_id_file_id_key',
  /** unique or primary key constraint on columns "type", "output_id" */
  OutputsIconsOutputIdTypeKey = 'outputs_icons_output_id_type_key',
  /** unique or primary key constraint on columns "id" */
  OutputsIconsPkey = 'outputs_icons_pkey'
}

/** input type for inserting data into table "outputs_icons" */
export type Outputs_Icons_Insert_Input = {
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  output?: InputMaybe<Outputs_Obj_Rel_Insert_Input>;
  output_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Icons_Types_Enum>;
};

/** aggregate max on columns */
export type Outputs_Icons_Max_Fields = {
  __typename?: 'outputs_icons_max_fields';
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  output_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "outputs_icons" */
export type Outputs_Icons_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  output_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Outputs_Icons_Min_Fields = {
  __typename?: 'outputs_icons_min_fields';
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  output_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "outputs_icons" */
export type Outputs_Icons_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  output_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "outputs_icons" */
export type Outputs_Icons_Mutation_Response = {
  __typename?: 'outputs_icons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Outputs_Icons>;
};

/** on_conflict condition type for table "outputs_icons" */
export type Outputs_Icons_On_Conflict = {
  constraint: Outputs_Icons_Constraint;
  update_columns?: Array<Outputs_Icons_Update_Column>;
  where?: InputMaybe<Outputs_Icons_Bool_Exp>;
};

/** Ordering options when selecting data from "outputs_icons". */
export type Outputs_Icons_Order_By = {
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  output?: InputMaybe<Outputs_Order_By>;
  output_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: outputs_icons */
export type Outputs_Icons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "outputs_icons" */
export enum Outputs_Icons_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  OutputId = 'output_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "outputs_icons" */
export type Outputs_Icons_Set_Input = {
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  output_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Icons_Types_Enum>;
};

/** Streaming cursor of the table "outputs_icons" */
export type Outputs_Icons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outputs_Icons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outputs_Icons_Stream_Cursor_Value_Input = {
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  output_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Icons_Types_Enum>;
};

/** update columns of table "outputs_icons" */
export enum Outputs_Icons_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  OutputId = 'output_id',
  /** column name */
  Type = 'type'
}

export type Outputs_Icons_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Outputs_Icons_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outputs_Icons_Bool_Exp;
};

/** input type for incrementing numeric columns in table "outputs" */
export type Outputs_Inc_Input = {
  order?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "outputs" */
export type Outputs_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  icons?: InputMaybe<Outputs_Icons_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  intro?: InputMaybe<Scalars['String']>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Obj_Rel_Insert_Input>;
  multimedia_content_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['smallint']>;
  parent_section_id?: InputMaybe<Scalars['uuid']>;
  section?: InputMaybe<Sections_Obj_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
  tools?: InputMaybe<Outputs_Tools_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Outputs_Max_Fields = {
  __typename?: 'outputs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  multimedia_content_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['smallint']>;
  parent_section_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "outputs" */
export type Outputs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intro?: InputMaybe<Order_By>;
  multimedia_content_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  parent_section_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Outputs_Min_Fields = {
  __typename?: 'outputs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  multimedia_content_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['smallint']>;
  parent_section_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "outputs" */
export type Outputs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intro?: InputMaybe<Order_By>;
  multimedia_content_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  parent_section_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "outputs" */
export type Outputs_Mutation_Response = {
  __typename?: 'outputs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Outputs>;
};

/** input type for inserting object relation for remote table "outputs" */
export type Outputs_Obj_Rel_Insert_Input = {
  data: Outputs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Outputs_On_Conflict>;
};

/** on_conflict condition type for table "outputs" */
export type Outputs_On_Conflict = {
  constraint: Outputs_Constraint;
  update_columns?: Array<Outputs_Update_Column>;
  where?: InputMaybe<Outputs_Bool_Exp>;
};

/** Ordering options when selecting data from "outputs". */
export type Outputs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  icons_aggregate?: InputMaybe<Outputs_Icons_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  intro?: InputMaybe<Order_By>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Order_By>;
  multimedia_content_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  parent_section_id?: InputMaybe<Order_By>;
  section?: InputMaybe<Sections_Order_By>;
  slug?: InputMaybe<Order_By>;
  tools_aggregate?: InputMaybe<Outputs_Tools_Aggregate_Order_By>;
};

/** primary key columns input for table: outputs */
export type Outputs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "outputs" */
export enum Outputs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  MultimediaContentId = 'multimedia_content_id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  ParentSectionId = 'parent_section_id',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "outputs" */
export type Outputs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  intro?: InputMaybe<Scalars['String']>;
  multimedia_content_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['smallint']>;
  parent_section_id?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Outputs_Stddev_Fields = {
  __typename?: 'outputs_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "outputs" */
export type Outputs_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Outputs_Stddev_Pop_Fields = {
  __typename?: 'outputs_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "outputs" */
export type Outputs_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Outputs_Stddev_Samp_Fields = {
  __typename?: 'outputs_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "outputs" */
export type Outputs_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "outputs" */
export type Outputs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outputs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outputs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  intro?: InputMaybe<Scalars['String']>;
  multimedia_content_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['smallint']>;
  parent_section_id?: InputMaybe<Scalars['uuid']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Outputs_Sum_Fields = {
  __typename?: 'outputs_sum_fields';
  order?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "outputs" */
export type Outputs_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "outputs_tools" */
export type Outputs_Tools = {
  __typename?: 'outputs_tools';
  id: Scalars['uuid'];
  order?: Maybe<Scalars['smallint']>;
  /** An object relationship */
  output: Outputs;
  output_id: Scalars['uuid'];
  /** An object relationship */
  tool: Tools;
  tool_id: Scalars['uuid'];
};

/** aggregated selection of "outputs_tools" */
export type Outputs_Tools_Aggregate = {
  __typename?: 'outputs_tools_aggregate';
  aggregate?: Maybe<Outputs_Tools_Aggregate_Fields>;
  nodes: Array<Outputs_Tools>;
};

export type Outputs_Tools_Aggregate_Bool_Exp = {
  count?: InputMaybe<Outputs_Tools_Aggregate_Bool_Exp_Count>;
};

export type Outputs_Tools_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Outputs_Tools_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "outputs_tools" */
export type Outputs_Tools_Aggregate_Fields = {
  __typename?: 'outputs_tools_aggregate_fields';
  avg?: Maybe<Outputs_Tools_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Outputs_Tools_Max_Fields>;
  min?: Maybe<Outputs_Tools_Min_Fields>;
  stddev?: Maybe<Outputs_Tools_Stddev_Fields>;
  stddev_pop?: Maybe<Outputs_Tools_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outputs_Tools_Stddev_Samp_Fields>;
  sum?: Maybe<Outputs_Tools_Sum_Fields>;
  var_pop?: Maybe<Outputs_Tools_Var_Pop_Fields>;
  var_samp?: Maybe<Outputs_Tools_Var_Samp_Fields>;
  variance?: Maybe<Outputs_Tools_Variance_Fields>;
};


/** aggregate fields of "outputs_tools" */
export type Outputs_Tools_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "outputs_tools" */
export type Outputs_Tools_Aggregate_Order_By = {
  avg?: InputMaybe<Outputs_Tools_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Outputs_Tools_Max_Order_By>;
  min?: InputMaybe<Outputs_Tools_Min_Order_By>;
  stddev?: InputMaybe<Outputs_Tools_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Outputs_Tools_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Outputs_Tools_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Outputs_Tools_Sum_Order_By>;
  var_pop?: InputMaybe<Outputs_Tools_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Outputs_Tools_Var_Samp_Order_By>;
  variance?: InputMaybe<Outputs_Tools_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outputs_tools" */
export type Outputs_Tools_Arr_Rel_Insert_Input = {
  data: Array<Outputs_Tools_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Outputs_Tools_On_Conflict>;
};

/** aggregate avg on columns */
export type Outputs_Tools_Avg_Fields = {
  __typename?: 'outputs_tools_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "outputs_tools" */
export type Outputs_Tools_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outputs_tools". All fields are combined with a logical 'AND'. */
export type Outputs_Tools_Bool_Exp = {
  _and?: InputMaybe<Array<Outputs_Tools_Bool_Exp>>;
  _not?: InputMaybe<Outputs_Tools_Bool_Exp>;
  _or?: InputMaybe<Array<Outputs_Tools_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Smallint_Comparison_Exp>;
  output?: InputMaybe<Outputs_Bool_Exp>;
  output_id?: InputMaybe<Uuid_Comparison_Exp>;
  tool?: InputMaybe<Tools_Bool_Exp>;
  tool_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "outputs_tools" */
export enum Outputs_Tools_Constraint {
  /** unique or primary key constraint on columns "output_id", "tool_id" */
  OutputsToolsOutputIdToolIdKey = 'outputs_tools_output_id_tool_id_key',
  /** unique or primary key constraint on columns "order", "output_id", "tool_id" */
  OutputsToolsOutputIdToolIdOrderKey = 'outputs_tools_output_id_tool_id_order_key',
  /** unique or primary key constraint on columns "id" */
  OutputsToolsPkey = 'outputs_tools_pkey'
}

/** input type for incrementing numeric columns in table "outputs_tools" */
export type Outputs_Tools_Inc_Input = {
  order?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "outputs_tools" */
export type Outputs_Tools_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  output?: InputMaybe<Outputs_Obj_Rel_Insert_Input>;
  output_id?: InputMaybe<Scalars['uuid']>;
  tool?: InputMaybe<Tools_Obj_Rel_Insert_Input>;
  tool_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Outputs_Tools_Max_Fields = {
  __typename?: 'outputs_tools_max_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  output_id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "outputs_tools" */
export type Outputs_Tools_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  output_id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Outputs_Tools_Min_Fields = {
  __typename?: 'outputs_tools_min_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['smallint']>;
  output_id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "outputs_tools" */
export type Outputs_Tools_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  output_id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "outputs_tools" */
export type Outputs_Tools_Mutation_Response = {
  __typename?: 'outputs_tools_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Outputs_Tools>;
};

/** on_conflict condition type for table "outputs_tools" */
export type Outputs_Tools_On_Conflict = {
  constraint: Outputs_Tools_Constraint;
  update_columns?: Array<Outputs_Tools_Update_Column>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};

/** Ordering options when selecting data from "outputs_tools". */
export type Outputs_Tools_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  output?: InputMaybe<Outputs_Order_By>;
  output_id?: InputMaybe<Order_By>;
  tool?: InputMaybe<Tools_Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: outputs_tools */
export type Outputs_Tools_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "outputs_tools" */
export enum Outputs_Tools_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  OutputId = 'output_id',
  /** column name */
  ToolId = 'tool_id'
}

/** input type for updating data in table "outputs_tools" */
export type Outputs_Tools_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  output_id?: InputMaybe<Scalars['uuid']>;
  tool_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Outputs_Tools_Stddev_Fields = {
  __typename?: 'outputs_tools_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "outputs_tools" */
export type Outputs_Tools_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Outputs_Tools_Stddev_Pop_Fields = {
  __typename?: 'outputs_tools_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "outputs_tools" */
export type Outputs_Tools_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Outputs_Tools_Stddev_Samp_Fields = {
  __typename?: 'outputs_tools_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "outputs_tools" */
export type Outputs_Tools_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "outputs_tools" */
export type Outputs_Tools_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outputs_Tools_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outputs_Tools_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['smallint']>;
  output_id?: InputMaybe<Scalars['uuid']>;
  tool_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Outputs_Tools_Sum_Fields = {
  __typename?: 'outputs_tools_sum_fields';
  order?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "outputs_tools" */
export type Outputs_Tools_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "outputs_tools" */
export enum Outputs_Tools_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  OutputId = 'output_id',
  /** column name */
  ToolId = 'tool_id'
}

export type Outputs_Tools_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Outputs_Tools_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Outputs_Tools_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outputs_Tools_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outputs_Tools_Var_Pop_Fields = {
  __typename?: 'outputs_tools_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "outputs_tools" */
export type Outputs_Tools_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Outputs_Tools_Var_Samp_Fields = {
  __typename?: 'outputs_tools_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "outputs_tools" */
export type Outputs_Tools_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Outputs_Tools_Variance_Fields = {
  __typename?: 'outputs_tools_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "outputs_tools" */
export type Outputs_Tools_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "outputs" */
export enum Outputs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  MultimediaContentId = 'multimedia_content_id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  ParentSectionId = 'parent_section_id',
  /** column name */
  Slug = 'slug'
}

export type Outputs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Outputs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Outputs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outputs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outputs_Var_Pop_Fields = {
  __typename?: 'outputs_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "outputs" */
export type Outputs_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Outputs_Var_Samp_Fields = {
  __typename?: 'outputs_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "outputs" */
export type Outputs_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Outputs_Variance_Fields = {
  __typename?: 'outputs_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "outputs" */
export type Outputs_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "available_mentoring_dates" */
  available_mentoring_dates: Array<Available_Mentoring_Dates>;
  /** fetch aggregated fields from the table: "available_mentoring_dates" */
  available_mentoring_dates_aggregate: Available_Mentoring_Dates_Aggregate;
  /** fetch data from the table: "available_mentoring_dates" using primary key columns */
  available_mentoring_dates_by_pk?: Maybe<Available_Mentoring_Dates>;
  /** Checks invite status */
  checkInviteStatus?: Maybe<CheckInviteStatusOutput>;
  /** fetch data from the table: "contents" */
  contents: Array<Contents>;
  /** fetch aggregated fields from the table: "contents" */
  contents_aggregate: Contents_Aggregate;
  /** fetch data from the table: "contents" using primary key columns */
  contents_by_pk?: Maybe<Contents>;
  /** An array relationship */
  contents_sections: Array<Contents_Sections>;
  /** An aggregate relationship */
  contents_sections_aggregate: Contents_Sections_Aggregate;
  /** fetch data from the table: "contents_sections" using primary key columns */
  contents_sections_by_pk?: Maybe<Contents_Sections>;
  createUser?: Maybe<CreateUser>;
  /** createUserPreregistered */
  createUserPreregistered?: Maybe<CreateUserPreregistered>;
  /** fetch data from the table: "diagnostics" */
  diagnostics: Array<Diagnostics>;
  /** fetch aggregated fields from the table: "diagnostics" */
  diagnostics_aggregate: Diagnostics_Aggregate;
  /** fetch data from the table: "diagnostics" using primary key columns */
  diagnostics_by_pk?: Maybe<Diagnostics>;
  /** fetch data from the table: "diagnostics_comments" */
  diagnostics_comments: Array<Diagnostics_Comments>;
  /** fetch aggregated fields from the table: "diagnostics_comments" */
  diagnostics_comments_aggregate: Diagnostics_Comments_Aggregate;
  /** fetch data from the table: "diagnostics_comments" using primary key columns */
  diagnostics_comments_by_pk?: Maybe<Diagnostics_Comments>;
  /** fetch data from the table: "diagnostics_groups" */
  diagnostics_groups: Array<Diagnostics_Groups>;
  /** fetch aggregated fields from the table: "diagnostics_groups" */
  diagnostics_groups_aggregate: Diagnostics_Groups_Aggregate;
  /** fetch data from the table: "diagnostics_groups" using primary key columns */
  diagnostics_groups_by_pk?: Maybe<Diagnostics_Groups>;
  /** fetch data from the table: "diagnostics_questions" */
  diagnostics_questions: Array<Diagnostics_Questions>;
  /** fetch aggregated fields from the table: "diagnostics_questions" */
  diagnostics_questions_aggregate: Diagnostics_Questions_Aggregate;
  /** fetch data from the table: "diagnostics_questions_answers" */
  diagnostics_questions_answers: Array<Diagnostics_Questions_Answers>;
  /** fetch aggregated fields from the table: "diagnostics_questions_answers" */
  diagnostics_questions_answers_aggregate: Diagnostics_Questions_Answers_Aggregate;
  /** fetch data from the table: "diagnostics_questions_answers" using primary key columns */
  diagnostics_questions_answers_by_pk?: Maybe<Diagnostics_Questions_Answers>;
  /** fetch data from the table: "diagnostics_questions" using primary key columns */
  diagnostics_questions_by_pk?: Maybe<Diagnostics_Questions>;
  /** fetch data from the table: "diagnostics_sections" */
  diagnostics_sections: Array<Diagnostics_Sections>;
  /** fetch aggregated fields from the table: "diagnostics_sections" */
  diagnostics_sections_aggregate: Diagnostics_Sections_Aggregate;
  /** fetch data from the table: "diagnostics_sections" using primary key columns */
  diagnostics_sections_by_pk?: Maybe<Diagnostics_Sections>;
  /** fetch data from the table: "diagnostics_subsections" */
  diagnostics_subsections: Array<Diagnostics_Subsections>;
  /** fetch aggregated fields from the table: "diagnostics_subsections" */
  diagnostics_subsections_aggregate: Diagnostics_Subsections_Aggregate;
  /** fetch data from the table: "diagnostics_subsections" using primary key columns */
  diagnostics_subsections_by_pk?: Maybe<Diagnostics_Subsections>;
  /** fetch data from the table: "diagnostics_tabs" */
  diagnostics_tabs: Array<Diagnostics_Tabs>;
  /** fetch aggregated fields from the table: "diagnostics_tabs" */
  diagnostics_tabs_aggregate: Diagnostics_Tabs_Aggregate;
  /** fetch data from the table: "diagnostics_tabs" using primary key columns */
  diagnostics_tabs_by_pk?: Maybe<Diagnostics_Tabs>;
  /** fetch data from the table: "diagnostics_tabs_sections" */
  diagnostics_tabs_sections: Array<Diagnostics_Tabs_Sections>;
  /** fetch aggregated fields from the table: "diagnostics_tabs_sections" */
  diagnostics_tabs_sections_aggregate: Diagnostics_Tabs_Sections_Aggregate;
  /** fetch data from the table: "diagnostics_tabs_sections" using primary key columns */
  diagnostics_tabs_sections_by_pk?: Maybe<Diagnostics_Tabs_Sections>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  getStorageSASUri: Scalars['String'];
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  /** fetch data from the table: "groups_contents" */
  groups_contents: Array<Groups_Contents>;
  /** fetch aggregated fields from the table: "groups_contents" */
  groups_contents_aggregate: Groups_Contents_Aggregate;
  /** fetch data from the table: "groups_contents" using primary key columns */
  groups_contents_by_pk?: Maybe<Groups_Contents>;
  /** fetch data from the table: "groups_flags" */
  groups_flags: Array<Groups_Flags>;
  /** fetch aggregated fields from the table: "groups_flags" */
  groups_flags_aggregate: Groups_Flags_Aggregate;
  /** fetch data from the table: "groups_flags" using primary key columns */
  groups_flags_by_pk?: Maybe<Groups_Flags>;
  /** fetch data from the table: "groups_group_flags" */
  groups_group_flags: Array<Groups_Group_Flags>;
  /** fetch aggregated fields from the table: "groups_group_flags" */
  groups_group_flags_aggregate: Groups_Group_Flags_Aggregate;
  /** fetch data from the table: "groups_group_flags" using primary key columns */
  groups_group_flags_by_pk?: Maybe<Groups_Group_Flags>;
  /** fetch data from the table: "groups_names" */
  groups_names: Array<Groups_Names>;
  /** fetch aggregated fields from the table: "groups_names" */
  groups_names_aggregate: Groups_Names_Aggregate;
  /** fetch data from the table: "groups_names" using primary key columns */
  groups_names_by_pk?: Maybe<Groups_Names>;
  /** fetch data from the table: "groups_teams" */
  groups_teams: Array<Groups_Teams>;
  /** fetch aggregated fields from the table: "groups_teams" */
  groups_teams_aggregate: Groups_Teams_Aggregate;
  /** fetch data from the table: "groups_teams" using primary key columns */
  groups_teams_by_pk?: Maybe<Groups_Teams>;
  /** fetch data from the table: "icons_types" */
  icons_types: Array<Icons_Types>;
  /** fetch aggregated fields from the table: "icons_types" */
  icons_types_aggregate: Icons_Types_Aggregate;
  /** fetch data from the table: "icons_types" using primary key columns */
  icons_types_by_pk?: Maybe<Icons_Types>;
  /** fetch data from the table: "invite_states" */
  invite_states: Array<Invite_States>;
  /** fetch aggregated fields from the table: "invite_states" */
  invite_states_aggregate: Invite_States_Aggregate;
  /** fetch data from the table: "invite_states" using primary key columns */
  invite_states_by_pk?: Maybe<Invite_States>;
  /** fetch data from the table: "kanban_columns" */
  kanban_columns: Array<Kanban_Columns>;
  /** fetch aggregated fields from the table: "kanban_columns" */
  kanban_columns_aggregate: Kanban_Columns_Aggregate;
  /** fetch data from the table: "kanban_columns" using primary key columns */
  kanban_columns_by_pk?: Maybe<Kanban_Columns>;
  /** fetch data from the table: "kanbans" */
  kanbans: Array<Kanbans>;
  /** fetch aggregated fields from the table: "kanbans" */
  kanbans_aggregate: Kanbans_Aggregate;
  /** fetch data from the table: "kanbans" using primary key columns */
  kanbans_by_pk?: Maybe<Kanbans>;
  /** fetch data from the table: "kanbans_cards" */
  kanbans_cards: Array<Kanbans_Cards>;
  /** fetch aggregated fields from the table: "kanbans_cards" */
  kanbans_cards_aggregate: Kanbans_Cards_Aggregate;
  /** fetch data from the table: "kanbans_cards_assignations" */
  kanbans_cards_assignations: Array<Kanbans_Cards_Assignations>;
  /** fetch aggregated fields from the table: "kanbans_cards_assignations" */
  kanbans_cards_assignations_aggregate: Kanbans_Cards_Assignations_Aggregate;
  /** fetch data from the table: "kanbans_cards_assignations" using primary key columns */
  kanbans_cards_assignations_by_pk?: Maybe<Kanbans_Cards_Assignations>;
  /** fetch data from the table: "kanbans_cards_attachments" */
  kanbans_cards_attachments: Array<Kanbans_Cards_Attachments>;
  /** fetch aggregated fields from the table: "kanbans_cards_attachments" */
  kanbans_cards_attachments_aggregate: Kanbans_Cards_Attachments_Aggregate;
  /** fetch data from the table: "kanbans_cards_attachments" using primary key columns */
  kanbans_cards_attachments_by_pk?: Maybe<Kanbans_Cards_Attachments>;
  /** fetch data from the table: "kanbans_cards" using primary key columns */
  kanbans_cards_by_pk?: Maybe<Kanbans_Cards>;
  /** fetch data from the table: "kanbans_cards_comments" */
  kanbans_cards_comments: Array<Kanbans_Cards_Comments>;
  /** fetch aggregated fields from the table: "kanbans_cards_comments" */
  kanbans_cards_comments_aggregate: Kanbans_Cards_Comments_Aggregate;
  /** fetch data from the table: "kanbans_cards_comments" using primary key columns */
  kanbans_cards_comments_by_pk?: Maybe<Kanbans_Cards_Comments>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table: "locations_names" */
  locations_names: Array<Locations_Names>;
  /** fetch aggregated fields from the table: "locations_names" */
  locations_names_aggregate: Locations_Names_Aggregate;
  /** fetch data from the table: "locations_names" using primary key columns */
  locations_names_by_pk?: Maybe<Locations_Names>;
  /** fetch data from the table: "mentor_additional_data" */
  mentor_additional_data: Array<Mentor_Additional_Data>;
  /** fetch aggregated fields from the table: "mentor_additional_data" */
  mentor_additional_data_aggregate: Mentor_Additional_Data_Aggregate;
  /** fetch data from the table: "mentor_additional_data" using primary key columns */
  mentor_additional_data_by_pk?: Maybe<Mentor_Additional_Data>;
  /** fetch data from the table: "mentor_tags" */
  mentor_tags: Array<Mentor_Tags>;
  /** fetch aggregated fields from the table: "mentor_tags" */
  mentor_tags_aggregate: Mentor_Tags_Aggregate;
  /** fetch data from the table: "mentor_tags" using primary key columns */
  mentor_tags_by_pk?: Maybe<Mentor_Tags>;
  /** fetch data from the table: "mentoring_records" */
  mentoring_records: Array<Mentoring_Records>;
  /** fetch aggregated fields from the table: "mentoring_records" */
  mentoring_records_aggregate: Mentoring_Records_Aggregate;
  /** An array relationship */
  mentoring_records_attachments: Array<Mentoring_Records_Attachments>;
  /** An aggregate relationship */
  mentoring_records_attachments_aggregate: Mentoring_Records_Attachments_Aggregate;
  /** fetch data from the table: "mentoring_records_attachments" using primary key columns */
  mentoring_records_attachments_by_pk?: Maybe<Mentoring_Records_Attachments>;
  /** fetch data from the table: "mentoring_records" using primary key columns */
  mentoring_records_by_pk?: Maybe<Mentoring_Records>;
  /** fetch data from the table: "mentoring_records_members" */
  mentoring_records_members: Array<Mentoring_Records_Members>;
  /** fetch aggregated fields from the table: "mentoring_records_members" */
  mentoring_records_members_aggregate: Mentoring_Records_Members_Aggregate;
  /** fetch data from the table: "mentoring_records_members" using primary key columns */
  mentoring_records_members_by_pk?: Maybe<Mentoring_Records_Members>;
  /** fetch data from the table: "mentoring_reschedules" */
  mentoring_reschedules: Array<Mentoring_Reschedules>;
  /** fetch aggregated fields from the table: "mentoring_reschedules" */
  mentoring_reschedules_aggregate: Mentoring_Reschedules_Aggregate;
  /** fetch data from the table: "mentoring_reschedules" using primary key columns */
  mentoring_reschedules_by_pk?: Maybe<Mentoring_Reschedules>;
  /** fetch data from the table: "mentoring_scheduling" */
  mentoring_scheduling: Array<Mentoring_Scheduling>;
  /** fetch aggregated fields from the table: "mentoring_scheduling" */
  mentoring_scheduling_aggregate: Mentoring_Scheduling_Aggregate;
  /** fetch data from the table: "mentoring_scheduling" using primary key columns */
  mentoring_scheduling_by_pk?: Maybe<Mentoring_Scheduling>;
  /** fetch data from the table: "mentoring_scheduling_meeting" */
  mentoring_scheduling_meeting: Array<Mentoring_Scheduling_Meeting>;
  /** fetch aggregated fields from the table: "mentoring_scheduling_meeting" */
  mentoring_scheduling_meeting_aggregate: Mentoring_Scheduling_Meeting_Aggregate;
  /** fetch data from the table: "mentoring_scheduling_meeting" using primary key columns */
  mentoring_scheduling_meeting_by_pk?: Maybe<Mentoring_Scheduling_Meeting>;
  /** An array relationship */
  mentoring_scheduling_members: Array<Mentoring_Scheduling_Members>;
  /** An aggregate relationship */
  mentoring_scheduling_members_aggregate: Mentoring_Scheduling_Members_Aggregate;
  /** fetch data from the table: "mentoring_scheduling_members" using primary key columns */
  mentoring_scheduling_members_by_pk?: Maybe<Mentoring_Scheduling_Members>;
  /** fetch data from the table: "mentors" */
  mentors: Array<Mentors>;
  /** fetch aggregated fields from the table: "mentors" */
  mentors_aggregate: Mentors_Aggregate;
  /** fetch data from the table: "mentors_blablabla" */
  mentors_blablabla: Array<Mentors_Blablabla>;
  /** fetch aggregated fields from the table: "mentors_blablabla" */
  mentors_blablabla_aggregate: Mentors_Blablabla_Aggregate;
  /** fetch data from the table: "mentors_blablabla" using primary key columns */
  mentors_blablabla_by_pk?: Maybe<Mentors_Blablabla>;
  /** fetch data from the table: "mentors" using primary key columns */
  mentors_by_pk?: Maybe<Mentors>;
  /** fetch data from the table: "mentors_mentor_tags" */
  mentors_mentor_tags: Array<Mentors_Mentor_Tags>;
  /** fetch aggregated fields from the table: "mentors_mentor_tags" */
  mentors_mentor_tags_aggregate: Mentors_Mentor_Tags_Aggregate;
  /** fetch data from the table: "mentors_mentor_tags" using primary key columns */
  mentors_mentor_tags_by_pk?: Maybe<Mentors_Mentor_Tags>;
  /** fetch data from the table: "metodologies" */
  metodologies: Array<Metodologies>;
  /** fetch aggregated fields from the table: "metodologies" */
  metodologies_aggregate: Metodologies_Aggregate;
  /** fetch data from the table: "metodologies" using primary key columns */
  metodologies_by_pk?: Maybe<Metodologies>;
  /** fetch data from the table: "multimedia_contents" */
  multimedia_contents: Array<Multimedia_Contents>;
  /** fetch aggregated fields from the table: "multimedia_contents" */
  multimedia_contents_aggregate: Multimedia_Contents_Aggregate;
  /** fetch data from the table: "multimedia_contents" using primary key columns */
  multimedia_contents_by_pk?: Maybe<Multimedia_Contents>;
  /** fetch data from the table: "multimedia_contents_links" */
  multimedia_contents_links: Array<Multimedia_Contents_Links>;
  /** fetch aggregated fields from the table: "multimedia_contents_links" */
  multimedia_contents_links_aggregate: Multimedia_Contents_Links_Aggregate;
  /** fetch data from the table: "multimedia_contents_links" using primary key columns */
  multimedia_contents_links_by_pk?: Maybe<Multimedia_Contents_Links>;
  /** An array relationship */
  outputs: Array<Outputs>;
  /** An aggregate relationship */
  outputs_aggregate: Outputs_Aggregate;
  /** fetch data from the table: "outputs" using primary key columns */
  outputs_by_pk?: Maybe<Outputs>;
  /** fetch data from the table: "outputs_icons" */
  outputs_icons: Array<Outputs_Icons>;
  /** fetch aggregated fields from the table: "outputs_icons" */
  outputs_icons_aggregate: Outputs_Icons_Aggregate;
  /** fetch data from the table: "outputs_icons" using primary key columns */
  outputs_icons_by_pk?: Maybe<Outputs_Icons>;
  /** fetch data from the table: "outputs_tools" */
  outputs_tools: Array<Outputs_Tools>;
  /** fetch aggregated fields from the table: "outputs_tools" */
  outputs_tools_aggregate: Outputs_Tools_Aggregate;
  /** fetch data from the table: "outputs_tools" using primary key columns */
  outputs_tools_by_pk?: Maybe<Outputs_Tools>;
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** An aggregate relationship */
  questionnaires_aggregate: Questionnaires_Aggregate;
  /** fetch data from the table: "questionnaires" using primary key columns */
  questionnaires_by_pk?: Maybe<Questionnaires>;
  /** fetch data from the table: "questionnaires_teams_states" */
  questionnaires_teams_states: Array<Questionnaires_Teams_States>;
  /** fetch aggregated fields from the table: "questionnaires_teams_states" */
  questionnaires_teams_states_aggregate: Questionnaires_Teams_States_Aggregate;
  /** fetch data from the table: "questionnaires_teams_states" using primary key columns */
  questionnaires_teams_states_by_pk?: Maybe<Questionnaires_Teams_States>;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** An array relationship */
  questions_answers: Array<Questions_Answers>;
  /** An aggregate relationship */
  questions_answers_aggregate: Questions_Answers_Aggregate;
  /** fetch data from the table: "questions_answers" using primary key columns */
  questions_answers_by_pk?: Maybe<Questions_Answers>;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** fetch data from the table: "questions_options" */
  questions_options: Array<Questions_Options>;
  /** fetch aggregated fields from the table: "questions_options" */
  questions_options_aggregate: Questions_Options_Aggregate;
  /** fetch data from the table: "questions_options" using primary key columns */
  questions_options_by_pk?: Maybe<Questions_Options>;
  /** fetch data from the table: "questions_types" */
  questions_types: Array<Questions_Types>;
  /** fetch aggregated fields from the table: "questions_types" */
  questions_types_aggregate: Questions_Types_Aggregate;
  /** fetch data from the table: "questions_types" using primary key columns */
  questions_types_by_pk?: Maybe<Questions_Types>;
  /** An array relationship */
  repositories: Array<Repositories>;
  /** An aggregate relationship */
  repositories_aggregate: Repositories_Aggregate;
  /** fetch data from the table: "repositories" using primary key columns */
  repositories_by_pk?: Maybe<Repositories>;
  /** fetch data from the table: "requested_mentorings" */
  requested_mentorings: Array<Requested_Mentorings>;
  /** fetch aggregated fields from the table: "requested_mentorings" */
  requested_mentorings_aggregate: Requested_Mentorings_Aggregate;
  /** fetch data from the table: "requested_mentorings" using primary key columns */
  requested_mentorings_by_pk?: Maybe<Requested_Mentorings>;
  /** fetch data from the table: "requested_mentorings_status" */
  requested_mentorings_status: Array<Requested_Mentorings_Status>;
  /** fetch aggregated fields from the table: "requested_mentorings_status" */
  requested_mentorings_status_aggregate: Requested_Mentorings_Status_Aggregate;
  /** fetch data from the table: "requested_mentorings_status" using primary key columns */
  requested_mentorings_status_by_pk?: Maybe<Requested_Mentorings_Status>;
  /** An array relationship */
  requests_mentor_tags: Array<Requests_Mentor_Tags>;
  /** An aggregate relationship */
  requests_mentor_tags_aggregate: Requests_Mentor_Tags_Aggregate;
  /** fetch data from the table: "requests_mentor_tags" using primary key columns */
  requests_mentor_tags_by_pk?: Maybe<Requests_Mentor_Tags>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table: "sections" */
  sections: Array<Sections>;
  /** fetch aggregated fields from the table: "sections" */
  sections_aggregate: Sections_Aggregate;
  /** fetch data from the table: "sections" using primary key columns */
  sections_by_pk?: Maybe<Sections>;
  /** fetch data from the table: "sections_icons" */
  sections_icons: Array<Sections_Icons>;
  /** fetch aggregated fields from the table: "sections_icons" */
  sections_icons_aggregate: Sections_Icons_Aggregate;
  /** fetch data from the table: "sections_icons" using primary key columns */
  sections_icons_by_pk?: Maybe<Sections_Icons>;
  /** fetch data from the table: "social_medias" */
  social_medias: Array<Social_Medias>;
  /** fetch aggregated fields from the table: "social_medias" */
  social_medias_aggregate: Social_Medias_Aggregate;
  /** fetch data from the table: "social_medias" using primary key columns */
  social_medias_by_pk?: Maybe<Social_Medias>;
  /** fetch data from the table: "tag_conversion" */
  tag_conversion: Array<Tag_Conversion>;
  /** fetch aggregated fields from the table: "tag_conversion" */
  tag_conversion_aggregate: Tag_Conversion_Aggregate;
  /** fetch data from the table: "tag_conversion" using primary key columns */
  tag_conversion_by_pk?: Maybe<Tag_Conversion>;
  /** fetch data from the table: "tags" */
  tags: Array<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table: "team_invites" */
  team_invites: Array<Team_Invites>;
  /** fetch aggregated fields from the table: "team_invites" */
  team_invites_aggregate: Team_Invites_Aggregate;
  /** fetch data from the table: "team_invites" using primary key columns */
  team_invites_by_pk?: Maybe<Team_Invites>;
  /** An array relationship */
  teams: Array<Teams>;
  /** An aggregate relationship */
  teams_aggregate: Teams_Aggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teams_by_pk?: Maybe<Teams>;
  /** fetch data from the table: "teams_names" */
  teams_names: Array<Teams_Names>;
  /** fetch aggregated fields from the table: "teams_names" */
  teams_names_aggregate: Teams_Names_Aggregate;
  /** fetch data from the table: "teams_names" using primary key columns */
  teams_names_by_pk?: Maybe<Teams_Names>;
  /** fetch data from the table: "teams_scores" */
  teams_scores: Array<Teams_Scores>;
  /** fetch aggregated fields from the table: "teams_scores" */
  teams_scores_aggregate: Teams_Scores_Aggregate;
  /** fetch data from the table: "teams_tags" */
  teams_tags: Array<Teams_Tags>;
  /** fetch aggregated fields from the table: "teams_tags" */
  teams_tags_aggregate: Teams_Tags_Aggregate;
  /** fetch data from the table: "teams_tags" using primary key columns */
  teams_tags_by_pk?: Maybe<Teams_Tags>;
  /** fetch data from the table: "teams_users" */
  teams_users: Array<Teams_Users>;
  /** fetch aggregated fields from the table: "teams_users" */
  teams_users_aggregate: Teams_Users_Aggregate;
  /** fetch data from the table: "teams_users" using primary key columns */
  teams_users_by_pk?: Maybe<Teams_Users>;
  /** fetch data from the table: "tools" */
  tools: Array<Tools>;
  /** fetch aggregated fields from the table: "tools" */
  tools_aggregate: Tools_Aggregate;
  /** fetch data from the table: "tools" using primary key columns */
  tools_by_pk?: Maybe<Tools>;
  /** fetch data from the table: "tools_icons" */
  tools_icons: Array<Tools_Icons>;
  /** fetch aggregated fields from the table: "tools_icons" */
  tools_icons_aggregate: Tools_Icons_Aggregate;
  /** fetch data from the table: "tools_icons" using primary key columns */
  tools_icons_by_pk?: Maybe<Tools_Icons>;
  /** fetch data from the table: "user_info" */
  user_info: Array<User_Info>;
  /** fetch aggregated fields from the table: "user_info" */
  user_info_aggregate: User_Info_Aggregate;
  /** fetch data from the table: "user_info" using primary key columns */
  user_info_by_pk?: Maybe<User_Info>;
  /** fetch data from the table: "user_invites" */
  user_invites: Array<User_Invites>;
  /** fetch aggregated fields from the table: "user_invites" */
  user_invites_aggregate: User_Invites_Aggregate;
  /** fetch data from the table: "user_invites" using primary key columns */
  user_invites_by_pk?: Maybe<User_Invites>;
  /** An array relationship */
  users: Array<Users>;
  /** fetch data from the table: "users_actions" */
  users_actions: Array<Users_Actions>;
  /** fetch aggregated fields from the table: "users_actions" */
  users_actions_aggregate: Users_Actions_Aggregate;
  /** fetch data from the table: "users_actions" using primary key columns */
  users_actions_by_pk?: Maybe<Users_Actions>;
  /** fetch data from the table: "users_actions_types" */
  users_actions_types: Array<Users_Actions_Types>;
  /** fetch aggregated fields from the table: "users_actions_types" */
  users_actions_types_aggregate: Users_Actions_Types_Aggregate;
  /** fetch data from the table: "users_actions_types" using primary key columns */
  users_actions_types_by_pk?: Maybe<Users_Actions_Types>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_documents" */
  users_documents: Array<Users_Documents>;
  /** fetch aggregated fields from the table: "users_documents" */
  users_documents_aggregate: Users_Documents_Aggregate;
  /** fetch data from the table: "users_documents" using primary key columns */
  users_documents_by_pk?: Maybe<Users_Documents>;
  /** fetch data from the table: "users_documents_types" */
  users_documents_types: Array<Users_Documents_Types>;
  /** fetch aggregated fields from the table: "users_documents_types" */
  users_documents_types_aggregate: Users_Documents_Types_Aggregate;
  /** fetch data from the table: "users_documents_types" using primary key columns */
  users_documents_types_by_pk?: Maybe<Users_Documents_Types>;
  /** fetch data from the table: "users_names" */
  users_names: Array<Users_Names>;
  /** fetch aggregated fields from the table: "users_names" */
  users_names_aggregate: Users_Names_Aggregate;
  /** fetch data from the table: "users_names" using primary key columns */
  users_names_by_pk?: Maybe<Users_Names>;
  /** An array relationship */
  video_repositories: Array<Video_Repositories>;
  /** An aggregate relationship */
  video_repositories_aggregate: Video_Repositories_Aggregate;
  /** fetch data from the table: "video_repositories" using primary key columns */
  video_repositories_by_pk?: Maybe<Video_Repositories>;
  /** An array relationship */
  videos: Array<Videos>;
  /** An aggregate relationship */
  videos_aggregate: Videos_Aggregate;
  /** fetch data from the table: "videos" using primary key columns */
  videos_by_pk?: Maybe<Videos>;
  /** execute function "videos_by_tags" which returns "videos" */
  videos_by_tags: Array<Videos>;
  /** execute function "videos_by_tags" and query aggregates on result of table type "videos" */
  videos_by_tags_aggregate: Videos_Aggregate;
  /** fetch data from the table: "videos_youtube_tags" */
  videos_youtube_tags: Array<Videos_Youtube_Tags>;
  /** fetch aggregated fields from the table: "videos_youtube_tags" */
  videos_youtube_tags_aggregate: Videos_Youtube_Tags_Aggregate;
  /** fetch data from the table: "videos_youtube_tags" using primary key columns */
  videos_youtube_tags_by_pk?: Maybe<Videos_Youtube_Tags>;
  /** fetch data from the table: "youtube_tags" */
  youtube_tags: Array<Youtube_Tags>;
  /** fetch aggregated fields from the table: "youtube_tags" */
  youtube_tags_aggregate: Youtube_Tags_Aggregate;
  /** fetch data from the table: "youtube_tags" using primary key columns */
  youtube_tags_by_pk?: Maybe<Youtube_Tags>;
};


export type Query_RootAvailable_Mentoring_DatesArgs = {
  distinct_on?: InputMaybe<Array<Available_Mentoring_Dates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Available_Mentoring_Dates_Order_By>>;
  where?: InputMaybe<Available_Mentoring_Dates_Bool_Exp>;
};


export type Query_RootAvailable_Mentoring_Dates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Available_Mentoring_Dates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Available_Mentoring_Dates_Order_By>>;
  where?: InputMaybe<Available_Mentoring_Dates_Bool_Exp>;
};


export type Query_RootAvailable_Mentoring_Dates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCheckInviteStatusArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};


export type Query_RootContentsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Query_RootContents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Query_RootContents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContents_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


export type Query_RootContents_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


export type Query_RootContents_Sections_By_PkArgs = {
  content_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};


export type Query_RootCreateUserArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCreateUserPreregisteredArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiagnosticsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


export type Query_RootDiagnostics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


export type Query_RootDiagnostics_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiagnostics_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Comments_Order_By>>;
  where?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
};


export type Query_RootDiagnostics_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Comments_Order_By>>;
  where?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
};


export type Query_RootDiagnostics_Comments_By_PkArgs = {
  diagnostic_tab_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};


export type Query_RootDiagnostics_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Groups_Order_By>>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};


export type Query_RootDiagnostics_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Groups_Order_By>>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};


export type Query_RootDiagnostics_Groups_By_PkArgs = {
  diagnostic_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};


export type Query_RootDiagnostics_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
};


export type Query_RootDiagnostics_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
};


export type Query_RootDiagnostics_Questions_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Answers_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};


export type Query_RootDiagnostics_Questions_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Answers_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};


export type Query_RootDiagnostics_Questions_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiagnostics_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiagnostics_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
};


export type Query_RootDiagnostics_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
};


export type Query_RootDiagnostics_Sections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiagnostics_SubsectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Subsections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Subsections_Order_By>>;
  where?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
};


export type Query_RootDiagnostics_Subsections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Subsections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Subsections_Order_By>>;
  where?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
};


export type Query_RootDiagnostics_Subsections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiagnostics_TabsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};


export type Query_RootDiagnostics_Tabs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};


export type Query_RootDiagnostics_Tabs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDiagnostics_Tabs_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};


export type Query_RootDiagnostics_Tabs_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};


export type Query_RootDiagnostics_Tabs_Sections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetStorageSasUriArgs = {
  container: Scalars['String'];
  filename: Scalars['String'];
};


export type Query_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Query_RootGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Query_RootGroups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGroups_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


export type Query_RootGroups_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


export type Query_RootGroups_Contents_By_PkArgs = {
  content_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};


export type Query_RootGroups_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Flags_Order_By>>;
  where?: InputMaybe<Groups_Flags_Bool_Exp>;
};


export type Query_RootGroups_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Flags_Order_By>>;
  where?: InputMaybe<Groups_Flags_Bool_Exp>;
};


export type Query_RootGroups_Flags_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootGroups_Group_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Group_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Group_Flags_Order_By>>;
  where?: InputMaybe<Groups_Group_Flags_Bool_Exp>;
};


export type Query_RootGroups_Group_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Group_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Group_Flags_Order_By>>;
  where?: InputMaybe<Groups_Group_Flags_Bool_Exp>;
};


export type Query_RootGroups_Group_Flags_By_PkArgs = {
  flag: Groups_Flags_Enum;
  group_id: Scalars['uuid'];
};


export type Query_RootGroups_NamesArgs = {
  distinct_on?: InputMaybe<Array<Groups_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Names_Order_By>>;
  where?: InputMaybe<Groups_Names_Bool_Exp>;
};


export type Query_RootGroups_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Names_Order_By>>;
  where?: InputMaybe<Groups_Names_Bool_Exp>;
};


export type Query_RootGroups_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGroups_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};


export type Query_RootGroups_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};


export type Query_RootGroups_Teams_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIcons_TypesArgs = {
  distinct_on?: InputMaybe<Array<Icons_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icons_Types_Order_By>>;
  where?: InputMaybe<Icons_Types_Bool_Exp>;
};


export type Query_RootIcons_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icons_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icons_Types_Order_By>>;
  where?: InputMaybe<Icons_Types_Bool_Exp>;
};


export type Query_RootIcons_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootInvite_StatesArgs = {
  distinct_on?: InputMaybe<Array<Invite_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invite_States_Order_By>>;
  where?: InputMaybe<Invite_States_Bool_Exp>;
};


export type Query_RootInvite_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invite_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invite_States_Order_By>>;
  where?: InputMaybe<Invite_States_Bool_Exp>;
};


export type Query_RootInvite_States_By_PkArgs = {
  state: Scalars['String'];
};


export type Query_RootKanban_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Kanban_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanban_Columns_Order_By>>;
  where?: InputMaybe<Kanban_Columns_Bool_Exp>;
};


export type Query_RootKanban_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanban_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanban_Columns_Order_By>>;
  where?: InputMaybe<Kanban_Columns_Bool_Exp>;
};


export type Query_RootKanban_Columns_By_PkArgs = {
  column: Scalars['String'];
};


export type Query_RootKanbansArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Order_By>>;
  where?: InputMaybe<Kanbans_Bool_Exp>;
};


export type Query_RootKanbans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Order_By>>;
  where?: InputMaybe<Kanbans_Bool_Exp>;
};


export type Query_RootKanbans_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootKanbans_CardsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Bool_Exp>;
};


export type Query_RootKanbans_Cards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Bool_Exp>;
};


export type Query_RootKanbans_Cards_AssignationsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Assignations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Assignations_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
};


export type Query_RootKanbans_Cards_Assignations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Assignations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Assignations_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
};


export type Query_RootKanbans_Cards_Assignations_By_PkArgs = {
  card_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


export type Query_RootKanbans_Cards_AttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Attachments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
};


export type Query_RootKanbans_Cards_Attachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Attachments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
};


export type Query_RootKanbans_Cards_Attachments_By_PkArgs = {
  card_id: Scalars['uuid'];
  file_id: Scalars['uuid'];
};


export type Query_RootKanbans_Cards_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootKanbans_Cards_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Comments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
};


export type Query_RootKanbans_Cards_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Comments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
};


export type Query_RootKanbans_Cards_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocationsArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};


export type Query_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLocations_NamesArgs = {
  distinct_on?: InputMaybe<Array<Locations_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Names_Order_By>>;
  where?: InputMaybe<Locations_Names_Bool_Exp>;
};


export type Query_RootLocations_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Names_Order_By>>;
  where?: InputMaybe<Locations_Names_Bool_Exp>;
};


export type Query_RootLocations_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMentor_Additional_DataArgs = {
  distinct_on?: InputMaybe<Array<Mentor_Additional_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentor_Additional_Data_Order_By>>;
  where?: InputMaybe<Mentor_Additional_Data_Bool_Exp>;
};


export type Query_RootMentor_Additional_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentor_Additional_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentor_Additional_Data_Order_By>>;
  where?: InputMaybe<Mentor_Additional_Data_Bool_Exp>;
};


export type Query_RootMentor_Additional_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMentor_TagsArgs = {
  distinct_on?: InputMaybe<Array<Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentor_Tags_Order_By>>;
  where?: InputMaybe<Mentor_Tags_Bool_Exp>;
};


export type Query_RootMentor_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentor_Tags_Order_By>>;
  where?: InputMaybe<Mentor_Tags_Bool_Exp>;
};


export type Query_RootMentor_Tags_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootMentoring_RecordsArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Bool_Exp>;
};


export type Query_RootMentoring_Records_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Bool_Exp>;
};


export type Query_RootMentoring_Records_AttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Attachments_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Attachments_Bool_Exp>;
};


export type Query_RootMentoring_Records_Attachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Attachments_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Attachments_Bool_Exp>;
};


export type Query_RootMentoring_Records_Attachments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMentoring_Records_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMentoring_Records_MembersArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
};


export type Query_RootMentoring_Records_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
};


export type Query_RootMentoring_Records_Members_By_PkArgs = {
  member_id: Scalars['String'];
  mentoring_record_id: Scalars['uuid'];
};


export type Query_RootMentoring_ReschedulesArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Reschedules_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Reschedules_Order_By>>;
  where?: InputMaybe<Mentoring_Reschedules_Bool_Exp>;
};


export type Query_RootMentoring_Reschedules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Reschedules_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Reschedules_Order_By>>;
  where?: InputMaybe<Mentoring_Reschedules_Bool_Exp>;
};


export type Query_RootMentoring_Reschedules_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMentoring_SchedulingArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Scheduling_Order_By>>;
  where?: InputMaybe<Mentoring_Scheduling_Bool_Exp>;
};


export type Query_RootMentoring_Scheduling_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Scheduling_Order_By>>;
  where?: InputMaybe<Mentoring_Scheduling_Bool_Exp>;
};


export type Query_RootMentoring_Scheduling_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMentoring_Scheduling_MeetingArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Scheduling_Meeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Scheduling_Meeting_Order_By>>;
  where?: InputMaybe<Mentoring_Scheduling_Meeting_Bool_Exp>;
};


export type Query_RootMentoring_Scheduling_Meeting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Scheduling_Meeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Scheduling_Meeting_Order_By>>;
  where?: InputMaybe<Mentoring_Scheduling_Meeting_Bool_Exp>;
};


export type Query_RootMentoring_Scheduling_Meeting_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMentoring_Scheduling_MembersArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Scheduling_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Scheduling_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Scheduling_Members_Bool_Exp>;
};


export type Query_RootMentoring_Scheduling_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Scheduling_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Scheduling_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Scheduling_Members_Bool_Exp>;
};


export type Query_RootMentoring_Scheduling_Members_By_PkArgs = {
  id: Scalars['uuid'];
  member_id: Scalars['String'];
  mentoring_scheduling_id: Scalars['uuid'];
};


export type Query_RootMentorsArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Order_By>>;
  where?: InputMaybe<Mentors_Bool_Exp>;
};


export type Query_RootMentors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Order_By>>;
  where?: InputMaybe<Mentors_Bool_Exp>;
};


export type Query_RootMentors_BlablablaArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


export type Query_RootMentors_Blablabla_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


export type Query_RootMentors_Blablabla_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMentors_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMentors_Mentor_TagsArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Mentor_Tags_Order_By>>;
  where?: InputMaybe<Mentors_Mentor_Tags_Bool_Exp>;
};


export type Query_RootMentors_Mentor_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Mentor_Tags_Order_By>>;
  where?: InputMaybe<Mentors_Mentor_Tags_Bool_Exp>;
};


export type Query_RootMentors_Mentor_Tags_By_PkArgs = {
  mentor_id: Scalars['uuid'];
  tag_name: Scalars['String'];
};


export type Query_RootMetodologiesArgs = {
  distinct_on?: InputMaybe<Array<Metodologies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metodologies_Order_By>>;
  where?: InputMaybe<Metodologies_Bool_Exp>;
};


export type Query_RootMetodologies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metodologies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metodologies_Order_By>>;
  where?: InputMaybe<Metodologies_Bool_Exp>;
};


export type Query_RootMetodologies_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootMultimedia_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Bool_Exp>;
};


export type Query_RootMultimedia_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Bool_Exp>;
};


export type Query_RootMultimedia_Contents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMultimedia_Contents_LinksArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Links_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};


export type Query_RootMultimedia_Contents_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Links_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};


export type Query_RootMultimedia_Contents_Links_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOutputsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Order_By>>;
  where?: InputMaybe<Outputs_Bool_Exp>;
};


export type Query_RootOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Order_By>>;
  where?: InputMaybe<Outputs_Bool_Exp>;
};


export type Query_RootOutputs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOutputs_IconsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Icons_Order_By>>;
  where?: InputMaybe<Outputs_Icons_Bool_Exp>;
};


export type Query_RootOutputs_Icons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Icons_Order_By>>;
  where?: InputMaybe<Outputs_Icons_Bool_Exp>;
};


export type Query_RootOutputs_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOutputs_ToolsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};


export type Query_RootOutputs_Tools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};


export type Query_RootOutputs_Tools_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Query_RootQuestionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Query_RootQuestionnaires_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootQuestionnaires_Teams_StatesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};


export type Query_RootQuestionnaires_Teams_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};


export type Query_RootQuestionnaires_Teams_States_By_PkArgs = {
  questionnaire_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};


export type Query_RootQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Query_RootQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Query_RootQuestions_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


export type Query_RootQuestions_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


export type Query_RootQuestions_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootQuestions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootQuestions_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Options_Order_By>>;
  where?: InputMaybe<Questions_Options_Bool_Exp>;
};


export type Query_RootQuestions_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Options_Order_By>>;
  where?: InputMaybe<Questions_Options_Bool_Exp>;
};


export type Query_RootQuestions_Options_By_PkArgs = {
  label: Scalars['String'];
  question_id: Scalars['uuid'];
  value: Scalars['String'];
};


export type Query_RootQuestions_TypesArgs = {
  distinct_on?: InputMaybe<Array<Questions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Types_Order_By>>;
  where?: InputMaybe<Questions_Types_Bool_Exp>;
};


export type Query_RootQuestions_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Types_Order_By>>;
  where?: InputMaybe<Questions_Types_Bool_Exp>;
};


export type Query_RootQuestions_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootRepositoriesArgs = {
  distinct_on?: InputMaybe<Array<Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Repositories_Order_By>>;
  where?: InputMaybe<Repositories_Bool_Exp>;
};


export type Query_RootRepositories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Repositories_Order_By>>;
  where?: InputMaybe<Repositories_Bool_Exp>;
};


export type Query_RootRepositories_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRequested_MentoringsArgs = {
  distinct_on?: InputMaybe<Array<Requested_Mentorings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Requested_Mentorings_Order_By>>;
  where?: InputMaybe<Requested_Mentorings_Bool_Exp>;
};


export type Query_RootRequested_Mentorings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Requested_Mentorings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Requested_Mentorings_Order_By>>;
  where?: InputMaybe<Requested_Mentorings_Bool_Exp>;
};


export type Query_RootRequested_Mentorings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRequested_Mentorings_StatusArgs = {
  distinct_on?: InputMaybe<Array<Requested_Mentorings_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Requested_Mentorings_Status_Order_By>>;
  where?: InputMaybe<Requested_Mentorings_Status_Bool_Exp>;
};


export type Query_RootRequested_Mentorings_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Requested_Mentorings_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Requested_Mentorings_Status_Order_By>>;
  where?: InputMaybe<Requested_Mentorings_Status_Bool_Exp>;
};


export type Query_RootRequested_Mentorings_Status_By_PkArgs = {
  status: Scalars['String'];
};


export type Query_RootRequests_Mentor_TagsArgs = {
  distinct_on?: InputMaybe<Array<Requests_Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Requests_Mentor_Tags_Order_By>>;
  where?: InputMaybe<Requests_Mentor_Tags_Bool_Exp>;
};


export type Query_RootRequests_Mentor_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Requests_Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Requests_Mentor_Tags_Order_By>>;
  where?: InputMaybe<Requests_Mentor_Tags_Bool_Exp>;
};


export type Query_RootRequests_Mentor_Tags_By_PkArgs = {
  request_id: Scalars['uuid'];
  tag_name: Scalars['String'];
};


export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_By_PkArgs = {
  role: Scalars['String'];
};


export type Query_RootSectionsArgs = {
  distinct_on?: InputMaybe<Array<Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Order_By>>;
  where?: InputMaybe<Sections_Bool_Exp>;
};


export type Query_RootSections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Order_By>>;
  where?: InputMaybe<Sections_Bool_Exp>;
};


export type Query_RootSections_By_PkArgs = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};


export type Query_RootSections_IconsArgs = {
  distinct_on?: InputMaybe<Array<Sections_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Icons_Order_By>>;
  where?: InputMaybe<Sections_Icons_Bool_Exp>;
};


export type Query_RootSections_Icons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sections_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Icons_Order_By>>;
  where?: InputMaybe<Sections_Icons_Bool_Exp>;
};


export type Query_RootSections_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSocial_MediasArgs = {
  distinct_on?: InputMaybe<Array<Social_Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Social_Medias_Order_By>>;
  where?: InputMaybe<Social_Medias_Bool_Exp>;
};


export type Query_RootSocial_Medias_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Social_Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Social_Medias_Order_By>>;
  where?: InputMaybe<Social_Medias_Bool_Exp>;
};


export type Query_RootSocial_Medias_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTag_ConversionArgs = {
  distinct_on?: InputMaybe<Array<Tag_Conversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Conversion_Order_By>>;
  where?: InputMaybe<Tag_Conversion_Bool_Exp>;
};


export type Query_RootTag_Conversion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Conversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Conversion_Order_By>>;
  where?: InputMaybe<Tag_Conversion_Bool_Exp>;
};


export type Query_RootTag_Conversion_By_PkArgs = {
  old_tag: Scalars['String'];
};


export type Query_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Query_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Query_RootTags_By_PkArgs = {
  id: Scalars['uuid'];
  value: Scalars['String'];
};


export type Query_RootTeam_InvitesArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};


export type Query_RootTeam_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};


export type Query_RootTeam_Invites_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Query_RootTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Query_RootTeams_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeams_NamesArgs = {
  distinct_on?: InputMaybe<Array<Teams_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Names_Order_By>>;
  where?: InputMaybe<Teams_Names_Bool_Exp>;
};


export type Query_RootTeams_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Names_Order_By>>;
  where?: InputMaybe<Teams_Names_Bool_Exp>;
};


export type Query_RootTeams_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeams_ScoresArgs = {
  distinct_on?: InputMaybe<Array<Teams_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Scores_Order_By>>;
  where?: InputMaybe<Teams_Scores_Bool_Exp>;
};


export type Query_RootTeams_Scores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Scores_Order_By>>;
  where?: InputMaybe<Teams_Scores_Bool_Exp>;
};


export type Query_RootTeams_TagsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};


export type Query_RootTeams_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};


export type Query_RootTeams_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeams_UsersArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};


export type Query_RootTeams_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};


export type Query_RootTeams_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootToolsArgs = {
  distinct_on?: InputMaybe<Array<Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Order_By>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Query_RootTools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Order_By>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Query_RootTools_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTools_IconsArgs = {
  distinct_on?: InputMaybe<Array<Tools_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Icons_Order_By>>;
  where?: InputMaybe<Tools_Icons_Bool_Exp>;
};


export type Query_RootTools_Icons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tools_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Icons_Order_By>>;
  where?: InputMaybe<Tools_Icons_Bool_Exp>;
};


export type Query_RootTools_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_InfoArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};


export type Query_RootUser_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};


export type Query_RootUser_Info_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_InvitesArgs = {
  distinct_on?: InputMaybe<Array<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Query_RootUser_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Query_RootUser_Invites_By_PkArgs = {
  id: Scalars['uuid'];
  token: Scalars['String'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Query_RootUsers_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Query_RootUsers_Actions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUsers_Actions_TypesArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Types_Order_By>>;
  where?: InputMaybe<Users_Actions_Types_Bool_Exp>;
};


export type Query_RootUsers_Actions_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Types_Order_By>>;
  where?: InputMaybe<Users_Actions_Types_Bool_Exp>;
};


export type Query_RootUsers_Actions_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  email: Scalars['String'];
  id: Scalars['String'];
};


export type Query_RootUsers_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Order_By>>;
  where?: InputMaybe<Users_Documents_Bool_Exp>;
};


export type Query_RootUsers_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Order_By>>;
  where?: InputMaybe<Users_Documents_Bool_Exp>;
};


export type Query_RootUsers_Documents_By_PkArgs = {
  file_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


export type Query_RootUsers_Documents_TypesArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Types_Order_By>>;
  where?: InputMaybe<Users_Documents_Types_Bool_Exp>;
};


export type Query_RootUsers_Documents_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Types_Order_By>>;
  where?: InputMaybe<Users_Documents_Types_Bool_Exp>;
};


export type Query_RootUsers_Documents_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootUsers_NamesArgs = {
  distinct_on?: InputMaybe<Array<Users_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Names_Order_By>>;
  where?: InputMaybe<Users_Names_Bool_Exp>;
};


export type Query_RootUsers_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Names_Order_By>>;
  where?: InputMaybe<Users_Names_Bool_Exp>;
};


export type Query_RootUsers_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVideo_RepositoriesArgs = {
  distinct_on?: InputMaybe<Array<Video_Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Repositories_Order_By>>;
  where?: InputMaybe<Video_Repositories_Bool_Exp>;
};


export type Query_RootVideo_Repositories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Repositories_Order_By>>;
  where?: InputMaybe<Video_Repositories_Bool_Exp>;
};


export type Query_RootVideo_Repositories_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVideosArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Query_RootVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Query_RootVideos_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVideos_By_TagsArgs = {
  args: Videos_By_Tags_Args;
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Query_RootVideos_By_Tags_AggregateArgs = {
  args: Videos_By_Tags_Args;
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Query_RootVideos_Youtube_TagsArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};


export type Query_RootVideos_Youtube_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};


export type Query_RootVideos_Youtube_Tags_By_PkArgs = {
  tag: Scalars['String'];
  video_id: Scalars['uuid'];
};


export type Query_RootYoutube_TagsArgs = {
  distinct_on?: InputMaybe<Array<Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Youtube_Tags_Order_By>>;
  where?: InputMaybe<Youtube_Tags_Bool_Exp>;
};


export type Query_RootYoutube_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Youtube_Tags_Order_By>>;
  where?: InputMaybe<Youtube_Tags_Bool_Exp>;
};


export type Query_RootYoutube_Tags_By_PkArgs = {
  tag: Scalars['String'];
};

/** columns and relationships of "questionnaires" */
export type Questionnaires = {
  __typename?: 'questionnaires';
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** An array relationship */
  teams_states: Array<Questionnaires_Teams_States>;
  /** An aggregate relationship */
  teams_states_aggregate: Questionnaires_Teams_States_Aggregate;
  /** An object relationship */
  tool: Tools;
  tool_id: Scalars['uuid'];
};


/** columns and relationships of "questionnaires" */
export type QuestionnairesQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


/** columns and relationships of "questionnaires" */
export type QuestionnairesQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


/** columns and relationships of "questionnaires" */
export type QuestionnairesTeams_StatesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};


/** columns and relationships of "questionnaires" */
export type QuestionnairesTeams_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};

/** aggregated selection of "questionnaires" */
export type Questionnaires_Aggregate = {
  __typename?: 'questionnaires_aggregate';
  aggregate?: Maybe<Questionnaires_Aggregate_Fields>;
  nodes: Array<Questionnaires>;
};

export type Questionnaires_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Questionnaires_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Questionnaires_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Questionnaires_Aggregate_Bool_Exp_Count>;
};

export type Questionnaires_Aggregate_Bool_Exp_Bool_And = {
  arguments: Questionnaires_Select_Column_Questionnaires_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Questionnaires_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Questionnaires_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Questionnaires_Select_Column_Questionnaires_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Questionnaires_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Questionnaires_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Questionnaires_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Questionnaires_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questionnaires" */
export type Questionnaires_Aggregate_Fields = {
  __typename?: 'questionnaires_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Questionnaires_Max_Fields>;
  min?: Maybe<Questionnaires_Min_Fields>;
};


/** aggregate fields of "questionnaires" */
export type Questionnaires_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questionnaires_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "questionnaires" */
export type Questionnaires_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questionnaires_Max_Order_By>;
  min?: InputMaybe<Questionnaires_Min_Order_By>;
};

/** input type for inserting array relation for remote table "questionnaires" */
export type Questionnaires_Arr_Rel_Insert_Input = {
  data: Array<Questionnaires_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};

/** Boolean expression to filter rows from the table "questionnaires". All fields are combined with a logical 'AND'. */
export type Questionnaires_Bool_Exp = {
  _and?: InputMaybe<Array<Questionnaires_Bool_Exp>>;
  _not?: InputMaybe<Questionnaires_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaires_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  questions?: InputMaybe<Questions_Bool_Exp>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Bool_Exp>;
  teams_states?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
  teams_states_aggregate?: InputMaybe<Questionnaires_Teams_States_Aggregate_Bool_Exp>;
  tool?: InputMaybe<Tools_Bool_Exp>;
  tool_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "questionnaires" */
export enum Questionnaires_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionnairesPkey = 'questionnaires_pkey'
}

/** input type for inserting data into table "questionnaires" */
export type Questionnaires_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  questions?: InputMaybe<Questions_Arr_Rel_Insert_Input>;
  teams_states?: InputMaybe<Questionnaires_Teams_States_Arr_Rel_Insert_Input>;
  tool?: InputMaybe<Tools_Obj_Rel_Insert_Input>;
  tool_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Questionnaires_Max_Fields = {
  __typename?: 'questionnaires_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "questionnaires" */
export type Questionnaires_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questionnaires_Min_Fields = {
  __typename?: 'questionnaires_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "questionnaires" */
export type Questionnaires_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questionnaires" */
export type Questionnaires_Mutation_Response = {
  __typename?: 'questionnaires_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaires>;
};

/** input type for inserting object relation for remote table "questionnaires" */
export type Questionnaires_Obj_Rel_Insert_Input = {
  data: Questionnaires_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};

/** on_conflict condition type for table "questionnaires" */
export type Questionnaires_On_Conflict = {
  constraint: Questionnaires_Constraint;
  update_columns?: Array<Questionnaires_Update_Column>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** Ordering options when selecting data from "questionnaires". */
export type Questionnaires_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questions_aggregate?: InputMaybe<Questions_Aggregate_Order_By>;
  teams_states_aggregate?: InputMaybe<Questionnaires_Teams_States_Aggregate_Order_By>;
  tool?: InputMaybe<Tools_Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questionnaires */
export type Questionnaires_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "questionnaires" */
export enum Questionnaires_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ToolId = 'tool_id'
}

/** select "questionnaires_aggregate_bool_exp_bool_and_arguments_columns" columns of table "questionnaires" */
export enum Questionnaires_Select_Column_Questionnaires_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "questionnaires_aggregate_bool_exp_bool_or_arguments_columns" columns of table "questionnaires" */
export enum Questionnaires_Select_Column_Questionnaires_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "questionnaires" */
export type Questionnaires_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  tool_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "questionnaires" */
export type Questionnaires_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionnaires_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionnaires_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  tool_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "questionnaires_teams_states" */
export type Questionnaires_Teams_States = {
  __typename?: 'questionnaires_teams_states';
  finished: Scalars['Boolean'];
  /** An object relationship */
  questionnaire: Questionnaires;
  questionnaire_id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Aggregate = {
  __typename?: 'questionnaires_teams_states_aggregate';
  aggregate?: Maybe<Questionnaires_Teams_States_Aggregate_Fields>;
  nodes: Array<Questionnaires_Teams_States>;
};

export type Questionnaires_Teams_States_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Questionnaires_Teams_States_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Questionnaires_Teams_States_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Questionnaires_Teams_States_Aggregate_Bool_Exp_Count>;
};

export type Questionnaires_Teams_States_Aggregate_Bool_Exp_Bool_And = {
  arguments: Questionnaires_Teams_States_Select_Column_Questionnaires_Teams_States_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Questionnaires_Teams_States_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Questionnaires_Teams_States_Select_Column_Questionnaires_Teams_States_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Questionnaires_Teams_States_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Aggregate_Fields = {
  __typename?: 'questionnaires_teams_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Questionnaires_Teams_States_Max_Fields>;
  min?: Maybe<Questionnaires_Teams_States_Min_Fields>;
};


/** aggregate fields of "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questionnaires_Teams_States_Max_Order_By>;
  min?: InputMaybe<Questionnaires_Teams_States_Min_Order_By>;
};

/** input type for inserting array relation for remote table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Arr_Rel_Insert_Input = {
  data: Array<Questionnaires_Teams_States_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaires_Teams_States_On_Conflict>;
};

/** Boolean expression to filter rows from the table "questionnaires_teams_states". All fields are combined with a logical 'AND'. */
export type Questionnaires_Teams_States_Bool_Exp = {
  _and?: InputMaybe<Array<Questionnaires_Teams_States_Bool_Exp>>;
  _not?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaires_Teams_States_Bool_Exp>>;
  finished?: InputMaybe<Boolean_Comparison_Exp>;
  questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "questionnaires_teams_states" */
export enum Questionnaires_Teams_States_Constraint {
  /** unique or primary key constraint on columns "questionnaire_id", "team_id" */
  QuestionnairesTeamsStatesPkey = 'questionnaires_teams_states_pkey'
}

/** input type for inserting data into table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Insert_Input = {
  finished?: InputMaybe<Scalars['Boolean']>;
  questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Questionnaires_Teams_States_Max_Fields = {
  __typename?: 'questionnaires_teams_states_max_fields';
  questionnaire_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Max_Order_By = {
  questionnaire_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questionnaires_Teams_States_Min_Fields = {
  __typename?: 'questionnaires_teams_states_min_fields';
  questionnaire_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Min_Order_By = {
  questionnaire_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Mutation_Response = {
  __typename?: 'questionnaires_teams_states_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaires_Teams_States>;
};

/** on_conflict condition type for table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_On_Conflict = {
  constraint: Questionnaires_Teams_States_Constraint;
  update_columns?: Array<Questionnaires_Teams_States_Update_Column>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};

/** Ordering options when selecting data from "questionnaires_teams_states". */
export type Questionnaires_Teams_States_Order_By = {
  finished?: InputMaybe<Order_By>;
  questionnaire?: InputMaybe<Questionnaires_Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questionnaires_teams_states */
export type Questionnaires_Teams_States_Pk_Columns_Input = {
  questionnaire_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};

/** select columns of table "questionnaires_teams_states" */
export enum Questionnaires_Teams_States_Select_Column {
  /** column name */
  Finished = 'finished',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "questionnaires_teams_states_aggregate_bool_exp_bool_and_arguments_columns" columns of table "questionnaires_teams_states" */
export enum Questionnaires_Teams_States_Select_Column_Questionnaires_Teams_States_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Finished = 'finished'
}

/** select "questionnaires_teams_states_aggregate_bool_exp_bool_or_arguments_columns" columns of table "questionnaires_teams_states" */
export enum Questionnaires_Teams_States_Select_Column_Questionnaires_Teams_States_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Finished = 'finished'
}

/** input type for updating data in table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Set_Input = {
  finished?: InputMaybe<Scalars['Boolean']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "questionnaires_teams_states" */
export type Questionnaires_Teams_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionnaires_Teams_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionnaires_Teams_States_Stream_Cursor_Value_Input = {
  finished?: InputMaybe<Scalars['Boolean']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "questionnaires_teams_states" */
export enum Questionnaires_Teams_States_Update_Column {
  /** column name */
  Finished = 'finished',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Questionnaires_Teams_States_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionnaires_Teams_States_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionnaires_Teams_States_Bool_Exp;
};

/** update columns of table "questionnaires" */
export enum Questionnaires_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ToolId = 'tool_id'
}

export type Questionnaires_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionnaires_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionnaires_Bool_Exp;
};

/** columns and relationships of "questions" */
export type Questions = {
  __typename?: 'questions';
  id: Scalars['uuid'];
  /** An array relationship */
  options: Array<Questions_Options>;
  /** An aggregate relationship */
  options_aggregate: Questions_Options_Aggregate;
  order: Scalars['Int'];
  /** An array relationship */
  question_answers: Array<Questions_Answers>;
  /** An aggregate relationship */
  question_answers_aggregate: Questions_Answers_Aggregate;
  /** An object relationship */
  questionnaire: Questionnaires;
  questionnaire_id: Scalars['uuid'];
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: Questions_Types_Enum;
};


/** columns and relationships of "questions" */
export type QuestionsOptionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Options_Order_By>>;
  where?: InputMaybe<Questions_Options_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsOptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Options_Order_By>>;
  where?: InputMaybe<Questions_Options_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsQuestion_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


/** columns and relationships of "questions" */
export type QuestionsQuestion_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};

/** aggregated selection of "questions" */
export type Questions_Aggregate = {
  __typename?: 'questions_aggregate';
  aggregate?: Maybe<Questions_Aggregate_Fields>;
  nodes: Array<Questions>;
};

export type Questions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Questions_Aggregate_Bool_Exp_Count>;
};

export type Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questions" */
export type Questions_Aggregate_Fields = {
  __typename?: 'questions_aggregate_fields';
  avg?: Maybe<Questions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Questions_Max_Fields>;
  min?: Maybe<Questions_Min_Fields>;
  stddev?: Maybe<Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Questions_Sum_Fields>;
  var_pop?: Maybe<Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Questions_Var_Samp_Fields>;
  variance?: Maybe<Questions_Variance_Fields>;
};


/** aggregate fields of "questions" */
export type Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "questions" */
export type Questions_Aggregate_Order_By = {
  avg?: InputMaybe<Questions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questions_Max_Order_By>;
  min?: InputMaybe<Questions_Min_Order_By>;
  stddev?: InputMaybe<Questions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Questions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Questions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Questions_Sum_Order_By>;
  var_pop?: InputMaybe<Questions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Questions_Var_Samp_Order_By>;
  variance?: InputMaybe<Questions_Variance_Order_By>;
};

/** columns and relationships of "questions_answers" */
export type Questions_Answers = {
  __typename?: 'questions_answers';
  answer: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  question: Questions;
  question_id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "questions_answers" */
export type Questions_Answers_Aggregate = {
  __typename?: 'questions_answers_aggregate';
  aggregate?: Maybe<Questions_Answers_Aggregate_Fields>;
  nodes: Array<Questions_Answers>;
};

export type Questions_Answers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Questions_Answers_Aggregate_Bool_Exp_Count>;
};

export type Questions_Answers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Questions_Answers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questions_answers" */
export type Questions_Answers_Aggregate_Fields = {
  __typename?: 'questions_answers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Questions_Answers_Max_Fields>;
  min?: Maybe<Questions_Answers_Min_Fields>;
};


/** aggregate fields of "questions_answers" */
export type Questions_Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "questions_answers" */
export type Questions_Answers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questions_Answers_Max_Order_By>;
  min?: InputMaybe<Questions_Answers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "questions_answers" */
export type Questions_Answers_Arr_Rel_Insert_Input = {
  data: Array<Questions_Answers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_Answers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "questions_answers". All fields are combined with a logical 'AND'. */
export type Questions_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Questions_Answers_Bool_Exp>>;
  _not?: InputMaybe<Questions_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Questions_Answers_Bool_Exp>>;
  answer?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  question?: InputMaybe<Questions_Bool_Exp>;
  question_id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "questions_answers" */
export enum Questions_Answers_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionsAnswersPkey = 'questions_answers_pkey',
  /** unique or primary key constraint on columns "question_id", "team_id" */
  QuestionsAnswersQuestionIdTeamIdKey = 'questions_answers_question_id_team_id_key'
}

/** input type for inserting data into table "questions_answers" */
export type Questions_Answers_Insert_Input = {
  answer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Questions_Obj_Rel_Insert_Input>;
  question_id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Questions_Answers_Max_Fields = {
  __typename?: 'questions_answers_max_fields';
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "questions_answers" */
export type Questions_Answers_Max_Order_By = {
  answer?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questions_Answers_Min_Fields = {
  __typename?: 'questions_answers_min_fields';
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "questions_answers" */
export type Questions_Answers_Min_Order_By = {
  answer?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questions_answers" */
export type Questions_Answers_Mutation_Response = {
  __typename?: 'questions_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questions_Answers>;
};

/** on_conflict condition type for table "questions_answers" */
export type Questions_Answers_On_Conflict = {
  constraint: Questions_Answers_Constraint;
  update_columns?: Array<Questions_Answers_Update_Column>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "questions_answers". */
export type Questions_Answers_Order_By = {
  answer?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Questions_Order_By>;
  question_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questions_answers */
export type Questions_Answers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "questions_answers" */
export enum Questions_Answers_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "questions_answers" */
export type Questions_Answers_Set_Input = {
  answer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  question_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "questions_answers" */
export type Questions_Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questions_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questions_Answers_Stream_Cursor_Value_Input = {
  answer?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  question_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "questions_answers" */
export enum Questions_Answers_Update_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

export type Questions_Answers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questions_Answers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questions_Answers_Bool_Exp;
};

/** input type for inserting array relation for remote table "questions" */
export type Questions_Arr_Rel_Insert_Input = {
  data: Array<Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};

/** aggregate avg on columns */
export type Questions_Avg_Fields = {
  __typename?: 'questions_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "questions" */
export type Questions_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "questions". All fields are combined with a logical 'AND'. */
export type Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Questions_Bool_Exp>>;
  _not?: InputMaybe<Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Questions_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  options?: InputMaybe<Questions_Options_Bool_Exp>;
  options_aggregate?: InputMaybe<Questions_Options_Aggregate_Bool_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  question_answers?: InputMaybe<Questions_Answers_Bool_Exp>;
  question_answers_aggregate?: InputMaybe<Questions_Answers_Aggregate_Bool_Exp>;
  questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  subtitle?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Questions_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "questions" */
export enum Questions_Constraint {
  /** unique or primary key constraint on columns "questionnaire_id", "order" */
  QuestionsOrderQuestionnaireIdKey = 'questions_order_questionnaire_id_key',
  /** unique or primary key constraint on columns "id" */
  QuestionsPkey = 'questions_pkey'
}

/** input type for incrementing numeric columns in table "questions" */
export type Questions_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "questions" */
export type Questions_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  options?: InputMaybe<Questions_Options_Arr_Rel_Insert_Input>;
  order?: InputMaybe<Scalars['Int']>;
  question_answers?: InputMaybe<Questions_Answers_Arr_Rel_Insert_Input>;
  questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Questions_Types_Enum>;
};

/** aggregate max on columns */
export type Questions_Max_Fields = {
  __typename?: 'questions_max_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "questions" */
export type Questions_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questions_Min_Fields = {
  __typename?: 'questions_min_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  questionnaire_id?: Maybe<Scalars['uuid']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "questions" */
export type Questions_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questions" */
export type Questions_Mutation_Response = {
  __typename?: 'questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questions>;
};

/** input type for inserting object relation for remote table "questions" */
export type Questions_Obj_Rel_Insert_Input = {
  data: Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_On_Conflict>;
};

/** on_conflict condition type for table "questions" */
export type Questions_On_Conflict = {
  constraint: Questions_Constraint;
  update_columns?: Array<Questions_Update_Column>;
  where?: InputMaybe<Questions_Bool_Exp>;
};

/** columns and relationships of "questions_options" */
export type Questions_Options = {
  __typename?: 'questions_options';
  label: Scalars['String'];
  question_id: Scalars['uuid'];
  value: Scalars['String'];
};

/** aggregated selection of "questions_options" */
export type Questions_Options_Aggregate = {
  __typename?: 'questions_options_aggregate';
  aggregate?: Maybe<Questions_Options_Aggregate_Fields>;
  nodes: Array<Questions_Options>;
};

export type Questions_Options_Aggregate_Bool_Exp = {
  count?: InputMaybe<Questions_Options_Aggregate_Bool_Exp_Count>;
};

export type Questions_Options_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Questions_Options_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Questions_Options_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questions_options" */
export type Questions_Options_Aggregate_Fields = {
  __typename?: 'questions_options_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Questions_Options_Max_Fields>;
  min?: Maybe<Questions_Options_Min_Fields>;
};


/** aggregate fields of "questions_options" */
export type Questions_Options_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questions_Options_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "questions_options" */
export type Questions_Options_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questions_Options_Max_Order_By>;
  min?: InputMaybe<Questions_Options_Min_Order_By>;
};

/** input type for inserting array relation for remote table "questions_options" */
export type Questions_Options_Arr_Rel_Insert_Input = {
  data: Array<Questions_Options_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questions_Options_On_Conflict>;
};

/** Boolean expression to filter rows from the table "questions_options". All fields are combined with a logical 'AND'. */
export type Questions_Options_Bool_Exp = {
  _and?: InputMaybe<Array<Questions_Options_Bool_Exp>>;
  _not?: InputMaybe<Questions_Options_Bool_Exp>;
  _or?: InputMaybe<Array<Questions_Options_Bool_Exp>>;
  label?: InputMaybe<String_Comparison_Exp>;
  question_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "questions_options" */
export enum Questions_Options_Constraint {
  /** unique or primary key constraint on columns "question_id", "label", "value" */
  QuestionsOptionsPkey = 'questions_options_pkey',
  /** unique or primary key constraint on columns "question_id", "label", "value" */
  QuestionsOptionsQuestionIdValueLabelKey = 'questions_options_question_id_value_label_key'
}

/** input type for inserting data into table "questions_options" */
export type Questions_Options_Insert_Input = {
  label?: InputMaybe<Scalars['String']>;
  question_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Questions_Options_Max_Fields = {
  __typename?: 'questions_options_max_fields';
  label?: Maybe<Scalars['String']>;
  question_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "questions_options" */
export type Questions_Options_Max_Order_By = {
  label?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questions_Options_Min_Fields = {
  __typename?: 'questions_options_min_fields';
  label?: Maybe<Scalars['String']>;
  question_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "questions_options" */
export type Questions_Options_Min_Order_By = {
  label?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questions_options" */
export type Questions_Options_Mutation_Response = {
  __typename?: 'questions_options_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questions_Options>;
};

/** on_conflict condition type for table "questions_options" */
export type Questions_Options_On_Conflict = {
  constraint: Questions_Options_Constraint;
  update_columns?: Array<Questions_Options_Update_Column>;
  where?: InputMaybe<Questions_Options_Bool_Exp>;
};

/** Ordering options when selecting data from "questions_options". */
export type Questions_Options_Order_By = {
  label?: InputMaybe<Order_By>;
  question_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questions_options */
export type Questions_Options_Pk_Columns_Input = {
  label: Scalars['String'];
  question_id: Scalars['uuid'];
  value: Scalars['String'];
};

/** select columns of table "questions_options" */
export enum Questions_Options_Select_Column {
  /** column name */
  Label = 'label',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "questions_options" */
export type Questions_Options_Set_Input = {
  label?: InputMaybe<Scalars['String']>;
  question_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "questions_options" */
export type Questions_Options_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questions_Options_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questions_Options_Stream_Cursor_Value_Input = {
  label?: InputMaybe<Scalars['String']>;
  question_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "questions_options" */
export enum Questions_Options_Update_Column {
  /** column name */
  Label = 'label',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  Value = 'value'
}

export type Questions_Options_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questions_Options_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questions_Options_Bool_Exp;
};

/** Ordering options when selecting data from "questions". */
export type Questions_Order_By = {
  id?: InputMaybe<Order_By>;
  options_aggregate?: InputMaybe<Questions_Options_Aggregate_Order_By>;
  order?: InputMaybe<Order_By>;
  question_answers_aggregate?: InputMaybe<Questions_Answers_Aggregate_Order_By>;
  questionnaire?: InputMaybe<Questionnaires_Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  subtitle?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questions */
export type Questions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "questions" */
export enum Questions_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "questions" */
export type Questions_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Questions_Types_Enum>;
};

/** aggregate stddev on columns */
export type Questions_Stddev_Fields = {
  __typename?: 'questions_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "questions" */
export type Questions_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Questions_Stddev_Pop_Fields = {
  __typename?: 'questions_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "questions" */
export type Questions_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Questions_Stddev_Samp_Fields = {
  __typename?: 'questions_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "questions" */
export type Questions_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "questions" */
export type Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questions_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Scalars['Int']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Questions_Types_Enum>;
};

/** aggregate sum on columns */
export type Questions_Sum_Fields = {
  __typename?: 'questions_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "questions" */
export type Questions_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "questions_types" */
export type Questions_Types = {
  __typename?: 'questions_types';
  type: Scalars['String'];
};

/** aggregated selection of "questions_types" */
export type Questions_Types_Aggregate = {
  __typename?: 'questions_types_aggregate';
  aggregate?: Maybe<Questions_Types_Aggregate_Fields>;
  nodes: Array<Questions_Types>;
};

/** aggregate fields of "questions_types" */
export type Questions_Types_Aggregate_Fields = {
  __typename?: 'questions_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Questions_Types_Max_Fields>;
  min?: Maybe<Questions_Types_Min_Fields>;
};


/** aggregate fields of "questions_types" */
export type Questions_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questions_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "questions_types". All fields are combined with a logical 'AND'. */
export type Questions_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Questions_Types_Bool_Exp>>;
  _not?: InputMaybe<Questions_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Questions_Types_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "questions_types" */
export enum Questions_Types_Constraint {
  /** unique or primary key constraint on columns "type" */
  QuestionsTypesPkey = 'questions_types_pkey'
}

export enum Questions_Types_Enum {
  File = 'file',
  HorizontalRule = 'horizontal_rule',
  InputArea = 'input_area',
  InputShort = 'input_short',
  Link = 'link',
  Miro = 'miro',
  Multiselect = 'multiselect',
  Radio = 'radio',
  Title = 'title'
}

/** Boolean expression to compare columns of type "questions_types_enum". All fields are combined with logical 'AND'. */
export type Questions_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Questions_Types_Enum>;
  _in?: InputMaybe<Array<Questions_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Questions_Types_Enum>;
  _nin?: InputMaybe<Array<Questions_Types_Enum>>;
};

/** input type for inserting data into table "questions_types" */
export type Questions_Types_Insert_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Questions_Types_Max_Fields = {
  __typename?: 'questions_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Questions_Types_Min_Fields = {
  __typename?: 'questions_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "questions_types" */
export type Questions_Types_Mutation_Response = {
  __typename?: 'questions_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questions_Types>;
};

/** on_conflict condition type for table "questions_types" */
export type Questions_Types_On_Conflict = {
  constraint: Questions_Types_Constraint;
  update_columns?: Array<Questions_Types_Update_Column>;
  where?: InputMaybe<Questions_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "questions_types". */
export type Questions_Types_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questions_types */
export type Questions_Types_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "questions_types" */
export enum Questions_Types_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "questions_types" */
export type Questions_Types_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "questions_types" */
export type Questions_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questions_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questions_Types_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "questions_types" */
export enum Questions_Types_Update_Column {
  /** column name */
  Type = 'type'
}

export type Questions_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questions_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questions_Types_Bool_Exp;
};

/** update columns of table "questions" */
export enum Questions_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

export type Questions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Questions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Questions_Var_Pop_Fields = {
  __typename?: 'questions_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "questions" */
export type Questions_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Questions_Var_Samp_Fields = {
  __typename?: 'questions_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "questions" */
export type Questions_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Questions_Variance_Fields = {
  __typename?: 'questions_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "questions" */
export type Questions_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "repositories" */
export type Repositories = {
  __typename?: 'repositories';
  app_key: Scalars['String'];
  /** An object relationship */
  content: Contents;
  content_id: Scalars['uuid'];
  id: Scalars['uuid'];
  link: Scalars['String'];
};

/** aggregated selection of "repositories" */
export type Repositories_Aggregate = {
  __typename?: 'repositories_aggregate';
  aggregate?: Maybe<Repositories_Aggregate_Fields>;
  nodes: Array<Repositories>;
};

export type Repositories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Repositories_Aggregate_Bool_Exp_Count>;
};

export type Repositories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Repositories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Repositories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "repositories" */
export type Repositories_Aggregate_Fields = {
  __typename?: 'repositories_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Repositories_Max_Fields>;
  min?: Maybe<Repositories_Min_Fields>;
};


/** aggregate fields of "repositories" */
export type Repositories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Repositories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "repositories" */
export type Repositories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Repositories_Max_Order_By>;
  min?: InputMaybe<Repositories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "repositories" */
export type Repositories_Arr_Rel_Insert_Input = {
  data: Array<Repositories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Repositories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "repositories". All fields are combined with a logical 'AND'. */
export type Repositories_Bool_Exp = {
  _and?: InputMaybe<Array<Repositories_Bool_Exp>>;
  _not?: InputMaybe<Repositories_Bool_Exp>;
  _or?: InputMaybe<Array<Repositories_Bool_Exp>>;
  app_key?: InputMaybe<String_Comparison_Exp>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "repositories" */
export enum Repositories_Constraint {
  /** unique or primary key constraint on columns "id" */
  RepositoriesPkey = 'repositories_pkey'
}

/** input type for inserting data into table "repositories" */
export type Repositories_Insert_Input = {
  app_key?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Repositories_Max_Fields = {
  __typename?: 'repositories_max_fields';
  app_key?: Maybe<Scalars['String']>;
  content_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "repositories" */
export type Repositories_Max_Order_By = {
  app_key?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Repositories_Min_Fields = {
  __typename?: 'repositories_min_fields';
  app_key?: Maybe<Scalars['String']>;
  content_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "repositories" */
export type Repositories_Min_Order_By = {
  app_key?: InputMaybe<Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "repositories" */
export type Repositories_Mutation_Response = {
  __typename?: 'repositories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Repositories>;
};

/** on_conflict condition type for table "repositories" */
export type Repositories_On_Conflict = {
  constraint: Repositories_Constraint;
  update_columns?: Array<Repositories_Update_Column>;
  where?: InputMaybe<Repositories_Bool_Exp>;
};

/** Ordering options when selecting data from "repositories". */
export type Repositories_Order_By = {
  app_key?: InputMaybe<Order_By>;
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
};

/** primary key columns input for table: repositories */
export type Repositories_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "repositories" */
export enum Repositories_Select_Column {
  /** column name */
  AppKey = 'app_key',
  /** column name */
  ContentId = 'content_id',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link'
}

/** input type for updating data in table "repositories" */
export type Repositories_Set_Input = {
  app_key?: InputMaybe<Scalars['String']>;
  content_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "repositories" */
export type Repositories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Repositories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Repositories_Stream_Cursor_Value_Input = {
  app_key?: InputMaybe<Scalars['String']>;
  content_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
};

/** update columns of table "repositories" */
export enum Repositories_Update_Column {
  /** column name */
  AppKey = 'app_key',
  /** column name */
  ContentId = 'content_id',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link'
}

export type Repositories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Repositories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Repositories_Bool_Exp;
};

/** columns and relationships of "requested_mentorings" */
export type Requested_Mentorings = {
  __typename?: 'requested_mentorings';
  /** An array relationship */
  available_dates: Array<Available_Mentoring_Dates>;
  /** An aggregate relationship */
  available_dates_aggregate: Available_Mentoring_Dates_Aggregate;
  cost: Scalars['Int'];
  created_at: Scalars['timestamp'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  mentoring_scheduling?: Maybe<Mentoring_Scheduling>;
  requested_date?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  requested_mentorings_status: Requested_Mentorings_Status;
  /** An array relationship */
  requests_mentor_tags: Array<Requests_Mentor_Tags>;
  /** An aggregate relationship */
  requests_mentor_tags_aggregate: Requests_Mentor_Tags_Aggregate;
  scheduling_id?: Maybe<Scalars['uuid']>;
  standard: Scalars['Boolean'];
  status: Requested_Mentorings_Status_Enum;
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};


/** columns and relationships of "requested_mentorings" */
export type Requested_MentoringsAvailable_DatesArgs = {
  distinct_on?: InputMaybe<Array<Available_Mentoring_Dates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Available_Mentoring_Dates_Order_By>>;
  where?: InputMaybe<Available_Mentoring_Dates_Bool_Exp>;
};


/** columns and relationships of "requested_mentorings" */
export type Requested_MentoringsAvailable_Dates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Available_Mentoring_Dates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Available_Mentoring_Dates_Order_By>>;
  where?: InputMaybe<Available_Mentoring_Dates_Bool_Exp>;
};


/** columns and relationships of "requested_mentorings" */
export type Requested_MentoringsRequests_Mentor_TagsArgs = {
  distinct_on?: InputMaybe<Array<Requests_Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Requests_Mentor_Tags_Order_By>>;
  where?: InputMaybe<Requests_Mentor_Tags_Bool_Exp>;
};


/** columns and relationships of "requested_mentorings" */
export type Requested_MentoringsRequests_Mentor_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Requests_Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Requests_Mentor_Tags_Order_By>>;
  where?: InputMaybe<Requests_Mentor_Tags_Bool_Exp>;
};

/** aggregated selection of "requested_mentorings" */
export type Requested_Mentorings_Aggregate = {
  __typename?: 'requested_mentorings_aggregate';
  aggregate?: Maybe<Requested_Mentorings_Aggregate_Fields>;
  nodes: Array<Requested_Mentorings>;
};

/** aggregate fields of "requested_mentorings" */
export type Requested_Mentorings_Aggregate_Fields = {
  __typename?: 'requested_mentorings_aggregate_fields';
  avg?: Maybe<Requested_Mentorings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Requested_Mentorings_Max_Fields>;
  min?: Maybe<Requested_Mentorings_Min_Fields>;
  stddev?: Maybe<Requested_Mentorings_Stddev_Fields>;
  stddev_pop?: Maybe<Requested_Mentorings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Requested_Mentorings_Stddev_Samp_Fields>;
  sum?: Maybe<Requested_Mentorings_Sum_Fields>;
  var_pop?: Maybe<Requested_Mentorings_Var_Pop_Fields>;
  var_samp?: Maybe<Requested_Mentorings_Var_Samp_Fields>;
  variance?: Maybe<Requested_Mentorings_Variance_Fields>;
};


/** aggregate fields of "requested_mentorings" */
export type Requested_Mentorings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Requested_Mentorings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Requested_Mentorings_Avg_Fields = {
  __typename?: 'requested_mentorings_avg_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "requested_mentorings". All fields are combined with a logical 'AND'. */
export type Requested_Mentorings_Bool_Exp = {
  _and?: InputMaybe<Array<Requested_Mentorings_Bool_Exp>>;
  _not?: InputMaybe<Requested_Mentorings_Bool_Exp>;
  _or?: InputMaybe<Array<Requested_Mentorings_Bool_Exp>>;
  available_dates?: InputMaybe<Available_Mentoring_Dates_Bool_Exp>;
  available_dates_aggregate?: InputMaybe<Available_Mentoring_Dates_Aggregate_Bool_Exp>;
  cost?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mentoring_scheduling?: InputMaybe<Mentoring_Scheduling_Bool_Exp>;
  requested_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  requested_mentorings_status?: InputMaybe<Requested_Mentorings_Status_Bool_Exp>;
  requests_mentor_tags?: InputMaybe<Requests_Mentor_Tags_Bool_Exp>;
  requests_mentor_tags_aggregate?: InputMaybe<Requests_Mentor_Tags_Aggregate_Bool_Exp>;
  scheduling_id?: InputMaybe<Uuid_Comparison_Exp>;
  standard?: InputMaybe<Boolean_Comparison_Exp>;
  status?: InputMaybe<Requested_Mentorings_Status_Enum_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "requested_mentorings" */
export enum Requested_Mentorings_Constraint {
  /** unique or primary key constraint on columns "id" */
  MentoringPendingPkey = 'mentoring_pending_pkey'
}

/** input type for incrementing numeric columns in table "requested_mentorings" */
export type Requested_Mentorings_Inc_Input = {
  cost?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "requested_mentorings" */
export type Requested_Mentorings_Insert_Input = {
  available_dates?: InputMaybe<Available_Mentoring_Dates_Arr_Rel_Insert_Input>;
  cost?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  mentoring_scheduling?: InputMaybe<Mentoring_Scheduling_Obj_Rel_Insert_Input>;
  requested_date?: InputMaybe<Scalars['timestamptz']>;
  requested_mentorings_status?: InputMaybe<Requested_Mentorings_Status_Obj_Rel_Insert_Input>;
  requests_mentor_tags?: InputMaybe<Requests_Mentor_Tags_Arr_Rel_Insert_Input>;
  scheduling_id?: InputMaybe<Scalars['uuid']>;
  standard?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Requested_Mentorings_Status_Enum>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Requested_Mentorings_Max_Fields = {
  __typename?: 'requested_mentorings_max_fields';
  cost?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  requested_date?: Maybe<Scalars['timestamptz']>;
  scheduling_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Requested_Mentorings_Min_Fields = {
  __typename?: 'requested_mentorings_min_fields';
  cost?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  requested_date?: Maybe<Scalars['timestamptz']>;
  scheduling_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "requested_mentorings" */
export type Requested_Mentorings_Mutation_Response = {
  __typename?: 'requested_mentorings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Requested_Mentorings>;
};

/** input type for inserting object relation for remote table "requested_mentorings" */
export type Requested_Mentorings_Obj_Rel_Insert_Input = {
  data: Requested_Mentorings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Requested_Mentorings_On_Conflict>;
};

/** on_conflict condition type for table "requested_mentorings" */
export type Requested_Mentorings_On_Conflict = {
  constraint: Requested_Mentorings_Constraint;
  update_columns?: Array<Requested_Mentorings_Update_Column>;
  where?: InputMaybe<Requested_Mentorings_Bool_Exp>;
};

/** Ordering options when selecting data from "requested_mentorings". */
export type Requested_Mentorings_Order_By = {
  available_dates_aggregate?: InputMaybe<Available_Mentoring_Dates_Aggregate_Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mentoring_scheduling?: InputMaybe<Mentoring_Scheduling_Order_By>;
  requested_date?: InputMaybe<Order_By>;
  requested_mentorings_status?: InputMaybe<Requested_Mentorings_Status_Order_By>;
  requests_mentor_tags_aggregate?: InputMaybe<Requests_Mentor_Tags_Aggregate_Order_By>;
  scheduling_id?: InputMaybe<Order_By>;
  standard?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: requested_mentorings */
export type Requested_Mentorings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "requested_mentorings" */
export enum Requested_Mentorings_Select_Column {
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  RequestedDate = 'requested_date',
  /** column name */
  SchedulingId = 'scheduling_id',
  /** column name */
  Standard = 'standard',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "requested_mentorings" */
export type Requested_Mentorings_Set_Input = {
  cost?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  requested_date?: InputMaybe<Scalars['timestamptz']>;
  scheduling_id?: InputMaybe<Scalars['uuid']>;
  standard?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Requested_Mentorings_Status_Enum>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "requested_mentorings_status" */
export type Requested_Mentorings_Status = {
  __typename?: 'requested_mentorings_status';
  status: Scalars['String'];
};

/** aggregated selection of "requested_mentorings_status" */
export type Requested_Mentorings_Status_Aggregate = {
  __typename?: 'requested_mentorings_status_aggregate';
  aggregate?: Maybe<Requested_Mentorings_Status_Aggregate_Fields>;
  nodes: Array<Requested_Mentorings_Status>;
};

/** aggregate fields of "requested_mentorings_status" */
export type Requested_Mentorings_Status_Aggregate_Fields = {
  __typename?: 'requested_mentorings_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Requested_Mentorings_Status_Max_Fields>;
  min?: Maybe<Requested_Mentorings_Status_Min_Fields>;
};


/** aggregate fields of "requested_mentorings_status" */
export type Requested_Mentorings_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Requested_Mentorings_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "requested_mentorings_status". All fields are combined with a logical 'AND'. */
export type Requested_Mentorings_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Requested_Mentorings_Status_Bool_Exp>>;
  _not?: InputMaybe<Requested_Mentorings_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Requested_Mentorings_Status_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "requested_mentorings_status" */
export enum Requested_Mentorings_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  RequestedMentoringsStatesPkey = 'requested_mentorings_states_pkey',
  /** unique or primary key constraint on columns "status" */
  RequestedMentoringsStatesStateKey = 'requested_mentorings_states_state_key'
}

export enum Requested_Mentorings_Status_Enum {
  Cancelled = 'cancelled',
  Done = 'done',
  Pending = 'pending',
  Scheduled = 'scheduled'
}

/** Boolean expression to compare columns of type "requested_mentorings_status_enum". All fields are combined with logical 'AND'. */
export type Requested_Mentorings_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Requested_Mentorings_Status_Enum>;
  _in?: InputMaybe<Array<Requested_Mentorings_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Requested_Mentorings_Status_Enum>;
  _nin?: InputMaybe<Array<Requested_Mentorings_Status_Enum>>;
};

/** input type for inserting data into table "requested_mentorings_status" */
export type Requested_Mentorings_Status_Insert_Input = {
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Requested_Mentorings_Status_Max_Fields = {
  __typename?: 'requested_mentorings_status_max_fields';
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Requested_Mentorings_Status_Min_Fields = {
  __typename?: 'requested_mentorings_status_min_fields';
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "requested_mentorings_status" */
export type Requested_Mentorings_Status_Mutation_Response = {
  __typename?: 'requested_mentorings_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Requested_Mentorings_Status>;
};

/** input type for inserting object relation for remote table "requested_mentorings_status" */
export type Requested_Mentorings_Status_Obj_Rel_Insert_Input = {
  data: Requested_Mentorings_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Requested_Mentorings_Status_On_Conflict>;
};

/** on_conflict condition type for table "requested_mentorings_status" */
export type Requested_Mentorings_Status_On_Conflict = {
  constraint: Requested_Mentorings_Status_Constraint;
  update_columns?: Array<Requested_Mentorings_Status_Update_Column>;
  where?: InputMaybe<Requested_Mentorings_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "requested_mentorings_status". */
export type Requested_Mentorings_Status_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: requested_mentorings_status */
export type Requested_Mentorings_Status_Pk_Columns_Input = {
  status: Scalars['String'];
};

/** select columns of table "requested_mentorings_status" */
export enum Requested_Mentorings_Status_Select_Column {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "requested_mentorings_status" */
export type Requested_Mentorings_Status_Set_Input = {
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "requested_mentorings_status" */
export type Requested_Mentorings_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Requested_Mentorings_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Requested_Mentorings_Status_Stream_Cursor_Value_Input = {
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "requested_mentorings_status" */
export enum Requested_Mentorings_Status_Update_Column {
  /** column name */
  Status = 'status'
}

export type Requested_Mentorings_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Requested_Mentorings_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Requested_Mentorings_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Requested_Mentorings_Stddev_Fields = {
  __typename?: 'requested_mentorings_stddev_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Requested_Mentorings_Stddev_Pop_Fields = {
  __typename?: 'requested_mentorings_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Requested_Mentorings_Stddev_Samp_Fields = {
  __typename?: 'requested_mentorings_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "requested_mentorings" */
export type Requested_Mentorings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Requested_Mentorings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Requested_Mentorings_Stream_Cursor_Value_Input = {
  cost?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  requested_date?: InputMaybe<Scalars['timestamptz']>;
  scheduling_id?: InputMaybe<Scalars['uuid']>;
  standard?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Requested_Mentorings_Status_Enum>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Requested_Mentorings_Sum_Fields = {
  __typename?: 'requested_mentorings_sum_fields';
  cost?: Maybe<Scalars['Int']>;
};

/** update columns of table "requested_mentorings" */
export enum Requested_Mentorings_Update_Column {
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  RequestedDate = 'requested_date',
  /** column name */
  SchedulingId = 'scheduling_id',
  /** column name */
  Standard = 'standard',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id'
}

export type Requested_Mentorings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Requested_Mentorings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Requested_Mentorings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Requested_Mentorings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Requested_Mentorings_Var_Pop_Fields = {
  __typename?: 'requested_mentorings_var_pop_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Requested_Mentorings_Var_Samp_Fields = {
  __typename?: 'requested_mentorings_var_samp_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Requested_Mentorings_Variance_Fields = {
  __typename?: 'requested_mentorings_variance_fields';
  cost?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "requests_mentor_tags" */
export type Requests_Mentor_Tags = {
  __typename?: 'requests_mentor_tags';
  /** An object relationship */
  mentor_tag: Mentor_Tags;
  /** An object relationship */
  request: Requested_Mentorings;
  request_id: Scalars['uuid'];
  tag_name: Scalars['String'];
};

/** aggregated selection of "requests_mentor_tags" */
export type Requests_Mentor_Tags_Aggregate = {
  __typename?: 'requests_mentor_tags_aggregate';
  aggregate?: Maybe<Requests_Mentor_Tags_Aggregate_Fields>;
  nodes: Array<Requests_Mentor_Tags>;
};

export type Requests_Mentor_Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Requests_Mentor_Tags_Aggregate_Bool_Exp_Count>;
};

export type Requests_Mentor_Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Requests_Mentor_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Requests_Mentor_Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "requests_mentor_tags" */
export type Requests_Mentor_Tags_Aggregate_Fields = {
  __typename?: 'requests_mentor_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Requests_Mentor_Tags_Max_Fields>;
  min?: Maybe<Requests_Mentor_Tags_Min_Fields>;
};


/** aggregate fields of "requests_mentor_tags" */
export type Requests_Mentor_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Requests_Mentor_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "requests_mentor_tags" */
export type Requests_Mentor_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Requests_Mentor_Tags_Max_Order_By>;
  min?: InputMaybe<Requests_Mentor_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "requests_mentor_tags" */
export type Requests_Mentor_Tags_Arr_Rel_Insert_Input = {
  data: Array<Requests_Mentor_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Requests_Mentor_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "requests_mentor_tags". All fields are combined with a logical 'AND'. */
export type Requests_Mentor_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Requests_Mentor_Tags_Bool_Exp>>;
  _not?: InputMaybe<Requests_Mentor_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Requests_Mentor_Tags_Bool_Exp>>;
  mentor_tag?: InputMaybe<Mentor_Tags_Bool_Exp>;
  request?: InputMaybe<Requested_Mentorings_Bool_Exp>;
  request_id?: InputMaybe<Uuid_Comparison_Exp>;
  tag_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "requests_mentor_tags" */
export enum Requests_Mentor_Tags_Constraint {
  /** unique or primary key constraint on columns "request_id", "tag_name" */
  RequestsMentorTagsPkey = 'requests_mentor_tags_pkey'
}

/** input type for inserting data into table "requests_mentor_tags" */
export type Requests_Mentor_Tags_Insert_Input = {
  mentor_tag?: InputMaybe<Mentor_Tags_Obj_Rel_Insert_Input>;
  request?: InputMaybe<Requested_Mentorings_Obj_Rel_Insert_Input>;
  request_id?: InputMaybe<Scalars['uuid']>;
  tag_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Requests_Mentor_Tags_Max_Fields = {
  __typename?: 'requests_mentor_tags_max_fields';
  request_id?: Maybe<Scalars['uuid']>;
  tag_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "requests_mentor_tags" */
export type Requests_Mentor_Tags_Max_Order_By = {
  request_id?: InputMaybe<Order_By>;
  tag_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Requests_Mentor_Tags_Min_Fields = {
  __typename?: 'requests_mentor_tags_min_fields';
  request_id?: Maybe<Scalars['uuid']>;
  tag_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "requests_mentor_tags" */
export type Requests_Mentor_Tags_Min_Order_By = {
  request_id?: InputMaybe<Order_By>;
  tag_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "requests_mentor_tags" */
export type Requests_Mentor_Tags_Mutation_Response = {
  __typename?: 'requests_mentor_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Requests_Mentor_Tags>;
};

/** on_conflict condition type for table "requests_mentor_tags" */
export type Requests_Mentor_Tags_On_Conflict = {
  constraint: Requests_Mentor_Tags_Constraint;
  update_columns?: Array<Requests_Mentor_Tags_Update_Column>;
  where?: InputMaybe<Requests_Mentor_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "requests_mentor_tags". */
export type Requests_Mentor_Tags_Order_By = {
  mentor_tag?: InputMaybe<Mentor_Tags_Order_By>;
  request?: InputMaybe<Requested_Mentorings_Order_By>;
  request_id?: InputMaybe<Order_By>;
  tag_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: requests_mentor_tags */
export type Requests_Mentor_Tags_Pk_Columns_Input = {
  request_id: Scalars['uuid'];
  tag_name: Scalars['String'];
};

/** select columns of table "requests_mentor_tags" */
export enum Requests_Mentor_Tags_Select_Column {
  /** column name */
  RequestId = 'request_id',
  /** column name */
  TagName = 'tag_name'
}

/** input type for updating data in table "requests_mentor_tags" */
export type Requests_Mentor_Tags_Set_Input = {
  request_id?: InputMaybe<Scalars['uuid']>;
  tag_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "requests_mentor_tags" */
export type Requests_Mentor_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Requests_Mentor_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Requests_Mentor_Tags_Stream_Cursor_Value_Input = {
  request_id?: InputMaybe<Scalars['uuid']>;
  tag_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "requests_mentor_tags" */
export enum Requests_Mentor_Tags_Update_Column {
  /** column name */
  RequestId = 'request_id',
  /** column name */
  TagName = 'tag_name'
}

export type Requests_Mentor_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Requests_Mentor_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Requests_Mentor_Tags_Bool_Exp;
};

/** columns and relationships of "roles" */
export type Roles = {
  __typename?: 'roles';
  role: Scalars['String'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
};


/** columns and relationships of "roles" */
export type RolesUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "roles" */
export type RolesUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  __typename?: 'roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  __typename?: 'roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
};


/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  role?: InputMaybe<String_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
  users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint on columns "role" */
  RolesPkey = 'roles_pkey'
}

export enum Roles_Enum {
  Admin = 'admin',
  Anonymous = 'anonymous',
  Editor = 'editor',
  Manager = 'manager',
  Mentor = 'mentor',
  User = 'user'
}

/** Boolean expression to compare columns of type "roles_enum". All fields are combined with logical 'AND'. */
export type Roles_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Roles_Enum>;
  _in?: InputMaybe<Array<Roles_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Roles_Enum>;
  _nin?: InputMaybe<Array<Roles_Enum>>;
};

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
  role?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename?: 'roles_max_fields';
  role?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename?: 'roles_min_fields';
  role?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  __typename?: 'roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
};

/** on_conflict condition type for table "roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns?: Array<Roles_Update_Column>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  role?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
  role: Scalars['String'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  role?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
  role?: InputMaybe<Scalars['String']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
  /** column name */
  Role = 'role'
}

export type Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Roles_Bool_Exp;
};

/** columns and relationships of "sections" */
export type Sections = {
  __typename?: 'sections';
  /** An array relationship */
  contents_sections: Array<Contents_Sections>;
  /** An aggregate relationship */
  contents_sections_aggregate: Contents_Sections_Aggregate;
  created_at: Scalars['timestamptz'];
  hex_color?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  intro: Scalars['String'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  /** An array relationship */
  outputs: Array<Outputs>;
  /** An aggregate relationship */
  outputs_aggregate: Outputs_Aggregate;
  outputs_img?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  outputs_img_file?: Maybe<Files>;
  outputs_text: Scalars['String'];
  /** An array relationship */
  section_icons_files: Array<Sections_Icons>;
  /** An aggregate relationship */
  section_icons_files_aggregate: Sections_Icons_Aggregate;
  slug: Scalars['String'];
};


/** columns and relationships of "sections" */
export type SectionsContents_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


/** columns and relationships of "sections" */
export type SectionsContents_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


/** columns and relationships of "sections" */
export type SectionsOutputsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Order_By>>;
  where?: InputMaybe<Outputs_Bool_Exp>;
};


/** columns and relationships of "sections" */
export type SectionsOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Order_By>>;
  where?: InputMaybe<Outputs_Bool_Exp>;
};


/** columns and relationships of "sections" */
export type SectionsSection_Icons_FilesArgs = {
  distinct_on?: InputMaybe<Array<Sections_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Icons_Order_By>>;
  where?: InputMaybe<Sections_Icons_Bool_Exp>;
};


/** columns and relationships of "sections" */
export type SectionsSection_Icons_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sections_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Icons_Order_By>>;
  where?: InputMaybe<Sections_Icons_Bool_Exp>;
};

/** aggregated selection of "sections" */
export type Sections_Aggregate = {
  __typename?: 'sections_aggregate';
  aggregate?: Maybe<Sections_Aggregate_Fields>;
  nodes: Array<Sections>;
};

/** aggregate fields of "sections" */
export type Sections_Aggregate_Fields = {
  __typename?: 'sections_aggregate_fields';
  avg?: Maybe<Sections_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sections_Max_Fields>;
  min?: Maybe<Sections_Min_Fields>;
  stddev?: Maybe<Sections_Stddev_Fields>;
  stddev_pop?: Maybe<Sections_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sections_Stddev_Samp_Fields>;
  sum?: Maybe<Sections_Sum_Fields>;
  var_pop?: Maybe<Sections_Var_Pop_Fields>;
  var_samp?: Maybe<Sections_Var_Samp_Fields>;
  variance?: Maybe<Sections_Variance_Fields>;
};


/** aggregate fields of "sections" */
export type Sections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Sections_Avg_Fields = {
  __typename?: 'sections_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sections". All fields are combined with a logical 'AND'. */
export type Sections_Bool_Exp = {
  _and?: InputMaybe<Array<Sections_Bool_Exp>>;
  _not?: InputMaybe<Sections_Bool_Exp>;
  _or?: InputMaybe<Array<Sections_Bool_Exp>>;
  contents_sections?: InputMaybe<Contents_Sections_Bool_Exp>;
  contents_sections_aggregate?: InputMaybe<Contents_Sections_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hex_color?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  intro?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  outputs?: InputMaybe<Outputs_Bool_Exp>;
  outputs_aggregate?: InputMaybe<Outputs_Aggregate_Bool_Exp>;
  outputs_img?: InputMaybe<Uuid_Comparison_Exp>;
  outputs_img_file?: InputMaybe<Files_Bool_Exp>;
  outputs_text?: InputMaybe<String_Comparison_Exp>;
  section_icons_files?: InputMaybe<Sections_Icons_Bool_Exp>;
  section_icons_files_aggregate?: InputMaybe<Sections_Icons_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sections" */
export enum Sections_Constraint {
  /** unique or primary key constraint on columns "id" */
  SectionsIdKey = 'sections_id_key',
  /** unique or primary key constraint on columns "id", "slug" */
  SectionsPkey = 'sections_pkey'
}

/** columns and relationships of "sections_icons" */
export type Sections_Icons = {
  __typename?: 'sections_icons';
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  section: Sections;
  section_id: Scalars['uuid'];
  type: Icons_Types_Enum;
};

/** aggregated selection of "sections_icons" */
export type Sections_Icons_Aggregate = {
  __typename?: 'sections_icons_aggregate';
  aggregate?: Maybe<Sections_Icons_Aggregate_Fields>;
  nodes: Array<Sections_Icons>;
};

export type Sections_Icons_Aggregate_Bool_Exp = {
  count?: InputMaybe<Sections_Icons_Aggregate_Bool_Exp_Count>;
};

export type Sections_Icons_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sections_Icons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Sections_Icons_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sections_icons" */
export type Sections_Icons_Aggregate_Fields = {
  __typename?: 'sections_icons_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Sections_Icons_Max_Fields>;
  min?: Maybe<Sections_Icons_Min_Fields>;
};


/** aggregate fields of "sections_icons" */
export type Sections_Icons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sections_Icons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sections_icons" */
export type Sections_Icons_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sections_Icons_Max_Order_By>;
  min?: InputMaybe<Sections_Icons_Min_Order_By>;
};

/** input type for inserting array relation for remote table "sections_icons" */
export type Sections_Icons_Arr_Rel_Insert_Input = {
  data: Array<Sections_Icons_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sections_Icons_On_Conflict>;
};

/** Boolean expression to filter rows from the table "sections_icons". All fields are combined with a logical 'AND'. */
export type Sections_Icons_Bool_Exp = {
  _and?: InputMaybe<Array<Sections_Icons_Bool_Exp>>;
  _not?: InputMaybe<Sections_Icons_Bool_Exp>;
  _or?: InputMaybe<Array<Sections_Icons_Bool_Exp>>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  section?: InputMaybe<Sections_Bool_Exp>;
  section_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Icons_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "sections_icons" */
export enum Sections_Icons_Constraint {
  /** unique or primary key constraint on columns "id" */
  SectionsIconsPkey = 'sections_icons_pkey',
  /** unique or primary key constraint on columns "section_id", "file_id" */
  SectionsIconsSectionIdIconFileIdKey = 'sections_icons_section_id_icon_file_id_key',
  /** unique or primary key constraint on columns "type", "section_id" */
  SectionsIconsSectionIdTypeKey = 'sections_icons_section_id_type_key'
}

/** input type for inserting data into table "sections_icons" */
export type Sections_Icons_Insert_Input = {
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  section?: InputMaybe<Sections_Obj_Rel_Insert_Input>;
  section_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Icons_Types_Enum>;
};

/** aggregate max on columns */
export type Sections_Icons_Max_Fields = {
  __typename?: 'sections_icons_max_fields';
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "sections_icons" */
export type Sections_Icons_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sections_Icons_Min_Fields = {
  __typename?: 'sections_icons_min_fields';
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  section_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "sections_icons" */
export type Sections_Icons_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  section_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sections_icons" */
export type Sections_Icons_Mutation_Response = {
  __typename?: 'sections_icons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sections_Icons>;
};

/** on_conflict condition type for table "sections_icons" */
export type Sections_Icons_On_Conflict = {
  constraint: Sections_Icons_Constraint;
  update_columns?: Array<Sections_Icons_Update_Column>;
  where?: InputMaybe<Sections_Icons_Bool_Exp>;
};

/** Ordering options when selecting data from "sections_icons". */
export type Sections_Icons_Order_By = {
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  section?: InputMaybe<Sections_Order_By>;
  section_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sections_icons */
export type Sections_Icons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "sections_icons" */
export enum Sections_Icons_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  SectionId = 'section_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "sections_icons" */
export type Sections_Icons_Set_Input = {
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  section_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Icons_Types_Enum>;
};

/** Streaming cursor of the table "sections_icons" */
export type Sections_Icons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sections_Icons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sections_Icons_Stream_Cursor_Value_Input = {
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  section_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Icons_Types_Enum>;
};

/** update columns of table "sections_icons" */
export enum Sections_Icons_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  SectionId = 'section_id',
  /** column name */
  Type = 'type'
}

export type Sections_Icons_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sections_Icons_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sections_Icons_Bool_Exp;
};

/** input type for incrementing numeric columns in table "sections" */
export type Sections_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "sections" */
export type Sections_Insert_Input = {
  contents_sections?: InputMaybe<Contents_Sections_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hex_color?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  intro?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  outputs?: InputMaybe<Outputs_Arr_Rel_Insert_Input>;
  outputs_img?: InputMaybe<Scalars['uuid']>;
  outputs_img_file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  outputs_text?: InputMaybe<Scalars['String']>;
  section_icons_files?: InputMaybe<Sections_Icons_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Sections_Max_Fields = {
  __typename?: 'sections_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  hex_color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  outputs_img?: Maybe<Scalars['uuid']>;
  outputs_text?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Sections_Min_Fields = {
  __typename?: 'sections_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  hex_color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  outputs_img?: Maybe<Scalars['uuid']>;
  outputs_text?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "sections" */
export type Sections_Mutation_Response = {
  __typename?: 'sections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sections>;
};

/** input type for inserting object relation for remote table "sections" */
export type Sections_Obj_Rel_Insert_Input = {
  data: Sections_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sections_On_Conflict>;
};

/** on_conflict condition type for table "sections" */
export type Sections_On_Conflict = {
  constraint: Sections_Constraint;
  update_columns?: Array<Sections_Update_Column>;
  where?: InputMaybe<Sections_Bool_Exp>;
};

/** Ordering options when selecting data from "sections". */
export type Sections_Order_By = {
  contents_sections_aggregate?: InputMaybe<Contents_Sections_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  hex_color?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intro?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  outputs_aggregate?: InputMaybe<Outputs_Aggregate_Order_By>;
  outputs_img?: InputMaybe<Order_By>;
  outputs_img_file?: InputMaybe<Files_Order_By>;
  outputs_text?: InputMaybe<Order_By>;
  section_icons_files_aggregate?: InputMaybe<Sections_Icons_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sections */
export type Sections_Pk_Columns_Input = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};

/** select columns of table "sections" */
export enum Sections_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HexColor = 'hex_color',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  OutputsImg = 'outputs_img',
  /** column name */
  OutputsText = 'outputs_text',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "sections" */
export type Sections_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hex_color?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  intro?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  outputs_img?: InputMaybe<Scalars['uuid']>;
  outputs_text?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Sections_Stddev_Fields = {
  __typename?: 'sections_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Sections_Stddev_Pop_Fields = {
  __typename?: 'sections_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Sections_Stddev_Samp_Fields = {
  __typename?: 'sections_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "sections" */
export type Sections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sections_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  hex_color?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  intro?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  outputs_img?: InputMaybe<Scalars['uuid']>;
  outputs_text?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Sections_Sum_Fields = {
  __typename?: 'sections_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "sections" */
export enum Sections_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HexColor = 'hex_color',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  OutputsImg = 'outputs_img',
  /** column name */
  OutputsText = 'outputs_text',
  /** column name */
  Slug = 'slug'
}

export type Sections_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sections_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sections_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sections_Var_Pop_Fields = {
  __typename?: 'sections_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Sections_Var_Samp_Fields = {
  __typename?: 'sections_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Sections_Variance_Fields = {
  __typename?: 'sections_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

/** columns and relationships of "social_medias" */
export type Social_Medias = {
  __typename?: 'social_medias';
  created_at: Scalars['timestamptz'];
  facebook?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  instagram?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  /** An array relationship */
  teams: Array<Teams>;
  /** An aggregate relationship */
  teams_aggregate: Teams_Aggregate;
  twitter?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  youtube?: Maybe<Scalars['String']>;
};


/** columns and relationships of "social_medias" */
export type Social_MediasTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


/** columns and relationships of "social_medias" */
export type Social_MediasTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


/** columns and relationships of "social_medias" */
export type Social_MediasUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "social_medias" */
export type Social_MediasUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "social_medias" */
export type Social_Medias_Aggregate = {
  __typename?: 'social_medias_aggregate';
  aggregate?: Maybe<Social_Medias_Aggregate_Fields>;
  nodes: Array<Social_Medias>;
};

/** aggregate fields of "social_medias" */
export type Social_Medias_Aggregate_Fields = {
  __typename?: 'social_medias_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Social_Medias_Max_Fields>;
  min?: Maybe<Social_Medias_Min_Fields>;
};


/** aggregate fields of "social_medias" */
export type Social_Medias_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Social_Medias_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "social_medias". All fields are combined with a logical 'AND'. */
export type Social_Medias_Bool_Exp = {
  _and?: InputMaybe<Array<Social_Medias_Bool_Exp>>;
  _not?: InputMaybe<Social_Medias_Bool_Exp>;
  _or?: InputMaybe<Array<Social_Medias_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  facebook?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  instagram?: InputMaybe<String_Comparison_Exp>;
  linkedin?: InputMaybe<String_Comparison_Exp>;
  other?: InputMaybe<String_Comparison_Exp>;
  teams?: InputMaybe<Teams_Bool_Exp>;
  teams_aggregate?: InputMaybe<Teams_Aggregate_Bool_Exp>;
  twitter?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
  users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  youtube?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "social_medias" */
export enum Social_Medias_Constraint {
  /** unique or primary key constraint on columns "id" */
  SocialMediasPkey = 'social_medias_pkey'
}

/** input type for inserting data into table "social_medias" */
export type Social_Medias_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  facebook?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  instagram?: InputMaybe<Scalars['String']>;
  linkedin?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Teams_Arr_Rel_Insert_Input>;
  twitter?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  youtube?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Social_Medias_Max_Fields = {
  __typename?: 'social_medias_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  facebook?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instagram?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  youtube?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Social_Medias_Min_Fields = {
  __typename?: 'social_medias_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  facebook?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  instagram?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  youtube?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "social_medias" */
export type Social_Medias_Mutation_Response = {
  __typename?: 'social_medias_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Social_Medias>;
};

/** input type for inserting object relation for remote table "social_medias" */
export type Social_Medias_Obj_Rel_Insert_Input = {
  data: Social_Medias_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Social_Medias_On_Conflict>;
};

/** on_conflict condition type for table "social_medias" */
export type Social_Medias_On_Conflict = {
  constraint: Social_Medias_Constraint;
  update_columns?: Array<Social_Medias_Update_Column>;
  where?: InputMaybe<Social_Medias_Bool_Exp>;
};

/** Ordering options when selecting data from "social_medias". */
export type Social_Medias_Order_By = {
  created_at?: InputMaybe<Order_By>;
  facebook?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instagram?: InputMaybe<Order_By>;
  linkedin?: InputMaybe<Order_By>;
  other?: InputMaybe<Order_By>;
  teams_aggregate?: InputMaybe<Teams_Aggregate_Order_By>;
  twitter?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  youtube?: InputMaybe<Order_By>;
};

/** primary key columns input for table: social_medias */
export type Social_Medias_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "social_medias" */
export enum Social_Medias_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  Id = 'id',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Linkedin = 'linkedin',
  /** column name */
  Other = 'other',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Youtube = 'youtube'
}

/** input type for updating data in table "social_medias" */
export type Social_Medias_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  facebook?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  instagram?: InputMaybe<Scalars['String']>;
  linkedin?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  youtube?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "social_medias" */
export type Social_Medias_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Social_Medias_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Social_Medias_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  facebook?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  instagram?: InputMaybe<Scalars['String']>;
  linkedin?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  youtube?: InputMaybe<Scalars['String']>;
};

/** update columns of table "social_medias" */
export enum Social_Medias_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  Id = 'id',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Linkedin = 'linkedin',
  /** column name */
  Other = 'other',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Youtube = 'youtube'
}

export type Social_Medias_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Social_Medias_Set_Input>;
  /** filter the rows which have to be updated */
  where: Social_Medias_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "available_mentoring_dates" */
  available_mentoring_dates: Array<Available_Mentoring_Dates>;
  /** fetch aggregated fields from the table: "available_mentoring_dates" */
  available_mentoring_dates_aggregate: Available_Mentoring_Dates_Aggregate;
  /** fetch data from the table: "available_mentoring_dates" using primary key columns */
  available_mentoring_dates_by_pk?: Maybe<Available_Mentoring_Dates>;
  /** fetch data from the table in a streaming manner: "available_mentoring_dates" */
  available_mentoring_dates_stream: Array<Available_Mentoring_Dates>;
  /** fetch data from the table: "contents" */
  contents: Array<Contents>;
  /** fetch aggregated fields from the table: "contents" */
  contents_aggregate: Contents_Aggregate;
  /** fetch data from the table: "contents" using primary key columns */
  contents_by_pk?: Maybe<Contents>;
  /** An array relationship */
  contents_sections: Array<Contents_Sections>;
  /** An aggregate relationship */
  contents_sections_aggregate: Contents_Sections_Aggregate;
  /** fetch data from the table: "contents_sections" using primary key columns */
  contents_sections_by_pk?: Maybe<Contents_Sections>;
  /** fetch data from the table in a streaming manner: "contents_sections" */
  contents_sections_stream: Array<Contents_Sections>;
  /** fetch data from the table in a streaming manner: "contents" */
  contents_stream: Array<Contents>;
  createUser?: Maybe<CreateUser>;
  /** createUserPreregistered */
  createUserPreregistered?: Maybe<CreateUserPreregistered>;
  /** fetch data from the table: "diagnostics" */
  diagnostics: Array<Diagnostics>;
  /** fetch aggregated fields from the table: "diagnostics" */
  diagnostics_aggregate: Diagnostics_Aggregate;
  /** fetch data from the table: "diagnostics" using primary key columns */
  diagnostics_by_pk?: Maybe<Diagnostics>;
  /** fetch data from the table: "diagnostics_comments" */
  diagnostics_comments: Array<Diagnostics_Comments>;
  /** fetch aggregated fields from the table: "diagnostics_comments" */
  diagnostics_comments_aggregate: Diagnostics_Comments_Aggregate;
  /** fetch data from the table: "diagnostics_comments" using primary key columns */
  diagnostics_comments_by_pk?: Maybe<Diagnostics_Comments>;
  /** fetch data from the table in a streaming manner: "diagnostics_comments" */
  diagnostics_comments_stream: Array<Diagnostics_Comments>;
  /** fetch data from the table: "diagnostics_groups" */
  diagnostics_groups: Array<Diagnostics_Groups>;
  /** fetch aggregated fields from the table: "diagnostics_groups" */
  diagnostics_groups_aggregate: Diagnostics_Groups_Aggregate;
  /** fetch data from the table: "diagnostics_groups" using primary key columns */
  diagnostics_groups_by_pk?: Maybe<Diagnostics_Groups>;
  /** fetch data from the table in a streaming manner: "diagnostics_groups" */
  diagnostics_groups_stream: Array<Diagnostics_Groups>;
  /** fetch data from the table: "diagnostics_questions" */
  diagnostics_questions: Array<Diagnostics_Questions>;
  /** fetch aggregated fields from the table: "diagnostics_questions" */
  diagnostics_questions_aggregate: Diagnostics_Questions_Aggregate;
  /** fetch data from the table: "diagnostics_questions_answers" */
  diagnostics_questions_answers: Array<Diagnostics_Questions_Answers>;
  /** fetch aggregated fields from the table: "diagnostics_questions_answers" */
  diagnostics_questions_answers_aggregate: Diagnostics_Questions_Answers_Aggregate;
  /** fetch data from the table: "diagnostics_questions_answers" using primary key columns */
  diagnostics_questions_answers_by_pk?: Maybe<Diagnostics_Questions_Answers>;
  /** fetch data from the table in a streaming manner: "diagnostics_questions_answers" */
  diagnostics_questions_answers_stream: Array<Diagnostics_Questions_Answers>;
  /** fetch data from the table: "diagnostics_questions" using primary key columns */
  diagnostics_questions_by_pk?: Maybe<Diagnostics_Questions>;
  /** fetch data from the table in a streaming manner: "diagnostics_questions" */
  diagnostics_questions_stream: Array<Diagnostics_Questions>;
  /** fetch data from the table: "diagnostics_sections" */
  diagnostics_sections: Array<Diagnostics_Sections>;
  /** fetch aggregated fields from the table: "diagnostics_sections" */
  diagnostics_sections_aggregate: Diagnostics_Sections_Aggregate;
  /** fetch data from the table: "diagnostics_sections" using primary key columns */
  diagnostics_sections_by_pk?: Maybe<Diagnostics_Sections>;
  /** fetch data from the table in a streaming manner: "diagnostics_sections" */
  diagnostics_sections_stream: Array<Diagnostics_Sections>;
  /** fetch data from the table in a streaming manner: "diagnostics" */
  diagnostics_stream: Array<Diagnostics>;
  /** fetch data from the table: "diagnostics_subsections" */
  diagnostics_subsections: Array<Diagnostics_Subsections>;
  /** fetch aggregated fields from the table: "diagnostics_subsections" */
  diagnostics_subsections_aggregate: Diagnostics_Subsections_Aggregate;
  /** fetch data from the table: "diagnostics_subsections" using primary key columns */
  diagnostics_subsections_by_pk?: Maybe<Diagnostics_Subsections>;
  /** fetch data from the table in a streaming manner: "diagnostics_subsections" */
  diagnostics_subsections_stream: Array<Diagnostics_Subsections>;
  /** fetch data from the table: "diagnostics_tabs" */
  diagnostics_tabs: Array<Diagnostics_Tabs>;
  /** fetch aggregated fields from the table: "diagnostics_tabs" */
  diagnostics_tabs_aggregate: Diagnostics_Tabs_Aggregate;
  /** fetch data from the table: "diagnostics_tabs" using primary key columns */
  diagnostics_tabs_by_pk?: Maybe<Diagnostics_Tabs>;
  /** fetch data from the table: "diagnostics_tabs_sections" */
  diagnostics_tabs_sections: Array<Diagnostics_Tabs_Sections>;
  /** fetch aggregated fields from the table: "diagnostics_tabs_sections" */
  diagnostics_tabs_sections_aggregate: Diagnostics_Tabs_Sections_Aggregate;
  /** fetch data from the table: "diagnostics_tabs_sections" using primary key columns */
  diagnostics_tabs_sections_by_pk?: Maybe<Diagnostics_Tabs_Sections>;
  /** fetch data from the table in a streaming manner: "diagnostics_tabs_sections" */
  diagnostics_tabs_sections_stream: Array<Diagnostics_Tabs_Sections>;
  /** fetch data from the table in a streaming manner: "diagnostics_tabs" */
  diagnostics_tabs_stream: Array<Diagnostics_Tabs>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** fetch data from the table in a streaming manner: "files" */
  files_stream: Array<Files>;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  /** fetch data from the table: "groups_contents" */
  groups_contents: Array<Groups_Contents>;
  /** fetch aggregated fields from the table: "groups_contents" */
  groups_contents_aggregate: Groups_Contents_Aggregate;
  /** fetch data from the table: "groups_contents" using primary key columns */
  groups_contents_by_pk?: Maybe<Groups_Contents>;
  /** fetch data from the table in a streaming manner: "groups_contents" */
  groups_contents_stream: Array<Groups_Contents>;
  /** fetch data from the table: "groups_flags" */
  groups_flags: Array<Groups_Flags>;
  /** fetch aggregated fields from the table: "groups_flags" */
  groups_flags_aggregate: Groups_Flags_Aggregate;
  /** fetch data from the table: "groups_flags" using primary key columns */
  groups_flags_by_pk?: Maybe<Groups_Flags>;
  /** fetch data from the table in a streaming manner: "groups_flags" */
  groups_flags_stream: Array<Groups_Flags>;
  /** fetch data from the table: "groups_group_flags" */
  groups_group_flags: Array<Groups_Group_Flags>;
  /** fetch aggregated fields from the table: "groups_group_flags" */
  groups_group_flags_aggregate: Groups_Group_Flags_Aggregate;
  /** fetch data from the table: "groups_group_flags" using primary key columns */
  groups_group_flags_by_pk?: Maybe<Groups_Group_Flags>;
  /** fetch data from the table in a streaming manner: "groups_group_flags" */
  groups_group_flags_stream: Array<Groups_Group_Flags>;
  /** fetch data from the table: "groups_names" */
  groups_names: Array<Groups_Names>;
  /** fetch aggregated fields from the table: "groups_names" */
  groups_names_aggregate: Groups_Names_Aggregate;
  /** fetch data from the table: "groups_names" using primary key columns */
  groups_names_by_pk?: Maybe<Groups_Names>;
  /** fetch data from the table in a streaming manner: "groups_names" */
  groups_names_stream: Array<Groups_Names>;
  /** fetch data from the table in a streaming manner: "groups" */
  groups_stream: Array<Groups>;
  /** fetch data from the table: "groups_teams" */
  groups_teams: Array<Groups_Teams>;
  /** fetch aggregated fields from the table: "groups_teams" */
  groups_teams_aggregate: Groups_Teams_Aggregate;
  /** fetch data from the table: "groups_teams" using primary key columns */
  groups_teams_by_pk?: Maybe<Groups_Teams>;
  /** fetch data from the table in a streaming manner: "groups_teams" */
  groups_teams_stream: Array<Groups_Teams>;
  /** fetch data from the table: "icons_types" */
  icons_types: Array<Icons_Types>;
  /** fetch aggregated fields from the table: "icons_types" */
  icons_types_aggregate: Icons_Types_Aggregate;
  /** fetch data from the table: "icons_types" using primary key columns */
  icons_types_by_pk?: Maybe<Icons_Types>;
  /** fetch data from the table in a streaming manner: "icons_types" */
  icons_types_stream: Array<Icons_Types>;
  /** fetch data from the table: "invite_states" */
  invite_states: Array<Invite_States>;
  /** fetch aggregated fields from the table: "invite_states" */
  invite_states_aggregate: Invite_States_Aggregate;
  /** fetch data from the table: "invite_states" using primary key columns */
  invite_states_by_pk?: Maybe<Invite_States>;
  /** fetch data from the table in a streaming manner: "invite_states" */
  invite_states_stream: Array<Invite_States>;
  /** fetch data from the table: "kanban_columns" */
  kanban_columns: Array<Kanban_Columns>;
  /** fetch aggregated fields from the table: "kanban_columns" */
  kanban_columns_aggregate: Kanban_Columns_Aggregate;
  /** fetch data from the table: "kanban_columns" using primary key columns */
  kanban_columns_by_pk?: Maybe<Kanban_Columns>;
  /** fetch data from the table in a streaming manner: "kanban_columns" */
  kanban_columns_stream: Array<Kanban_Columns>;
  /** fetch data from the table: "kanbans" */
  kanbans: Array<Kanbans>;
  /** fetch aggregated fields from the table: "kanbans" */
  kanbans_aggregate: Kanbans_Aggregate;
  /** fetch data from the table: "kanbans" using primary key columns */
  kanbans_by_pk?: Maybe<Kanbans>;
  /** fetch data from the table: "kanbans_cards" */
  kanbans_cards: Array<Kanbans_Cards>;
  /** fetch aggregated fields from the table: "kanbans_cards" */
  kanbans_cards_aggregate: Kanbans_Cards_Aggregate;
  /** fetch data from the table: "kanbans_cards_assignations" */
  kanbans_cards_assignations: Array<Kanbans_Cards_Assignations>;
  /** fetch aggregated fields from the table: "kanbans_cards_assignations" */
  kanbans_cards_assignations_aggregate: Kanbans_Cards_Assignations_Aggregate;
  /** fetch data from the table: "kanbans_cards_assignations" using primary key columns */
  kanbans_cards_assignations_by_pk?: Maybe<Kanbans_Cards_Assignations>;
  /** fetch data from the table in a streaming manner: "kanbans_cards_assignations" */
  kanbans_cards_assignations_stream: Array<Kanbans_Cards_Assignations>;
  /** fetch data from the table: "kanbans_cards_attachments" */
  kanbans_cards_attachments: Array<Kanbans_Cards_Attachments>;
  /** fetch aggregated fields from the table: "kanbans_cards_attachments" */
  kanbans_cards_attachments_aggregate: Kanbans_Cards_Attachments_Aggregate;
  /** fetch data from the table: "kanbans_cards_attachments" using primary key columns */
  kanbans_cards_attachments_by_pk?: Maybe<Kanbans_Cards_Attachments>;
  /** fetch data from the table in a streaming manner: "kanbans_cards_attachments" */
  kanbans_cards_attachments_stream: Array<Kanbans_Cards_Attachments>;
  /** fetch data from the table: "kanbans_cards" using primary key columns */
  kanbans_cards_by_pk?: Maybe<Kanbans_Cards>;
  /** fetch data from the table: "kanbans_cards_comments" */
  kanbans_cards_comments: Array<Kanbans_Cards_Comments>;
  /** fetch aggregated fields from the table: "kanbans_cards_comments" */
  kanbans_cards_comments_aggregate: Kanbans_Cards_Comments_Aggregate;
  /** fetch data from the table: "kanbans_cards_comments" using primary key columns */
  kanbans_cards_comments_by_pk?: Maybe<Kanbans_Cards_Comments>;
  /** fetch data from the table in a streaming manner: "kanbans_cards_comments" */
  kanbans_cards_comments_stream: Array<Kanbans_Cards_Comments>;
  /** fetch data from the table in a streaming manner: "kanbans_cards" */
  kanbans_cards_stream: Array<Kanbans_Cards>;
  /** fetch data from the table in a streaming manner: "kanbans" */
  kanbans_stream: Array<Kanbans>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table: "locations_names" */
  locations_names: Array<Locations_Names>;
  /** fetch aggregated fields from the table: "locations_names" */
  locations_names_aggregate: Locations_Names_Aggregate;
  /** fetch data from the table: "locations_names" using primary key columns */
  locations_names_by_pk?: Maybe<Locations_Names>;
  /** fetch data from the table in a streaming manner: "locations_names" */
  locations_names_stream: Array<Locations_Names>;
  /** fetch data from the table in a streaming manner: "locations" */
  locations_stream: Array<Locations>;
  /** fetch data from the table: "mentor_additional_data" */
  mentor_additional_data: Array<Mentor_Additional_Data>;
  /** fetch aggregated fields from the table: "mentor_additional_data" */
  mentor_additional_data_aggregate: Mentor_Additional_Data_Aggregate;
  /** fetch data from the table: "mentor_additional_data" using primary key columns */
  mentor_additional_data_by_pk?: Maybe<Mentor_Additional_Data>;
  /** fetch data from the table in a streaming manner: "mentor_additional_data" */
  mentor_additional_data_stream: Array<Mentor_Additional_Data>;
  /** fetch data from the table: "mentor_tags" */
  mentor_tags: Array<Mentor_Tags>;
  /** fetch aggregated fields from the table: "mentor_tags" */
  mentor_tags_aggregate: Mentor_Tags_Aggregate;
  /** fetch data from the table: "mentor_tags" using primary key columns */
  mentor_tags_by_pk?: Maybe<Mentor_Tags>;
  /** fetch data from the table in a streaming manner: "mentor_tags" */
  mentor_tags_stream: Array<Mentor_Tags>;
  /** fetch data from the table: "mentoring_records" */
  mentoring_records: Array<Mentoring_Records>;
  /** fetch aggregated fields from the table: "mentoring_records" */
  mentoring_records_aggregate: Mentoring_Records_Aggregate;
  /** An array relationship */
  mentoring_records_attachments: Array<Mentoring_Records_Attachments>;
  /** An aggregate relationship */
  mentoring_records_attachments_aggregate: Mentoring_Records_Attachments_Aggregate;
  /** fetch data from the table: "mentoring_records_attachments" using primary key columns */
  mentoring_records_attachments_by_pk?: Maybe<Mentoring_Records_Attachments>;
  /** fetch data from the table in a streaming manner: "mentoring_records_attachments" */
  mentoring_records_attachments_stream: Array<Mentoring_Records_Attachments>;
  /** fetch data from the table: "mentoring_records" using primary key columns */
  mentoring_records_by_pk?: Maybe<Mentoring_Records>;
  /** fetch data from the table: "mentoring_records_members" */
  mentoring_records_members: Array<Mentoring_Records_Members>;
  /** fetch aggregated fields from the table: "mentoring_records_members" */
  mentoring_records_members_aggregate: Mentoring_Records_Members_Aggregate;
  /** fetch data from the table: "mentoring_records_members" using primary key columns */
  mentoring_records_members_by_pk?: Maybe<Mentoring_Records_Members>;
  /** fetch data from the table in a streaming manner: "mentoring_records_members" */
  mentoring_records_members_stream: Array<Mentoring_Records_Members>;
  /** fetch data from the table in a streaming manner: "mentoring_records" */
  mentoring_records_stream: Array<Mentoring_Records>;
  /** fetch data from the table: "mentoring_reschedules" */
  mentoring_reschedules: Array<Mentoring_Reschedules>;
  /** fetch aggregated fields from the table: "mentoring_reschedules" */
  mentoring_reschedules_aggregate: Mentoring_Reschedules_Aggregate;
  /** fetch data from the table: "mentoring_reschedules" using primary key columns */
  mentoring_reschedules_by_pk?: Maybe<Mentoring_Reschedules>;
  /** fetch data from the table in a streaming manner: "mentoring_reschedules" */
  mentoring_reschedules_stream: Array<Mentoring_Reschedules>;
  /** fetch data from the table: "mentoring_scheduling" */
  mentoring_scheduling: Array<Mentoring_Scheduling>;
  /** fetch aggregated fields from the table: "mentoring_scheduling" */
  mentoring_scheduling_aggregate: Mentoring_Scheduling_Aggregate;
  /** fetch data from the table: "mentoring_scheduling" using primary key columns */
  mentoring_scheduling_by_pk?: Maybe<Mentoring_Scheduling>;
  /** fetch data from the table: "mentoring_scheduling_meeting" */
  mentoring_scheduling_meeting: Array<Mentoring_Scheduling_Meeting>;
  /** fetch aggregated fields from the table: "mentoring_scheduling_meeting" */
  mentoring_scheduling_meeting_aggregate: Mentoring_Scheduling_Meeting_Aggregate;
  /** fetch data from the table: "mentoring_scheduling_meeting" using primary key columns */
  mentoring_scheduling_meeting_by_pk?: Maybe<Mentoring_Scheduling_Meeting>;
  /** fetch data from the table in a streaming manner: "mentoring_scheduling_meeting" */
  mentoring_scheduling_meeting_stream: Array<Mentoring_Scheduling_Meeting>;
  /** An array relationship */
  mentoring_scheduling_members: Array<Mentoring_Scheduling_Members>;
  /** An aggregate relationship */
  mentoring_scheduling_members_aggregate: Mentoring_Scheduling_Members_Aggregate;
  /** fetch data from the table: "mentoring_scheduling_members" using primary key columns */
  mentoring_scheduling_members_by_pk?: Maybe<Mentoring_Scheduling_Members>;
  /** fetch data from the table in a streaming manner: "mentoring_scheduling_members" */
  mentoring_scheduling_members_stream: Array<Mentoring_Scheduling_Members>;
  /** fetch data from the table in a streaming manner: "mentoring_scheduling" */
  mentoring_scheduling_stream: Array<Mentoring_Scheduling>;
  /** fetch data from the table: "mentors" */
  mentors: Array<Mentors>;
  /** fetch aggregated fields from the table: "mentors" */
  mentors_aggregate: Mentors_Aggregate;
  /** fetch data from the table: "mentors_blablabla" */
  mentors_blablabla: Array<Mentors_Blablabla>;
  /** fetch aggregated fields from the table: "mentors_blablabla" */
  mentors_blablabla_aggregate: Mentors_Blablabla_Aggregate;
  /** fetch data from the table: "mentors_blablabla" using primary key columns */
  mentors_blablabla_by_pk?: Maybe<Mentors_Blablabla>;
  /** fetch data from the table in a streaming manner: "mentors_blablabla" */
  mentors_blablabla_stream: Array<Mentors_Blablabla>;
  /** fetch data from the table: "mentors" using primary key columns */
  mentors_by_pk?: Maybe<Mentors>;
  /** fetch data from the table: "mentors_mentor_tags" */
  mentors_mentor_tags: Array<Mentors_Mentor_Tags>;
  /** fetch aggregated fields from the table: "mentors_mentor_tags" */
  mentors_mentor_tags_aggregate: Mentors_Mentor_Tags_Aggregate;
  /** fetch data from the table: "mentors_mentor_tags" using primary key columns */
  mentors_mentor_tags_by_pk?: Maybe<Mentors_Mentor_Tags>;
  /** fetch data from the table in a streaming manner: "mentors_mentor_tags" */
  mentors_mentor_tags_stream: Array<Mentors_Mentor_Tags>;
  /** fetch data from the table in a streaming manner: "mentors" */
  mentors_stream: Array<Mentors>;
  /** fetch data from the table: "metodologies" */
  metodologies: Array<Metodologies>;
  /** fetch aggregated fields from the table: "metodologies" */
  metodologies_aggregate: Metodologies_Aggregate;
  /** fetch data from the table: "metodologies" using primary key columns */
  metodologies_by_pk?: Maybe<Metodologies>;
  /** fetch data from the table in a streaming manner: "metodologies" */
  metodologies_stream: Array<Metodologies>;
  /** fetch data from the table: "multimedia_contents" */
  multimedia_contents: Array<Multimedia_Contents>;
  /** fetch aggregated fields from the table: "multimedia_contents" */
  multimedia_contents_aggregate: Multimedia_Contents_Aggregate;
  /** fetch data from the table: "multimedia_contents" using primary key columns */
  multimedia_contents_by_pk?: Maybe<Multimedia_Contents>;
  /** fetch data from the table: "multimedia_contents_links" */
  multimedia_contents_links: Array<Multimedia_Contents_Links>;
  /** fetch aggregated fields from the table: "multimedia_contents_links" */
  multimedia_contents_links_aggregate: Multimedia_Contents_Links_Aggregate;
  /** fetch data from the table: "multimedia_contents_links" using primary key columns */
  multimedia_contents_links_by_pk?: Maybe<Multimedia_Contents_Links>;
  /** fetch data from the table in a streaming manner: "multimedia_contents_links" */
  multimedia_contents_links_stream: Array<Multimedia_Contents_Links>;
  /** fetch data from the table in a streaming manner: "multimedia_contents" */
  multimedia_contents_stream: Array<Multimedia_Contents>;
  /** An array relationship */
  outputs: Array<Outputs>;
  /** An aggregate relationship */
  outputs_aggregate: Outputs_Aggregate;
  /** fetch data from the table: "outputs" using primary key columns */
  outputs_by_pk?: Maybe<Outputs>;
  /** fetch data from the table: "outputs_icons" */
  outputs_icons: Array<Outputs_Icons>;
  /** fetch aggregated fields from the table: "outputs_icons" */
  outputs_icons_aggregate: Outputs_Icons_Aggregate;
  /** fetch data from the table: "outputs_icons" using primary key columns */
  outputs_icons_by_pk?: Maybe<Outputs_Icons>;
  /** fetch data from the table in a streaming manner: "outputs_icons" */
  outputs_icons_stream: Array<Outputs_Icons>;
  /** fetch data from the table in a streaming manner: "outputs" */
  outputs_stream: Array<Outputs>;
  /** fetch data from the table: "outputs_tools" */
  outputs_tools: Array<Outputs_Tools>;
  /** fetch aggregated fields from the table: "outputs_tools" */
  outputs_tools_aggregate: Outputs_Tools_Aggregate;
  /** fetch data from the table: "outputs_tools" using primary key columns */
  outputs_tools_by_pk?: Maybe<Outputs_Tools>;
  /** fetch data from the table in a streaming manner: "outputs_tools" */
  outputs_tools_stream: Array<Outputs_Tools>;
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** An aggregate relationship */
  questionnaires_aggregate: Questionnaires_Aggregate;
  /** fetch data from the table: "questionnaires" using primary key columns */
  questionnaires_by_pk?: Maybe<Questionnaires>;
  /** fetch data from the table in a streaming manner: "questionnaires" */
  questionnaires_stream: Array<Questionnaires>;
  /** fetch data from the table: "questionnaires_teams_states" */
  questionnaires_teams_states: Array<Questionnaires_Teams_States>;
  /** fetch aggregated fields from the table: "questionnaires_teams_states" */
  questionnaires_teams_states_aggregate: Questionnaires_Teams_States_Aggregate;
  /** fetch data from the table: "questionnaires_teams_states" using primary key columns */
  questionnaires_teams_states_by_pk?: Maybe<Questionnaires_Teams_States>;
  /** fetch data from the table in a streaming manner: "questionnaires_teams_states" */
  questionnaires_teams_states_stream: Array<Questionnaires_Teams_States>;
  /** An array relationship */
  questions: Array<Questions>;
  /** An aggregate relationship */
  questions_aggregate: Questions_Aggregate;
  /** An array relationship */
  questions_answers: Array<Questions_Answers>;
  /** An aggregate relationship */
  questions_answers_aggregate: Questions_Answers_Aggregate;
  /** fetch data from the table: "questions_answers" using primary key columns */
  questions_answers_by_pk?: Maybe<Questions_Answers>;
  /** fetch data from the table in a streaming manner: "questions_answers" */
  questions_answers_stream: Array<Questions_Answers>;
  /** fetch data from the table: "questions" using primary key columns */
  questions_by_pk?: Maybe<Questions>;
  /** fetch data from the table: "questions_options" */
  questions_options: Array<Questions_Options>;
  /** fetch aggregated fields from the table: "questions_options" */
  questions_options_aggregate: Questions_Options_Aggregate;
  /** fetch data from the table: "questions_options" using primary key columns */
  questions_options_by_pk?: Maybe<Questions_Options>;
  /** fetch data from the table in a streaming manner: "questions_options" */
  questions_options_stream: Array<Questions_Options>;
  /** fetch data from the table in a streaming manner: "questions" */
  questions_stream: Array<Questions>;
  /** fetch data from the table: "questions_types" */
  questions_types: Array<Questions_Types>;
  /** fetch aggregated fields from the table: "questions_types" */
  questions_types_aggregate: Questions_Types_Aggregate;
  /** fetch data from the table: "questions_types" using primary key columns */
  questions_types_by_pk?: Maybe<Questions_Types>;
  /** fetch data from the table in a streaming manner: "questions_types" */
  questions_types_stream: Array<Questions_Types>;
  /** An array relationship */
  repositories: Array<Repositories>;
  /** An aggregate relationship */
  repositories_aggregate: Repositories_Aggregate;
  /** fetch data from the table: "repositories" using primary key columns */
  repositories_by_pk?: Maybe<Repositories>;
  /** fetch data from the table in a streaming manner: "repositories" */
  repositories_stream: Array<Repositories>;
  /** fetch data from the table: "requested_mentorings" */
  requested_mentorings: Array<Requested_Mentorings>;
  /** fetch aggregated fields from the table: "requested_mentorings" */
  requested_mentorings_aggregate: Requested_Mentorings_Aggregate;
  /** fetch data from the table: "requested_mentorings" using primary key columns */
  requested_mentorings_by_pk?: Maybe<Requested_Mentorings>;
  /** fetch data from the table: "requested_mentorings_status" */
  requested_mentorings_status: Array<Requested_Mentorings_Status>;
  /** fetch aggregated fields from the table: "requested_mentorings_status" */
  requested_mentorings_status_aggregate: Requested_Mentorings_Status_Aggregate;
  /** fetch data from the table: "requested_mentorings_status" using primary key columns */
  requested_mentorings_status_by_pk?: Maybe<Requested_Mentorings_Status>;
  /** fetch data from the table in a streaming manner: "requested_mentorings_status" */
  requested_mentorings_status_stream: Array<Requested_Mentorings_Status>;
  /** fetch data from the table in a streaming manner: "requested_mentorings" */
  requested_mentorings_stream: Array<Requested_Mentorings>;
  /** An array relationship */
  requests_mentor_tags: Array<Requests_Mentor_Tags>;
  /** An aggregate relationship */
  requests_mentor_tags_aggregate: Requests_Mentor_Tags_Aggregate;
  /** fetch data from the table: "requests_mentor_tags" using primary key columns */
  requests_mentor_tags_by_pk?: Maybe<Requests_Mentor_Tags>;
  /** fetch data from the table in a streaming manner: "requests_mentor_tags" */
  requests_mentor_tags_stream: Array<Requests_Mentor_Tags>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table in a streaming manner: "roles" */
  roles_stream: Array<Roles>;
  /** fetch data from the table: "sections" */
  sections: Array<Sections>;
  /** fetch aggregated fields from the table: "sections" */
  sections_aggregate: Sections_Aggregate;
  /** fetch data from the table: "sections" using primary key columns */
  sections_by_pk?: Maybe<Sections>;
  /** fetch data from the table: "sections_icons" */
  sections_icons: Array<Sections_Icons>;
  /** fetch aggregated fields from the table: "sections_icons" */
  sections_icons_aggregate: Sections_Icons_Aggregate;
  /** fetch data from the table: "sections_icons" using primary key columns */
  sections_icons_by_pk?: Maybe<Sections_Icons>;
  /** fetch data from the table in a streaming manner: "sections_icons" */
  sections_icons_stream: Array<Sections_Icons>;
  /** fetch data from the table in a streaming manner: "sections" */
  sections_stream: Array<Sections>;
  /** fetch data from the table: "social_medias" */
  social_medias: Array<Social_Medias>;
  /** fetch aggregated fields from the table: "social_medias" */
  social_medias_aggregate: Social_Medias_Aggregate;
  /** fetch data from the table: "social_medias" using primary key columns */
  social_medias_by_pk?: Maybe<Social_Medias>;
  /** fetch data from the table in a streaming manner: "social_medias" */
  social_medias_stream: Array<Social_Medias>;
  /** fetch data from the table: "tag_conversion" */
  tag_conversion: Array<Tag_Conversion>;
  /** fetch aggregated fields from the table: "tag_conversion" */
  tag_conversion_aggregate: Tag_Conversion_Aggregate;
  /** fetch data from the table: "tag_conversion" using primary key columns */
  tag_conversion_by_pk?: Maybe<Tag_Conversion>;
  /** fetch data from the table in a streaming manner: "tag_conversion" */
  tag_conversion_stream: Array<Tag_Conversion>;
  /** fetch data from the table: "tags" */
  tags: Array<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table in a streaming manner: "tags" */
  tags_stream: Array<Tags>;
  /** fetch data from the table: "team_invites" */
  team_invites: Array<Team_Invites>;
  /** fetch aggregated fields from the table: "team_invites" */
  team_invites_aggregate: Team_Invites_Aggregate;
  /** fetch data from the table: "team_invites" using primary key columns */
  team_invites_by_pk?: Maybe<Team_Invites>;
  /** fetch data from the table in a streaming manner: "team_invites" */
  team_invites_stream: Array<Team_Invites>;
  /** An array relationship */
  teams: Array<Teams>;
  /** An aggregate relationship */
  teams_aggregate: Teams_Aggregate;
  /** fetch data from the table: "teams" using primary key columns */
  teams_by_pk?: Maybe<Teams>;
  /** fetch data from the table: "teams_names" */
  teams_names: Array<Teams_Names>;
  /** fetch aggregated fields from the table: "teams_names" */
  teams_names_aggregate: Teams_Names_Aggregate;
  /** fetch data from the table: "teams_names" using primary key columns */
  teams_names_by_pk?: Maybe<Teams_Names>;
  /** fetch data from the table in a streaming manner: "teams_names" */
  teams_names_stream: Array<Teams_Names>;
  /** fetch data from the table: "teams_scores" */
  teams_scores: Array<Teams_Scores>;
  /** fetch aggregated fields from the table: "teams_scores" */
  teams_scores_aggregate: Teams_Scores_Aggregate;
  /** fetch data from the table in a streaming manner: "teams_scores" */
  teams_scores_stream: Array<Teams_Scores>;
  /** fetch data from the table in a streaming manner: "teams" */
  teams_stream: Array<Teams>;
  /** fetch data from the table: "teams_tags" */
  teams_tags: Array<Teams_Tags>;
  /** fetch aggregated fields from the table: "teams_tags" */
  teams_tags_aggregate: Teams_Tags_Aggregate;
  /** fetch data from the table: "teams_tags" using primary key columns */
  teams_tags_by_pk?: Maybe<Teams_Tags>;
  /** fetch data from the table in a streaming manner: "teams_tags" */
  teams_tags_stream: Array<Teams_Tags>;
  /** fetch data from the table: "teams_users" */
  teams_users: Array<Teams_Users>;
  /** fetch aggregated fields from the table: "teams_users" */
  teams_users_aggregate: Teams_Users_Aggregate;
  /** fetch data from the table: "teams_users" using primary key columns */
  teams_users_by_pk?: Maybe<Teams_Users>;
  /** fetch data from the table in a streaming manner: "teams_users" */
  teams_users_stream: Array<Teams_Users>;
  /** fetch data from the table: "tools" */
  tools: Array<Tools>;
  /** fetch aggregated fields from the table: "tools" */
  tools_aggregate: Tools_Aggregate;
  /** fetch data from the table: "tools" using primary key columns */
  tools_by_pk?: Maybe<Tools>;
  /** fetch data from the table: "tools_icons" */
  tools_icons: Array<Tools_Icons>;
  /** fetch aggregated fields from the table: "tools_icons" */
  tools_icons_aggregate: Tools_Icons_Aggregate;
  /** fetch data from the table: "tools_icons" using primary key columns */
  tools_icons_by_pk?: Maybe<Tools_Icons>;
  /** fetch data from the table in a streaming manner: "tools_icons" */
  tools_icons_stream: Array<Tools_Icons>;
  /** fetch data from the table in a streaming manner: "tools" */
  tools_stream: Array<Tools>;
  /** fetch data from the table: "user_info" */
  user_info: Array<User_Info>;
  /** fetch aggregated fields from the table: "user_info" */
  user_info_aggregate: User_Info_Aggregate;
  /** fetch data from the table: "user_info" using primary key columns */
  user_info_by_pk?: Maybe<User_Info>;
  /** fetch data from the table in a streaming manner: "user_info" */
  user_info_stream: Array<User_Info>;
  /** fetch data from the table: "user_invites" */
  user_invites: Array<User_Invites>;
  /** fetch aggregated fields from the table: "user_invites" */
  user_invites_aggregate: User_Invites_Aggregate;
  /** fetch data from the table: "user_invites" using primary key columns */
  user_invites_by_pk?: Maybe<User_Invites>;
  /** fetch data from the table in a streaming manner: "user_invites" */
  user_invites_stream: Array<User_Invites>;
  /** An array relationship */
  users: Array<Users>;
  /** fetch data from the table: "users_actions" */
  users_actions: Array<Users_Actions>;
  /** fetch aggregated fields from the table: "users_actions" */
  users_actions_aggregate: Users_Actions_Aggregate;
  /** fetch data from the table: "users_actions" using primary key columns */
  users_actions_by_pk?: Maybe<Users_Actions>;
  /** fetch data from the table in a streaming manner: "users_actions" */
  users_actions_stream: Array<Users_Actions>;
  /** fetch data from the table: "users_actions_types" */
  users_actions_types: Array<Users_Actions_Types>;
  /** fetch aggregated fields from the table: "users_actions_types" */
  users_actions_types_aggregate: Users_Actions_Types_Aggregate;
  /** fetch data from the table: "users_actions_types" using primary key columns */
  users_actions_types_by_pk?: Maybe<Users_Actions_Types>;
  /** fetch data from the table in a streaming manner: "users_actions_types" */
  users_actions_types_stream: Array<Users_Actions_Types>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_documents" */
  users_documents: Array<Users_Documents>;
  /** fetch aggregated fields from the table: "users_documents" */
  users_documents_aggregate: Users_Documents_Aggregate;
  /** fetch data from the table: "users_documents" using primary key columns */
  users_documents_by_pk?: Maybe<Users_Documents>;
  /** fetch data from the table in a streaming manner: "users_documents" */
  users_documents_stream: Array<Users_Documents>;
  /** fetch data from the table: "users_documents_types" */
  users_documents_types: Array<Users_Documents_Types>;
  /** fetch aggregated fields from the table: "users_documents_types" */
  users_documents_types_aggregate: Users_Documents_Types_Aggregate;
  /** fetch data from the table: "users_documents_types" using primary key columns */
  users_documents_types_by_pk?: Maybe<Users_Documents_Types>;
  /** fetch data from the table in a streaming manner: "users_documents_types" */
  users_documents_types_stream: Array<Users_Documents_Types>;
  /** fetch data from the table: "users_names" */
  users_names: Array<Users_Names>;
  /** fetch aggregated fields from the table: "users_names" */
  users_names_aggregate: Users_Names_Aggregate;
  /** fetch data from the table: "users_names" using primary key columns */
  users_names_by_pk?: Maybe<Users_Names>;
  /** fetch data from the table in a streaming manner: "users_names" */
  users_names_stream: Array<Users_Names>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** An array relationship */
  video_repositories: Array<Video_Repositories>;
  /** An aggregate relationship */
  video_repositories_aggregate: Video_Repositories_Aggregate;
  /** fetch data from the table: "video_repositories" using primary key columns */
  video_repositories_by_pk?: Maybe<Video_Repositories>;
  /** fetch data from the table in a streaming manner: "video_repositories" */
  video_repositories_stream: Array<Video_Repositories>;
  /** An array relationship */
  videos: Array<Videos>;
  /** An aggregate relationship */
  videos_aggregate: Videos_Aggregate;
  /** fetch data from the table: "videos" using primary key columns */
  videos_by_pk?: Maybe<Videos>;
  /** execute function "videos_by_tags" which returns "videos" */
  videos_by_tags: Array<Videos>;
  /** execute function "videos_by_tags" and query aggregates on result of table type "videos" */
  videos_by_tags_aggregate: Videos_Aggregate;
  /** fetch data from the table in a streaming manner: "videos" */
  videos_stream: Array<Videos>;
  /** fetch data from the table: "videos_youtube_tags" */
  videos_youtube_tags: Array<Videos_Youtube_Tags>;
  /** fetch aggregated fields from the table: "videos_youtube_tags" */
  videos_youtube_tags_aggregate: Videos_Youtube_Tags_Aggregate;
  /** fetch data from the table: "videos_youtube_tags" using primary key columns */
  videos_youtube_tags_by_pk?: Maybe<Videos_Youtube_Tags>;
  /** fetch data from the table in a streaming manner: "videos_youtube_tags" */
  videos_youtube_tags_stream: Array<Videos_Youtube_Tags>;
  /** fetch data from the table: "youtube_tags" */
  youtube_tags: Array<Youtube_Tags>;
  /** fetch aggregated fields from the table: "youtube_tags" */
  youtube_tags_aggregate: Youtube_Tags_Aggregate;
  /** fetch data from the table: "youtube_tags" using primary key columns */
  youtube_tags_by_pk?: Maybe<Youtube_Tags>;
  /** fetch data from the table in a streaming manner: "youtube_tags" */
  youtube_tags_stream: Array<Youtube_Tags>;
};


export type Subscription_RootAvailable_Mentoring_DatesArgs = {
  distinct_on?: InputMaybe<Array<Available_Mentoring_Dates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Available_Mentoring_Dates_Order_By>>;
  where?: InputMaybe<Available_Mentoring_Dates_Bool_Exp>;
};


export type Subscription_RootAvailable_Mentoring_Dates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Available_Mentoring_Dates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Available_Mentoring_Dates_Order_By>>;
  where?: InputMaybe<Available_Mentoring_Dates_Bool_Exp>;
};


export type Subscription_RootAvailable_Mentoring_Dates_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAvailable_Mentoring_Dates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Available_Mentoring_Dates_Stream_Cursor_Input>>;
  where?: InputMaybe<Available_Mentoring_Dates_Bool_Exp>;
};


export type Subscription_RootContentsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Subscription_RootContents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Order_By>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Subscription_RootContents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootContents_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


export type Subscription_RootContents_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contents_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contents_Sections_Order_By>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


export type Subscription_RootContents_Sections_By_PkArgs = {
  content_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
};


export type Subscription_RootContents_Sections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contents_Sections_Stream_Cursor_Input>>;
  where?: InputMaybe<Contents_Sections_Bool_Exp>;
};


export type Subscription_RootContents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contents_Stream_Cursor_Input>>;
  where?: InputMaybe<Contents_Bool_Exp>;
};


export type Subscription_RootCreateUserArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCreateUserPreregisteredArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiagnosticsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


export type Subscription_RootDiagnostics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Order_By>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


export type Subscription_RootDiagnostics_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Comments_Order_By>>;
  where?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Comments_Order_By>>;
  where?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Comments_By_PkArgs = {
  diagnostic_tab_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_Comments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Diagnostics_Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Diagnostics_Comments_Bool_Exp>;
};


export type Subscription_RootDiagnostics_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Groups_Order_By>>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Groups_Order_By>>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Groups_By_PkArgs = {
  diagnostic_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Diagnostics_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Diagnostics_Groups_Bool_Exp>;
};


export type Subscription_RootDiagnostics_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Questions_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Answers_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Questions_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Answers_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Questions_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_Questions_Answers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Diagnostics_Questions_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_Questions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Diagnostics_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Diagnostics_Questions_Bool_Exp>;
};


export type Subscription_RootDiagnostics_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Sections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_Sections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Diagnostics_Sections_Stream_Cursor_Input>>;
  where?: InputMaybe<Diagnostics_Sections_Bool_Exp>;
};


export type Subscription_RootDiagnostics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Diagnostics_Stream_Cursor_Input>>;
  where?: InputMaybe<Diagnostics_Bool_Exp>;
};


export type Subscription_RootDiagnostics_SubsectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Subsections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Subsections_Order_By>>;
  where?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Subsections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Subsections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Subsections_Order_By>>;
  where?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Subsections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_Subsections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Diagnostics_Subsections_Stream_Cursor_Input>>;
  where?: InputMaybe<Diagnostics_Subsections_Bool_Exp>;
};


export type Subscription_RootDiagnostics_TabsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Tabs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Tabs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_Tabs_SectionsArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Tabs_Sections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Tabs_Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Tabs_Sections_Order_By>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Tabs_Sections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDiagnostics_Tabs_Sections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Diagnostics_Tabs_Sections_Stream_Cursor_Input>>;
  where?: InputMaybe<Diagnostics_Tabs_Sections_Bool_Exp>;
};


export type Subscription_RootDiagnostics_Tabs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Diagnostics_Tabs_Stream_Cursor_Input>>;
  where?: InputMaybe<Diagnostics_Tabs_Bool_Exp>;
};


export type Subscription_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Subscription_RootGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Subscription_RootGroups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGroups_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


export type Subscription_RootGroups_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Contents_Order_By>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


export type Subscription_RootGroups_Contents_By_PkArgs = {
  content_id: Scalars['uuid'];
  group_id: Scalars['uuid'];
};


export type Subscription_RootGroups_Contents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Groups_Contents_Stream_Cursor_Input>>;
  where?: InputMaybe<Groups_Contents_Bool_Exp>;
};


export type Subscription_RootGroups_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Flags_Order_By>>;
  where?: InputMaybe<Groups_Flags_Bool_Exp>;
};


export type Subscription_RootGroups_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Flags_Order_By>>;
  where?: InputMaybe<Groups_Flags_Bool_Exp>;
};


export type Subscription_RootGroups_Flags_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootGroups_Flags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Groups_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Groups_Flags_Bool_Exp>;
};


export type Subscription_RootGroups_Group_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Group_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Group_Flags_Order_By>>;
  where?: InputMaybe<Groups_Group_Flags_Bool_Exp>;
};


export type Subscription_RootGroups_Group_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Group_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Group_Flags_Order_By>>;
  where?: InputMaybe<Groups_Group_Flags_Bool_Exp>;
};


export type Subscription_RootGroups_Group_Flags_By_PkArgs = {
  flag: Groups_Flags_Enum;
  group_id: Scalars['uuid'];
};


export type Subscription_RootGroups_Group_Flags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Groups_Group_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Groups_Group_Flags_Bool_Exp>;
};


export type Subscription_RootGroups_NamesArgs = {
  distinct_on?: InputMaybe<Array<Groups_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Names_Order_By>>;
  where?: InputMaybe<Groups_Names_Bool_Exp>;
};


export type Subscription_RootGroups_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Names_Order_By>>;
  where?: InputMaybe<Groups_Names_Bool_Exp>;
};


export type Subscription_RootGroups_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGroups_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Groups_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Groups_Names_Bool_Exp>;
};


export type Subscription_RootGroups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Subscription_RootGroups_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};


export type Subscription_RootGroups_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};


export type Subscription_RootGroups_Teams_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGroups_Teams_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Groups_Teams_Stream_Cursor_Input>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};


export type Subscription_RootIcons_TypesArgs = {
  distinct_on?: InputMaybe<Array<Icons_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icons_Types_Order_By>>;
  where?: InputMaybe<Icons_Types_Bool_Exp>;
};


export type Subscription_RootIcons_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Icons_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Icons_Types_Order_By>>;
  where?: InputMaybe<Icons_Types_Bool_Exp>;
};


export type Subscription_RootIcons_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootIcons_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Icons_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Icons_Types_Bool_Exp>;
};


export type Subscription_RootInvite_StatesArgs = {
  distinct_on?: InputMaybe<Array<Invite_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invite_States_Order_By>>;
  where?: InputMaybe<Invite_States_Bool_Exp>;
};


export type Subscription_RootInvite_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invite_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invite_States_Order_By>>;
  where?: InputMaybe<Invite_States_Bool_Exp>;
};


export type Subscription_RootInvite_States_By_PkArgs = {
  state: Scalars['String'];
};


export type Subscription_RootInvite_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Invite_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Invite_States_Bool_Exp>;
};


export type Subscription_RootKanban_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Kanban_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanban_Columns_Order_By>>;
  where?: InputMaybe<Kanban_Columns_Bool_Exp>;
};


export type Subscription_RootKanban_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanban_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanban_Columns_Order_By>>;
  where?: InputMaybe<Kanban_Columns_Bool_Exp>;
};


export type Subscription_RootKanban_Columns_By_PkArgs = {
  column: Scalars['String'];
};


export type Subscription_RootKanban_Columns_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Kanban_Columns_Stream_Cursor_Input>>;
  where?: InputMaybe<Kanban_Columns_Bool_Exp>;
};


export type Subscription_RootKanbansArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Order_By>>;
  where?: InputMaybe<Kanbans_Bool_Exp>;
};


export type Subscription_RootKanbans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Order_By>>;
  where?: InputMaybe<Kanbans_Bool_Exp>;
};


export type Subscription_RootKanbans_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootKanbans_CardsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_AssignationsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Assignations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Assignations_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_Assignations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Assignations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Assignations_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_Assignations_By_PkArgs = {
  card_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


export type Subscription_RootKanbans_Cards_Assignations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Kanbans_Cards_Assignations_Stream_Cursor_Input>>;
  where?: InputMaybe<Kanbans_Cards_Assignations_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_AttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Attachments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_Attachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Attachments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_Attachments_By_PkArgs = {
  card_id: Scalars['uuid'];
  file_id: Scalars['uuid'];
};


export type Subscription_RootKanbans_Cards_Attachments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Kanbans_Cards_Attachments_Stream_Cursor_Input>>;
  where?: InputMaybe<Kanbans_Cards_Attachments_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootKanbans_Cards_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Comments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kanbans_Cards_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kanbans_Cards_Comments_Order_By>>;
  where?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootKanbans_Cards_Comments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Kanbans_Cards_Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Kanbans_Cards_Comments_Bool_Exp>;
};


export type Subscription_RootKanbans_Cards_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Kanbans_Cards_Stream_Cursor_Input>>;
  where?: InputMaybe<Kanbans_Cards_Bool_Exp>;
};


export type Subscription_RootKanbans_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Kanbans_Stream_Cursor_Input>>;
  where?: InputMaybe<Kanbans_Bool_Exp>;
};


export type Subscription_RootLocationsArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};


export type Subscription_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocations_NamesArgs = {
  distinct_on?: InputMaybe<Array<Locations_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Names_Order_By>>;
  where?: InputMaybe<Locations_Names_Bool_Exp>;
};


export type Subscription_RootLocations_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Names_Order_By>>;
  where?: InputMaybe<Locations_Names_Bool_Exp>;
};


export type Subscription_RootLocations_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLocations_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Locations_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Locations_Names_Bool_Exp>;
};


export type Subscription_RootLocations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};


export type Subscription_RootMentor_Additional_DataArgs = {
  distinct_on?: InputMaybe<Array<Mentor_Additional_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentor_Additional_Data_Order_By>>;
  where?: InputMaybe<Mentor_Additional_Data_Bool_Exp>;
};


export type Subscription_RootMentor_Additional_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentor_Additional_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentor_Additional_Data_Order_By>>;
  where?: InputMaybe<Mentor_Additional_Data_Bool_Exp>;
};


export type Subscription_RootMentor_Additional_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMentor_Additional_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mentor_Additional_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Mentor_Additional_Data_Bool_Exp>;
};


export type Subscription_RootMentor_TagsArgs = {
  distinct_on?: InputMaybe<Array<Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentor_Tags_Order_By>>;
  where?: InputMaybe<Mentor_Tags_Bool_Exp>;
};


export type Subscription_RootMentor_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentor_Tags_Order_By>>;
  where?: InputMaybe<Mentor_Tags_Bool_Exp>;
};


export type Subscription_RootMentor_Tags_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootMentor_Tags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mentor_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Mentor_Tags_Bool_Exp>;
};


export type Subscription_RootMentoring_RecordsArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Bool_Exp>;
};


export type Subscription_RootMentoring_Records_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Bool_Exp>;
};


export type Subscription_RootMentoring_Records_AttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Attachments_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Attachments_Bool_Exp>;
};


export type Subscription_RootMentoring_Records_Attachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Attachments_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Attachments_Bool_Exp>;
};


export type Subscription_RootMentoring_Records_Attachments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMentoring_Records_Attachments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mentoring_Records_Attachments_Stream_Cursor_Input>>;
  where?: InputMaybe<Mentoring_Records_Attachments_Bool_Exp>;
};


export type Subscription_RootMentoring_Records_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMentoring_Records_MembersArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
};


export type Subscription_RootMentoring_Records_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Records_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Records_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
};


export type Subscription_RootMentoring_Records_Members_By_PkArgs = {
  member_id: Scalars['String'];
  mentoring_record_id: Scalars['uuid'];
};


export type Subscription_RootMentoring_Records_Members_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mentoring_Records_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Mentoring_Records_Members_Bool_Exp>;
};


export type Subscription_RootMentoring_Records_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mentoring_Records_Stream_Cursor_Input>>;
  where?: InputMaybe<Mentoring_Records_Bool_Exp>;
};


export type Subscription_RootMentoring_ReschedulesArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Reschedules_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Reschedules_Order_By>>;
  where?: InputMaybe<Mentoring_Reschedules_Bool_Exp>;
};


export type Subscription_RootMentoring_Reschedules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Reschedules_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Reschedules_Order_By>>;
  where?: InputMaybe<Mentoring_Reschedules_Bool_Exp>;
};


export type Subscription_RootMentoring_Reschedules_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMentoring_Reschedules_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mentoring_Reschedules_Stream_Cursor_Input>>;
  where?: InputMaybe<Mentoring_Reschedules_Bool_Exp>;
};


export type Subscription_RootMentoring_SchedulingArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Scheduling_Order_By>>;
  where?: InputMaybe<Mentoring_Scheduling_Bool_Exp>;
};


export type Subscription_RootMentoring_Scheduling_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Scheduling_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Scheduling_Order_By>>;
  where?: InputMaybe<Mentoring_Scheduling_Bool_Exp>;
};


export type Subscription_RootMentoring_Scheduling_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMentoring_Scheduling_MeetingArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Scheduling_Meeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Scheduling_Meeting_Order_By>>;
  where?: InputMaybe<Mentoring_Scheduling_Meeting_Bool_Exp>;
};


export type Subscription_RootMentoring_Scheduling_Meeting_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Scheduling_Meeting_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Scheduling_Meeting_Order_By>>;
  where?: InputMaybe<Mentoring_Scheduling_Meeting_Bool_Exp>;
};


export type Subscription_RootMentoring_Scheduling_Meeting_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMentoring_Scheduling_Meeting_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mentoring_Scheduling_Meeting_Stream_Cursor_Input>>;
  where?: InputMaybe<Mentoring_Scheduling_Meeting_Bool_Exp>;
};


export type Subscription_RootMentoring_Scheduling_MembersArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Scheduling_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Scheduling_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Scheduling_Members_Bool_Exp>;
};


export type Subscription_RootMentoring_Scheduling_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentoring_Scheduling_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentoring_Scheduling_Members_Order_By>>;
  where?: InputMaybe<Mentoring_Scheduling_Members_Bool_Exp>;
};


export type Subscription_RootMentoring_Scheduling_Members_By_PkArgs = {
  id: Scalars['uuid'];
  member_id: Scalars['String'];
  mentoring_scheduling_id: Scalars['uuid'];
};


export type Subscription_RootMentoring_Scheduling_Members_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mentoring_Scheduling_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Mentoring_Scheduling_Members_Bool_Exp>;
};


export type Subscription_RootMentoring_Scheduling_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mentoring_Scheduling_Stream_Cursor_Input>>;
  where?: InputMaybe<Mentoring_Scheduling_Bool_Exp>;
};


export type Subscription_RootMentorsArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Order_By>>;
  where?: InputMaybe<Mentors_Bool_Exp>;
};


export type Subscription_RootMentors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Order_By>>;
  where?: InputMaybe<Mentors_Bool_Exp>;
};


export type Subscription_RootMentors_BlablablaArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


export type Subscription_RootMentors_Blablabla_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


export type Subscription_RootMentors_Blablabla_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMentors_Blablabla_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mentors_Blablabla_Stream_Cursor_Input>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


export type Subscription_RootMentors_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMentors_Mentor_TagsArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Mentor_Tags_Order_By>>;
  where?: InputMaybe<Mentors_Mentor_Tags_Bool_Exp>;
};


export type Subscription_RootMentors_Mentor_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Mentor_Tags_Order_By>>;
  where?: InputMaybe<Mentors_Mentor_Tags_Bool_Exp>;
};


export type Subscription_RootMentors_Mentor_Tags_By_PkArgs = {
  mentor_id: Scalars['uuid'];
  tag_name: Scalars['String'];
};


export type Subscription_RootMentors_Mentor_Tags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mentors_Mentor_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Mentors_Mentor_Tags_Bool_Exp>;
};


export type Subscription_RootMentors_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mentors_Stream_Cursor_Input>>;
  where?: InputMaybe<Mentors_Bool_Exp>;
};


export type Subscription_RootMetodologiesArgs = {
  distinct_on?: InputMaybe<Array<Metodologies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metodologies_Order_By>>;
  where?: InputMaybe<Metodologies_Bool_Exp>;
};


export type Subscription_RootMetodologies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Metodologies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Metodologies_Order_By>>;
  where?: InputMaybe<Metodologies_Bool_Exp>;
};


export type Subscription_RootMetodologies_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootMetodologies_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Metodologies_Stream_Cursor_Input>>;
  where?: InputMaybe<Metodologies_Bool_Exp>;
};


export type Subscription_RootMultimedia_ContentsArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Bool_Exp>;
};


export type Subscription_RootMultimedia_Contents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Bool_Exp>;
};


export type Subscription_RootMultimedia_Contents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMultimedia_Contents_LinksArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Links_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};


export type Subscription_RootMultimedia_Contents_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Multimedia_Contents_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Multimedia_Contents_Links_Order_By>>;
  where?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};


export type Subscription_RootMultimedia_Contents_Links_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMultimedia_Contents_Links_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Multimedia_Contents_Links_Stream_Cursor_Input>>;
  where?: InputMaybe<Multimedia_Contents_Links_Bool_Exp>;
};


export type Subscription_RootMultimedia_Contents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Multimedia_Contents_Stream_Cursor_Input>>;
  where?: InputMaybe<Multimedia_Contents_Bool_Exp>;
};


export type Subscription_RootOutputsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Order_By>>;
  where?: InputMaybe<Outputs_Bool_Exp>;
};


export type Subscription_RootOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Order_By>>;
  where?: InputMaybe<Outputs_Bool_Exp>;
};


export type Subscription_RootOutputs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOutputs_IconsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Icons_Order_By>>;
  where?: InputMaybe<Outputs_Icons_Bool_Exp>;
};


export type Subscription_RootOutputs_Icons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Icons_Order_By>>;
  where?: InputMaybe<Outputs_Icons_Bool_Exp>;
};


export type Subscription_RootOutputs_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOutputs_Icons_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Outputs_Icons_Stream_Cursor_Input>>;
  where?: InputMaybe<Outputs_Icons_Bool_Exp>;
};


export type Subscription_RootOutputs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Outputs_Stream_Cursor_Input>>;
  where?: InputMaybe<Outputs_Bool_Exp>;
};


export type Subscription_RootOutputs_ToolsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};


export type Subscription_RootOutputs_Tools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};


export type Subscription_RootOutputs_Tools_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOutputs_Tools_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Outputs_Tools_Stream_Cursor_Input>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};


export type Subscription_RootQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Subscription_RootQuestionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Subscription_RootQuestionnaires_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootQuestionnaires_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Questionnaires_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


export type Subscription_RootQuestionnaires_Teams_StatesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};


export type Subscription_RootQuestionnaires_Teams_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};


export type Subscription_RootQuestionnaires_Teams_States_By_PkArgs = {
  questionnaire_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
};


export type Subscription_RootQuestionnaires_Teams_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Questionnaires_Teams_States_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};


export type Subscription_RootQuestionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootQuestions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Order_By>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootQuestions_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


export type Subscription_RootQuestions_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


export type Subscription_RootQuestions_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootQuestions_Answers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Questions_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


export type Subscription_RootQuestions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootQuestions_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Questions_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Options_Order_By>>;
  where?: InputMaybe<Questions_Options_Bool_Exp>;
};


export type Subscription_RootQuestions_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Options_Order_By>>;
  where?: InputMaybe<Questions_Options_Bool_Exp>;
};


export type Subscription_RootQuestions_Options_By_PkArgs = {
  label: Scalars['String'];
  question_id: Scalars['uuid'];
  value: Scalars['String'];
};


export type Subscription_RootQuestions_Options_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Questions_Options_Stream_Cursor_Input>>;
  where?: InputMaybe<Questions_Options_Bool_Exp>;
};


export type Subscription_RootQuestions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Questions_Bool_Exp>;
};


export type Subscription_RootQuestions_TypesArgs = {
  distinct_on?: InputMaybe<Array<Questions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Types_Order_By>>;
  where?: InputMaybe<Questions_Types_Bool_Exp>;
};


export type Subscription_RootQuestions_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Types_Order_By>>;
  where?: InputMaybe<Questions_Types_Bool_Exp>;
};


export type Subscription_RootQuestions_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootQuestions_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Questions_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Questions_Types_Bool_Exp>;
};


export type Subscription_RootRepositoriesArgs = {
  distinct_on?: InputMaybe<Array<Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Repositories_Order_By>>;
  where?: InputMaybe<Repositories_Bool_Exp>;
};


export type Subscription_RootRepositories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Repositories_Order_By>>;
  where?: InputMaybe<Repositories_Bool_Exp>;
};


export type Subscription_RootRepositories_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRepositories_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Repositories_Stream_Cursor_Input>>;
  where?: InputMaybe<Repositories_Bool_Exp>;
};


export type Subscription_RootRequested_MentoringsArgs = {
  distinct_on?: InputMaybe<Array<Requested_Mentorings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Requested_Mentorings_Order_By>>;
  where?: InputMaybe<Requested_Mentorings_Bool_Exp>;
};


export type Subscription_RootRequested_Mentorings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Requested_Mentorings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Requested_Mentorings_Order_By>>;
  where?: InputMaybe<Requested_Mentorings_Bool_Exp>;
};


export type Subscription_RootRequested_Mentorings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRequested_Mentorings_StatusArgs = {
  distinct_on?: InputMaybe<Array<Requested_Mentorings_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Requested_Mentorings_Status_Order_By>>;
  where?: InputMaybe<Requested_Mentorings_Status_Bool_Exp>;
};


export type Subscription_RootRequested_Mentorings_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Requested_Mentorings_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Requested_Mentorings_Status_Order_By>>;
  where?: InputMaybe<Requested_Mentorings_Status_Bool_Exp>;
};


export type Subscription_RootRequested_Mentorings_Status_By_PkArgs = {
  status: Scalars['String'];
};


export type Subscription_RootRequested_Mentorings_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Requested_Mentorings_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Requested_Mentorings_Status_Bool_Exp>;
};


export type Subscription_RootRequested_Mentorings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Requested_Mentorings_Stream_Cursor_Input>>;
  where?: InputMaybe<Requested_Mentorings_Bool_Exp>;
};


export type Subscription_RootRequests_Mentor_TagsArgs = {
  distinct_on?: InputMaybe<Array<Requests_Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Requests_Mentor_Tags_Order_By>>;
  where?: InputMaybe<Requests_Mentor_Tags_Bool_Exp>;
};


export type Subscription_RootRequests_Mentor_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Requests_Mentor_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Requests_Mentor_Tags_Order_By>>;
  where?: InputMaybe<Requests_Mentor_Tags_Bool_Exp>;
};


export type Subscription_RootRequests_Mentor_Tags_By_PkArgs = {
  request_id: Scalars['uuid'];
  tag_name: Scalars['String'];
};


export type Subscription_RootRequests_Mentor_Tags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Requests_Mentor_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Requests_Mentor_Tags_Bool_Exp>;
};


export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_By_PkArgs = {
  role: Scalars['String'];
};


export type Subscription_RootRoles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootSectionsArgs = {
  distinct_on?: InputMaybe<Array<Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Order_By>>;
  where?: InputMaybe<Sections_Bool_Exp>;
};


export type Subscription_RootSections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Order_By>>;
  where?: InputMaybe<Sections_Bool_Exp>;
};


export type Subscription_RootSections_By_PkArgs = {
  id: Scalars['uuid'];
  slug: Scalars['String'];
};


export type Subscription_RootSections_IconsArgs = {
  distinct_on?: InputMaybe<Array<Sections_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Icons_Order_By>>;
  where?: InputMaybe<Sections_Icons_Bool_Exp>;
};


export type Subscription_RootSections_Icons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sections_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sections_Icons_Order_By>>;
  where?: InputMaybe<Sections_Icons_Bool_Exp>;
};


export type Subscription_RootSections_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSections_Icons_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sections_Icons_Stream_Cursor_Input>>;
  where?: InputMaybe<Sections_Icons_Bool_Exp>;
};


export type Subscription_RootSections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sections_Stream_Cursor_Input>>;
  where?: InputMaybe<Sections_Bool_Exp>;
};


export type Subscription_RootSocial_MediasArgs = {
  distinct_on?: InputMaybe<Array<Social_Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Social_Medias_Order_By>>;
  where?: InputMaybe<Social_Medias_Bool_Exp>;
};


export type Subscription_RootSocial_Medias_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Social_Medias_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Social_Medias_Order_By>>;
  where?: InputMaybe<Social_Medias_Bool_Exp>;
};


export type Subscription_RootSocial_Medias_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSocial_Medias_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Social_Medias_Stream_Cursor_Input>>;
  where?: InputMaybe<Social_Medias_Bool_Exp>;
};


export type Subscription_RootTag_ConversionArgs = {
  distinct_on?: InputMaybe<Array<Tag_Conversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Conversion_Order_By>>;
  where?: InputMaybe<Tag_Conversion_Bool_Exp>;
};


export type Subscription_RootTag_Conversion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tag_Conversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tag_Conversion_Order_By>>;
  where?: InputMaybe<Tag_Conversion_Bool_Exp>;
};


export type Subscription_RootTag_Conversion_By_PkArgs = {
  old_tag: Scalars['String'];
};


export type Subscription_RootTag_Conversion_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tag_Conversion_Stream_Cursor_Input>>;
  where?: InputMaybe<Tag_Conversion_Bool_Exp>;
};


export type Subscription_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_By_PkArgs = {
  id: Scalars['uuid'];
  value: Scalars['String'];
};


export type Subscription_RootTags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTeam_InvitesArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};


export type Subscription_RootTeam_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};


export type Subscription_RootTeam_Invites_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeam_Invites_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Team_Invites_Stream_Cursor_Input>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};


export type Subscription_RootTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Subscription_RootTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Order_By>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Subscription_RootTeams_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeams_NamesArgs = {
  distinct_on?: InputMaybe<Array<Teams_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Names_Order_By>>;
  where?: InputMaybe<Teams_Names_Bool_Exp>;
};


export type Subscription_RootTeams_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Names_Order_By>>;
  where?: InputMaybe<Teams_Names_Bool_Exp>;
};


export type Subscription_RootTeams_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeams_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Teams_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Names_Bool_Exp>;
};


export type Subscription_RootTeams_ScoresArgs = {
  distinct_on?: InputMaybe<Array<Teams_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Scores_Order_By>>;
  where?: InputMaybe<Teams_Scores_Bool_Exp>;
};


export type Subscription_RootTeams_Scores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Scores_Order_By>>;
  where?: InputMaybe<Teams_Scores_Bool_Exp>;
};


export type Subscription_RootTeams_Scores_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Teams_Scores_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Scores_Bool_Exp>;
};


export type Subscription_RootTeams_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Teams_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Bool_Exp>;
};


export type Subscription_RootTeams_TagsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};


export type Subscription_RootTeams_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};


export type Subscription_RootTeams_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeams_Tags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Teams_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};


export type Subscription_RootTeams_UsersArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};


export type Subscription_RootTeams_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};


export type Subscription_RootTeams_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeams_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Teams_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};


export type Subscription_RootToolsArgs = {
  distinct_on?: InputMaybe<Array<Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Order_By>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Subscription_RootTools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Order_By>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Subscription_RootTools_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTools_IconsArgs = {
  distinct_on?: InputMaybe<Array<Tools_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Icons_Order_By>>;
  where?: InputMaybe<Tools_Icons_Bool_Exp>;
};


export type Subscription_RootTools_Icons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tools_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Icons_Order_By>>;
  where?: InputMaybe<Tools_Icons_Bool_Exp>;
};


export type Subscription_RootTools_Icons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTools_Icons_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tools_Icons_Stream_Cursor_Input>>;
  where?: InputMaybe<Tools_Icons_Bool_Exp>;
};


export type Subscription_RootTools_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tools_Stream_Cursor_Input>>;
  where?: InputMaybe<Tools_Bool_Exp>;
};


export type Subscription_RootUser_InfoArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};


export type Subscription_RootUser_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Info_Order_By>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};


export type Subscription_RootUser_Info_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_Info_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Info_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};


export type Subscription_RootUser_InvitesArgs = {
  distinct_on?: InputMaybe<Array<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Subscription_RootUser_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Invites_Order_By>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Subscription_RootUser_Invites_By_PkArgs = {
  id: Scalars['uuid'];
  token: Scalars['String'];
};


export type Subscription_RootUser_Invites_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Invites_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Subscription_RootUsers_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Subscription_RootUsers_Actions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsers_Actions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


export type Subscription_RootUsers_Actions_TypesArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Types_Order_By>>;
  where?: InputMaybe<Users_Actions_Types_Bool_Exp>;
};


export type Subscription_RootUsers_Actions_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Types_Order_By>>;
  where?: InputMaybe<Users_Actions_Types_Bool_Exp>;
};


export type Subscription_RootUsers_Actions_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootUsers_Actions_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Actions_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Actions_Types_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  email: Scalars['String'];
  id: Scalars['String'];
};


export type Subscription_RootUsers_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Order_By>>;
  where?: InputMaybe<Users_Documents_Bool_Exp>;
};


export type Subscription_RootUsers_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Order_By>>;
  where?: InputMaybe<Users_Documents_Bool_Exp>;
};


export type Subscription_RootUsers_Documents_By_PkArgs = {
  file_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


export type Subscription_RootUsers_Documents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Documents_Bool_Exp>;
};


export type Subscription_RootUsers_Documents_TypesArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Types_Order_By>>;
  where?: InputMaybe<Users_Documents_Types_Bool_Exp>;
};


export type Subscription_RootUsers_Documents_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Types_Order_By>>;
  where?: InputMaybe<Users_Documents_Types_Bool_Exp>;
};


export type Subscription_RootUsers_Documents_Types_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootUsers_Documents_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Documents_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Documents_Types_Bool_Exp>;
};


export type Subscription_RootUsers_NamesArgs = {
  distinct_on?: InputMaybe<Array<Users_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Names_Order_By>>;
  where?: InputMaybe<Users_Names_Bool_Exp>;
};


export type Subscription_RootUsers_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Names_Order_By>>;
  where?: InputMaybe<Users_Names_Bool_Exp>;
};


export type Subscription_RootUsers_Names_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsers_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Names_Bool_Exp>;
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootVideo_RepositoriesArgs = {
  distinct_on?: InputMaybe<Array<Video_Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Repositories_Order_By>>;
  where?: InputMaybe<Video_Repositories_Bool_Exp>;
};


export type Subscription_RootVideo_Repositories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Video_Repositories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Repositories_Order_By>>;
  where?: InputMaybe<Video_Repositories_Bool_Exp>;
};


export type Subscription_RootVideo_Repositories_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVideo_Repositories_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Video_Repositories_Stream_Cursor_Input>>;
  where?: InputMaybe<Video_Repositories_Bool_Exp>;
};


export type Subscription_RootVideosArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Subscription_RootVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Subscription_RootVideos_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVideos_By_TagsArgs = {
  args: Videos_By_Tags_Args;
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Subscription_RootVideos_By_Tags_AggregateArgs = {
  args: Videos_By_Tags_Args;
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Subscription_RootVideos_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Videos_Stream_Cursor_Input>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


export type Subscription_RootVideos_Youtube_TagsArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};


export type Subscription_RootVideos_Youtube_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};


export type Subscription_RootVideos_Youtube_Tags_By_PkArgs = {
  tag: Scalars['String'];
  video_id: Scalars['uuid'];
};


export type Subscription_RootVideos_Youtube_Tags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Videos_Youtube_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};


export type Subscription_RootYoutube_TagsArgs = {
  distinct_on?: InputMaybe<Array<Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Youtube_Tags_Order_By>>;
  where?: InputMaybe<Youtube_Tags_Bool_Exp>;
};


export type Subscription_RootYoutube_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Youtube_Tags_Order_By>>;
  where?: InputMaybe<Youtube_Tags_Bool_Exp>;
};


export type Subscription_RootYoutube_Tags_By_PkArgs = {
  tag: Scalars['String'];
};


export type Subscription_RootYoutube_Tags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Youtube_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Youtube_Tags_Bool_Exp>;
};

/** columns and relationships of "tag_conversion" */
export type Tag_Conversion = {
  __typename?: 'tag_conversion';
  new_tag: Scalars['String'];
  old_tag: Scalars['String'];
};

/** aggregated selection of "tag_conversion" */
export type Tag_Conversion_Aggregate = {
  __typename?: 'tag_conversion_aggregate';
  aggregate?: Maybe<Tag_Conversion_Aggregate_Fields>;
  nodes: Array<Tag_Conversion>;
};

/** aggregate fields of "tag_conversion" */
export type Tag_Conversion_Aggregate_Fields = {
  __typename?: 'tag_conversion_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tag_Conversion_Max_Fields>;
  min?: Maybe<Tag_Conversion_Min_Fields>;
};


/** aggregate fields of "tag_conversion" */
export type Tag_Conversion_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tag_Conversion_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tag_conversion". All fields are combined with a logical 'AND'. */
export type Tag_Conversion_Bool_Exp = {
  _and?: InputMaybe<Array<Tag_Conversion_Bool_Exp>>;
  _not?: InputMaybe<Tag_Conversion_Bool_Exp>;
  _or?: InputMaybe<Array<Tag_Conversion_Bool_Exp>>;
  new_tag?: InputMaybe<String_Comparison_Exp>;
  old_tag?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tag_conversion" */
export enum Tag_Conversion_Constraint {
  /** unique or primary key constraint on columns "old_tag" */
  TagConversionPkey = 'tag_conversion_pkey'
}

/** input type for inserting data into table "tag_conversion" */
export type Tag_Conversion_Insert_Input = {
  new_tag?: InputMaybe<Scalars['String']>;
  old_tag?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tag_Conversion_Max_Fields = {
  __typename?: 'tag_conversion_max_fields';
  new_tag?: Maybe<Scalars['String']>;
  old_tag?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tag_Conversion_Min_Fields = {
  __typename?: 'tag_conversion_min_fields';
  new_tag?: Maybe<Scalars['String']>;
  old_tag?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "tag_conversion" */
export type Tag_Conversion_Mutation_Response = {
  __typename?: 'tag_conversion_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tag_Conversion>;
};

/** on_conflict condition type for table "tag_conversion" */
export type Tag_Conversion_On_Conflict = {
  constraint: Tag_Conversion_Constraint;
  update_columns?: Array<Tag_Conversion_Update_Column>;
  where?: InputMaybe<Tag_Conversion_Bool_Exp>;
};

/** Ordering options when selecting data from "tag_conversion". */
export type Tag_Conversion_Order_By = {
  new_tag?: InputMaybe<Order_By>;
  old_tag?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tag_conversion */
export type Tag_Conversion_Pk_Columns_Input = {
  old_tag: Scalars['String'];
};

/** select columns of table "tag_conversion" */
export enum Tag_Conversion_Select_Column {
  /** column name */
  NewTag = 'new_tag',
  /** column name */
  OldTag = 'old_tag'
}

/** input type for updating data in table "tag_conversion" */
export type Tag_Conversion_Set_Input = {
  new_tag?: InputMaybe<Scalars['String']>;
  old_tag?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "tag_conversion" */
export type Tag_Conversion_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tag_Conversion_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tag_Conversion_Stream_Cursor_Value_Input = {
  new_tag?: InputMaybe<Scalars['String']>;
  old_tag?: InputMaybe<Scalars['String']>;
};

/** update columns of table "tag_conversion" */
export enum Tag_Conversion_Update_Column {
  /** column name */
  NewTag = 'new_tag',
  /** column name */
  OldTag = 'old_tag'
}

export type Tag_Conversion_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tag_Conversion_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tag_Conversion_Bool_Exp;
};

/** columns and relationships of "tags" */
export type Tags = {
  __typename?: 'tags';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  teams: Array<Teams_Tags>;
  /** An aggregate relationship */
  teams_aggregate: Teams_Tags_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "tags" */
export type TagsTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};


/** columns and relationships of "tags" */
export type TagsTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};

/** aggregated selection of "tags" */
export type Tags_Aggregate = {
  __typename?: 'tags_aggregate';
  aggregate?: Maybe<Tags_Aggregate_Fields>;
  nodes: Array<Tags>;
};

/** aggregate fields of "tags" */
export type Tags_Aggregate_Fields = {
  __typename?: 'tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tags_Max_Fields>;
  min?: Maybe<Tags_Min_Fields>;
};


/** aggregate fields of "tags" */
export type Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tags". All fields are combined with a logical 'AND'. */
export type Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Tags_Bool_Exp>>;
  _not?: InputMaybe<Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Tags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  teams?: InputMaybe<Teams_Tags_Bool_Exp>;
  teams_aggregate?: InputMaybe<Teams_Tags_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tags" */
export enum Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  TagsIdKey = 'tags_id_key',
  /** unique or primary key constraint on columns "id", "value" */
  TagsPkey = 'tags_pkey',
  /** unique or primary key constraint on columns "value" */
  TagsValueKey = 'tags_value_key'
}

/** input type for inserting data into table "tags" */
export type Tags_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  teams?: InputMaybe<Teams_Tags_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tags_Max_Fields = {
  __typename?: 'tags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tags_Min_Fields = {
  __typename?: 'tags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "tags" */
export type Tags_Mutation_Response = {
  __typename?: 'tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tags>;
};

/** input type for inserting object relation for remote table "tags" */
export type Tags_Obj_Rel_Insert_Input = {
  data: Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** on_conflict condition type for table "tags" */
export type Tags_On_Conflict = {
  constraint: Tags_Constraint;
  update_columns?: Array<Tags_Update_Column>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "tags". */
export type Tags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  teams_aggregate?: InputMaybe<Teams_Tags_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tags */
export type Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
  value: Scalars['String'];
};

/** select columns of table "tags" */
export enum Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "tags" */
export type Tags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "tags" */
export type Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "tags" */
export enum Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

export type Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tags_Bool_Exp;
};

/** columns and relationships of "team_invites" */
export type Team_Invites = {
  __typename?: 'team_invites';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  status: Invite_States_Enum;
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "team_invites" */
export type Team_Invites_Aggregate = {
  __typename?: 'team_invites_aggregate';
  aggregate?: Maybe<Team_Invites_Aggregate_Fields>;
  nodes: Array<Team_Invites>;
};

export type Team_Invites_Aggregate_Bool_Exp = {
  count?: InputMaybe<Team_Invites_Aggregate_Bool_Exp_Count>;
};

export type Team_Invites_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Team_Invites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Team_Invites_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "team_invites" */
export type Team_Invites_Aggregate_Fields = {
  __typename?: 'team_invites_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Team_Invites_Max_Fields>;
  min?: Maybe<Team_Invites_Min_Fields>;
};


/** aggregate fields of "team_invites" */
export type Team_Invites_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Team_Invites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "team_invites" */
export type Team_Invites_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Team_Invites_Max_Order_By>;
  min?: InputMaybe<Team_Invites_Min_Order_By>;
};

/** input type for inserting array relation for remote table "team_invites" */
export type Team_Invites_Arr_Rel_Insert_Input = {
  data: Array<Team_Invites_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Team_Invites_On_Conflict>;
};

/** Boolean expression to filter rows from the table "team_invites". All fields are combined with a logical 'AND'. */
export type Team_Invites_Bool_Exp = {
  _and?: InputMaybe<Array<Team_Invites_Bool_Exp>>;
  _not?: InputMaybe<Team_Invites_Bool_Exp>;
  _or?: InputMaybe<Array<Team_Invites_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Invite_States_Enum_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "team_invites" */
export enum Team_Invites_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamInvitesPkey = 'team_invites_pkey'
}

/** input type for inserting data into table "team_invites" */
export type Team_Invites_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Invite_States_Enum>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Team_Invites_Max_Fields = {
  __typename?: 'team_invites_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "team_invites" */
export type Team_Invites_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Team_Invites_Min_Fields = {
  __typename?: 'team_invites_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "team_invites" */
export type Team_Invites_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "team_invites" */
export type Team_Invites_Mutation_Response = {
  __typename?: 'team_invites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Team_Invites>;
};

/** on_conflict condition type for table "team_invites" */
export type Team_Invites_On_Conflict = {
  constraint: Team_Invites_Constraint;
  update_columns?: Array<Team_Invites_Update_Column>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};

/** Ordering options when selecting data from "team_invites". */
export type Team_Invites_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: team_invites */
export type Team_Invites_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "team_invites" */
export enum Team_Invites_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "team_invites" */
export type Team_Invites_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Invite_States_Enum>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "team_invites" */
export type Team_Invites_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Team_Invites_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Team_Invites_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Invite_States_Enum>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "team_invites" */
export enum Team_Invites_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

export type Team_Invites_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Team_Invites_Set_Input>;
  /** filter the rows which have to be updated */
  where: Team_Invites_Bool_Exp;
};

/** columns and relationships of "teams" */
export type Teams = {
  __typename?: 'teams';
  accept_mentoring?: Maybe<Scalars['Boolean']>;
  cnpj?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  diagnostics_answers: Array<Diagnostics_Questions_Answers>;
  /** An aggregate relationship */
  diagnostics_answers_aggregate: Diagnostics_Questions_Answers_Aggregate;
  disabled: Scalars['Boolean'];
  /** An array relationship */
  groups: Array<Groups_Teams>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Teams_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  logo?: Maybe<Files>;
  logo_file_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  members: Array<Teams_Users>;
  /** An aggregate relationship */
  members_aggregate: Teams_Users_Aggregate;
  /** An array relationship */
  mentors_blablablas: Array<Mentors_Blablabla>;
  /** An aggregate relationship */
  mentors_blablablas_aggregate: Mentors_Blablabla_Aggregate;
  /** A computed field, executes function "team_name" */
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  names: Array<Teams_Names>;
  /** An aggregate relationship */
  names_aggregate: Teams_Names_Aggregate;
  /** An array relationship */
  questionnaires_states: Array<Questionnaires_Teams_States>;
  /** An aggregate relationship */
  questionnaires_states_aggregate: Questionnaires_Teams_States_Aggregate;
  /** An array relationship */
  questions_answers: Array<Questions_Answers>;
  /** An aggregate relationship */
  questions_answers_aggregate: Questions_Answers_Aggregate;
  /** An object relationship */
  social_media?: Maybe<Social_Medias>;
  social_media_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  tags: Array<Teams_Tags>;
  /** An aggregate relationship */
  tags_aggregate: Teams_Tags_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  user_invitations: Array<Team_Invites>;
  /** An aggregate relationship */
  user_invitations_aggregate: Team_Invites_Aggregate;
};


/** columns and relationships of "teams" */
export type TeamsDiagnostics_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Answers_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsDiagnostics_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Diagnostics_Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Diagnostics_Questions_Answers_Order_By>>;
  where?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Teams_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Teams_Order_By>>;
  where?: InputMaybe<Groups_Teams_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsMembersArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsMentors_BlablablasArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsMentors_Blablablas_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsNamesArgs = {
  distinct_on?: InputMaybe<Array<Teams_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Names_Order_By>>;
  where?: InputMaybe<Teams_Names_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsNames_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Names_Order_By>>;
  where?: InputMaybe<Teams_Names_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsQuestionnaires_StatesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsQuestionnaires_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Teams_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Teams_States_Order_By>>;
  where?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsQuestions_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsQuestions_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsTagsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Tags_Order_By>>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsUser_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};


/** columns and relationships of "teams" */
export type TeamsUser_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};

/** aggregated selection of "teams" */
export type Teams_Aggregate = {
  __typename?: 'teams_aggregate';
  aggregate?: Maybe<Teams_Aggregate_Fields>;
  nodes: Array<Teams>;
};

export type Teams_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Teams_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Teams_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Teams_Aggregate_Bool_Exp_Count>;
};

export type Teams_Aggregate_Bool_Exp_Bool_And = {
  arguments: Teams_Select_Column_Teams_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Teams_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Teams_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Teams_Select_Column_Teams_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Teams_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Teams_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Teams_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "teams" */
export type Teams_Aggregate_Fields = {
  __typename?: 'teams_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Teams_Max_Fields>;
  min?: Maybe<Teams_Min_Fields>;
};


/** aggregate fields of "teams" */
export type Teams_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Teams_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teams" */
export type Teams_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Max_Order_By>;
  min?: InputMaybe<Teams_Min_Order_By>;
};

/** input type for inserting array relation for remote table "teams" */
export type Teams_Arr_Rel_Insert_Input = {
  data: Array<Teams_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};

/** Boolean expression to filter rows from the table "teams". All fields are combined with a logical 'AND'. */
export type Teams_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Bool_Exp>>;
  _not?: InputMaybe<Teams_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Bool_Exp>>;
  accept_mentoring?: InputMaybe<Boolean_Comparison_Exp>;
  cnpj?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  diagnostics_answers?: InputMaybe<Diagnostics_Questions_Answers_Bool_Exp>;
  diagnostics_answers_aggregate?: InputMaybe<Diagnostics_Questions_Answers_Aggregate_Bool_Exp>;
  disabled?: InputMaybe<Boolean_Comparison_Exp>;
  groups?: InputMaybe<Groups_Teams_Bool_Exp>;
  groups_aggregate?: InputMaybe<Groups_Teams_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  logo?: InputMaybe<Files_Bool_Exp>;
  logo_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  members?: InputMaybe<Teams_Users_Bool_Exp>;
  members_aggregate?: InputMaybe<Teams_Users_Aggregate_Bool_Exp>;
  mentors_blablablas?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
  mentors_blablablas_aggregate?: InputMaybe<Mentors_Blablabla_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  names?: InputMaybe<Teams_Names_Bool_Exp>;
  names_aggregate?: InputMaybe<Teams_Names_Aggregate_Bool_Exp>;
  questionnaires_states?: InputMaybe<Questionnaires_Teams_States_Bool_Exp>;
  questionnaires_states_aggregate?: InputMaybe<Questionnaires_Teams_States_Aggregate_Bool_Exp>;
  questions_answers?: InputMaybe<Questions_Answers_Bool_Exp>;
  questions_answers_aggregate?: InputMaybe<Questions_Answers_Aggregate_Bool_Exp>;
  social_media?: InputMaybe<Social_Medias_Bool_Exp>;
  social_media_id?: InputMaybe<Uuid_Comparison_Exp>;
  tags?: InputMaybe<Teams_Tags_Bool_Exp>;
  tags_aggregate?: InputMaybe<Teams_Tags_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_invitations?: InputMaybe<Team_Invites_Bool_Exp>;
  user_invitations_aggregate?: InputMaybe<Team_Invites_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "teams" */
export enum Teams_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamsPkey = 'teams_pkey',
  /** unique or primary key constraint on columns "social_media_id" */
  TeamsSocialMediaIdKey = 'teams_social_media_id_key'
}

/** input type for inserting data into table "teams" */
export type Teams_Insert_Input = {
  accept_mentoring?: InputMaybe<Scalars['Boolean']>;
  cnpj?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  diagnostics_answers?: InputMaybe<Diagnostics_Questions_Answers_Arr_Rel_Insert_Input>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  groups?: InputMaybe<Groups_Teams_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  logo?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  logo_file_id?: InputMaybe<Scalars['uuid']>;
  members?: InputMaybe<Teams_Users_Arr_Rel_Insert_Input>;
  mentors_blablablas?: InputMaybe<Mentors_Blablabla_Arr_Rel_Insert_Input>;
  names?: InputMaybe<Teams_Names_Arr_Rel_Insert_Input>;
  questionnaires_states?: InputMaybe<Questionnaires_Teams_States_Arr_Rel_Insert_Input>;
  questions_answers?: InputMaybe<Questions_Answers_Arr_Rel_Insert_Input>;
  social_media?: InputMaybe<Social_Medias_Obj_Rel_Insert_Input>;
  social_media_id?: InputMaybe<Scalars['uuid']>;
  tags?: InputMaybe<Teams_Tags_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_invitations?: InputMaybe<Team_Invites_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Teams_Max_Fields = {
  __typename?: 'teams_max_fields';
  cnpj?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_file_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "team_name" */
  name?: Maybe<Scalars['String']>;
  social_media_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "teams" */
export type Teams_Max_Order_By = {
  cnpj?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_file_id?: InputMaybe<Order_By>;
  social_media_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Teams_Min_Fields = {
  __typename?: 'teams_min_fields';
  cnpj?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_file_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "team_name" */
  name?: Maybe<Scalars['String']>;
  social_media_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "teams" */
export type Teams_Min_Order_By = {
  cnpj?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_file_id?: InputMaybe<Order_By>;
  social_media_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams" */
export type Teams_Mutation_Response = {
  __typename?: 'teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teams>;
};

/** columns and relationships of "teams_names" */
export type Teams_Names = {
  __typename?: 'teams_names';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};

/** aggregated selection of "teams_names" */
export type Teams_Names_Aggregate = {
  __typename?: 'teams_names_aggregate';
  aggregate?: Maybe<Teams_Names_Aggregate_Fields>;
  nodes: Array<Teams_Names>;
};

export type Teams_Names_Aggregate_Bool_Exp = {
  count?: InputMaybe<Teams_Names_Aggregate_Bool_Exp_Count>;
};

export type Teams_Names_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Teams_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Teams_Names_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "teams_names" */
export type Teams_Names_Aggregate_Fields = {
  __typename?: 'teams_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Teams_Names_Max_Fields>;
  min?: Maybe<Teams_Names_Min_Fields>;
};


/** aggregate fields of "teams_names" */
export type Teams_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Teams_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teams_names" */
export type Teams_Names_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Names_Max_Order_By>;
  min?: InputMaybe<Teams_Names_Min_Order_By>;
};

/** input type for inserting array relation for remote table "teams_names" */
export type Teams_Names_Arr_Rel_Insert_Input = {
  data: Array<Teams_Names_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_Names_On_Conflict>;
};

/** Boolean expression to filter rows from the table "teams_names". All fields are combined with a logical 'AND'. */
export type Teams_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Names_Bool_Exp>>;
  _not?: InputMaybe<Teams_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Names_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams_names" */
export enum Teams_Names_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamsNamesIdKey = 'teams_names_id_key',
  /** unique or primary key constraint on columns "id" */
  TeamsNamesPkey = 'teams_names_pkey'
}

/** input type for inserting data into table "teams_names" */
export type Teams_Names_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Teams_Names_Max_Fields = {
  __typename?: 'teams_names_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "teams_names" */
export type Teams_Names_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Teams_Names_Min_Fields = {
  __typename?: 'teams_names_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "teams_names" */
export type Teams_Names_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams_names" */
export type Teams_Names_Mutation_Response = {
  __typename?: 'teams_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teams_Names>;
};

/** on_conflict condition type for table "teams_names" */
export type Teams_Names_On_Conflict = {
  constraint: Teams_Names_Constraint;
  update_columns?: Array<Teams_Names_Update_Column>;
  where?: InputMaybe<Teams_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "teams_names". */
export type Teams_Names_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: teams_names */
export type Teams_Names_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "teams_names" */
export enum Teams_Names_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "teams_names" */
export type Teams_Names_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "teams_names" */
export type Teams_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Names_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "teams_names" */
export enum Teams_Names_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TeamId = 'team_id'
}

export type Teams_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Teams_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Teams_Names_Bool_Exp;
};

/** input type for inserting object relation for remote table "teams" */
export type Teams_Obj_Rel_Insert_Input = {
  data: Teams_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_On_Conflict>;
};

/** on_conflict condition type for table "teams" */
export type Teams_On_Conflict = {
  constraint: Teams_Constraint;
  update_columns?: Array<Teams_Update_Column>;
  where?: InputMaybe<Teams_Bool_Exp>;
};

/** Ordering options when selecting data from "teams". */
export type Teams_Order_By = {
  accept_mentoring?: InputMaybe<Order_By>;
  cnpj?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  diagnostics_answers_aggregate?: InputMaybe<Diagnostics_Questions_Answers_Aggregate_Order_By>;
  disabled?: InputMaybe<Order_By>;
  groups_aggregate?: InputMaybe<Groups_Teams_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Files_Order_By>;
  logo_file_id?: InputMaybe<Order_By>;
  members_aggregate?: InputMaybe<Teams_Users_Aggregate_Order_By>;
  mentors_blablablas_aggregate?: InputMaybe<Mentors_Blablabla_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  names_aggregate?: InputMaybe<Teams_Names_Aggregate_Order_By>;
  questionnaires_states_aggregate?: InputMaybe<Questionnaires_Teams_States_Aggregate_Order_By>;
  questions_answers_aggregate?: InputMaybe<Questions_Answers_Aggregate_Order_By>;
  social_media?: InputMaybe<Social_Medias_Order_By>;
  social_media_id?: InputMaybe<Order_By>;
  tags_aggregate?: InputMaybe<Teams_Tags_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_invitations_aggregate?: InputMaybe<Team_Invites_Aggregate_Order_By>;
};

/** primary key columns input for table: teams */
export type Teams_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "teams_scores" */
export type Teams_Scores = {
  __typename?: 'teams_scores';
  available?: Maybe<Scalars['bigint']>;
  spent?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  team?: Maybe<Teams>;
  team_id?: Maybe<Scalars['uuid']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "teams_scores" */
export type Teams_Scores_Aggregate = {
  __typename?: 'teams_scores_aggregate';
  aggregate?: Maybe<Teams_Scores_Aggregate_Fields>;
  nodes: Array<Teams_Scores>;
};

/** aggregate fields of "teams_scores" */
export type Teams_Scores_Aggregate_Fields = {
  __typename?: 'teams_scores_aggregate_fields';
  avg?: Maybe<Teams_Scores_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Teams_Scores_Max_Fields>;
  min?: Maybe<Teams_Scores_Min_Fields>;
  stddev?: Maybe<Teams_Scores_Stddev_Fields>;
  stddev_pop?: Maybe<Teams_Scores_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Teams_Scores_Stddev_Samp_Fields>;
  sum?: Maybe<Teams_Scores_Sum_Fields>;
  var_pop?: Maybe<Teams_Scores_Var_Pop_Fields>;
  var_samp?: Maybe<Teams_Scores_Var_Samp_Fields>;
  variance?: Maybe<Teams_Scores_Variance_Fields>;
};


/** aggregate fields of "teams_scores" */
export type Teams_Scores_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Teams_Scores_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Teams_Scores_Avg_Fields = {
  __typename?: 'teams_scores_avg_fields';
  available?: Maybe<Scalars['Float']>;
  spent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "teams_scores". All fields are combined with a logical 'AND'. */
export type Teams_Scores_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Scores_Bool_Exp>>;
  _not?: InputMaybe<Teams_Scores_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Scores_Bool_Exp>>;
  available?: InputMaybe<Bigint_Comparison_Exp>;
  spent?: InputMaybe<Bigint_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  total?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Teams_Scores_Max_Fields = {
  __typename?: 'teams_scores_max_fields';
  available?: Maybe<Scalars['bigint']>;
  spent?: Maybe<Scalars['bigint']>;
  team_id?: Maybe<Scalars['uuid']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Teams_Scores_Min_Fields = {
  __typename?: 'teams_scores_min_fields';
  available?: Maybe<Scalars['bigint']>;
  spent?: Maybe<Scalars['bigint']>;
  team_id?: Maybe<Scalars['uuid']>;
  total?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "teams_scores". */
export type Teams_Scores_Order_By = {
  available?: InputMaybe<Order_By>;
  spent?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "teams_scores" */
export enum Teams_Scores_Select_Column {
  /** column name */
  Available = 'available',
  /** column name */
  Spent = 'spent',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Teams_Scores_Stddev_Fields = {
  __typename?: 'teams_scores_stddev_fields';
  available?: Maybe<Scalars['Float']>;
  spent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Teams_Scores_Stddev_Pop_Fields = {
  __typename?: 'teams_scores_stddev_pop_fields';
  available?: Maybe<Scalars['Float']>;
  spent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Teams_Scores_Stddev_Samp_Fields = {
  __typename?: 'teams_scores_stddev_samp_fields';
  available?: Maybe<Scalars['Float']>;
  spent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "teams_scores" */
export type Teams_Scores_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Scores_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Scores_Stream_Cursor_Value_Input = {
  available?: InputMaybe<Scalars['bigint']>;
  spent?: InputMaybe<Scalars['bigint']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  total?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Teams_Scores_Sum_Fields = {
  __typename?: 'teams_scores_sum_fields';
  available?: Maybe<Scalars['bigint']>;
  spent?: Maybe<Scalars['bigint']>;
  total?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Teams_Scores_Var_Pop_Fields = {
  __typename?: 'teams_scores_var_pop_fields';
  available?: Maybe<Scalars['Float']>;
  spent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Teams_Scores_Var_Samp_Fields = {
  __typename?: 'teams_scores_var_samp_fields';
  available?: Maybe<Scalars['Float']>;
  spent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Teams_Scores_Variance_Fields = {
  __typename?: 'teams_scores_variance_fields';
  available?: Maybe<Scalars['Float']>;
  spent?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** select columns of table "teams" */
export enum Teams_Select_Column {
  /** column name */
  AcceptMentoring = 'accept_mentoring',
  /** column name */
  Cnpj = 'cnpj',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  LogoFileId = 'logo_file_id',
  /** column name */
  SocialMediaId = 'social_media_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "teams_aggregate_bool_exp_bool_and_arguments_columns" columns of table "teams" */
export enum Teams_Select_Column_Teams_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AcceptMentoring = 'accept_mentoring',
  /** column name */
  Disabled = 'disabled'
}

/** select "teams_aggregate_bool_exp_bool_or_arguments_columns" columns of table "teams" */
export enum Teams_Select_Column_Teams_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AcceptMentoring = 'accept_mentoring',
  /** column name */
  Disabled = 'disabled'
}

/** input type for updating data in table "teams" */
export type Teams_Set_Input = {
  accept_mentoring?: InputMaybe<Scalars['Boolean']>;
  cnpj?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  logo_file_id?: InputMaybe<Scalars['uuid']>;
  social_media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "teams" */
export type Teams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Stream_Cursor_Value_Input = {
  accept_mentoring?: InputMaybe<Scalars['Boolean']>;
  cnpj?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  logo_file_id?: InputMaybe<Scalars['uuid']>;
  social_media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "teams_tags" */
export type Teams_Tags = {
  __typename?: 'teams_tags';
  id: Scalars['uuid'];
  /** An object relationship */
  tag: Tags;
  tag_id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
};

/** aggregated selection of "teams_tags" */
export type Teams_Tags_Aggregate = {
  __typename?: 'teams_tags_aggregate';
  aggregate?: Maybe<Teams_Tags_Aggregate_Fields>;
  nodes: Array<Teams_Tags>;
};

export type Teams_Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Teams_Tags_Aggregate_Bool_Exp_Count>;
};

export type Teams_Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Teams_Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "teams_tags" */
export type Teams_Tags_Aggregate_Fields = {
  __typename?: 'teams_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Teams_Tags_Max_Fields>;
  min?: Maybe<Teams_Tags_Min_Fields>;
};


/** aggregate fields of "teams_tags" */
export type Teams_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Teams_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teams_tags" */
export type Teams_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Tags_Max_Order_By>;
  min?: InputMaybe<Teams_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "teams_tags" */
export type Teams_Tags_Arr_Rel_Insert_Input = {
  data: Array<Teams_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "teams_tags". All fields are combined with a logical 'AND'. */
export type Teams_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Tags_Bool_Exp>>;
  _not?: InputMaybe<Teams_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Tags_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tag?: InputMaybe<Tags_Bool_Exp>;
  tag_id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams_tags" */
export enum Teams_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamsTagsPkey = 'teams_tags_pkey',
  /** unique or primary key constraint on columns "tag_id", "team_id" */
  TeamsTagsTeamIdTagIdKey = 'teams_tags_team_id_tag_id_key'
}

/** input type for inserting data into table "teams_tags" */
export type Teams_Tags_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Teams_Tags_Max_Fields = {
  __typename?: 'teams_tags_max_fields';
  id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "teams_tags" */
export type Teams_Tags_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Teams_Tags_Min_Fields = {
  __typename?: 'teams_tags_min_fields';
  id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "teams_tags" */
export type Teams_Tags_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams_tags" */
export type Teams_Tags_Mutation_Response = {
  __typename?: 'teams_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teams_Tags>;
};

/** on_conflict condition type for table "teams_tags" */
export type Teams_Tags_On_Conflict = {
  constraint: Teams_Tags_Constraint;
  update_columns?: Array<Teams_Tags_Update_Column>;
  where?: InputMaybe<Teams_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "teams_tags". */
export type Teams_Tags_Order_By = {
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Tags_Order_By>;
  tag_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: teams_tags */
export type Teams_Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "teams_tags" */
export enum Teams_Tags_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TeamId = 'team_id'
}

/** input type for updating data in table "teams_tags" */
export type Teams_Tags_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "teams_tags" */
export type Teams_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Tags_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "teams_tags" */
export enum Teams_Tags_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TeamId = 'team_id'
}

export type Teams_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Teams_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Teams_Tags_Bool_Exp;
};

/** update columns of table "teams" */
export enum Teams_Update_Column {
  /** column name */
  AcceptMentoring = 'accept_mentoring',
  /** column name */
  Cnpj = 'cnpj',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  LogoFileId = 'logo_file_id',
  /** column name */
  SocialMediaId = 'social_media_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Teams_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Teams_Set_Input>;
  /** filter the rows which have to be updated */
  where: Teams_Bool_Exp;
};

/** columns and relationships of "teams_users" */
export type Teams_Users = {
  __typename?: 'teams_users';
  id: Scalars['uuid'];
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "teams_users" */
export type Teams_Users_Aggregate = {
  __typename?: 'teams_users_aggregate';
  aggregate?: Maybe<Teams_Users_Aggregate_Fields>;
  nodes: Array<Teams_Users>;
};

export type Teams_Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Teams_Users_Aggregate_Bool_Exp_Count>;
};

export type Teams_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Teams_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Teams_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "teams_users" */
export type Teams_Users_Aggregate_Fields = {
  __typename?: 'teams_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Teams_Users_Max_Fields>;
  min?: Maybe<Teams_Users_Min_Fields>;
};


/** aggregate fields of "teams_users" */
export type Teams_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Teams_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "teams_users" */
export type Teams_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Users_Max_Order_By>;
  min?: InputMaybe<Teams_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "teams_users" */
export type Teams_Users_Arr_Rel_Insert_Input = {
  data: Array<Teams_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "teams_users". All fields are combined with a logical 'AND'. */
export type Teams_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Users_Bool_Exp>>;
  _not?: InputMaybe<Teams_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Users_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams_users" */
export enum Teams_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamsUsersPkey = 'teams_users_pkey',
  /** unique or primary key constraint on columns "user_id", "team_id" */
  TeamsUsersTeamIdUserIdKey = 'teams_users_team_id_user_id_key'
}

/** input type for inserting data into table "teams_users" */
export type Teams_Users_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Teams_Users_Max_Fields = {
  __typename?: 'teams_users_max_fields';
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "teams_users" */
export type Teams_Users_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Teams_Users_Min_Fields = {
  __typename?: 'teams_users_min_fields';
  id?: Maybe<Scalars['uuid']>;
  team_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "teams_users" */
export type Teams_Users_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams_users" */
export type Teams_Users_Mutation_Response = {
  __typename?: 'teams_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teams_Users>;
};

/** on_conflict condition type for table "teams_users" */
export type Teams_Users_On_Conflict = {
  constraint: Teams_Users_Constraint;
  update_columns?: Array<Teams_Users_Update_Column>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "teams_users". */
export type Teams_Users_Order_By = {
  id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: teams_users */
export type Teams_Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "teams_users" */
export enum Teams_Users_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "teams_users" */
export type Teams_Users_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "teams_users" */
export type Teams_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Users_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "teams_users" */
export enum Teams_Users_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UserId = 'user_id'
}

export type Teams_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Teams_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Teams_Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "tools" */
export type Tools = {
  __typename?: 'tools';
  explanation: Scalars['String'];
  /** An array relationship */
  icons: Array<Tools_Icons>;
  /** An aggregate relationship */
  icons_aggregate: Tools_Icons_Aggregate;
  id: Scalars['uuid'];
  intro: Scalars['String'];
  /** An array relationship */
  mentors_blablablas: Array<Mentors_Blablabla>;
  /** An aggregate relationship */
  mentors_blablablas_aggregate: Mentors_Blablabla_Aggregate;
  /** An object relationship */
  multimedia_content: Multimedia_Contents;
  multimedia_content_id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  outputs: Array<Outputs_Tools>;
  /** An aggregate relationship */
  outputs_aggregate: Outputs_Tools_Aggregate;
  points: Scalars['smallint'];
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** An aggregate relationship */
  questionnaires_aggregate: Questionnaires_Aggregate;
  slug: Scalars['String'];
};


/** columns and relationships of "tools" */
export type ToolsIconsArgs = {
  distinct_on?: InputMaybe<Array<Tools_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Icons_Order_By>>;
  where?: InputMaybe<Tools_Icons_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsIcons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tools_Icons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tools_Icons_Order_By>>;
  where?: InputMaybe<Tools_Icons_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsMentors_BlablablasArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsMentors_Blablablas_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mentors_Blablabla_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mentors_Blablabla_Order_By>>;
  where?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsOutputsArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsOutputs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Outputs_Tools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Outputs_Tools_Order_By>>;
  where?: InputMaybe<Outputs_Tools_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};


/** columns and relationships of "tools" */
export type ToolsQuestionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** aggregated selection of "tools" */
export type Tools_Aggregate = {
  __typename?: 'tools_aggregate';
  aggregate?: Maybe<Tools_Aggregate_Fields>;
  nodes: Array<Tools>;
};

/** aggregate fields of "tools" */
export type Tools_Aggregate_Fields = {
  __typename?: 'tools_aggregate_fields';
  avg?: Maybe<Tools_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tools_Max_Fields>;
  min?: Maybe<Tools_Min_Fields>;
  stddev?: Maybe<Tools_Stddev_Fields>;
  stddev_pop?: Maybe<Tools_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tools_Stddev_Samp_Fields>;
  sum?: Maybe<Tools_Sum_Fields>;
  var_pop?: Maybe<Tools_Var_Pop_Fields>;
  var_samp?: Maybe<Tools_Var_Samp_Fields>;
  variance?: Maybe<Tools_Variance_Fields>;
};


/** aggregate fields of "tools" */
export type Tools_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tools_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tools_Avg_Fields = {
  __typename?: 'tools_avg_fields';
  points?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tools". All fields are combined with a logical 'AND'. */
export type Tools_Bool_Exp = {
  _and?: InputMaybe<Array<Tools_Bool_Exp>>;
  _not?: InputMaybe<Tools_Bool_Exp>;
  _or?: InputMaybe<Array<Tools_Bool_Exp>>;
  explanation?: InputMaybe<String_Comparison_Exp>;
  icons?: InputMaybe<Tools_Icons_Bool_Exp>;
  icons_aggregate?: InputMaybe<Tools_Icons_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  intro?: InputMaybe<String_Comparison_Exp>;
  mentors_blablablas?: InputMaybe<Mentors_Blablabla_Bool_Exp>;
  mentors_blablablas_aggregate?: InputMaybe<Mentors_Blablabla_Aggregate_Bool_Exp>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Bool_Exp>;
  multimedia_content_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  outputs?: InputMaybe<Outputs_Tools_Bool_Exp>;
  outputs_aggregate?: InputMaybe<Outputs_Tools_Aggregate_Bool_Exp>;
  points?: InputMaybe<Smallint_Comparison_Exp>;
  questionnaires?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaires_aggregate?: InputMaybe<Questionnaires_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tools" */
export enum Tools_Constraint {
  /** unique or primary key constraint on columns "id" */
  ToolsIdKey = 'tools_id_key',
  /** unique or primary key constraint on columns "id" */
  ToolsPkey = 'tools_pkey',
  /** unique or primary key constraint on columns "id", "slug" */
  ToolsSlugIdKey = 'tools_slug_id_key'
}

/** columns and relationships of "tools_icons" */
export type Tools_Icons = {
  __typename?: 'tools_icons';
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  tool: Tools;
  tool_id: Scalars['uuid'];
  type: Icons_Types_Enum;
};

/** aggregated selection of "tools_icons" */
export type Tools_Icons_Aggregate = {
  __typename?: 'tools_icons_aggregate';
  aggregate?: Maybe<Tools_Icons_Aggregate_Fields>;
  nodes: Array<Tools_Icons>;
};

export type Tools_Icons_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tools_Icons_Aggregate_Bool_Exp_Count>;
};

export type Tools_Icons_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tools_Icons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tools_Icons_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tools_icons" */
export type Tools_Icons_Aggregate_Fields = {
  __typename?: 'tools_icons_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tools_Icons_Max_Fields>;
  min?: Maybe<Tools_Icons_Min_Fields>;
};


/** aggregate fields of "tools_icons" */
export type Tools_Icons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tools_Icons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tools_icons" */
export type Tools_Icons_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tools_Icons_Max_Order_By>;
  min?: InputMaybe<Tools_Icons_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tools_icons" */
export type Tools_Icons_Arr_Rel_Insert_Input = {
  data: Array<Tools_Icons_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tools_Icons_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tools_icons". All fields are combined with a logical 'AND'. */
export type Tools_Icons_Bool_Exp = {
  _and?: InputMaybe<Array<Tools_Icons_Bool_Exp>>;
  _not?: InputMaybe<Tools_Icons_Bool_Exp>;
  _or?: InputMaybe<Array<Tools_Icons_Bool_Exp>>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tool?: InputMaybe<Tools_Bool_Exp>;
  tool_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Icons_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "tools_icons" */
export enum Tools_Icons_Constraint {
  /** unique or primary key constraint on columns "file_id", "tool_id" */
  ToolsIconsFileIdToolIdKey = 'tools_icons_file_id_tool_id_key',
  /** unique or primary key constraint on columns "id" */
  ToolsIconsIdKey = 'tools_icons_id_key',
  /** unique or primary key constraint on columns "id" */
  ToolsIconsPkey = 'tools_icons_pkey',
  /** unique or primary key constraint on columns "type", "tool_id" */
  ToolsIconsToolIdTypeKey = 'tools_icons_tool_id_type_key'
}

/** input type for inserting data into table "tools_icons" */
export type Tools_Icons_Insert_Input = {
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  tool?: InputMaybe<Tools_Obj_Rel_Insert_Input>;
  tool_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Icons_Types_Enum>;
};

/** aggregate max on columns */
export type Tools_Icons_Max_Fields = {
  __typename?: 'tools_icons_max_fields';
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tools_icons" */
export type Tools_Icons_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tools_Icons_Min_Fields = {
  __typename?: 'tools_icons_min_fields';
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tools_icons" */
export type Tools_Icons_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tools_icons" */
export type Tools_Icons_Mutation_Response = {
  __typename?: 'tools_icons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tools_Icons>;
};

/** on_conflict condition type for table "tools_icons" */
export type Tools_Icons_On_Conflict = {
  constraint: Tools_Icons_Constraint;
  update_columns?: Array<Tools_Icons_Update_Column>;
  where?: InputMaybe<Tools_Icons_Bool_Exp>;
};

/** Ordering options when selecting data from "tools_icons". */
export type Tools_Icons_Order_By = {
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tool?: InputMaybe<Tools_Order_By>;
  tool_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tools_icons */
export type Tools_Icons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tools_icons" */
export enum Tools_Icons_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "tools_icons" */
export type Tools_Icons_Set_Input = {
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  tool_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Icons_Types_Enum>;
};

/** Streaming cursor of the table "tools_icons" */
export type Tools_Icons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tools_Icons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tools_Icons_Stream_Cursor_Value_Input = {
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  tool_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Icons_Types_Enum>;
};

/** update columns of table "tools_icons" */
export enum Tools_Icons_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  Type = 'type'
}

export type Tools_Icons_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tools_Icons_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tools_Icons_Bool_Exp;
};

/** input type for incrementing numeric columns in table "tools" */
export type Tools_Inc_Input = {
  points?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "tools" */
export type Tools_Insert_Input = {
  explanation?: InputMaybe<Scalars['String']>;
  icons?: InputMaybe<Tools_Icons_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  intro?: InputMaybe<Scalars['String']>;
  mentors_blablablas?: InputMaybe<Mentors_Blablabla_Arr_Rel_Insert_Input>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Obj_Rel_Insert_Input>;
  multimedia_content_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  outputs?: InputMaybe<Outputs_Tools_Arr_Rel_Insert_Input>;
  points?: InputMaybe<Scalars['smallint']>;
  questionnaires?: InputMaybe<Questionnaires_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tools_Max_Fields = {
  __typename?: 'tools_max_fields';
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  multimedia_content_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['smallint']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tools_Min_Fields = {
  __typename?: 'tools_min_fields';
  explanation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  intro?: Maybe<Scalars['String']>;
  multimedia_content_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['smallint']>;
  slug?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "tools" */
export type Tools_Mutation_Response = {
  __typename?: 'tools_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tools>;
};

/** input type for inserting object relation for remote table "tools" */
export type Tools_Obj_Rel_Insert_Input = {
  data: Tools_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tools_On_Conflict>;
};

/** on_conflict condition type for table "tools" */
export type Tools_On_Conflict = {
  constraint: Tools_Constraint;
  update_columns?: Array<Tools_Update_Column>;
  where?: InputMaybe<Tools_Bool_Exp>;
};

/** Ordering options when selecting data from "tools". */
export type Tools_Order_By = {
  explanation?: InputMaybe<Order_By>;
  icons_aggregate?: InputMaybe<Tools_Icons_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  intro?: InputMaybe<Order_By>;
  mentors_blablablas_aggregate?: InputMaybe<Mentors_Blablabla_Aggregate_Order_By>;
  multimedia_content?: InputMaybe<Multimedia_Contents_Order_By>;
  multimedia_content_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  outputs_aggregate?: InputMaybe<Outputs_Tools_Aggregate_Order_By>;
  points?: InputMaybe<Order_By>;
  questionnaires_aggregate?: InputMaybe<Questionnaires_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tools */
export type Tools_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tools" */
export enum Tools_Select_Column {
  /** column name */
  Explanation = 'explanation',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  MultimediaContentId = 'multimedia_content_id',
  /** column name */
  Name = 'name',
  /** column name */
  Points = 'points',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "tools" */
export type Tools_Set_Input = {
  explanation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  intro?: InputMaybe<Scalars['String']>;
  multimedia_content_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  points?: InputMaybe<Scalars['smallint']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Tools_Stddev_Fields = {
  __typename?: 'tools_stddev_fields';
  points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tools_Stddev_Pop_Fields = {
  __typename?: 'tools_stddev_pop_fields';
  points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tools_Stddev_Samp_Fields = {
  __typename?: 'tools_stddev_samp_fields';
  points?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "tools" */
export type Tools_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tools_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tools_Stream_Cursor_Value_Input = {
  explanation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  intro?: InputMaybe<Scalars['String']>;
  multimedia_content_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  points?: InputMaybe<Scalars['smallint']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Tools_Sum_Fields = {
  __typename?: 'tools_sum_fields';
  points?: Maybe<Scalars['smallint']>;
};

/** update columns of table "tools" */
export enum Tools_Update_Column {
  /** column name */
  Explanation = 'explanation',
  /** column name */
  Id = 'id',
  /** column name */
  Intro = 'intro',
  /** column name */
  MultimediaContentId = 'multimedia_content_id',
  /** column name */
  Name = 'name',
  /** column name */
  Points = 'points',
  /** column name */
  Slug = 'slug'
}

export type Tools_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tools_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tools_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tools_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tools_Var_Pop_Fields = {
  __typename?: 'tools_var_pop_fields';
  points?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tools_Var_Samp_Fields = {
  __typename?: 'tools_var_samp_fields';
  points?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tools_Variance_Fields = {
  __typename?: 'tools_variance_fields';
  points?: Maybe<Scalars['Float']>;
};

/** Additional personal user info */
export type User_Info = {
  __typename?: 'user_info';
  address?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  phone?: Maybe<Scalars['String']>;
  second_line?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "user_info" */
export type User_Info_Aggregate = {
  __typename?: 'user_info_aggregate';
  aggregate?: Maybe<User_Info_Aggregate_Fields>;
  nodes: Array<User_Info>;
};

/** aggregate fields of "user_info" */
export type User_Info_Aggregate_Fields = {
  __typename?: 'user_info_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Info_Max_Fields>;
  min?: Maybe<User_Info_Min_Fields>;
};


/** aggregate fields of "user_info" */
export type User_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_info". All fields are combined with a logical 'AND'. */
export type User_Info_Bool_Exp = {
  _and?: InputMaybe<Array<User_Info_Bool_Exp>>;
  _not?: InputMaybe<User_Info_Bool_Exp>;
  _or?: InputMaybe<Array<User_Info_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  cep?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  dob?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  second_line?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "user_info" */
export enum User_Info_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserInfoPkey = 'user_info_pkey'
}

/** input type for inserting data into table "user_info" */
export type User_Info_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  cep?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  phone?: InputMaybe<Scalars['String']>;
  second_line?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Info_Max_Fields = {
  __typename?: 'user_info_max_fields';
  address?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  second_line?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Info_Min_Fields = {
  __typename?: 'user_info_min_fields';
  address?: Maybe<Scalars['String']>;
  cep?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  second_line?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_info" */
export type User_Info_Mutation_Response = {
  __typename?: 'user_info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Info>;
};

/** input type for inserting object relation for remote table "user_info" */
export type User_Info_Obj_Rel_Insert_Input = {
  data: User_Info_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Info_On_Conflict>;
};

/** on_conflict condition type for table "user_info" */
export type User_Info_On_Conflict = {
  constraint: User_Info_Constraint;
  update_columns?: Array<User_Info_Update_Column>;
  where?: InputMaybe<User_Info_Bool_Exp>;
};

/** Ordering options when selecting data from "user_info". */
export type User_Info_Order_By = {
  address?: InputMaybe<Order_By>;
  cep?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  dob?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  second_line?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: user_info */
export type User_Info_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_info" */
export enum User_Info_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Cep = 'cep',
  /** column name */
  City = 'city',
  /** column name */
  Dob = 'dob',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  SecondLine = 'second_line',
  /** column name */
  State = 'state'
}

/** input type for updating data in table "user_info" */
export type User_Info_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  cep?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  phone?: InputMaybe<Scalars['String']>;
  second_line?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_info" */
export type User_Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Info_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Info_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  cep?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  phone?: InputMaybe<Scalars['String']>;
  second_line?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_info" */
export enum User_Info_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Cep = 'cep',
  /** column name */
  City = 'city',
  /** column name */
  Dob = 'dob',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  SecondLine = 'second_line',
  /** column name */
  State = 'state'
}

export type User_Info_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Info_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Info_Bool_Exp;
};

/** columns and relationships of "user_invites" */
export type User_Invites = {
  __typename?: 'user_invites';
  consumed?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  invited_by?: Maybe<Scalars['uuid']>;
  preregistered: Scalars['Boolean'];
  role: Roles_Enum;
  /** An object relationship */
  team?: Maybe<Teams>;
  token: Scalars['String'];
};

/** aggregated selection of "user_invites" */
export type User_Invites_Aggregate = {
  __typename?: 'user_invites_aggregate';
  aggregate?: Maybe<User_Invites_Aggregate_Fields>;
  nodes: Array<User_Invites>;
};

/** aggregate fields of "user_invites" */
export type User_Invites_Aggregate_Fields = {
  __typename?: 'user_invites_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Invites_Max_Fields>;
  min?: Maybe<User_Invites_Min_Fields>;
};


/** aggregate fields of "user_invites" */
export type User_Invites_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Invites_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_invites". All fields are combined with a logical 'AND'. */
export type User_Invites_Bool_Exp = {
  _and?: InputMaybe<Array<User_Invites_Bool_Exp>>;
  _not?: InputMaybe<User_Invites_Bool_Exp>;
  _or?: InputMaybe<Array<User_Invites_Bool_Exp>>;
  consumed?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invited_by?: InputMaybe<Uuid_Comparison_Exp>;
  preregistered?: InputMaybe<Boolean_Comparison_Exp>;
  role?: InputMaybe<Roles_Enum_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_invites" */
export enum User_Invites_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserInvitesIdKey = 'user_invites_id_key',
  /** unique or primary key constraint on columns "id", "token" */
  UserInvitesPkey = 'user_invites_pkey',
  /** unique or primary key constraint on columns "token" */
  UserInvitesTokenKey = 'user_invites_token_key'
}

/** input type for inserting data into table "user_invites" */
export type User_Invites_Insert_Input = {
  consumed?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  preregistered?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Roles_Enum>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  token?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Invites_Max_Fields = {
  __typename?: 'user_invites_max_fields';
  consumed?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invited_by?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Invites_Min_Fields = {
  __typename?: 'user_invites_min_fields';
  consumed?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invited_by?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_invites" */
export type User_Invites_Mutation_Response = {
  __typename?: 'user_invites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Invites>;
};

/** on_conflict condition type for table "user_invites" */
export type User_Invites_On_Conflict = {
  constraint: User_Invites_Constraint;
  update_columns?: Array<User_Invites_Update_Column>;
  where?: InputMaybe<User_Invites_Bool_Exp>;
};

/** Ordering options when selecting data from "user_invites". */
export type User_Invites_Order_By = {
  consumed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  preregistered?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  token?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_invites */
export type User_Invites_Pk_Columns_Input = {
  id: Scalars['uuid'];
  token: Scalars['String'];
};

/** select columns of table "user_invites" */
export enum User_Invites_Select_Column {
  /** column name */
  Consumed = 'consumed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  Preregistered = 'preregistered',
  /** column name */
  Role = 'role',
  /** column name */
  Token = 'token'
}

/** input type for updating data in table "user_invites" */
export type User_Invites_Set_Input = {
  consumed?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  preregistered?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Roles_Enum>;
  token?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_invites" */
export type User_Invites_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Invites_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Invites_Stream_Cursor_Value_Input = {
  consumed?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  preregistered?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Roles_Enum>;
  token?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_invites" */
export enum User_Invites_Update_Column {
  /** column name */
  Consumed = 'consumed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  Preregistered = 'preregistered',
  /** column name */
  Role = 'role',
  /** column name */
  Token = 'token'
}

export type User_Invites_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Invites_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Invites_Bool_Exp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An array relationship */
  actions: Array<Users_Actions>;
  /** An aggregate relationship */
  actions_aggregate: Users_Actions_Aggregate;
  cpf: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deactivated_since?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  documents: Array<Users_Documents>;
  /** An aggregate relationship */
  documents_aggregate: Users_Documents_Aggregate;
  email: Scalars['String'];
  id: Scalars['String'];
  last_term_accepted: Scalars['timestamptz'];
  /** An array relationship */
  names: Array<Users_Names>;
  /** An aggregate relationship */
  names_aggregate: Users_Names_Aggregate;
  occupation?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile_picture?: Maybe<Files>;
  profile_picture_file_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  questions_answers: Array<Questions_Answers>;
  /** An aggregate relationship */
  questions_answers_aggregate: Questions_Answers_Aggregate;
  role: Roles_Enum;
  /** An object relationship */
  social_media?: Maybe<Social_Medias>;
  social_media_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  team_invitations: Array<Team_Invites>;
  /** An aggregate relationship */
  team_invitations_aggregate: Team_Invites_Aggregate;
  /** An array relationship */
  teams: Array<Teams_Users>;
  /** An aggregate relationship */
  teams_aggregate: Teams_Users_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user_info?: Maybe<User_Info>;
  user_info_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "users" */
export type UsersActionsArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Actions_Order_By>>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Order_By>>;
  where?: InputMaybe<Users_Documents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Documents_Order_By>>;
  where?: InputMaybe<Users_Documents_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNamesArgs = {
  distinct_on?: InputMaybe<Array<Users_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Names_Order_By>>;
  where?: InputMaybe<Users_Names_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNames_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Names_Order_By>>;
  where?: InputMaybe<Users_Names_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersQuestions_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersQuestions_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questions_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Questions_Answers_Order_By>>;
  where?: InputMaybe<Questions_Answers_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeam_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeam_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Team_Invites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Team_Invites_Order_By>>;
  where?: InputMaybe<Team_Invites_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTeams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Teams_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Teams_Users_Order_By>>;
  where?: InputMaybe<Teams_Users_Bool_Exp>;
};

/** columns and relationships of "users_actions" */
export type Users_Actions = {
  __typename?: 'users_actions';
  at_page_slug: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  mentoring_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  mentoring_record?: Maybe<Mentoring_Records>;
  previous_page_slug?: Maybe<Scalars['String']>;
  /** An object relationship */
  team: Teams;
  team_id: Scalars['uuid'];
  /** An object relationship */
  tool?: Maybe<Tools>;
  tool_id?: Maybe<Scalars['uuid']>;
  type: Users_Actions_Types_Enum;
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "users_actions" */
export type Users_Actions_Aggregate = {
  __typename?: 'users_actions_aggregate';
  aggregate?: Maybe<Users_Actions_Aggregate_Fields>;
  nodes: Array<Users_Actions>;
};

export type Users_Actions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Actions_Aggregate_Bool_Exp_Count>;
};

export type Users_Actions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Actions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_actions" */
export type Users_Actions_Aggregate_Fields = {
  __typename?: 'users_actions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Actions_Max_Fields>;
  min?: Maybe<Users_Actions_Min_Fields>;
};


/** aggregate fields of "users_actions" */
export type Users_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users_actions" */
export type Users_Actions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Actions_Max_Order_By>;
  min?: InputMaybe<Users_Actions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_actions" */
export type Users_Actions_Arr_Rel_Insert_Input = {
  data: Array<Users_Actions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Actions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_actions". All fields are combined with a logical 'AND'. */
export type Users_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Actions_Bool_Exp>>;
  _not?: InputMaybe<Users_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Actions_Bool_Exp>>;
  at_page_slug?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mentoring_id?: InputMaybe<Uuid_Comparison_Exp>;
  mentoring_record?: InputMaybe<Mentoring_Records_Bool_Exp>;
  previous_page_slug?: InputMaybe<String_Comparison_Exp>;
  team?: InputMaybe<Teams_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  tool?: InputMaybe<Tools_Bool_Exp>;
  tool_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Users_Actions_Types_Enum_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_actions" */
export enum Users_Actions_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersActionsPkey = 'users_actions_pkey'
}

/** input type for inserting data into table "users_actions" */
export type Users_Actions_Insert_Input = {
  at_page_slug?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  mentoring_id?: InputMaybe<Scalars['uuid']>;
  mentoring_record?: InputMaybe<Mentoring_Records_Obj_Rel_Insert_Input>;
  previous_page_slug?: InputMaybe<Scalars['String']>;
  team?: InputMaybe<Teams_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  tool?: InputMaybe<Tools_Obj_Rel_Insert_Input>;
  tool_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Users_Actions_Types_Enum>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Actions_Max_Fields = {
  __typename?: 'users_actions_max_fields';
  at_page_slug?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  mentoring_id?: Maybe<Scalars['uuid']>;
  previous_page_slug?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users_actions" */
export type Users_Actions_Max_Order_By = {
  at_page_slug?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mentoring_id?: InputMaybe<Order_By>;
  previous_page_slug?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Actions_Min_Fields = {
  __typename?: 'users_actions_min_fields';
  at_page_slug?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  mentoring_id?: Maybe<Scalars['uuid']>;
  previous_page_slug?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  tool_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users_actions" */
export type Users_Actions_Min_Order_By = {
  at_page_slug?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mentoring_id?: InputMaybe<Order_By>;
  previous_page_slug?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  tool_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_actions" */
export type Users_Actions_Mutation_Response = {
  __typename?: 'users_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Actions>;
};

/** on_conflict condition type for table "users_actions" */
export type Users_Actions_On_Conflict = {
  constraint: Users_Actions_Constraint;
  update_columns?: Array<Users_Actions_Update_Column>;
  where?: InputMaybe<Users_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "users_actions". */
export type Users_Actions_Order_By = {
  at_page_slug?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mentoring_id?: InputMaybe<Order_By>;
  mentoring_record?: InputMaybe<Mentoring_Records_Order_By>;
  previous_page_slug?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Order_By>;
  team_id?: InputMaybe<Order_By>;
  tool?: InputMaybe<Tools_Order_By>;
  tool_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_actions */
export type Users_Actions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users_actions" */
export enum Users_Actions_Select_Column {
  /** column name */
  AtPageSlug = 'at_page_slug',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MentoringId = 'mentoring_id',
  /** column name */
  PreviousPageSlug = 'previous_page_slug',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_actions" */
export type Users_Actions_Set_Input = {
  at_page_slug?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  mentoring_id?: InputMaybe<Scalars['uuid']>;
  previous_page_slug?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  tool_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Users_Actions_Types_Enum>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "users_actions" */
export type Users_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Actions_Stream_Cursor_Value_Input = {
  at_page_slug?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  mentoring_id?: InputMaybe<Scalars['uuid']>;
  previous_page_slug?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  tool_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Users_Actions_Types_Enum>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "users_actions_types" */
export type Users_Actions_Types = {
  __typename?: 'users_actions_types';
  type: Scalars['String'];
};

/** aggregated selection of "users_actions_types" */
export type Users_Actions_Types_Aggregate = {
  __typename?: 'users_actions_types_aggregate';
  aggregate?: Maybe<Users_Actions_Types_Aggregate_Fields>;
  nodes: Array<Users_Actions_Types>;
};

/** aggregate fields of "users_actions_types" */
export type Users_Actions_Types_Aggregate_Fields = {
  __typename?: 'users_actions_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Actions_Types_Max_Fields>;
  min?: Maybe<Users_Actions_Types_Min_Fields>;
};


/** aggregate fields of "users_actions_types" */
export type Users_Actions_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Actions_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users_actions_types". All fields are combined with a logical 'AND'. */
export type Users_Actions_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Actions_Types_Bool_Exp>>;
  _not?: InputMaybe<Users_Actions_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Actions_Types_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_actions_types" */
export enum Users_Actions_Types_Constraint {
  /** unique or primary key constraint on columns "type" */
  UsersActionsTypesPkey = 'users_actions_types_pkey'
}

export enum Users_Actions_Types_Enum {
  DeleteMentoring = 'DELETE_MENTORING',
  FinishTool = 'FINISH_TOOL',
  InsertMentoring = 'INSERT_MENTORING',
  InsertTool = 'INSERT_TOOL',
  InviteUser = 'INVITE_USER',
  Login = 'LOGIN',
  UpdateMentoring = 'UPDATE_MENTORING',
  UpdateProfile = 'UPDATE_PROFILE',
  UpdateTool = 'UPDATE_TOOL',
  Visit = 'VISIT'
}

/** Boolean expression to compare columns of type "users_actions_types_enum". All fields are combined with logical 'AND'. */
export type Users_Actions_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Users_Actions_Types_Enum>;
  _in?: InputMaybe<Array<Users_Actions_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Users_Actions_Types_Enum>;
  _nin?: InputMaybe<Array<Users_Actions_Types_Enum>>;
};

/** input type for inserting data into table "users_actions_types" */
export type Users_Actions_Types_Insert_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Actions_Types_Max_Fields = {
  __typename?: 'users_actions_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Users_Actions_Types_Min_Fields = {
  __typename?: 'users_actions_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users_actions_types" */
export type Users_Actions_Types_Mutation_Response = {
  __typename?: 'users_actions_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Actions_Types>;
};

/** on_conflict condition type for table "users_actions_types" */
export type Users_Actions_Types_On_Conflict = {
  constraint: Users_Actions_Types_Constraint;
  update_columns?: Array<Users_Actions_Types_Update_Column>;
  where?: InputMaybe<Users_Actions_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "users_actions_types". */
export type Users_Actions_Types_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_actions_types */
export type Users_Actions_Types_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "users_actions_types" */
export enum Users_Actions_Types_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "users_actions_types" */
export type Users_Actions_Types_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "users_actions_types" */
export type Users_Actions_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Actions_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Actions_Types_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "users_actions_types" */
export enum Users_Actions_Types_Update_Column {
  /** column name */
  Type = 'type'
}

export type Users_Actions_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Actions_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Actions_Types_Bool_Exp;
};

/** update columns of table "users_actions" */
export enum Users_Actions_Update_Column {
  /** column name */
  AtPageSlug = 'at_page_slug',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MentoringId = 'mentoring_id',
  /** column name */
  PreviousPageSlug = 'previous_page_slug',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  ToolId = 'tool_id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

export type Users_Actions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Actions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Actions_Bool_Exp;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  actions?: InputMaybe<Users_Actions_Bool_Exp>;
  actions_aggregate?: InputMaybe<Users_Actions_Aggregate_Bool_Exp>;
  cpf?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deactivated_since?: InputMaybe<Timestamptz_Comparison_Exp>;
  documents?: InputMaybe<Users_Documents_Bool_Exp>;
  documents_aggregate?: InputMaybe<Users_Documents_Aggregate_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  last_term_accepted?: InputMaybe<Timestamptz_Comparison_Exp>;
  names?: InputMaybe<Users_Names_Bool_Exp>;
  names_aggregate?: InputMaybe<Users_Names_Aggregate_Bool_Exp>;
  occupation?: InputMaybe<String_Comparison_Exp>;
  profile_picture?: InputMaybe<Files_Bool_Exp>;
  profile_picture_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  questions_answers?: InputMaybe<Questions_Answers_Bool_Exp>;
  questions_answers_aggregate?: InputMaybe<Questions_Answers_Aggregate_Bool_Exp>;
  role?: InputMaybe<Roles_Enum_Comparison_Exp>;
  social_media?: InputMaybe<Social_Medias_Bool_Exp>;
  social_media_id?: InputMaybe<Uuid_Comparison_Exp>;
  team_invitations?: InputMaybe<Team_Invites_Bool_Exp>;
  team_invitations_aggregate?: InputMaybe<Team_Invites_Aggregate_Bool_Exp>;
  teams?: InputMaybe<Teams_Users_Bool_Exp>;
  teams_aggregate?: InputMaybe<Teams_Users_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_info?: InputMaybe<User_Info_Bool_Exp>;
  user_info_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "cpf" */
  UsersCpfKey = 'users_cpf_key',
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "id" */
  UsersIdKey = 'users_id_key',
  /** unique or primary key constraint on columns "email", "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "user_info_id" */
  UsersUserInfoIdKey = 'users_user_info_id_key'
}

/** columns and relationships of "users_documents" */
export type Users_Documents = {
  __typename?: 'users_documents';
  document_type: Users_Documents_Types_Enum;
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "users_documents" */
export type Users_Documents_Aggregate = {
  __typename?: 'users_documents_aggregate';
  aggregate?: Maybe<Users_Documents_Aggregate_Fields>;
  nodes: Array<Users_Documents>;
};

export type Users_Documents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Documents_Aggregate_Bool_Exp_Count>;
};

export type Users_Documents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Documents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_documents" */
export type Users_Documents_Aggregate_Fields = {
  __typename?: 'users_documents_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Documents_Max_Fields>;
  min?: Maybe<Users_Documents_Min_Fields>;
};


/** aggregate fields of "users_documents" */
export type Users_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users_documents" */
export type Users_Documents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Documents_Max_Order_By>;
  min?: InputMaybe<Users_Documents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_documents" */
export type Users_Documents_Arr_Rel_Insert_Input = {
  data: Array<Users_Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Documents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_documents". All fields are combined with a logical 'AND'. */
export type Users_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Documents_Bool_Exp>>;
  _not?: InputMaybe<Users_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Documents_Bool_Exp>>;
  document_type?: InputMaybe<Users_Documents_Types_Enum_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_documents" */
export enum Users_Documents_Constraint {
  /** unique or primary key constraint on columns "user_id", "file_id" */
  UsersDocumentsPkey = 'users_documents_pkey'
}

/** input type for inserting data into table "users_documents" */
export type Users_Documents_Insert_Input = {
  document_type?: InputMaybe<Users_Documents_Types_Enum>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Documents_Max_Fields = {
  __typename?: 'users_documents_max_fields';
  file_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users_documents" */
export type Users_Documents_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Documents_Min_Fields = {
  __typename?: 'users_documents_min_fields';
  file_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users_documents" */
export type Users_Documents_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_documents" */
export type Users_Documents_Mutation_Response = {
  __typename?: 'users_documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Documents>;
};

/** on_conflict condition type for table "users_documents" */
export type Users_Documents_On_Conflict = {
  constraint: Users_Documents_Constraint;
  update_columns?: Array<Users_Documents_Update_Column>;
  where?: InputMaybe<Users_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "users_documents". */
export type Users_Documents_Order_By = {
  document_type?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_documents */
export type Users_Documents_Pk_Columns_Input = {
  file_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** select columns of table "users_documents" */
export enum Users_Documents_Select_Column {
  /** column name */
  DocumentType = 'document_type',
  /** column name */
  FileId = 'file_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_documents" */
export type Users_Documents_Set_Input = {
  document_type?: InputMaybe<Users_Documents_Types_Enum>;
  file_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "users_documents" */
export type Users_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Documents_Stream_Cursor_Value_Input = {
  document_type?: InputMaybe<Users_Documents_Types_Enum>;
  file_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "users_documents_types" */
export type Users_Documents_Types = {
  __typename?: 'users_documents_types';
  type: Scalars['String'];
};

/** aggregated selection of "users_documents_types" */
export type Users_Documents_Types_Aggregate = {
  __typename?: 'users_documents_types_aggregate';
  aggregate?: Maybe<Users_Documents_Types_Aggregate_Fields>;
  nodes: Array<Users_Documents_Types>;
};

/** aggregate fields of "users_documents_types" */
export type Users_Documents_Types_Aggregate_Fields = {
  __typename?: 'users_documents_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Documents_Types_Max_Fields>;
  min?: Maybe<Users_Documents_Types_Min_Fields>;
};


/** aggregate fields of "users_documents_types" */
export type Users_Documents_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Documents_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users_documents_types". All fields are combined with a logical 'AND'. */
export type Users_Documents_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Documents_Types_Bool_Exp>>;
  _not?: InputMaybe<Users_Documents_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Documents_Types_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_documents_types" */
export enum Users_Documents_Types_Constraint {
  /** unique or primary key constraint on columns "type" */
  UsersDocumentsTypesPkey = 'users_documents_types_pkey'
}

export enum Users_Documents_Types_Enum {
  Contract = 'contract',
  Identity = 'identity',
  Other = 'other'
}

/** Boolean expression to compare columns of type "users_documents_types_enum". All fields are combined with logical 'AND'. */
export type Users_Documents_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Users_Documents_Types_Enum>;
  _in?: InputMaybe<Array<Users_Documents_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Users_Documents_Types_Enum>;
  _nin?: InputMaybe<Array<Users_Documents_Types_Enum>>;
};

/** input type for inserting data into table "users_documents_types" */
export type Users_Documents_Types_Insert_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Documents_Types_Max_Fields = {
  __typename?: 'users_documents_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Users_Documents_Types_Min_Fields = {
  __typename?: 'users_documents_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users_documents_types" */
export type Users_Documents_Types_Mutation_Response = {
  __typename?: 'users_documents_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Documents_Types>;
};

/** on_conflict condition type for table "users_documents_types" */
export type Users_Documents_Types_On_Conflict = {
  constraint: Users_Documents_Types_Constraint;
  update_columns?: Array<Users_Documents_Types_Update_Column>;
  where?: InputMaybe<Users_Documents_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "users_documents_types". */
export type Users_Documents_Types_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_documents_types */
export type Users_Documents_Types_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "users_documents_types" */
export enum Users_Documents_Types_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "users_documents_types" */
export type Users_Documents_Types_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "users_documents_types" */
export type Users_Documents_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Documents_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Documents_Types_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "users_documents_types" */
export enum Users_Documents_Types_Update_Column {
  /** column name */
  Type = 'type'
}

export type Users_Documents_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Documents_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Documents_Types_Bool_Exp;
};

/** update columns of table "users_documents" */
export enum Users_Documents_Update_Column {
  /** column name */
  DocumentType = 'document_type',
  /** column name */
  FileId = 'file_id',
  /** column name */
  UserId = 'user_id'
}

export type Users_Documents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Documents_Bool_Exp;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  actions?: InputMaybe<Users_Actions_Arr_Rel_Insert_Input>;
  cpf?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_since?: InputMaybe<Scalars['timestamptz']>;
  documents?: InputMaybe<Users_Documents_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  last_term_accepted?: InputMaybe<Scalars['timestamptz']>;
  names?: InputMaybe<Users_Names_Arr_Rel_Insert_Input>;
  occupation?: InputMaybe<Scalars['String']>;
  profile_picture?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  profile_picture_file_id?: InputMaybe<Scalars['uuid']>;
  questions_answers?: InputMaybe<Questions_Answers_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Roles_Enum>;
  social_media?: InputMaybe<Social_Medias_Obj_Rel_Insert_Input>;
  social_media_id?: InputMaybe<Scalars['uuid']>;
  team_invitations?: InputMaybe<Team_Invites_Arr_Rel_Insert_Input>;
  teams?: InputMaybe<Teams_Users_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info?: InputMaybe<User_Info_Obj_Rel_Insert_Input>;
  user_info_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  cpf?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_since?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_term_accepted?: Maybe<Scalars['timestamptz']>;
  occupation?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['uuid']>;
  social_media_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_info_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_since?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_term_accepted?: InputMaybe<Order_By>;
  occupation?: InputMaybe<Order_By>;
  profile_picture_file_id?: InputMaybe<Order_By>;
  social_media_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  cpf?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_since?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_term_accepted?: Maybe<Scalars['timestamptz']>;
  occupation?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['uuid']>;
  social_media_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_info_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_since?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_term_accepted?: InputMaybe<Order_By>;
  occupation?: InputMaybe<Order_By>;
  profile_picture_file_id?: InputMaybe<Order_By>;
  social_media_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** columns and relationships of "users_names" */
export type Users_Names = {
  __typename?: 'users_names';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "users_names" */
export type Users_Names_Aggregate = {
  __typename?: 'users_names_aggregate';
  aggregate?: Maybe<Users_Names_Aggregate_Fields>;
  nodes: Array<Users_Names>;
};

export type Users_Names_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Names_Aggregate_Bool_Exp_Count>;
};

export type Users_Names_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Names_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_names" */
export type Users_Names_Aggregate_Fields = {
  __typename?: 'users_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Names_Max_Fields>;
  min?: Maybe<Users_Names_Min_Fields>;
};


/** aggregate fields of "users_names" */
export type Users_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users_names" */
export type Users_Names_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Names_Max_Order_By>;
  min?: InputMaybe<Users_Names_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users_names" */
export type Users_Names_Arr_Rel_Insert_Input = {
  data: Array<Users_Names_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Names_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_names". All fields are combined with a logical 'AND'. */
export type Users_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Names_Bool_Exp>>;
  _not?: InputMaybe<Users_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Names_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_names" */
export enum Users_Names_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersNamesPkey = 'users_names_pkey'
}

/** input type for inserting data into table "users_names" */
export type Users_Names_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Names_Max_Fields = {
  __typename?: 'users_names_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users_names" */
export type Users_Names_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Names_Min_Fields = {
  __typename?: 'users_names_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users_names" */
export type Users_Names_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_names" */
export type Users_Names_Mutation_Response = {
  __typename?: 'users_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Names>;
};

/** on_conflict condition type for table "users_names" */
export type Users_Names_On_Conflict = {
  constraint: Users_Names_Constraint;
  update_columns?: Array<Users_Names_Update_Column>;
  where?: InputMaybe<Users_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "users_names". */
export type Users_Names_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_names */
export type Users_Names_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users_names" */
export enum Users_Names_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_names" */
export type Users_Names_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "users_names" */
export type Users_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Names_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "users_names" */
export enum Users_Names_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'user_id'
}

export type Users_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Names_Bool_Exp;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  actions_aggregate?: InputMaybe<Users_Actions_Aggregate_Order_By>;
  cpf?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_since?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Users_Documents_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_term_accepted?: InputMaybe<Order_By>;
  names_aggregate?: InputMaybe<Users_Names_Aggregate_Order_By>;
  occupation?: InputMaybe<Order_By>;
  profile_picture?: InputMaybe<Files_Order_By>;
  profile_picture_file_id?: InputMaybe<Order_By>;
  questions_answers_aggregate?: InputMaybe<Questions_Answers_Aggregate_Order_By>;
  role?: InputMaybe<Order_By>;
  social_media?: InputMaybe<Social_Medias_Order_By>;
  social_media_id?: InputMaybe<Order_By>;
  team_invitations_aggregate?: InputMaybe<Team_Invites_Aggregate_Order_By>;
  teams_aggregate?: InputMaybe<Teams_Users_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_info?: InputMaybe<User_Info_Order_By>;
  user_info_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  email: Scalars['String'];
  id: Scalars['String'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Cpf = 'cpf',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedSince = 'deactivated_since',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LastTermAccepted = 'last_term_accepted',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  ProfilePictureFileId = 'profile_picture_file_id',
  /** column name */
  Role = 'role',
  /** column name */
  SocialMediaId = 'social_media_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfoId = 'user_info_id'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  cpf?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_since?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  last_term_accepted?: InputMaybe<Scalars['timestamptz']>;
  occupation?: InputMaybe<Scalars['String']>;
  profile_picture_file_id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Roles_Enum>;
  social_media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  cpf?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_since?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  last_term_accepted?: InputMaybe<Scalars['timestamptz']>;
  occupation?: InputMaybe<Scalars['String']>;
  profile_picture_file_id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Roles_Enum>;
  social_media_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_info_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Cpf = 'cpf',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedSince = 'deactivated_since',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LastTermAccepted = 'last_term_accepted',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  ProfilePictureFileId = 'profile_picture_file_id',
  /** column name */
  Role = 'role',
  /** column name */
  SocialMediaId = 'social_media_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserInfoId = 'user_info_id'
}

export type Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "video_repositories" */
export type Video_Repositories = {
  __typename?: 'video_repositories';
  /** An object relationship */
  content: Contents;
  content_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An array relationship */
  videos: Array<Videos>;
  /** An aggregate relationship */
  videos_aggregate: Videos_Aggregate;
  youtube_channel_id: Scalars['String'];
};


/** columns and relationships of "video_repositories" */
export type Video_RepositoriesVideosArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};


/** columns and relationships of "video_repositories" */
export type Video_RepositoriesVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Order_By>>;
  where?: InputMaybe<Videos_Bool_Exp>;
};

/** aggregated selection of "video_repositories" */
export type Video_Repositories_Aggregate = {
  __typename?: 'video_repositories_aggregate';
  aggregate?: Maybe<Video_Repositories_Aggregate_Fields>;
  nodes: Array<Video_Repositories>;
};

export type Video_Repositories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Video_Repositories_Aggregate_Bool_Exp_Count>;
};

export type Video_Repositories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Video_Repositories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Video_Repositories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "video_repositories" */
export type Video_Repositories_Aggregate_Fields = {
  __typename?: 'video_repositories_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Video_Repositories_Max_Fields>;
  min?: Maybe<Video_Repositories_Min_Fields>;
};


/** aggregate fields of "video_repositories" */
export type Video_Repositories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Video_Repositories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "video_repositories" */
export type Video_Repositories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Video_Repositories_Max_Order_By>;
  min?: InputMaybe<Video_Repositories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "video_repositories" */
export type Video_Repositories_Arr_Rel_Insert_Input = {
  data: Array<Video_Repositories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Video_Repositories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "video_repositories". All fields are combined with a logical 'AND'. */
export type Video_Repositories_Bool_Exp = {
  _and?: InputMaybe<Array<Video_Repositories_Bool_Exp>>;
  _not?: InputMaybe<Video_Repositories_Bool_Exp>;
  _or?: InputMaybe<Array<Video_Repositories_Bool_Exp>>;
  content?: InputMaybe<Contents_Bool_Exp>;
  content_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  videos?: InputMaybe<Videos_Bool_Exp>;
  videos_aggregate?: InputMaybe<Videos_Aggregate_Bool_Exp>;
  youtube_channel_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "video_repositories" */
export enum Video_Repositories_Constraint {
  /** unique or primary key constraint on columns "id" */
  VideoRepositoriesPkey = 'video_repositories_pkey',
  /** unique or primary key constraint on columns "content_id", "youtube_channel_id" */
  VideoRepositoriesYoutubeChannelIdContentIdKey = 'video_repositories_youtube_channel_id_content_id_key'
}

/** input type for inserting data into table "video_repositories" */
export type Video_Repositories_Insert_Input = {
  content?: InputMaybe<Contents_Obj_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  videos?: InputMaybe<Videos_Arr_Rel_Insert_Input>;
  youtube_channel_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Video_Repositories_Max_Fields = {
  __typename?: 'video_repositories_max_fields';
  content_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  youtube_channel_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "video_repositories" */
export type Video_Repositories_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  youtube_channel_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Video_Repositories_Min_Fields = {
  __typename?: 'video_repositories_min_fields';
  content_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  youtube_channel_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "video_repositories" */
export type Video_Repositories_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  youtube_channel_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "video_repositories" */
export type Video_Repositories_Mutation_Response = {
  __typename?: 'video_repositories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Repositories>;
};

/** input type for inserting object relation for remote table "video_repositories" */
export type Video_Repositories_Obj_Rel_Insert_Input = {
  data: Video_Repositories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Video_Repositories_On_Conflict>;
};

/** on_conflict condition type for table "video_repositories" */
export type Video_Repositories_On_Conflict = {
  constraint: Video_Repositories_Constraint;
  update_columns?: Array<Video_Repositories_Update_Column>;
  where?: InputMaybe<Video_Repositories_Bool_Exp>;
};

/** Ordering options when selecting data from "video_repositories". */
export type Video_Repositories_Order_By = {
  content?: InputMaybe<Contents_Order_By>;
  content_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  videos_aggregate?: InputMaybe<Videos_Aggregate_Order_By>;
  youtube_channel_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: video_repositories */
export type Video_Repositories_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "video_repositories" */
export enum Video_Repositories_Select_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  Id = 'id',
  /** column name */
  YoutubeChannelId = 'youtube_channel_id'
}

/** input type for updating data in table "video_repositories" */
export type Video_Repositories_Set_Input = {
  content_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  youtube_channel_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "video_repositories" */
export type Video_Repositories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Video_Repositories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Video_Repositories_Stream_Cursor_Value_Input = {
  content_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  youtube_channel_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "video_repositories" */
export enum Video_Repositories_Update_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  Id = 'id',
  /** column name */
  YoutubeChannelId = 'youtube_channel_id'
}

export type Video_Repositories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Video_Repositories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Video_Repositories_Bool_Exp;
};

/** columns and relationships of "videos" */
export type Videos = {
  __typename?: 'videos';
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  link: Scalars['String'];
  published_at: Scalars['timestamptz'];
  /** An object relationship */
  repository: Video_Repositories;
  repository_id: Scalars['uuid'];
  /** An array relationship */
  tags: Array<Videos_Youtube_Tags>;
  /** An aggregate relationship */
  tags_aggregate: Videos_Youtube_Tags_Aggregate;
  thumbnail: Scalars['String'];
  title: Scalars['String'];
};


/** columns and relationships of "videos" */
export type VideosTagsArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};


/** columns and relationships of "videos" */
export type VideosTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};

/** aggregated selection of "videos" */
export type Videos_Aggregate = {
  __typename?: 'videos_aggregate';
  aggregate?: Maybe<Videos_Aggregate_Fields>;
  nodes: Array<Videos>;
};

export type Videos_Aggregate_Bool_Exp = {
  count?: InputMaybe<Videos_Aggregate_Bool_Exp_Count>;
};

export type Videos_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Videos_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "videos" */
export type Videos_Aggregate_Fields = {
  __typename?: 'videos_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Videos_Max_Fields>;
  min?: Maybe<Videos_Min_Fields>;
};


/** aggregate fields of "videos" */
export type Videos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Videos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "videos" */
export type Videos_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Videos_Max_Order_By>;
  min?: InputMaybe<Videos_Min_Order_By>;
};

/** input type for inserting array relation for remote table "videos" */
export type Videos_Arr_Rel_Insert_Input = {
  data: Array<Videos_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Videos_On_Conflict>;
};

/** Boolean expression to filter rows from the table "videos". All fields are combined with a logical 'AND'. */
export type Videos_Bool_Exp = {
  _and?: InputMaybe<Array<Videos_Bool_Exp>>;
  _not?: InputMaybe<Videos_Bool_Exp>;
  _or?: InputMaybe<Array<Videos_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  published_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  repository?: InputMaybe<Video_Repositories_Bool_Exp>;
  repository_id?: InputMaybe<Uuid_Comparison_Exp>;
  tags?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
  tags_aggregate?: InputMaybe<Videos_Youtube_Tags_Aggregate_Bool_Exp>;
  thumbnail?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

export type Videos_By_Tags_Args = {
  tags?: InputMaybe<Scalars['_text']>;
};

/** unique or primary key constraints on table "videos" */
export enum Videos_Constraint {
  /** unique or primary key constraint on columns "id" */
  VideosPkey = 'videos_pkey',
  /** unique or primary key constraint on columns "link", "repository_id" */
  VideosRepositoryIdLinkKey = 'videos_repository_id_link_key'
}

/** input type for inserting data into table "videos" */
export type Videos_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  published_at?: InputMaybe<Scalars['timestamptz']>;
  repository?: InputMaybe<Video_Repositories_Obj_Rel_Insert_Input>;
  repository_id?: InputMaybe<Scalars['uuid']>;
  tags?: InputMaybe<Videos_Youtube_Tags_Arr_Rel_Insert_Input>;
  thumbnail?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Videos_Max_Fields = {
  __typename?: 'videos_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  repository_id?: Maybe<Scalars['uuid']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "videos" */
export type Videos_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  published_at?: InputMaybe<Order_By>;
  repository_id?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Videos_Min_Fields = {
  __typename?: 'videos_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  repository_id?: Maybe<Scalars['uuid']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "videos" */
export type Videos_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  published_at?: InputMaybe<Order_By>;
  repository_id?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "videos" */
export type Videos_Mutation_Response = {
  __typename?: 'videos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Videos>;
};

/** input type for inserting object relation for remote table "videos" */
export type Videos_Obj_Rel_Insert_Input = {
  data: Videos_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Videos_On_Conflict>;
};

/** on_conflict condition type for table "videos" */
export type Videos_On_Conflict = {
  constraint: Videos_Constraint;
  update_columns?: Array<Videos_Update_Column>;
  where?: InputMaybe<Videos_Bool_Exp>;
};

/** Ordering options when selecting data from "videos". */
export type Videos_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  published_at?: InputMaybe<Order_By>;
  repository?: InputMaybe<Video_Repositories_Order_By>;
  repository_id?: InputMaybe<Order_By>;
  tags_aggregate?: InputMaybe<Videos_Youtube_Tags_Aggregate_Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: videos */
export type Videos_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "videos" */
export enum Videos_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  RepositoryId = 'repository_id',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "videos" */
export type Videos_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  published_at?: InputMaybe<Scalars['timestamptz']>;
  repository_id?: InputMaybe<Scalars['uuid']>;
  thumbnail?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "videos" */
export type Videos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Videos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Videos_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  link?: InputMaybe<Scalars['String']>;
  published_at?: InputMaybe<Scalars['timestamptz']>;
  repository_id?: InputMaybe<Scalars['uuid']>;
  thumbnail?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** update columns of table "videos" */
export enum Videos_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  RepositoryId = 'repository_id',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Title = 'title'
}

export type Videos_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Videos_Set_Input>;
  /** filter the rows which have to be updated */
  where: Videos_Bool_Exp;
};

/** columns and relationships of "videos_youtube_tags" */
export type Videos_Youtube_Tags = {
  __typename?: 'videos_youtube_tags';
  tag: Scalars['String'];
  /** An object relationship */
  video: Videos;
  video_id: Scalars['uuid'];
  /** An object relationship */
  youtube_tag: Youtube_Tags;
};

/** aggregated selection of "videos_youtube_tags" */
export type Videos_Youtube_Tags_Aggregate = {
  __typename?: 'videos_youtube_tags_aggregate';
  aggregate?: Maybe<Videos_Youtube_Tags_Aggregate_Fields>;
  nodes: Array<Videos_Youtube_Tags>;
};

export type Videos_Youtube_Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Videos_Youtube_Tags_Aggregate_Bool_Exp_Count>;
};

export type Videos_Youtube_Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "videos_youtube_tags" */
export type Videos_Youtube_Tags_Aggregate_Fields = {
  __typename?: 'videos_youtube_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Videos_Youtube_Tags_Max_Fields>;
  min?: Maybe<Videos_Youtube_Tags_Min_Fields>;
};


/** aggregate fields of "videos_youtube_tags" */
export type Videos_Youtube_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "videos_youtube_tags" */
export type Videos_Youtube_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Videos_Youtube_Tags_Max_Order_By>;
  min?: InputMaybe<Videos_Youtube_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "videos_youtube_tags" */
export type Videos_Youtube_Tags_Arr_Rel_Insert_Input = {
  data: Array<Videos_Youtube_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Videos_Youtube_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "videos_youtube_tags". All fields are combined with a logical 'AND'. */
export type Videos_Youtube_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Videos_Youtube_Tags_Bool_Exp>>;
  _not?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Videos_Youtube_Tags_Bool_Exp>>;
  tag?: InputMaybe<String_Comparison_Exp>;
  video?: InputMaybe<Videos_Bool_Exp>;
  video_id?: InputMaybe<Uuid_Comparison_Exp>;
  youtube_tag?: InputMaybe<Youtube_Tags_Bool_Exp>;
};

/** unique or primary key constraints on table "videos_youtube_tags" */
export enum Videos_Youtube_Tags_Constraint {
  /** unique or primary key constraint on columns "video_id", "tag" */
  VideosYoutubeTagsPkey = 'videos_youtube_tags_pkey'
}

/** input type for inserting data into table "videos_youtube_tags" */
export type Videos_Youtube_Tags_Insert_Input = {
  tag?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Videos_Obj_Rel_Insert_Input>;
  video_id?: InputMaybe<Scalars['uuid']>;
  youtube_tag?: InputMaybe<Youtube_Tags_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Videos_Youtube_Tags_Max_Fields = {
  __typename?: 'videos_youtube_tags_max_fields';
  tag?: Maybe<Scalars['String']>;
  video_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "videos_youtube_tags" */
export type Videos_Youtube_Tags_Max_Order_By = {
  tag?: InputMaybe<Order_By>;
  video_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Videos_Youtube_Tags_Min_Fields = {
  __typename?: 'videos_youtube_tags_min_fields';
  tag?: Maybe<Scalars['String']>;
  video_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "videos_youtube_tags" */
export type Videos_Youtube_Tags_Min_Order_By = {
  tag?: InputMaybe<Order_By>;
  video_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "videos_youtube_tags" */
export type Videos_Youtube_Tags_Mutation_Response = {
  __typename?: 'videos_youtube_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Videos_Youtube_Tags>;
};

/** on_conflict condition type for table "videos_youtube_tags" */
export type Videos_Youtube_Tags_On_Conflict = {
  constraint: Videos_Youtube_Tags_Constraint;
  update_columns?: Array<Videos_Youtube_Tags_Update_Column>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "videos_youtube_tags". */
export type Videos_Youtube_Tags_Order_By = {
  tag?: InputMaybe<Order_By>;
  video?: InputMaybe<Videos_Order_By>;
  video_id?: InputMaybe<Order_By>;
  youtube_tag?: InputMaybe<Youtube_Tags_Order_By>;
};

/** primary key columns input for table: videos_youtube_tags */
export type Videos_Youtube_Tags_Pk_Columns_Input = {
  tag: Scalars['String'];
  video_id: Scalars['uuid'];
};

/** select columns of table "videos_youtube_tags" */
export enum Videos_Youtube_Tags_Select_Column {
  /** column name */
  Tag = 'tag',
  /** column name */
  VideoId = 'video_id'
}

/** input type for updating data in table "videos_youtube_tags" */
export type Videos_Youtube_Tags_Set_Input = {
  tag?: InputMaybe<Scalars['String']>;
  video_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "videos_youtube_tags" */
export type Videos_Youtube_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Videos_Youtube_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Videos_Youtube_Tags_Stream_Cursor_Value_Input = {
  tag?: InputMaybe<Scalars['String']>;
  video_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "videos_youtube_tags" */
export enum Videos_Youtube_Tags_Update_Column {
  /** column name */
  Tag = 'tag',
  /** column name */
  VideoId = 'video_id'
}

export type Videos_Youtube_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Videos_Youtube_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Videos_Youtube_Tags_Bool_Exp;
};

/** columns and relationships of "youtube_tags" */
export type Youtube_Tags = {
  __typename?: 'youtube_tags';
  tag: Scalars['String'];
  /** An array relationship */
  videos: Array<Videos_Youtube_Tags>;
  /** An aggregate relationship */
  videos_aggregate: Videos_Youtube_Tags_Aggregate;
};


/** columns and relationships of "youtube_tags" */
export type Youtube_TagsVideosArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};


/** columns and relationships of "youtube_tags" */
export type Youtube_TagsVideos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Videos_Youtube_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Videos_Youtube_Tags_Order_By>>;
  where?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
};

/** aggregated selection of "youtube_tags" */
export type Youtube_Tags_Aggregate = {
  __typename?: 'youtube_tags_aggregate';
  aggregate?: Maybe<Youtube_Tags_Aggregate_Fields>;
  nodes: Array<Youtube_Tags>;
};

/** aggregate fields of "youtube_tags" */
export type Youtube_Tags_Aggregate_Fields = {
  __typename?: 'youtube_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Youtube_Tags_Max_Fields>;
  min?: Maybe<Youtube_Tags_Min_Fields>;
};


/** aggregate fields of "youtube_tags" */
export type Youtube_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Youtube_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "youtube_tags". All fields are combined with a logical 'AND'. */
export type Youtube_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Youtube_Tags_Bool_Exp>>;
  _not?: InputMaybe<Youtube_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Youtube_Tags_Bool_Exp>>;
  tag?: InputMaybe<String_Comparison_Exp>;
  videos?: InputMaybe<Videos_Youtube_Tags_Bool_Exp>;
  videos_aggregate?: InputMaybe<Videos_Youtube_Tags_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "youtube_tags" */
export enum Youtube_Tags_Constraint {
  /** unique or primary key constraint on columns "tag" */
  YoutubeTagsPkey = 'youtube_tags_pkey'
}

/** input type for inserting data into table "youtube_tags" */
export type Youtube_Tags_Insert_Input = {
  tag?: InputMaybe<Scalars['String']>;
  videos?: InputMaybe<Videos_Youtube_Tags_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Youtube_Tags_Max_Fields = {
  __typename?: 'youtube_tags_max_fields';
  tag?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Youtube_Tags_Min_Fields = {
  __typename?: 'youtube_tags_min_fields';
  tag?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "youtube_tags" */
export type Youtube_Tags_Mutation_Response = {
  __typename?: 'youtube_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Youtube_Tags>;
};

/** input type for inserting object relation for remote table "youtube_tags" */
export type Youtube_Tags_Obj_Rel_Insert_Input = {
  data: Youtube_Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Youtube_Tags_On_Conflict>;
};

/** on_conflict condition type for table "youtube_tags" */
export type Youtube_Tags_On_Conflict = {
  constraint: Youtube_Tags_Constraint;
  update_columns?: Array<Youtube_Tags_Update_Column>;
  where?: InputMaybe<Youtube_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "youtube_tags". */
export type Youtube_Tags_Order_By = {
  tag?: InputMaybe<Order_By>;
  videos_aggregate?: InputMaybe<Videos_Youtube_Tags_Aggregate_Order_By>;
};

/** primary key columns input for table: youtube_tags */
export type Youtube_Tags_Pk_Columns_Input = {
  tag: Scalars['String'];
};

/** select columns of table "youtube_tags" */
export enum Youtube_Tags_Select_Column {
  /** column name */
  Tag = 'tag'
}

/** input type for updating data in table "youtube_tags" */
export type Youtube_Tags_Set_Input = {
  tag?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "youtube_tags" */
export type Youtube_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Youtube_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Youtube_Tags_Stream_Cursor_Value_Input = {
  tag?: InputMaybe<Scalars['String']>;
};

/** update columns of table "youtube_tags" */
export enum Youtube_Tags_Update_Column {
  /** column name */
  Tag = 'tag'
}

export type Youtube_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Youtube_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Youtube_Tags_Bool_Exp;
};

export type SectionCoreFragment = { __typename?: 'sections', id: string, name: string, slug: string, hex_color?: string | null, intro: string, section_icons_files: Array<{ __typename?: 'sections_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> };

export type OutputCoreFragment = { __typename?: 'outputs', id: string, intro: string, name: string, slug: string, order?: number | null, icons: Array<{ __typename?: 'outputs_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> };

export type ToolCoreFragment = { __typename?: 'tools', id: string, name: string, intro: string, explanation: string, slug: string, points: number, icons: Array<{ __typename?: 'tools_icons', type: Icons_Types_Enum, file: { __typename?: 'files', id: string, path: string } }> };

export type MultimediaContentFullFragment = { __typename?: 'multimedia_contents', ebooks_link?: string | null, resources?: string | null, video_lessons: Array<{ __typename?: 'multimedia_contents_links', link: string }> };

export type UserPrivateInfoFragment = { __typename?: 'users', cpf: string, created_at: string, updated_at?: string | null, deactivated_since?: string | null, last_term_accepted: string, role: Roles_Enum };

export type UserPublicInfoFragment = { __typename?: 'users', id: string, email: string, occupation?: string | null, names: Array<{ __typename?: 'users_names', name: string }>, profile_picture?: { __typename?: 'files', path: string } | null, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null };

export type TeamCoreFragment = { __typename?: 'teams', description?: string | null, disabled: boolean, id: string, cnpj?: string | null, logo?: { __typename?: 'files', path: string } | null, names: Array<{ __typename?: 'teams_names', name: string }>, tags: Array<{ __typename?: 'teams_tags', tag: { __typename?: 'tags', value: string } }>, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null, members: Array<{ __typename?: 'teams_users', user: { __typename?: 'users', id: string, email: string, names: Array<{ __typename?: 'users_names', name: string }> } }> };

export type GroupCoreFragment = { __typename?: 'groups', id: string, start_date: string, end_date: string, location_id: string, names: Array<{ __typename?: 'groups_names', name: string }> };

export type GetSectionsBasicQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetSectionsBasicQuery = { __typename?: 'query_root', sections: Array<{ __typename?: 'sections', id: string, name: string, slug: string, hex_color?: string | null, intro: string, section_icons_files: Array<{ __typename?: 'sections_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> }> };

export type SectionsBasicByGroupQueryVariables = Exact<{
  group_id: Scalars['uuid'];
}>;


export type SectionsBasicByGroupQuery = { __typename?: 'query_root', sections: Array<{ __typename?: 'sections', id: string, name: string, slug: string, hex_color?: string | null, intro: string, section_icons_files: Array<{ __typename?: 'sections_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> }> };

export type GetSectionsCoreQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetSectionsCoreQuery = { __typename?: 'query_root', sections: Array<{ __typename?: 'sections', id: string, name: string, slug: string, hex_color?: string | null, intro: string, section_icons_files: Array<{ __typename?: 'sections_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> }> };

export type GetOutputsCoresBySectionQueryVariables = Exact<{
  sectionId: Scalars['uuid'];
}>;


export type GetOutputsCoresBySectionQuery = { __typename?: 'query_root', outputs: Array<{ __typename?: 'outputs', id: string, intro: string, name: string, slug: string, order?: number | null, icons: Array<{ __typename?: 'outputs_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> }> };

export type GetOutputCoreBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetOutputCoreBySlugQuery = { __typename?: 'query_root', outputs: Array<{ __typename?: 'outputs', id: string, intro: string, name: string, slug: string, order?: number | null, icons: Array<{ __typename?: 'outputs_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> }> };

export type GetOutputCoreMultimediaBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
  team_id: Scalars['uuid'];
}>;


export type GetOutputCoreMultimediaBySlugQuery = { __typename?: 'query_root', outputs: Array<{ __typename?: 'outputs', id: string, intro: string, name: string, slug: string, order?: number | null, multimedia_content: { __typename?: 'multimedia_contents', ebooks_link?: string | null, resources?: string | null, video_lessons: Array<{ __typename?: 'multimedia_contents_links', link: string }> }, icons: Array<{ __typename?: 'outputs_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> }> };

export type GetToolsCoreByOutputQueryVariables = Exact<{
  outputId: Scalars['uuid'];
}>;


export type GetToolsCoreByOutputQuery = { __typename?: 'query_root', outputs_tools: Array<{ __typename?: 'outputs_tools', order?: number | null, tool: { __typename?: 'tools', id: string, name: string, intro: string, explanation: string, slug: string, points: number, icons: Array<{ __typename?: 'tools_icons', type: Icons_Types_Enum, file: { __typename?: 'files', id: string, path: string } }> } }> };

export type GetToolCoreMultimediaBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
  team_id: Scalars['uuid'];
}>;


export type GetToolCoreMultimediaBySlugQuery = { __typename?: 'query_root', tools: Array<{ __typename?: 'tools', id: string, name: string, intro: string, explanation: string, slug: string, points: number, multimedia_content: { __typename?: 'multimedia_contents', ebooks_link?: string | null, resources?: string | null, video_lessons: Array<{ __typename?: 'multimedia_contents_links', link: string }> }, icons: Array<{ __typename?: 'tools_icons', type: Icons_Types_Enum, file: { __typename?: 'files', id: string, path: string } }> }> };

export type GetToolCoreByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetToolCoreByIdQuery = { __typename?: 'query_root', tools_by_pk?: { __typename?: 'tools', id: string, name: string, intro: string, explanation: string, slug: string, points: number, icons: Array<{ __typename?: 'tools_icons', type: Icons_Types_Enum, file: { __typename?: 'files', id: string, path: string } }> } | null };

export type GetAllToolInfoByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetAllToolInfoByIdQuery = { __typename?: 'query_root', tools_by_pk?: { __typename?: 'tools', id: string, name: string, intro: string, explanation: string, slug: string, points: number, multimedia_content: { __typename?: 'multimedia_contents', ebooks_link?: string | null, resources?: string | null, video_lessons: Array<{ __typename?: 'multimedia_contents_links', link: string }> }, questionnaires: Array<{ __typename?: 'questionnaires', id: string, questions: Array<{ __typename?: 'questions', id: string, order: number, type: Questions_Types_Enum, title?: string | null, subtitle?: string | null, options: Array<{ __typename?: 'questions_options', value: string, label: string }> }> }>, icons: Array<{ __typename?: 'tools_icons', type: Icons_Types_Enum, file: { __typename?: 'files', id: string, path: string } }> } | null };

export type QuestionnaireActiveOfToolQueryVariables = Exact<{
  tool_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
}>;


export type QuestionnaireActiveOfToolQuery = { __typename?: 'query_root', questionnaires: Array<{ __typename?: 'questionnaires', id: string, tool: { __typename?: 'tools', name: string }, questions: Array<{ __typename?: 'questions', id: string, order: number, title?: string | null, subtitle?: string | null, type: Questions_Types_Enum, options: Array<{ __typename?: 'questions_options', value: string, label: string }>, question_answers: Array<{ __typename?: 'questions_answers', answer: string }> }>, teams_states: Array<{ __typename?: 'questionnaires_teams_states', updated_at: string, finished: boolean }> }> };

export type SignUpUserMutationVariables = Exact<{
  cpf: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  address_doc_b64str?: InputMaybe<Scalars['String']>;
  id_doc_b64str: Scalars['String'];
  token: Scalars['String'];
  team_id?: InputMaybe<Scalars['String']>;
  dob: Scalars['date'];
  phone: Scalars['String'];
  cep: Scalars['String'];
  state: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  secondLine?: InputMaybe<Scalars['String']>;
}>;


export type SignUpUserMutation = { __typename?: 'mutation_root', createUser: string };

export type SignUpUserPreregisteredMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  id_doc_b64str: Scalars['String'];
  token: Scalars['String'];
  team_id?: InputMaybe<Scalars['String']>;
}>;


export type SignUpUserPreregisteredMutation = { __typename?: 'mutation_root', createUserPreregistered: string };

export type SignUpSubscriptionSubscriptionVariables = Exact<{
  action_id: Scalars['uuid'];
}>;


export type SignUpSubscriptionSubscription = { __typename?: 'subscription_root', createUser?: { __typename?: 'createUser', errors?: object | null, output?: { __typename?: 'CreateUserOutput', id?: string | null, name?: string | null, email?: string | null, cpf?: string | null } | null } | null };

export type SignUpPreregisteredSubSubscriptionVariables = Exact<{
  action_id: Scalars['uuid'];
}>;


export type SignUpPreregisteredSubSubscription = { __typename?: 'subscription_root', createUserPreregistered?: { __typename?: 'createUserPreregistered', errors?: object | null, output?: { __typename?: 'CreateUserOutput', cpf?: string | null, email?: string | null, id?: string | null, name?: string | null } | null } | null };

export type GetUserCoreQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserCoreQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', cpf: string, created_at: string, updated_at?: string | null, deactivated_since?: string | null, last_term_accepted: string, role: Roles_Enum, id: string, email: string, occupation?: string | null, names: Array<{ __typename?: 'users_names', name: string }>, profile_picture?: { __typename?: 'files', path: string } | null, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null }> };

export type GetTeamCoreByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetTeamCoreByIdQuery = { __typename?: 'query_root', teams_by_pk?: { __typename?: 'teams', description?: string | null, disabled: boolean, id: string, cnpj?: string | null, logo?: { __typename?: 'files', path: string } | null, names: Array<{ __typename?: 'teams_names', name: string }>, tags: Array<{ __typename?: 'teams_tags', tag: { __typename?: 'tags', value: string } }>, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null, members: Array<{ __typename?: 'teams_users', user: { __typename?: 'users', id: string, email: string, names: Array<{ __typename?: 'users_names', name: string }> } }> } | null };

export type GetTeamsOfUserQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type GetTeamsOfUserQuery = { __typename?: 'query_root', teams: Array<{ __typename?: 'teams', description?: string | null, disabled: boolean, id: string, cnpj?: string | null, logo?: { __typename?: 'files', path: string } | null, names: Array<{ __typename?: 'teams_names', name: string }>, tags: Array<{ __typename?: 'teams_tags', tag: { __typename?: 'tags', value: string } }>, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null, members: Array<{ __typename?: 'teams_users', user: { __typename?: 'users', id: string, email: string, names: Array<{ __typename?: 'users_names', name: string }> } }> }> };

export type GetGroupsOfTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetGroupsOfTeamQuery = { __typename?: 'query_root', groups: Array<{ __typename?: 'groups', id: string, start_date: string, end_date: string, location_id: string, names: Array<{ __typename?: 'groups_names', name: string }> }> };

export type GetTeamCoreQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type GetTeamCoreQuery = { __typename?: 'query_root', team_invites: Array<{ __typename?: 'team_invites', id: string, team: { __typename?: 'teams', description?: string | null, disabled: boolean, id: string, cnpj?: string | null, logo?: { __typename?: 'files', path: string } | null, names: Array<{ __typename?: 'teams_names', name: string }>, tags: Array<{ __typename?: 'teams_tags', tag: { __typename?: 'tags', value: string } }>, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null, members: Array<{ __typename?: 'teams_users', user: { __typename?: 'users', id: string, email: string, names: Array<{ __typename?: 'users_names', name: string }> } }> } }> };

export type RespondTeamInvitationMutationVariables = Exact<{
  invite_id: Scalars['String'];
  accept: Scalars['Boolean'];
}>;


export type RespondTeamInvitationMutation = { __typename?: 'mutation_root', respondTeamInvitation?: { __typename?: 'RespondTeamInvitationOutput', status: string, team_id: string, user_id: string } | null };

export type GetTeamScoreQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetTeamScoreQuery = { __typename?: 'query_root', tools_aggregate: { __typename?: 'tools_aggregate', aggregate?: { __typename?: 'tools_aggregate_fields', sum?: { __typename?: 'tools_sum_fields', points?: number | null } | null } | null } };

export type GetTeamsScoresByGroupQueryVariables = Exact<{
  group_id: Scalars['uuid'];
}>;


export type GetTeamsScoresByGroupQuery = { __typename?: 'query_root', teams_scores: Array<{ __typename?: 'teams_scores', total?: number | null, team?: { __typename?: 'teams', id: string, names: Array<{ __typename?: 'teams_names', name: string }> } | null }> };

export type CountToolsDoneOfSectionByTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
}>;


export type CountToolsDoneOfSectionByTeamQuery = { __typename?: 'query_root', done: { __typename?: 'tools_aggregate', aggregate?: { __typename?: 'tools_aggregate_fields', count: number } | null }, all: { __typename?: 'tools_aggregate', aggregate?: { __typename?: 'tools_aggregate_fields', count: number } | null } };

export type CountToolsDoneOfSectionByTeamGetOutputsNodesQueryVariables = Exact<{
  team_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
}>;


export type CountToolsDoneOfSectionByTeamGetOutputsNodesQuery = { __typename?: 'query_root', done: { __typename?: 'tools_aggregate', nodes: Array<{ __typename?: 'tools', outputs: Array<{ __typename?: 'outputs_tools', output_id: string }> }> }, all: { __typename?: 'tools_aggregate', nodes: Array<{ __typename?: 'tools', outputs: Array<{ __typename?: 'outputs_tools', output_id: string }> }> } };

export type GetOutputsDoneBySectionQueryVariables = Exact<{
  section_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
}>;


export type GetOutputsDoneBySectionQuery = { __typename?: 'query_root', outputs: Array<{ __typename?: 'outputs', id: string, intro: string, name: string, slug: string, order?: number | null, done: { __typename?: 'outputs_tools_aggregate', aggregate?: { __typename?: 'outputs_tools_aggregate_fields', count: number } | null }, all: { __typename?: 'outputs_tools_aggregate', aggregate?: { __typename?: 'outputs_tools_aggregate_fields', count: number } | null }, icons: Array<{ __typename?: 'outputs_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> }> };

export type GetLocationsCalendarsQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetLocationsCalendarsQuery = { __typename?: 'query_root', default: Array<{ __typename?: 'locations', calendar_id?: string | null, names: Array<{ __typename?: 'locations_names', name: string }> }>, all: Array<{ __typename?: 'locations', calendar_id?: string | null, names: Array<{ __typename?: 'locations_names', name: string }> }> };

export type GetTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTagsQuery = { __typename?: 'query_root', tags: Array<{ __typename?: 'tags', id: string, value: string }> };

export type GetMembersOfTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetMembersOfTeamQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', role: Roles_Enum, id: string, email: string, occupation?: string | null, names: Array<{ __typename?: 'users_names', name: string }>, profile_picture?: { __typename?: 'files', path: string } | null, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null }> };

export type UploadProfilePictureMutationVariables = Exact<{
  user_id: Scalars['String'];
  b64_img: Scalars['String'];
}>;


export type UploadProfilePictureMutation = { __typename?: 'mutation_root', uploadProfilePicture?: { __typename?: 'UploadProfilePictureOutput', id: string, path: string } | null };

export type UpdateUserNameMutationVariables = Exact<{
  user_id: Scalars['String'];
  name: Scalars['String'];
}>;


export type UpdateUserNameMutation = { __typename?: 'mutation_root', insert_users_names_one?: { __typename?: 'users_names', name: string, user_id: string } | null };

export type UpdateUserOccupationMutationVariables = Exact<{
  user_id: Scalars['String'];
  occupation: Scalars['String'];
}>;


export type UpdateUserOccupationMutation = { __typename?: 'mutation_root', update_users?: { __typename?: 'users_mutation_response', returning: Array<{ __typename?: 'users', id: string, occupation?: string | null }> } | null };

export type UpdateTeamMutationVariables = Exact<{
  team_id: Scalars['uuid'];
  cnpj?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
}>;


export type UpdateTeamMutation = { __typename?: 'mutation_root', update_teams_by_pk?: { __typename?: 'teams', id: string, cnpj?: string | null, description?: string | null } | null };

export type InsertTeamNameMutationVariables = Exact<{
  team_id: Scalars['uuid'];
  name: Scalars['String'];
}>;


export type InsertTeamNameMutation = { __typename?: 'mutation_root', insert_teams_names_one?: { __typename?: 'teams_names', team_id: string, name: string } | null };

export type DeleteTeamMemberMutationVariables = Exact<{
  team_id: Scalars['uuid'];
  user_id: Scalars['String'];
}>;


export type DeleteTeamMemberMutation = { __typename?: 'mutation_root', delete_teams_users?: { __typename?: 'teams_users_mutation_response', returning: Array<{ __typename?: 'teams_users', team_id: string }> } | null };

export type UpdateTeamSocialMediaMutationVariables = Exact<{
  team_id: Scalars['uuid'];
  facebook?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  youtube?: InputMaybe<Scalars['String']>;
  linkedin?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Scalars['String']>;
}>;


export type UpdateTeamSocialMediaMutation = { __typename?: 'mutation_root', update_social_medias?: { __typename?: 'social_medias_mutation_response', returning: Array<{ __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, twitter?: string | null, youtube?: string | null }> } | null };

export type InsertTagsToTeamMutationVariables = Exact<{
  objects: Array<Teams_Tags_Insert_Input> | Teams_Tags_Insert_Input;
}>;


export type InsertTagsToTeamMutation = { __typename?: 'mutation_root', insert_teams_tags?: { __typename?: 'teams_tags_mutation_response', returning: Array<{ __typename?: 'teams_tags', tag_id: string, team_id: string }> } | null };

export type DeleteTagsOfTeamMutationVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type DeleteTagsOfTeamMutation = { __typename?: 'mutation_root', delete_teams_tags?: { __typename?: 'teams_tags_mutation_response', affected_rows: number } | null };

export type GetDiagnosticsTabsActiveByTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetDiagnosticsTabsActiveByTeamQuery = { __typename?: 'query_root', diagnostics_tabs: Array<{ __typename?: 'diagnostics_tabs', id: string, order: number, title: string }> };

export type GetDiagnosticsSectionsByTabQueryVariables = Exact<{
  tab_id: Scalars['uuid'];
}>;


export type GetDiagnosticsSectionsByTabQuery = { __typename?: 'query_root', diagnostics_tabs_sections: Array<{ __typename?: 'diagnostics_tabs_sections', order: number, section: { __typename?: 'diagnostics_sections', id: string, title: string } }> };

export type GetDiagnosticsSubsectionsBySectionAndTeamQueryVariables = Exact<{
  tab_id: Scalars['uuid'];
  section_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
}>;


export type GetDiagnosticsSubsectionsBySectionAndTeamQuery = { __typename?: 'query_root', diagnostics_subsections: Array<{ __typename?: 'diagnostics_subsections', id: string, order: number, title: string, questions: Array<{ __typename?: 'diagnostics_questions', id: string, order: number, question: string, answers: Array<{ __typename?: 'diagnostics_questions_answers', id: string, answer: number }> }> }> };

export type StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeamMutationVariables = Exact<{
  objects: Array<Diagnostics_Questions_Answers_Insert_Input> | Diagnostics_Questions_Answers_Insert_Input;
}>;


export type StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeamMutation = { __typename?: 'mutation_root', insert_diagnostics_questions_answers?: { __typename?: 'diagnostics_questions_answers_mutation_response', returning: Array<{ __typename?: 'diagnostics_questions_answers', id: string, answer: number }> } | null };

export type GetDiagnosticsCommentByTabAndTeamQueryVariables = Exact<{
  tab_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
}>;


export type GetDiagnosticsCommentByTabAndTeamQuery = { __typename?: 'query_root', diagnostics_comments_by_pk?: { __typename?: 'diagnostics_comments', comment?: string | null } | null };

export type StoreDiagnosticsCommentByTabAndTeamMutationVariables = Exact<{
  comment: Scalars['String'];
  tab_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
}>;


export type StoreDiagnosticsCommentByTabAndTeamMutation = { __typename?: 'mutation_root', insert_diagnostics_comments_one?: { __typename?: 'diagnostics_comments', comment?: string | null } | null };

export type GetVideosByTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order_by?: InputMaybe<Array<Videos_Order_By> | Videos_Order_By>;
  contains_text: Scalars['String'];
}>;


export type GetVideosByTeamQuery = { __typename?: 'query_root', videos: Array<{ __typename?: 'videos', description?: string | null, title: string, id: string, link: string, published_at: string, thumbnail: string, tags: Array<{ __typename?: 'videos_youtube_tags', tag: string }> }> };

export type CountVideosByTagsByTeamQueryVariables = Exact<{
  tags: Scalars['_text'];
  team_id: Scalars['uuid'];
  contains_text: Scalars['String'];
}>;


export type CountVideosByTagsByTeamQuery = { __typename?: 'query_root', videos_by_tags_aggregate: { __typename?: 'videos_aggregate', aggregate?: { __typename?: 'videos_aggregate_fields', count: number } | null } };

export type GetVideosByTagsByTeamQueryVariables = Exact<{
  tags: Scalars['_text'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order: Order_By;
  contains_text: Scalars['String'];
}>;


export type GetVideosByTagsByTeamQuery = { __typename?: 'query_root', videos_by_tags: Array<{ __typename?: 'videos', description?: string | null, title: string, id: string, link: string, published_at: string, thumbnail: string, tags: Array<{ __typename?: 'videos_youtube_tags', tag: string }> }> };

export type GetYoutubeTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetYoutubeTagsQuery = { __typename?: 'query_root', youtube_tags: Array<{ __typename?: 'youtube_tags', tag: string }> };

export type GetRepositoriesActiveByTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetRepositoriesActiveByTeamQuery = { __typename?: 'query_root', repositories: Array<{ __typename?: 'repositories', id: string, link: string, app_key: string }> };

export type StoreQuestionsAnswersByQuestionByTeamMutationVariables = Exact<{
  answers: Array<Questions_Answers_Insert_Input> | Questions_Answers_Insert_Input;
}>;


export type StoreQuestionsAnswersByQuestionByTeamMutation = { __typename?: 'mutation_root', insert_questions_answers?: { __typename?: 'questions_answers_mutation_response', affected_rows: number } | null };

export type StoreQuestionnaireTeamStateMutationVariables = Exact<{
  questionnaire_id: Scalars['uuid'];
  team_id: Scalars['uuid'];
  finished: Scalars['Boolean'];
}>;


export type StoreQuestionnaireTeamStateMutation = { __typename?: 'mutation_root', insert_questionnaires_teams_states_one?: { __typename?: 'questionnaires_teams_states', finished: boolean } | null };

export type GetMentorsFeedbackByToolByTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
  tool_id: Scalars['uuid'];
}>;


export type GetMentorsFeedbackByToolByTeamQuery = { __typename?: 'query_root', mentors_blablabla: Array<{ __typename?: 'mentors_blablabla', id: string, value: string }> };

export type StoreMentorsFeedbackMutationVariables = Exact<{
  team_id: Scalars['uuid'];
  tool_id: Scalars['uuid'];
  value: Scalars['String'];
}>;


export type StoreMentorsFeedbackMutation = { __typename?: 'mutation_root', insert_mentors_blablabla_one?: { __typename?: 'mentors_blablabla', value: string } | null };

export type GetBlobStorageSasUriQueryVariables = Exact<{
  container: Scalars['String'];
  filename: Scalars['String'];
}>;


export type GetBlobStorageSasUriQuery = { __typename?: 'query_root', getStorageSASUri: string };

export type InsertMentoringRecordMutationVariables = Exact<{
  date: Scalars['timestamptz'];
  record: Scalars['String'];
  team_id: Scalars['uuid'];
  duration: Scalars['smallint'];
  scheduling_id?: InputMaybe<Scalars['uuid']>;
}>;


export type InsertMentoringRecordMutation = { __typename?: 'mutation_root', insert_mentoring_records_one?: { __typename?: 'mentoring_records', id: string, scheduling_id?: string | null, date: string, schedules?: { __typename?: 'mentoring_scheduling', request_id?: string | null } | null } | null };

export type GetMentoringRecordQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetMentoringRecordQuery = { __typename?: 'query_root', mentoring_records_by_pk?: { __typename?: 'mentoring_records', id: string, date: string, duration: number } | null };

export type UpdateMentoringRecordByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
  record: Scalars['String'];
  duration: Scalars['smallint'];
}>;


export type UpdateMentoringRecordByIdMutation = { __typename?: 'mutation_root', update_mentoring_records_by_pk?: { __typename?: 'mentoring_records', id: string } | null };

export type DeleteMentoringRecordByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteMentoringRecordByIdMutation = { __typename?: 'mutation_root', delete_mentoring_records_by_pk?: { __typename?: 'mentoring_records', id: string } | null };

export type GetMentoringRecordsByTeamByMonthQueryVariables = Exact<{
  team_id: Scalars['uuid'];
  first_day: Scalars['timestamptz'];
  last_day: Scalars['timestamptz'];
}>;


export type GetMentoringRecordsByTeamByMonthQuery = { __typename?: 'query_root', mentoring_records: Array<{ __typename?: 'mentoring_records', id: string, duration: number, date: string, record: string, members: Array<{ __typename?: 'mentoring_records_members', member_id: string, member: { __typename?: 'users', id: string, names: Array<{ __typename?: 'users_names', name: string }> } }> }> };

export type InviteEmailToTeamMutationVariables = Exact<{
  email: Scalars['String'];
  team_id: Scalars['String'];
}>;


export type InviteEmailToTeamMutation = { __typename?: 'mutation_root', inviteUserToTeam?: { __typename?: 'InviteUserToTeamOutput', invite_id: string, team_id: string, user_id: string } | null };

export type GetInvitesOfTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetInvitesOfTeamQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'team_invites', user: { __typename?: 'users', id: string, names: Array<{ __typename?: 'users_names', name: string }> } }>, emails: Array<{ __typename?: 'user_invites', email: string }> };

export type GetTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamsQuery = { __typename?: 'query_root', teams: Array<{ __typename?: 'teams', id: string, names: Array<{ __typename?: 'teams_names', name: string }> }> };

export type GetLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsQuery = { __typename?: 'query_root', locations: Array<{ __typename?: 'locations', id: string, names: Array<{ __typename?: 'locations_names', name: string }> }> };

export type GetGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupsQuery = { __typename?: 'query_root', groups: Array<{ __typename?: 'groups', id: string, names: Array<{ __typename?: 'groups_names', name: string }> }> };

export type GetTeamsByGroupQueryVariables = Exact<{
  groupId: Scalars['uuid'];
}>;


export type GetTeamsByGroupQuery = { __typename?: 'query_root', teams: Array<{ __typename?: 'teams', name?: string | null, description?: string | null, disabled: boolean, id: string, cnpj?: string | null, logo?: { __typename?: 'files', path: string } | null, names: Array<{ __typename?: 'teams_names', name: string }>, tags: Array<{ __typename?: 'teams_tags', tag: { __typename?: 'tags', value: string } }>, social_media?: { __typename?: 'social_medias', facebook?: string | null, instagram?: string | null, linkedin?: string | null, other?: string | null, youtube?: string | null, twitter?: string | null } | null, members: Array<{ __typename?: 'teams_users', user: { __typename?: 'users', id: string, email: string, names: Array<{ __typename?: 'users_names', name: string }> } }> }> };

export type GroupsByUserQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type GroupsByUserQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', teams: Array<{ __typename?: 'teams_users', team: { __typename?: 'teams', groups: Array<{ __typename?: 'groups_teams', group: { __typename?: 'groups', id: string, names: Array<{ __typename?: 'groups_names', name: string }> } }> } }> }> };

export type GetInviteStateQueryVariables = Exact<{
  email: Scalars['String'];
  token: Scalars['String'];
}>;


export type GetInviteStateQuery = { __typename?: 'query_root', checkInviteStatus?: { __typename?: 'CheckInviteStatusOutput', id: string, status: InviteState, role: RolesEnum, preregistered: boolean } | null };

export type ContentWithProgressQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type ContentWithProgressQuery = { __typename?: 'query_root', contents: Array<{ __typename?: 'contents', sections: Array<{ __typename?: 'contents_sections', section: { __typename?: 'sections', id: string, name: string, slug: string, section_icons_files: Array<{ __typename?: 'sections_icons', id: string, type: Icons_Types_Enum, file: { __typename?: 'files', id: string, path: string } }>, outputs: Array<{ __typename?: 'outputs', id: string, name: string, slug: string, icons: Array<{ __typename?: 'outputs_icons', id: string, type: Icons_Types_Enum, file: { __typename?: 'files', id: string, path: string } }>, tools: Array<{ __typename?: 'outputs_tools', tool: { __typename?: 'tools', id: string, name: string, slug: string, intro: string, explanation: string, points: number, icons: Array<{ __typename?: 'tools_icons', id: string, type: Icons_Types_Enum, file: { __typename?: 'files', id: string, path: string } }>, questionnaires: Array<{ __typename?: 'questionnaires', teams_states: Array<{ __typename?: 'questionnaires_teams_states', finished: boolean }> }> } }> }> } }> }> };

export type GetTeamsNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamsNamesQuery = { __typename?: 'query_root', teams_names: Array<{ __typename?: 'teams_names', team_id: string, name: string }> };

export type GetGroupNameByTeamIdQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetGroupNameByTeamIdQuery = { __typename?: 'query_root', teams: Array<{ __typename?: 'teams', groups: Array<{ __typename?: 'groups_teams', group: { __typename?: 'groups', id: string, names: Array<{ __typename?: 'groups_names', name: string }> } }> }> };

export type GetGroupIdByUserIdQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type GetGroupIdByUserIdQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', teams: Array<{ __typename?: 'teams_users', team: { __typename?: 'teams', groups: Array<{ __typename?: 'groups_teams', group: { __typename?: 'groups', id: string } }> } }> }> };

export type InviteNewUserTeamRoleMutationVariables = Exact<{
  email: Scalars['String'];
  role: RolesEnum;
  team_id?: InputMaybe<Scalars['String']>;
}>;


export type InviteNewUserTeamRoleMutation = { __typename?: 'mutation_root', inviteNewUser?: { __typename?: 'InviteNewUserOutput', user_invite_id: string, token: string } | null };

export type GetTeamsByGroupIdQueryVariables = Exact<{
  group_id: Scalars['uuid'];
}>;


export type GetTeamsByGroupIdQuery = { __typename?: 'query_root', groups: Array<{ __typename?: 'groups', teams: Array<{ __typename?: 'groups_teams', team: { __typename?: 'teams', id: string, names: Array<{ __typename?: 'teams_names', name: string }> } }> }> };

export type GetUsersWithNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersWithNamesQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: string, email: string, cpf: string, role: Roles_Enum, names: Array<{ __typename?: 'users_names', name: string }> }> };

export type UpdateUserRoleMutationVariables = Exact<{
  new_role: RolesEnum;
  user_id: Scalars['String'];
}>;


export type UpdateUserRoleMutation = { __typename?: 'mutation_root', updateUserRole?: { __typename?: 'UpdateUserRoleOutput', user_id?: string | null } | null };

export type GetMinutesAttachmentsByMentoringRecordIdQueryVariables = Exact<{
  mentoring_record_id: Scalars['uuid'];
}>;


export type GetMinutesAttachmentsByMentoringRecordIdQuery = { __typename?: 'query_root', mentoring_records_attachments: Array<{ __typename?: 'mentoring_records_attachments', id: string, attachment: string, label: string, created_at: string }> };

export type InsertMentoringRecordAttachmentMutationVariables = Exact<{
  mentoring_record_id: Scalars['uuid'];
  attachment?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
}>;


export type InsertMentoringRecordAttachmentMutation = { __typename?: 'mutation_root', insert_mentoring_records_attachments_one?: { __typename?: 'mentoring_records_attachments', attachment: string } | null };

export type DeleteMentoringRecordAttachmentsByMentoringRecordIdMutationVariables = Exact<{
  mentoring_record_id: Scalars['uuid'];
}>;


export type DeleteMentoringRecordAttachmentsByMentoringRecordIdMutation = { __typename?: 'mutation_root', delete_mentoring_records_attachments?: { __typename?: 'mentoring_records_attachments_mutation_response', returning: Array<{ __typename?: 'mentoring_records_attachments', mentoring_record_id: string }> } | null };

export type InsertMentoringMemberByMentoringRecordIdMutationVariables = Exact<{
  objects: Array<Mentoring_Records_Members_Insert_Input> | Mentoring_Records_Members_Insert_Input;
}>;


export type InsertMentoringMemberByMentoringRecordIdMutation = { __typename?: 'mutation_root', insert_mentoring_records_members?: { __typename?: 'mentoring_records_members_mutation_response', returning: Array<{ __typename?: 'mentoring_records_members', member_id: string, mentoring_record: { __typename?: 'mentoring_records', id: string } }> } | null };

export type GetMentoringMemberByMentoringRecordIdQueryVariables = Exact<{
  mentoring_record_id: Scalars['uuid'];
}>;


export type GetMentoringMemberByMentoringRecordIdQuery = { __typename?: 'query_root', mentoring_records_members: Array<{ __typename?: 'mentoring_records_members', member_id: string }> };

export type DeleteMentoringRecordMemberMutationVariables = Exact<{
  mentoring_record_id: Scalars['uuid'];
  member_id: Scalars['String'];
}>;


export type DeleteMentoringRecordMemberMutation = { __typename?: 'mutation_root', delete_mentoring_records_members_by_pk?: { __typename?: 'mentoring_records_members', member_id: string } | null };

export type InsertMentoringSchedulingMutationVariables = Exact<{
  date?: InputMaybe<Scalars['timestamptz']>;
  team_id: Scalars['uuid'];
}>;


export type InsertMentoringSchedulingMutation = { __typename?: 'mutation_root', insert_mentoring_scheduling?: { __typename?: 'mentoring_scheduling_mutation_response', returning: Array<{ __typename?: 'mentoring_scheduling', id: string }> } | null };

export type InsertMentoringSchedulingWithRequestIdMutationVariables = Exact<{
  date?: InputMaybe<Scalars['timestamptz']>;
  team_id: Scalars['uuid'];
  request_id?: InputMaybe<Scalars['uuid']>;
  mentor_id?: InputMaybe<Scalars['uuid']>;
}>;


export type InsertMentoringSchedulingWithRequestIdMutation = { __typename?: 'mutation_root', insert_mentoring_scheduling?: { __typename?: 'mentoring_scheduling_mutation_response', returning: Array<{ __typename?: 'mentoring_scheduling', id: string, date: string, mentor?: { __typename?: 'mentors', email: string, name: string, additional_data?: { __typename?: 'mentor_additional_data', linkedin?: string | null } | null } | null, team: { __typename?: 'teams', members: Array<{ __typename?: 'teams_users', user: { __typename?: 'users', email: string } }> }, meeting?: { __typename?: 'mentoring_scheduling_meeting', title: string, contact: string } | null }> } | null };

export type CreateScheduleMeetingMutationVariables = Exact<{
  contact: Scalars['String'];
  title: Scalars['String'];
}>;


export type CreateScheduleMeetingMutation = { __typename?: 'mutation_root', insert_mentoring_scheduling_meeting?: { __typename?: 'mentoring_scheduling_meeting_mutation_response', returning: Array<{ __typename?: 'mentoring_scheduling_meeting', id: string }> } | null };

export type InsertMeetingIdOnScheduleMutationVariables = Exact<{
  id: Scalars['uuid'];
  meeting_id: Scalars['uuid'];
}>;


export type InsertMeetingIdOnScheduleMutation = { __typename?: 'mutation_root', update_mentoring_scheduling?: { __typename?: 'mentoring_scheduling_mutation_response', returning: Array<{ __typename?: 'mentoring_scheduling', id: string }> } | null };

export type InsertMentoringSchedulingForDatesMutationVariables = Exact<{
  objects: Array<Mentoring_Scheduling_Insert_Input> | Mentoring_Scheduling_Insert_Input;
}>;


export type InsertMentoringSchedulingForDatesMutation = { __typename?: 'mutation_root', insert_mentoring_scheduling?: { __typename?: 'mentoring_scheduling_mutation_response', returning: Array<{ __typename?: 'mentoring_scheduling', id: string }> } | null };

export type InsertMentoringSchedulingMembersMutationVariables = Exact<{
  objects: Array<Mentoring_Scheduling_Members_Insert_Input> | Mentoring_Scheduling_Members_Insert_Input;
}>;


export type InsertMentoringSchedulingMembersMutation = { __typename?: 'mutation_root', insert_mentoring_scheduling_members?: { __typename?: 'mentoring_scheduling_members_mutation_response', returning: Array<{ __typename?: 'mentoring_scheduling_members', id: string }> } | null };

export type GetTeamUsersQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetTeamUsersQuery = { __typename?: 'query_root', teams_users: Array<{ __typename?: 'teams_users', user_id: string, user: { __typename?: 'users', names: Array<{ __typename?: 'users_names', name: string }>, profile_picture?: { __typename?: 'files', path: string } | null } }> };

export type GetMentoringSchedulingByTeamIdQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetMentoringSchedulingByTeamIdQuery = { __typename?: 'query_root', mentoring_scheduling: Array<{ __typename?: 'mentoring_scheduling', id: string, date: string, request_id?: string | null, request?: { __typename?: 'requested_mentorings', standard: boolean } | null }> };

export type GetMentoringSchedulingMembersByMentoringSchedulingIdQueryVariables = Exact<{
  mentoring_scheduling_id: Scalars['uuid'];
}>;


export type GetMentoringSchedulingMembersByMentoringSchedulingIdQuery = { __typename?: 'query_root', mentoring_scheduling_members: Array<{ __typename?: 'mentoring_scheduling_members', id: string, member: { __typename?: 'users', id: string, email: string, names: Array<{ __typename?: 'users_names', name: string }>, profile_picture?: { __typename?: 'files', path: string } | null } }> };

export type UpdateMentoringSchedulingByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
  completed: Scalars['Boolean'];
  date?: InputMaybe<Scalars['timestamptz']>;
  team_id: Scalars['uuid'];
}>;


export type UpdateMentoringSchedulingByIdMutation = { __typename?: 'mutation_root', update_mentoring_scheduling?: { __typename?: 'mentoring_scheduling_mutation_response', returning: Array<{ __typename?: 'mentoring_scheduling', id: string }> } | null };

export type UpdateMentoringSchedulingForRecordsByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
  completed: Scalars['Boolean'];
  record_id?: InputMaybe<Scalars['uuid']>;
}>;


export type UpdateMentoringSchedulingForRecordsByIdMutation = { __typename?: 'mutation_root', update_mentoring_scheduling?: { __typename?: 'mentoring_scheduling_mutation_response', returning: Array<{ __typename?: 'mentoring_scheduling', id: string, request_id?: string | null }> } | null };

export type UpdateMentoringSchedulingMembersByMentoringSchedulingIdMutationVariables = Exact<{
  mentoring_scheduling_ids: Array<Scalars['uuid']> | Scalars['uuid'];
  member_ids: Array<Scalars['String']> | Scalars['String'];
  objects: Array<Mentoring_Scheduling_Members_Insert_Input> | Mentoring_Scheduling_Members_Insert_Input;
}>;


export type UpdateMentoringSchedulingMembersByMentoringSchedulingIdMutation = { __typename?: 'mutation_root', delete_mentoring_scheduling_members?: { __typename?: 'mentoring_scheduling_members_mutation_response', returning: Array<{ __typename?: 'mentoring_scheduling_members', id: string }> } | null, insert_mentoring_scheduling_members?: { __typename?: 'mentoring_scheduling_members_mutation_response', returning: Array<{ __typename?: 'mentoring_scheduling_members', id: string }> } | null };

export type GetMentoringRecordsByTeamIdQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetMentoringRecordsByTeamIdQuery = { __typename?: 'query_root', mentoring_records: Array<{ __typename?: 'mentoring_records', id: string, duration: number, date: string, record: string, members: Array<{ __typename?: 'mentoring_records_members', member_id: string, member: { __typename?: 'users', id: string, names: Array<{ __typename?: 'users_names', name: string }> } }>, mentoring_records_attachments: Array<{ __typename?: 'mentoring_records_attachments', id: string, label: string, attachment: string }>, schedules?: { __typename?: 'mentoring_scheduling', request?: { __typename?: 'requested_mentorings', description: string } | null } | null }> };

export type DeleteMentoringRecordAttachmentsByMentoringRecordAttachmentIdMutationVariables = Exact<{
  mentoring_record_attachment_id: Scalars['uuid'];
}>;


export type DeleteMentoringRecordAttachmentsByMentoringRecordAttachmentIdMutation = { __typename?: 'mutation_root', delete_mentoring_records_attachments?: { __typename?: 'mentoring_records_attachments_mutation_response', returning: Array<{ __typename?: 'mentoring_records_attachments', mentoring_record_id: string }> } | null };

export type GetAnswersByTeamIdQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetAnswersByTeamIdQuery = { __typename?: 'query_root', questions_answers: Array<{ __typename?: 'questions_answers', id: string }> };

export type GetBlankAnswersByTeamIdQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetBlankAnswersByTeamIdQuery = { __typename?: 'query_root', questions_answers: Array<{ __typename?: 'questions_answers', id: string }> };

export type SendManagerEmailMutationVariables = Exact<{
  team_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
}>;


export type SendManagerEmailMutation = { __typename?: 'mutation_root', sendManagerEmail?: { __typename?: 'SendManagerEmailOutput', status_code: string } | null };

export type SendUserEmailMutationVariables = Exact<{
  date?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  time?: InputMaybe<Scalars['String']>;
}>;


export type SendUserEmailMutation = { __typename?: 'mutation_root', sendUserEmail?: { __typename?: 'SendUserEmailOutput', status_code: string } | null };

export type InsertRequestedMentoringsOneMutationVariables = Exact<{
  cost?: InputMaybe<Scalars['Int']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  requested_date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  standard?: InputMaybe<Scalars['Boolean']>;
  scheduling_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Requested_Mentorings_Status_Enum>;
}>;


export type InsertRequestedMentoringsOneMutation = { __typename?: 'mutation_root', insert_requested_mentorings_one?: { __typename?: 'requested_mentorings', id: string, status: Requested_Mentorings_Status_Enum, requested_date?: string | null, team_id: string, cost: number, description: string } | null };

export type InsertRequestedMentoringsNewGamificationOneMutationVariables = Exact<{
  cost?: InputMaybe<Scalars['Int']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  requested_date?: InputMaybe<Scalars['timestamptz']>;
  available_dates?: InputMaybe<Array<Available_Mentoring_Dates_Insert_Input> | Available_Mentoring_Dates_Insert_Input>;
  tags?: InputMaybe<Array<Requests_Mentor_Tags_Insert_Input> | Requests_Mentor_Tags_Insert_Input>;
}>;


export type InsertRequestedMentoringsNewGamificationOneMutation = { __typename?: 'mutation_root', insert_requested_mentorings_one?: { __typename?: 'requested_mentorings', id: string, status: Requested_Mentorings_Status_Enum, requested_date?: string | null, team_id: string, cost: number, description: string, standard: boolean, created_at: any, team: { __typename?: 'teams', name?: string | null }, requests_mentor_tags: Array<{ __typename?: 'requests_mentor_tags', tag_name: string }>, available_dates: Array<{ __typename?: 'available_mentoring_dates', date: string }> } | null };

export type GetRequestedsMentoringsByTeamIdQueryVariables = Exact<{
  team_id?: InputMaybe<Scalars['uuid']>;
}>;


export type GetRequestedsMentoringsByTeamIdQuery = { __typename?: 'query_root', requested_mentorings: Array<{ __typename?: 'requested_mentorings', id: string, cost: number, status: Requested_Mentorings_Status_Enum, standard: boolean, description: string, scheduling_id?: string | null, requested_date?: string | null, created_at: any, mentoring_scheduling?: { __typename?: 'mentoring_scheduling', mentor?: { __typename?: 'mentors', name: string, email: string } | null, team: { __typename?: 'teams', name?: string | null, members: Array<{ __typename?: 'teams_users', user: { __typename?: 'users', email: string } }> }, meeting?: { __typename?: 'mentoring_scheduling_meeting', title: string, contact: string } | null, reschedule?: { __typename?: 'mentoring_reschedules', id: string, new_date: any } | null } | null, requests_mentor_tags: Array<{ __typename?: 'requests_mentor_tags', tag_name: string }>, available_dates: Array<{ __typename?: 'available_mentoring_dates', date: string }> }> };

export type UpdateRequestedMentoringByPkMutationVariables = Exact<{
  id?: Scalars['uuid'];
  cost?: InputMaybe<Scalars['Int']>;
  requested_date?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Requested_Mentorings_Status_Enum>;
  team_id?: InputMaybe<Scalars['uuid']>;
  scheduling_id?: InputMaybe<Scalars['uuid']>;
}>;


export type UpdateRequestedMentoringByPkMutation = { __typename?: 'mutation_root', update_requested_mentorings_by_pk?: { __typename?: 'requested_mentorings', id: string, cost: number, scheduling_id?: string | null, requested_date?: string | null, status: Requested_Mentorings_Status_Enum } | null };

export type SetRequestedMentoringStatusByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  status?: InputMaybe<Requested_Mentorings_Status_Enum>;
}>;


export type SetRequestedMentoringStatusByPkMutation = { __typename?: 'mutation_root', update_requested_mentorings_by_pk?: { __typename?: 'requested_mentorings', id: string, cost: number, status: Requested_Mentorings_Status_Enum } | null };

export type UpdateRequestedMentoringForRecordsByPkMutationVariables = Exact<{
  id?: Scalars['uuid'];
  status?: InputMaybe<Requested_Mentorings_Status_Enum>;
}>;


export type UpdateRequestedMentoringForRecordsByPkMutation = { __typename?: 'mutation_root', update_requested_mentorings_by_pk?: { __typename?: 'requested_mentorings', id: string } | null };

export type GetMoreAvailableRequestsDatesSubscriptionVariables = Exact<{
  min: Scalars['timestamptz'];
  max: Scalars['timestamptz'];
  start: Scalars['timestamp'];
}>;


export type GetMoreAvailableRequestsDatesSubscription = { __typename?: 'subscription_root', requested_mentorings: Array<{ __typename?: 'requested_mentorings', available_dates: Array<{ __typename?: 'available_mentoring_dates', date: string }> }> };

export type GetAllAvailableRequestsDatesQueryVariables = Exact<{
  min: Scalars['timestamptz'];
  max: Scalars['timestamptz'];
}>;


export type GetAllAvailableRequestsDatesQuery = { __typename?: 'query_root', requested_mentorings: Array<{ __typename?: 'requested_mentorings', available_dates: Array<{ __typename?: 'available_mentoring_dates', date: string }> }> };

export type GetDateRequestsQueryVariables = Exact<{
  date?: InputMaybe<Scalars['timestamptz']>;
}>;


export type GetDateRequestsQuery = { __typename?: 'query_root', requested_mentorings: Array<{ __typename?: 'requested_mentorings', id: string, cost: number, description: string, team: { __typename?: 'teams', id: string, name?: string | null }, requests_mentor_tags: Array<{ __typename?: 'requests_mentor_tags', tag_name: string }>, available_dates: Array<{ __typename?: 'available_mentoring_dates', date: string }> }> };

export type GetSchedulingsV2SubscriptionVariables = Exact<{
  min: Scalars['timestamptz'];
  max: Scalars['timestamptz'];
  start: Scalars['timestamptz'];
}>;


export type GetSchedulingsV2Subscription = { __typename?: 'subscription_root', mentoring_scheduling: Array<{ __typename?: 'mentoring_scheduling', id: string, request_id?: string | null }> };

export type GetMentorByCpfQueryVariables = Exact<{
  cpf?: InputMaybe<Scalars['String']>;
}>;


export type GetMentorByCpfQuery = { __typename?: 'query_root', mentors: Array<{ __typename?: 'mentors', id: string, tags: Array<{ __typename?: 'mentors_mentor_tags', tag_name: string }> }> };

export type GetTeamsScoresByTeamIdQueryVariables = Exact<{
  team_id?: InputMaybe<Scalars['uuid']>;
}>;


export type GetTeamsScoresByTeamIdQuery = { __typename?: 'query_root', teams_scores: Array<{ __typename?: 'teams_scores', available?: number | null, spent?: number | null, team_id?: string | null, total?: number | null }> };

export type GetContentByTeamIdQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;


export type GetContentByTeamIdQuery = { __typename?: 'query_root', contents: Array<{ __typename?: 'contents', description: string }> };

export type GetActiveLocationsCalendarsQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetActiveLocationsCalendarsQuery = { __typename?: 'query_root', default: Array<{ __typename?: 'locations', calendar_id?: string | null, names: Array<{ __typename?: 'locations_names', name: string }> }>, all: Array<{ __typename?: 'locations', calendar_id?: string | null, names: Array<{ __typename?: 'locations_names', name: string }> }> };

export type GetAvailableMentoringSchedulingByTeamIdQueryVariables = Exact<{
  team_id?: InputMaybe<Scalars['uuid']>;
}>;


export type GetAvailableMentoringSchedulingByTeamIdQuery = { __typename?: 'query_root', mentoring_scheduling: Array<{ __typename?: 'mentoring_scheduling', id: string, date: string, team_id: string, completed: boolean, request_id?: string | null }> };

export type GetAvailableMentoringSchedulingByTeamIdAndDateQueryVariables = Exact<{
  team_id?: InputMaybe<Scalars['uuid']>;
  date?: InputMaybe<Scalars['timestamptz']>;
}>;


export type GetAvailableMentoringSchedulingByTeamIdAndDateQuery = { __typename?: 'query_root', requested_mentorings: Array<{ __typename?: 'requested_mentorings', id: string, description: string, cost: number, requested_date?: string | null, scheduling_id?: string | null, status: Requested_Mentorings_Status_Enum, team_id: string }> };

export type GetTeamMethodologyByTeamIdQueryVariables = Exact<{
  team_id?: InputMaybe<Scalars['uuid']>;
}>;


export type GetTeamMethodologyByTeamIdQuery = { __typename?: 'query_root', teams: Array<{ __typename?: 'teams', groups: Array<{ __typename?: 'groups_teams', group: { __typename?: 'groups', methodology: Metodologies_Enum } }> }> };

export type GetGroupFlagsByTeamIdQueryVariables = Exact<{
  team_id?: InputMaybe<Scalars['uuid']>;
}>;


export type GetGroupFlagsByTeamIdQuery = { __typename?: 'query_root', groups_group_flags: Array<{ __typename?: 'groups_group_flags', flag: Groups_Flags_Enum }> };

export type InsertActionLoginMutationVariables = Exact<{
  user_id: Scalars['String'];
  at_page_slug: Scalars['String'];
  team_id: Scalars['uuid'];
}>;


export type InsertActionLoginMutation = { __typename?: 'mutation_root', insert_users_actions_one?: { __typename?: 'users_actions', id: string, type: Users_Actions_Types_Enum, user_id: string } | null };

export type InsertActionVisitMutationVariables = Exact<{
  user_id: Scalars['String'];
  team_id: Scalars['uuid'];
  at_page_slug: Scalars['String'];
  previous_page_slug?: InputMaybe<Scalars['String']>;
}>;


export type InsertActionVisitMutation = { __typename?: 'mutation_root', insert_users_actions_one?: { __typename?: 'users_actions', id: string, type: Users_Actions_Types_Enum, user_id: string } | null };

export type InsertActionInsertToolMutationVariables = Exact<{
  user_id: Scalars['String'];
  team_id: Scalars['uuid'];
  at_page_slug: Scalars['String'];
  tool_id: Scalars['uuid'];
}>;


export type InsertActionInsertToolMutation = { __typename?: 'mutation_root', insert_users_actions_one?: { __typename?: 'users_actions', id: string, type: Users_Actions_Types_Enum, user_id: string } | null };

export type InsertActionUpdateToolMutationVariables = Exact<{
  user_id: Scalars['String'];
  team_id: Scalars['uuid'];
  at_page_slug: Scalars['String'];
  tool_id: Scalars['uuid'];
}>;


export type InsertActionUpdateToolMutation = { __typename?: 'mutation_root', insert_users_actions_one?: { __typename?: 'users_actions', id: string, type: Users_Actions_Types_Enum, user_id: string } | null };

export type InsertActionFinishToolMutationVariables = Exact<{
  user_id: Scalars['String'];
  team_id: Scalars['uuid'];
  at_page_slug: Scalars['String'];
  tool_id: Scalars['uuid'];
}>;


export type InsertActionFinishToolMutation = { __typename?: 'mutation_root', insert_users_actions_one?: { __typename?: 'users_actions', id: string, type: Users_Actions_Types_Enum, user_id: string } | null };

export type InsertActionInsertMentoringMutationVariables = Exact<{
  user_id: Scalars['String'];
  team_id: Scalars['uuid'];
  mentoring_id: Scalars['uuid'];
}>;


export type InsertActionInsertMentoringMutation = { __typename?: 'mutation_root', insert_users_actions_one?: { __typename?: 'users_actions', id: string, type: Users_Actions_Types_Enum, user_id: string } | null };

export type InsertActionUpdateMentoringMutationVariables = Exact<{
  user_id: Scalars['String'];
  team_id: Scalars['uuid'];
  mentoring_id: Scalars['uuid'];
}>;


export type InsertActionUpdateMentoringMutation = { __typename?: 'mutation_root', insert_users_actions_one?: { __typename?: 'users_actions', id: string, type: Users_Actions_Types_Enum, user_id: string } | null };

export type InsertActionDeleteMentoringMutationVariables = Exact<{
  user_id: Scalars['String'];
  team_id: Scalars['uuid'];
  mentoring_id: Scalars['uuid'];
}>;


export type InsertActionDeleteMentoringMutation = { __typename?: 'mutation_root', insert_users_actions_one?: { __typename?: 'users_actions', id: string, type: Users_Actions_Types_Enum, user_id: string } | null };

export type InsertActionUpdateProfileMutationVariables = Exact<{
  user_id: Scalars['String'];
  team_id: Scalars['uuid'];
}>;


export type InsertActionUpdateProfileMutation = { __typename?: 'mutation_root', insert_users_actions_one?: { __typename?: 'users_actions', id: string, type: Users_Actions_Types_Enum, user_id: string } | null };

export type UserActionsPerGroupQueryVariables = Exact<{
  group_id: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['timestamptz']>;
  before: Scalars['timestamptz'];
  offset?: InputMaybe<Scalars['Int']>;
  types: Array<Users_Actions_Types_Enum> | Users_Actions_Types_Enum;
}>;


export type UserActionsPerGroupQuery = { __typename?: 'query_root', users_actions: Array<{ __typename: 'users_actions', created_at: string, at_page_slug: string, mentoring_id?: string | null, previous_page_slug?: string | null, tool_id?: string | null, type: Users_Actions_Types_Enum, user_id: string, team_id: string, id: string }>, users_actions_aggregate: { __typename?: 'users_actions_aggregate', aggregate?: { __typename?: 'users_actions_aggregate_fields', count: number } | null } };

export type UserActionsPerGroupSubSubscriptionVariables = Exact<{
  group_id: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['timestamptz']>;
  types: Array<Users_Actions_Types_Enum> | Users_Actions_Types_Enum;
}>;


export type UserActionsPerGroupSubSubscription = { __typename?: 'subscription_root', users_actions: Array<{ __typename?: 'users_actions', created_at: string, at_page_slug: string, mentoring_id?: string | null, previous_page_slug?: string | null, tool_id?: string | null, type: Users_Actions_Types_Enum, user_id: string, team_id: string, id: string }> };

export type UserActionsPerTeamQueryVariables = Exact<{
  team_id: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['timestamptz']>;
  before: Scalars['timestamptz'];
  offset?: InputMaybe<Scalars['Int']>;
  types: Array<Users_Actions_Types_Enum> | Users_Actions_Types_Enum;
}>;


export type UserActionsPerTeamQuery = { __typename?: 'query_root', users_actions: Array<{ __typename: 'users_actions', created_at: string, at_page_slug: string, mentoring_id?: string | null, previous_page_slug?: string | null, tool_id?: string | null, type: Users_Actions_Types_Enum, user_id: string, team_id: string, id: string }>, users_actions_aggregate: { __typename?: 'users_actions_aggregate', aggregate?: { __typename?: 'users_actions_aggregate_fields', count: number } | null } };

export type UserActionsPerTeamSubSubscriptionVariables = Exact<{
  team_id: Scalars['uuid'];
  limit?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['timestamptz']>;
  types: Array<Users_Actions_Types_Enum> | Users_Actions_Types_Enum;
}>;


export type UserActionsPerTeamSubSubscription = { __typename?: 'subscription_root', users_actions: Array<{ __typename: 'users_actions', created_at: string, at_page_slug: string, mentoring_id?: string | null, previous_page_slug?: string | null, tool_id?: string | null, type: Users_Actions_Types_Enum, user_id: string, team_id: string, id: string }> };

export type InsertGroupsMutationVariables = Exact<{
  group_name?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['date']>;
  start_date?: InputMaybe<Scalars['date']>;
  location_id?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  methodology?: InputMaybe<Metodologies_Enum>;
  content_id?: InputMaybe<Scalars['uuid']>;
  url?: InputMaybe<Scalars['String']>;
  diagnostic_id?: InputMaybe<Scalars['uuid']>;
  flags?: InputMaybe<Groups_Group_Flags_Arr_Rel_Insert_Input>;
}>;


export type InsertGroupsMutation = { __typename?: 'mutation_root', insert_groups?: { __typename?: 'groups_mutation_response', returning: Array<{ __typename?: 'groups', id: string }> } | null };

export type InsertTeamUserMutationVariables = Exact<{
  team_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
}>;


export type InsertTeamUserMutation = { __typename?: 'mutation_root', insert_teams_users?: { __typename?: 'teams_users_mutation_response', returning: Array<{ __typename?: 'teams_users', user_id: string, team: { __typename?: 'teams', name?: string | null } }> } | null };

export type InsertTeamMutationVariables = Exact<{
  description?: InputMaybe<Scalars['String']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  cnpj?: InputMaybe<Scalars['String']>;
}>;


export type InsertTeamMutation = { __typename?: 'mutation_root', insert_teams?: { __typename?: 'teams_mutation_response', returning: Array<{ __typename?: 'teams', id: string, name?: string | null }> } | null };

export type GetContentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContentsQuery = { __typename?: 'query_root', contents: Array<{ __typename?: 'contents', id: string, description: string }> };

export type GetDiagnosticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDiagnosticsQuery = { __typename?: 'query_root', diagnostics: Array<{ __typename?: 'diagnostics', id: string, description: string }> };

export type InsertLocationWithGroupMutationVariables = Exact<{
  location_name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  calendar_id?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  group_name?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
  end_date?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  methodology?: InputMaybe<Metodologies_Enum>;
  flags?: InputMaybe<Groups_Group_Flags_Arr_Rel_Insert_Input>;
  content_id?: InputMaybe<Scalars['uuid']>;
  diagnostic_id?: InputMaybe<Scalars['uuid']>;
}>;


export type InsertLocationWithGroupMutation = { __typename?: 'mutation_root', insert_locations?: { __typename?: 'locations_mutation_response', returning: Array<{ __typename?: 'locations', id: string }> } | null };

export type GetVideosByTeamAllContentsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  order: Order_By;
}>;


export type GetVideosByTeamAllContentsQuery = { __typename?: 'query_root', videos: Array<{ __typename?: 'videos', description?: string | null, title: string, id: string, link: string, published_at: string, thumbnail: string, tags: Array<{ __typename?: 'videos_youtube_tags', tag: string }> }> };

export type GetUserByCpfQueryVariables = Exact<{
  cpf?: InputMaybe<Scalars['String']>;
}>;


export type GetUserByCpfQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: string }> };

export type GetMentoringSchedulingLteByTeamIdQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetMentoringSchedulingLteByTeamIdQuery = { __typename?: 'query_root', mentoring_scheduling: Array<{ __typename?: 'mentoring_scheduling', id: string, date: string, request?: { __typename?: 'requested_mentorings', description: string } | null }> };

export type GetUserByIdentifierQueryVariables = Exact<{
  identifier?: InputMaybe<Scalars['String']>;
}>;


export type GetUserByIdentifierQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', email: string, cpf: string, names: Array<{ __typename?: 'users_names', name: string }>, profile_picture?: { __typename?: 'files', path: string } | null, teams: Array<{ __typename?: 'teams_users', team: { __typename?: 'teams', name?: string | null, groups: Array<{ __typename?: 'groups_teams', group: { __typename?: 'groups', names: Array<{ __typename?: 'groups_names', name: string }> } }> } }> }> };

export type CountAllToolsDoneByTeamIdQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type CountAllToolsDoneByTeamIdQuery = { __typename?: 'query_root', questionnaires_teams_states: Array<{ __typename?: 'questionnaires_teams_states', finished: boolean, questionnaire: { __typename?: 'questionnaires', tool_id: string } }> };

export type GetToolsByTeamIdQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetToolsByTeamIdQuery = { __typename?: 'query_root', teams: Array<{ __typename?: 'teams', groups: Array<{ __typename?: 'groups_teams', group: { __typename?: 'groups', contents: Array<{ __typename?: 'groups_contents', content: { __typename?: 'contents', sections: Array<{ __typename?: 'contents_sections', section: { __typename?: 'sections', outputs: Array<{ __typename?: 'outputs', tools: Array<{ __typename?: 'outputs_tools', id: string, tool: { __typename?: 'tools', name: string } }> }> } }> } }> } }> }> };

export type GetMentorTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMentorTagsQuery = { __typename?: 'query_root', mentor_tags: Array<{ __typename?: 'mentor_tags', name: string }> };

export type CancellRequestMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
}>;


export type CancellRequestMutation = { __typename?: 'mutation_root', update_requested_mentorings?: { __typename?: 'requested_mentorings_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'requested_mentorings', id: string }> } | null, update_mentoring_scheduling?: { __typename?: 'mentoring_scheduling_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'mentoring_scheduling', id: string }> } | null };

export type InsertMentoringRescheduleMutationVariables = Exact<{
  new_date?: InputMaybe<Scalars['timestamp']>;
  old_date?: InputMaybe<Scalars['timestamp']>;
  schedule_id?: InputMaybe<Scalars['uuid']>;
  description: Scalars['String'];
}>;


export type InsertMentoringRescheduleMutation = { __typename?: 'mutation_root', insert_mentoring_reschedules?: { __typename?: 'mentoring_reschedules_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'mentoring_reschedules', id: string }> } | null };

export type InsertRescheduleIntoScheduleMutationVariables = Exact<{
  id: Scalars['uuid'];
  reschedule_id?: InputMaybe<Scalars['uuid']>;
}>;


export type InsertRescheduleIntoScheduleMutation = { __typename?: 'mutation_root', update_mentoring_scheduling_by_pk?: { __typename?: 'mentoring_scheduling', reschedule?: { __typename?: 'mentoring_reschedules', new_date: any, old_date: any } | null } | null };

export type SendEmailMutationVariables = Exact<{
  to: Array<Scalars['String']> | Scalars['String'];
  message: Scalars['String'];
  subject: Scalars['String'];
}>;


export type SendEmailMutation = { __typename?: 'mutation_root', sendEmail?: { __typename?: 'SendUserEmailOutput', status_code: string } | null };

export type GetMembersOfTeamEmailQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetMembersOfTeamEmailQuery = { __typename?: 'query_root', teams: Array<{ __typename?: 'teams', members: Array<{ __typename?: 'teams_users', user: { __typename?: 'users', email: string } }> }> };

export type GetManagerEmailByTeamIdQueryVariables = Exact<{
  team_id: Scalars['uuid'];
}>;


export type GetManagerEmailByTeamIdQuery = { __typename?: 'query_root', teams: Array<{ __typename?: 'teams', groups: Array<{ __typename?: 'groups_teams', group: { __typename?: 'groups', email: string } }> }> };

export type UpdateTeamDisableMutationVariables = Exact<{
  disabled: Scalars['Boolean'];
  id?: InputMaybe<Scalars['uuid']>;
}>;


export type UpdateTeamDisableMutation = { __typename?: 'mutation_root', update_teams_by_pk?: { __typename?: 'teams', id: string, disabled: boolean } | null };

export type UpdateRequestV2ManagerMutationVariables = Exact<{
  requested_date: Scalars['timestamptz'];
  request_id: Scalars['uuid'];
}>;


export type UpdateRequestV2ManagerMutation = { __typename?: 'mutation_root', update_requested_mentorings_by_pk?: { __typename?: 'requested_mentorings', id: string } | null };

export type InsertMentorMutationVariables = Exact<{
  cpf: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  tags?: InputMaybe<Array<Mentors_Mentor_Tags_Insert_Input> | Mentors_Mentor_Tags_Insert_Input>;
}>;


export type InsertMentorMutation = { __typename?: 'mutation_root', insert_mentors_one?: { __typename?: 'mentors', id: string } | null };

export type TeamAcceptMentoringMutationVariables = Exact<{
  id: Scalars['uuid'];
  accept_mentoring: Scalars['Boolean'];
}>;


export type TeamAcceptMentoringMutation = { __typename?: 'mutation_root', update_teams_by_pk?: { __typename?: 'teams', accept_mentoring?: boolean | null } | null };

export type GetTeamAcceptQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetTeamAcceptQuery = { __typename?: 'query_root', teams_by_pk?: { __typename?: 'teams', accept_mentoring?: boolean | null } | null };

export type GetGroupsFlagsByIdQueryVariables = Exact<{
  group_id: Scalars['uuid'];
}>;


export type GetGroupsFlagsByIdQuery = { __typename?: 'query_root', groups_group_flags: Array<{ __typename?: 'groups_group_flags', flag: Groups_Flags_Enum }> };

export type CreateGroupFlagMutationVariables = Exact<{
  flag: Groups_Flags_Enum;
  group_id: Scalars['uuid'];
}>;


export type CreateGroupFlagMutation = { __typename?: 'mutation_root', insert_groups_group_flags?: { __typename?: 'groups_group_flags_mutation_response', returning: Array<{ __typename?: 'groups_group_flags', flag: Groups_Flags_Enum }> } | null };

export type DeleteGroupFlagMutationVariables = Exact<{
  flag: Groups_Flags_Enum;
  group_id: Scalars['uuid'];
}>;


export type DeleteGroupFlagMutation = { __typename?: 'mutation_root', delete_groups_group_flags?: { __typename?: 'groups_group_flags_mutation_response', returning: Array<{ __typename?: 'groups_group_flags', flag: Groups_Flags_Enum }> } | null };

export type GetMentorEmailByTagQueryVariables = Exact<{
  tag: Scalars['String'];
}>;


export type GetMentorEmailByTagQuery = { __typename?: 'query_root', mentors: Array<{ __typename?: 'mentors', email: string }> };

export type GetGroupNameByIdQueryVariables = Exact<{
  group_id: Scalars['uuid'];
}>;


export type GetGroupNameByIdQuery = { __typename?: 'query_root', groups_by_pk?: { __typename?: 'groups', names: Array<{ __typename?: 'groups_names', name: string }> } | null };

export type DeleteDeleteMentorTagsMutationVariables = Exact<{
  mentor_id: Scalars['uuid'];
}>;


export type DeleteDeleteMentorTagsMutation = { __typename?: 'mutation_root', delete_mentors_mentor_tags?: { __typename?: 'mentors_mentor_tags_mutation_response', affected_rows: number } | null };

export type InsertMentorTagsMutationVariables = Exact<{
  objects?: Array<Mentors_Mentor_Tags_Insert_Input> | Mentors_Mentor_Tags_Insert_Input;
}>;


export type InsertMentorTagsMutation = { __typename?: 'mutation_root', insert_mentors_mentor_tags?: { __typename?: 'mentors_mentor_tags_mutation_response', affected_rows: number } | null };

export type UpdateTermMutationVariables = Exact<{
  id: Scalars['String'];
  email: Scalars['String'];
  last_term_accepted: Scalars['timestamptz'];
}>;


export type UpdateTermMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', last_term_accepted: string } | null };

export type GetContentBaseInfoQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetContentBaseInfoQuery = { __typename?: 'query_root', contents_by_pk?: { __typename?: 'contents', description: string, sections: Array<{ __typename?: 'contents_sections', section: { __typename?: 'sections', id: string, name: string, slug: string, hex_color?: string | null, intro: string, outputs_text: string, order?: number | null, section_icons_files: Array<{ __typename?: 'sections_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }>, outputs: Array<{ __typename?: 'outputs', id: string, name: string, slug: string, intro: string, order?: number | null, icons: Array<{ __typename?: 'outputs_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }>, multimedia_content: { __typename?: 'multimedia_contents', ebooks_link?: string | null, resources?: string | null, video_lessons: Array<{ __typename?: 'multimedia_contents_links', link: string }> }, tools: Array<{ __typename?: 'outputs_tools', tool: { __typename?: 'tools', id: string, name: string, icons: Array<{ __typename?: 'tools_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> } }> }> } }> } | null };

export type GetAllContentByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetAllContentByIdQuery = { __typename?: 'query_root', contents_by_pk?: { __typename?: 'contents', description: string, repositories: Array<{ __typename?: 'repositories', link: string, app_key: string }>, sections: Array<{ __typename?: 'contents_sections', section: { __typename?: 'sections', name: string, slug: string, intro: string, hex_color?: string | null, outputs_text: string, order?: number | null, section_icons_files: Array<{ __typename?: 'sections_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }>, outputs: Array<{ __typename?: 'outputs', name: string, slug: string, intro: string, order?: number | null, icons: Array<{ __typename?: 'outputs_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }>, multimedia_content: { __typename?: 'multimedia_contents', ebooks_link?: string | null, resources?: string | null, video_lessons: Array<{ __typename?: 'multimedia_contents_links', link: string }> }, tools: Array<{ __typename?: 'outputs_tools', order?: number | null, tool: { __typename?: 'tools', name: string, slug: string, points: number, intro: string, explanation: string, icons: Array<{ __typename?: 'tools_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }>, questionnaires: Array<{ __typename?: 'questionnaires', active: boolean, questions: Array<{ __typename?: 'questions', order: number, title?: string | null, subtitle?: string | null, type: Questions_Types_Enum }> }>, multimedia_content: { __typename?: 'multimedia_contents', ebooks_link?: string | null, resources?: string | null, video_lessons: Array<{ __typename?: 'multimedia_contents_links', link: string }> } } }> }> } }>, video_repositories: Array<{ __typename?: 'video_repositories', youtube_channel_id: string, videos: Array<{ __typename?: 'videos', link: string, title: string, description?: string | null, thumbnail: string, published_at: string }> }> } | null };

export type InsertContentMutationVariables = Exact<{
  object: Contents_Insert_Input;
}>;


export type InsertContentMutation = { __typename?: 'mutation_root', insert_contents_one?: { __typename?: 'contents', id: string } | null };

export type GetOutputByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetOutputByIdQuery = { __typename?: 'query_root', outputs_by_pk?: { __typename?: 'outputs', slug: string, name: string, intro: string, order?: number | null, icons: Array<{ __typename?: 'outputs_icons', file: { __typename?: 'files', id: string, path: string } }>, multimedia_content: { __typename?: 'multimedia_contents', ebooks_link?: string | null, resources?: string | null, video_lessons: Array<{ __typename?: 'multimedia_contents_links', link: string }> }, section: { __typename?: 'sections', id: string, slug: string }, tools: Array<{ __typename?: 'outputs_tools', order?: number | null, tool: { __typename?: 'tools', name: string, icons: Array<{ __typename?: 'tools_icons', file: { __typename?: 'files', id: string, path: string } }> } }> } | null };

export type GetSectionBasicsByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetSectionBasicsByIdQuery = { __typename?: 'query_root', sections_by_pk?: { __typename?: 'sections', id: string, name: string, slug: string, hex_color?: string | null, intro: string, section_icons_files: Array<{ __typename?: 'sections_icons', type: Icons_Types_Enum, file: { __typename?: 'files', path: string } }> } | null };

export type GetOutputQuantityQueryVariables = Exact<{
  section_id: Scalars['uuid'];
}>;


export type GetOutputQuantityQuery = { __typename?: 'query_root', outputs_aggregate: { __typename?: 'outputs_aggregate', aggregate?: { __typename?: 'outputs_aggregate_fields', count: number } | null } };

export type UploadFileMutationVariables = Exact<{
  base64str: Scalars['String'];
  container: Scalars['String'];
  filename: Scalars['String'];
}>;


export type UploadFileMutation = { __typename?: 'mutation_root', uploadFile?: { __typename?: 'UploadOutput', file_id: string, file_path: string } | null };

export type InsertOutputMutationVariables = Exact<{
  slug: Scalars['String'];
  parent_section_id: Scalars['uuid'];
  order: Scalars['smallint'];
  name: Scalars['String'];
  videos: Array<Multimedia_Contents_Links_Insert_Input> | Multimedia_Contents_Links_Insert_Input;
  resources: Scalars['String'];
  ebooks_link: Scalars['String'];
  intro: Scalars['String'];
  iconId: Scalars['uuid'];
}>;


export type InsertOutputMutation = { __typename?: 'mutation_root', insert_outputs_one?: { __typename?: 'outputs', id: string } | null };

export type EditOutputMutationVariables = Exact<{
  id: Scalars['uuid'];
  slug: Scalars['String'];
  parent_section_id: Scalars['uuid'];
  order: Scalars['smallint'];
  name: Scalars['String'];
  intro: Scalars['String'];
}>;


export type EditOutputMutation = { __typename?: 'mutation_root', update_outputs_by_pk?: { __typename?: 'outputs', id: string, multimedia_content_id: string, icons: Array<{ __typename?: 'outputs_icons', file_id: string }> } | null };

export type EditMultimediaContentMutationVariables = Exact<{
  id: Scalars['uuid'];
  resources?: InputMaybe<Scalars['String']>;
  ebooks_link?: InputMaybe<Scalars['String']>;
}>;


export type EditMultimediaContentMutation = { __typename?: 'mutation_root', update_multimedia_contents_by_pk?: { __typename?: 'multimedia_contents', video_lessons: Array<{ __typename?: 'multimedia_contents_links', id: string }> } | null };

export type DeleteMultimediaLinksMutationVariables = Exact<{
  ids: Array<Multimedia_Contents_Links_Bool_Exp> | Multimedia_Contents_Links_Bool_Exp;
}>;


export type DeleteMultimediaLinksMutation = { __typename?: 'mutation_root', delete_multimedia_contents_links?: { __typename?: 'multimedia_contents_links_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'multimedia_contents_links', id: string }> } | null };

export type InsertMultimediaLinksMutationVariables = Exact<{
  links: Array<Multimedia_Contents_Links_Insert_Input> | Multimedia_Contents_Links_Insert_Input;
}>;


export type InsertMultimediaLinksMutation = { __typename?: 'mutation_root', insert_multimedia_contents_links?: { __typename?: 'multimedia_contents_links_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'multimedia_contents_links', link: string }> } | null };

export type UpdateFileByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  path: Scalars['String'];
}>;


export type UpdateFileByPkMutation = { __typename?: 'mutation_root', update_files_by_pk?: { __typename?: 'files', path: string } | null };

export type GetToolQuantityQueryVariables = Exact<{
  outputId: Scalars['uuid'];
}>;


export type GetToolQuantityQuery = { __typename?: 'query_root', tools_aggregate: { __typename?: 'tools_aggregate', aggregate?: { __typename?: 'tools_aggregate_fields', count: number } | null } };

export type InsertToolMutationVariables = Exact<{
  order: Scalars['smallint'];
  output_id: Scalars['uuid'];
  video_lessons: Array<Multimedia_Contents_Links_Insert_Input> | Multimedia_Contents_Links_Insert_Input;
  name: Scalars['String'];
  points: Scalars['smallint'];
  slug: Scalars['String'];
  explanation: Scalars['String'];
  intro: Scalars['String'];
  ebooks_link: Scalars['String'];
  resources: Scalars['String'];
  file_id: Scalars['uuid'];
  questions: Array<Questions_Insert_Input> | Questions_Insert_Input;
}>;


export type InsertToolMutation = { __typename?: 'mutation_root', insert_outputs_tools_one?: { __typename?: 'outputs_tools', id: string } | null };

export type UpdateToolByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
  points: Scalars['smallint'];
  slug: Scalars['String'];
  explanation: Scalars['String'];
  intro: Scalars['String'];
}>;


export type UpdateToolByPkMutation = { __typename?: 'mutation_root', update_tools_by_pk?: { __typename?: 'tools', id: string, multimedia_content_id: string, icons: Array<{ __typename?: 'tools_icons', file_id: string }> } | null };

export type DisableToolQuestionnairesMutationVariables = Exact<{
  tool_id: Scalars['uuid'];
}>;


export type DisableToolQuestionnairesMutation = { __typename?: 'mutation_root', update_questionnaires?: { __typename?: 'questionnaires_mutation_response', returning: Array<{ __typename?: 'questionnaires', id: string }> } | null };

export type InsertQuestionnaireMutationVariables = Exact<{
  tool_id: Scalars['uuid'];
  questions: Array<Questions_Insert_Input> | Questions_Insert_Input;
}>;


export type InsertQuestionnaireMutation = { __typename?: 'mutation_root', insert_questionnaires?: { __typename?: 'questionnaires_mutation_response', returning: Array<{ __typename?: 'questionnaires', id: string }> } | null };

export type GetContentDescriptionByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetContentDescriptionByPkQuery = { __typename?: 'query_root', contents_by_pk?: { __typename?: 'contents', description: string } | null };

export type UpdateContentDescriptionMutationVariables = Exact<{
  id: Scalars['uuid'];
  description: Scalars['String'];
}>;


export type UpdateContentDescriptionMutation = { __typename?: 'mutation_root', update_contents_by_pk?: { __typename?: 'contents', id: string } | null };

export type DeleteOutputToolsMutationVariables = Exact<{
  tool_id: Scalars['uuid'];
}>;


export type DeleteOutputToolsMutation = { __typename?: 'mutation_root', delete_outputs_tools?: { __typename?: 'outputs_tools_mutation_response', returning: Array<{ __typename?: 'outputs_tools', id: string }> } | null };

export const SectionCoreFragmentDoc = gql`
    fragment SectionCore on sections {
  id
  name
  slug
  hex_color
  intro
  section_icons_files {
    type
    file {
      path
    }
  }
}
    `;
export const OutputCoreFragmentDoc = gql`
    fragment OutputCore on outputs {
  id
  intro
  name
  slug
  order
  icons {
    type
    file {
      path
    }
  }
}
    `;
export const ToolCoreFragmentDoc = gql`
    fragment ToolCore on tools {
  id
  name
  intro
  explanation
  slug
  points
  icons {
    type
    file {
      id
      path
    }
  }
}
    `;
export const MultimediaContentFullFragmentDoc = gql`
    fragment MultimediaContentFull on multimedia_contents {
  ebooks_link
  resources
  video_lessons {
    link
  }
}
    `;
export const UserPrivateInfoFragmentDoc = gql`
    fragment UserPrivateInfo on users {
  cpf
  created_at
  updated_at
  deactivated_since
  last_term_accepted
  role
}
    `;
export const UserPublicInfoFragmentDoc = gql`
    fragment UserPublicInfo on users {
  id
  email
  names(order_by: {created_at: desc}) {
    name
  }
  profile_picture {
    path
  }
  social_media {
    facebook
    instagram
    linkedin
    other
    youtube
    twitter
  }
  occupation
}
    `;
export const TeamCoreFragmentDoc = gql`
    fragment TeamCore on teams {
  description
  disabled
  id
  logo {
    path
  }
  names(order_by: {created_at: desc}) {
    name
  }
  tags {
    tag {
      value
    }
  }
  social_media {
    facebook
    instagram
    linkedin
    other
    youtube
    twitter
  }
  members {
    user {
      id
      names(order_by: {created_at: desc}) {
        name
      }
      email
    }
  }
  cnpj
}
    `;
export const GroupCoreFragmentDoc = gql`
    fragment GroupCore on groups {
  id
  start_date
  end_date
  names(order_by: {created_at: desc}) {
    name
  }
  location_id
}
    `;
export const GetSectionsBasicDoc = gql`
    query GetSectionsBasic($team_id: uuid!) {
  sections(
    where: {contents_sections: {content: {groups: {_and: {active: {_eq: true}, group: {teams: {team_id: {_eq: $team_id}}}}}}}}
    order_by: {order: asc}
  ) {
    id
    name
    slug
    hex_color
    intro
    section_icons_files {
      type
      file {
        path
      }
    }
  }
}
    `;
export const SectionsBasicByGroupDoc = gql`
    query SectionsBasicByGroup($group_id: uuid!) {
  sections(
    where: {contents_sections: {content: {groups: {_and: {active: {_eq: true}, group: {id: {_eq: $group_id}}}}}}}
    order_by: {order: asc}
  ) {
    id
    name
    slug
    hex_color
    intro
    section_icons_files {
      type
      file {
        path
      }
    }
  }
}
    `;
export const GetSectionsCoreDoc = gql`
    query GetSectionsCore($team_id: uuid!) {
  sections(
    where: {contents_sections: {content: {groups: {_and: {active: {_eq: true}, group: {teams: {team_id: {_eq: $team_id}}}}}}}}
    order_by: {order: asc}
  ) {
    ...SectionCore
  }
}
    ${SectionCoreFragmentDoc}`;
export const GetOutputsCoresBySectionDoc = gql`
    query GetOutputsCoresBySection($sectionId: uuid!) {
  outputs(where: {parent_section_id: {_eq: $sectionId}}, order_by: {order: asc}) {
    ...OutputCore
  }
}
    ${OutputCoreFragmentDoc}`;
export const GetOutputCoreBySlugDoc = gql`
    query GetOutputCoreBySlug($slug: String!) {
  outputs(where: {slug: {_eq: $slug}}) {
    ...OutputCore
  }
}
    ${OutputCoreFragmentDoc}`;
export const GetOutputCoreMultimediaBySlugDoc = gql`
    query GetOutputCoreMultimediaBySlug($slug: String!, $team_id: uuid!) {
  outputs(
    where: {_and: {slug: {_eq: $slug}}, section: {contents_sections: {content: {groups: {_and: {active: {_eq: true}, group: {teams: {team: {id: {_eq: $team_id}}}}}}}}}}
  ) {
    ...OutputCore
    multimedia_content {
      ...MultimediaContentFull
    }
  }
}
    ${OutputCoreFragmentDoc}
${MultimediaContentFullFragmentDoc}`;
export const GetToolsCoreByOutputDoc = gql`
    query GetToolsCoreByOutput($outputId: uuid!) {
  outputs_tools(where: {output_id: {_eq: $outputId}}, order_by: {order: asc}) {
    order
    tool {
      ...ToolCore
    }
  }
}
    ${ToolCoreFragmentDoc}`;
export const GetToolCoreMultimediaBySlugDoc = gql`
    query GetToolCoreMultimediaBySlug($slug: String!, $team_id: uuid!) {
  tools(
    where: {_and: {slug: {_eq: $slug}, outputs: {output: {section: {contents_sections: {content: {groups: {_and: {active: {_eq: true}, group: {teams: {team: {id: {_eq: $team_id}}}}}}}}}}}}}
  ) {
    ...ToolCore
    multimedia_content {
      ...MultimediaContentFull
    }
  }
}
    ${ToolCoreFragmentDoc}
${MultimediaContentFullFragmentDoc}`;
export const GetToolCoreByIdDoc = gql`
    query GetToolCoreById($id: uuid!) {
  tools_by_pk(id: $id) {
    ...ToolCore
  }
}
    ${ToolCoreFragmentDoc}`;
export const GetAllToolInfoByIdDoc = gql`
    query GetAllToolInfoById($id: uuid!) {
  tools_by_pk(id: $id) {
    ...ToolCore
    multimedia_content {
      ...MultimediaContentFull
    }
    questionnaires(
      limit: 1
      order_by: {created_at: desc}
      where: {active: {_eq: true}}
    ) {
      id
      questions(order_by: {order: asc}) {
        id
        order
        type
        title
        subtitle
        options {
          value
          label
        }
      }
    }
  }
}
    ${ToolCoreFragmentDoc}
${MultimediaContentFullFragmentDoc}`;
export const QuestionnaireActiveOfToolDoc = gql`
    query QuestionnaireActiveOfTool($tool_id: uuid!, $team_id: uuid!) {
  questionnaires(
    limit: 1
    order_by: {created_at: desc}
    where: {_and: {tool_id: {_eq: $tool_id}, active: {_eq: true}}}
  ) {
    id
    tool {
      name
    }
    questions(order_by: {order: asc}) {
      id
      order
      title
      subtitle
      type
      options {
        value
        label
      }
      question_answers(where: {team_id: {_eq: $team_id}}) {
        answer
      }
    }
    teams_states(where: {team_id: {_eq: $team_id}}) {
      updated_at
      finished
    }
  }
}
    `;
export const SignUpUserDoc = gql`
    mutation SignUpUser($cpf: String!, $email: String!, $name: String!, $password: String!, $address_doc_b64str: String, $id_doc_b64str: String!, $token: String!, $team_id: String, $dob: date!, $phone: String!, $cep: String!, $state: String!, $city: String!, $address: String!, $secondLine: String) {
  createUser(
    cpf: $cpf
    email: $email
    name: $name
    password: $password
    address_doc_b64str: $address_doc_b64str
    id_doc_b64str: $id_doc_b64str
    token: $token
    team_id: $team_id
    dob: $dob
    phone: $phone
    cep: $cep
    state: $state
    city: $city
    address: $address
    secondLine: $secondLine
  )
}
    `;
export const SignUpUserPreregisteredDoc = gql`
    mutation SignUpUserPreregistered($email: String!, $password: String!, $id_doc_b64str: String!, $token: String!, $team_id: String) {
  createUserPreregistered(
    email: $email
    id_doc_b64str: $id_doc_b64str
    password: $password
    token: $token
    team_id: $team_id
  )
}
    `;
export const SignUpSubscriptionDoc = gql`
    subscription SignUpSubscription($action_id: uuid!) {
  createUser(id: $action_id) {
    errors
    output {
      id
      name
      email
      cpf
    }
  }
}
    `;
export const SignUpPreregisteredSubDoc = gql`
    subscription SignUpPreregisteredSub($action_id: uuid!) {
  createUserPreregistered(id: $action_id) {
    errors
    output {
      cpf
      email
      id
      name
    }
  }
}
    `;
export const GetUserCoreDoc = gql`
    query GetUserCore($id: String!) {
  users(where: {id: {_eq: $id}}) {
    ...UserPrivateInfo
    ...UserPublicInfo
  }
}
    ${UserPrivateInfoFragmentDoc}
${UserPublicInfoFragmentDoc}`;
export const GetTeamCoreByIdDoc = gql`
    query GetTeamCoreById($id: uuid!) {
  teams_by_pk(id: $id) {
    ...TeamCore
  }
}
    ${TeamCoreFragmentDoc}`;
export const GetTeamsOfUserDoc = gql`
    query GetTeamsOfUser($user_id: String!) {
  teams(where: {members: {user_id: {_eq: $user_id}}}) {
    ...TeamCore
  }
}
    ${TeamCoreFragmentDoc}`;
export const GetGroupsOfTeamDoc = gql`
    query GetGroupsOfTeam($team_id: uuid!) {
  groups(where: {teams: {team_id: {_eq: $team_id}}}) {
    ...GroupCore
  }
}
    ${GroupCoreFragmentDoc}`;
export const GetTeamCoreDoc = gql`
    query GetTeamCore($user_id: String!) {
  team_invites(where: {_and: {user_id: {_eq: $user_id}, status: {_eq: pending}}}) {
    id
    team {
      ...TeamCore
    }
  }
}
    ${TeamCoreFragmentDoc}`;
export const RespondTeamInvitationDoc = gql`
    mutation RespondTeamInvitation($invite_id: String!, $accept: Boolean!) {
  respondTeamInvitation(invite_id: $invite_id, accept: $accept) {
    status
    team_id
    user_id
  }
}
    `;
export const GetTeamScoreDoc = gql`
    query GetTeamScore($team_id: uuid!) {
  tools_aggregate(
    where: {questionnaires: {teams_states: {_and: {finished: {_eq: true}, team_id: {_eq: $team_id}}}}}
  ) {
    aggregate {
      sum {
        points
      }
    }
  }
}
    `;
export const GetTeamsScoresByGroupDoc = gql`
    query GetTeamsScoresByGroup($group_id: uuid!) {
  teams_scores(
    order_by: {total: desc}
    where: {team: {groups: {group_id: {_eq: $group_id}}, disabled: {_eq: false}}}
  ) {
    total
    team {
      id
      names(limit: 1, order_by: {created_at: desc}) {
        name
      }
    }
  }
}
    `;
export const CountToolsDoneOfSectionByTeamDoc = gql`
    query CountToolsDoneOfSectionByTeam($team_id: uuid!, $section_id: uuid!) {
  done: tools_aggregate(
    where: {_and: {questionnaires: {teams_states: {_and: {finished: {_eq: true}, team_id: {_eq: $team_id}}}}, outputs: {output: {parent_section_id: {_eq: $section_id}}}}}
  ) {
    aggregate {
      count
    }
  }
  all: tools_aggregate(
    where: {outputs: {output: {parent_section_id: {_eq: $section_id}}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const CountToolsDoneOfSectionByTeamGetOutputsNodesDoc = gql`
    query CountToolsDoneOfSectionByTeamGetOutputsNodes($team_id: uuid!, $section_id: uuid!) {
  done: tools_aggregate(
    where: {_and: {questionnaires: {teams_states: {_and: {finished: {_eq: true}, team_id: {_eq: $team_id}}}}, outputs: {output: {parent_section_id: {_eq: $section_id}}}}}
  ) {
    nodes {
      outputs {
        output_id
      }
    }
  }
  all: tools_aggregate(
    where: {outputs: {output: {parent_section_id: {_eq: $section_id}}}}
  ) {
    nodes {
      outputs {
        output_id
      }
    }
  }
}
    `;
export const GetOutputsDoneBySectionDoc = gql`
    query GetOutputsDoneBySection($section_id: uuid!, $team_id: uuid!) {
  outputs(where: {parent_section_id: {_eq: $section_id}}) {
    ...OutputCore
    done: tools_aggregate(
      where: {tool: {questionnaires: {teams_states: {_and: {finished: {_eq: true}, team_id: {_eq: $team_id}}}}}}
    ) {
      aggregate {
        count
      }
    }
    all: tools_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${OutputCoreFragmentDoc}`;
export const GetLocationsCalendarsDoc = gql`
    query GetLocationsCalendars($team_id: uuid!) {
  default: locations(where: {groups: {teams: {team_id: {_eq: $team_id}}}}) {
    calendar_id
    names(order_by: {created_at: desc}) {
      name
    }
  }
  all: locations(order_by: {names_aggregate: {min: {name: asc}}}) {
    calendar_id
    names(order_by: {created_at: desc}) {
      name
    }
  }
}
    `;
export const GetTagsDoc = gql`
    query GetTags {
  tags {
    id
    value
  }
}
    `;
export const GetMembersOfTeamDoc = gql`
    query GetMembersOfTeam($team_id: uuid!) {
  users(where: {teams: {team_id: {_eq: $team_id}}}) {
    role
    ...UserPublicInfo
  }
}
    ${UserPublicInfoFragmentDoc}`;
export const UploadProfilePictureDoc = gql`
    mutation UploadProfilePicture($user_id: String!, $b64_img: String!) {
  uploadProfilePicture(user_id: $user_id, b64_img: $b64_img) {
    id
    path
  }
}
    `;
export const UpdateUserNameDoc = gql`
    mutation UpdateUserName($user_id: String!, $name: String!) {
  insert_users_names_one(object: {user_id: $user_id, name: $name}) {
    name
    user_id
  }
}
    `;
export const UpdateUserOccupationDoc = gql`
    mutation UpdateUserOccupation($user_id: String!, $occupation: String!) {
  update_users(where: {id: {_eq: $user_id}}, _set: {occupation: $occupation}) {
    returning {
      id
      occupation
    }
  }
}
    `;
export const UpdateTeamDoc = gql`
    mutation UpdateTeam($team_id: uuid!, $cnpj: String, $description: String) {
  update_teams_by_pk(
    pk_columns: {id: $team_id}
    _set: {cnpj: $cnpj, description: $description}
  ) {
    id
    cnpj
    description
  }
}
    `;
export const InsertTeamNameDoc = gql`
    mutation InsertTeamName($team_id: uuid!, $name: String!) {
  insert_teams_names_one(object: {team_id: $team_id, name: $name}) {
    team_id
    name
  }
}
    `;
export const DeleteTeamMemberDoc = gql`
    mutation DeleteTeamMember($team_id: uuid!, $user_id: String!) {
  delete_teams_users(
    where: {_and: {team_id: {_eq: $team_id}, user_id: {_eq: $user_id}}}
  ) {
    returning {
      team_id
    }
  }
}
    `;
export const UpdateTeamSocialMediaDoc = gql`
    mutation UpdateTeamSocialMedia($team_id: uuid!, $facebook: String, $twitter: String, $instagram: String, $youtube: String, $linkedin: String, $other: String) {
  update_social_medias(
    where: {teams: {id: {_eq: $team_id}}}
    _set: {youtube: $youtube, twitter: $twitter, other: $other, linkedin: $linkedin, instagram: $instagram, facebook: $facebook}
  ) {
    returning {
      facebook
      instagram
      linkedin
      other
      twitter
      youtube
    }
  }
}
    `;
export const InsertTagsToTeamDoc = gql`
    mutation InsertTagsToTeam($objects: [teams_tags_insert_input!]!) {
  insert_teams_tags(objects: $objects) {
    returning {
      tag_id
      team_id
    }
  }
}
    `;
export const DeleteTagsOfTeamDoc = gql`
    mutation DeleteTagsOfTeam($team_id: uuid!) {
  delete_teams_tags(where: {team_id: {_eq: $team_id}}) {
    affected_rows
  }
}
    `;
export const GetDiagnosticsTabsActiveByTeamDoc = gql`
    query GetDiagnosticsTabsActiveByTeam($team_id: uuid!) {
  diagnostics_tabs(
    order_by: {order: asc}
    where: {diagnostic: {groups: {_and: {active: {_eq: true}, group: {teams: {team_id: {_eq: $team_id}}}}}}}
  ) {
    id
    order
    title
  }
}
    `;
export const GetDiagnosticsSectionsByTabDoc = gql`
    query GetDiagnosticsSectionsByTab($tab_id: uuid!) {
  diagnostics_tabs_sections(
    order_by: {order: asc}
    where: {tab_id: {_eq: $tab_id}}
  ) {
    order
    section {
      id
      title
    }
  }
}
    `;
export const GetDiagnosticsSubsectionsBySectionAndTeamDoc = gql`
    query GetDiagnosticsSubsectionsBySectionAndTeam($tab_id: uuid!, $section_id: uuid!, $team_id: uuid!) {
  diagnostics_subsections(
    order_by: {order: asc}
    where: {section_id: {_eq: $section_id}}
  ) {
    id
    order
    title
    questions(order_by: {order: asc}) {
      id
      order
      question
      answers(where: {_and: {tab_id: {_eq: $tab_id}, team_id: {_eq: $team_id}}}) {
        id
        answer
      }
    }
  }
}
    `;
export const StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeamDoc = gql`
    mutation StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeam($objects: [diagnostics_questions_answers_insert_input!]!) {
  insert_diagnostics_questions_answers(
    objects: $objects
    on_conflict: {constraint: diagnostics_questions_answers_group_id_question_id_tab_id_key, update_columns: answer}
  ) {
    returning {
      id
      answer
    }
  }
}
    `;
export const GetDiagnosticsCommentByTabAndTeamDoc = gql`
    query GetDiagnosticsCommentByTabAndTeam($tab_id: uuid!, $team_id: uuid!) {
  diagnostics_comments_by_pk(diagnostic_tab_id: $tab_id, team_id: $team_id) {
    comment
  }
}
    `;
export const StoreDiagnosticsCommentByTabAndTeamDoc = gql`
    mutation StoreDiagnosticsCommentByTabAndTeam($comment: String!, $tab_id: uuid!, $team_id: uuid!) {
  insert_diagnostics_comments_one(
    object: {comment: $comment, diagnostic_tab_id: $tab_id, team_id: $team_id}
    on_conflict: {constraint: diagnostics_comments_pkey, update_columns: comment}
  ) {
    comment
  }
}
    `;
export const GetVideosByTeamDoc = gql`
    query GetVideosByTeam($team_id: uuid!, $limit: Int!, $offset: Int!, $order_by: [videos_order_by!], $contains_text: String!) {
  videos(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: {_and: {repository: {content: {groups: {_and: {active: {_eq: true}, group: {teams: {team_id: {_eq: $team_id}}}}}}}, _or: [{description: {_ilike: $contains_text}}, {title: {_ilike: $contains_text}}]}}
  ) {
    description
    title
    id
    link
    published_at
    tags {
      tag
    }
    thumbnail
  }
}
    `;
export const CountVideosByTagsByTeamDoc = gql`
    query CountVideosByTagsByTeam($tags: _text!, $team_id: uuid!, $contains_text: String!) {
  videos_by_tags_aggregate(
    args: {tags: $tags}
    where: {_and: {repository: {content: {groups: {_and: {active: {_eq: true}, group: {teams: {team_id: {_eq: $team_id}}}}}}}, _or: [{description: {_ilike: $contains_text}}, {title: {_ilike: $contains_text}}]}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetVideosByTagsByTeamDoc = gql`
    query GetVideosByTagsByTeam($tags: _text!, $limit: Int!, $offset: Int!, $order: order_by!, $contains_text: String!) {
  videos_by_tags(
    args: {tags: $tags}
    limit: $limit
    offset: $offset
    order_by: {published_at: $order}
    where: {_and: {_or: [{description: {_ilike: $contains_text}}, {title: {_ilike: $contains_text}}]}}
  ) {
    description
    title
    id
    link
    published_at
    tags {
      tag
    }
    thumbnail
  }
}
    `;
export const GetYoutubeTagsDoc = gql`
    query GetYoutubeTags {
  youtube_tags {
    tag
  }
}
    `;
export const GetRepositoriesActiveByTeamDoc = gql`
    query GetRepositoriesActiveByTeam($team_id: uuid!) {
  repositories(
    where: {content: {groups: {_and: {active: {_eq: true}, group: {teams: {team_id: {_eq: $team_id}}}}}}}
  ) {
    id
    link
    app_key
  }
}
    `;
export const StoreQuestionsAnswersByQuestionByTeamDoc = gql`
    mutation StoreQuestionsAnswersByQuestionByTeam($answers: [questions_answers_insert_input!]!) {
  insert_questions_answers(
    objects: $answers
    on_conflict: {constraint: questions_answers_question_id_team_id_key, update_columns: answer}
  ) {
    affected_rows
  }
}
    `;
export const StoreQuestionnaireTeamStateDoc = gql`
    mutation StoreQuestionnaireTeamState($questionnaire_id: uuid!, $team_id: uuid!, $finished: Boolean!) {
  insert_questionnaires_teams_states_one(
    object: {questionnaire_id: $questionnaire_id, team_id: $team_id, finished: $finished}
    on_conflict: {constraint: questionnaires_teams_states_pkey, update_columns: finished}
  ) {
    finished
  }
}
    `;
export const GetMentorsFeedbackByToolByTeamDoc = gql`
    query GetMentorsFeedbackByToolByTeam($team_id: uuid!, $tool_id: uuid!) {
  mentors_blablabla(
    where: {_and: {team_id: {_eq: $team_id}, tool_id: {_eq: $tool_id}}}
  ) {
    id
    value
  }
}
    `;
export const StoreMentorsFeedbackDoc = gql`
    mutation StoreMentorsFeedback($team_id: uuid!, $tool_id: uuid!, $value: String!) {
  insert_mentors_blablabla_one(
    object: {team_id: $team_id, tool_id: $tool_id, value: $value}
    on_conflict: {constraint: mentors_blablabla_team_id_tool_id_key, update_columns: value}
  ) {
    value
  }
}
    `;
export const GetBlobStorageSasUriDoc = gql`
    query GetBlobStorageSASUri($container: String!, $filename: String!) {
  getStorageSASUri(container: $container, filename: $filename)
}
    `;
export const InsertMentoringRecordDoc = gql`
    mutation InsertMentoringRecord($date: timestamptz!, $record: String!, $team_id: uuid!, $duration: smallint!, $scheduling_id: uuid = "") {
  insert_mentoring_records_one(
    object: {date: $date, record: $record, team_id: $team_id, duration: $duration, scheduling_id: $scheduling_id}
  ) {
    id
    scheduling_id
    date
    schedules {
      request_id
    }
  }
}
    `;
export const GetMentoringRecordDoc = gql`
    query GetMentoringRecord($id: uuid!) {
  mentoring_records_by_pk(id: $id) {
    id
    date
    duration
  }
}
    `;
export const UpdateMentoringRecordByIdDoc = gql`
    mutation UpdateMentoringRecordById($id: uuid!, $record: String!, $duration: smallint!) {
  update_mentoring_records_by_pk(
    pk_columns: {id: $id}
    _set: {record: $record, duration: $duration}
  ) {
    id
  }
}
    `;
export const DeleteMentoringRecordByIdDoc = gql`
    mutation DeleteMentoringRecordById($id: uuid!) {
  delete_mentoring_records_by_pk(id: $id) {
    id
  }
}
    `;
export const GetMentoringRecordsByTeamByMonthDoc = gql`
    query GetMentoringRecordsByTeamByMonth($team_id: uuid!, $first_day: timestamptz!, $last_day: timestamptz!) {
  mentoring_records(
    where: {_and: {team_id: {_eq: $team_id}, date: {_gte: $first_day, _lte: $last_day}}}
  ) {
    id
    duration
    date
    record
    members {
      member_id
      member {
        id
        names(order_by: {created_at: desc}, limit: 1) {
          name
        }
      }
    }
  }
}
    `;
export const InviteEmailToTeamDoc = gql`
    mutation InviteEmailToTeam($email: String!, $team_id: String!) {
  inviteUserToTeam(user_email: $email, team_id: $team_id) {
    invite_id
    team_id
    user_id
  }
}
    `;
export const GetInvitesOfTeamDoc = gql`
    query GetInvitesOfTeam($team_id: uuid!) {
  users: team_invites(
    where: {_and: {team_id: {_eq: $team_id}}, status: {_eq: pending}}
  ) {
    user {
      id
      names(order_by: {created_at: desc}, limit: 1) {
        name
      }
    }
  }
  emails: user_invites(
    where: {_and: {invited_by: {_eq: $team_id}}, consumed: {_is_null: true}}
  ) {
    email
  }
}
    `;
export const GetTeamsDoc = gql`
    query GetTeams {
  teams {
    id
    names {
      name
    }
  }
}
    `;
export const GetLocationsDoc = gql`
    query GetLocations {
  locations {
    id
    names {
      name
    }
  }
}
    `;
export const GetGroupsDoc = gql`
    query GetGroups {
  groups {
    names(order_by: {created_at: desc}) {
      name
    }
    id
  }
}
    `;
export const GetTeamsByGroupDoc = gql`
    query GetTeamsByGroup($groupId: uuid!) {
  teams(where: {groups: {group: {id: {_eq: $groupId}}}}, order_by: {name: asc}) {
    ...TeamCore
    name
  }
}
    ${TeamCoreFragmentDoc}`;
export const GroupsByUserDoc = gql`
    query GroupsByUser($user_id: String!) {
  users(where: {id: {_eq: $user_id}}) {
    teams {
      team {
        groups {
          group {
            id
            names(order_by: {created_at: desc}) {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export const GetInviteStateDoc = gql`
    query GetInviteState($email: String!, $token: String!) {
  checkInviteStatus(email: $email, token: $token) {
    id
    status
    role
    preregistered
  }
}
    `;
export const ContentWithProgressDoc = gql`
    query ContentWithProgress($team_id: uuid!) {
  contents(where: {groups: {group: {teams: {team: {id: {_eq: $team_id}}}}}}) {
    sections {
      section {
        id
        name
        slug
        section_icons_files {
          id
          type
          file {
            id
            path
          }
        }
        outputs(order_by: {order: asc}) {
          id
          name
          slug
          icons {
            id
            type
            file {
              id
              path
            }
          }
          tools(order_by: {order: asc}) {
            tool {
              id
              name
              slug
              intro
              explanation
              slug
              points
              icons {
                id
                type
                file {
                  id
                  path
                }
              }
              questionnaires {
                teams_states(where: {team_id: {_eq: $team_id}}) {
                  finished
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const GetTeamsNamesDoc = gql`
    query GetTeamsNames {
  teams_names {
    team_id
    name
  }
}
    `;
export const GetGroupNameByTeamIdDoc = gql`
    query GetGroupNameByTeamId($team_id: uuid!) {
  teams(where: {id: {_eq: $team_id}}) {
    groups {
      group {
        id
        names {
          name
        }
      }
    }
  }
}
    `;
export const GetGroupIdByUserIdDoc = gql`
    query GetGroupIdByUserId($user_id: String!) {
  users(where: {id: {_eq: $user_id}}) {
    teams {
      team {
        groups {
          group {
            id
          }
        }
      }
    }
  }
}
    `;
export const InviteNewUserTeamRoleDoc = gql`
    mutation inviteNewUserTeamRole($email: String!, $role: RolesEnum!, $team_id: String) {
  inviteNewUser(email: $email, role: $role, team_id: $team_id) {
    user_invite_id
    token
  }
}
    `;
export const GetTeamsByGroupIdDoc = gql`
    query GetTeamsByGroupId($group_id: uuid!) {
  groups(where: {id: {_eq: $group_id}}) {
    teams(where: {team: {disabled: {_eq: false}}}) {
      team {
        id
        names {
          name
        }
      }
    }
  }
}
    `;
export const GetUsersWithNamesDoc = gql`
    query GetUsersWithNames {
  users {
    id
    email
    cpf
    role
    names {
      name
    }
  }
}
    `;
export const UpdateUserRoleDoc = gql`
    mutation UpdateUserRole($new_role: RolesEnum!, $user_id: String!) {
  updateUserRole(new_role: $new_role, user_id: $user_id) {
    user_id
  }
}
    `;
export const GetMinutesAttachmentsByMentoringRecordIdDoc = gql`
    query GetMinutesAttachmentsByMentoringRecordId($mentoring_record_id: uuid!) {
  mentoring_records_attachments(
    where: {mentoring_record_id: {_eq: $mentoring_record_id}}
  ) {
    id
    attachment
    label
    created_at
  }
}
    `;
export const InsertMentoringRecordAttachmentDoc = gql`
    mutation InsertMentoringRecordAttachment($mentoring_record_id: uuid!, $attachment: String, $label: String) {
  insert_mentoring_records_attachments_one(
    object: {mentoring_record_id: $mentoring_record_id, attachment: $attachment, label: $label}
  ) {
    attachment
  }
}
    `;
export const DeleteMentoringRecordAttachmentsByMentoringRecordIdDoc = gql`
    mutation DeleteMentoringRecordAttachmentsByMentoringRecordId($mentoring_record_id: uuid!) {
  delete_mentoring_records_attachments(
    where: {mentoring_record_id: {_eq: $mentoring_record_id}}
  ) {
    returning {
      mentoring_record_id
    }
  }
}
    `;
export const InsertMentoringMemberByMentoringRecordIdDoc = gql`
    mutation InsertMentoringMemberByMentoringRecordId($objects: [mentoring_records_members_insert_input!]!) {
  insert_mentoring_records_members(objects: $objects) {
    returning {
      member_id
      mentoring_record {
        id
      }
    }
  }
}
    `;
export const GetMentoringMemberByMentoringRecordIdDoc = gql`
    query GetMentoringMemberByMentoringRecordId($mentoring_record_id: uuid!) {
  mentoring_records_members(
    where: {mentoring_record_id: {_eq: $mentoring_record_id}}
  ) {
    member_id
  }
}
    `;
export const DeleteMentoringRecordMemberDoc = gql`
    mutation DeleteMentoringRecordMember($mentoring_record_id: uuid!, $member_id: String!) {
  delete_mentoring_records_members_by_pk(
    member_id: $member_id
    mentoring_record_id: $mentoring_record_id
  ) {
    member_id
  }
}
    `;
export const InsertMentoringSchedulingDoc = gql`
    mutation InsertMentoringScheduling($date: timestamptz, $team_id: uuid!) {
  insert_mentoring_scheduling(
    objects: {completed: false, date: $date, team_id: $team_id}
  ) {
    returning {
      id
    }
  }
}
    `;
export const InsertMentoringSchedulingWithRequestIdDoc = gql`
    mutation InsertMentoringSchedulingWithRequestId($date: timestamptz, $team_id: uuid!, $request_id: uuid = "", $mentor_id: uuid = null) {
  insert_mentoring_scheduling(
    objects: {completed: false, date: $date, team_id: $team_id, request_id: $request_id, mentor_id: $mentor_id}
  ) {
    returning {
      id
      date
      mentor {
        email
        name
        additional_data {
          linkedin
        }
      }
      team {
        members {
          user {
            email
          }
        }
      }
      meeting {
        title
        contact
      }
    }
  }
}
    `;
export const CreateScheduleMeetingDoc = gql`
    mutation CreateScheduleMeeting($contact: String!, $title: String!) {
  insert_mentoring_scheduling_meeting(objects: {contact: $contact, title: $title}) {
    returning {
      id
    }
  }
}
    `;
export const InsertMeetingIdOnScheduleDoc = gql`
    mutation InsertMeetingIdOnSchedule($id: uuid!, $meeting_id: uuid!) {
  update_mentoring_scheduling(
    where: {id: {_eq: $id}}
    _set: {meeting_id: $meeting_id}
  ) {
    returning {
      id
    }
  }
}
    `;
export const InsertMentoringSchedulingForDatesDoc = gql`
    mutation InsertMentoringSchedulingForDates($objects: [mentoring_scheduling_insert_input!]!) {
  insert_mentoring_scheduling(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export const InsertMentoringSchedulingMembersDoc = gql`
    mutation InsertMentoringSchedulingMembers($objects: [mentoring_scheduling_members_insert_input!]!) {
  insert_mentoring_scheduling_members(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export const GetTeamUsersDoc = gql`
    query GetTeamUsers($team_id: uuid!) {
  teams_users(where: {team_id: {_eq: $team_id}}) {
    user_id
    user {
      names {
        name
      }
      profile_picture {
        path
      }
    }
  }
}
    `;
export const GetMentoringSchedulingByTeamIdDoc = gql`
    query GetMentoringSchedulingByTeamId($team_id: uuid!) {
  mentoring_scheduling(where: {team_id: {_eq: $team_id}}, order_by: {date: asc}) {
    id
    date
    request_id
    request {
      standard
    }
  }
}
    `;
export const GetMentoringSchedulingMembersByMentoringSchedulingIdDoc = gql`
    query GetMentoringSchedulingMembersByMentoringSchedulingId($mentoring_scheduling_id: uuid!) {
  mentoring_scheduling_members(
    where: {mentoring_scheduling_id: {_eq: $mentoring_scheduling_id}}
  ) {
    id
    member {
      id
      names {
        name
      }
      email
      profile_picture {
        path
      }
    }
  }
}
    `;
export const UpdateMentoringSchedulingByIdDoc = gql`
    mutation UpdateMentoringSchedulingById($id: uuid!, $completed: Boolean!, $date: timestamptz, $team_id: uuid!) {
  update_mentoring_scheduling(
    where: {id: {_eq: $id}}
    _set: {completed: $completed, date: $date, team_id: $team_id}
  ) {
    returning {
      id
    }
  }
}
    `;
export const UpdateMentoringSchedulingForRecordsByIdDoc = gql`
    mutation UpdateMentoringSchedulingForRecordsById($id: uuid!, $completed: Boolean!, $record_id: uuid = "") {
  update_mentoring_scheduling(
    where: {id: {_eq: $id}}
    _set: {completed: $completed, record_id: $record_id}
  ) {
    returning {
      id
      request_id
    }
  }
}
    `;
export const UpdateMentoringSchedulingMembersByMentoringSchedulingIdDoc = gql`
    mutation UpdateMentoringSchedulingMembersByMentoringSchedulingId($mentoring_scheduling_ids: [uuid!]!, $member_ids: [String!]!, $objects: [mentoring_scheduling_members_insert_input!]!) {
  delete_mentoring_scheduling_members(
    where: {mentoring_scheduling_id: {_in: $mentoring_scheduling_ids}, member_id: {_in: $member_ids}}
  ) {
    returning {
      id
    }
  }
  insert_mentoring_scheduling_members(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export const GetMentoringRecordsByTeamIdDoc = gql`
    query GetMentoringRecordsByTeamId($team_id: uuid!) {
  mentoring_records(
    order_by: {date: asc}
    where: {_and: {team_id: {_eq: $team_id}}}
  ) {
    id
    duration
    date
    record
    members {
      member_id
      member {
        id
        names(order_by: {created_at: desc}, limit: 1) {
          name
        }
      }
    }
    mentoring_records_attachments {
      id
      label
      attachment
    }
    schedules {
      request {
        description
      }
    }
  }
}
    `;
export const DeleteMentoringRecordAttachmentsByMentoringRecordAttachmentIdDoc = gql`
    mutation DeleteMentoringRecordAttachmentsByMentoringRecordAttachmentId($mentoring_record_attachment_id: uuid!) {
  delete_mentoring_records_attachments(
    where: {id: {_eq: $mentoring_record_attachment_id}}
  ) {
    returning {
      mentoring_record_id
    }
  }
}
    `;
export const GetAnswersByTeamIdDoc = gql`
    query GetAnswersByTeamId($team_id: uuid!) {
  questions_answers(where: {team_id: {_eq: $team_id}}) {
    id
  }
}
    `;
export const GetBlankAnswersByTeamIdDoc = gql`
    query GetBlankAnswersByTeamId($team_id: uuid!) {
  questions_answers(where: {team_id: {_eq: $team_id}, answer: {_eq: ""}}) {
    id
  }
}
    `;
export const SendManagerEmailDoc = gql`
    mutation SendManagerEmail($team_id: uuid = "", $description: String = "") {
  sendManagerEmail(description: $description, team_id: $team_id) {
    status_code
  }
}
    `;
export const SendUserEmailDoc = gql`
    mutation SendUserEmail($date: String = "", $status: String = "", $team_id: uuid = "", $time: String = "") {
  sendUserEmail(team_id: $team_id, status: $status, date: $date, time: $time) {
    status_code
  }
}
    `;
export const InsertRequestedMentoringsOneDoc = gql`
    mutation InsertRequestedMentoringsOne($cost: Int = 400, $team_id: uuid = "", $requested_date: timestamptz = "", $description: String = "", $standard: Boolean = false, $scheduling_id: uuid, $status: requested_mentorings_status_enum = pending) {
  insert_requested_mentorings_one(
    object: {team_id: $team_id, cost: $cost, status: $status, requested_date: $requested_date, description: $description, standard: $standard, scheduling_id: $scheduling_id}
  ) {
    id
    status
    requested_date
    team_id
    cost
    description
  }
}
    `;
export const InsertRequestedMentoringsNewGamificationOneDoc = gql`
    mutation InsertRequestedMentoringsNewGamificationOne($cost: Int = 400, $team_id: uuid = "", $description: String = "", $requested_date: timestamptz, $available_dates: [available_mentoring_dates_insert_input!] = {}, $tags: [requests_mentor_tags_insert_input!] = {}) {
  insert_requested_mentorings_one(
    object: {team_id: $team_id, cost: $cost, status: pending, requested_date: $requested_date, description: $description, available_dates: {data: $available_dates}, requests_mentor_tags: {data: $tags}}
  ) {
    id
    status
    requested_date
    team_id
    cost
    description
    standard
    created_at
    team {
      name
    }
    requests_mentor_tags {
      tag_name
    }
    available_dates {
      date
    }
  }
}
    `;
export const GetRequestedsMentoringsByTeamIdDoc = gql`
    query GetRequestedsMentoringsByTeamId($team_id: uuid = "") {
  requested_mentorings(
    where: {team_id: {_eq: $team_id}}
    order_by: {created_at: asc}
  ) {
    id
    cost
    status
    standard
    description
    scheduling_id
    requested_date
    mentoring_scheduling {
      mentor {
        name
        email
      }
      team {
        name
        members {
          user {
            email
          }
        }
      }
      meeting {
        title
        contact
      }
      reschedule {
        id
        new_date
      }
    }
    requests_mentor_tags {
      tag_name
    }
    available_dates {
      date
    }
    created_at
  }
}
    `;
export const UpdateRequestedMentoringByPkDoc = gql`
    mutation UpdateRequestedMentoringByPk($id: uuid! = "", $cost: Int, $requested_date: timestamptz = "", $status: requested_mentorings_status_enum = scheduled, $team_id: uuid, $scheduling_id: uuid = "") {
  update_requested_mentorings_by_pk(
    pk_columns: {id: $id}
    _set: {cost: $cost, status: $status, team_id: $team_id, requested_date: $requested_date, scheduling_id: $scheduling_id}
  ) {
    id
    cost
    scheduling_id
    requested_date
    status
  }
}
    `;
export const SetRequestedMentoringStatusByPkDoc = gql`
    mutation SetRequestedMentoringStatusByPk($id: uuid!, $status: requested_mentorings_status_enum = pending) {
  update_requested_mentorings_by_pk(
    pk_columns: {id: $id}
    _set: {status: $status}
  ) {
    id
    cost
    status
  }
}
    `;
export const UpdateRequestedMentoringForRecordsByPkDoc = gql`
    mutation UpdateRequestedMentoringForRecordsByPk($id: uuid! = "", $status: requested_mentorings_status_enum = done) {
  update_requested_mentorings_by_pk(
    pk_columns: {id: $id}
    _set: {status: $status}
  ) {
    id
  }
}
    `;
export const GetMoreAvailableRequestsDatesDoc = gql`
    subscription GetMoreAvailableRequestsDates($min: timestamptz!, $max: timestamptz!, $start: timestamp!) {
  requested_mentorings(
    where: {available_dates: {date: {_gt: $min}}, status: {_eq: pending}, created_at: {_gte: $start}}
  ) {
    available_dates(where: {date: {_gt: $min, _lte: $max}}) {
      date
    }
  }
}
    `;
export const GetAllAvailableRequestsDatesDoc = gql`
    query GetAllAvailableRequestsDates($min: timestamptz!, $max: timestamptz!) {
  requested_mentorings(
    where: {_and: {available_dates: {date: {_gt: $min}}, status: {_eq: pending}}}
  ) {
    available_dates(where: {date: {_gt: $min, _lte: $max}}) {
      date
    }
  }
}
    `;
export const GetDateRequestsDoc = gql`
    query GetDateRequests($date: timestamptz = "") {
  requested_mentorings(
    where: {available_dates: {date: {_eq: $date}}, _and: {status: {_eq: pending}}}
  ) {
    id
    cost
    description
    team {
      id
      name
    }
    requests_mentor_tags {
      tag_name
    }
    available_dates(where: {date: {_eq: $date}}) {
      date
    }
  }
}
    `;
export const GetSchedulingsV2Doc = gql`
    subscription GetSchedulingsV2($min: timestamptz!, $max: timestamptz!, $start: timestamptz!) {
  mentoring_scheduling(
    where: {date: {_gt: $min, _lte: $max}, created_at: {_gte: $start}}
    order_by: {created_at: desc}
  ) {
    id
    request_id
  }
}
    `;
export const GetMentorByCpfDoc = gql`
    query GetMentorByCpf($cpf: String = "") {
  mentors(where: {user: {cpf: {_eq: $cpf}}}) {
    id
    tags {
      tag_name
    }
  }
}
    `;
export const GetTeamsScoresByTeamIdDoc = gql`
    query GetTeamsScoresByTeamId($team_id: uuid = "") {
  teams_scores(where: {team_id: {_eq: $team_id}}) {
    available
    spent
    team_id
    total
  }
}
    `;
export const GetContentByTeamIdDoc = gql`
    query GetContentByTeamId($_eq: uuid) {
  contents(where: {groups: {group: {teams: {team_id: {_eq: $_eq}}}}}) {
    description
  }
}
    `;
export const GetActiveLocationsCalendarsDoc = gql`
    query GetActiveLocationsCalendars($team_id: uuid!) {
  default: locations(where: {groups: {teams: {team_id: {_eq: $team_id}}}}) {
    calendar_id
    names(order_by: {created_at: desc}) {
      name
    }
  }
  all: locations(
    where: {groups: {end_date: {_gte: "today"}, start_date: {_lte: "today"}}}
    order_by: {names_aggregate: {min: {name: asc}}}
  ) {
    calendar_id
    names(order_by: {created_at: desc}) {
      name
    }
  }
}
    `;
export const GetAvailableMentoringSchedulingByTeamIdDoc = gql`
    query GetAvailableMentoringSchedulingByTeamId($team_id: uuid) {
  mentoring_scheduling(where: {team_id: {_eq: $team_id}, completed: {_neq: true}}) {
    id
    date
    team_id
    completed
    request_id
  }
}
    `;
export const GetAvailableMentoringSchedulingByTeamIdAndDateDoc = gql`
    query GetAvailableMentoringSchedulingByTeamIdAndDate($team_id: uuid = "", $date: timestamptz = "") {
  requested_mentorings(
    where: {team_id: {_eq: $team_id}, _and: {requested_date: {_eq: $date}}}
  ) {
    id
    description
    cost
    requested_date
    scheduling_id
    status
    team_id
  }
}
    `;
export const GetTeamMethodologyByTeamIdDoc = gql`
    query GetTeamMethodologyByTeamId($team_id: uuid = "") {
  teams(where: {id: {_eq: $team_id}}) {
    groups {
      group {
        methodology
      }
    }
  }
}
    `;
export const GetGroupFlagsByTeamIdDoc = gql`
    query GetGroupFlagsByTeamId($team_id: uuid = "") {
  groups_group_flags(where: {group: {teams: {team_id: {_eq: $team_id}}}}) {
    flag
  }
}
    `;
export const InsertActionLoginDoc = gql`
    mutation InsertActionLogin($user_id: String!, $at_page_slug: String!, $team_id: uuid!) {
  insert_users_actions_one(
    object: {user_id: $user_id, team_id: $team_id, type: LOGIN, at_page_slug: $at_page_slug}
  ) {
    id
    type
    user_id
  }
}
    `;
export const InsertActionVisitDoc = gql`
    mutation InsertActionVisit($user_id: String!, $team_id: uuid!, $at_page_slug: String!, $previous_page_slug: String) {
  insert_users_actions_one(
    object: {user_id: $user_id, team_id: $team_id, type: VISIT, at_page_slug: $at_page_slug, previous_page_slug: $previous_page_slug}
  ) {
    id
    type
    user_id
  }
}
    `;
export const InsertActionInsertToolDoc = gql`
    mutation InsertActionInsertTool($user_id: String!, $team_id: uuid!, $at_page_slug: String!, $tool_id: uuid!) {
  insert_users_actions_one(
    object: {user_id: $user_id, team_id: $team_id, type: INSERT_TOOL, at_page_slug: $at_page_slug, tool_id: $tool_id}
  ) {
    id
    type
    user_id
  }
}
    `;
export const InsertActionUpdateToolDoc = gql`
    mutation InsertActionUpdateTool($user_id: String!, $team_id: uuid!, $at_page_slug: String!, $tool_id: uuid!) {
  insert_users_actions_one(
    object: {user_id: $user_id, team_id: $team_id, type: UPDATE_TOOL, at_page_slug: $at_page_slug, tool_id: $tool_id}
  ) {
    id
    type
    user_id
  }
}
    `;
export const InsertActionFinishToolDoc = gql`
    mutation InsertActionFinishTool($user_id: String!, $team_id: uuid!, $at_page_slug: String!, $tool_id: uuid!) {
  insert_users_actions_one(
    object: {user_id: $user_id, team_id: $team_id, type: FINISH_TOOL, at_page_slug: $at_page_slug, tool_id: $tool_id}
  ) {
    id
    type
    user_id
  }
}
    `;
export const InsertActionInsertMentoringDoc = gql`
    mutation InsertActionInsertMentoring($user_id: String!, $team_id: uuid!, $mentoring_id: uuid!) {
  insert_users_actions_one(
    object: {user_id: $user_id, team_id: $team_id, type: INSERT_MENTORING, at_page_slug: "/project", mentoring_id: $mentoring_id}
  ) {
    id
    type
    user_id
  }
}
    `;
export const InsertActionUpdateMentoringDoc = gql`
    mutation InsertActionUpdateMentoring($user_id: String!, $team_id: uuid!, $mentoring_id: uuid!) {
  insert_users_actions_one(
    object: {user_id: $user_id, team_id: $team_id, type: UPDATE_MENTORING, at_page_slug: "/project", mentoring_id: $mentoring_id}
  ) {
    id
    type
    user_id
  }
}
    `;
export const InsertActionDeleteMentoringDoc = gql`
    mutation InsertActionDeleteMentoring($user_id: String!, $team_id: uuid!, $mentoring_id: uuid!) {
  insert_users_actions_one(
    object: {user_id: $user_id, team_id: $team_id, type: DELETE_MENTORING, at_page_slug: "/project", mentoring_id: $mentoring_id}
  ) {
    id
    type
    user_id
  }
}
    `;
export const InsertActionUpdateProfileDoc = gql`
    mutation InsertActionUpdateProfile($user_id: String!, $team_id: uuid!) {
  insert_users_actions_one(
    object: {user_id: $user_id, team_id: $team_id, type: UPDATE_PROFILE, at_page_slug: "/profile"}
  ) {
    id
    type
    user_id
  }
}
    `;
export const UserActionsPerGroupDoc = gql`
    query UserActionsPerGroup($group_id: uuid!, $limit: Int = 20, $after: timestamptz = "1970-01-01T00:00:00.000Z", $before: timestamptz!, $offset: Int = 0, $types: [users_actions_types_enum!]!) {
  users_actions(
    where: {team: {groups: {group_id: {_eq: $group_id}}}, created_at: {_gt: $after, _lt: $before}, type: {_in: $types}}
    limit: $limit
    order_by: {created_at: desc}
    offset: $offset
  ) {
    created_at
    at_page_slug
    mentoring_id
    previous_page_slug
    tool_id
    type
    user_id
    team_id
    id
    __typename
  }
  users_actions_aggregate(
    where: {team: {groups: {group_id: {_eq: $group_id}}}, created_at: {_gt: $after, _lt: $before}, type: {_in: $types}}
    order_by: {created_at: desc}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const UserActionsPerGroupSubDoc = gql`
    subscription UserActionsPerGroupSub($group_id: uuid!, $limit: Int = 20, $after: timestamptz = "1970-01-01T00:00:00.000Z", $types: [users_actions_types_enum!]!) {
  users_actions(
    where: {team: {groups: {group_id: {_eq: $group_id}}}, created_at: {_gt: $after}, type: {_in: $types}}
    limit: $limit
    order_by: {created_at: desc}
  ) {
    created_at
    at_page_slug
    mentoring_id
    previous_page_slug
    tool_id
    type
    user_id
    team_id
    id
  }
}
    `;
export const UserActionsPerTeamDoc = gql`
    query UserActionsPerTeam($team_id: uuid!, $limit: Int = 20, $after: timestamptz = "1970-01-01T00:00:00.000Z", $before: timestamptz!, $offset: Int = 0, $types: [users_actions_types_enum!]!) {
  users_actions(
    where: {team_id: {_eq: $team_id}, created_at: {_gt: $after, _lt: $before}, type: {_in: $types}}
    limit: $limit
    order_by: {created_at: desc}
    offset: $offset
  ) {
    created_at
    at_page_slug
    mentoring_id
    previous_page_slug
    tool_id
    type
    user_id
    team_id
    id
    __typename
  }
  users_actions_aggregate(
    where: {team_id: {_eq: $team_id}, created_at: {_gt: $after, _lt: $before}, type: {_in: $types}}
    order_by: {created_at: desc}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const UserActionsPerTeamSubDoc = gql`
    subscription UserActionsPerTeamSub($team_id: uuid!, $limit: Int = 20, $after: timestamptz = "1970-01-01T00:00:00.000Z", $types: [users_actions_types_enum!]!) {
  users_actions(
    where: {team_id: {_eq: $team_id}, created_at: {_gt: $after}, type: {_in: $types}}
    limit: $limit
    order_by: {created_at: desc}
  ) {
    created_at
    at_page_slug
    mentoring_id
    previous_page_slug
    tool_id
    type
    user_id
    team_id
    id
    __typename
  }
}
    `;
export const InsertGroupsDoc = gql`
    mutation InsertGroups($group_name: String = "", $end_date: date = "", $start_date: date = "", $location_id: uuid = "", $email: String = "", $methodology: metodologies_enum = DEFAULT, $content_id: uuid = "", $url: String = "", $diagnostic_id: uuid = "", $flags: groups_group_flags_arr_rel_insert_input) {
  insert_groups(
    objects: {names: {data: {name: $group_name}}, end_date: $end_date, start_date: $start_date, location_id: $location_id, methodology: $methodology, email: $email, url: $url, contents: {data: {content_id: $content_id, active: true}}, diagnostics: {data: {diagnostic_id: $diagnostic_id, active: true}}, flags: $flags}
  ) {
    returning {
      id
    }
  }
}
    `;
export const InsertTeamUserDoc = gql`
    mutation InsertTeamUser($team_id: uuid = "", $user_id: String = "") {
  insert_teams_users(objects: {team_id: $team_id, user_id: $user_id}) {
    returning {
      team {
        name
      }
      user_id
    }
  }
}
    `;
export const InsertTeamDoc = gql`
    mutation InsertTeam($description: String = "", $group_id: uuid = "", $name: String = "", $cnpj: String = "") {
  insert_teams(
    objects: {groups: {data: {group_id: $group_id}}, names: {data: {name: $name}}, cnpj: $cnpj, description: $description, social_media: {data: {}}}
  ) {
    returning {
      id
      name
    }
  }
}
    `;
export const GetContentsDoc = gql`
    query GetContents {
  contents {
    id
    description
  }
}
    `;
export const GetDiagnosticsDoc = gql`
    query GetDiagnostics {
  diagnostics {
    id
    description
  }
}
    `;
export const InsertLocationWithGroupDoc = gql`
    mutation InsertLocationWithGroup($location_name: String = "", $description: String = "", $calendar_id: String = "", $country: String = "", $state: String = "", $city: String = "", $address: String = "", $group_name: String = "", $start_date: date = "", $end_date: date = "", $email: String = "", $url: String = "", $methodology: metodologies_enum = DEFAULT, $flags: groups_group_flags_arr_rel_insert_input, $content_id: uuid = "", $diagnostic_id: uuid = "") {
  insert_locations(
    objects: {names: {data: {name: $location_name}}, description: $description, calendar_id: $calendar_id, country: $country, city: $city, state: $state, address: $address, groups: {data: {names: {data: {name: $group_name}}, start_date: $start_date, end_date: $end_date, email: $email, url: $url, methodology: $methodology, contents: {data: {content_id: $content_id, active: true}}, diagnostics: {data: {diagnostic_id: $diagnostic_id, active: true}}, flags: $flags}}}
  ) {
    returning {
      id
    }
  }
}
    `;
export const GetVideosByTeamAllContentsDoc = gql`
    query GetVideosByTeamAllContents($limit: Int!, $offset: Int!, $order: order_by!) {
  videos(limit: $limit, offset: $offset, order_by: {published_at: $order}) {
    description
    title
    id
    link
    published_at
    tags {
      tag
    }
    thumbnail
  }
}
    `;
export const GetUserByCpfDoc = gql`
    query GetUserByCpf($cpf: String = "") {
  users(where: {cpf: {_eq: $cpf}}) {
    id
  }
}
    `;
export const GetMentoringSchedulingLteByTeamIdDoc = gql`
    query GetMentoringSchedulingLteByTeamId($team_id: uuid!) {
  mentoring_scheduling(
    where: {team_id: {_eq: $team_id}, date: {_lte: "now()"}, _and: {completed: {_eq: false}}}
    order_by: {date: asc}
  ) {
    id
    date
    request {
      description
    }
  }
}
    `;
export const GetUserByIdentifierDoc = gql`
    query GetUserByIdentifier($identifier: String = "") {
  users(
    where: {_or: [{_and: [{cpf: {_ilike: $identifier}}, {_not: {cpf: {_eq: ""}}}]}, {_and: [{email: {_ilike: $identifier}}, {_not: {email: {_eq: ""}}}]}, {_and: [{names: {name: {_ilike: $identifier}}}]}]}
  ) {
    names(order_by: {created_at: desc}) {
      name
    }
    email
    cpf
    profile_picture {
      path
    }
    teams {
      team {
        name
        groups {
          group {
            names {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export const CountAllToolsDoneByTeamIdDoc = gql`
    query CountAllToolsDoneByTeamId($team_id: uuid!) {
  questionnaires_teams_states(
    where: {_and: {finished: {_eq: true}, team_id: {_eq: $team_id}}}
  ) {
    questionnaire {
      tool_id
    }
    finished
  }
}
    `;
export const GetToolsByTeamIdDoc = gql`
    query GetToolsByTeamId($team_id: uuid!) {
  teams(where: {id: {_eq: $team_id}}) {
    groups {
      group {
        contents {
          content {
            sections {
              section {
                outputs {
                  tools {
                    id
                    tool {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const GetMentorTagsDoc = gql`
    query GetMentorTags {
  mentor_tags {
    name
  }
}
    `;
export const CancellRequestDoc = gql`
    mutation CancellRequest($id: uuid = "", $description: String = "") {
  update_requested_mentorings(
    where: {id: {_eq: $id}}
    _set: {cost: 0, status: cancelled, description: $description}
  ) {
    affected_rows
    returning {
      id
    }
  }
  update_mentoring_scheduling(
    where: {request_id: {_eq: $id}}
    _set: {completed: true}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export const InsertMentoringRescheduleDoc = gql`
    mutation InsertMentoringReschedule($new_date: timestamp = "", $old_date: timestamp = "", $schedule_id: uuid = "", $description: String!) {
  insert_mentoring_reschedules(
    objects: {schedule_id: $schedule_id, new_date: $new_date, old_date: $old_date, description: $description}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export const InsertRescheduleIntoScheduleDoc = gql`
    mutation InsertRescheduleIntoSchedule($id: uuid!, $reschedule_id: uuid = "") {
  update_mentoring_scheduling_by_pk(
    pk_columns: {id: $id}
    _set: {reschedule_id: $reschedule_id}
  ) {
    reschedule {
      new_date
      old_date
    }
  }
}
    `;
export const SendEmailDoc = gql`
    mutation SendEmail($to: [String!]!, $message: String!, $subject: String!) {
  sendEmail(to: $to, subject: $subject, message: $message) {
    status_code
  }
}
    `;
export const GetMembersOfTeamEmailDoc = gql`
    query GetMembersOfTeamEmail($team_id: uuid!) {
  teams(where: {id: {_eq: $team_id}}) {
    members {
      user {
        email
      }
    }
  }
}
    `;
export const GetManagerEmailByTeamIdDoc = gql`
    query GetManagerEmailByTeamId($team_id: uuid!) {
  teams(where: {id: {_eq: $team_id}}) {
    groups {
      group {
        email
      }
    }
  }
}
    `;
export const UpdateTeamDisableDoc = gql`
    mutation UpdateTeamDisable($disabled: Boolean!, $id: uuid = "") {
  update_teams_by_pk(pk_columns: {id: $id}, _set: {disabled: $disabled}) {
    id
    disabled
  }
}
    `;
export const UpdateRequestV2ManagerDoc = gql`
    mutation UpdateRequestV2Manager($requested_date: timestamptz!, $request_id: uuid!) {
  update_requested_mentorings_by_pk(
    pk_columns: {id: $request_id}
    _set: {requested_date: $requested_date}
  ) {
    id
  }
}
    `;
export const InsertMentorDoc = gql`
    mutation InsertMentor($cpf: String!, $email: String!, $name: String!, $tags: [mentors_mentor_tags_insert_input!] = []) {
  insert_mentors_one(
    object: {name: $name, email: $email, cpf: $cpf, tags: {data: $tags}}
  ) {
    id
  }
}
    `;
export const TeamAcceptMentoringDoc = gql`
    mutation TeamAcceptMentoring($id: uuid!, $accept_mentoring: Boolean!) {
  update_teams_by_pk(
    pk_columns: {id: $id}
    _set: {accept_mentoring: $accept_mentoring}
  ) {
    accept_mentoring
  }
}
    `;
export const GetTeamAcceptDoc = gql`
    query GetTeamAccept($id: uuid!) {
  teams_by_pk(id: $id) {
    accept_mentoring
  }
}
    `;
export const GetGroupsFlagsByIdDoc = gql`
    query GetGroupsFlagsById($group_id: uuid!) {
  groups_group_flags(where: {group_id: {_eq: $group_id}}) {
    flag
  }
}
    `;
export const CreateGroupFlagDoc = gql`
    mutation CreateGroupFlag($flag: groups_flags_enum!, $group_id: uuid!) {
  insert_groups_group_flags(objects: {group_id: $group_id, flag: $flag}) {
    returning {
      flag
    }
  }
}
    `;
export const DeleteGroupFlagDoc = gql`
    mutation DeleteGroupFlag($flag: groups_flags_enum!, $group_id: uuid!) {
  delete_groups_group_flags(
    where: {group_id: {_eq: $group_id}, flag: {_eq: $flag}}
  ) {
    returning {
      flag
    }
  }
}
    `;
export const GetMentorEmailByTagDoc = gql`
    query GetMentorEmailByTag($tag: String!) {
  mentors(where: {tags: {tag_name: {_eq: $tag}}}) {
    email
  }
}
    `;
export const GetGroupNameByIdDoc = gql`
    query GetGroupNameById($group_id: uuid!) {
  groups_by_pk(id: $group_id) {
    names {
      name
    }
  }
}
    `;
export const DeleteDeleteMentorTagsDoc = gql`
    mutation DeleteDeleteMentorTags($mentor_id: uuid!) {
  delete_mentors_mentor_tags(where: {mentor_id: {_eq: $mentor_id}}) {
    affected_rows
  }
}
    `;
export const InsertMentorTagsDoc = gql`
    mutation InsertMentorTags($objects: [mentors_mentor_tags_insert_input!]! = {}) {
  insert_mentors_mentor_tags(objects: $objects) {
    affected_rows
  }
}
    `;
export const UpdateTermDoc = gql`
    mutation UpdateTerm($id: String!, $email: String!, $last_term_accepted: timestamptz!) {
  update_users_by_pk(
    pk_columns: {id: $id, email: $email}
    _set: {last_term_accepted: $last_term_accepted}
  ) {
    last_term_accepted
  }
}
    `;
export const GetContentBaseInfoDoc = gql`
    query GetContentBaseInfo($id: uuid!) {
  contents_by_pk(id: $id) {
    description
    sections {
      section {
        id
        name
        slug
        hex_color
        intro
        outputs_text
        order
        section_icons_files {
          type
          file {
            path
          }
        }
        outputs {
          id
          name
          slug
          intro
          order
          icons {
            type
            file {
              path
            }
          }
          multimedia_content {
            ebooks_link
            resources
            video_lessons {
              link
            }
          }
          tools {
            tool {
              id
              name
              icons {
                type
                file {
                  path
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const GetAllContentByIdDoc = gql`
    query GetAllContentById($id: uuid!) {
  contents_by_pk(id: $id) {
    description
    repositories {
      link
      app_key
    }
    sections {
      section {
        name
        slug
        intro
        hex_color
        outputs_text
        order
        section_icons_files {
          type
          file {
            path
          }
        }
        outputs {
          name
          slug
          intro
          order
          icons {
            type
            file {
              path
            }
          }
          multimedia_content {
            ebooks_link
            resources
            video_lessons {
              link
            }
          }
          tools {
            order
            tool {
              name
              slug
              points
              intro
              explanation
              icons {
                type
                file {
                  path
                }
              }
              questionnaires {
                active
                questions {
                  order
                  title
                  subtitle
                  type
                }
              }
              multimedia_content {
                ebooks_link
                resources
                video_lessons {
                  link
                }
              }
            }
          }
        }
      }
    }
    video_repositories {
      youtube_channel_id
      videos {
        link
        title
        description
        thumbnail
        published_at
      }
    }
  }
}
    `;
export const InsertContentDoc = gql`
    mutation InsertContent($object: contents_insert_input!) {
  insert_contents_one(object: $object, on_conflict: {constraint: contents_pkey}) {
    id
  }
}
    `;
export const GetOutputByIdDoc = gql`
    query GetOutputById($id: uuid!) {
  outputs_by_pk(id: $id) {
    slug
    name
    intro
    order
    icons {
      file {
        id
        path
      }
    }
    multimedia_content {
      ebooks_link
      resources
      video_lessons {
        link
      }
    }
    section {
      id
      slug
    }
    tools {
      order
      tool {
        name
        icons {
          file {
            id
            path
          }
        }
      }
    }
  }
}
    `;
export const GetSectionBasicsByIdDoc = gql`
    query GetSectionBasicsById($id: uuid!) {
  sections_by_pk(id: $id) {
    ...SectionCore
  }
}
    ${SectionCoreFragmentDoc}`;
export const GetOutputQuantityDoc = gql`
    query GetOutputQuantity($section_id: uuid!) {
  outputs_aggregate(where: {parent_section_id: {_eq: $section_id}}) {
    aggregate {
      count
    }
  }
}
    `;
export const UploadFileDoc = gql`
    mutation UploadFile($base64str: String!, $container: String!, $filename: String!) {
  uploadFile(filename: $filename, container: $container, base64str: $base64str) {
    file_id
    file_path
  }
}
    `;
export const InsertOutputDoc = gql`
    mutation InsertOutput($slug: String!, $parent_section_id: uuid!, $order: smallint!, $name: String!, $videos: [multimedia_contents_links_insert_input!]!, $resources: String!, $ebooks_link: String!, $intro: String!, $iconId: uuid!) {
  insert_outputs_one(
    object: {name: $name, intro: $intro, order: $order, parent_section_id: $parent_section_id, slug: $slug, multimedia_content: {data: {ebooks_link: $ebooks_link, resources: $resources, video_lessons: {data: $videos}}}, icons: {data: {file_id: $iconId, type: small}}}
  ) {
    id
  }
}
    `;
export const EditOutputDoc = gql`
    mutation EditOutput($id: uuid!, $slug: String!, $parent_section_id: uuid!, $order: smallint!, $name: String!, $intro: String!) {
  update_outputs_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, intro: $intro, order: $order, parent_section_id: $parent_section_id, slug: $slug}
  ) {
    id
    multimedia_content_id
    icons {
      file_id
    }
  }
}
    `;
export const EditMultimediaContentDoc = gql`
    mutation EditMultimediaContent($id: uuid!, $resources: String, $ebooks_link: String) {
  update_multimedia_contents_by_pk(
    pk_columns: {id: $id}
    _set: {ebooks_link: $ebooks_link, resources: $resources}
  ) {
    video_lessons {
      id
    }
  }
}
    `;
export const DeleteMultimediaLinksDoc = gql`
    mutation DeleteMultimediaLinks($ids: [multimedia_contents_links_bool_exp!]!) {
  delete_multimedia_contents_links(where: {_or: $ids}) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export const InsertMultimediaLinksDoc = gql`
    mutation InsertMultimediaLinks($links: [multimedia_contents_links_insert_input!]!) {
  insert_multimedia_contents_links(objects: $links) {
    affected_rows
    returning {
      link
    }
  }
}
    `;
export const UpdateFileByPkDoc = gql`
    mutation UpdateFileByPk($id: uuid!, $path: String!) {
  update_files_by_pk(pk_columns: {id: $id}, _set: {path: $path}) {
    path
  }
}
    `;
export const GetToolQuantityDoc = gql`
    query GetToolQuantity($outputId: uuid!) {
  tools_aggregate(where: {outputs: {output_id: {_eq: $outputId}}}) {
    aggregate {
      count
    }
  }
}
    `;
export const InsertToolDoc = gql`
    mutation InsertTool($order: smallint!, $output_id: uuid!, $video_lessons: [multimedia_contents_links_insert_input!]!, $name: String!, $points: smallint!, $slug: String!, $explanation: String!, $intro: String!, $ebooks_link: String!, $resources: String!, $file_id: uuid!, $questions: [questions_insert_input!]!) {
  insert_outputs_tools_one(
    object: {order: $order, output_id: $output_id, tool: {data: {intro: $intro, explanation: $explanation, name: $name, points: $points, slug: $slug, icons: {data: {file_id: $file_id, type: big}}, multimedia_content: {data: {ebooks_link: $ebooks_link, resources: $resources, video_lessons: {data: $video_lessons}}}, questionnaires: {data: {active: true, questions: {data: $questions}}}}}}
  ) {
    id
  }
}
    `;
export const UpdateToolByPkDoc = gql`
    mutation UpdateToolByPk($id: uuid!, $name: String!, $points: smallint!, $slug: String!, $explanation: String!, $intro: String!) {
  update_tools_by_pk(
    pk_columns: {id: $id}
    _set: {explanation: $explanation, name: $name, points: $points, slug: $slug, intro: $intro}
  ) {
    id
    multimedia_content_id
    icons {
      file_id
    }
  }
}
    `;
export const DisableToolQuestionnairesDoc = gql`
    mutation DisableToolQuestionnaires($tool_id: uuid!) {
  update_questionnaires(
    where: {_and: [{tool_id: {_eq: $tool_id}}, {active: {_eq: true}}]}
    _set: {active: false}
  ) {
    returning {
      id
    }
  }
}
    `;
export const InsertQuestionnaireDoc = gql`
    mutation InsertQuestionnaire($tool_id: uuid!, $questions: [questions_insert_input!]!) {
  insert_questionnaires(
    objects: {tool_id: $tool_id, active: true, questions: {data: $questions}}
  ) {
    returning {
      id
    }
  }
}
    `;
export const GetContentDescriptionByPkDoc = gql`
    query GetContentDescriptionByPK($id: uuid!) {
  contents_by_pk(id: $id) {
    description
  }
}
    `;
export const UpdateContentDescriptionDoc = gql`
    mutation UpdateContentDescription($id: uuid!, $description: String!) {
  update_contents_by_pk(pk_columns: {id: $id}, _set: {description: $description}) {
    id
  }
}
    `;
export const DeleteOutputToolsDoc = gql`
    mutation DeleteOutputTools($tool_id: uuid!) {
  delete_outputs_tools(where: {tool_id: {_eq: $tool_id}}) {
    returning {
      id
    }
  }
}
    `;
export const GetSectionsBasic = (
            options: Omit<
              WatchQueryOptions<GetSectionsBasicQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetSectionsBasicQuery> & {
              query: ObservableQuery<
                GetSectionsBasicQuery,
                GetSectionsBasicQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetSectionsBasicDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetSectionsBasicQuery> & {
                query: ObservableQuery<
                  GetSectionsBasicQuery,
                  GetSectionsBasicQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const SectionsBasicByGroup = (
            options: Omit<
              WatchQueryOptions<SectionsBasicByGroupQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<SectionsBasicByGroupQuery> & {
              query: ObservableQuery<
                SectionsBasicByGroupQuery,
                SectionsBasicByGroupQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: SectionsBasicByGroupDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<SectionsBasicByGroupQuery> & {
                query: ObservableQuery<
                  SectionsBasicByGroupQuery,
                  SectionsBasicByGroupQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetSectionsCore = (
            options: Omit<
              WatchQueryOptions<GetSectionsCoreQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetSectionsCoreQuery> & {
              query: ObservableQuery<
                GetSectionsCoreQuery,
                GetSectionsCoreQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetSectionsCoreDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetSectionsCoreQuery> & {
                query: ObservableQuery<
                  GetSectionsCoreQuery,
                  GetSectionsCoreQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetOutputsCoresBySection = (
            options: Omit<
              WatchQueryOptions<GetOutputsCoresBySectionQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetOutputsCoresBySectionQuery> & {
              query: ObservableQuery<
                GetOutputsCoresBySectionQuery,
                GetOutputsCoresBySectionQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetOutputsCoresBySectionDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetOutputsCoresBySectionQuery> & {
                query: ObservableQuery<
                  GetOutputsCoresBySectionQuery,
                  GetOutputsCoresBySectionQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetOutputCoreBySlug = (
            options: Omit<
              WatchQueryOptions<GetOutputCoreBySlugQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetOutputCoreBySlugQuery> & {
              query: ObservableQuery<
                GetOutputCoreBySlugQuery,
                GetOutputCoreBySlugQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetOutputCoreBySlugDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetOutputCoreBySlugQuery> & {
                query: ObservableQuery<
                  GetOutputCoreBySlugQuery,
                  GetOutputCoreBySlugQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetOutputCoreMultimediaBySlug = (
            options: Omit<
              WatchQueryOptions<GetOutputCoreMultimediaBySlugQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetOutputCoreMultimediaBySlugQuery> & {
              query: ObservableQuery<
                GetOutputCoreMultimediaBySlugQuery,
                GetOutputCoreMultimediaBySlugQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetOutputCoreMultimediaBySlugDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetOutputCoreMultimediaBySlugQuery> & {
                query: ObservableQuery<
                  GetOutputCoreMultimediaBySlugQuery,
                  GetOutputCoreMultimediaBySlugQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetToolsCoreByOutput = (
            options: Omit<
              WatchQueryOptions<GetToolsCoreByOutputQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetToolsCoreByOutputQuery> & {
              query: ObservableQuery<
                GetToolsCoreByOutputQuery,
                GetToolsCoreByOutputQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetToolsCoreByOutputDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetToolsCoreByOutputQuery> & {
                query: ObservableQuery<
                  GetToolsCoreByOutputQuery,
                  GetToolsCoreByOutputQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetToolCoreMultimediaBySlug = (
            options: Omit<
              WatchQueryOptions<GetToolCoreMultimediaBySlugQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetToolCoreMultimediaBySlugQuery> & {
              query: ObservableQuery<
                GetToolCoreMultimediaBySlugQuery,
                GetToolCoreMultimediaBySlugQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetToolCoreMultimediaBySlugDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetToolCoreMultimediaBySlugQuery> & {
                query: ObservableQuery<
                  GetToolCoreMultimediaBySlugQuery,
                  GetToolCoreMultimediaBySlugQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetToolCoreById = (
            options: Omit<
              WatchQueryOptions<GetToolCoreByIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetToolCoreByIdQuery> & {
              query: ObservableQuery<
                GetToolCoreByIdQuery,
                GetToolCoreByIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetToolCoreByIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetToolCoreByIdQuery> & {
                query: ObservableQuery<
                  GetToolCoreByIdQuery,
                  GetToolCoreByIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetAllToolInfoById = (
            options: Omit<
              WatchQueryOptions<GetAllToolInfoByIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetAllToolInfoByIdQuery> & {
              query: ObservableQuery<
                GetAllToolInfoByIdQuery,
                GetAllToolInfoByIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetAllToolInfoByIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetAllToolInfoByIdQuery> & {
                query: ObservableQuery<
                  GetAllToolInfoByIdQuery,
                  GetAllToolInfoByIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const QuestionnaireActiveOfTool = (
            options: Omit<
              WatchQueryOptions<QuestionnaireActiveOfToolQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<QuestionnaireActiveOfToolQuery> & {
              query: ObservableQuery<
                QuestionnaireActiveOfToolQuery,
                QuestionnaireActiveOfToolQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: QuestionnaireActiveOfToolDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<QuestionnaireActiveOfToolQuery> & {
                query: ObservableQuery<
                  QuestionnaireActiveOfToolQuery,
                  QuestionnaireActiveOfToolQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const SignUpUser = (
            options: Omit<
              MutationOptions<any, SignUpUserMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<SignUpUserMutation, SignUpUserMutationVariables>({
              mutation: SignUpUserDoc,
              ...options,
            });
            return m;
          }
export const SignUpUserPreregistered = (
            options: Omit<
              MutationOptions<any, SignUpUserPreregisteredMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<SignUpUserPreregisteredMutation, SignUpUserPreregisteredMutationVariables>({
              mutation: SignUpUserPreregisteredDoc,
              ...options,
            });
            return m;
          }
export const SignUpSubscription = (
            options: Omit<SubscriptionOptions<SignUpSubscriptionSubscriptionVariables>, "query">
          ) => {
            const q = client.subscribe<SignUpSubscriptionSubscription, SignUpSubscriptionSubscriptionVariables>(
              {
                query: SignUpSubscriptionDoc,
                ...options,
              }
            )
            return q;
          }
export const SignUpPreregisteredSub = (
            options: Omit<SubscriptionOptions<SignUpPreregisteredSubSubscriptionVariables>, "query">
          ) => {
            const q = client.subscribe<SignUpPreregisteredSubSubscription, SignUpPreregisteredSubSubscriptionVariables>(
              {
                query: SignUpPreregisteredSubDoc,
                ...options,
              }
            )
            return q;
          }
export const GetUserCore = (
            options: Omit<
              WatchQueryOptions<GetUserCoreQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetUserCoreQuery> & {
              query: ObservableQuery<
                GetUserCoreQuery,
                GetUserCoreQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetUserCoreDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetUserCoreQuery> & {
                query: ObservableQuery<
                  GetUserCoreQuery,
                  GetUserCoreQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTeamCoreById = (
            options: Omit<
              WatchQueryOptions<GetTeamCoreByIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamCoreByIdQuery> & {
              query: ObservableQuery<
                GetTeamCoreByIdQuery,
                GetTeamCoreByIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamCoreByIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamCoreByIdQuery> & {
                query: ObservableQuery<
                  GetTeamCoreByIdQuery,
                  GetTeamCoreByIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTeamsOfUser = (
            options: Omit<
              WatchQueryOptions<GetTeamsOfUserQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamsOfUserQuery> & {
              query: ObservableQuery<
                GetTeamsOfUserQuery,
                GetTeamsOfUserQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamsOfUserDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamsOfUserQuery> & {
                query: ObservableQuery<
                  GetTeamsOfUserQuery,
                  GetTeamsOfUserQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetGroupsOfTeam = (
            options: Omit<
              WatchQueryOptions<GetGroupsOfTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetGroupsOfTeamQuery> & {
              query: ObservableQuery<
                GetGroupsOfTeamQuery,
                GetGroupsOfTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetGroupsOfTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetGroupsOfTeamQuery> & {
                query: ObservableQuery<
                  GetGroupsOfTeamQuery,
                  GetGroupsOfTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTeamCore = (
            options: Omit<
              WatchQueryOptions<GetTeamCoreQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamCoreQuery> & {
              query: ObservableQuery<
                GetTeamCoreQuery,
                GetTeamCoreQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamCoreDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamCoreQuery> & {
                query: ObservableQuery<
                  GetTeamCoreQuery,
                  GetTeamCoreQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const RespondTeamInvitation = (
            options: Omit<
              MutationOptions<any, RespondTeamInvitationMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<RespondTeamInvitationMutation, RespondTeamInvitationMutationVariables>({
              mutation: RespondTeamInvitationDoc,
              ...options,
            });
            return m;
          }
export const GetTeamScore = (
            options: Omit<
              WatchQueryOptions<GetTeamScoreQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamScoreQuery> & {
              query: ObservableQuery<
                GetTeamScoreQuery,
                GetTeamScoreQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamScoreDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamScoreQuery> & {
                query: ObservableQuery<
                  GetTeamScoreQuery,
                  GetTeamScoreQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTeamsScoresByGroup = (
            options: Omit<
              WatchQueryOptions<GetTeamsScoresByGroupQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamsScoresByGroupQuery> & {
              query: ObservableQuery<
                GetTeamsScoresByGroupQuery,
                GetTeamsScoresByGroupQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamsScoresByGroupDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamsScoresByGroupQuery> & {
                query: ObservableQuery<
                  GetTeamsScoresByGroupQuery,
                  GetTeamsScoresByGroupQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const CountToolsDoneOfSectionByTeam = (
            options: Omit<
              WatchQueryOptions<CountToolsDoneOfSectionByTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<CountToolsDoneOfSectionByTeamQuery> & {
              query: ObservableQuery<
                CountToolsDoneOfSectionByTeamQuery,
                CountToolsDoneOfSectionByTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: CountToolsDoneOfSectionByTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<CountToolsDoneOfSectionByTeamQuery> & {
                query: ObservableQuery<
                  CountToolsDoneOfSectionByTeamQuery,
                  CountToolsDoneOfSectionByTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const CountToolsDoneOfSectionByTeamGetOutputsNodes = (
            options: Omit<
              WatchQueryOptions<CountToolsDoneOfSectionByTeamGetOutputsNodesQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<CountToolsDoneOfSectionByTeamGetOutputsNodesQuery> & {
              query: ObservableQuery<
                CountToolsDoneOfSectionByTeamGetOutputsNodesQuery,
                CountToolsDoneOfSectionByTeamGetOutputsNodesQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: CountToolsDoneOfSectionByTeamGetOutputsNodesDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<CountToolsDoneOfSectionByTeamGetOutputsNodesQuery> & {
                query: ObservableQuery<
                  CountToolsDoneOfSectionByTeamGetOutputsNodesQuery,
                  CountToolsDoneOfSectionByTeamGetOutputsNodesQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetOutputsDoneBySection = (
            options: Omit<
              WatchQueryOptions<GetOutputsDoneBySectionQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetOutputsDoneBySectionQuery> & {
              query: ObservableQuery<
                GetOutputsDoneBySectionQuery,
                GetOutputsDoneBySectionQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetOutputsDoneBySectionDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetOutputsDoneBySectionQuery> & {
                query: ObservableQuery<
                  GetOutputsDoneBySectionQuery,
                  GetOutputsDoneBySectionQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetLocationsCalendars = (
            options: Omit<
              WatchQueryOptions<GetLocationsCalendarsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetLocationsCalendarsQuery> & {
              query: ObservableQuery<
                GetLocationsCalendarsQuery,
                GetLocationsCalendarsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetLocationsCalendarsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetLocationsCalendarsQuery> & {
                query: ObservableQuery<
                  GetLocationsCalendarsQuery,
                  GetLocationsCalendarsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTags = (
            options: Omit<
              WatchQueryOptions<GetTagsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTagsQuery> & {
              query: ObservableQuery<
                GetTagsQuery,
                GetTagsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTagsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTagsQuery> & {
                query: ObservableQuery<
                  GetTagsQuery,
                  GetTagsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetMembersOfTeam = (
            options: Omit<
              WatchQueryOptions<GetMembersOfTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMembersOfTeamQuery> & {
              query: ObservableQuery<
                GetMembersOfTeamQuery,
                GetMembersOfTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMembersOfTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMembersOfTeamQuery> & {
                query: ObservableQuery<
                  GetMembersOfTeamQuery,
                  GetMembersOfTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const UploadProfilePicture = (
            options: Omit<
              MutationOptions<any, UploadProfilePictureMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>({
              mutation: UploadProfilePictureDoc,
              ...options,
            });
            return m;
          }
export const UpdateUserName = (
            options: Omit<
              MutationOptions<any, UpdateUserNameMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateUserNameMutation, UpdateUserNameMutationVariables>({
              mutation: UpdateUserNameDoc,
              ...options,
            });
            return m;
          }
export const UpdateUserOccupation = (
            options: Omit<
              MutationOptions<any, UpdateUserOccupationMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateUserOccupationMutation, UpdateUserOccupationMutationVariables>({
              mutation: UpdateUserOccupationDoc,
              ...options,
            });
            return m;
          }
export const UpdateTeam = (
            options: Omit<
              MutationOptions<any, UpdateTeamMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateTeamMutation, UpdateTeamMutationVariables>({
              mutation: UpdateTeamDoc,
              ...options,
            });
            return m;
          }
export const InsertTeamName = (
            options: Omit<
              MutationOptions<any, InsertTeamNameMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertTeamNameMutation, InsertTeamNameMutationVariables>({
              mutation: InsertTeamNameDoc,
              ...options,
            });
            return m;
          }
export const DeleteTeamMember = (
            options: Omit<
              MutationOptions<any, DeleteTeamMemberMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>({
              mutation: DeleteTeamMemberDoc,
              ...options,
            });
            return m;
          }
export const UpdateTeamSocialMedia = (
            options: Omit<
              MutationOptions<any, UpdateTeamSocialMediaMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateTeamSocialMediaMutation, UpdateTeamSocialMediaMutationVariables>({
              mutation: UpdateTeamSocialMediaDoc,
              ...options,
            });
            return m;
          }
export const InsertTagsToTeam = (
            options: Omit<
              MutationOptions<any, InsertTagsToTeamMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertTagsToTeamMutation, InsertTagsToTeamMutationVariables>({
              mutation: InsertTagsToTeamDoc,
              ...options,
            });
            return m;
          }
export const DeleteTagsOfTeam = (
            options: Omit<
              MutationOptions<any, DeleteTagsOfTeamMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<DeleteTagsOfTeamMutation, DeleteTagsOfTeamMutationVariables>({
              mutation: DeleteTagsOfTeamDoc,
              ...options,
            });
            return m;
          }
export const GetDiagnosticsTabsActiveByTeam = (
            options: Omit<
              WatchQueryOptions<GetDiagnosticsTabsActiveByTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetDiagnosticsTabsActiveByTeamQuery> & {
              query: ObservableQuery<
                GetDiagnosticsTabsActiveByTeamQuery,
                GetDiagnosticsTabsActiveByTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetDiagnosticsTabsActiveByTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetDiagnosticsTabsActiveByTeamQuery> & {
                query: ObservableQuery<
                  GetDiagnosticsTabsActiveByTeamQuery,
                  GetDiagnosticsTabsActiveByTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetDiagnosticsSectionsByTab = (
            options: Omit<
              WatchQueryOptions<GetDiagnosticsSectionsByTabQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetDiagnosticsSectionsByTabQuery> & {
              query: ObservableQuery<
                GetDiagnosticsSectionsByTabQuery,
                GetDiagnosticsSectionsByTabQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetDiagnosticsSectionsByTabDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetDiagnosticsSectionsByTabQuery> & {
                query: ObservableQuery<
                  GetDiagnosticsSectionsByTabQuery,
                  GetDiagnosticsSectionsByTabQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetDiagnosticsSubsectionsBySectionAndTeam = (
            options: Omit<
              WatchQueryOptions<GetDiagnosticsSubsectionsBySectionAndTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetDiagnosticsSubsectionsBySectionAndTeamQuery> & {
              query: ObservableQuery<
                GetDiagnosticsSubsectionsBySectionAndTeamQuery,
                GetDiagnosticsSubsectionsBySectionAndTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetDiagnosticsSubsectionsBySectionAndTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetDiagnosticsSubsectionsBySectionAndTeamQuery> & {
                query: ObservableQuery<
                  GetDiagnosticsSubsectionsBySectionAndTeamQuery,
                  GetDiagnosticsSubsectionsBySectionAndTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeam = (
            options: Omit<
              MutationOptions<any, StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeamMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeamMutation, StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeamMutationVariables>({
              mutation: StoreDiagnosticsQuestionsAnswersByTabQuestionAndTeamDoc,
              ...options,
            });
            return m;
          }
export const GetDiagnosticsCommentByTabAndTeam = (
            options: Omit<
              WatchQueryOptions<GetDiagnosticsCommentByTabAndTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetDiagnosticsCommentByTabAndTeamQuery> & {
              query: ObservableQuery<
                GetDiagnosticsCommentByTabAndTeamQuery,
                GetDiagnosticsCommentByTabAndTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetDiagnosticsCommentByTabAndTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetDiagnosticsCommentByTabAndTeamQuery> & {
                query: ObservableQuery<
                  GetDiagnosticsCommentByTabAndTeamQuery,
                  GetDiagnosticsCommentByTabAndTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const StoreDiagnosticsCommentByTabAndTeam = (
            options: Omit<
              MutationOptions<any, StoreDiagnosticsCommentByTabAndTeamMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<StoreDiagnosticsCommentByTabAndTeamMutation, StoreDiagnosticsCommentByTabAndTeamMutationVariables>({
              mutation: StoreDiagnosticsCommentByTabAndTeamDoc,
              ...options,
            });
            return m;
          }
export const GetVideosByTeam = (
            options: Omit<
              WatchQueryOptions<GetVideosByTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetVideosByTeamQuery> & {
              query: ObservableQuery<
                GetVideosByTeamQuery,
                GetVideosByTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetVideosByTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetVideosByTeamQuery> & {
                query: ObservableQuery<
                  GetVideosByTeamQuery,
                  GetVideosByTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const CountVideosByTagsByTeam = (
            options: Omit<
              WatchQueryOptions<CountVideosByTagsByTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<CountVideosByTagsByTeamQuery> & {
              query: ObservableQuery<
                CountVideosByTagsByTeamQuery,
                CountVideosByTagsByTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: CountVideosByTagsByTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<CountVideosByTagsByTeamQuery> & {
                query: ObservableQuery<
                  CountVideosByTagsByTeamQuery,
                  CountVideosByTagsByTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetVideosByTagsByTeam = (
            options: Omit<
              WatchQueryOptions<GetVideosByTagsByTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetVideosByTagsByTeamQuery> & {
              query: ObservableQuery<
                GetVideosByTagsByTeamQuery,
                GetVideosByTagsByTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetVideosByTagsByTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetVideosByTagsByTeamQuery> & {
                query: ObservableQuery<
                  GetVideosByTagsByTeamQuery,
                  GetVideosByTagsByTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetYoutubeTags = (
            options: Omit<
              WatchQueryOptions<GetYoutubeTagsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetYoutubeTagsQuery> & {
              query: ObservableQuery<
                GetYoutubeTagsQuery,
                GetYoutubeTagsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetYoutubeTagsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetYoutubeTagsQuery> & {
                query: ObservableQuery<
                  GetYoutubeTagsQuery,
                  GetYoutubeTagsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetRepositoriesActiveByTeam = (
            options: Omit<
              WatchQueryOptions<GetRepositoriesActiveByTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetRepositoriesActiveByTeamQuery> & {
              query: ObservableQuery<
                GetRepositoriesActiveByTeamQuery,
                GetRepositoriesActiveByTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetRepositoriesActiveByTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetRepositoriesActiveByTeamQuery> & {
                query: ObservableQuery<
                  GetRepositoriesActiveByTeamQuery,
                  GetRepositoriesActiveByTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const StoreQuestionsAnswersByQuestionByTeam = (
            options: Omit<
              MutationOptions<any, StoreQuestionsAnswersByQuestionByTeamMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<StoreQuestionsAnswersByQuestionByTeamMutation, StoreQuestionsAnswersByQuestionByTeamMutationVariables>({
              mutation: StoreQuestionsAnswersByQuestionByTeamDoc,
              ...options,
            });
            return m;
          }
export const StoreQuestionnaireTeamState = (
            options: Omit<
              MutationOptions<any, StoreQuestionnaireTeamStateMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<StoreQuestionnaireTeamStateMutation, StoreQuestionnaireTeamStateMutationVariables>({
              mutation: StoreQuestionnaireTeamStateDoc,
              ...options,
            });
            return m;
          }
export const GetMentorsFeedbackByToolByTeam = (
            options: Omit<
              WatchQueryOptions<GetMentorsFeedbackByToolByTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMentorsFeedbackByToolByTeamQuery> & {
              query: ObservableQuery<
                GetMentorsFeedbackByToolByTeamQuery,
                GetMentorsFeedbackByToolByTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMentorsFeedbackByToolByTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMentorsFeedbackByToolByTeamQuery> & {
                query: ObservableQuery<
                  GetMentorsFeedbackByToolByTeamQuery,
                  GetMentorsFeedbackByToolByTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const StoreMentorsFeedback = (
            options: Omit<
              MutationOptions<any, StoreMentorsFeedbackMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<StoreMentorsFeedbackMutation, StoreMentorsFeedbackMutationVariables>({
              mutation: StoreMentorsFeedbackDoc,
              ...options,
            });
            return m;
          }
export const GetBlobStorageSASUri = (
            options: Omit<
              WatchQueryOptions<GetBlobStorageSasUriQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetBlobStorageSasUriQuery> & {
              query: ObservableQuery<
                GetBlobStorageSasUriQuery,
                GetBlobStorageSasUriQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetBlobStorageSasUriDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetBlobStorageSasUriQuery> & {
                query: ObservableQuery<
                  GetBlobStorageSasUriQuery,
                  GetBlobStorageSasUriQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const InsertMentoringRecord = (
            options: Omit<
              MutationOptions<any, InsertMentoringRecordMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertMentoringRecordMutation, InsertMentoringRecordMutationVariables>({
              mutation: InsertMentoringRecordDoc,
              ...options,
            });
            return m;
          }
export const GetMentoringRecord = (
            options: Omit<
              WatchQueryOptions<GetMentoringRecordQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMentoringRecordQuery> & {
              query: ObservableQuery<
                GetMentoringRecordQuery,
                GetMentoringRecordQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMentoringRecordDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMentoringRecordQuery> & {
                query: ObservableQuery<
                  GetMentoringRecordQuery,
                  GetMentoringRecordQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const UpdateMentoringRecordById = (
            options: Omit<
              MutationOptions<any, UpdateMentoringRecordByIdMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateMentoringRecordByIdMutation, UpdateMentoringRecordByIdMutationVariables>({
              mutation: UpdateMentoringRecordByIdDoc,
              ...options,
            });
            return m;
          }
export const DeleteMentoringRecordById = (
            options: Omit<
              MutationOptions<any, DeleteMentoringRecordByIdMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<DeleteMentoringRecordByIdMutation, DeleteMentoringRecordByIdMutationVariables>({
              mutation: DeleteMentoringRecordByIdDoc,
              ...options,
            });
            return m;
          }
export const GetMentoringRecordsByTeamByMonth = (
            options: Omit<
              WatchQueryOptions<GetMentoringRecordsByTeamByMonthQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMentoringRecordsByTeamByMonthQuery> & {
              query: ObservableQuery<
                GetMentoringRecordsByTeamByMonthQuery,
                GetMentoringRecordsByTeamByMonthQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMentoringRecordsByTeamByMonthDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMentoringRecordsByTeamByMonthQuery> & {
                query: ObservableQuery<
                  GetMentoringRecordsByTeamByMonthQuery,
                  GetMentoringRecordsByTeamByMonthQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const InviteEmailToTeam = (
            options: Omit<
              MutationOptions<any, InviteEmailToTeamMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InviteEmailToTeamMutation, InviteEmailToTeamMutationVariables>({
              mutation: InviteEmailToTeamDoc,
              ...options,
            });
            return m;
          }
export const GetInvitesOfTeam = (
            options: Omit<
              WatchQueryOptions<GetInvitesOfTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetInvitesOfTeamQuery> & {
              query: ObservableQuery<
                GetInvitesOfTeamQuery,
                GetInvitesOfTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetInvitesOfTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetInvitesOfTeamQuery> & {
                query: ObservableQuery<
                  GetInvitesOfTeamQuery,
                  GetInvitesOfTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTeams = (
            options: Omit<
              WatchQueryOptions<GetTeamsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamsQuery> & {
              query: ObservableQuery<
                GetTeamsQuery,
                GetTeamsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamsQuery> & {
                query: ObservableQuery<
                  GetTeamsQuery,
                  GetTeamsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetLocations = (
            options: Omit<
              WatchQueryOptions<GetLocationsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetLocationsQuery> & {
              query: ObservableQuery<
                GetLocationsQuery,
                GetLocationsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetLocationsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetLocationsQuery> & {
                query: ObservableQuery<
                  GetLocationsQuery,
                  GetLocationsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetGroups = (
            options: Omit<
              WatchQueryOptions<GetGroupsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetGroupsQuery> & {
              query: ObservableQuery<
                GetGroupsQuery,
                GetGroupsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetGroupsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetGroupsQuery> & {
                query: ObservableQuery<
                  GetGroupsQuery,
                  GetGroupsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTeamsByGroup = (
            options: Omit<
              WatchQueryOptions<GetTeamsByGroupQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamsByGroupQuery> & {
              query: ObservableQuery<
                GetTeamsByGroupQuery,
                GetTeamsByGroupQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamsByGroupDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamsByGroupQuery> & {
                query: ObservableQuery<
                  GetTeamsByGroupQuery,
                  GetTeamsByGroupQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GroupsByUser = (
            options: Omit<
              WatchQueryOptions<GroupsByUserQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GroupsByUserQuery> & {
              query: ObservableQuery<
                GroupsByUserQuery,
                GroupsByUserQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GroupsByUserDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GroupsByUserQuery> & {
                query: ObservableQuery<
                  GroupsByUserQuery,
                  GroupsByUserQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetInviteState = (
            options: Omit<
              WatchQueryOptions<GetInviteStateQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetInviteStateQuery> & {
              query: ObservableQuery<
                GetInviteStateQuery,
                GetInviteStateQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetInviteStateDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetInviteStateQuery> & {
                query: ObservableQuery<
                  GetInviteStateQuery,
                  GetInviteStateQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const ContentWithProgress = (
            options: Omit<
              WatchQueryOptions<ContentWithProgressQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<ContentWithProgressQuery> & {
              query: ObservableQuery<
                ContentWithProgressQuery,
                ContentWithProgressQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: ContentWithProgressDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<ContentWithProgressQuery> & {
                query: ObservableQuery<
                  ContentWithProgressQuery,
                  ContentWithProgressQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTeamsNames = (
            options: Omit<
              WatchQueryOptions<GetTeamsNamesQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamsNamesQuery> & {
              query: ObservableQuery<
                GetTeamsNamesQuery,
                GetTeamsNamesQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamsNamesDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamsNamesQuery> & {
                query: ObservableQuery<
                  GetTeamsNamesQuery,
                  GetTeamsNamesQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetGroupNameByTeamId = (
            options: Omit<
              WatchQueryOptions<GetGroupNameByTeamIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetGroupNameByTeamIdQuery> & {
              query: ObservableQuery<
                GetGroupNameByTeamIdQuery,
                GetGroupNameByTeamIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetGroupNameByTeamIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetGroupNameByTeamIdQuery> & {
                query: ObservableQuery<
                  GetGroupNameByTeamIdQuery,
                  GetGroupNameByTeamIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetGroupIdByUserId = (
            options: Omit<
              WatchQueryOptions<GetGroupIdByUserIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetGroupIdByUserIdQuery> & {
              query: ObservableQuery<
                GetGroupIdByUserIdQuery,
                GetGroupIdByUserIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetGroupIdByUserIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetGroupIdByUserIdQuery> & {
                query: ObservableQuery<
                  GetGroupIdByUserIdQuery,
                  GetGroupIdByUserIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const inviteNewUserTeamRole = (
            options: Omit<
              MutationOptions<any, InviteNewUserTeamRoleMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InviteNewUserTeamRoleMutation, InviteNewUserTeamRoleMutationVariables>({
              mutation: InviteNewUserTeamRoleDoc,
              ...options,
            });
            return m;
          }
export const GetTeamsByGroupId = (
            options: Omit<
              WatchQueryOptions<GetTeamsByGroupIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamsByGroupIdQuery> & {
              query: ObservableQuery<
                GetTeamsByGroupIdQuery,
                GetTeamsByGroupIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamsByGroupIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamsByGroupIdQuery> & {
                query: ObservableQuery<
                  GetTeamsByGroupIdQuery,
                  GetTeamsByGroupIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetUsersWithNames = (
            options: Omit<
              WatchQueryOptions<GetUsersWithNamesQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetUsersWithNamesQuery> & {
              query: ObservableQuery<
                GetUsersWithNamesQuery,
                GetUsersWithNamesQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetUsersWithNamesDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetUsersWithNamesQuery> & {
                query: ObservableQuery<
                  GetUsersWithNamesQuery,
                  GetUsersWithNamesQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const UpdateUserRole = (
            options: Omit<
              MutationOptions<any, UpdateUserRoleMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>({
              mutation: UpdateUserRoleDoc,
              ...options,
            });
            return m;
          }
export const GetMinutesAttachmentsByMentoringRecordId = (
            options: Omit<
              WatchQueryOptions<GetMinutesAttachmentsByMentoringRecordIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMinutesAttachmentsByMentoringRecordIdQuery> & {
              query: ObservableQuery<
                GetMinutesAttachmentsByMentoringRecordIdQuery,
                GetMinutesAttachmentsByMentoringRecordIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMinutesAttachmentsByMentoringRecordIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMinutesAttachmentsByMentoringRecordIdQuery> & {
                query: ObservableQuery<
                  GetMinutesAttachmentsByMentoringRecordIdQuery,
                  GetMinutesAttachmentsByMentoringRecordIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const InsertMentoringRecordAttachment = (
            options: Omit<
              MutationOptions<any, InsertMentoringRecordAttachmentMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertMentoringRecordAttachmentMutation, InsertMentoringRecordAttachmentMutationVariables>({
              mutation: InsertMentoringRecordAttachmentDoc,
              ...options,
            });
            return m;
          }
export const DeleteMentoringRecordAttachmentsByMentoringRecordId = (
            options: Omit<
              MutationOptions<any, DeleteMentoringRecordAttachmentsByMentoringRecordIdMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<DeleteMentoringRecordAttachmentsByMentoringRecordIdMutation, DeleteMentoringRecordAttachmentsByMentoringRecordIdMutationVariables>({
              mutation: DeleteMentoringRecordAttachmentsByMentoringRecordIdDoc,
              ...options,
            });
            return m;
          }
export const InsertMentoringMemberByMentoringRecordId = (
            options: Omit<
              MutationOptions<any, InsertMentoringMemberByMentoringRecordIdMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertMentoringMemberByMentoringRecordIdMutation, InsertMentoringMemberByMentoringRecordIdMutationVariables>({
              mutation: InsertMentoringMemberByMentoringRecordIdDoc,
              ...options,
            });
            return m;
          }
export const GetMentoringMemberByMentoringRecordId = (
            options: Omit<
              WatchQueryOptions<GetMentoringMemberByMentoringRecordIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMentoringMemberByMentoringRecordIdQuery> & {
              query: ObservableQuery<
                GetMentoringMemberByMentoringRecordIdQuery,
                GetMentoringMemberByMentoringRecordIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMentoringMemberByMentoringRecordIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMentoringMemberByMentoringRecordIdQuery> & {
                query: ObservableQuery<
                  GetMentoringMemberByMentoringRecordIdQuery,
                  GetMentoringMemberByMentoringRecordIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const DeleteMentoringRecordMember = (
            options: Omit<
              MutationOptions<any, DeleteMentoringRecordMemberMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<DeleteMentoringRecordMemberMutation, DeleteMentoringRecordMemberMutationVariables>({
              mutation: DeleteMentoringRecordMemberDoc,
              ...options,
            });
            return m;
          }
export const InsertMentoringScheduling = (
            options: Omit<
              MutationOptions<any, InsertMentoringSchedulingMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertMentoringSchedulingMutation, InsertMentoringSchedulingMutationVariables>({
              mutation: InsertMentoringSchedulingDoc,
              ...options,
            });
            return m;
          }
export const InsertMentoringSchedulingWithRequestId = (
            options: Omit<
              MutationOptions<any, InsertMentoringSchedulingWithRequestIdMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertMentoringSchedulingWithRequestIdMutation, InsertMentoringSchedulingWithRequestIdMutationVariables>({
              mutation: InsertMentoringSchedulingWithRequestIdDoc,
              ...options,
            });
            return m;
          }
export const CreateScheduleMeeting = (
            options: Omit<
              MutationOptions<any, CreateScheduleMeetingMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<CreateScheduleMeetingMutation, CreateScheduleMeetingMutationVariables>({
              mutation: CreateScheduleMeetingDoc,
              ...options,
            });
            return m;
          }
export const InsertMeetingIdOnSchedule = (
            options: Omit<
              MutationOptions<any, InsertMeetingIdOnScheduleMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertMeetingIdOnScheduleMutation, InsertMeetingIdOnScheduleMutationVariables>({
              mutation: InsertMeetingIdOnScheduleDoc,
              ...options,
            });
            return m;
          }
export const InsertMentoringSchedulingForDates = (
            options: Omit<
              MutationOptions<any, InsertMentoringSchedulingForDatesMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertMentoringSchedulingForDatesMutation, InsertMentoringSchedulingForDatesMutationVariables>({
              mutation: InsertMentoringSchedulingForDatesDoc,
              ...options,
            });
            return m;
          }
export const InsertMentoringSchedulingMembers = (
            options: Omit<
              MutationOptions<any, InsertMentoringSchedulingMembersMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertMentoringSchedulingMembersMutation, InsertMentoringSchedulingMembersMutationVariables>({
              mutation: InsertMentoringSchedulingMembersDoc,
              ...options,
            });
            return m;
          }
export const GetTeamUsers = (
            options: Omit<
              WatchQueryOptions<GetTeamUsersQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamUsersQuery> & {
              query: ObservableQuery<
                GetTeamUsersQuery,
                GetTeamUsersQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamUsersDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamUsersQuery> & {
                query: ObservableQuery<
                  GetTeamUsersQuery,
                  GetTeamUsersQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetMentoringSchedulingByTeamId = (
            options: Omit<
              WatchQueryOptions<GetMentoringSchedulingByTeamIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMentoringSchedulingByTeamIdQuery> & {
              query: ObservableQuery<
                GetMentoringSchedulingByTeamIdQuery,
                GetMentoringSchedulingByTeamIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMentoringSchedulingByTeamIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMentoringSchedulingByTeamIdQuery> & {
                query: ObservableQuery<
                  GetMentoringSchedulingByTeamIdQuery,
                  GetMentoringSchedulingByTeamIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetMentoringSchedulingMembersByMentoringSchedulingId = (
            options: Omit<
              WatchQueryOptions<GetMentoringSchedulingMembersByMentoringSchedulingIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMentoringSchedulingMembersByMentoringSchedulingIdQuery> & {
              query: ObservableQuery<
                GetMentoringSchedulingMembersByMentoringSchedulingIdQuery,
                GetMentoringSchedulingMembersByMentoringSchedulingIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMentoringSchedulingMembersByMentoringSchedulingIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMentoringSchedulingMembersByMentoringSchedulingIdQuery> & {
                query: ObservableQuery<
                  GetMentoringSchedulingMembersByMentoringSchedulingIdQuery,
                  GetMentoringSchedulingMembersByMentoringSchedulingIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const UpdateMentoringSchedulingById = (
            options: Omit<
              MutationOptions<any, UpdateMentoringSchedulingByIdMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateMentoringSchedulingByIdMutation, UpdateMentoringSchedulingByIdMutationVariables>({
              mutation: UpdateMentoringSchedulingByIdDoc,
              ...options,
            });
            return m;
          }
export const UpdateMentoringSchedulingForRecordsById = (
            options: Omit<
              MutationOptions<any, UpdateMentoringSchedulingForRecordsByIdMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateMentoringSchedulingForRecordsByIdMutation, UpdateMentoringSchedulingForRecordsByIdMutationVariables>({
              mutation: UpdateMentoringSchedulingForRecordsByIdDoc,
              ...options,
            });
            return m;
          }
export const UpdateMentoringSchedulingMembersByMentoringSchedulingId = (
            options: Omit<
              MutationOptions<any, UpdateMentoringSchedulingMembersByMentoringSchedulingIdMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateMentoringSchedulingMembersByMentoringSchedulingIdMutation, UpdateMentoringSchedulingMembersByMentoringSchedulingIdMutationVariables>({
              mutation: UpdateMentoringSchedulingMembersByMentoringSchedulingIdDoc,
              ...options,
            });
            return m;
          }
export const GetMentoringRecordsByTeamId = (
            options: Omit<
              WatchQueryOptions<GetMentoringRecordsByTeamIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMentoringRecordsByTeamIdQuery> & {
              query: ObservableQuery<
                GetMentoringRecordsByTeamIdQuery,
                GetMentoringRecordsByTeamIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMentoringRecordsByTeamIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMentoringRecordsByTeamIdQuery> & {
                query: ObservableQuery<
                  GetMentoringRecordsByTeamIdQuery,
                  GetMentoringRecordsByTeamIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const DeleteMentoringRecordAttachmentsByMentoringRecordAttachmentId = (
            options: Omit<
              MutationOptions<any, DeleteMentoringRecordAttachmentsByMentoringRecordAttachmentIdMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<DeleteMentoringRecordAttachmentsByMentoringRecordAttachmentIdMutation, DeleteMentoringRecordAttachmentsByMentoringRecordAttachmentIdMutationVariables>({
              mutation: DeleteMentoringRecordAttachmentsByMentoringRecordAttachmentIdDoc,
              ...options,
            });
            return m;
          }
export const GetAnswersByTeamId = (
            options: Omit<
              WatchQueryOptions<GetAnswersByTeamIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetAnswersByTeamIdQuery> & {
              query: ObservableQuery<
                GetAnswersByTeamIdQuery,
                GetAnswersByTeamIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetAnswersByTeamIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetAnswersByTeamIdQuery> & {
                query: ObservableQuery<
                  GetAnswersByTeamIdQuery,
                  GetAnswersByTeamIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetBlankAnswersByTeamId = (
            options: Omit<
              WatchQueryOptions<GetBlankAnswersByTeamIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetBlankAnswersByTeamIdQuery> & {
              query: ObservableQuery<
                GetBlankAnswersByTeamIdQuery,
                GetBlankAnswersByTeamIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetBlankAnswersByTeamIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetBlankAnswersByTeamIdQuery> & {
                query: ObservableQuery<
                  GetBlankAnswersByTeamIdQuery,
                  GetBlankAnswersByTeamIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const SendManagerEmail = (
            options: Omit<
              MutationOptions<any, SendManagerEmailMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<SendManagerEmailMutation, SendManagerEmailMutationVariables>({
              mutation: SendManagerEmailDoc,
              ...options,
            });
            return m;
          }
export const SendUserEmail = (
            options: Omit<
              MutationOptions<any, SendUserEmailMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<SendUserEmailMutation, SendUserEmailMutationVariables>({
              mutation: SendUserEmailDoc,
              ...options,
            });
            return m;
          }
export const InsertRequestedMentoringsOne = (
            options: Omit<
              MutationOptions<any, InsertRequestedMentoringsOneMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertRequestedMentoringsOneMutation, InsertRequestedMentoringsOneMutationVariables>({
              mutation: InsertRequestedMentoringsOneDoc,
              ...options,
            });
            return m;
          }
export const InsertRequestedMentoringsNewGamificationOne = (
            options: Omit<
              MutationOptions<any, InsertRequestedMentoringsNewGamificationOneMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertRequestedMentoringsNewGamificationOneMutation, InsertRequestedMentoringsNewGamificationOneMutationVariables>({
              mutation: InsertRequestedMentoringsNewGamificationOneDoc,
              ...options,
            });
            return m;
          }
export const GetRequestedsMentoringsByTeamId = (
            options: Omit<
              WatchQueryOptions<GetRequestedsMentoringsByTeamIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetRequestedsMentoringsByTeamIdQuery> & {
              query: ObservableQuery<
                GetRequestedsMentoringsByTeamIdQuery,
                GetRequestedsMentoringsByTeamIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetRequestedsMentoringsByTeamIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetRequestedsMentoringsByTeamIdQuery> & {
                query: ObservableQuery<
                  GetRequestedsMentoringsByTeamIdQuery,
                  GetRequestedsMentoringsByTeamIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const UpdateRequestedMentoringByPk = (
            options: Omit<
              MutationOptions<any, UpdateRequestedMentoringByPkMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateRequestedMentoringByPkMutation, UpdateRequestedMentoringByPkMutationVariables>({
              mutation: UpdateRequestedMentoringByPkDoc,
              ...options,
            });
            return m;
          }
export const SetRequestedMentoringStatusByPk = (
            options: Omit<
              MutationOptions<any, SetRequestedMentoringStatusByPkMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<SetRequestedMentoringStatusByPkMutation, SetRequestedMentoringStatusByPkMutationVariables>({
              mutation: SetRequestedMentoringStatusByPkDoc,
              ...options,
            });
            return m;
          }
export const UpdateRequestedMentoringForRecordsByPk = (
            options: Omit<
              MutationOptions<any, UpdateRequestedMentoringForRecordsByPkMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateRequestedMentoringForRecordsByPkMutation, UpdateRequestedMentoringForRecordsByPkMutationVariables>({
              mutation: UpdateRequestedMentoringForRecordsByPkDoc,
              ...options,
            });
            return m;
          }
export const GetMoreAvailableRequestsDates = (
            options: Omit<SubscriptionOptions<GetMoreAvailableRequestsDatesSubscriptionVariables>, "query">
          ) => {
            const q = client.subscribe<GetMoreAvailableRequestsDatesSubscription, GetMoreAvailableRequestsDatesSubscriptionVariables>(
              {
                query: GetMoreAvailableRequestsDatesDoc,
                ...options,
              }
            )
            return q;
          }
export const GetAllAvailableRequestsDates = (
            options: Omit<
              WatchQueryOptions<GetAllAvailableRequestsDatesQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetAllAvailableRequestsDatesQuery> & {
              query: ObservableQuery<
                GetAllAvailableRequestsDatesQuery,
                GetAllAvailableRequestsDatesQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetAllAvailableRequestsDatesDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetAllAvailableRequestsDatesQuery> & {
                query: ObservableQuery<
                  GetAllAvailableRequestsDatesQuery,
                  GetAllAvailableRequestsDatesQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetDateRequests = (
            options: Omit<
              WatchQueryOptions<GetDateRequestsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetDateRequestsQuery> & {
              query: ObservableQuery<
                GetDateRequestsQuery,
                GetDateRequestsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetDateRequestsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetDateRequestsQuery> & {
                query: ObservableQuery<
                  GetDateRequestsQuery,
                  GetDateRequestsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetSchedulingsV2 = (
            options: Omit<SubscriptionOptions<GetSchedulingsV2SubscriptionVariables>, "query">
          ) => {
            const q = client.subscribe<GetSchedulingsV2Subscription, GetSchedulingsV2SubscriptionVariables>(
              {
                query: GetSchedulingsV2Doc,
                ...options,
              }
            )
            return q;
          }
export const GetMentorByCpf = (
            options: Omit<
              WatchQueryOptions<GetMentorByCpfQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMentorByCpfQuery> & {
              query: ObservableQuery<
                GetMentorByCpfQuery,
                GetMentorByCpfQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMentorByCpfDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMentorByCpfQuery> & {
                query: ObservableQuery<
                  GetMentorByCpfQuery,
                  GetMentorByCpfQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTeamsScoresByTeamId = (
            options: Omit<
              WatchQueryOptions<GetTeamsScoresByTeamIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamsScoresByTeamIdQuery> & {
              query: ObservableQuery<
                GetTeamsScoresByTeamIdQuery,
                GetTeamsScoresByTeamIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamsScoresByTeamIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamsScoresByTeamIdQuery> & {
                query: ObservableQuery<
                  GetTeamsScoresByTeamIdQuery,
                  GetTeamsScoresByTeamIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetContentByTeamId = (
            options: Omit<
              WatchQueryOptions<GetContentByTeamIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetContentByTeamIdQuery> & {
              query: ObservableQuery<
                GetContentByTeamIdQuery,
                GetContentByTeamIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetContentByTeamIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetContentByTeamIdQuery> & {
                query: ObservableQuery<
                  GetContentByTeamIdQuery,
                  GetContentByTeamIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetActiveLocationsCalendars = (
            options: Omit<
              WatchQueryOptions<GetActiveLocationsCalendarsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetActiveLocationsCalendarsQuery> & {
              query: ObservableQuery<
                GetActiveLocationsCalendarsQuery,
                GetActiveLocationsCalendarsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetActiveLocationsCalendarsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetActiveLocationsCalendarsQuery> & {
                query: ObservableQuery<
                  GetActiveLocationsCalendarsQuery,
                  GetActiveLocationsCalendarsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetAvailableMentoringSchedulingByTeamId = (
            options: Omit<
              WatchQueryOptions<GetAvailableMentoringSchedulingByTeamIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetAvailableMentoringSchedulingByTeamIdQuery> & {
              query: ObservableQuery<
                GetAvailableMentoringSchedulingByTeamIdQuery,
                GetAvailableMentoringSchedulingByTeamIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetAvailableMentoringSchedulingByTeamIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetAvailableMentoringSchedulingByTeamIdQuery> & {
                query: ObservableQuery<
                  GetAvailableMentoringSchedulingByTeamIdQuery,
                  GetAvailableMentoringSchedulingByTeamIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetAvailableMentoringSchedulingByTeamIdAndDate = (
            options: Omit<
              WatchQueryOptions<GetAvailableMentoringSchedulingByTeamIdAndDateQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetAvailableMentoringSchedulingByTeamIdAndDateQuery> & {
              query: ObservableQuery<
                GetAvailableMentoringSchedulingByTeamIdAndDateQuery,
                GetAvailableMentoringSchedulingByTeamIdAndDateQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetAvailableMentoringSchedulingByTeamIdAndDateDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetAvailableMentoringSchedulingByTeamIdAndDateQuery> & {
                query: ObservableQuery<
                  GetAvailableMentoringSchedulingByTeamIdAndDateQuery,
                  GetAvailableMentoringSchedulingByTeamIdAndDateQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetTeamMethodologyByTeamId = (
            options: Omit<
              WatchQueryOptions<GetTeamMethodologyByTeamIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamMethodologyByTeamIdQuery> & {
              query: ObservableQuery<
                GetTeamMethodologyByTeamIdQuery,
                GetTeamMethodologyByTeamIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamMethodologyByTeamIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamMethodologyByTeamIdQuery> & {
                query: ObservableQuery<
                  GetTeamMethodologyByTeamIdQuery,
                  GetTeamMethodologyByTeamIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetGroupFlagsByTeamId = (
            options: Omit<
              WatchQueryOptions<GetGroupFlagsByTeamIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetGroupFlagsByTeamIdQuery> & {
              query: ObservableQuery<
                GetGroupFlagsByTeamIdQuery,
                GetGroupFlagsByTeamIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetGroupFlagsByTeamIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetGroupFlagsByTeamIdQuery> & {
                query: ObservableQuery<
                  GetGroupFlagsByTeamIdQuery,
                  GetGroupFlagsByTeamIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const InsertActionLogin = (
            options: Omit<
              MutationOptions<any, InsertActionLoginMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertActionLoginMutation, InsertActionLoginMutationVariables>({
              mutation: InsertActionLoginDoc,
              ...options,
            });
            return m;
          }
export const InsertActionVisit = (
            options: Omit<
              MutationOptions<any, InsertActionVisitMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertActionVisitMutation, InsertActionVisitMutationVariables>({
              mutation: InsertActionVisitDoc,
              ...options,
            });
            return m;
          }
export const InsertActionInsertTool = (
            options: Omit<
              MutationOptions<any, InsertActionInsertToolMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertActionInsertToolMutation, InsertActionInsertToolMutationVariables>({
              mutation: InsertActionInsertToolDoc,
              ...options,
            });
            return m;
          }
export const InsertActionUpdateTool = (
            options: Omit<
              MutationOptions<any, InsertActionUpdateToolMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertActionUpdateToolMutation, InsertActionUpdateToolMutationVariables>({
              mutation: InsertActionUpdateToolDoc,
              ...options,
            });
            return m;
          }
export const InsertActionFinishTool = (
            options: Omit<
              MutationOptions<any, InsertActionFinishToolMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertActionFinishToolMutation, InsertActionFinishToolMutationVariables>({
              mutation: InsertActionFinishToolDoc,
              ...options,
            });
            return m;
          }
export const InsertActionInsertMentoring = (
            options: Omit<
              MutationOptions<any, InsertActionInsertMentoringMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertActionInsertMentoringMutation, InsertActionInsertMentoringMutationVariables>({
              mutation: InsertActionInsertMentoringDoc,
              ...options,
            });
            return m;
          }
export const InsertActionUpdateMentoring = (
            options: Omit<
              MutationOptions<any, InsertActionUpdateMentoringMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertActionUpdateMentoringMutation, InsertActionUpdateMentoringMutationVariables>({
              mutation: InsertActionUpdateMentoringDoc,
              ...options,
            });
            return m;
          }
export const InsertActionDeleteMentoring = (
            options: Omit<
              MutationOptions<any, InsertActionDeleteMentoringMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertActionDeleteMentoringMutation, InsertActionDeleteMentoringMutationVariables>({
              mutation: InsertActionDeleteMentoringDoc,
              ...options,
            });
            return m;
          }
export const InsertActionUpdateProfile = (
            options: Omit<
              MutationOptions<any, InsertActionUpdateProfileMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertActionUpdateProfileMutation, InsertActionUpdateProfileMutationVariables>({
              mutation: InsertActionUpdateProfileDoc,
              ...options,
            });
            return m;
          }
export const UserActionsPerGroup = (
            options: Omit<
              WatchQueryOptions<UserActionsPerGroupQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<UserActionsPerGroupQuery> & {
              query: ObservableQuery<
                UserActionsPerGroupQuery,
                UserActionsPerGroupQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: UserActionsPerGroupDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<UserActionsPerGroupQuery> & {
                query: ObservableQuery<
                  UserActionsPerGroupQuery,
                  UserActionsPerGroupQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const UserActionsPerGroupSub = (
            options: Omit<SubscriptionOptions<UserActionsPerGroupSubSubscriptionVariables>, "query">
          ) => {
            const q = client.subscribe<UserActionsPerGroupSubSubscription, UserActionsPerGroupSubSubscriptionVariables>(
              {
                query: UserActionsPerGroupSubDoc,
                ...options,
              }
            )
            return q;
          }
export const UserActionsPerTeam = (
            options: Omit<
              WatchQueryOptions<UserActionsPerTeamQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<UserActionsPerTeamQuery> & {
              query: ObservableQuery<
                UserActionsPerTeamQuery,
                UserActionsPerTeamQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: UserActionsPerTeamDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<UserActionsPerTeamQuery> & {
                query: ObservableQuery<
                  UserActionsPerTeamQuery,
                  UserActionsPerTeamQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const UserActionsPerTeamSub = (
            options: Omit<SubscriptionOptions<UserActionsPerTeamSubSubscriptionVariables>, "query">
          ) => {
            const q = client.subscribe<UserActionsPerTeamSubSubscription, UserActionsPerTeamSubSubscriptionVariables>(
              {
                query: UserActionsPerTeamSubDoc,
                ...options,
              }
            )
            return q;
          }
export const InsertGroups = (
            options: Omit<
              MutationOptions<any, InsertGroupsMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertGroupsMutation, InsertGroupsMutationVariables>({
              mutation: InsertGroupsDoc,
              ...options,
            });
            return m;
          }
export const InsertTeamUser = (
            options: Omit<
              MutationOptions<any, InsertTeamUserMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertTeamUserMutation, InsertTeamUserMutationVariables>({
              mutation: InsertTeamUserDoc,
              ...options,
            });
            return m;
          }
export const InsertTeam = (
            options: Omit<
              MutationOptions<any, InsertTeamMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertTeamMutation, InsertTeamMutationVariables>({
              mutation: InsertTeamDoc,
              ...options,
            });
            return m;
          }
export const GetContents = (
            options: Omit<
              WatchQueryOptions<GetContentsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetContentsQuery> & {
              query: ObservableQuery<
                GetContentsQuery,
                GetContentsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetContentsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetContentsQuery> & {
                query: ObservableQuery<
                  GetContentsQuery,
                  GetContentsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetDiagnostics = (
            options: Omit<
              WatchQueryOptions<GetDiagnosticsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetDiagnosticsQuery> & {
              query: ObservableQuery<
                GetDiagnosticsQuery,
                GetDiagnosticsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetDiagnosticsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetDiagnosticsQuery> & {
                query: ObservableQuery<
                  GetDiagnosticsQuery,
                  GetDiagnosticsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const InsertLocationWithGroup = (
            options: Omit<
              MutationOptions<any, InsertLocationWithGroupMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertLocationWithGroupMutation, InsertLocationWithGroupMutationVariables>({
              mutation: InsertLocationWithGroupDoc,
              ...options,
            });
            return m;
          }
export const GetVideosByTeamAllContents = (
            options: Omit<
              WatchQueryOptions<GetVideosByTeamAllContentsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetVideosByTeamAllContentsQuery> & {
              query: ObservableQuery<
                GetVideosByTeamAllContentsQuery,
                GetVideosByTeamAllContentsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetVideosByTeamAllContentsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetVideosByTeamAllContentsQuery> & {
                query: ObservableQuery<
                  GetVideosByTeamAllContentsQuery,
                  GetVideosByTeamAllContentsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetUserByCpf = (
            options: Omit<
              WatchQueryOptions<GetUserByCpfQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetUserByCpfQuery> & {
              query: ObservableQuery<
                GetUserByCpfQuery,
                GetUserByCpfQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetUserByCpfDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetUserByCpfQuery> & {
                query: ObservableQuery<
                  GetUserByCpfQuery,
                  GetUserByCpfQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetMentoringSchedulingLteByTeamId = (
            options: Omit<
              WatchQueryOptions<GetMentoringSchedulingLteByTeamIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMentoringSchedulingLteByTeamIdQuery> & {
              query: ObservableQuery<
                GetMentoringSchedulingLteByTeamIdQuery,
                GetMentoringSchedulingLteByTeamIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMentoringSchedulingLteByTeamIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMentoringSchedulingLteByTeamIdQuery> & {
                query: ObservableQuery<
                  GetMentoringSchedulingLteByTeamIdQuery,
                  GetMentoringSchedulingLteByTeamIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetUserByIdentifier = (
            options: Omit<
              WatchQueryOptions<GetUserByIdentifierQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetUserByIdentifierQuery> & {
              query: ObservableQuery<
                GetUserByIdentifierQuery,
                GetUserByIdentifierQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetUserByIdentifierDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetUserByIdentifierQuery> & {
                query: ObservableQuery<
                  GetUserByIdentifierQuery,
                  GetUserByIdentifierQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const CountAllToolsDoneByTeamId = (
            options: Omit<
              WatchQueryOptions<CountAllToolsDoneByTeamIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<CountAllToolsDoneByTeamIdQuery> & {
              query: ObservableQuery<
                CountAllToolsDoneByTeamIdQuery,
                CountAllToolsDoneByTeamIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: CountAllToolsDoneByTeamIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<CountAllToolsDoneByTeamIdQuery> & {
                query: ObservableQuery<
                  CountAllToolsDoneByTeamIdQuery,
                  CountAllToolsDoneByTeamIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetToolsByTeamId = (
            options: Omit<
              WatchQueryOptions<GetToolsByTeamIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetToolsByTeamIdQuery> & {
              query: ObservableQuery<
                GetToolsByTeamIdQuery,
                GetToolsByTeamIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetToolsByTeamIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetToolsByTeamIdQuery> & {
                query: ObservableQuery<
                  GetToolsByTeamIdQuery,
                  GetToolsByTeamIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetMentorTags = (
            options: Omit<
              WatchQueryOptions<GetMentorTagsQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMentorTagsQuery> & {
              query: ObservableQuery<
                GetMentorTagsQuery,
                GetMentorTagsQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMentorTagsDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMentorTagsQuery> & {
                query: ObservableQuery<
                  GetMentorTagsQuery,
                  GetMentorTagsQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const CancellRequest = (
            options: Omit<
              MutationOptions<any, CancellRequestMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<CancellRequestMutation, CancellRequestMutationVariables>({
              mutation: CancellRequestDoc,
              ...options,
            });
            return m;
          }
export const InsertMentoringReschedule = (
            options: Omit<
              MutationOptions<any, InsertMentoringRescheduleMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertMentoringRescheduleMutation, InsertMentoringRescheduleMutationVariables>({
              mutation: InsertMentoringRescheduleDoc,
              ...options,
            });
            return m;
          }
export const InsertRescheduleIntoSchedule = (
            options: Omit<
              MutationOptions<any, InsertRescheduleIntoScheduleMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertRescheduleIntoScheduleMutation, InsertRescheduleIntoScheduleMutationVariables>({
              mutation: InsertRescheduleIntoScheduleDoc,
              ...options,
            });
            return m;
          }
export const SendEmail = (
            options: Omit<
              MutationOptions<any, SendEmailMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<SendEmailMutation, SendEmailMutationVariables>({
              mutation: SendEmailDoc,
              ...options,
            });
            return m;
          }
export const GetMembersOfTeamEmail = (
            options: Omit<
              WatchQueryOptions<GetMembersOfTeamEmailQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMembersOfTeamEmailQuery> & {
              query: ObservableQuery<
                GetMembersOfTeamEmailQuery,
                GetMembersOfTeamEmailQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMembersOfTeamEmailDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMembersOfTeamEmailQuery> & {
                query: ObservableQuery<
                  GetMembersOfTeamEmailQuery,
                  GetMembersOfTeamEmailQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetManagerEmailByTeamId = (
            options: Omit<
              WatchQueryOptions<GetManagerEmailByTeamIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetManagerEmailByTeamIdQuery> & {
              query: ObservableQuery<
                GetManagerEmailByTeamIdQuery,
                GetManagerEmailByTeamIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetManagerEmailByTeamIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetManagerEmailByTeamIdQuery> & {
                query: ObservableQuery<
                  GetManagerEmailByTeamIdQuery,
                  GetManagerEmailByTeamIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const UpdateTeamDisable = (
            options: Omit<
              MutationOptions<any, UpdateTeamDisableMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateTeamDisableMutation, UpdateTeamDisableMutationVariables>({
              mutation: UpdateTeamDisableDoc,
              ...options,
            });
            return m;
          }
export const UpdateRequestV2Manager = (
            options: Omit<
              MutationOptions<any, UpdateRequestV2ManagerMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateRequestV2ManagerMutation, UpdateRequestV2ManagerMutationVariables>({
              mutation: UpdateRequestV2ManagerDoc,
              ...options,
            });
            return m;
          }
export const InsertMentor = (
            options: Omit<
              MutationOptions<any, InsertMentorMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertMentorMutation, InsertMentorMutationVariables>({
              mutation: InsertMentorDoc,
              ...options,
            });
            return m;
          }
export const TeamAcceptMentoring = (
            options: Omit<
              MutationOptions<any, TeamAcceptMentoringMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<TeamAcceptMentoringMutation, TeamAcceptMentoringMutationVariables>({
              mutation: TeamAcceptMentoringDoc,
              ...options,
            });
            return m;
          }
export const GetTeamAccept = (
            options: Omit<
              WatchQueryOptions<GetTeamAcceptQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetTeamAcceptQuery> & {
              query: ObservableQuery<
                GetTeamAcceptQuery,
                GetTeamAcceptQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetTeamAcceptDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetTeamAcceptQuery> & {
                query: ObservableQuery<
                  GetTeamAcceptQuery,
                  GetTeamAcceptQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetGroupsFlagsById = (
            options: Omit<
              WatchQueryOptions<GetGroupsFlagsByIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetGroupsFlagsByIdQuery> & {
              query: ObservableQuery<
                GetGroupsFlagsByIdQuery,
                GetGroupsFlagsByIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetGroupsFlagsByIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetGroupsFlagsByIdQuery> & {
                query: ObservableQuery<
                  GetGroupsFlagsByIdQuery,
                  GetGroupsFlagsByIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const CreateGroupFlag = (
            options: Omit<
              MutationOptions<any, CreateGroupFlagMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<CreateGroupFlagMutation, CreateGroupFlagMutationVariables>({
              mutation: CreateGroupFlagDoc,
              ...options,
            });
            return m;
          }
export const DeleteGroupFlag = (
            options: Omit<
              MutationOptions<any, DeleteGroupFlagMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<DeleteGroupFlagMutation, DeleteGroupFlagMutationVariables>({
              mutation: DeleteGroupFlagDoc,
              ...options,
            });
            return m;
          }
export const GetMentorEmailByTag = (
            options: Omit<
              WatchQueryOptions<GetMentorEmailByTagQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetMentorEmailByTagQuery> & {
              query: ObservableQuery<
                GetMentorEmailByTagQuery,
                GetMentorEmailByTagQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetMentorEmailByTagDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetMentorEmailByTagQuery> & {
                query: ObservableQuery<
                  GetMentorEmailByTagQuery,
                  GetMentorEmailByTagQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetGroupNameById = (
            options: Omit<
              WatchQueryOptions<GetGroupNameByIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetGroupNameByIdQuery> & {
              query: ObservableQuery<
                GetGroupNameByIdQuery,
                GetGroupNameByIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetGroupNameByIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetGroupNameByIdQuery> & {
                query: ObservableQuery<
                  GetGroupNameByIdQuery,
                  GetGroupNameByIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const DeleteDeleteMentorTags = (
            options: Omit<
              MutationOptions<any, DeleteDeleteMentorTagsMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<DeleteDeleteMentorTagsMutation, DeleteDeleteMentorTagsMutationVariables>({
              mutation: DeleteDeleteMentorTagsDoc,
              ...options,
            });
            return m;
          }
export const InsertMentorTags = (
            options: Omit<
              MutationOptions<any, InsertMentorTagsMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertMentorTagsMutation, InsertMentorTagsMutationVariables>({
              mutation: InsertMentorTagsDoc,
              ...options,
            });
            return m;
          }
export const UpdateTerm = (
            options: Omit<
              MutationOptions<any, UpdateTermMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateTermMutation, UpdateTermMutationVariables>({
              mutation: UpdateTermDoc,
              ...options,
            });
            return m;
          }
export const GetContentBaseInfo = (
            options: Omit<
              WatchQueryOptions<GetContentBaseInfoQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetContentBaseInfoQuery> & {
              query: ObservableQuery<
                GetContentBaseInfoQuery,
                GetContentBaseInfoQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetContentBaseInfoDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetContentBaseInfoQuery> & {
                query: ObservableQuery<
                  GetContentBaseInfoQuery,
                  GetContentBaseInfoQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetAllContentById = (
            options: Omit<
              WatchQueryOptions<GetAllContentByIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetAllContentByIdQuery> & {
              query: ObservableQuery<
                GetAllContentByIdQuery,
                GetAllContentByIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetAllContentByIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetAllContentByIdQuery> & {
                query: ObservableQuery<
                  GetAllContentByIdQuery,
                  GetAllContentByIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const InsertContent = (
            options: Omit<
              MutationOptions<any, InsertContentMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertContentMutation, InsertContentMutationVariables>({
              mutation: InsertContentDoc,
              ...options,
            });
            return m;
          }
export const GetOutputById = (
            options: Omit<
              WatchQueryOptions<GetOutputByIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetOutputByIdQuery> & {
              query: ObservableQuery<
                GetOutputByIdQuery,
                GetOutputByIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetOutputByIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetOutputByIdQuery> & {
                query: ObservableQuery<
                  GetOutputByIdQuery,
                  GetOutputByIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetSectionBasicsById = (
            options: Omit<
              WatchQueryOptions<GetSectionBasicsByIdQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetSectionBasicsByIdQuery> & {
              query: ObservableQuery<
                GetSectionBasicsByIdQuery,
                GetSectionBasicsByIdQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetSectionBasicsByIdDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetSectionBasicsByIdQuery> & {
                query: ObservableQuery<
                  GetSectionBasicsByIdQuery,
                  GetSectionBasicsByIdQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const GetOutputQuantity = (
            options: Omit<
              WatchQueryOptions<GetOutputQuantityQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetOutputQuantityQuery> & {
              query: ObservableQuery<
                GetOutputQuantityQuery,
                GetOutputQuantityQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetOutputQuantityDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetOutputQuantityQuery> & {
                query: ObservableQuery<
                  GetOutputQuantityQuery,
                  GetOutputQuantityQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const UploadFile = (
            options: Omit<
              MutationOptions<any, UploadFileMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UploadFileMutation, UploadFileMutationVariables>({
              mutation: UploadFileDoc,
              ...options,
            });
            return m;
          }
export const InsertOutput = (
            options: Omit<
              MutationOptions<any, InsertOutputMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertOutputMutation, InsertOutputMutationVariables>({
              mutation: InsertOutputDoc,
              ...options,
            });
            return m;
          }
export const EditOutput = (
            options: Omit<
              MutationOptions<any, EditOutputMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<EditOutputMutation, EditOutputMutationVariables>({
              mutation: EditOutputDoc,
              ...options,
            });
            return m;
          }
export const EditMultimediaContent = (
            options: Omit<
              MutationOptions<any, EditMultimediaContentMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<EditMultimediaContentMutation, EditMultimediaContentMutationVariables>({
              mutation: EditMultimediaContentDoc,
              ...options,
            });
            return m;
          }
export const DeleteMultimediaLinks = (
            options: Omit<
              MutationOptions<any, DeleteMultimediaLinksMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<DeleteMultimediaLinksMutation, DeleteMultimediaLinksMutationVariables>({
              mutation: DeleteMultimediaLinksDoc,
              ...options,
            });
            return m;
          }
export const InsertMultimediaLinks = (
            options: Omit<
              MutationOptions<any, InsertMultimediaLinksMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertMultimediaLinksMutation, InsertMultimediaLinksMutationVariables>({
              mutation: InsertMultimediaLinksDoc,
              ...options,
            });
            return m;
          }
export const UpdateFileByPk = (
            options: Omit<
              MutationOptions<any, UpdateFileByPkMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateFileByPkMutation, UpdateFileByPkMutationVariables>({
              mutation: UpdateFileByPkDoc,
              ...options,
            });
            return m;
          }
export const GetToolQuantity = (
            options: Omit<
              WatchQueryOptions<GetToolQuantityQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetToolQuantityQuery> & {
              query: ObservableQuery<
                GetToolQuantityQuery,
                GetToolQuantityQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetToolQuantityDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetToolQuantityQuery> & {
                query: ObservableQuery<
                  GetToolQuantityQuery,
                  GetToolQuantityQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const InsertTool = (
            options: Omit<
              MutationOptions<any, InsertToolMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertToolMutation, InsertToolMutationVariables>({
              mutation: InsertToolDoc,
              ...options,
            });
            return m;
          }
export const UpdateToolByPk = (
            options: Omit<
              MutationOptions<any, UpdateToolByPkMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateToolByPkMutation, UpdateToolByPkMutationVariables>({
              mutation: UpdateToolByPkDoc,
              ...options,
            });
            return m;
          }
export const DisableToolQuestionnaires = (
            options: Omit<
              MutationOptions<any, DisableToolQuestionnairesMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<DisableToolQuestionnairesMutation, DisableToolQuestionnairesMutationVariables>({
              mutation: DisableToolQuestionnairesDoc,
              ...options,
            });
            return m;
          }
export const InsertQuestionnaire = (
            options: Omit<
              MutationOptions<any, InsertQuestionnaireMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<InsertQuestionnaireMutation, InsertQuestionnaireMutationVariables>({
              mutation: InsertQuestionnaireDoc,
              ...options,
            });
            return m;
          }
export const GetContentDescriptionByPK = (
            options: Omit<
              WatchQueryOptions<GetContentDescriptionByPkQueryVariables>, 
              "query"
            >
          ): Readable<
            ApolloQueryResult<GetContentDescriptionByPkQuery> & {
              query: ObservableQuery<
                GetContentDescriptionByPkQuery,
                GetContentDescriptionByPkQueryVariables
              >;
            }
          > => {
            const q = client.watchQuery({
              query: GetContentDescriptionByPkDoc,
              ...options,
            });
            var result = readable<
              ApolloQueryResult<GetContentDescriptionByPkQuery> & {
                query: ObservableQuery<
                  GetContentDescriptionByPkQuery,
                  GetContentDescriptionByPkQueryVariables
                >;
              }
            >(
              { data: {} as any, loading: true, error: undefined, networkStatus: 1, query: q },
              (set) => {
                q.subscribe((v: any) => {
                  set({ ...v, query: q });
                });
              }
            );
            return result;
          }
        
export const UpdateContentDescription = (
            options: Omit<
              MutationOptions<any, UpdateContentDescriptionMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<UpdateContentDescriptionMutation, UpdateContentDescriptionMutationVariables>({
              mutation: UpdateContentDescriptionDoc,
              ...options,
            });
            return m;
          }
export const DeleteOutputTools = (
            options: Omit<
              MutationOptions<any, DeleteOutputToolsMutationVariables>, 
              "mutation"
            >
          ) => {
            const m = client.mutate<DeleteOutputToolsMutation, DeleteOutputToolsMutationVariables>({
              mutation: DeleteOutputToolsDoc,
              ...options,
            });
            return m;
          }